import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  EnumString: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AboutCellars = {
  __typename?: 'AboutCellars';
  cellars?: Maybe<Array<Maybe<CellarChecking>>>;
};

export type AboutContactDetails = {
  __typename?: 'AboutContactDetails';
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type AboutHistory = {
  __typename?: 'AboutHistory';
  description?: Maybe<Scalars['String']>;
};

export type AboutVineyard = {
  __typename?: 'AboutVineyard';
  description?: Maybe<Scalars['String']>;
  soilDescription?: Maybe<Scalars['String']>;
  otherCultures?: Maybe<Scalars['String']>;
  totalSurfaceArea?: Maybe<Scalars['Float']>;
  vineSurfaceArea?: Maybe<Scalars['Float']>;
};

export type AccountScreen = {
  __typename?: 'AccountScreen';
  title?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  newsletter?: Maybe<Scalars['Boolean']>;
  hasPassword?: Maybe<Scalars['Boolean']>;
  allowDigest?: Maybe<Scalars['Boolean']>;
  isEmailValid?: Maybe<Scalars['Boolean']>;
  billingAddress?: Maybe<Address>;
  logInProviders?: Maybe<Array<Maybe<Scalars['String']>>>;
  billingInformation?: Maybe<BillingInformation>;
  subscriptionRecurrence?: Maybe<SubscriptionRecurrence>;
};

export type AccountScreenEmpty = {
  __typename?: 'AccountScreenEmpty';
  email?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  allowDigest?: Maybe<Scalars['Boolean']>;
  hasPassword?: Maybe<Scalars['Boolean']>;
  isEmailValid?: Maybe<Scalars['Boolean']>;
  isProfessional?: Maybe<Scalars['Boolean']>;
  telephoneNumber?: Maybe<Scalars['String']>;
  logInProviders?: Maybe<Array<Maybe<Scalars['String']>>>;
  professionalCompany?: Maybe<Scalars['String']>;
  professionalCategory?: Maybe<Scalars['String']>;
  professionalAddress?: Maybe<Address>;
};

export type ActivePeriod = {
  __typename?: 'ActivePeriod';
  startAt?: Maybe<VagueDate>;
  endAt?: Maybe<VagueDate>;
};

export type ActivePeriodInput = {
  startAt?: Maybe<VagueDateInput>;
  endAt?: Maybe<VagueDateInput>;
};

export type ActivitiesCommonalitiesType = {
  __typename?: 'ActivitiesCommonalitiesType';
  byCommune?: Maybe<ActivityFilteredType>;
  byType?: Maybe<ActivityFilteredType>;
};

export type ActivityCardInformation = {
  __typename?: 'ActivityCardInformation';
  img?: Maybe<Img>;
};

export type ActivityCommonalityType = {
  __typename?: 'ActivityCommonalityType';
  wineEntityShortId?: Maybe<Scalars['String']>;
  wineEntityName?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  INSEEcode?: Maybe<Scalars['String']>;
  typeFr?: Maybe<Scalars['String']>;
  image?: Maybe<Array<Maybe<Img>>>;
};

export type ActivityFilteredType = {
  __typename?: 'ActivityFilteredType';
  result?: Maybe<Array<Maybe<ActivityCommonalityType>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ActivityFront = {
  __typename?: 'ActivityFront';
  activityShortId?: Maybe<Scalars['String']>;
  activityId?: Maybe<Scalars['ID']>;
  subscriptionName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<ActivityType>>>;
  languages?: Maybe<Array<Maybe<Language>>>;
  description?: Maybe<Scalars['String']>;
  groupSize?: Maybe<Array<Maybe<Scalars['Int']>>>;
  image?: Maybe<Array<Maybe<Img>>>;
  accessibleTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  startingAge?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  activePeriods?: Maybe<Array<Maybe<ActivePeriod>>>;
  duration?: Maybe<Scalars['Float']>;
  durationUnit?: Maybe<Scalars['String']>;
  reservationNeeded?: Maybe<Scalars['Boolean']>;
  priceDetails?: Maybe<Array<Maybe<PriceDetail>>>;
  contactPerson?: Maybe<PersonOption>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  free?: Maybe<Scalars['Boolean']>;
  priceOnEstimation?: Maybe<Scalars['Boolean']>;
  minRentTime?: Maybe<Scalars['Int']>;
  minRentTimeUnit?: Maybe<Scalars['String']>;
  maxRentTime?: Maybe<Scalars['Int']>;
  maxRentTimeUnit?: Maybe<Scalars['String']>;
};

export type ActivityList = {
  __typename?: 'ActivityList';
  activities?: Maybe<Array<Maybe<SimpleActivity>>>;
};

export type ActivityResult = {
  __typename?: 'ActivityResult';
  data?: Maybe<Array<Maybe<ActivityResultData>>>;
  totalCount?: Maybe<Array<Maybe<CountResult>>>;
  similar?: Maybe<Scalars['Boolean']>;
};

export type ActivityResultData = {
  __typename?: 'ActivityResultData';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  activityShortId?: Maybe<Scalars['String']>;
  wineEntityShortId?: Maybe<Scalars['String']>;
  wineEntityName?: Maybe<Scalars['String']>;
  commune?: Maybe<Scalars['String']>;
  prices?: Maybe<Array<Maybe<Scalars['Float']>>>;
  groupSize?: Maybe<Array<Maybe<Scalars['Int']>>>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  labelLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  private?: Maybe<Scalars['Boolean']>;
  parkingBus?: Maybe<Scalars['Boolean']>;
  accessibleTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  duration?: Maybe<Scalars['Float']>;
  durationUnit?: Maybe<Scalars['String']>;
  missingField?: Maybe<Scalars['String']>;
};

export type ActivitySearchInput = {
  searchText?: Maybe<Scalars['String']>;
  communes?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  accessibleTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  payments?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  nbPersons?: Maybe<Scalars['Int']>;
  privateActivity?: Maybe<Scalars['Boolean']>;
  parkingBus?: Maybe<Scalars['Boolean']>;
  durations?: Maybe<Array<Maybe<Scalars['String']>>>;
  priceRange?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type ActivityTabScreen = {
  __typename?: 'ActivityTabScreen';
  private?: Maybe<Scalars['Boolean']>;
  atWineEntity?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  wineEntityAddress?: Maybe<Address>;
  availableOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  receptionAreas?: Maybe<Array<Maybe<ReceptionArea>>>;
  equipmentProvided?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentDetails?: Maybe<LngString>;
  priceOnEstimation?: Maybe<Scalars['Boolean']>;
  paymentOptionsAccepted?: Maybe<Array<Maybe<Scalars['String']>>>;
  sanitaryMeasures?: Maybe<Array<Maybe<Scalars['String']>>>;
  minRentTime?: Maybe<Scalars['Int']>;
  minRentTimeUnit?: Maybe<Scalars['String']>;
  maxRentTime?: Maybe<Scalars['Int']>;
  maxRentTimeUnit?: Maybe<Scalars['String']>;
  accomodationPossible?: Maybe<Scalars['Boolean']>;
  bedCount?: Maybe<Scalars['Int']>;
  accomodationDetails?: Maybe<LngString>;
  name?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Img>>>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<LngString>;
  groupSize?: Maybe<Array<Maybe<Scalars['Int']>>>;
  accessibleTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  startingAge?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  activePeriods?: Maybe<Array<Maybe<ActivePeriod>>>;
  vagueActivePeriods?: Maybe<Array<Maybe<ActivePeriod>>>;
  isVagueActivePeriods?: Maybe<Scalars['Boolean']>;
  duration?: Maybe<Scalars['Float']>;
  durationUnit?: Maybe<Scalars['String']>;
  reservationNeeded?: Maybe<Scalars['Boolean']>;
  priceDetails?: Maybe<Array<Maybe<PriceDetail>>>;
  contactPerson?: Maybe<PersonOption>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactOptions?: Maybe<Array<Maybe<PersonOption>>>;
  free?: Maybe<Scalars['Boolean']>;
  parkingBus?: Maybe<Scalars['Boolean']>;
  numberWineTasted?: Maybe<Scalars['Int']>;
};

export type ActivityType = {
  __typename?: 'ActivityType';
  bgColor?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeFr?: Maybe<Scalars['String']>;
};

export type AddActivityInput = {
  name?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<LngStringInput>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupSize?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type AddCellarInput = {
  name: Scalars['String'];
  address?: Maybe<AddressInput>;
  sameAddressAsWineEntity?: Maybe<Scalars['Boolean']>;
  isVathouse?: Maybe<Scalars['Boolean']>;
  isConditioning?: Maybe<Scalars['Boolean']>;
  isStorage?: Maybe<Scalars['Boolean']>;
};

export type AddConnectionLogInput = {
  login?: Maybe<Scalars['String']>;
  browser?: Maybe<Scalars['String']>;
  device?: Maybe<Scalars['String']>;
  connectionSucceed?: Maybe<Scalars['Boolean']>;
  useragent?: Maybe<Scalars['String']>;
};

export type AddQrcodeStatsInput = {
  vintageId?: Maybe<Scalars['String']>;
  wineId?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
};

export type AddTeamMemberInput = {
  kind: PersonKind;
  roleComplement?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  organizationName?: Maybe<Scalars['String']>;
  organizationStatus?: Maybe<Scalars['String']>;
  proFunctions?: Maybe<Array<Maybe<Scalars['String']>>>;
  feminineProFunction?: Maybe<Scalars['Boolean']>;
};

export type Address = {
  __typename?: 'Address';
  address?: Maybe<Scalars['String']>;
  complementaryAddress?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  commune?: Maybe<Scalars['String']>;
  communeINSEEcode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  address?: Maybe<Scalars['String']>;
  complementaryAddress?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  commune?: Maybe<Scalars['String']>;
  communeINSEEcode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type AdviceInput = {
  description?: Maybe<LngStringInput>;
  serviceTemperature?: Maybe<Scalars['Int']>;
  treatmentBeforeService?: Maybe<Scalars['String']>;
  decantingDuration?: Maybe<Scalars['Int']>;
  ageing?: Maybe<Array<Maybe<Scalars['Int']>>>;
  foodPairings?: Maybe<Array<Maybe<FoodPairingInput>>>;
  appearanceColor?: Maybe<Scalars['String']>;
  colorOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  appearanceIntensity?: Maybe<Scalars['Int']>;
  appearanceBrilliance?: Maybe<Scalars['String']>;
  appearanceLimpidity?: Maybe<Scalars['String']>;
  appearanceTears?: Maybe<Scalars['String']>;
  noseAromas?: Maybe<Array<Maybe<Scalars['String']>>>;
  noseIntensity?: Maybe<Scalars['Int']>;
  palateAttack?: Maybe<Scalars['String']>;
  palateIntensity?: Maybe<Scalars['Int']>;
  palateLength?: Maybe<Scalars['String']>;
  palateAcidity?: Maybe<Scalars['String']>;
  palateTannins?: Maybe<Scalars['String']>;
  aromaValues?: Maybe<AromaValuesInput>;
  aromaAnimal?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaBalsamic?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaConfectionery?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaEmpyreumatic?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaFermented?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaFloral?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaFruity?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaMineral?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaOaky?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaSpicy?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaVegetal?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AdviceType = {
  __typename?: 'AdviceType';
  description?: Maybe<LngString>;
  serviceTemperature?: Maybe<Scalars['Int']>;
  treatmentBeforeService?: Maybe<Scalars['String']>;
  decantingDuration?: Maybe<Scalars['Int']>;
  ageing?: Maybe<Array<Maybe<Scalars['Int']>>>;
  foodPairings?: Maybe<Array<Maybe<FoodPairing>>>;
  appearanceColor?: Maybe<Scalars['String']>;
  colorOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  appearanceIntensity?: Maybe<Scalars['Int']>;
  appearanceBrilliance?: Maybe<Scalars['String']>;
  appearanceLimpidity?: Maybe<Scalars['String']>;
  appearanceTears?: Maybe<Scalars['String']>;
  noseAromas?: Maybe<Array<Maybe<Scalars['String']>>>;
  noseIntensity?: Maybe<Scalars['Int']>;
  palateAttack?: Maybe<Scalars['String']>;
  palateIntensity?: Maybe<Scalars['Int']>;
  palateLength?: Maybe<Scalars['String']>;
  palateAcidity?: Maybe<Scalars['String']>;
  palateTannins?: Maybe<Scalars['String']>;
  aromaValues?: Maybe<AromaValues>;
  aromaAnimal?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaBalsamic?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaConfectionery?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaEmpyreumatic?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaFermented?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaFloral?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaFruity?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaMineral?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaOaky?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaSpicy?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaVegetal?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AllResult = {
  __typename?: 'AllResult';
  wineEntityData?: Maybe<WineEntityResult>;
  vintageData?: Maybe<WineResult>;
  activityData?: Maybe<ActivityResult>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AllSearchInput = {
  searchText?: Maybe<Scalars['String']>;
};

export enum AppellationDependentField {
  Type = 'type',
  Color = 'color',
  Sweetness = 'sweetness'
}

export type AppellationDescriptionFrontType = {
  __typename?: 'AppellationDescriptionFrontType';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Document>;
  subregion?: Maybe<Scalars['String']>;
  wines?: Maybe<Array<Maybe<WineCompositionType>>>;
  winesProduced?: Maybe<Scalars['String']>;
  surface?: Maybe<Scalars['Float']>;
  surfacePercentage?: Maybe<Scalars['Float']>;
  odgId?: Maybe<Scalars['ID']>;
  odg?: Maybe<OdgType>;
  specificationLink?: Maybe<Scalars['String']>;
  grapeVarietiesRed?: Maybe<Array<Maybe<GrapeVarietyType>>>;
  grapeVarietiesWhite?: Maybe<Array<Maybe<GrapeVarietyType>>>;
  geoArea?: Maybe<Array<Maybe<Scalars['String']>>>;
  proximityArea?: Maybe<Array<Maybe<Scalars['String']>>>;
  wineInformations?: Maybe<Array<Maybe<WineInformationFrontType>>>;
};

export type AppellationDescriptionInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<DocumentInput>;
  subregion?: Maybe<Scalars['String']>;
  wines?: Maybe<Array<Maybe<WineCompositionInput>>>;
  surface?: Maybe<Scalars['Float']>;
  surfacePercentage?: Maybe<Scalars['Float']>;
  odgId?: Maybe<Scalars['ID']>;
  specificationLink?: Maybe<Scalars['String']>;
  grapeVarietiesRed?: Maybe<Array<Maybe<GrapeVarietyInput>>>;
  grapeVarietiesWhite?: Maybe<Array<Maybe<GrapeVarietyInput>>>;
  geoArea?: Maybe<Array<Maybe<Scalars['String']>>>;
  proximityArea?: Maybe<Array<Maybe<Scalars['String']>>>;
  wineInformations?: Maybe<Array<Maybe<WineInformationInput>>>;
};

export type AppellationDescriptionType = {
  __typename?: 'AppellationDescriptionType';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<LngString>;
  logo?: Maybe<Document>;
  subregion?: Maybe<Scalars['String']>;
  wines?: Maybe<Array<Maybe<WineCompositionType>>>;
  surface?: Maybe<Scalars['Float']>;
  surfacePercentage?: Maybe<Scalars['Float']>;
  odgId?: Maybe<Scalars['ID']>;
  specificationLink?: Maybe<Scalars['String']>;
  grapeVarietiesRed?: Maybe<Array<Maybe<GrapeVarietyType>>>;
  grapeVarietiesWhite?: Maybe<Array<Maybe<GrapeVarietyType>>>;
  geoArea?: Maybe<Array<Maybe<Scalars['String']>>>;
  proximityArea?: Maybe<Array<Maybe<Scalars['String']>>>;
  wineInformations?: Maybe<Array<Maybe<WineInformationType>>>;
};

export type AppellationDescriptionsSample = {
  __typename?: 'AppellationDescriptionsSample';
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Document>;
  subregion?: Maybe<Scalars['String']>;
  wines?: Maybe<Array<Maybe<WineCompositionType>>>;
};

export type AppellationInput = {
  link?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type AppellationType = {
  __typename?: 'AppellationType';
  label?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type ApplyEstateCheckingInput = {
  historyDescription?: Maybe<Scalars['String']>;
  vineyardDescription?: Maybe<Scalars['String']>;
  soilDescription?: Maybe<Scalars['String']>;
  otherCultures?: Maybe<Scalars['String']>;
  totalSurfaceArea?: Maybe<Scalars['Float']>;
  vineSurfaceArea?: Maybe<Scalars['Float']>;
  cellars?: Maybe<Array<Maybe<CellarCheckingInput>>>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type AromaValues = {
  __typename?: 'AromaValues';
  Animal?: Maybe<Scalars['Int']>;
  Balsamique?: Maybe<Scalars['Int']>;
  Boise?: Maybe<Scalars['Int']>;
  Confiserie?: Maybe<Scalars['Int']>;
  Empyreumatique?: Maybe<Scalars['Int']>;
  Epice?: Maybe<Scalars['Int']>;
  Fermentaire?: Maybe<Scalars['Int']>;
  Floral?: Maybe<Scalars['Int']>;
  Fruite?: Maybe<Scalars['Int']>;
  Mineral?: Maybe<Scalars['Int']>;
  Vegetal?: Maybe<Scalars['Int']>;
};

export type AromaValuesInput = {
  Animal?: Maybe<Scalars['Int']>;
  Balsamique?: Maybe<Scalars['Int']>;
  Boise?: Maybe<Scalars['Int']>;
  Confiserie?: Maybe<Scalars['Int']>;
  Empyreumatique?: Maybe<Scalars['Int']>;
  Epice?: Maybe<Scalars['Int']>;
  Fermentaire?: Maybe<Scalars['Int']>;
  Floral?: Maybe<Scalars['Int']>;
  Fruite?: Maybe<Scalars['Int']>;
  Mineral?: Maybe<Scalars['Int']>;
  Vegetal?: Maybe<Scalars['Int']>;
};

export type ArticleContentType = {
  __typename?: 'ArticleContentType';
  content?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
};

export type ArticleFrontType = {
  __typename?: 'ArticleFrontType';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  readTime?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  authorSlug?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['String']>;
  authorInfo?: Maybe<Scalars['String']>;
  references?: Maybe<Array<Maybe<ReferenceType>>>;
  pageCount?: Maybe<Scalars['Int']>;
};

export type ArticleInput = {
  name?: Maybe<Scalars['String']>;
  readTime?: Maybe<TimeInput>;
  authorId?: Maybe<Scalars['ID']>;
  authorInfo?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  references?: Maybe<Array<Maybe<ReferenceInput>>>;
};

export type ArticleSummaryType = {
  __typename?: 'ArticleSummaryType';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  readTime?: Maybe<TimeType>;
  author?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['String']>;
  authorInfo?: Maybe<Scalars['String']>;
  slugFr?: Maybe<Scalars['String']>;
};

export type ArticleType = {
  __typename?: 'ArticleType';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<LngString>;
  readTime?: Maybe<TimeType>;
  author?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['String']>;
  authorInfo?: Maybe<Scalars['String']>;
  content?: Maybe<LngString>;
  references?: Maybe<Array<Maybe<ReferenceType>>>;
};

export type AuthorArticleType = {
  __typename?: 'AuthorArticleType';
  name?: Maybe<Scalars['String']>;
  slugFr?: Maybe<Scalars['String']>;
};

export type AuthorFrontItemType = {
  __typename?: 'AuthorFrontItemType';
  personIdentity?: Maybe<PersonSimple>;
  photo?: Maybe<Document>;
};

export type AuthorFrontType = {
  __typename?: 'AuthorFrontType';
  _id?: Maybe<Scalars['String']>;
  personIdentity?: Maybe<PersonSimple>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  photo?: Maybe<Document>;
  degree?: Maybe<Scalars['String']>;
  professionalExperience?: Maybe<Scalars['String']>;
  expertise?: Maybe<Scalars['String']>;
  expertiseDetails?: Maybe<Scalars['String']>;
  recentPublications?: Maybe<Array<Maybe<PublicationFront>>>;
};

export type AuthorInput = {
  personIdentity?: Maybe<PersonInput>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  photo?: Maybe<DocumentInput>;
  degree?: Maybe<Scalars['String']>;
  professionalExperience?: Maybe<Scalars['String']>;
  expertise?: Maybe<Scalars['String']>;
  expertiseDetails?: Maybe<Scalars['String']>;
  recentPublications?: Maybe<Array<Maybe<PublicationInput>>>;
};

export type AuthorType = {
  __typename?: 'AuthorType';
  _id?: Maybe<Scalars['String']>;
  personIdentity?: Maybe<PersonSimple>;
  title?: Maybe<LngString>;
  description?: Maybe<LngString>;
  photo?: Maybe<Document>;
  degree?: Maybe<LngString>;
  professionalExperience?: Maybe<LngString>;
  expertise?: Maybe<LngString>;
  expertiseDetails?: Maybe<LngString>;
  recentPublications?: Maybe<Array<Maybe<Publication>>>;
};

export type AutocompleteSearchResult = {
  __typename?: 'AutocompleteSearchResult';
  _id?: Maybe<Scalars['String']>;
  wineEntityShortId?: Maybe<Scalars['String']>;
  wineEntityName?: Maybe<Scalars['String']>;
  activityShortId?: Maybe<Scalars['String']>;
  wineShortId?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  wineEntityId?: Maybe<Scalars['String']>;
  vintages?: Maybe<Array<Maybe<SimpleVintage>>>;
};

export type BofWineEntityMetrics = {
  __typename?: 'BOFWineEntityMetrics';
  start?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  ftCount?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  wineCount?: Maybe<Scalars['Int']>;
  diffCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  fieldCount?: Maybe<Scalars['Int']>;
  shortId?: Maybe<Scalars['String']>;
  memberCount?: Maybe<Scalars['Int']>;
  cviImported?: Maybe<Scalars['Int']>;
  vintageCount?: Maybe<Scalars['Int']>;
  contactCount?: Maybe<Scalars['Int']>;
  packPlus?: Maybe<Scalars['Boolean']>;
  activityCount?: Maybe<Scalars['Int']>;
  freeTrial?: Maybe<Scalars['Boolean']>;
  lastConnection?: Maybe<Scalars['Date']>;
  connectionCount?: Maybe<Scalars['Int']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionType?: Maybe<Scalars['String']>;
};

export type BesvInfo = {
  __typename?: 'BesvInfo';
  scans?: Maybe<Array<Maybe<Img>>>;
  edition?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type BillingInformation = {
  __typename?: 'BillingInformation';
  siret?: Maybe<Scalars['String']>;
  organizationStatus?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  tvaNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephoneNumber?: Maybe<Scalars['String']>;
};

export type BillingInformationInput = {
  siret?: Maybe<Scalars['String']>;
  organizationStatus?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  tvaNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephoneNumber?: Maybe<Scalars['String']>;
};

export type BocFtParameters = {
  __typename?: 'BocFtParameters';
  ftId?: Maybe<Scalars['ID']>;
  wineEntityId?: Maybe<Scalars['ID']>;
  wineId?: Maybe<Scalars['ID']>;
  vintageId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  wine?: Maybe<Scalars['String']>;
  vintage?: Maybe<Scalars['String']>;
  cuvee?: Maybe<Scalars['String']>;
  fullAppellation?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  lastModification?: Maybe<Scalars['Date']>;
  online?: Maybe<Scalars['Boolean']>;
  languages?: Maybe<LanguagesBool>;
  preview?: Maybe<Img>;
  bottleImage?: Maybe<Img>;
  hasDiffusion?: Maybe<Scalars['Boolean']>;
  configuration?: Maybe<ConfigurationType>;
  createdAt?: Maybe<Scalars['Date']>;
  wineEntityName?: Maybe<Scalars['String']>;
};

export type BottlerOption = {
  __typename?: 'BottlerOption';
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  communeINSEEcode?: Maybe<Scalars['String']>;
  commune?: Maybe<Scalars['String']>;
  functions?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
};

export type BottlerOptionInput = {
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  communeINSEEcode?: Maybe<Scalars['String']>;
  functions?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
};

export type ByGroupResult = {
  __typename?: 'ByGroupResult';
  _id?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type CsvContactType = {
  __typename?: 'CSVContactType';
  contactNumber?: Maybe<Scalars['Int']>;
  contacts?: Maybe<Array<Maybe<FeretContactType>>>;
};

export type CanCreateFtType = {
  __typename?: 'CanCreateFtType';
  hasList?: Maybe<Scalars['Boolean']>;
  hasFt?: Maybe<Scalars['Boolean']>;
};

export type Category = {
  __typename?: 'Category';
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  appellation?: Maybe<Scalars['String']>;
  wineColor?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<Maybe<CategoryElement>>>;
  categories?: Maybe<Array<Maybe<Category>>>;
  path?: Maybe<Scalars['String']>;
  searchTerms?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  informationType?: Maybe<Scalars['String']>;
};

export type CategoryElement = {
  __typename?: 'CategoryElement';
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  searchTerms?: Maybe<Array<Maybe<Scalars['String']>>>;
  wineEntityId?: Maybe<Scalars['String']>;
  wineId?: Maybe<Scalars['String']>;
  vintageId?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type CellarChecking = {
  __typename?: 'CellarChecking';
  name?: Maybe<Scalars['String']>;
  isVathouse?: Maybe<Scalars['Boolean']>;
  isConditioning?: Maybe<Scalars['Boolean']>;
  isStorage?: Maybe<Scalars['Boolean']>;
  isAgeing?: Maybe<Scalars['Boolean']>;
  wineEntityAddress?: Maybe<Address>;
  surfaceArea?: Maybe<Scalars['Float']>;
};

export type CellarCheckingInput = {
  name?: Maybe<Scalars['String']>;
  isVathouse?: Maybe<Scalars['Boolean']>;
  isConditioning?: Maybe<Scalars['Boolean']>;
  isStorage?: Maybe<Scalars['Boolean']>;
  isAgeing?: Maybe<Scalars['Boolean']>;
  wineEntityAddress?: Maybe<AddressInput>;
  surfaceArea?: Maybe<Scalars['Float']>;
};

export type CellarData = {
  __typename?: 'CellarData';
  surfaceArea?: Maybe<Mesure>;
  isVathouse?: Maybe<Scalars['Boolean']>;
  isAgeing?: Maybe<Scalars['Boolean']>;
  isStorage?: Maybe<Scalars['Boolean']>;
};

export type CellarFront = {
  __typename?: 'CellarFront';
  name?: Maybe<Scalars['String']>;
  surfaceArea?: Maybe<Scalars['Float']>;
  isVathouse?: Maybe<Scalars['Boolean']>;
  isConditioning?: Maybe<Scalars['Boolean']>;
  isStorage?: Maybe<Scalars['Boolean']>;
  isAgeing?: Maybe<Scalars['Boolean']>;
  cellarId?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Img>>>;
};

export type CellarFrontPopin = {
  __typename?: 'CellarFrontPopin';
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  images?: Maybe<Array<Maybe<Img>>>;
  surfaceArea?: Maybe<Scalars['Int']>;
  airConditioning?: Maybe<Scalars['Boolean']>;
  temperature?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  vatThermoregulation?: Maybe<Array<Maybe<Scalars['String']>>>;
  vatCirculation?: Maybe<Scalars['String']>;
  vatContainers?: Maybe<Array<Maybe<VatContainer>>>;
  ageingContainers?: Maybe<Array<Maybe<VatContainer>>>;
  isVathouse?: Maybe<Scalars['Boolean']>;
  isConditioning?: Maybe<Scalars['Boolean']>;
  isStorage?: Maybe<Scalars['Boolean']>;
  isAgeing?: Maybe<Scalars['Boolean']>;
  conditioningActivities?: Maybe<Array<Maybe<Scalars['String']>>>;
  numberOfConditioningChains?: Maybe<Scalars['Int']>;
  conditioningCapacity?: Maybe<Scalars['Int']>;
  storageCapacity?: Maybe<Scalars['Int']>;
  storageCapacityUnit?: Maybe<Scalars['String']>;
};

export type CellarFunctions = {
  __typename?: 'CellarFunctions';
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  show?: Maybe<Scalars['Boolean']>;
};

export type CellarInput = {
  surfaceArea?: Maybe<MesureInput>;
  isVathouse?: Maybe<Scalars['Boolean']>;
  isAgeing?: Maybe<Scalars['Boolean']>;
  isStorage?: Maybe<Scalars['Boolean']>;
};

export type CellarTabScreen = {
  __typename?: 'CellarTabScreen';
  name?: Maybe<Scalars['String']>;
  sameAddressAsWineEntity?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  images?: Maybe<Array<Maybe<Img>>>;
  surfaceArea?: Maybe<Scalars['Int']>;
  airConditioning?: Maybe<Scalars['Boolean']>;
  temperature?: Maybe<Scalars['Int']>;
  description?: Maybe<LngString>;
  vatThermoregulation?: Maybe<Array<Maybe<Scalars['String']>>>;
  vatCirculation?: Maybe<Scalars['String']>;
  vatContainers?: Maybe<Array<Maybe<VatContainer>>>;
  ageingContainers?: Maybe<Array<Maybe<VatContainer>>>;
  isVathouse?: Maybe<Scalars['Boolean']>;
  isConditioning?: Maybe<Scalars['Boolean']>;
  isAgeing?: Maybe<Scalars['Boolean']>;
  conditioningActivities?: Maybe<Array<Maybe<Scalars['String']>>>;
  numberOfConditioningChains?: Maybe<Scalars['Int']>;
  conditioningCapacity?: Maybe<Scalars['Int']>;
  isStorage?: Maybe<Scalars['Boolean']>;
  storageCapacity?: Maybe<Scalars['Int']>;
  storageCapacityUnit?: Maybe<Scalars['String']>;
  wineEntityAddress?: Maybe<Address>;
};

export type CellarsDashboard = {
  __typename?: 'CellarsDashboard';
  cellars?: Maybe<Array<Maybe<SimpleCellar>>>;
  wineEntityAddress?: Maybe<Address>;
};

export type CertificationFront = {
  __typename?: 'CertificationFront';
  name?: Maybe<Scalars['String']>;
  isInConversion?: Maybe<Scalars['Boolean']>;
};

export enum CertificationLevel {
  WineEntity = 'WINE_ENTITY',
  Vintage = 'VINTAGE'
}

export type Classification = {
  __typename?: 'Classification';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ClassificationFront = {
  __typename?: 'ClassificationFront';
  id?: Maybe<Scalars['ID']>;
  number?: Maybe<Scalars['Int']>;
  classified?: Maybe<Array<Maybe<SimpleWineEntityLabel>>>;
  classificationGrade?: Maybe<Scalars['String']>;
};

export type ClassificationInput = {
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ClassificationOrganismFront = {
  __typename?: 'ClassificationOrganismFront';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  history?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  socialMedias?: Maybe<Array<Maybe<SocialMediaFront>>>;
  lastClassification?: Maybe<Scalars['Int']>;
  nextClassification?: Maybe<Scalars['Int']>;
  organisation?: Maybe<Scalars['String']>;
  classificationLink?: Maybe<Scalars['String']>;
  organism?: Maybe<PersonSimple>;
  president?: Maybe<PersonSimple>;
  director?: Maybe<PersonSimple>;
  logo?: Maybe<Document>;
  classifiedWines?: Maybe<WineType>;
};

export type ClassificationOrganismInput = {
  name?: Maybe<Scalars['String']>;
  orderItem?: Maybe<Scalars['Int']>;
  history?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  socialMedias?: Maybe<Array<Maybe<SocialMediaInput>>>;
  lastClassification?: Maybe<Scalars['Int']>;
  nextClassification?: Maybe<Scalars['Int']>;
  organisation?: Maybe<Scalars['String']>;
  classificationLink?: Maybe<Scalars['String']>;
  organism?: Maybe<PersonInput>;
  president?: Maybe<PersonInput>;
  director?: Maybe<PersonInput>;
  logo?: Maybe<DocumentInput>;
};

export type ClassificationOrganismItem = {
  __typename?: 'ClassificationOrganismItem';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Document>;
  classifiedWinesCount?: Maybe<Scalars['Int']>;
};

export type ClassificationOrganismType = {
  __typename?: 'ClassificationOrganismType';
  _id?: Maybe<Scalars['ID']>;
  orderItem?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  history?: Maybe<LngString>;
  about?: Maybe<LngString>;
  socialMedias?: Maybe<Array<Maybe<SocialMediaFront>>>;
  lastClassification?: Maybe<Scalars['Int']>;
  nextClassification?: Maybe<Scalars['Int']>;
  organisation?: Maybe<Scalars['String']>;
  classificationLink?: Maybe<Scalars['String']>;
  organism?: Maybe<PersonSimple>;
  president?: Maybe<PersonSimple>;
  director?: Maybe<PersonSimple>;
  logo?: Maybe<Document>;
};

export type CommuneFront = {
  __typename?: 'CommuneFront';
  communeId?: Maybe<Scalars['ID']>;
  fullName?: Maybe<Scalars['String']>;
  rootName?: Maybe<Scalars['String']>;
  nameAttribute?: Maybe<Scalars['String']>;
  surfaceArea?: Maybe<Scalars['Float']>;
  geoSituation?: Maybe<Scalars['String']>;
  soilDescription?: Maybe<Scalars['String']>;
  INSEEcode?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  regionName?: Maybe<Scalars['String']>;
  departmentNumber?: Maybe<Scalars['String']>;
  population?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  altitudeMax?: Maybe<Scalars['Int']>;
  altitudeMin?: Maybe<Scalars['Int']>;
  gentileM?: Maybe<Scalars['String']>;
  gentileF?: Maybe<Scalars['String']>;
  redWines?: Maybe<Array<Maybe<Scalars['String']>>>;
  whiteWines?: Maybe<Array<Maybe<Scalars['String']>>>;
  roseWines?: Maybe<Array<Maybe<Scalars['String']>>>;
  mairieAddress?: Maybe<Address>;
};

export type CommuneOption = {
  __typename?: 'CommuneOption';
  simpleCommuneName?: Maybe<Scalars['String']>;
  communeName?: Maybe<Scalars['String']>;
  communeINSEEcode?: Maybe<Scalars['String']>;
  appellations?: Maybe<Array<Maybe<Scalars['String']>>>;
  postalCode?: Maybe<Scalars['String']>;
};

export type CommuneSimple = {
  __typename?: 'CommuneSimple';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  INSEEcode?: Maybe<Scalars['String']>;
};

export type CompetitionFront = {
  __typename?: 'CompetitionFront';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  selectionCriteria?: Maybe<Scalars['String']>;
  moreInformations?: Maybe<Scalars['String']>;
  medalPercentage?: Maybe<Scalars['Float']>;
  medals?: Maybe<Array<Maybe<MedalType>>>;
  totalMedals?: Maybe<Scalars['Int']>;
  maxYear?: Maybe<Scalars['Int']>;
  lastDate?: Maybe<Scalars['Date']>;
  nextDate?: Maybe<Scalars['Date']>;
  nextDateYear?: Maybe<Scalars['Date']>;
  nextDateUnknow?: Maybe<Scalars['String']>;
  isNextDateKnow?: Maybe<Scalars['Boolean']>;
  members?: Maybe<Array<Maybe<CompetitionMembersType>>>;
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  socialMedias?: Maybe<Array<Maybe<SocialMediaFront>>>;
  logo?: Maybe<Document>;
  competitionId?: Maybe<Scalars['String']>;
};

export type CompetitionInput = {
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  selectionCriteria?: Maybe<Scalars['String']>;
  moreInformations?: Maybe<Scalars['String']>;
  medalPercentage?: Maybe<Scalars['Float']>;
  medals?: Maybe<Array<Maybe<MedalInput>>>;
  lastDate?: Maybe<Scalars['Date']>;
  nextDate?: Maybe<Scalars['Date']>;
  nextDateYear?: Maybe<Scalars['Date']>;
  nextDateUnknow?: Maybe<Scalars['String']>;
  isNextDateKnow?: Maybe<Scalars['Boolean']>;
  members?: Maybe<Array<Maybe<CompetitionMembersInput>>>;
  address?: Maybe<AddressInput>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  socialMedias?: Maybe<Array<Maybe<SocialMediaInput>>>;
  logo?: Maybe<DocumentInput>;
  logoEn?: Maybe<DocumentInput>;
};

export type CompetitionMembersInput = {
  _id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  role?: Maybe<LngStringInput>;
};

export type CompetitionMembersType = {
  __typename?: 'CompetitionMembersType';
  _id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  role?: Maybe<LngString>;
};

export type CompetitionType = {
  __typename?: 'CompetitionType';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  presentation?: Maybe<LngString>;
  selectionCriteria?: Maybe<LngString>;
  moreInformations?: Maybe<LngString>;
  medalPercentage?: Maybe<Scalars['Float']>;
  medals?: Maybe<Array<Maybe<MedalType>>>;
  lastDate?: Maybe<Scalars['Date']>;
  nextDate?: Maybe<Scalars['Date']>;
  nextDateYear?: Maybe<Scalars['Date']>;
  nextDateUnknow?: Maybe<LngString>;
  isNextDateKnow?: Maybe<Scalars['Boolean']>;
  members?: Maybe<Array<Maybe<CompetitionMembersType>>>;
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  socialMedias?: Maybe<Array<Maybe<SocialMediaFront>>>;
  logo?: Maybe<Document>;
  logoEn?: Maybe<Document>;
};

export type ContactCard = {
  __typename?: 'ContactCard';
  openingHours?: Maybe<Array<Maybe<OpeningHour>>>;
  displayOpeningHours?: Maybe<Scalars['Boolean']>;
  estateName?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  logo?: Maybe<Img>;
  coordinates?: Maybe<Coordinates>;
  telephoneNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
};

export type ContactInformation = {
  __typename?: 'ContactInformation';
  address?: Maybe<FtAddress>;
  telephone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  socialMedia?: Maybe<Array<Maybe<FtSocialMedia>>>;
  wineEntityName?: Maybe<Scalars['String']>;
  logos?: Maybe<Array<Maybe<Logo>>>;
};

export type ContactMetrics = {
  __typename?: 'ContactMetrics';
  total?: Maybe<Scalars['Int']>;
  listCount?: Maybe<Scalars['Int']>;
  subscriberCount?: Maybe<Scalars['Int']>;
  contactsByCategories?: Maybe<Array<Maybe<ContactsCategory>>>;
};

export type ContactPagination = {
  __typename?: 'ContactPagination';
  items?: Maybe<Array<Maybe<FeretContactType>>>;
  count?: Maybe<Scalars['Int']>;
};

export type ContactScreen = {
  __typename?: 'ContactScreen';
  contactEmail?: Maybe<Scalars['String']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
};

export type ContactStats = {
  __typename?: 'ContactStats';
  contactCount?: Maybe<Scalars['Int']>;
  contactListCount?: Maybe<Scalars['Int']>;
};

export type ContactsCategory = {
  __typename?: 'ContactsCategory';
  count?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  preciseLocation?: Maybe<Scalars['Boolean']>;
};

export type CountResult = {
  __typename?: 'CountResult';
  totalRecords?: Maybe<Scalars['Int']>;
};

export type CreateCertificationSuggestionInput = {
  name?: Maybe<Scalars['String']>;
  complementaryInformation?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<DocumentInput>>>;
  level?: Maybe<Scalars['String']>;
};

export type CreateLabelBocInput = {
  level?: Maybe<CertificationLevel>;
  object?: Maybe<Scalars['String']>;
  visibility?: Maybe<Scalars['Boolean']>;
  isInConversion?: Maybe<Scalars['Boolean']>;
  certificationBody?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['Date']>;
  proofDocuments?: Maybe<Array<Maybe<DocumentInput>>>;
};

export type CreateScore = {
  __typename?: 'CreateScore';
  _id?: Maybe<Scalars['ID']>;
  scoreMax?: Maybe<Scalars['Int']>;
  score: Scalars['String'];
  scoreUrl: Scalars['String'];
  level: Scalars['String'];
  wineId: Scalars['String'];
  vintageYear?: Maybe<Scalars['Int']>;
  vintageId?: Maybe<Scalars['String']>;
  criticName?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  wineEntityId: Scalars['String'];
  matchingDate?: Maybe<Scalars['String']>;
};

export type CreateVintageInput = {
  wineId: Scalars['ID'];
  year?: Maybe<Scalars['Int']>;
  cuvee?: Maybe<Scalars['String']>;
  duplicateVintageId?: Maybe<Scalars['ID']>;
};

export type CreateWineInput = {
  wineEntityId?: Maybe<Scalars['ID']>;
  brandName?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  sweetness?: Maybe<Scalars['String']>;
};

export type CreatedReward = {
  __typename?: 'CreatedReward';
  _id?: Maybe<Scalars['ID']>;
  lot?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  wineId?: Maybe<Scalars['ID']>;
  vintageId?: Maybe<Scalars['ID']>;
  wineEntityId?: Maybe<Scalars['ID']>;
  competitionId?: Maybe<Scalars['String']>;
  rewardGivenUrl?: Maybe<Scalars['String']>;
  sortIndex?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  competitionName?: Maybe<Scalars['String']>;
  image?: Maybe<Img>;
  matchingDate?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
};

export type CustomFontInput = {
  family?: Maybe<Scalars['String']>;
  files?: Maybe<FontFilesInput>;
};

export type CustomFontType = {
  __typename?: 'CustomFontType';
  _id?: Maybe<Scalars['ID']>;
  family?: Maybe<Scalars['String']>;
  files?: Maybe<FontFilesType>;
};


export type DetailedHarvest = {
  __typename?: 'DetailedHarvest';
  method?: Maybe<Scalars['String']>;
  grapeVariety?: Maybe<Scalars['String']>;
  startAt?: Maybe<VagueDate>;
  endAt?: Maybe<VagueDate>;
};

export type DetailedHarvestInput = {
  method?: Maybe<Scalars['String']>;
  grapeVariety?: Maybe<Scalars['String']>;
  startAt?: Maybe<VagueDateInput>;
  endAt?: Maybe<VagueDateInput>;
};

export type DetailedPhenologicalStage = {
  __typename?: 'DetailedPhenologicalStage';
  stage?: Maybe<Scalars['String']>;
  grapeVariety?: Maybe<Scalars['String']>;
  startAt?: Maybe<VagueDate>;
  endAt?: Maybe<VagueDate>;
  image?: Maybe<Img>;
};

export type DetailedPhenologicalStageInput = {
  stage?: Maybe<Scalars['String']>;
  grapeVariety?: Maybe<Scalars['String']>;
  startAt?: Maybe<VagueDateInput>;
  endAt?: Maybe<VagueDateInput>;
};

export type DetailsHarvest = {
  __typename?: 'DetailsHarvest';
  id?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['String']>;
  show?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export type DiffusionListContactInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<FeretContactInput>>>;
};

export type DiffusionListDiffType = {
  __typename?: 'DiffusionListDiffType';
  diffusionLists?: Maybe<Array<Maybe<DiffusionListType>>>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  wineName?: Maybe<Scalars['String']>;
  estateName?: Maybe<Scalars['String']>;
  vintage?: Maybe<Scalars['String']>;
  mainAppellation?: Maybe<Scalars['String']>;
  preview?: Maybe<Img>;
  lastUpdate?: Maybe<Scalars['Date']>;
};

export type DiffusionListInContact = {
  __typename?: 'DiffusionListInContact';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type DiffusionListInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type DiffusionListSimpleInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  contactCount?: Maybe<Scalars['Int']>;
};

export type DiffusionListType = {
  __typename?: 'DiffusionListType';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  wineEntityId?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<FeretContactType>>>;
  contactCount?: Maybe<Scalars['Int']>;
};

export type DiffusionParametersInput = {
  scoreNotify?: Maybe<Scalars['Boolean']>;
  medalNotify?: Maybe<Scalars['Boolean']>;
  certificationNotify?: Maybe<Scalars['Boolean']>;
};

export type DiffusionParametersType = {
  __typename?: 'DiffusionParametersType';
  scoreNotify?: Maybe<Scalars['Boolean']>;
  medalNotify?: Maybe<Scalars['Boolean']>;
  certificationNotify?: Maybe<Scalars['Boolean']>;
};

export type DistributionType = {
  __typename?: 'DistributionType';
  _id?: Maybe<Scalars['ID']>;
  units?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  centilisation?: Maybe<Scalars['String']>;
  conditionningMode?: Maybe<Scalars['String']>;
};

export type Document = {
  __typename?: 'Document';
  data?: Maybe<Scalars['String']>;
  bucketName?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  twicpicPath?: Maybe<Scalars['String']>;
  twicpicURL?: Maybe<Scalars['String']>;
};

export type DocumentInput = {
  bucketName?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
};

export type DynamicEnum = {
  __typename?: 'DynamicEnum';
  value?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  valueLng?: Maybe<LngString>;
};

export type DynamicEnumInput = {
  value?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  valueLng?: Maybe<LngStringInput>;
};

export type EditAccountInput = {
  logInProviders?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasPassword?: Maybe<Scalars['Boolean']>;
  newsletter?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<AddressInput>;
  billingInformation?: Maybe<BillingInformationInput>;
  allowDigest?: Maybe<Scalars['Boolean']>;
};

export type EditAccountScreenEmptyInput = {
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  telephoneNumber?: Maybe<Scalars['String']>;
  allowDigest?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  isProfessional?: Maybe<Scalars['Boolean']>;
  professionalCategory?: Maybe<Scalars['String']>;
  professionalCompany?: Maybe<Scalars['String']>;
  professionalAddress?: Maybe<AddressInput>;
};

export type EditActivityInput = {
  private?: Maybe<Scalars['Boolean']>;
  atWineEntity?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressInput>;
  wineEntityAddress?: Maybe<AddressInput>;
  availableOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  receptionAreas?: Maybe<Array<Maybe<ReceptionAreaInput>>>;
  equipmentProvided?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentDetails?: Maybe<LngStringInput>;
  priceOnEstimation?: Maybe<Scalars['Boolean']>;
  paymentOptionsAccepted?: Maybe<Array<Maybe<Scalars['String']>>>;
  sanitaryMeasures?: Maybe<Array<Maybe<Scalars['String']>>>;
  minRentTime?: Maybe<Scalars['Int']>;
  minRentTimeUnit?: Maybe<Scalars['String']>;
  maxRentTime?: Maybe<Scalars['Int']>;
  maxRentTimeUnit?: Maybe<Scalars['String']>;
  accomodationPossible?: Maybe<Scalars['Boolean']>;
  bedCount?: Maybe<Scalars['Int']>;
  accomodationDetails?: Maybe<LngStringInput>;
  name?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<LngStringInput>;
  groupSize?: Maybe<Array<Maybe<Scalars['Int']>>>;
  images?: Maybe<Array<Maybe<ImgInput>>>;
  accessibleTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  startingAge?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  activePeriods?: Maybe<Array<Maybe<ActivePeriodInput>>>;
  vagueActivePeriods?: Maybe<Array<Maybe<ActivePeriodInput>>>;
  isVagueActivePeriods?: Maybe<Scalars['Boolean']>;
  duration?: Maybe<Scalars['Float']>;
  durationUnit?: Maybe<Scalars['String']>;
  reservationNeeded?: Maybe<Scalars['Boolean']>;
  priceDetails?: Maybe<Array<Maybe<PriceDetailInput>>>;
  contactPerson?: Maybe<PersonOptionInput>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  free?: Maybe<Scalars['Boolean']>;
  parkingBus?: Maybe<Scalars['Boolean']>;
  numberWineTasted?: Maybe<Scalars['Int']>;
};

export type EditCellarPencilInput = {
  name?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  sameAddressAsWineEntity?: Maybe<Scalars['Boolean']>;
};

export type EditCellarTabInput = {
  name?: Maybe<Scalars['String']>;
  sameAddressAsWineEntity?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressInput>;
  images?: Maybe<Array<Maybe<ImgInput>>>;
  surfaceArea?: Maybe<Scalars['Int']>;
  airConditioning?: Maybe<Scalars['Boolean']>;
  temperature?: Maybe<Scalars['Int']>;
  description?: Maybe<LngStringInput>;
  vatThermoregulation?: Maybe<Array<Maybe<Scalars['String']>>>;
  vatCirculation?: Maybe<Scalars['String']>;
  vatContainers?: Maybe<Array<Maybe<VatContainerInput>>>;
  ageingContainers?: Maybe<Array<Maybe<VatContainerInput>>>;
  isVathouse?: Maybe<Scalars['Boolean']>;
  isConditioning?: Maybe<Scalars['Boolean']>;
  isAgeing?: Maybe<Scalars['Boolean']>;
  conditioningActivities?: Maybe<Array<Maybe<Scalars['String']>>>;
  numberOfConditioningChains?: Maybe<Scalars['Int']>;
  conditioningCapacity?: Maybe<Scalars['Int']>;
  isStorage?: Maybe<Scalars['Boolean']>;
  storageCapacity?: Maybe<Scalars['Int']>;
  storageCapacityUnit?: Maybe<Scalars['String']>;
};

export type EditContactInput = {
  contactPhoneNumber?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
};

export type EditEstateInput = {
  totalSurfaceArea?: Maybe<Scalars['Float']>;
  description?: Maybe<LngStringInput>;
  citation?: Maybe<LngStringInput>;
  author?: Maybe<PersonOptionInput>;
  images?: Maybe<Array<Maybe<ImgInput>>>;
  promotionBodies?: Maybe<Array<Maybe<Scalars['String']>>>;
  commercialisationTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EditHiddenSurfacesInput = {
  hiddenSurfaces?: Maybe<Array<Maybe<HiddenSurfaceInput>>>;
};

export type EditJournalistAccountInput = {
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  isFeretAddress?: Maybe<Scalars['Boolean']>;
  paymentInformation?: Maybe<PaymentInformationInput>;
  shippingAddress?: Maybe<ShippingAddressInput>;
};

export type EditReward = {
  competitionName?: Maybe<Scalars['String']>;
  rewardName?: Maybe<Scalars['String']>;
  competitionYear?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  permission?: Maybe<Scalars['Boolean']>;
  rewardId?: Maybe<Scalars['ID']>;
  logo?: Maybe<ImgInput>;
  sortIndex?: Maybe<Scalars['Int']>;
};

export type EditRewardsInput = {
  rewards?: Maybe<Array<Maybe<EditReward>>>;
  scores?: Maybe<Array<Maybe<EditScore>>>;
};

export type EditScore = {
  id?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  scoreMax?: Maybe<Scalars['Float']>;
  scoreUrl?: Maybe<Scalars['String']>;
  tastingDate?: Maybe<Scalars['Date']>;
  logo?: Maybe<ImgInput>;
  hidden?: Maybe<Scalars['Boolean']>;
  permission?: Maybe<Scalars['Boolean']>;
  criticName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  tastingComment?: Maybe<LngStringInput>;
  isFeret?: Maybe<Scalars['Boolean']>;
  sortIndex?: Maybe<Scalars['Int']>;
  tastingRequestId?: Maybe<Scalars['String']>;
  forceVisibility?: Maybe<Scalars['Boolean']>;
};

export type EditServicesOfferByJournalistInput = {
  areas?: Maybe<Array<Maybe<Scalars['String']>>>;
  prerequisites?: Maybe<LngStringInput>;
  maxTasting?: Maybe<Scalars['Int']>;
  delay?: Maybe<Scalars['Int']>;
  video?: Maybe<Scalars['Boolean']>;
  demoVideoUrl?: Maybe<Scalars['String']>;
};

export type EditTeamGeneralInput = {
  teamDescription?: Maybe<LngStringInput>;
  image?: Maybe<Array<Maybe<ImgInput>>>;
  members?: Maybe<Array<Maybe<GeneralTeamMemberInput>>>;
};

export type EditTeamMemberInput = {
  kind?: Maybe<PersonKind>;
  roleComplement?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  visibility?: Maybe<Scalars['Boolean']>;
  hidden?: Maybe<Scalars['Boolean']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  vip?: Maybe<Scalars['Boolean']>;
  description?: Maybe<LngStringInput>;
  organizationName?: Maybe<Scalars['String']>;
  organizationStatus?: Maybe<Scalars['String']>;
  proFunctions?: Maybe<Array<Maybe<Scalars['String']>>>;
  feminineProFunction?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<Maybe<ImgInput>>>;
};

export type EntityType = {
  __typename?: 'EntityType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type EnumType = {
  __typename?: 'EnumType';
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  valueLng?: Maybe<LngString>;
  status?: Maybe<Scalars['String']>;
  conditionIndex?: Maybe<Scalars['String']>;
  foodCategory?: Maybe<Scalars['String']>;
  sortingIndex?: Maybe<Scalars['String']>;
  wineColor?: Maybe<Scalars['String']>;
};

export type EstateComplementaryInfoInput = {
  promotionBodies?: Maybe<Array<Maybe<Scalars['String']>>>;
  commercialisationTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EstateFrontScreen = {
  __typename?: 'EstateFrontScreen';
  wineEntityId?: Maybe<Scalars['ID']>;
  subscriptionName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  hasBadge?: Maybe<Scalars['Boolean']>;
  mainAppellation?: Maybe<Scalars['String']>;
  classifications?: Maybe<Array<Maybe<Classification>>>;
  estateSurfaceArea?: Maybe<Scalars['Float']>;
  bgImage?: Maybe<Img>;
  description?: Maybe<Scalars['String']>;
  truncatedDescription?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Coordinates>;
  socialMedias?: Maybe<Array<Maybe<SocialMediaFront>>>;
  images?: Maybe<Array<Maybe<Img>>>;
  cellars?: Maybe<Array<Maybe<CellarFront>>>;
  logos?: Maybe<Array<Maybe<Img>>>;
  region?: Maybe<Scalars['String']>;
  subregion?: Maybe<Scalars['String']>;
  subregionImage?: Maybe<Img>;
  vineSurfaceArea?: Maybe<Scalars['Float']>;
  commune?: Maybe<Scalars['String']>;
  commercialisationTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  promotionBodies?: Maybe<Array<Maybe<Scalars['String']>>>;
  certifications?: Maybe<Array<Maybe<CertificationFront>>>;
  citation?: Maybe<Scalars['String']>;
  citationAuthor?: Maybe<PersonOption>;
  teamImage?: Maybe<Img>;
  teamDescription?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Maybe<TeamMemberFront>>>;
  owners?: Maybe<Array<Maybe<TeamMemberFront>>>;
  operators?: Maybe<Array<Maybe<TeamMemberFront>>>;
  firstWines?: Maybe<Array<Maybe<WineFront>>>;
  secondWines?: Maybe<Array<Maybe<WineFront>>>;
  otherWines?: Maybe<Array<Maybe<WineFront>>>;
  activities?: Maybe<Array<Maybe<ActivityFront>>>;
  address?: Maybe<Address>;
  telephoneNumber?: Maybe<Scalars['String']>;
  hasVineyardInformation?: Maybe<Scalars['Boolean']>;
  displayOpeningHours?: Maybe<Scalars['Boolean']>;
  openingHours?: Maybe<Array<Maybe<OpeningHour>>>;
  isOrphan?: Maybe<Scalars['Boolean']>;
};

export type EstateScreen = {
  __typename?: 'EstateScreen';
  wineEntityName?: Maybe<Scalars['String']>;
  defaultBgImage?: Maybe<Img>;
  totalSurfaceArea?: Maybe<Scalars['Float']>;
  vineSurfaceArea?: Maybe<Scalars['Float']>;
  description?: Maybe<LngString>;
  citation?: Maybe<LngString>;
  author?: Maybe<PersonOption>;
  images?: Maybe<Array<Maybe<Img>>>;
  promotionBodies?: Maybe<Array<Maybe<Scalars['String']>>>;
  commercialisationTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorOptions?: Maybe<Array<Maybe<PersonOption>>>;
  info_19e?: Maybe<Scalars['String']>;
};

export type ExplorerKeywords = {
  __typename?: 'ExplorerKeywords';
  label?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ExportData = {
  __typename?: 'ExportData';
  file?: Maybe<Document>;
  exportDate?: Maybe<Scalars['Date']>;
};

export type ExternalServiceType = {
  __typename?: 'ExternalServiceType';
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  wineEntityId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  interview?: Maybe<Scalars['Boolean']>;
  numberOfPackshots?: Maybe<Scalars['Int']>;
  photoReport?: Maybe<Scalars['Boolean']>;
  standaloneStatus?: Maybe<Scalars['String']>;
  webAddress?: Maybe<Scalars['String']>;
};

export type FtqrCode = {
  __typename?: 'FTQRCode';
  id?: Maybe<Scalars['String']>;
  show?: Maybe<Scalars['Boolean']>;
  disabled?: Maybe<Scalars['Boolean']>;
  imageUrl?: Maybe<Scalars['String']>;
};

export type FeretContactDiffMetricsType = {
  __typename?: 'FeretContactDiffMetricsType';
  name?: Maybe<Scalars['String']>;
  preview?: Maybe<Img>;
  vintage?: Maybe<Scalars['String']>;
  wineName?: Maybe<Scalars['String']>;
  estateName?: Maybe<Scalars['String']>;
  contactMetrics?: Maybe<FtContactMetricsType>;
  allMetrics?: Maybe<FtContactMetricsType>;
  webMetrics?: Maybe<FtWebMetricsType>;
  mainAppellation?: Maybe<Scalars['String']>;
  lastModification?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<FeretContactDiffType>>>;
};

export type FeretContactDiffType = {
  __typename?: 'FeretContactDiffType';
  contact?: Maybe<FeretContactType>;
  ftDiffusion?: Maybe<FtDiffusionType>;
  ftRequest?: Maybe<FtRequestType>;
  ftDownload?: Maybe<FtDownloadType>;
  ftOpened?: Maybe<Array<Maybe<FtOpenedType>>>;
};

export type FeretContactInput = {
  _id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  lastDiffusion?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<DocumentInput>>>;
  diffusionLists?: Maybe<Array<Maybe<DiffusionListSimpleInput>>>;
  list?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type FeretContactType = {
  __typename?: 'FeretContactType';
  _id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  lastDiffusion?: Maybe<Scalars['String']>;
  wineEntityId?: Maybe<Scalars['String']>;
  diffusionLists?: Maybe<Array<Maybe<DiffusionListInContact>>>;
  telephone?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Document>>>;
  invalidCategory?: Maybe<Scalars['String']>;
  isValidEmail?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  list?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  isEmailValid?: Maybe<Scalars['Boolean']>;
  isDuplicate?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  ftDiffusions?: Maybe<Array<Maybe<FtDiffusionType>>>;
  hasDiffusion?: Maybe<Scalars['Boolean']>;
};

export type FeretNotification = {
  __typename?: 'FeretNotification';
  notificationId?: Maybe<Scalars['ID']>;
  wineEntityId?: Maybe<Scalars['ID']>;
  category?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['Date']>;
  dateRead?: Maybe<Scalars['Date']>;
  text?: Maybe<Scalars['String']>;
  extraContent?: Maybe<Scalars['JSON']>;
  deleted?: Maybe<Scalars['Boolean']>;
  action?: Maybe<Scalars['String']>;
  actionArgs?: Maybe<Scalars['JSON']>;
};

export type FileUrl = {
  __typename?: 'FileUrl';
  url?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type Flags = {
  __typename?: 'Flags';
  roundedFlag?: Maybe<FtImg>;
  squaredFlag?: Maybe<FtImg>;
};

export type FontFilesInput = {
  regular?: Maybe<DocumentInput>;
  italic?: Maybe<DocumentInput>;
};

export type FontFilesType = {
  __typename?: 'FontFilesType';
  regular?: Maybe<Scalars['String']>;
  italic?: Maybe<Scalars['String']>;
};

export type FoodPairing = {
  __typename?: 'FoodPairing';
  food?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};

export type FoodPairingGroup = {
  __typename?: 'FoodPairingGroup';
  foods?: Maybe<Array<Maybe<Scalars['String']>>>;
  category?: Maybe<Scalars['String']>;
  icon?: Maybe<Img>;
};

export type FoodPairingInput = {
  food?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};

export type FormationOrganism = {
  __typename?: 'FormationOrganism';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  about?: Maybe<LngString>;
  formations?: Maybe<LngString>;
  rhythm?: Maybe<LngString>;
  link?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  socialMedias?: Maybe<Array<Maybe<SocialMediaFront>>>;
  logo?: Maybe<Document>;
};

export type FormationOrganismFront = {
  __typename?: 'FormationOrganismFront';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  formations?: Maybe<Scalars['String']>;
  rhythm?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  socialMedias?: Maybe<Array<Maybe<SocialMediaFront>>>;
  logo?: Maybe<Document>;
};

export type FormationOrganismInput = {
  name?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  formations?: Maybe<Scalars['String']>;
  rhythm?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  socialMedias?: Maybe<Array<Maybe<SocialMediaInput>>>;
  logo?: Maybe<DocumentInput>;
};

export type FormationOrganismSample = {
  __typename?: 'FormationOrganismSample';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Document>;
};

export type FrontClimatology = {
  __typename?: 'FrontClimatology';
  season?: Maybe<Season>;
  logo?: Maybe<Img>;
  text?: Maybe<Scalars['String']>;
};

export type FrontDetailedHarvest = {
  __typename?: 'FrontDetailedHarvest';
  method?: Maybe<Scalars['String']>;
  methodFr?: Maybe<Scalars['String']>;
  startAt?: Maybe<VagueDate>;
  endAt?: Maybe<VagueDate>;
  icon?: Maybe<Img>;
  grapeVariety?: Maybe<Scalars['String']>;
};

export type FrontHarvest = {
  __typename?: 'FrontHarvest';
  method?: Maybe<Scalars['String']>;
  startAt?: Maybe<VagueDate>;
  endAt?: Maybe<VagueDate>;
  icon?: Maybe<Img>;
};

export type FrontLabel = {
  __typename?: 'FrontLabel';
  certificationId?: Maybe<Scalars['ID']>;
  object?: Maybe<Scalars['String']>;
  logo?: Maybe<Img>;
  expiryDate?: Maybe<Scalars['Date']>;
  certificationBody?: Maybe<Scalars['String']>;
  isInConversion?: Maybe<Scalars['Boolean']>;
};

export type FrontPackaging = {
  __typename?: 'FrontPackaging';
  centilisation?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['Int']>;
  conditionningMode?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  icon?: Maybe<Img>;
  gtin?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
};

export type FrontQrcodeRedirect = {
  __typename?: 'FrontQrcodeRedirect';
  wineId?: Maybe<Scalars['String']>;
  vintageId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
};

export type FrontReward = {
  __typename?: 'FrontReward';
  rewardId?: Maybe<Scalars['ID']>;
  image?: Maybe<Img>;
  year?: Maybe<Scalars['Int']>;
  rewardName?: Maybe<Scalars['String']>;
  competitionName?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type FrontScore = {
  __typename?: 'FrontScore';
  _id?: Maybe<Scalars['ID']>;
  score?: Maybe<Scalars['String']>;
  scoreMax?: Maybe<Scalars['Float']>;
  scoreUrl?: Maybe<Scalars['String']>;
  tastingDate?: Maybe<Scalars['Date']>;
  criticName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  tastingComment?: Maybe<Scalars['String']>;
  vintageYear?: Maybe<Scalars['Int']>;
};

export type FrontVintage = {
  __typename?: 'FrontVintage';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  subscriptionName?: Maybe<Scalars['String']>;
  wineId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  cuvee?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  rankFr?: Maybe<Scalars['String']>;
  wineEntityName?: Maybe<Scalars['String']>;
  wineEntityId?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  noSulphites?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  sweetness?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  ftId?: Maybe<Scalars['ID']>;
  wineAssembly?: Maybe<Array<Maybe<SimpleGrapeVarietyPart>>>;
  climatology?: Maybe<Array<Maybe<FrontClimatology>>>;
  bySeasonClimatology?: Maybe<Scalars['Boolean']>;
  alcoholicStrength?: Maybe<Scalars['Float']>;
  residualSugar?: Maybe<Scalars['Float']>;
  totalAcidity?: Maybe<Scalars['Float']>;
  vintageList?: Maybe<Array<Maybe<SimpleVintage>>>;
  vintageDescription?: Maybe<Scalars['String']>;
  truncatedVintageDescription?: Maybe<Scalars['String']>;
  bottleImage?: Maybe<Img>;
  bottleLabel?: Maybe<Img>;
  backgroundBottleImage?: Maybe<Img>;
  backgroundBottleColor?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Img>;
  certificationList?: Maybe<Array<Maybe<SimpleLabel>>>;
  commercialisation?: Maybe<Array<Maybe<Scalars['String']>>>;
  allergens?: Maybe<Array<Maybe<Scalars['String']>>>;
  tabs?: Maybe<Array<Maybe<Scalars['String']>>>;
  nonOGM?: Maybe<Scalars['Boolean']>;
  productPageUrl?: Maybe<Scalars['String']>;
  showDetailedHarvest?: Maybe<Scalars['Boolean']>;
  scores?: Maybe<Array<Maybe<FrontScore>>>;
  rewards?: Maybe<Array<Maybe<FrontReward>>>;
  classification?: Maybe<Scalars['String']>;
};

export type FrontVintageAdvice = {
  __typename?: 'FrontVintageAdvice';
  wineLabel?: Maybe<Scalars['String']>;
  wineSweetness?: Maybe<Scalars['String']>;
  wineColor?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
  serviceTemperature?: Maybe<Scalars['Int']>;
  apogee?: Maybe<Array<Maybe<Scalars['Int']>>>;
  ageing?: Maybe<Array<Maybe<Scalars['Int']>>>;
  treatmentBeforeService?: Maybe<Scalars['String']>;
  decantingDuration?: Maybe<Scalars['Int']>;
  foodPairingGroups?: Maybe<Array<Maybe<FoodPairingGroup>>>;
  appearanceColor?: Maybe<Scalars['String']>;
  appearanceIntensity?: Maybe<Scalars['Int']>;
  appearanceBrilliance?: Maybe<Scalars['String']>;
  appearanceLimpidity?: Maybe<Scalars['String']>;
  appearanceTears?: Maybe<Scalars['String']>;
  noseAromas?: Maybe<Array<Maybe<Scalars['String']>>>;
  noseIntensity?: Maybe<Scalars['Int']>;
  palateAttack?: Maybe<Scalars['String']>;
  palateIntensity?: Maybe<Scalars['Int']>;
  palateLength?: Maybe<Scalars['String']>;
  palateAcidity?: Maybe<Scalars['String']>;
  palateTannins?: Maybe<Scalars['String']>;
  aromaValues?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  aromaAnimal?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaBalsamic?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaConfectionery?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaEmpyreumatic?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaFermented?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaFloral?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaFruity?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaMineral?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaOaky?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaSpicy?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaVegetal?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FrontVintageAgeing = {
  __typename?: 'FrontVintageAgeing';
  barrelMaturation?: Maybe<Scalars['Boolean']>;
  barrelMaturationDurationValue?: Maybe<Scalars['Int']>;
  barrelMaturationDurationUnit?: Maybe<Scalars['String']>;
  newBarrelPercentage?: Maybe<Scalars['Int']>;
  toasting?: Maybe<Scalars['Int']>;
  frenchOak?: Maybe<Scalars['Boolean']>;
  barrelMakers?: Maybe<Array<Maybe<BarrelMakerPart>>>;
  otherMaturations?: Maybe<Array<Maybe<OtherMaturation>>>;
  lees?: Maybe<Scalars['Boolean']>;
  batonnage?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
};

export type FrontVintageMarketing = {
  __typename?: 'FrontVintageMarketing';
  exportPercentage?: Maybe<Scalars['Int']>;
  directPercentage?: Maybe<Scalars['Int']>;
  indirectPercentage?: Maybe<Scalars['Int']>;
  exportCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  packagings?: Maybe<Array<Maybe<FrontPackaging>>>;
};

export type FrontVintageNutrition = {
  __typename?: 'FrontVintageNutrition';
  nutritionalAnalysis?: Maybe<Array<Maybe<Document>>>;
  fats?: Maybe<Scalars['Float']>;
  saturatedFattyAcids?: Maybe<Scalars['Float']>;
  glucides?: Maybe<Scalars['Float']>;
  sugars?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  salt?: Maybe<Scalars['Float']>;
  energy?: Maybe<Scalars['Float']>;
  energyType?: Maybe<Scalars['String']>;
  alcoholicStrength?: Maybe<Scalars['Float']>;
  residualSugar?: Maybe<Scalars['Float']>;
  totalAcidity?: Maybe<Scalars['Float']>;
  auto?: Maybe<Scalars['Boolean']>;
  qrnutri?: Maybe<Document>;
  ingredients?: Maybe<Array<Maybe<IngredientPart>>>;
};

export type FrontVintageProduction = {
  __typename?: 'FrontVintageProduction';
  finingTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  filtrationTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  bottlingDate?: Maybe<Scalars['Date']>;
  estateBottling?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  bottlesVolume?: Maybe<Scalars['Int']>;
  yield?: Maybe<Scalars['Int']>;
  production?: Maybe<Scalars['Int']>;
  corkingMode?: Maybe<Scalars['String']>;
  waxedBottle?: Maybe<Scalars['Boolean']>;
  bottler?: Maybe<BottlerOption>;
  bottlerCommuneINSEEcode?: Maybe<Scalars['String']>;
  bottlerCommune?: Maybe<Scalars['String']>;
  bottlerPostalCode?: Maybe<Scalars['String']>;
};

export type FrontVintageQrcode = {
  __typename?: 'FrontVintageQrcode';
  name?: Maybe<Scalars['String']>;
  wineId?: Maybe<Scalars['String']>;
  wineShortId?: Maybe<Scalars['String']>;
  wineEntityShortId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  cuvee?: Maybe<Scalars['String']>;
  wineEntityName?: Maybe<Scalars['String']>;
  wineEntityId?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  nutritionalAnalysis?: Maybe<Array<Maybe<Document>>>;
  fats?: Maybe<Scalars['Float']>;
  saturatedFattyAcids?: Maybe<Scalars['Float']>;
  glucides?: Maybe<Scalars['Float']>;
  sugars?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  salt?: Maybe<Scalars['Float']>;
  energy?: Maybe<Scalars['Float']>;
  energyType?: Maybe<Scalars['String']>;
  alcoholicStrength?: Maybe<Scalars['Float']>;
  residualSugar?: Maybe<Scalars['Float']>;
  totalAcidity?: Maybe<Scalars['Float']>;
  auto?: Maybe<Scalars['Boolean']>;
  qrnutri?: Maybe<Document>;
  ingredients?: Maybe<Array<Maybe<IngredientPart>>>;
};

export type FrontVintageViticulture = {
  __typename?: 'FrontVintageViticulture';
  soil?: Maybe<Array<Maybe<Scalars['String']>>>;
  subsoil?: Maybe<Array<Maybe<Scalars['String']>>>;
  greenOperations?: Maybe<Array<Maybe<GreenOperation>>>;
  simplePhenologicalStages?: Maybe<Array<Maybe<SimplePhenologicalStage>>>;
  detailedPhenologicalStages?: Maybe<Array<Maybe<DetailedPhenologicalStage>>>;
  viticultureDetails?: Maybe<Scalars['String']>;
  showDefaultViticultureDetails?: Maybe<Scalars['Boolean']>;
};

export type FrontVintageWineMaking = {
  __typename?: 'FrontVintageWineMaking';
  parcelBasedVinification?: Maybe<Scalars['Boolean']>;
  consultingEnologist?: Maybe<PersonOption>;
  sortingMethod?: Maybe<Array<Maybe<Scalars['String']>>>;
  destemming?: Maybe<Scalars['String']>;
  destemmingFr?: Maybe<Scalars['String']>;
  treading?: Maybe<Scalars['String']>;
  treadingFr?: Maybe<Scalars['String']>;
  vatting?: Maybe<Array<Maybe<Scalars['String']>>>;
  extractionMode?: Maybe<Array<Maybe<Scalars['String']>>>;
  macerations?: Maybe<Array<Maybe<Maceration>>>;
  pressType?: Maybe<Array<Maybe<Scalars['String']>>>;
  yeasting?: Maybe<Scalars['String']>;
  vinificationPlace?: Maybe<Scalars['String']>;
  vinificationCoopName?: Maybe<Scalars['String']>;
  alcoholicFermentationDuration?: Maybe<Scalars['Float']>;
  alcoholicFermentationDurationUnit?: Maybe<Scalars['String']>;
  alcoholicFermentationTemperature?: Maybe<Scalars['Int']>;
  malolacticFermentation?: Maybe<Scalars['Boolean']>;
  malolacticFermentationDuration?: Maybe<Scalars['Float']>;
  malolacticFermentationDurationUnit?: Maybe<Scalars['String']>;
  malolacticFermentationTemperature?: Maybe<Scalars['Int']>;
  malolacticBacteria?: Maybe<Scalars['String']>;
  vinificationDetails?: Maybe<Scalars['String']>;
};

export type FrontWineList = {
  __typename?: 'FrontWineList';
  firstWines?: Maybe<Array<Maybe<WineFront>>>;
  secondWines?: Maybe<Array<Maybe<WineFront>>>;
  otherWines?: Maybe<Array<Maybe<WineFront>>>;
};

export type FrontWinePresentation = {
  __typename?: 'FrontWinePresentation';
  wineId?: Maybe<Scalars['ID']>;
  wineEntityName?: Maybe<Scalars['String']>;
  wineEntityId?: Maybe<Scalars['ID']>;
  subscriptionName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  rankFr?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  sweetness?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  wineAssembly?: Maybe<Array<Maybe<SimpleGrapeVarietyPart>>>;
  commercialisation?: Maybe<Array<Maybe<Scalars['String']>>>;
  vintageList?: Maybe<Array<Maybe<SimpleVintage>>>;
  nonOGM?: Maybe<Scalars['Boolean']>;
  noSulphites?: Maybe<Scalars['Boolean']>;
  harvestMethod?: Maybe<Array<Maybe<Scalars['String']>>>;
  soil?: Maybe<Array<Maybe<Scalars['String']>>>;
  subsoil?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  truncateDescription?: Maybe<Scalars['String']>;
  bottleImage?: Maybe<Img>;
  backgroundBottleImage?: Maybe<Img>;
  backgroundBottleColor?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Img>;
  tabs?: Maybe<Array<Maybe<Scalars['String']>>>;
  scores?: Maybe<Array<Maybe<FrontScore>>>;
  averageAge?: Maybe<Scalars['String']>;
  totalSurfaceArea?: Maybe<Scalars['String']>;
  averageDensity?: Maybe<Scalars['String']>;
};

export type FtActivity = {
  __typename?: 'FtActivity';
  id?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['String']>;
  show?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  image?: Maybe<Array<Maybe<FtImg>>>;
  type?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<FtImg>>>;
  groupSize?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  durationUnit?: Maybe<Scalars['String']>;
  reservationNeeded?: Maybe<ReservationType>;
  free?: Maybe<Scalars['String']>;
  priceDetails?: Maybe<Scalars['String']>;
};

export type FtAddress = {
  __typename?: 'FtAddress';
  address?: Maybe<Scalars['String']>;
  complementaryAddress?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  commune?: Maybe<Scalars['String']>;
};

export type FtAwards = {
  __typename?: 'FtAwards';
  label?: Maybe<Scalars['String']>;
  show?: Maybe<Scalars['Boolean']>;
  img?: Maybe<FtImg>;
  year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  competitionName?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  criticName?: Maybe<Scalars['String']>;
  tastingComment?: Maybe<Scalars['String']>;
};

export type FtBottlingInformations = {
  __typename?: 'FtBottlingInformations';
  bottlingTitle?: Maybe<Scalars['String']>;
  estateBottling?: Maybe<Scalars['String']>;
  bottlingDate?: Maybe<Scalars['String']>;
};

export type FtByStatus = {
  __typename?: 'FtByStatus';
  draft?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  online?: Maybe<Scalars['Int']>;
  inactive?: Maybe<Scalars['Int']>;
};

export type FtCellar = {
  __typename?: 'FtCellar';
  id?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['String']>;
  show?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<FtImg>>>;
  surfaceArea?: Maybe<Scalars['Int']>;
  functions?: Maybe<Array<Maybe<CellarFunctions>>>;
};

export type FtConsultingOenologist = {
  __typename?: 'FtConsultingOenologist';
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type FtContactMetricsType = {
  __typename?: 'FtContactMetricsType';
  lists?: Maybe<Scalars['Int']>;
  contactNumber?: Maybe<Scalars['Int']>;
  opened?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['Int']>;
  downloaded?: Maybe<Scalars['Int']>;
  locales?: Maybe<LocalesType>;
};

export type FtCreatorParams = {
  __typename?: 'FtCreatorParams';
  token?: Maybe<Scalars['String']>;
  ftParametersId?: Maybe<Scalars['ID']>;
};

export type FtDiffusionType = {
  __typename?: 'FtDiffusionType';
  _id?: Maybe<Scalars['ID']>;
  isOpen?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  wineName?: Maybe<Scalars['String']>;
  estateName?: Maybe<Scalars['String']>;
  vintage?: Maybe<Scalars['String']>;
  mainAppellation?: Maybe<Scalars['String']>;
  bottleImage?: Maybe<Img>;
  isEmailValid?: Maybe<Scalars['Boolean']>;
  ftId?: Maybe<Scalars['ID']>;
  lastUpdate?: Maybe<Scalars['Date']>;
  languages?: Maybe<LanguagesBool>;
  ftDownload?: Maybe<FtDownloadType>;
  diffusionListId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  isCreated?: Maybe<Scalars['Boolean']>;
  isUpdated?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  preview?: Maybe<Img>;
  userId?: Maybe<Scalars['ID']>;
  wineEntityId?: Maybe<Scalars['ID']>;
  wineEntityShortId?: Maybe<Scalars['String']>;
  contactCount?: Maybe<Scalars['Int']>;
  webCount?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
  diffusionListNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FtDownloadType = {
  __typename?: 'FtDownloadType';
  fr?: Maybe<LngCount>;
  en?: Maybe<LngCount>;
  de?: Maybe<LngCount>;
  es?: Maybe<LngCount>;
  it?: Maybe<LngCount>;
  nl?: Maybe<LngCount>;
  zh?: Maybe<LngCount>;
  ja?: Maybe<LngCount>;
  userId?: Maybe<Scalars['String']>;
  ftId?: Maybe<Scalars['ID']>;
};

export type FtGlobalClimatology = {
  __typename?: 'FtGlobalClimatology';
  description?: Maybe<Scalars['String']>;
};

export type FtHarvestByGrapeVariety = {
  __typename?: 'FtHarvestByGrapeVariety';
  id?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['String']>;
  show?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<DetailsHarvest>>>;
};

export type FtImg = {
  __typename?: 'FtImg';
  data?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['Boolean']>;
  backgroundImage?: Maybe<Scalars['Boolean']>;
  caption?: Maybe<Scalars['String']>;
  bucketName?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
};

export type FtItem = {
  __typename?: 'FtItem';
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  show?: Maybe<Scalars['Boolean']>;
  internalId?: Maybe<Scalars['String']>;
  extraDescription?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
};

export type FtLanguageIndicator = {
  __typename?: 'FtLanguageIndicator';
  countryCode?: Maybe<Scalars['String']>;
  flags?: Maybe<Flags>;
};

export type FtMaceration = {
  __typename?: 'FtMaceration';
  internalId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  durationUnit?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['Float']>;
};

export type FtModel = {
  __typename?: 'FtModel';
  _id?: Maybe<Scalars['ID']>;
  pagePreviews?: Maybe<Array<Maybe<Img>>>;
  category?: Maybe<Scalars['String']>;
  missingComponents?: Maybe<Array<Maybe<MissingComponentType>>>;
  essentialRestriction?: Maybe<Scalars['Boolean']>;
};

export type FtOpenedType = {
  __typename?: 'FtOpenedType';
  userId?: Maybe<Scalars['String']>;
  ftId?: Maybe<Scalars['ID']>;
  version?: Maybe<Scalars['String']>;
  wineId?: Maybe<Scalars['ID']>;
  vintageId?: Maybe<Scalars['ID']>;
  wineEntityId?: Maybe<Scalars['ID']>;
  ftDiffusionId?: Maybe<Scalars['ID']>;
};

export type FtOtherMaturation = {
  __typename?: 'FtOtherMaturation';
  containerType?: Maybe<Scalars['String']>;
  durationValue?: Maybe<Scalars['Int']>;
  durationUnit?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
};

export type FtParameterMetrics = {
  __typename?: 'FtParameterMetrics';
  total?: Maybe<Scalars['Int']>;
  ftByStatus?: Maybe<FtByStatus>;
};

export type FtPersonSimple = {
  __typename?: 'FtPersonSimple';
  title?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
};

export type FtQuote = {
  __typename?: 'FtQuote';
  quote?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  selectOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  selectValue?: Maybe<Scalars['String']>;
  quotation?: Maybe<Scalars['Boolean']>;
};

export type FtRequestType = {
  __typename?: 'FtRequestType';
  _id?: Maybe<Scalars['ID']>;
  estateName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  lastUpdate?: Maybe<Scalars['Date']>;
  isEmailValid?: Maybe<Scalars['Boolean']>;
};

export type FtScore = {
  __typename?: 'FtScore';
  img?: Maybe<FtImg>;
  tastingDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  scoreMax?: Maybe<Scalars['Int']>;
  criticName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
};

export type FtSimpleGrapeVarietyPart = {
  __typename?: 'FtSimpleGrapeVarietyPart';
  grapeVarietyName?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
};

export type FtSocialMedia = {
  __typename?: 'FtSocialMedia';
  platform?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type FtTastingDescription = {
  __typename?: 'FtTastingDescription';
  wineName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type FtTeamDescription = {
  __typename?: 'FtTeamDescription';
  photo?: Maybe<FtImg>;
  description?: Maybe<Scalars['String']>;
};

export type FtTeamMember = {
  __typename?: 'FtTeamMember';
  personId?: Maybe<Scalars['ID']>;
  civility?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  proFunctions?: Maybe<Array<Maybe<Scalars['String']>>>;
  selectOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  vip?: Maybe<Scalars['Boolean']>;
  image?: Maybe<FtImg>;
  description?: Maybe<Scalars['String']>;
};

export type FtTeamPresentation = {
  __typename?: 'FtTeamPresentation';
  image?: Maybe<Img>;
  teamDescription?: Maybe<Scalars['String']>;
};

export type FtVineyardType = {
  __typename?: 'FtVineyardType';
  soil?: Maybe<Array<Maybe<Scalars['String']>>>;
  subsoil?: Maybe<Array<Maybe<Scalars['String']>>>;
  soilDescription?: Maybe<Scalars['String']>;
  grassGrowing?: Maybe<Array<Maybe<Scalars['String']>>>;
  grassGrowingIsTotal?: Maybe<Scalars['Boolean']>;
  grassGrowingIsTemporary?: Maybe<Scalars['Boolean']>;
  fertilizers?: Maybe<Array<Maybe<Scalars['String']>>>;
  worksUnderTheRow?: Maybe<Array<Maybe<Scalars['String']>>>;
  soilWorkDescription?: Maybe<Scalars['String']>;
  pruningMethod?: Maybe<Array<Maybe<Scalars['String']>>>;
  greenProcedures?: Maybe<Array<Maybe<Scalars['String']>>>;
  vineWorkDescription?: Maybe<Scalars['String']>;
  sexualConfusion?: Maybe<Scalars['String']>;
  insectWorkDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  otherCultures?: Maybe<Scalars['String']>;
  averageDensity?: Maybe<Scalars['Int']>;
  averageAge?: Maybe<Scalars['Int']>;
  totalSurfaceArea?: Maybe<Scalars['Float']>;
};

export type FtVintageFoodWinePairing = {
  __typename?: 'FtVintageFoodWinePairing';
  foodPairings?: Maybe<Scalars['String']>;
};

export type FtVintageViticulture = {
  __typename?: 'FtVintageViticulture';
  viticultureDetails?: Maybe<Scalars['String']>;
};

export type FtWebMetricsType = {
  __typename?: 'FtWebMetricsType';
  webNumber?: Maybe<Scalars['Int']>;
  opened?: Maybe<Scalars['Int']>;
  downloaded?: Maybe<Scalars['Int']>;
  locales?: Maybe<LocalesType>;
};

export type FtWineBreedingDescription = {
  __typename?: 'FtWineBreedingDescription';
  breedingTechnique?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type FtpDiffusion = {
  __typename?: 'FtpDiffusion';
  _id?: Maybe<Scalars['ID']>;
  language?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
  remotePath?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  ftId?: Maybe<Scalars['String']>;
  wineId?: Maybe<Scalars['String']>;
  vintageId?: Maybe<Scalars['String']>;
  wineEntityId?: Maybe<Scalars['String']>;
  lastSync?: Maybe<Scalars['Date']>;
  autoSync?: Maybe<Scalars['Boolean']>;
  protocole?: Maybe<Scalars['String']>;
  succeed?: Maybe<Scalars['Boolean']>;
};

export type FtpDiffusionLog = {
  __typename?: 'FtpDiffusionLog';
  text?: Maybe<Scalars['String']>;
  logName?: Maybe<Scalars['String']>;
};

export type FullActivityFront = {
  __typename?: 'FullActivityFront';
  id?: Maybe<Scalars['ID']>;
  wineEntityShortId?: Maybe<Scalars['String']>;
  activityShortId?: Maybe<Scalars['String']>;
  parkingBus?: Maybe<Scalars['Boolean']>;
  numberWineTasted?: Maybe<Scalars['Int']>;
  socialMedias?: Maybe<Array<Maybe<SocialMediaFront>>>;
  estateName?: Maybe<Scalars['String']>;
  bgImage?: Maybe<Img>;
  atWineEntity?: Maybe<Scalars['Boolean']>;
  private?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  wineEntityId?: Maybe<Scalars['String']>;
  availableOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  receptionAreas?: Maybe<Array<Maybe<ReceptionArea>>>;
  equipmentProvided?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentDetails?: Maybe<LngString>;
  priceOnEstimation?: Maybe<Scalars['Boolean']>;
  paymentOptionsAccepted?: Maybe<Array<Maybe<Scalars['String']>>>;
  sanitaryMeasures?: Maybe<Array<Maybe<Scalars['String']>>>;
  minRentTime?: Maybe<Scalars['Int']>;
  minRentTimeUnit?: Maybe<Scalars['String']>;
  maxRentTime?: Maybe<Scalars['Int']>;
  maxRentTimeUnit?: Maybe<Scalars['String']>;
  accomodationPossible?: Maybe<Scalars['Boolean']>;
  bedCount?: Maybe<Scalars['Int']>;
  accomodationDetails?: Maybe<LngString>;
  isOrphan?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Img>>>;
  types?: Maybe<Array<Maybe<ActivityType>>>;
  languages?: Maybe<Array<Maybe<Language>>>;
  description?: Maybe<Scalars['String']>;
  groupSize?: Maybe<Array<Maybe<Scalars['Int']>>>;
  accessibleTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  startingAge?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  activePeriods?: Maybe<Array<Maybe<ActivePeriod>>>;
  duration?: Maybe<Scalars['Float']>;
  durationUnit?: Maybe<Scalars['String']>;
  reservationNeeded?: Maybe<Scalars['Boolean']>;
  priceDetails?: Maybe<Array<Maybe<PriceDetail>>>;
  contactPerson?: Maybe<PersonOption>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  free?: Maybe<Scalars['Boolean']>;
};

export type GeneralInformationScreen = {
  __typename?: 'GeneralInformationScreen';
  name?: Maybe<Scalars['String']>;
  siret?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  telephoneCountry?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  faxCountry?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  socialMedia?: Maybe<Array<Maybe<SocialMedia>>>;
  image?: Maybe<Array<Maybe<Img>>>;
  mainAppellation?: Maybe<Scalars['String']>;
  mainCommune?: Maybe<Scalars['String']>;
  displayOpeningHours?: Maybe<Scalars['Boolean']>;
  isUniformOpeningHours?: Maybe<Scalars['Boolean']>;
  openingHours?: Maybe<Array<Maybe<OpeningHour>>>;
  uniformOpeningHours?: Maybe<Array<Maybe<OpeningHour>>>;
  classifications?: Maybe<Array<Maybe<Classification>>>;
};

export type GeneralInformationScreenInput = {
  address?: Maybe<AddressInput>;
  telephoneCountry?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  faxCountry?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  socialMedia?: Maybe<Array<Maybe<SocialMediaInput>>>;
  image?: Maybe<Array<Maybe<ImgInput>>>;
  displayOpeningHours?: Maybe<Scalars['Boolean']>;
  isUniformOpeningHours?: Maybe<Scalars['Boolean']>;
  uniformOpeningHours?: Maybe<Array<Maybe<OpeningHourInput>>>;
  openingHours?: Maybe<Array<Maybe<OpeningHourInput>>>;
  classifications?: Maybe<Array<Maybe<ClassificationInput>>>;
};

export type GeneralTeamMember = {
  __typename?: 'GeneralTeamMember';
  vip?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['ID']>;
  hidden?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  proFunctions?: Maybe<Array<Maybe<Scalars['String']>>>;
  organizationName?: Maybe<Scalars['String']>;
  organizationStatus?: Maybe<Scalars['String']>;
};

export type GeneralTeamMemberInput = {
  personId?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  proFunctions?: Maybe<Array<Maybe<Scalars['String']>>>;
  vip?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  organizationStatus?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type GetFtpDiffusions = {
  __typename?: 'GetFtpDiffusions';
  ft?: Maybe<BocFtParameters>;
  ftpDiffusions?: Maybe<Array<Maybe<FtpDiffusion>>>;
};

export type GetServicesOfferByJournalist = {
  __typename?: 'GetServicesOfferByJournalist';
  areas?: Maybe<Array<Maybe<Scalars['String']>>>;
  prerequisites?: Maybe<LngString>;
  maxTasting?: Maybe<Scalars['Int']>;
  delay?: Maybe<Scalars['Int']>;
  video?: Maybe<Scalars['Boolean']>;
  demoVideoUrl?: Maybe<Scalars['String']>;
};

export type GetVinificationDescription = {
  __typename?: 'GetVinificationDescription';
  description?: Maybe<Scalars['String']>;
};

export type GrapeVarietyInput = {
  varietyDisplayName?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
};

export type GrapeVarietyType = {
  __typename?: 'GrapeVarietyType';
  varietyDisplayName?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
};

export type GraphIndicator = {
  __typename?: 'GraphIndicator';
  name?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  area?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};

export type GreenOperation = {
  __typename?: 'GreenOperation';
  operation?: Maybe<Scalars['String']>;
  startAt?: Maybe<VagueDate>;
  endAt?: Maybe<VagueDate>;
};

export type GreenOperationInput = {
  operation?: Maybe<Scalars['String']>;
  startAt?: Maybe<VagueDateInput>;
  endAt?: Maybe<VagueDateInput>;
};

export type Harvest = {
  __typename?: 'Harvest';
  method?: Maybe<Scalars['String']>;
  startAt?: Maybe<VagueDate>;
  endAt?: Maybe<VagueDate>;
};

export type HarvestInput = {
  method?: Maybe<Scalars['String']>;
  startAt?: Maybe<VagueDateInput>;
  endAt?: Maybe<VagueDateInput>;
};

export type HiddenSurface = {
  __typename?: 'HiddenSurface';
  ageMoyenDesVignes?: Maybe<Scalars['Boolean']>;
  densiteDePlantation?: Maybe<Scalars['Boolean']>;
  communes?: Maybe<Scalars['Boolean']>;
  encepagements?: Maybe<Scalars['Boolean']>;
  vinesCount?: Maybe<Scalars['Boolean']>;
  rootstocks?: Maybe<Scalars['Boolean']>;
  parcelCount?: Maybe<Scalars['Boolean']>;
};

export type HiddenSurfaceFront = {
  __typename?: 'HiddenSurfaceFront';
  ageMoyenDesVignes?: Maybe<Scalars['Boolean']>;
  densiteDePlantation?: Maybe<Scalars['Boolean']>;
  communes?: Maybe<Scalars['Boolean']>;
  encepagements?: Maybe<Scalars['Boolean']>;
  vinesCount?: Maybe<Scalars['Boolean']>;
  rootstocks?: Maybe<Scalars['Boolean']>;
  parcelCount?: Maybe<Scalars['Boolean']>;
};

export type HiddenSurfaceInput = {
  ageMoyenDesVignes?: Maybe<Scalars['Boolean']>;
  densiteDePlantation?: Maybe<Scalars['Boolean']>;
  communes?: Maybe<Scalars['Boolean']>;
  encepagements?: Maybe<Scalars['Boolean']>;
  vinesCount?: Maybe<Scalars['Boolean']>;
  rootstocks?: Maybe<Scalars['Boolean']>;
  parcelCount?: Maybe<Scalars['Boolean']>;
};

export type HomeResult = {
  __typename?: 'HomeResult';
  wineEntityData?: Maybe<WineEntityResult>;
  vintageData?: Maybe<WineResult>;
};

export type IdentityInformation = {
  __typename?: 'IdentityInformation';
  name?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  hasBadge?: Maybe<Scalars['Boolean']>;
  appellation?: Maybe<Scalars['String']>;
  surfaceArea?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  subregion?: Maybe<Scalars['String']>;
  surfaceAreaUnit?: Maybe<Scalars['String']>;
  memberOf?: Maybe<Array<Maybe<Scalars['String']>>>;
  management?: Maybe<Array<Maybe<FtPersonSimple>>>;
  address?: Maybe<FtAddress>;
  commercialization?: Maybe<Array<Maybe<Scalars['String']>>>;
  bgImage?: Maybe<FtImg>;
  images?: Maybe<Array<Maybe<FtImg>>>;
};

export type Image = {
  __typename?: 'Image';
  file?: Maybe<Document>;
};

export type Img = {
  __typename?: 'Img';
  data?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  bucketName?: Maybe<Scalars['String']>;
  twicpicPath?: Maybe<Scalars['String']>;
  twicpicURL?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['Boolean']>;
  backgroundImage?: Maybe<Scalars['Boolean']>;
  hidden?: Maybe<Scalars['Boolean']>;
  caption?: Maybe<Scalars['String']>;
};

export type ImgInput = {
  bucketName?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['Boolean']>;
  backgroundImage?: Maybe<Scalars['Boolean']>;
  caption?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  twicpicURL?: Maybe<Scalars['String']>;
};

export type ImportError = {
  __typename?: 'ImportError';
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ImportResponse = {
  __typename?: 'ImportResponse';
  numAdded?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<ImportRewardsError>>>;
};

export type ImportRewardsError = {
  __typename?: 'ImportRewardsError';
  rewardId?: Maybe<Scalars['String']>;
  allMedalsId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ImportRewardsResponse = {
  __typename?: 'ImportRewardsResponse';
  numRewardsAdded?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<ImportRewardsError>>>;
};

export type Imported = {
  __typename?: 'Imported';
  name?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
};

export type IndicatorDashboard = {
  __typename?: 'IndicatorDashboard';
  surfaceImportCvi?: Maybe<Scalars['Boolean']>;
  wineEntityName?: Maybe<Scalars['String']>;
};


export type JournalistAccountScreen = {
  __typename?: 'JournalistAccountScreen';
  title?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isEmailValid?: Maybe<Scalars['Boolean']>;
  hasPassword?: Maybe<Scalars['Boolean']>;
  logInProviders?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  isFeretAddress?: Maybe<Scalars['Boolean']>;
  paymentInformation?: Maybe<PaymentInformationType>;
  shippingAddress?: Maybe<ShippingAddressType>;
  locale?: Maybe<Scalars['String']>;
};

export type JournalistBofInput = {
  slug?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  about?: Maybe<LngStringInput>;
  photo?: Maybe<ImgInput>;
  logo?: Maybe<ImgInput>;
  background?: Maybe<ImgInput>;
  socialMedias?: Maybe<Array<Maybe<SocialMediaInput>>>;
};

export type JournalistFilter = {
  search?: Maybe<Scalars['String']>;
  areas?: Maybe<Array<Maybe<Scalars['String']>>>;
  video?: Maybe<Scalars['Boolean']>;
};

export type JournalistItem = {
  __typename?: 'JournalistItem';
  _id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  about?: Maybe<LngString>;
  photo?: Maybe<Img>;
  logo?: Maybe<Img>;
  background?: Maybe<Img>;
  socialMedias?: Maybe<Array<Maybe<SocialMediaFront>>>;
};

export type JournalistItemFront = {
  __typename?: 'JournalistItemFront';
  _id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  about?: Maybe<LngString>;
  photo?: Maybe<Img>;
  logo?: Maybe<Img>;
  background?: Maybe<Img>;
  socialMedias?: Maybe<Array<Maybe<SocialMediaFront>>>;
  areas?: Maybe<Array<Maybe<Scalars['String']>>>;
  vintages?: Maybe<Array<Maybe<JournalistVintages>>>;
};

export type JournalistSu = {
  __typename?: 'JournalistSu';
  userId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type JournalistType = {
  __typename?: 'JournalistType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['Boolean']>;
  demoVideoUrl?: Maybe<Scalars['String']>;
  delay?: Maybe<Scalars['Int']>;
  prerequisites?: Maybe<Scalars['String']>;
  areas?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type JournalistVintages = {
  __typename?: 'JournalistVintages';
  image?: Maybe<Img>;
  vintage?: Maybe<FrontVintage>;
  wineEntity?: Maybe<SimpleWineEntity>;
  vintageName?: Maybe<Scalars['String']>;
  wine?: Maybe<WineFront>;
  classification?: Maybe<Scalars['String']>;
};

export type LabelsBoc = {
  __typename?: 'LabelsBOC';
  certificationId?: Maybe<Scalars['ID']>;
  object?: Maybe<Scalars['String']>;
  logo?: Maybe<Img>;
  isInConversion?: Maybe<Scalars['Boolean']>;
  visibility?: Maybe<Scalars['Boolean']>;
  certificationBody?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['Date']>;
  internalStatus?: Maybe<Scalars['String']>;
  refusalReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  element?: Maybe<Scalars['String']>;
  proofDocuments?: Maybe<Array<Maybe<Document>>>;
};

export type LabelsBof = {
  __typename?: 'LabelsBOF';
  wineId?: Maybe<Scalars['ID']>;
  vintageId?: Maybe<Scalars['ID']>;
  cuvee?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  shortId?: Maybe<Scalars['String']>;
  wineEntityId?: Maybe<Scalars['ID']>;
  wineName?: Maybe<Scalars['String']>;
  vintageYear?: Maybe<Scalars['Int']>;
  expiryDate?: Maybe<Scalars['Date']>;
  certificationId?: Maybe<Scalars['ID']>;
  refusalReason?: Maybe<Scalars['String']>;
  wineEntityName?: Maybe<Scalars['String']>;
  internalStatus?: Maybe<Scalars['String']>;
  isInConversion?: Maybe<Scalars['Boolean']>;
  level?: Maybe<CertificationLevel>;
  certificationBody?: Maybe<Scalars['String']>;
  proofDocuments?: Maybe<Array<Maybe<Document>>>;
};

export type Language = {
  __typename?: 'Language';
  language?: Maybe<Scalars['String']>;
  icon?: Maybe<Img>;
};

export enum LanguageValue {
  Fr = 'fr',
  En = 'en',
  Es = 'es',
  De = 'de',
  It = 'it',
  Nl = 'nl',
  Ja = 'ja',
  Zh = 'zh'
}

export type LanguagesBool = {
  __typename?: 'LanguagesBool';
  fr?: Maybe<Scalars['Boolean']>;
  en?: Maybe<Scalars['Boolean']>;
  de?: Maybe<Scalars['Boolean']>;
  es?: Maybe<Scalars['Boolean']>;
  it?: Maybe<Scalars['Boolean']>;
  nl?: Maybe<Scalars['Boolean']>;
  zh?: Maybe<Scalars['Boolean']>;
  ja?: Maybe<Scalars['Boolean']>;
};

export type LanguagesBoolInput = {
  fr?: Maybe<Scalars['Boolean']>;
  en?: Maybe<Scalars['Boolean']>;
  de?: Maybe<Scalars['Boolean']>;
  es?: Maybe<Scalars['Boolean']>;
  it?: Maybe<Scalars['Boolean']>;
  nl?: Maybe<Scalars['Boolean']>;
  zh?: Maybe<Scalars['Boolean']>;
  ja?: Maybe<Scalars['Boolean']>;
};

export type LieuDitDistribution = {
  __typename?: 'LieuDitDistribution';
  lieuDit?: Maybe<Scalars['String']>;
  superficie?: Maybe<Scalars['Float']>;
};

export type LngCount = {
  __typename?: 'LngCount';
  count?: Maybe<Scalars['Int']>;
  lastDownload?: Maybe<Scalars['Date']>;
};

export type LngString = {
  __typename?: 'LngString';
  fr?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  de?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
  nl?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  zh?: Maybe<Scalars['String']>;
  ja?: Maybe<Scalars['String']>;
};

export type LngStringInput = {
  fr?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  de?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
  nl?: Maybe<Scalars['String']>;
  ja?: Maybe<Scalars['String']>;
  zh?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type LocalesType = {
  __typename?: 'LocalesType';
  fr?: Maybe<Scalars['Int']>;
  en?: Maybe<Scalars['Int']>;
  es?: Maybe<Scalars['Int']>;
  it?: Maybe<Scalars['Int']>;
  de?: Maybe<Scalars['Int']>;
  nl?: Maybe<Scalars['Int']>;
  ja?: Maybe<Scalars['Int']>;
  zh?: Maybe<Scalars['Int']>;
};

export type Logo = {
  __typename?: 'Logo';
  data?: Maybe<Scalars['String']>;
};

export type Maceration = {
  __typename?: 'Maceration';
  type?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  durationUnit?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['Int']>;
};

export type MacerationInput = {
  type?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  durationUnit?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['Int']>;
};

export type MainTitle = {
  __typename?: 'MainTitle';
  name?: Maybe<Scalars['String']>;
  mainAppellation?: Maybe<Scalars['String']>;
  classifications?: Maybe<Array<Maybe<Classification>>>;
  estateSurfaceArea?: Maybe<Scalars['Float']>;
  bgImage?: Maybe<Img>;
};

export type MarketingWine = {
  __typename?: 'MarketingWine';
  exportPercentage?: Maybe<Scalars['Int']>;
  directPercentage?: Maybe<Scalars['Int']>;
  indirectPercentage?: Maybe<Scalars['Int']>;
  exportCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MarketingWineInput = {
  exportPercentage?: Maybe<Scalars['Int']>;
  directPercentage?: Maybe<Scalars['Int']>;
  indirectPercentage?: Maybe<Scalars['Int']>;
  exportCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MaturationWine = {
  __typename?: 'MaturationWine';
  barrelMaturation?: Maybe<Scalars['Boolean']>;
  barrelMaturationDurationValue?: Maybe<Scalars['Int']>;
  barrelMaturationDurationUnit?: Maybe<Scalars['String']>;
  newBarrelPercentage?: Maybe<Scalars['Int']>;
  toasting?: Maybe<Scalars['Int']>;
  frenchOak?: Maybe<Scalars['Boolean']>;
};

export type MaturationWineInput = {
  barrelMaturation?: Maybe<Scalars['Boolean']>;
  barrelMaturationDurationValue?: Maybe<Scalars['Int']>;
  barrelMaturationDurationUnit?: Maybe<Scalars['String']>;
  newBarrelPercentage?: Maybe<Scalars['Int']>;
  toasting?: Maybe<Scalars['Int']>;
  frenchOak?: Maybe<Scalars['Boolean']>;
};

export type MedalInput = {
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  fontColor?: Maybe<Scalars['String']>;
  bgColor?: Maybe<Scalars['String']>;
};

export type MedalType = {
  __typename?: 'MedalType';
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  fontColor?: Maybe<Scalars['String']>;
  bgColor?: Maybe<Scalars['String']>;
};

export type MemberMetrics = {
  __typename?: 'MemberMetrics';
  total?: Maybe<Scalars['Int']>;
  otherCount?: Maybe<Scalars['Int']>;
  ownerCount?: Maybe<Scalars['Int']>;
  operatorCount?: Maybe<Scalars['Int']>;
  ownerOperatorCount?: Maybe<Scalars['Int']>;
  members?: Maybe<Array<Maybe<MemberSimple>>>;
};

export type MemberSimple = {
  __typename?: 'MemberSimple';
  displayName?: Maybe<Scalars['String']>;
  image?: Maybe<Img>;
  personId?: Maybe<Scalars['ID']>;
  vip?: Maybe<Scalars['Boolean']>;
  proFunctions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MembersFrontType = {
  __typename?: 'MembersFrontType';
  _id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  role?: Maybe<Scalars['String']>;
};

export type MembersInput = {
  _id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  role?: Maybe<LngStringInput>;
};

export type MembersType = {
  __typename?: 'MembersType';
  _id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  role?: Maybe<LngString>;
};

export type Mesure = {
  __typename?: 'Mesure';
  value?: Maybe<Scalars['Float']>;
};

export type MesureInput = {
  value?: Maybe<Scalars['Float']>;
};

export type MissingComponentType = {
  __typename?: 'MissingComponentType';
  label?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  CancelContractCRMTask?: Maybe<Scalars['String']>;
  EditAccountScreenEmpty?: Maybe<Scalars['String']>;
  ApplyOwnerOperator?: Maybe<Scalars['String']>;
  ApplyEstateChecking?: Maybe<Scalars['String']>;
  ImportVintageClassifications?: Maybe<ImportResponse>;
  ImportWineClassifications?: Maybe<ImportResponse>;
  ImportWineEntityClassifications?: Maybe<ImportResponse>;
  ImportWineScores?: Maybe<ImportResponse>;
  ImportRewards?: Maybe<ImportRewardsResponse>;
  CreateCertificationSuggestion?: Maybe<Scalars['String']>;
  EditHiddenSurfaces?: Maybe<Scalars['String']>;
  RemoveCellar?: Maybe<Scalars['String']>;
  SetImportCVI?: Maybe<Scalars['String']>;
  EditAccountScreen?: Maybe<Scalars['String']>;
  ChangePassword?: Maybe<Scalars['String']>;
  ModifyLabelBoc?: Maybe<Scalars['String']>;
  CreateLabelBoc?: Maybe<Scalars['String']>;
  RemoveCertification?: Maybe<Scalars['String']>;
  UpdateCertification?: Maybe<Scalars['String']>;
  EditEstate?: Maybe<Scalars['String']>;
  SurfaceDeProduction?: Maybe<Scalars['String']>;
  ParseCVI?: Maybe<Scalars['String']>;
  deleteAllSurfaces?: Maybe<Scalars['String']>;
  EditSurfaceManual?: Maybe<Scalars['String']>;
  AddTeamMember?: Maybe<Scalars['String']>;
  EditTeamMember?: Maybe<Scalars['String']>;
  DeleteTeamMember?: Maybe<Scalars['String']>;
  EditTeamGeneral?: Maybe<Scalars['String']>;
  ProprieteVignobleScreen?: Maybe<Scalars['JSON']>;
  GeneralInformationScreen?: Maybe<Scalars['String']>;
  EditEstateComplementaryInfo?: Maybe<Scalars['String']>;
  AddCellar?: Maybe<Scalars['String']>;
  EditCellarTab?: Maybe<Scalars['String']>;
  EditCellarPencil?: Maybe<Scalars['String']>;
  AddQrcodeStats?: Maybe<Scalars['String']>;
  AddConnectionLog?: Maybe<Scalars['String']>;
  SendEstateMail?: Maybe<Scalars['String']>;
  SendContactMail?: Maybe<Scalars['String']>;
  HandleReCaptcha?: Maybe<Scalars['String']>;
  CreateRoleSugestion?: Maybe<Scalars['String']>;
  ChangeEstateVisibility?: Maybe<Scalars['String']>;
  SavePaperSubscriptionData?: Maybe<Scalars['String']>;
  DeleteManyNotifications?: Maybe<Scalars['String']>;
  DeleteNotification?: Maybe<Scalars['String']>;
  MarkManyNotifications?: Maybe<Scalars['String']>;
  MarkNotification?: Maybe<Scalars['String']>;
  MarkAllNotifications?: Maybe<Scalars['String']>;
  CreateContactsWithQueue?: Maybe<Scalars['String']>;
  CreateFeretContact?: Maybe<Scalars['String']>;
  CreateFeretContacts?: Maybe<Scalars['String']>;
  EditFeretContact?: Maybe<Scalars['String']>;
  DeleteFeretContact?: Maybe<Scalars['String']>;
  RemoveContactFromDiffusionList?: Maybe<Scalars['String']>;
  AddContactToDiffusionList?: Maybe<Scalars['String']>;
  EditContactAndResentDiffusion?: Maybe<Scalars['String']>;
  EditClassificationOrganism?: Maybe<Scalars['String']>;
  CreateClassificationOrganism?: Maybe<Scalars['String']>;
  DeleteClassificationOrganism?: Maybe<Scalars['String']>;
  CreateQrcodeRedirect?: Maybe<Scalars['String']>;
  CreateQrcodeStats?: Maybe<Scalars['String']>;
  CreateDiffusionList?: Maybe<Scalars['String']>;
  EditDiffusionList?: Maybe<Scalars['String']>;
  DeleteDiffusionList?: Maybe<Scalars['String']>;
  CreateAuthor?: Maybe<Scalars['String']>;
  EditAuthor?: Maybe<Scalars['String']>;
  DeleteAuthor?: Maybe<Scalars['String']>;
  CreateOdg?: Maybe<Scalars['String']>;
  EditOdg?: Maybe<Scalars['String']>;
  DeleteOdg?: Maybe<Scalars['String']>;
  OpenFtDiffusion?: Maybe<Scalars['String']>;
  CreateFtDiffusion?: Maybe<Scalars['String']>;
  CreateWebFtDiffusion?: Maybe<Scalars['String']>;
  EditFtDiffusion?: Maybe<Scalars['String']>;
  ResendAll?: Maybe<Scalars['String']>;
  ResendOne?: Maybe<Scalars['String']>;
  EditDiffusionParameters?: Maybe<Scalars['String']>;
  RequestFtLanguage?: Maybe<Scalars['String']>;
  RequestFtLanguages?: Maybe<Scalars['String']>;
  GenerateFt?: Maybe<Scalars['String']>;
  SaveFt?: Maybe<Scalars['String']>;
  CreateFt?: Maybe<FtCreatorParams>;
  CreateFtFromModel?: Maybe<FtCreatorParams>;
  UpdateFtComponents?: Maybe<FtCreatorParams>;
  DeleteFt?: Maybe<Scalars['String']>;
  SwitchStatus?: Maybe<Scalars['String']>;
  EditFt?: Maybe<Scalars['String']>;
  CheckEmailAvailability?: Maybe<Scalars['Boolean']>;
  SignUp?: Maybe<SignUpResponse>;
  SignUpInvite?: Maybe<SignUpResponse>;
  SignUpJournalist?: Maybe<SignUpResponse>;
  SignInInvite?: Maybe<Scalars['String']>;
  ResetPassword?: Maybe<Scalars['String']>;
  DeleteUser?: Maybe<Scalars['String']>;
  EditUserEmail?: Maybe<Scalars['String']>;
  CreateFormationOrganism?: Maybe<Scalars['String']>;
  EditFormationOrganism?: Maybe<Scalars['String']>;
  DeleteFormationOrganism?: Maybe<Scalars['String']>;
  CreateRepresentationOrganism?: Maybe<Scalars['String']>;
  EditRepresentationOrganism?: Maybe<Scalars['String']>;
  DeleteRepresentationOrganism?: Maybe<Scalars['String']>;
  CreateCompetition?: Maybe<Scalars['String']>;
  EditCompetition?: Maybe<Scalars['String']>;
  DeleteCompetition?: Maybe<Scalars['String']>;
  CreateArticle?: Maybe<Scalars['String']>;
  EditArticle?: Maybe<Scalars['String']>;
  DeleteArticle?: Maybe<Scalars['String']>;
  CreateAppellationDescription?: Maybe<Scalars['String']>;
  EditAppellationDescription?: Maybe<Scalars['String']>;
  DeleteAppellationDescription?: Maybe<Scalars['String']>;
  CancelSubscriptionData?: Maybe<Scalars['String']>;
  CreateNewSubscriptionData?: Maybe<Scalars['String']>;
  GenerateSubscriptionHostedPage?: Maybe<Scalars['String']>;
  EditPaperSubcription?: Maybe<Scalars['String']>;
  GenerateHostedPageAddon?: Maybe<Scalars['String']>;
  GenerateHostedPageUpgrade?: Maybe<Scalars['String']>;
  DowngradeSubscription?: Maybe<Scalars['String']>;
  CreateTastingRequests?: Maybe<Scalars['String']>;
  EditTastingRequest?: Maybe<Scalars['String']>;
  EditTastingRequestStatus?: Maybe<Scalars['String']>;
  DeleteTastingRequest?: Maybe<Scalars['String']>;
  EditNoDisplayModal?: Maybe<Scalars['String']>;
  CreateWineEntities?: Maybe<Scalars['String']>;
  CreateRewards?: Maybe<Array<Maybe<CreatedReward>>>;
  UpdateMatchingDates?: Maybe<UpdateMatchingDatesResponse>;
  CreateScores?: Maybe<Array<Maybe<CreateScore>>>;
  UpdateBookAppearances?: Maybe<Scalars['String']>;
  CreateServiceRequest?: Maybe<Scalars['String']>;
  GenerateHostedPageServiceRequest?: Maybe<Scalars['String']>;
  ApplyTranslation?: Maybe<Scalars['String']>;
  NewTranslationRequest?: Maybe<Scalars['String']>;
  EditRewards?: Maybe<Scalars['String']>;
  RemoveScore?: Maybe<Scalars['String']>;
  EditContact?: Maybe<Scalars['String']>;
  AddActivity?: Maybe<Scalars['String']>;
  EditActivity?: Maybe<Scalars['String']>;
  RemoveActivity?: Maybe<Scalars['String']>;
  CreateWine?: Maybe<Scalars['String']>;
  EditWinePresentationScreen?: Maybe<Scalars['String']>;
  DeleteWine?: Maybe<Scalars['String']>;
  CreateJournalist?: Maybe<Scalars['String']>;
  EditJournalist?: Maybe<Scalars['String']>;
  DeleteJournalist?: Maybe<Scalars['String']>;
  EditJournalistAccount?: Maybe<Scalars['String']>;
  EditServicesOfferByJournalist?: Maybe<Scalars['String']>;
  CreateVintage?: Maybe<Scalars['ID']>;
  EditVintageIdentity?: Maybe<Scalars['String']>;
  EditVintageViticultureScreen?: Maybe<Scalars['String']>;
  EditVintageWineMakingScreen?: Maybe<Scalars['String']>;
  EditVintageAgeingScreen?: Maybe<Scalars['String']>;
  EditVintageProductionScreen?: Maybe<Scalars['String']>;
  EditVintageNutritionScreen?: Maybe<Scalars['String']>;
  EditVintageMarketingScreen?: Maybe<Scalars['String']>;
  EditVintageAdviceScreen?: Maybe<Scalars['String']>;
  EditVintageAdviceUrl?: Maybe<Scalars['String']>;
  DeleteVintage?: Maybe<Scalars['String']>;
  CreateCustomFont?: Maybe<Scalars['String']>;
  CreateCustomFonts?: Maybe<Array<Maybe<Scalars['String']>>>;
  DeleteCustomFont?: Maybe<Scalars['String']>;
  DeleteCustomFonts?: Maybe<Array<Maybe<Scalars['String']>>>;
  CreateDownloadURL?: Maybe<UrlType>;
  CreateUploadURL?: Maybe<UrlType>;
  UpdateFtpDiffusions?: Maybe<Scalars['String']>;
  DiffuseOne?: Maybe<Scalars['Boolean']>;
  SetSU?: Maybe<Scalars['String']>;
};


export type MutationCancelContractCrmTaskArgs = {
  userId: Scalars['ID'];
};


export type MutationEditAccountScreenEmptyArgs = {
  userId: Scalars['ID'];
  input: EditAccountScreenEmptyInput;
};


export type MutationApplyOwnerOperatorArgs = {
  wineEntityId: Scalars['ID'];
};


export type MutationApplyEstateCheckingArgs = {
  wineEntityId: Scalars['ID'];
  input?: Maybe<ApplyEstateCheckingInput>;
};


export type MutationImportVintageClassificationsArgs = {
  fileName?: Maybe<Scalars['String']>;
};


export type MutationImportWineClassificationsArgs = {
  fileName?: Maybe<Scalars['String']>;
};


export type MutationImportWineEntityClassificationsArgs = {
  fileName?: Maybe<Scalars['String']>;
};


export type MutationImportWineScoresArgs = {
  fileName?: Maybe<Scalars['String']>;
};


export type MutationImportRewardsArgs = {
  fileName?: Maybe<Scalars['String']>;
};


export type MutationCreateCertificationSuggestionArgs = {
  wineEntityId: Scalars['ID'];
  input: CreateCertificationSuggestionInput;
};


export type MutationEditHiddenSurfacesArgs = {
  wineEntityId: Scalars['ID'];
  input: EditHiddenSurfacesInput;
};


export type MutationRemoveCellarArgs = {
  cellarId: Scalars['ID'];
};


export type MutationSetImportCviArgs = {
  wineEntityId: Scalars['ID'];
  input?: Maybe<Scalars['Boolean']>;
};


export type MutationEditAccountScreenArgs = {
  userId: Scalars['ID'];
  input: EditAccountInput;
};


export type MutationChangePasswordArgs = {
  email: Scalars['String'];
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationModifyLabelBocArgs = {
  wineEntityId: Scalars['ID'];
  certificationId: Scalars['ID'];
  input?: Maybe<UpdateCertificationInput>;
};


export type MutationCreateLabelBocArgs = {
  wineEntityId: Scalars['ID'];
  wineId?: Maybe<Scalars['ID']>;
  vintageId?: Maybe<Scalars['ID']>;
  input?: Maybe<CreateLabelBocInput>;
};


export type MutationRemoveCertificationArgs = {
  certificationId: Scalars['ID'];
};


export type MutationUpdateCertificationArgs = {
  certificationId: Scalars['ID'];
  input?: Maybe<UpdateCertificationBofInput>;
};


export type MutationEditEstateArgs = {
  wineEntityId: Scalars['ID'];
  input: EditEstateInput;
};


export type MutationSurfaceDeProductionArgs = {
  wineEntityId: Scalars['ID'];
  input: SurfaceDeProductionInput;
};


export type MutationParseCviArgs = {
  input: Scalars['JSON'];
  wineEntityId: Scalars['ID'];
};


export type MutationDeleteAllSurfacesArgs = {
  wineEntityId: Scalars['ID'];
};


export type MutationEditSurfaceManualArgs = {
  wineEntityId: Scalars['ID'];
  input: SurfaceManualInput;
};


export type MutationAddTeamMemberArgs = {
  input: AddTeamMemberInput;
  wineEntityId: Scalars['ID'];
};


export type MutationEditTeamMemberArgs = {
  input: EditTeamMemberInput;
  wineEntityId: Scalars['ID'];
  personId: Scalars['ID'];
};


export type MutationDeleteTeamMemberArgs = {
  wineEntityId: Scalars['ID'];
  personId: Scalars['ID'];
};


export type MutationEditTeamGeneralArgs = {
  wineEntityId: Scalars['ID'];
  input: EditTeamGeneralInput;
};


export type MutationProprieteVignobleScreenArgs = {
  wineEntityId: Scalars['ID'];
  input: ProprieteVignobleScreenInput;
};


export type MutationGeneralInformationScreenArgs = {
  wineEntityId: Scalars['ID'];
  input: GeneralInformationScreenInput;
};


export type MutationEditEstateComplementaryInfoArgs = {
  wineEntityId: Scalars['ID'];
  input?: Maybe<EstateComplementaryInfoInput>;
};


export type MutationAddCellarArgs = {
  wineEntityId: Scalars['ID'];
  input?: Maybe<AddCellarInput>;
};


export type MutationEditCellarTabArgs = {
  wineEntityId: Scalars['ID'];
  cellarId: Scalars['ID'];
  input?: Maybe<EditCellarTabInput>;
};


export type MutationEditCellarPencilArgs = {
  wineEntityId: Scalars['ID'];
  cellarId: Scalars['ID'];
  input?: Maybe<EditCellarPencilInput>;
};


export type MutationAddQrcodeStatsArgs = {
  input: AddQrcodeStatsInput;
};


export type MutationAddConnectionLogArgs = {
  input: AddConnectionLogInput;
};


export type MutationSendEstateMailArgs = {
  wineEntityId: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  emailFrom: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  estateName: Scalars['String'];
  newsletter?: Maybe<Scalars['Boolean']>;
};


export type MutationSendContactMailArgs = {
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  emailFrom?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  newsletter?: Maybe<Scalars['Boolean']>;
};


export type MutationHandleReCaptchaArgs = {
  recaptchaResponse: Scalars['String'];
};


export type MutationCreateRoleSugestionArgs = {
  wineEntityId: Scalars['ID'];
  roleName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};


export type MutationChangeEstateVisibilityArgs = {
  wineEntityId: Scalars['ID'];
  visibility: Scalars['Boolean'];
};


export type MutationSavePaperSubscriptionDataArgs = {
  wineEntityId: Scalars['ID'];
  accessibility?: Maybe<Array<Maybe<Scalars['String']>>>;
  isBookingRequired?: Maybe<Scalars['Boolean']>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  priceRange?: Maybe<Array<Maybe<Scalars['Int']>>>;
  touristLabels?: Maybe<Scalars['String']>;
  description?: Maybe<LngStringInput>;
  visitSchedule?: Maybe<Scalars['String']>;
  hasOtherCulture?: Maybe<Scalars['Boolean']>;
  otherCulture?: Maybe<Scalars['String']>;
  bugFightMethod?: Maybe<Scalars['String']>;
  byGrassing?: Maybe<Scalars['Boolean']>;
  grassingMethod?: Maybe<Scalars['String']>;
  done?: Maybe<Scalars['Boolean']>;
  redVineSurface?: Maybe<Scalars['Int']>;
  whiteVineSurface?: Maybe<Scalars['Int']>;
  redAvgProd?: Maybe<Scalars['Int']>;
  whiteAvgProd?: Maybe<Scalars['Int']>;
  redWineAssembly?: Maybe<Array<Maybe<SimpleGrapeVarietyPartInput>>>;
  whiteWineAssembly?: Maybe<Array<Maybe<SimpleGrapeVarietyPartInput>>>;
  certificationFiles?: Maybe<Array<Maybe<DocumentInput>>>;
};


export type MutationDeleteManyNotificationsArgs = {
  notificationIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationDeleteNotificationArgs = {
  notificationId: Scalars['ID'];
};


export type MutationMarkManyNotificationsArgs = {
  notificationIds: Array<Maybe<Scalars['ID']>>;
  isRead?: Maybe<Scalars['Boolean']>;
};


export type MutationMarkNotificationArgs = {
  notificationId: Scalars['ID'];
  isRead?: Maybe<Scalars['Boolean']>;
};


export type MutationMarkAllNotificationsArgs = {
  userId: Scalars['ID'];
  isRead?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateContactsWithQueueArgs = {
  csvFile: DocumentInput;
  wineEntityId: Scalars['ID'];
};


export type MutationCreateFeretContactArgs = {
  input: FeretContactInput;
  wineEntityId: Scalars['ID'];
  diffusionListIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationCreateFeretContactsArgs = {
  contactInputs: Array<Maybe<FeretContactInput>>;
  wineEntityId: Scalars['ID'];
};


export type MutationEditFeretContactArgs = {
  input: FeretContactInput;
  contactId: Scalars['ID'];
  wineEntityId: Scalars['ID'];
};


export type MutationDeleteFeretContactArgs = {
  contactId: Scalars['ID'];
  wineEntityId: Scalars['ID'];
};


export type MutationRemoveContactFromDiffusionListArgs = {
  contactId: Scalars['ID'];
  wineEntityId: Scalars['ID'];
  diffusionListId: Scalars['ID'];
};


export type MutationAddContactToDiffusionListArgs = {
  contactId: Scalars['ID'];
  wineEntityId: Scalars['ID'];
  diffusionListId: Scalars['ID'];
};


export type MutationEditContactAndResentDiffusionArgs = {
  contactId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
};


export type MutationEditClassificationOrganismArgs = {
  classificationOrganismInput: ClassificationOrganismInput;
  classificationOrganismId: Scalars['ID'];
  locale: Scalars['String'];
};


export type MutationCreateClassificationOrganismArgs = {
  classificationOrganismInput: ClassificationOrganismInput;
  locale: Scalars['String'];
};


export type MutationDeleteClassificationOrganismArgs = {
  classificationOrganismId: Scalars['ID'];
};


export type MutationCreateQrcodeRedirectArgs = {
  input: QrcodeRedirectInput;
};


export type MutationCreateQrcodeStatsArgs = {
  input: QrcodeStatsInput;
};


export type MutationCreateDiffusionListArgs = {
  diffusionListInput: DiffusionListInput;
  wineEntityId: Scalars['ID'];
};


export type MutationEditDiffusionListArgs = {
  input: DiffusionListInput;
  diffusionListId: Scalars['ID'];
  wineEntityId: Scalars['ID'];
};


export type MutationDeleteDiffusionListArgs = {
  diffusionListId: Scalars['ID'];
  wineEntityId: Scalars['ID'];
};


export type MutationCreateAuthorArgs = {
  authorInput: AuthorInput;
  locale: Scalars['String'];
};


export type MutationEditAuthorArgs = {
  authorInput: AuthorInput;
  authorId: Scalars['ID'];
  locale: Scalars['String'];
};


export type MutationDeleteAuthorArgs = {
  authorId: Scalars['ID'];
};


export type MutationCreateOdgArgs = {
  odgInput: OdgInput;
  locale: Scalars['String'];
};


export type MutationEditOdgArgs = {
  odgInput: OdgInput;
  locale: Scalars['String'];
  odgId: Scalars['String'];
};


export type MutationDeleteOdgArgs = {
  odgId: Scalars['ID'];
};


export type MutationOpenFtDiffusionArgs = {
  ftDiffusionId: Scalars['ID'];
};


export type MutationCreateFtDiffusionArgs = {
  contactIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  diffusionListIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  ftId: Scalars['ID'];
  wineEntityId: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
};


export type MutationCreateWebFtDiffusionArgs = {
  userId: Scalars['ID'];
  ftId: Scalars['ID'];
};


export type MutationEditFtDiffusionArgs = {
  ftId: Scalars['ID'];
  diffusionListIds?: Maybe<Array<Scalars['ID']>>;
  message?: Maybe<Scalars['String']>;
};


export type MutationResendAllArgs = {
  ftId: Scalars['ID'];
  wineEntityId: Scalars['ID'];
};


export type MutationResendOneArgs = {
  ftDiffusionId: Scalars['ID'];
};


export type MutationEditDiffusionParametersArgs = {
  wineEntityId: Scalars['ID'];
  parameters: DiffusionParametersInput;
};


export type MutationRequestFtLanguageArgs = {
  ftParametersId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Boolean']>;
};


export type MutationRequestFtLanguagesArgs = {
  ftParametersId: Scalars['ID'];
  locales?: Maybe<LanguagesBoolInput>;
};


export type MutationGenerateFtArgs = {
  ftParametersId: Scalars['ID'];
  locales?: Maybe<Array<Maybe<Scalars['String']>>>;
  userId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};


export type MutationSaveFtArgs = {
  notify?: Maybe<Scalars['Boolean']>;
  pages?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  components?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  ftParametersId?: Maybe<Scalars['ID']>;
};


export type MutationCreateFtArgs = {
  wineEntityId: Scalars['ID'];
  wineId?: Maybe<Scalars['ID']>;
  vintageId?: Maybe<Scalars['ID']>;
  ftId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type MutationCreateFtFromModelArgs = {
  ftModelId: Scalars['ID'];
  wineEntityId: Scalars['ID'];
  wineId?: Maybe<Scalars['ID']>;
  vintageId: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};


export type MutationUpdateFtComponentsArgs = {
  ftParametersId: Scalars['ID'];
};


export type MutationDeleteFtArgs = {
  ftId: Scalars['ID'];
};


export type MutationSwitchStatusArgs = {
  ftParametersId: Scalars['ID'];
  status: Scalars['String'];
};


export type MutationEditFtArgs = {
  ftParametersId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  online?: Maybe<Scalars['Boolean']>;
};


export type MutationCheckEmailAvailabilityArgs = {
  email: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationSignUpInviteArgs = {
  input: SignUpInviteInput;
};


export type MutationSignUpJournalistArgs = {
  input: SignUpJournalistInput;
};


export type MutationSignInInviteArgs = {
  input: SignInInviteInput;
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['String'];
};


export type MutationEditUserEmailArgs = {
  userId: Scalars['String'];
  email: Scalars['String'];
};


export type MutationCreateFormationOrganismArgs = {
  input: FormationOrganismInput;
  locale: Scalars['String'];
};


export type MutationEditFormationOrganismArgs = {
  input: FormationOrganismInput;
  formationOrganismId: Scalars['ID'];
  locale: Scalars['String'];
};


export type MutationDeleteFormationOrganismArgs = {
  formationOrganismId: Scalars['ID'];
};


export type MutationCreateRepresentationOrganismArgs = {
  input: RepresentationOrganismInput;
  locale: Scalars['String'];
};


export type MutationEditRepresentationOrganismArgs = {
  input: RepresentationOrganismInput;
  representationOrganismId: Scalars['ID'];
  locale: Scalars['String'];
};


export type MutationDeleteRepresentationOrganismArgs = {
  representationOrganismId: Scalars['ID'];
};


export type MutationCreateCompetitionArgs = {
  input: CompetitionInput;
  locale: Scalars['String'];
};


export type MutationEditCompetitionArgs = {
  input: CompetitionInput;
  competitionId: Scalars['ID'];
  locale: Scalars['String'];
};


export type MutationDeleteCompetitionArgs = {
  competitionId: Scalars['ID'];
};


export type MutationCreateArticleArgs = {
  input: ArticleInput;
  locale: Scalars['String'];
};


export type MutationEditArticleArgs = {
  input: ArticleInput;
  articleId: Scalars['ID'];
  locale: Scalars['String'];
};


export type MutationDeleteArticleArgs = {
  articleId: Scalars['ID'];
};


export type MutationCreateAppellationDescriptionArgs = {
  input: AppellationDescriptionInput;
  locale: Scalars['String'];
};


export type MutationEditAppellationDescriptionArgs = {
  input: AppellationDescriptionInput;
  appellationDescriptionId: Scalars['ID'];
  locale: Scalars['String'];
};


export type MutationDeleteAppellationDescriptionArgs = {
  appellationDescriptionId: Scalars['ID'];
};


export type MutationCancelSubscriptionDataArgs = {
  subscriptionDataId: Scalars['ID'];
};


export type MutationCreateNewSubscriptionDataArgs = {
  input: NewSubscriptionDataInput;
  freeTrial?: Maybe<Scalars['Boolean']>;
};


export type MutationGenerateSubscriptionHostedPageArgs = {
  userId: Scalars['ID'];
  planCode: Scalars['String'];
  subscriptionDataId: Scalars['ID'];
  addonCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationEditPaperSubcriptionArgs = {
  wineEntityId: Scalars['ID'];
  paperSubsciptionName: Scalars['String'];
};


export type MutationGenerateHostedPageAddonArgs = {
  wineEntityId: Scalars['ID'];
  addonCodes: Array<Maybe<AddonInput>>;
};


export type MutationGenerateHostedPageUpgradeArgs = {
  wineEntityId: Scalars['ID'];
  planName: Scalars['String'];
  addonCodes?: Maybe<Array<Maybe<AddonInput>>>;
};


export type MutationDowngradeSubscriptionArgs = {
  subscriptionId: Scalars['String'];
  subscriptionType?: Maybe<Scalars['String']>;
};


export type MutationCreateTastingRequestsArgs = {
  tastingRequests?: Maybe<Array<Maybe<TastingRequestInput>>>;
};


export type MutationEditTastingRequestArgs = {
  tastingRequestId: Scalars['ID'];
  input: TastingRequestInput;
  locale?: Maybe<Scalars['String']>;
};


export type MutationEditTastingRequestStatusArgs = {
  tastingRequestId: Scalars['ID'];
  status: Scalars['String'];
};


export type MutationDeleteTastingRequestArgs = {
  tastingRequestId: Scalars['ID'];
};


export type MutationEditNoDisplayModalArgs = {
  userId: Scalars['ID'];
  noDisplayModal: Array<Maybe<Scalars['String']>>;
};


export type MutationCreateWineEntitiesArgs = {
  wineEntities?: Maybe<Array<Maybe<WineEntitiesInput>>>;
};


export type MutationCreateRewardsArgs = {
  file?: Maybe<DocumentInput>;
};


export type MutationUpdateMatchingDatesArgs = {
  file?: Maybe<DocumentInput>;
};


export type MutationCreateScoresArgs = {
  scores: Array<ScoresInput>;
};


export type MutationUpdateBookAppearancesArgs = {
  file?: Maybe<DocumentInput>;
};


export type MutationCreateServiceRequestArgs = {
  input?: Maybe<ServiceRequestInput>;
};


export type MutationGenerateHostedPageServiceRequestArgs = {
  serviceRequestId: Scalars['ID'];
};


export type MutationApplyTranslationArgs = {
  translationRequestId: Scalars['ID'];
  filename?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type MutationNewTranslationRequestArgs = {
  wineEntityId: Scalars['ID'];
  locale: Scalars['String'];
  translationMode: TranslationMode;
  translationRequest?: Maybe<TranslationRequestInput>;
  price: Scalars['Float'];
  paymentMethod: Scalars['String'];
  wordCount: Scalars['Int'];
};


export type MutationEditRewardsArgs = {
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
  input: EditRewardsInput;
};


export type MutationRemoveScoreArgs = {
  scoreId: Scalars['ID'];
};


export type MutationEditContactArgs = {
  wineEntityId?: Maybe<Scalars['ID']>;
  personId?: Maybe<Scalars['ID']>;
  type: Scalars['String'];
  input: EditContactInput;
};


export type MutationAddActivityArgs = {
  wineEntityId: Scalars['ID'];
  input: AddActivityInput;
};


export type MutationEditActivityArgs = {
  wineEntityId: Scalars['ID'];
  activityId: Scalars['ID'];
  input: EditActivityInput;
};


export type MutationRemoveActivityArgs = {
  wineEntityId: Scalars['ID'];
  activityId: Scalars['ID'];
};


export type MutationCreateWineArgs = {
  input: CreateWineInput;
};


export type MutationEditWinePresentationScreenArgs = {
  wineEntityId: Scalars['ID'];
  wineId: Scalars['ID'];
  input?: Maybe<WinePresentationInput>;
};


export type MutationDeleteWineArgs = {
  wineId: Scalars['ID'];
};


export type MutationCreateJournalistArgs = {
  input?: Maybe<JournalistBofInput>;
};


export type MutationEditJournalistArgs = {
  input?: Maybe<JournalistBofInput>;
  journalistId?: Maybe<Scalars['ID']>;
};


export type MutationDeleteJournalistArgs = {
  journalistId?: Maybe<Scalars['ID']>;
};


export type MutationEditJournalistAccountArgs = {
  userId: Scalars['ID'];
  input?: Maybe<EditJournalistAccountInput>;
};


export type MutationEditServicesOfferByJournalistArgs = {
  userId: Scalars['ID'];
  input?: Maybe<EditServicesOfferByJournalistInput>;
};


export type MutationCreateVintageArgs = {
  wineEntityId: Scalars['ID'];
  input: CreateVintageInput;
};


export type MutationEditVintageIdentityArgs = {
  wineEntityId: Scalars['ID'];
  wineId: Scalars['ID'];
  vintageId: Scalars['ID'];
  input?: Maybe<VintageIdentityInput>;
};


export type MutationEditVintageViticultureScreenArgs = {
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
  input?: Maybe<VintageViticultureScreenInput>;
};


export type MutationEditVintageWineMakingScreenArgs = {
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
  input?: Maybe<VintageWineMakingScreenInput>;
};


export type MutationEditVintageAgeingScreenArgs = {
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
  input?: Maybe<VintageAgeingScreenInput>;
};


export type MutationEditVintageProductionScreenArgs = {
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
  input?: Maybe<VintageProductionScreenInput>;
};


export type MutationEditVintageNutritionScreenArgs = {
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
  input?: Maybe<VintageNutritionScreenInput>;
};


export type MutationEditVintageMarketingScreenArgs = {
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
  input?: Maybe<VintageMarketingScreenInput>;
};


export type MutationEditVintageAdviceScreenArgs = {
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
  input?: Maybe<VintageAdviceScreenInput>;
};


export type MutationEditVintageAdviceUrlArgs = {
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
  videoUrl: Scalars['String'];
};


export type MutationDeleteVintageArgs = {
  vintageId: Scalars['ID'];
};


export type MutationCreateCustomFontArgs = {
  wineEntityId?: Maybe<Scalars['ID']>;
  family?: Maybe<Scalars['String']>;
  files?: Maybe<FontFilesInput>;
};


export type MutationCreateCustomFontsArgs = {
  wineEntityId?: Maybe<Scalars['ID']>;
  fonts?: Maybe<Array<Maybe<CustomFontInput>>>;
};


export type MutationDeleteCustomFontArgs = {
  customFontId?: Maybe<Scalars['ID']>;
};


export type MutationDeleteCustomFontsArgs = {
  customFontIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationCreateDownloadUrlArgs = {
  input: DocumentInput;
};


export type MutationCreateUploadUrlArgs = {
  input: DocumentInput;
};


export type MutationUpdateFtpDiffusionsArgs = {
  wineEntityId?: Maybe<Scalars['ID']>;
  ftIdInputs?: Maybe<Array<Maybe<FtIdInput>>>;
};


export type MutationDiffuseOneArgs = {
  ftpDiffusionId?: Maybe<Scalars['ID']>;
};


export type MutationSetSuArgs = {
  entityId: Scalars['String'];
  userId: Scalars['ID'];
};

export type NameSubregionType = {
  __typename?: 'NameSubregionType';
  name?: Maybe<Scalars['String']>;
  subregion?: Maybe<Scalars['String']>;
};

export type NewSubscriptionDataInput = {
  userId?: Maybe<Scalars['String']>;
  wineEntityName?: Maybe<Scalars['String']>;
  mainCommune?: Maybe<Scalars['String']>;
  mainAppellationCommune?: Maybe<Scalars['String']>;
  mainCommuneInseeCode?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  billingAddress?: Maybe<AddressInput>;
  billingInformation?: Maybe<BillingInformationInput>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  isOwner?: Maybe<Scalars['Boolean']>;
  isOperator?: Maybe<Scalars['Boolean']>;
  organizationName?: Maybe<Scalars['String']>;
  orphanWineEntityId?: Maybe<Scalars['ID']>;
  mainAppellation?: Maybe<Scalars['String']>;
  siret?: Maybe<Scalars['String']>;
  organizationStatus?: Maybe<Scalars['String']>;
  subscriptionType?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  addonCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  recurrence?: Maybe<SubscriptionRecurrence>;
  planCode?: Maybe<Scalars['String']>;
};

export type OdgFrontType = {
  __typename?: 'OdgFrontType';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  appellations?: Maybe<Array<Maybe<AppellationType>>>;
  members?: Maybe<Array<Maybe<MembersFrontType>>>;
  socialMedias?: Maybe<Array<Maybe<SocialMediaFront>>>;
};

export type OdgInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  appellations?: Maybe<Array<Maybe<AppellationInput>>>;
  members?: Maybe<Array<Maybe<MembersInput>>>;
  socialMedias?: Maybe<Array<Maybe<SocialMediaInput>>>;
};

export type OdgSampleType = {
  __typename?: 'OdgSampleType';
  name?: Maybe<Scalars['String']>;
};

export type OdgType = {
  __typename?: 'OdgType';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<LngString>;
  appellations?: Maybe<Array<Maybe<AppellationType>>>;
  members?: Maybe<Array<Maybe<MembersType>>>;
  socialMedias?: Maybe<Array<Maybe<SocialMediaFront>>>;
};

export type Option = {
  __typename?: 'Option';
  title?: Maybe<Scalars['String']>;
};

export type OrganismMember = {
  __typename?: 'OrganismMember';
  _id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  role?: Maybe<LngString>;
  displayName?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
};

export type OrganismMemberFront = {
  __typename?: 'OrganismMemberFront';
  _id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
};

export type OrganismMemberInput = {
  _id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  role?: Maybe<LngStringInput>;
  displayName?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
};

export type OrphanWineEntity = {
  __typename?: 'OrphanWineEntity';
  wineEntityId?: Maybe<Scalars['ID']>;
  estateName?: Maybe<Scalars['String']>;
  mainAppellation?: Maybe<Scalars['String']>;
  mainCommune?: Maybe<Scalars['String']>;
  communeINSEEcode?: Maybe<Scalars['String']>;
  businessAddress?: Maybe<Address>;
};

export type OwnerOperator = {
  __typename?: 'OwnerOperator';
  operators?: Maybe<Array<Maybe<TeamMemberBoc>>>;
  owners?: Maybe<Array<Maybe<TeamMemberBoc>>>;
  numMembers?: Maybe<Scalars['Int']>;
};

export type PHp = {
  __typename?: 'PHp';
  name?: Maybe<Scalars['String']>;
  hasBadge?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
};

export type Plc = {
  __typename?: 'PLC';
  logo?: Maybe<FtImg>;
  certificationBody?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isInConversion?: Maybe<Scalars['Boolean']>;
  conversionLabel?: Maybe<Scalars['String']>;
};

export type Packaging = {
  __typename?: 'Packaging';
  centilisation?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['Int']>;
  conditionningMode?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  gtin?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
};

export type PackagingInput = {
  centilisation?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['Int']>;
  conditionningMode?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  gtin?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
};

export type PaginatedJournalist = {
  __typename?: 'PaginatedJournalist';
  items?: Maybe<Array<Maybe<JournalistSu>>>;
  pageNumber?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type PaginatedWineEntityList = {
  __typename?: 'PaginatedWineEntityList';
  items?: Maybe<Array<Maybe<SimpleWineEntity>>>;
  pageNumber?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type PaperSubcriptionData = {
  __typename?: 'PaperSubcriptionData';
  wineEntityId?: Maybe<Scalars['ID']>;
  accessibility?: Maybe<Array<Maybe<Scalars['String']>>>;
  isBookingRequired?: Maybe<Scalars['Boolean']>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  priceRange?: Maybe<Array<Maybe<Scalars['Int']>>>;
  touristLabels?: Maybe<Scalars['String']>;
  description?: Maybe<LngString>;
  visitSchedule?: Maybe<Scalars['String']>;
  hasOtherCulture?: Maybe<Scalars['Boolean']>;
  otherCulture?: Maybe<Scalars['String']>;
  bugFightMethod?: Maybe<Scalars['String']>;
  byGrassing?: Maybe<Scalars['Boolean']>;
  grassingMethod?: Maybe<Scalars['String']>;
  done?: Maybe<Scalars['Boolean']>;
  redVineSurface?: Maybe<Scalars['Int']>;
  whiteVineSurface?: Maybe<Scalars['Int']>;
  redAvgProd?: Maybe<Scalars['Int']>;
  whiteAvgProd?: Maybe<Scalars['Int']>;
  redWineAssembly?: Maybe<Array<Maybe<SimpleGrapeVarietyPart>>>;
  whiteWineAssembly?: Maybe<Array<Maybe<SimpleGrapeVarietyPart>>>;
  certificationFiles?: Maybe<Array<Maybe<Document>>>;
};

export type PaymentInformationInput = {
  iban?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
};

export type PaymentInformationType = {
  __typename?: 'PaymentInformationType';
  iban?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
};

export type PersonInput = {
  _id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
};

export type PersonOption = {
  __typename?: 'PersonOption';
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  functions?: Maybe<Array<Maybe<Scalars['String']>>>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['ID']>;
  image?: Maybe<Img>;
};

export type PersonOptionInput = {
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  functions?: Maybe<Array<Maybe<Scalars['String']>>>;
  phoneNumber?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  image?: Maybe<ImgInput>;
  id?: Maybe<Scalars['ID']>;
};

export type PersonSimple = {
  __typename?: 'PersonSimple';
  _id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
};

export type PreprocessedResponse = {
  __typename?: 'PreprocessedResponse';
  fr?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  de?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
  nl?: Maybe<Scalars['String']>;
  ja?: Maybe<Scalars['String']>;
  zh?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  scale?: Maybe<Scalars['Int']>;
  wineId?: Maybe<Scalars['ID']>;
  vintageId?: Maybe<Scalars['ID']>;
  cuvee?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  wineImage?: Maybe<Img>;
  wineEntityId?: Maybe<Scalars['ID']>;
  scoreUrl?: Maybe<Scalars['String']>;
  wineName?: Maybe<Scalars['String']>;
  criticName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  appellation?: Maybe<Scalars['String']>;
  tastingDate?: Maybe<Scalars['String']>;
  wineShortId?: Maybe<Scalars['String']>;
  wineEntityImage?: Maybe<Img>;
  notification?: Maybe<Scalars['Boolean']>;
  wineEntityName?: Maybe<Scalars['String']>;
  wineEntityShortId?: Maybe<Scalars['String']>;
};

export type PriceDetail = {
  __typename?: 'PriceDetail';
  kind?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
};

export type PriceDetailInput = {
  kind?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
};

export type ProdcutionType = {
  __typename?: 'ProdcutionType';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  unitOfMeasure?: Maybe<Scalars['String']>;
};

export type ProprieteVignobleScreen = {
  __typename?: 'ProprieteVignobleScreen';
  soil?: Maybe<Array<Maybe<Scalars['String']>>>;
  subsoil?: Maybe<Array<Maybe<Scalars['String']>>>;
  soilDescription?: Maybe<LngString>;
  grassGrowing?: Maybe<Array<Maybe<Scalars['String']>>>;
  grassGrowingIsTotal?: Maybe<Scalars['Boolean']>;
  grassGrowingIsTemporary?: Maybe<Scalars['Boolean']>;
  fertilizers?: Maybe<Array<Maybe<Scalars['String']>>>;
  worksUnderTheRow?: Maybe<Array<Maybe<Scalars['String']>>>;
  soilWorkDescription?: Maybe<LngString>;
  pruningMethod?: Maybe<Array<Maybe<Scalars['String']>>>;
  greenProcedures?: Maybe<Array<Maybe<Scalars['String']>>>;
  vineWorkDescription?: Maybe<LngString>;
  sexualConfusion?: Maybe<Scalars['Boolean']>;
  insectWorkDescription?: Maybe<LngString>;
  description?: Maybe<LngString>;
  otherCultures?: Maybe<LngString>;
};

export type ProprieteVignobleScreenInput = {
  soil?: Maybe<Array<Maybe<Scalars['String']>>>;
  subsoil?: Maybe<Array<Maybe<Scalars['String']>>>;
  soilDescription?: Maybe<LngStringInput>;
  grassGrowing?: Maybe<Array<Maybe<Scalars['String']>>>;
  grassGrowingIsTotal?: Maybe<Scalars['Boolean']>;
  grassGrowingIsTemporary?: Maybe<Scalars['Boolean']>;
  fertilizers?: Maybe<Array<Maybe<Scalars['String']>>>;
  worksUnderTheRow?: Maybe<Array<Maybe<Scalars['String']>>>;
  soilWorkDescription?: Maybe<LngStringInput>;
  pruningMethod?: Maybe<Array<Maybe<Scalars['String']>>>;
  greenProcedures?: Maybe<Array<Maybe<Scalars['String']>>>;
  vineWorkDescription?: Maybe<LngStringInput>;
  sexualConfusion?: Maybe<Scalars['Boolean']>;
  insectWorkDescription?: Maybe<LngStringInput>;
  description?: Maybe<LngStringInput>;
  otherCultures?: Maybe<LngStringInput>;
};

export type Publication = {
  __typename?: 'Publication';
  _id?: Maybe<Scalars['String']>;
  publication?: Maybe<LngString>;
  year?: Maybe<Scalars['Int']>;
};

export type PublicationFront = {
  __typename?: 'PublicationFront';
  _id?: Maybe<Scalars['String']>;
  publication?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type PublicationInput = {
  _id?: Maybe<Scalars['String']>;
  publication?: Maybe<LngStringInput>;
  year?: Maybe<Scalars['Int']>;
};

export type QrcodeRedirect = {
  __typename?: 'QrcodeRedirect';
  _id?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
  vintageId?: Maybe<Scalars['String']>;
  wineId?: Maybe<Scalars['String']>;
};

export type QrcodeRedirectFront = {
  __typename?: 'QrcodeRedirectFront';
  _id?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
  vintageId?: Maybe<Scalars['String']>;
  wineId?: Maybe<Scalars['String']>;
};

export type QrcodeRedirectInput = {
  url?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
  vintageId?: Maybe<Scalars['String']>;
  wineId?: Maybe<Scalars['String']>;
};

export type QrcodeStats = {
  __typename?: 'QrcodeStats';
  _id?: Maybe<Scalars['ID']>;
  ipAddress?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
  vintageId?: Maybe<Scalars['String']>;
  wineId?: Maybe<Scalars['String']>;
};

export type QrcodeStatsFront = {
  __typename?: 'QrcodeStatsFront';
  _id?: Maybe<Scalars['ID']>;
  ipAddress?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
  vintageId?: Maybe<Scalars['String']>;
  wineId?: Maybe<Scalars['String']>;
};

export type QrcodeStatsInput = {
  ipAdress?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
  vintageId?: Maybe<Scalars['String']>;
  wineId?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  GetClassificationFront?: Maybe<Array<Maybe<ClassificationFront>>>;
  GetOwnerOperator?: Maybe<OwnerOperator>;
  GetBesvInfo?: Maybe<Array<Maybe<BesvInfo>>>;
  AboutContactDetails?: Maybe<AboutContactDetails>;
  AboutHistory?: Maybe<AboutHistory>;
  AboutCellars?: Maybe<AboutCellars>;
  AboutVineyard?: Maybe<AboutVineyard>;
  GetOrphanWineEntities?: Maybe<Array<Maybe<OrphanWineEntity>>>;
  GetRandomWineEntities?: Maybe<Array<Maybe<WineEntityBrief>>>;
  GetRandomWineEntitiesFromCommune?: Maybe<Array<Maybe<WineEntityBrief>>>;
  GetCommuneFront?: Maybe<CommuneFront>;
  GetImported?: Maybe<Array<Maybe<Imported>>>;
  ContactCard?: Maybe<ContactCard>;
  MainTitle?: Maybe<MainTitle>;
  FtTeamPresentation?: Maybe<FtTeamPresentation>;
  ExportData?: Maybe<ExportData>;
  GetEstateVisibility?: Maybe<Scalars['Boolean']>;
  AllFoodPairings?: Maybe<Array<Maybe<FoodPairing>>>;
  AllowedAppellationLabels?: Maybe<Array<Maybe<Scalars['String']>>>;
  VineyardFrontPopin?: Maybe<VineyardFrontPopin>;
  CellarFrontPopin?: Maybe<CellarFrontPopin>;
  EstateFrontScreen?: Maybe<EstateFrontScreen>;
  AccountScreen?: Maybe<AccountScreen>;
  AccountScreenEmpty?: Maybe<AccountScreenEmpty>;
  SubscriptionSummary?: Maybe<SubscriptionSummary>;
  IndicatorDashboard?: Maybe<IndicatorDashboard>;
  GetCommunes?: Maybe<Array<Maybe<CommuneSimple>>>;
  GetCommunesOfAppellation?: Maybe<Array<Maybe<CommuneSimple>>>;
  GetCommunesOptions?: Maybe<Array<Maybe<CommuneOption>>>;
  GetCommunePostalCode?: Maybe<Array<Maybe<CommuneSimple>>>;
  GetCertificationObjects?: Maybe<Array<Maybe<SimpleCertificationObject>>>;
  GetLabelsForBOC?: Maybe<Array<Maybe<LabelsBoc>>>;
  GetLabelsForBOF?: Maybe<Array<Maybe<LabelsBof>>>;
  AppellationDependencyObject?: Maybe<Scalars['JSON']>;
  SurfaceImportCvi?: Maybe<SurfaceImportCvi>;
  SurfaceDeProductionScreen?: Maybe<SurfaceDeProductionScreen>;
  SurfaceManualScreen?: Maybe<SurfaceManualScreen>;
  TeamGeneral?: Maybe<TeamGeneral>;
  TeamMemberBoc?: Maybe<TeamMemberBoc>;
  CellarsDashboard?: Maybe<CellarsDashboard>;
  FeminineTitles?: Maybe<Array<Maybe<Scalars['String']>>>;
  ProprieteVignobleScreen?: Maybe<ProprieteVignobleScreen>;
  GeneralInformationScreen?: Maybe<GeneralInformationScreen>;
  EstateScreen?: Maybe<EstateScreen>;
  CellarTabScreen?: Maybe<CellarTabScreen>;
  GetPaperSubscriptionData?: Maybe<PaperSubcriptionData>;
  GetNotifications?: Maybe<Array<Maybe<FeretNotification>>>;
  GetFeretContacts?: Maybe<ContactPagination>;
  GetFeretContactEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  GetFeretContact?: Maybe<FeretContactType>;
  GetFeretContactStats?: Maybe<ContactStats>;
  GetContactsFromCSV?: Maybe<CsvContactType>;
  GetClassificationOrganisms?: Maybe<Array<Maybe<ClassificationOrganismType>>>;
  GetClassificationOrganismFront?: Maybe<ClassificationOrganismFront>;
  GetClassificationOrganismList?: Maybe<Array<Maybe<ClassificationOrganismItem>>>;
  GetQrcodeRedirect?: Maybe<Array<Maybe<QrcodeRedirect>>>;
  GetQrcodeRedirectFront?: Maybe<Array<Maybe<QrcodeRedirectFront>>>;
  GetQrcodeStats?: Maybe<Array<Maybe<QrcodeStats>>>;
  GetQrcodeStatsFront?: Maybe<Array<Maybe<QrcodeStatsFront>>>;
  GetDiffusionList?: Maybe<DiffusionListType>;
  GetDiffusionLists?: Maybe<Array<Maybe<DiffusionListType>>>;
  GetAuthor?: Maybe<Array<Maybe<AuthorType>>>;
  GetAuthorFront?: Maybe<AuthorFrontType>;
  GetAuthorFrontList?: Maybe<Array<Maybe<AuthorFrontItemType>>>;
  GetOdg?: Maybe<Array<Maybe<OdgType>>>;
  GetOdgFront?: Maybe<Array<Maybe<OdgFrontType>>>;
  GetOdgSample?: Maybe<Array<Maybe<OdgSampleType>>>;
  GetStreamerFtDiffusions?: Maybe<Array<Maybe<FtDiffusionType>>>;
  GetWatcherFtDiffusions?: Maybe<Array<Maybe<FtDiffusionType>>>;
  GetFtRequestByCode?: Maybe<FtRequestType>;
  GetFtDiffusionByContact?: Maybe<FeretContactDiffMetricsType>;
  GetFtDiffusionByDiffusionList?: Maybe<DiffusionListDiffType>;
  GetCanCreateFt?: Maybe<CanCreateFtType>;
  GetLastDiffusionMessage?: Maybe<Scalars['String']>;
  GetDiffusionParameters?: Maybe<DiffusionParametersType>;
  GetFtParameters?: Maybe<Array<Maybe<BocFtParameters>>>;
  GetFtParameter?: Maybe<BocFtParameters>;
  GetFtModels?: Maybe<Array<Maybe<FtModel>>>;
  GetFtPdfUrl?: Maybe<FileUrl>;
  GetFtPdfUrls?: Maybe<Array<Maybe<FileUrl>>>;
  GetFtZipUrl?: Maybe<FileUrl>;
  SearchVintages?: Maybe<WineResult>;
  searchWines?: Maybe<WineResult>;
  SearchSimilarVintages?: Maybe<WineResult>;
  SearchActivities?: Maybe<ActivityResult>;
  SearchSimilarActivities?: Maybe<ActivityResult>;
  SearchWineEntities?: Maybe<WineEntityResult>;
  SearchSimilarWineEntities?: Maybe<WineEntityResult>;
  SearchAll?: Maybe<AllResult>;
  HomeResult?: Maybe<HomeResult>;
  GetWineCardInformation?: Maybe<WineCardInformation>;
  GetWineEntityCardInformation?: Maybe<WineEntityCardInformation>;
  GetActivityCardInformation?: Maybe<ActivityCardInformation>;
  AutocompleteSearch?: Maybe<Array<Maybe<AutocompleteSearchResult>>>;
  GetFormationOrganisms?: Maybe<Array<Maybe<FormationOrganism>>>;
  GetFormationOrganismsFront?: Maybe<Array<Maybe<FormationOrganismFront>>>;
  GetFormationOrganismsSample?: Maybe<Array<Maybe<FormationOrganismSample>>>;
  GetRepresentationOrganisms?: Maybe<Array<Maybe<RepresentationOrganismType>>>;
  GetRepresentationOrganismsFront?: Maybe<Array<Maybe<RepresentationOrganismFront>>>;
  GetCompetitions?: Maybe<Array<Maybe<CompetitionType>>>;
  GetCompetitionsFront?: Maybe<Array<Maybe<CompetitionFront>>>;
  GetVintagesOfCompetition?: Maybe<Array<Maybe<SimpleRewardVintage>>>;
  GetRandomCompetitionSample?: Maybe<Array<Maybe<SimpleCompetition>>>;
  GetCompetitionList?: Maybe<Array<Maybe<SimpleCompetition>>>;
  GetArticles?: Maybe<Array<Maybe<ArticleType>>>;
  GetArticleFront?: Maybe<ArticleFrontType>;
  GetArticleContent?: Maybe<ArticleContentType>;
  GetArticlesByAuthorId?: Maybe<Array<Maybe<AuthorArticleType>>>;
  GetArticleSummary?: Maybe<Array<Maybe<ArticleSummaryType>>>;
  GetAppellationDescriptions?: Maybe<Array<Maybe<AppellationDescriptionType>>>;
  GetAppellationDescriptionsFront?: Maybe<Array<Maybe<AppellationDescriptionFrontType>>>;
  GetAppellationDescriptionsSample?: Maybe<Array<Maybe<AppellationDescriptionsSample>>>;
  GetAppellationDescriptionsNameSubregion?: Maybe<Array<Maybe<NameSubregionType>>>;
  GetAppellationVintageIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  GetAppellationVintage?: Maybe<VintageAppellationType>;
  GetSubscriptionData?: Maybe<SubscriptionData>;
  GetUnpaidSubscriptionInformation?: Maybe<UnpaidSubscriptionInformation>;
  GetTastingRequest?: Maybe<TastingRequestType>;
  GetTastingRequests?: Maybe<Array<Maybe<TastingRequestType>>>;
  GetJournalistList?: Maybe<Array<Maybe<JournalistType>>>;
  GetFirstWineId?: Maybe<Scalars['String']>;
  GetFirstActivityId?: Maybe<Scalars['String']>;
  GetExplorerKeywords?: Maybe<Array<Maybe<ExplorerKeywords>>>;
  GetIndicatorsMetrics?: Maybe<WineEntityMetrics>;
  GetMemberMetrics?: Maybe<MemberMetrics>;
  GetContactMetrics?: Maybe<ContactMetrics>;
  GetFtParametersMetrics?: Maybe<FtParameterMetrics>;
  GetWineMetrics?: Maybe<Array<Maybe<WineMetrics>>>;
  GetWineActivityMetrics?: Maybe<Array<Maybe<WineActivityMetrics>>>;
  GetVineyardMetrics?: Maybe<VineyardMetrics>;
  GetEnumByField?: Maybe<Array<Maybe<EnumType>>>;
  GetRoleEnum?: Maybe<Array<Maybe<EnumType>>>;
  GetUserSettings?: Maybe<UserSettingsType>;
  GetWineEntitiesData?: Maybe<Array<Maybe<WineEntitiesData>>>;
  GetWineEntitiesCandidates?: Maybe<WineEntitiesCandidatesResponse>;
  GetPreprocessedData?: Maybe<Array<Maybe<PreprocessedResponse>>>;
  GetTranslationOptions?: Maybe<Array<Maybe<TranslationOption>>>;
  GetExternalServiceRequests?: Maybe<ServiceInformations>;
  VintageRewardScreen?: Maybe<RewardScreen>;
  ContactScreen?: Maybe<ContactScreen>;
  GetActivityFront?: Maybe<Array<Maybe<ActivityFront>>>;
  ActivityFront?: Maybe<FullActivityFront>;
  ActivityTabScreen?: Maybe<ActivityTabScreen>;
  ListActivities?: Maybe<Array<Maybe<ActivityFront>>>;
  ActivityList?: Maybe<ActivityList>;
  GetCommonalitiesActivities?: Maybe<ActivitiesCommonalitiesType>;
  GetSimpleUserById?: Maybe<SimpleUser>;
  GetUserByEmail?: Maybe<SimpleUser>;
  GetCommonalitiesWineEntities?: Maybe<WineEntitiesCommonalitiesType>;
  GetWineById?: Maybe<SimpleWineType>;
  GetVintagesByWineId?: Maybe<Array<Maybe<VintageType>>>;
  FrontWinePresentation?: Maybe<FrontWinePresentation>;
  ListWines?: Maybe<Array<Maybe<SimpleWine>>>;
  FrontWineList?: Maybe<FrontWineList>;
  WinePresentationScreen?: Maybe<WinePresentationScreen>;
  GetCommonalitiesWine?: Maybe<WinesCommonalitiesType>;
  JournalistAccountScreen?: Maybe<JournalistAccountScreen>;
  GetServicesOfferByJournalist?: Maybe<GetServicesOfferByJournalist>;
  GetJournalists?: Maybe<Array<Maybe<JournalistItem>>>;
  GetJournalistSamples?: Maybe<Array<Maybe<JournalistItem>>>;
  GetJournalist?: Maybe<JournalistItemFront>;
  FrontVintageViticulture?: Maybe<FrontVintageViticulture>;
  FrontVintageWineMaking?: Maybe<FrontVintageWineMaking>;
  VintageWineMakingScreen?: Maybe<VintageWineMakingScreen>;
  FrontVintageAgeing?: Maybe<FrontVintageAgeing>;
  VintageIdentityScreen?: Maybe<VintageIdentityScreen>;
  VintageViticultureScreen?: Maybe<VintageViticultureScreen>;
  VintageAgeingScreen?: Maybe<VintageAgeingScreen>;
  VintageProductionScreen?: Maybe<VintageProductionScreen>;
  VintageNutritionScreen?: Maybe<VintageNutritionScreen>;
  FrontVintageMarketing?: Maybe<FrontVintageMarketing>;
  VintageMarketingScreen?: Maybe<VintageMarketingScreen>;
  VintageAdviceScreen?: Maybe<VintageAdviceScreen>;
  FrontVintageAdvice?: Maybe<FrontVintageAdvice>;
  FrontVintageLabelsPopin?: Maybe<Array<Maybe<FrontLabel>>>;
  FrontVintageDetailedHarvest?: Maybe<Array<Maybe<FrontDetailedHarvest>>>;
  FrontVintageProduction?: Maybe<FrontVintageProduction>;
  FrontVintageNutrition?: Maybe<FrontVintageNutrition>;
  FrontVintageQrcode?: Maybe<FrontVintageQrcode>;
  FrontQrcodeRedirect?: Maybe<FrontQrcodeRedirect>;
  FrontVintage?: Maybe<FrontVintage>;
  VMS?: Maybe<Vms>;
  SimpleVintages?: Maybe<Array<Maybe<SimpleVintage>>>;
  GetSubscriptionMetrics?: Maybe<Array<Maybe<BofWineEntityMetrics>>>;
  GetCustomFonts?: Maybe<Array<Maybe<CustomFontType>>>;
  GetFtpDiffusions?: Maybe<Array<Maybe<GetFtpDiffusions>>>;
  GetFtpDiffusionLog?: Maybe<FtpDiffusionLog>;
  UserEntity?: Maybe<EntityType>;
  ListWineEntities?: Maybe<PaginatedWineEntityList>;
  ListJournalists?: Maybe<PaginatedJournalist>;
  GetSu?: Maybe<Array<Maybe<SimpleUser>>>;
  TLM?: Maybe<Array<Maybe<FtTeamMember>>>;
  GetCategories?: Maybe<Category>;
  GetIdentityInformation?: Maybe<IdentityInformation>;
  GetContactInformation?: Maybe<ContactInformation>;
  GetTeamMember?: Maybe<FtTeamMember>;
  GetWineEntityQuote?: Maybe<FtQuote>;
  GetImages?: Maybe<Array<Maybe<FtImg>>>;
  GetVineInformations?: Maybe<Array<Maybe<FtItem>>>;
  GetVintageAssembly?: Maybe<Array<Maybe<VintageAssemblyType>>>;
  GetVintageDistribution?: Maybe<Array<Maybe<DistributionType>>>;
  GetWineEntityPresentation?: Maybe<Array<Maybe<FtItem>>>;
  GetVintageViticulture?: Maybe<FtVintageViticulture>;
  GetTeamDescription?: Maybe<FtTeamDescription>;
  GetLanguageIndicator?: Maybe<FtLanguageIndicator>;
  GetSocialMedia?: Maybe<Array<Maybe<FtItem>>>;
  GetHarvestByGrapeVariety?: Maybe<Array<Maybe<FtHarvestByGrapeVariety>>>;
  PHp?: Maybe<PHp>;
  VMILC?: Maybe<Array<Maybe<Plc>>>;
  GetCertificationsWE?: Maybe<Array<Maybe<Plc>>>;
  VMID?: Maybe<Vmid>;
  VMI?: Maybe<Vmi>;
  GetVintageVendagesGlobales?: Maybe<Array<Maybe<FtItem>>>;
  GetWineDescription?: Maybe<WineDescription>;
  GetAllCellars?: Maybe<Array<Maybe<FtCellar>>>;
  GetAllActivities?: Maybe<Array<Maybe<FtActivity>>>;
  GetQRCode?: Maybe<FtqrCode>;
  GetWineName?: Maybe<SimpleContent>;
  GetVintageYear?: Maybe<SimpleContent>;
  GetWineRank?: Maybe<SimpleContent>;
  GetWineAppellation?: Maybe<SimpleContent>;
  GetWineColor?: Maybe<SimpleContent>;
  GetWineSweetness?: Maybe<SimpleContent>;
  GetWineType?: Maybe<SimpleContent>;
  GetVintageTechnicalInformation?: Maybe<Array<Maybe<FtItem>>>;
  GetAlcPerVol?: Maybe<SimpleContent>;
  GetPH?: Maybe<SimpleContent>;
  GetTotaleAcidity?: Maybe<SimpleContent>;
  GetResidualSugar?: Maybe<SimpleContent>;
  GetVolatileAcidity?: Maybe<SimpleContent>;
  GetTotalSO2?: Maybe<SimpleContent>;
  GetIPT?: Maybe<SimpleContent>;
  GetVintageClimatology?: Maybe<Array<Maybe<FtItem>>>;
  GetTotalSurfaceArea?: Maybe<SimpleContent>;
  GetAverageDensity?: Maybe<SimpleContent>;
  GetAverageAge?: Maybe<SimpleContent>;
  GetGlobalClimatology?: Maybe<FtGlobalClimatology>;
  GetSoilsSubSoils?: Maybe<Array<Maybe<FtItem>>>;
  GetWineBreedingDescription?: Maybe<FtWineBreedingDescription>;
  GetWineBreedings?: Maybe<Array<Maybe<FtOtherMaturation>>>;
  VME?: Maybe<Vme>;
  GetAgeingDescription?: Maybe<SimpleContent>;
  GetAgeingTechnicalInformations?: Maybe<SimpleContent>;
  GetVintageTastingDescription?: Maybe<FtTastingDescription>;
  GetVintageConseilService?: Maybe<Array<Maybe<FtItem>>>;
  GetVintageFoodWinePairing?: Maybe<FtVintageFoodWinePairing>;
  GetServiceTemperature?: Maybe<SimpleContent>;
  GetApogee?: Maybe<SimpleContent>;
  GetTreatmentBeforeService?: Maybe<SimpleContent>;
  GetAppearanceDetails?: Maybe<Array<Maybe<FtItem>>>;
  GetShade?: Maybe<SimpleContent>;
  GetShadeIntensity?: Maybe<SimpleContent>;
  GetShine?: Maybe<SimpleContent>;
  GetLimpidity?: Maybe<SimpleContent>;
  GetTears?: Maybe<SimpleContent>;
  GetPalateDetails?: Maybe<Array<Maybe<FtItem>>>;
  GetAttack?: Maybe<SimpleContent>;
  GetAcidity?: Maybe<SimpleContent>;
  GetTannins?: Maybe<SimpleContent>;
  GetLength?: Maybe<SimpleContent>;
  GetTasteIntensity?: Maybe<SimpleContent>;
  GetTasteAromas?: Maybe<Array<Maybe<FtItem>>>;
  GetNoseDetails?: Maybe<Array<Maybe<FtItem>>>;
  GetOlfactoryAromas?: Maybe<SimpleContent>;
  GetOlfactoryIntensity?: Maybe<SimpleContent>;
  GetVintageProduction?: Maybe<Array<Maybe<FtItem>>>;
  GetProducedVolume?: Maybe<SimpleContent>;
  GetYield?: Maybe<SimpleContent>;
  GetProdHarvest?: Maybe<SimpleContent>;
  GetBottlingInformations?: Maybe<FtBottlingInformations>;
  GetMedals?: Maybe<Array<Maybe<FtAwards>>>;
  GetNotes?: Maybe<Array<Maybe<FtAwards>>>;
  GetVintageVinification?: Maybe<Array<Maybe<FtItem>>>;
  GetVinificationDescription?: Maybe<GetVinificationDescription>;
  GetConsultingOenologist?: Maybe<FtConsultingOenologist>;
  GetMethod?: Maybe<SimpleContent>;
  GetLocation?: Maybe<SimpleContent>;
  GetDestemming?: Maybe<SimpleContent>;
  GetCrushing?: Maybe<SimpleContent>;
  GetPressType?: Maybe<SimpleContent>;
  GetSorting?: Maybe<SimpleContent>;
  GetMaceration?: Maybe<Array<Maybe<FtMaceration>>>;
  GetAlcoholicFermentation?: Maybe<Array<Maybe<FtItem>>>;
};


export type QueryGetClassificationFrontArgs = {
  classificationOrganismSlug: Scalars['String'];
};


export type QueryGetOwnerOperatorArgs = {
  wineEntityId?: Maybe<Scalars['ID']>;
};


export type QueryGetBesvInfoArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryAboutContactDetailsArgs = {
  wineEntityId?: Maybe<Scalars['ID']>;
};


export type QueryAboutHistoryArgs = {
  wineEntityId?: Maybe<Scalars['ID']>;
};


export type QueryAboutCellarsArgs = {
  wineEntityId?: Maybe<Scalars['ID']>;
};


export type QueryAboutVineyardArgs = {
  wineEntityId?: Maybe<Scalars['ID']>;
};


export type QueryGetOrphanWineEntitiesArgs = {
  searchString?: Maybe<Scalars['String']>;
};


export type QueryGetRandomWineEntitiesArgs = {
  sampleSize?: Maybe<Scalars['Int']>;
};


export type QueryGetRandomWineEntitiesFromCommuneArgs = {
  communeINSEEcode: Scalars['String'];
};


export type QueryGetCommuneFrontArgs = {
  name: Scalars['String'];
};


export type QueryContactCardArgs = {
  wineEntityId?: Maybe<Scalars['ID']>;
};


export type QueryMainTitleArgs = {
  wineEntityId?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryFtTeamPresentationArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetEstateVisibilityArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryAllowedAppellationLabelsArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryVineyardFrontPopinArgs = {
  wineEntityId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryCellarFrontPopinArgs = {
  wineEntityId: Scalars['ID'];
  cellarId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryEstateFrontScreenArgs = {
  wineEntityShortId: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryAccountScreenArgs = {
  userId: Scalars['ID'];
};


export type QueryAccountScreenEmptyArgs = {
  userId: Scalars['ID'];
};


export type QuerySubscriptionSummaryArgs = {
  wineEntityId?: Maybe<Scalars['ID']>;
};


export type QueryIndicatorDashboardArgs = {
  wineEntityId?: Maybe<Scalars['ID']>;
};


export type QueryGetCommunesArgs = {
  postalCode?: Maybe<Scalars['String']>;
};


export type QueryGetCommunesOfAppellationArgs = {
  appellationLabel?: Maybe<Scalars['String']>;
  filterByRegion?: Maybe<Scalars['String']>;
};


export type QueryGetCommunesOptionsArgs = {
  postalCode?: Maybe<Scalars['String']>;
  filterByRegion?: Maybe<Scalars['String']>;
};


export type QueryGetCommunePostalCodeArgs = {
  communePostalCode?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
};


export type QueryGetCertificationObjectsArgs = {
  level?: Maybe<CertificationLevel>;
};


export type QueryGetLabelsForBocArgs = {
  wineEntityId: Scalars['ID'];
  wineId?: Maybe<Scalars['ID']>;
  vintageId?: Maybe<Scalars['ID']>;
  level?: Maybe<CertificationLevel>;
};


export type QueryGetLabelsForBofArgs = {
  level?: Maybe<CertificationLevel>;
};


export type QueryAppellationDependencyObjectArgs = {
  dependentFields?: Maybe<Array<Maybe<AppellationDependentField>>>;
};


export type QuerySurfaceImportCviArgs = {
  wineEntityId: Scalars['ID'];
};


export type QuerySurfaceDeProductionScreenArgs = {
  wineEntityId: Scalars['ID'];
};


export type QuerySurfaceManualScreenArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryTeamGeneralArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryTeamMemberBocArgs = {
  wineEntityId: Scalars['ID'];
  personId: Scalars['ID'];
};


export type QueryCellarsDashboardArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryProprieteVignobleScreenArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGeneralInformationScreenArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryEstateScreenArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryCellarTabScreenArgs = {
  wineEntityId: Scalars['ID'];
  cellarId: Scalars['ID'];
};


export type QueryGetPaperSubscriptionDataArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetNotificationsArgs = {
  userId?: Maybe<Scalars['ID']>;
};


export type QueryGetFeretContactsArgs = {
  wineEntityId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<SortType>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGetFeretContactEmailsArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetFeretContactArgs = {
  contactId?: Maybe<Scalars['ID']>;
};


export type QueryGetFeretContactStatsArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetContactsFromCsvArgs = {
  csvFile: DocumentInput;
  wineEntityId: Scalars['ID'];
};


export type QueryGetClassificationOrganismsArgs = {
  classificationOrganismId?: Maybe<Scalars['ID']>;
};


export type QueryGetClassificationOrganismFrontArgs = {
  name: Scalars['String'];
  locale: Scalars['String'];
};


export type QueryGetClassificationOrganismListArgs = {
  name?: Maybe<Scalars['String']>;
};


export type QueryGetQrcodeRedirectArgs = {
  url?: Maybe<Scalars['String']>;
};


export type QueryGetQrcodeRedirectFrontArgs = {
  url?: Maybe<Scalars['String']>;
};


export type QueryGetQrcodeStatsArgs = {
  vintageId?: Maybe<Scalars['String']>;
};


export type QueryGetQrcodeStatsFrontArgs = {
  vintageId?: Maybe<Scalars['String']>;
};


export type QueryGetDiffusionListArgs = {
  diffusionListId: Scalars['ID'];
};


export type QueryGetDiffusionListsArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetAuthorArgs = {
  authorId?: Maybe<Scalars['ID']>;
};


export type QueryGetAuthorFrontArgs = {
  name?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetAuthorFrontListArgs = {
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryGetOdgArgs = {
  odgId?: Maybe<Scalars['ID']>;
};


export type QueryGetOdgFrontArgs = {
  name?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetOdgSampleArgs = {
  name?: Maybe<Scalars['String']>;
};


export type QueryGetStreamerFtDiffusionsArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetWatcherFtDiffusionsArgs = {
  userId: Scalars['ID'];
};


export type QueryGetFtRequestByCodeArgs = {
  code: Scalars['String'];
};


export type QueryGetFtDiffusionByContactArgs = {
  wineEntityId: Scalars['ID'];
  ftId: Scalars['ID'];
};


export type QueryGetFtDiffusionByDiffusionListArgs = {
  wineEntityId: Scalars['ID'];
  ftId: Scalars['ID'];
};


export type QueryGetCanCreateFtArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetLastDiffusionMessageArgs = {
  ftParametersId: Scalars['ID'];
};


export type QueryGetDiffusionParametersArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetFtParametersArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetFtParameterArgs = {
  ftParametersId: Scalars['ID'];
};


export type QueryGetFtModelsArgs = {
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
};


export type QueryGetFtPdfUrlArgs = {
  ftParametersId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetFtPdfUrlsArgs = {
  ftParametersId: Scalars['ID'];
  locales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGetFtZipUrlArgs = {
  ftParametersId: Scalars['ID'];
};


export type QuerySearchVintagesArgs = {
  searchInput?: Maybe<VintageSearchInput>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
};


export type QuerySearchWinesArgs = {
  searchInput?: Maybe<VintageSearchInput>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
};


export type QuerySearchSimilarVintagesArgs = {
  searchInput?: Maybe<VintageSearchInput>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
};


export type QuerySearchActivitiesArgs = {
  searchInput?: Maybe<ActivitySearchInput>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
};


export type QuerySearchSimilarActivitiesArgs = {
  searchInput?: Maybe<ActivitySearchInput>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
};


export type QuerySearchWineEntitiesArgs = {
  searchInput?: Maybe<WineEntitySearchInput>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  call?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QuerySearchSimilarWineEntitiesArgs = {
  searchInput?: Maybe<WineEntitySearchInput>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
};


export type QuerySearchAllArgs = {
  searchInput?: Maybe<AllSearchInput>;
};


export type QueryGetWineCardInformationArgs = {
  wineId: Scalars['ID'];
};


export type QueryGetWineEntityCardInformationArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetActivityCardInformationArgs = {
  activityId: Scalars['ID'];
};


export type QueryAutocompleteSearchArgs = {
  searchText: Scalars['String'];
};


export type QueryGetFormationOrganismsArgs = {
  formationOrganismId?: Maybe<Scalars['ID']>;
};


export type QueryGetFormationOrganismsFrontArgs = {
  name?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
};


export type QueryGetFormationOrganismsSampleArgs = {
  name?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
};


export type QueryGetRepresentationOrganismsArgs = {
  representationOrganismId?: Maybe<Scalars['ID']>;
};


export type QueryGetRepresentationOrganismsFrontArgs = {
  name?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  excludeId?: Maybe<Scalars['ID']>;
};


export type QueryGetCompetitionsArgs = {
  competitionId?: Maybe<Scalars['ID']>;
};


export type QueryGetCompetitionsFrontArgs = {
  name?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetVintagesOfCompetitionArgs = {
  competitionId?: Maybe<Scalars['String']>;
};


export type QueryGetRandomCompetitionSampleArgs = {
  sampleSize?: Maybe<Scalars['Int']>;
  excludeId?: Maybe<Scalars['ID']>;
};


export type QueryGetArticlesArgs = {
  articleId?: Maybe<Scalars['ID']>;
};


export type QueryGetArticleFrontArgs = {
  name: Scalars['String'];
  locale: Scalars['String'];
};


export type QueryGetArticleContentArgs = {
  articleId: Scalars['ID'];
  locale: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetArticlesByAuthorIdArgs = {
  authorId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetArticleSummaryArgs = {
  name?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetAppellationDescriptionsArgs = {
  appellationDescriptionId?: Maybe<Scalars['ID']>;
};


export type QueryGetAppellationDescriptionsFrontArgs = {
  name?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
};


export type QueryGetAppellationVintageIdsArgs = {
  label: Scalars['String'];
};


export type QueryGetAppellationVintageArgs = {
  vintageId: Scalars['ID'];
};


export type QueryGetSubscriptionDataArgs = {
  userId: Scalars['ID'];
};


export type QueryGetUnpaidSubscriptionInformationArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetTastingRequestArgs = {
  tastingRequestId?: Maybe<Scalars['ID']>;
};


export type QueryGetTastingRequestsArgs = {
  userId?: Maybe<Scalars['String']>;
  wineEntityId?: Maybe<Scalars['String']>;
};


export type QueryGetJournalistListArgs = {
  filters?: Maybe<JournalistFilter>;
};


export type QueryGetFirstWineIdArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetFirstActivityIdArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetExplorerKeywordsArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetIndicatorsMetricsArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetMemberMetricsArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetContactMetricsArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetFtParametersMetricsArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetWineMetricsArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetWineActivityMetricsArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetVineyardMetricsArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetEnumByFieldArgs = {
  field: Scalars['String'];
};


export type QueryGetRoleEnumArgs = {
  type: Scalars['String'];
  wineEntityId: Scalars['ID'];
  personId?: Maybe<Scalars['ID']>;
};


export type QueryGetUserSettingsArgs = {
  userId: Scalars['String'];
};


export type QueryGetWineEntitiesDataArgs = {
  file?: Maybe<DocumentInput>;
};


export type QueryGetWineEntitiesCandidatesArgs = {
  file?: Maybe<DocumentInput>;
};


export type QueryGetPreprocessedDataArgs = {
  file?: Maybe<DocumentInput>;
};


export type QueryGetTranslationOptionsArgs = {
  wineEntityId?: Maybe<Scalars['ID']>;
};


export type QueryGetExternalServiceRequestsArgs = {
  wineEntityId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
};


export type QueryVintageRewardScreenArgs = {
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
};


export type QueryContactScreenArgs = {
  wineEntityId: Scalars['ID'];
  personId?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
};


export type QueryGetActivityFrontArgs = {
  communeINSEEcode: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryActivityFrontArgs = {
  activityShortId?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryActivityTabScreenArgs = {
  wineEntityId: Scalars['ID'];
  activityId: Scalars['ID'];
};


export type QueryListActivitiesArgs = {
  wineEntityId?: Maybe<Scalars['ID']>;
  omit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryActivityListArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetCommonalitiesActivitiesArgs = {
  activityId: Scalars['ID'];
};


export type QueryGetSimpleUserByIdArgs = {
  userId: Scalars['ID'];
};


export type QueryGetUserByEmailArgs = {
  email?: Maybe<Scalars['String']>;
};


export type QueryGetCommonalitiesWineEntitiesArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetWineByIdArgs = {
  wineId: Scalars['ID'];
};


export type QueryGetVintagesByWineIdArgs = {
  wineId: Scalars['ID'];
};


export type QueryFrontWinePresentationArgs = {
  wineShortId: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryListWinesArgs = {
  wineEntityId?: Maybe<Scalars['ID']>;
};


export type QueryFrontWineListArgs = {
  wineEntityId?: Maybe<Scalars['ID']>;
  omitWineId?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryWinePresentationScreenArgs = {
  wineEntityId: Scalars['ID'];
  wineId: Scalars['ID'];
};


export type QueryGetCommonalitiesWineArgs = {
  wineId: Scalars['ID'];
};


export type QueryJournalistAccountScreenArgs = {
  userId: Scalars['ID'];
};


export type QueryGetServicesOfferByJournalistArgs = {
  userId: Scalars['ID'];
};


export type QueryGetJournalistSamplesArgs = {
  exclude?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetJournalistArgs = {
  slug?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryFrontVintageViticultureArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryFrontVintageWineMakingArgs = {
  vintageId?: Maybe<Scalars['ID']>;
  wineId?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryVintageWineMakingScreenArgs = {
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
};


export type QueryFrontVintageAgeingArgs = {
  vintageId?: Maybe<Scalars['ID']>;
  wineId?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryVintageIdentityScreenArgs = {
  wineEntityId: Scalars['ID'];
  wineId: Scalars['ID'];
  vintageId: Scalars['ID'];
};


export type QueryVintageViticultureScreenArgs = {
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
};


export type QueryVintageAgeingScreenArgs = {
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
};


export type QueryVintageProductionScreenArgs = {
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
};


export type QueryVintageNutritionScreenArgs = {
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
};


export type QueryFrontVintageMarketingArgs = {
  vintageId?: Maybe<Scalars['ID']>;
  wineId?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryVintageMarketingScreenArgs = {
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
};


export type QueryVintageAdviceScreenArgs = {
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
};


export type QueryFrontVintageAdviceArgs = {
  vintageId?: Maybe<Scalars['ID']>;
  wineId?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryFrontVintageLabelsPopinArgs = {
  wineEntityId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryFrontVintageDetailedHarvestArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryFrontVintageProductionArgs = {
  vintageId?: Maybe<Scalars['ID']>;
  wineId?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryFrontVintageNutritionArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryFrontVintageQrcodeArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryFrontQrcodeRedirectArgs = {
  vintageId: Scalars['String'];
};


export type QueryFrontVintageArgs = {
  wineShortId: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryVmsArgs = {
  vintageId: Scalars['ID'];
};


export type QuerySimpleVintagesArgs = {
  wineEntityId: Scalars['ID'];
  wineId: Scalars['ID'];
};


export type QueryGetCustomFontsArgs = {
  wineEntityId?: Maybe<Scalars['ID']>;
};


export type QueryGetFtpDiffusionsArgs = {
  wineEntityId?: Maybe<Scalars['ID']>;
};


export type QueryGetFtpDiffusionLogArgs = {
  ftpDiffusionId?: Maybe<Scalars['ID']>;
};


export type QueryUserEntityArgs = {
  userId: Scalars['ID'];
};


export type QueryListWineEntitiesArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  filters?: Maybe<WineEntityFilters>;
};


export type QueryTlmArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetCategoriesArgs = {
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
};


export type QueryGetIdentityInformationArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetContactInformationArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetTeamMemberArgs = {
  personId: Scalars['ID'];
  wineEntityId: Scalars['ID'];
};


export type QueryGetWineEntityQuoteArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetImagesArgs = {
  id: Scalars['ID'];
  category: Scalars['String'];
};


export type QueryGetVineInformationsArgs = {
  wineEntityId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetVintageAssemblyArgs = {
  vintageId: Scalars['ID'];
};


export type QueryGetVintageDistributionArgs = {
  vintageId: Scalars['ID'];
};


export type QueryGetWineEntityPresentationArgs = {
  wineEntityId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetVintageViticultureArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetTeamDescriptionArgs = {
  wineEntityId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetLanguageIndicatorArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetSocialMediaArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryGetHarvestByGrapeVarietyArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryPHpArgs = {
  wineEntityId: Scalars['ID'];
};


export type QueryVmilcArgs = {
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetCertificationsWeArgs = {
  wineEntityId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryVmidArgs = {
  vintageId: Scalars['ID'];
};


export type QueryVmiArgs = {
  vintageId: Scalars['ID'];
};


export type QueryGetVintageVendagesGlobalesArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetWineDescriptionArgs = {
  wineId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetAllCellarsArgs = {
  wineEntityId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetAllActivitiesArgs = {
  wineEntityId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetQrCodeArgs = {
  vintageId: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetWineNameArgs = {
  wineId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetVintageYearArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetWineRankArgs = {
  wineId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetWineAppellationArgs = {
  wineId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetWineColorArgs = {
  wineId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetWineSweetnessArgs = {
  wineId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetWineTypeArgs = {
  wineId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetVintageTechnicalInformationArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetAlcPerVolArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetPhArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetTotaleAcidityArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetResidualSugarArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetVolatileAcidityArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetTotalSo2Args = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetIptArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetVintageClimatologyArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetTotalSurfaceAreaArgs = {
  wineId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetAverageDensityArgs = {
  wineId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetAverageAgeArgs = {
  wineId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetGlobalClimatologyArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetSoilsSubSoilsArgs = {
  wineId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetWineBreedingDescriptionArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetWineBreedingsArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryVmeArgs = {
  vintageId: Scalars['ID'];
};


export type QueryGetAgeingDescriptionArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetAgeingTechnicalInformationsArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetVintageTastingDescriptionArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetVintageConseilServiceArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetVintageFoodWinePairingArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetServiceTemperatureArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetApogeeArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetTreatmentBeforeServiceArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetAppearanceDetailsArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetShadeArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetShadeIntensityArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetShineArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetLimpidityArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetTearsArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetPalateDetailsArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetAttackArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetAcidityArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetTanninsArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetLengthArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetTasteIntensityArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetTasteAromasArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetNoseDetailsArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetOlfactoryAromasArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetOlfactoryIntensityArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetVintageProductionArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetProducedVolumeArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetYieldArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetProdHarvestArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetBottlingInformationsArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetMedalsArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetNotesArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetVintageVinificationArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetVinificationDescriptionArgs = {
  vintageId: Scalars['ID'];
};


export type QueryGetConsultingOenologistArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetMethodArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetLocationArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetDestemmingArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetCrushingArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetPressTypeArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetSortingArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetMacerationArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetAlcoholicFermentationArgs = {
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};

export type ReceptionArea = {
  __typename?: 'ReceptionArea';
  name?: Maybe<Scalars['String']>;
  surfaceArea?: Maybe<Scalars['Int']>;
  surfaceAreaUnit?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type ReceptionAreaInput = {
  name?: Maybe<Scalars['String']>;
  surfaceArea?: Maybe<Scalars['Int']>;
  surfaceAreaUnit?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type ReferenceInput = {
  place?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
};

export type ReferenceType = {
  __typename?: 'ReferenceType';
  place?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
};

export type ReferenceWineType = {
  __typename?: 'ReferenceWineType';
  shortId?: Maybe<Scalars['String']>;
  wineName?: Maybe<Scalars['String']>;
  wineLabel?: Maybe<Scalars['String']>;
  wineColor?: Maybe<Scalars['String']>;
  wineType?: Maybe<Scalars['String']>;
  wineSweetness?: Maybe<Scalars['String']>;
  wineId?: Maybe<Scalars['String']>;
  appellation?: Maybe<Scalars['String']>;
  bottleImage?: Maybe<Img>;
  vintages?: Maybe<Array<Maybe<VintageType>>>;
  bottleIcon?: Maybe<Img>;
};

export type RepresentationOrganismFront = {
  __typename?: 'RepresentationOrganismFront';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Maybe<OrganismMemberFront>>>;
  socialMedias?: Maybe<Array<Maybe<SocialMediaFront>>>;
  logo?: Maybe<Document>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
};

export type RepresentationOrganismInput = {
  name?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Maybe<OrganismMemberInput>>>;
  socialMedias?: Maybe<Array<Maybe<SocialMediaInput>>>;
  logo?: Maybe<DocumentInput>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
};

export type RepresentationOrganismType = {
  __typename?: 'RepresentationOrganismType';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  about?: Maybe<LngString>;
  members?: Maybe<Array<Maybe<OrganismMember>>>;
  socialMedias?: Maybe<Array<Maybe<SocialMediaFront>>>;
  logo?: Maybe<Document>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
};

export type ReservationType = {
  __typename?: 'ReservationType';
  isNeeded?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
};

export type RewardScreen = {
  __typename?: 'RewardScreen';
  rewards?: Maybe<Array<Maybe<SingleReward>>>;
  scores?: Maybe<Array<Maybe<SingleFrontScore>>>;
};

export enum Season {
  Hiver = 'Hiver',
  Printemps = 'Printemps',
  Ete = 'Ete',
  Automne = 'Automne',
  Globale = 'Globale'
}

export type ScoresInput = {
  fr?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  de?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
  nl?: Maybe<Scalars['String']>;
  ja?: Maybe<Scalars['String']>;
  zh?: Maybe<Scalars['String']>;
  scale?: Maybe<Scalars['Int']>;
  wineId: Scalars['ID'];
  score: Scalars['String'];
  level: Scalars['String'];
  vintageId: Scalars['ID'];
  scoreUrl?: Maybe<Scalars['String']>;
  vintageYear?: Maybe<Scalars['Int']>;
  wineEntityId: Scalars['ID'];
  criticName?: Maybe<Scalars['String']>;
  tastingDate?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  notification?: Maybe<Scalars['Boolean']>;
  forceVisibility?: Maybe<Scalars['Boolean']>;
};

export type ServiceInformations = {
  __typename?: 'ServiceInformations';
  services?: Maybe<Array<Maybe<ExternalServiceType>>>;
  hasTastingRequest?: Maybe<Scalars['Boolean']>;
};

export type ServiceRequestInput = {
  wineEntityId: Scalars['ID'];
  type: Scalars['String'];
  paymentMethod?: Maybe<Scalars['String']>;
  photoReport?: Maybe<Scalars['Boolean']>;
  numberOfPackshots?: Maybe<Scalars['Int']>;
  interview?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  files?: Maybe<Array<Maybe<DocumentInput>>>;
  description?: Maybe<Scalars['String']>;
};

export type ShippingAddressInput = {
  recipient?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type ShippingAddressType = {
  __typename?: 'ShippingAddressType';
  recipient?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type SignInInviteInput = {
  requestEmail: Scalars['String'];
  email: Scalars['String'];
};

export type SignUpInput = {
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephoneCountry?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  newsletter?: Maybe<Scalars['Boolean']>;
  firebaseId?: Maybe<Scalars['String']>;
  freeTrial?: Maybe<Scalars['Boolean']>;
};

export type SignUpInviteInput = {
  requestEmail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  telephoneCountry?: Maybe<Scalars['String']>;
  telephone: Scalars['String'];
  password: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  extraAddress?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  commune?: Maybe<Scalars['String']>;
};

export type SignUpJournalistInput = {
  title?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  displayName: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  areas?: Maybe<Array<Maybe<Scalars['String']>>>;
  locale?: Maybe<Scalars['String']>;
};

export type SignUpResponse = {
  __typename?: 'SignUpResponse';
  user?: Maybe<SignUpUser>;
};

export type SignUpUser = {
  __typename?: 'SignUpUser';
  _id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  telephoneNumber?: Maybe<Scalars['String']>;
  telephoneNumberCountry?: Maybe<Scalars['String']>;
};

export type SignedImg = {
  __typename?: 'SignedImg';
  data?: Maybe<Scalars['String']>;
};

export type SimpleActivity = {
  __typename?: 'SimpleActivity';
  activityId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type SimpleCellar = {
  __typename?: 'SimpleCellar';
  cellarId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type SimpleCertificationObject = {
  __typename?: 'SimpleCertificationObject';
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Img>;
  level?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  inConversionPossible?: Maybe<Scalars['Boolean']>;
};

export type SimpleClassificationOrganism = {
  __typename?: 'SimpleClassificationOrganism';
  name?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['ID']>;
};

export type SimpleCompetition = {
  __typename?: 'SimpleCompetition';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  totalMedals?: Maybe<Scalars['Int']>;
  logo?: Maybe<Document>;
  logoEn?: Maybe<Document>;
  maxYear?: Maybe<Scalars['Int']>;
};

export type SimpleContent = {
  __typename?: 'SimpleContent';
  description?: Maybe<Scalars['String']>;
};

export type SimpleGrapeVariety = {
  __typename?: 'SimpleGrapeVariety';
  varietyId?: Maybe<Scalars['ID']>;
  varietyDisplayName?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
};

export type SimpleGrapeVarietyPart = {
  __typename?: 'SimpleGrapeVarietyPart';
  grapeVarietyName?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
};

export type SimpleGrapeVarietyPartInput = {
  grapeVarietyName?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
};

export type SimpleLabel = {
  __typename?: 'SimpleLabel';
  certificationId?: Maybe<Scalars['ID']>;
  object?: Maybe<Scalars['String']>;
  logo?: Maybe<Img>;
  certificationBody?: Maybe<Scalars['String']>;
};

export type SimplePhenologicalStage = {
  __typename?: 'SimplePhenologicalStage';
  stage?: Maybe<Scalars['String']>;
  startAt?: Maybe<VagueDate>;
  endAt?: Maybe<VagueDate>;
  image?: Maybe<Img>;
};

export type SimplePhenologicalStageInput = {
  stage?: Maybe<Scalars['String']>;
  startAt?: Maybe<VagueDateInput>;
  endAt?: Maybe<VagueDateInput>;
};

export type SimpleRewardVintage = {
  __typename?: 'SimpleRewardVintage';
  wineEntityShortId?: Maybe<Scalars['String']>;
  wineShortId?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  _id?: Maybe<Scalars['ID']>;
  wineId?: Maybe<Scalars['ID']>;
  appellation?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  sweetness?: Maybe<Scalars['String']>;
  wineEntityName?: Maybe<Scalars['String']>;
  rewardName?: Maybe<Scalars['String']>;
  rewardYear?: Maybe<Scalars['Int']>;
  image?: Maybe<Img>;
};

export type SimpleUser = {
  __typename?: 'SimpleUser';
  userId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  telephoneNumber?: Maybe<Scalars['String']>;
  freeTrial?: Maybe<Scalars['Boolean']>;
  subscriptionName?: Maybe<Scalars['String']>;
  subscriptionPaperName?: Maybe<Scalars['String']>;
  subscriptionRecurrence?: Maybe<Scalars['String']>;
  zohoSubscriptionId?: Maybe<Scalars['String']>;
  wineEntityId?: Maybe<Scalars['String']>;
  wineEntityShortId?: Maybe<Scalars['String']>;
  shortWineEntityId?: Maybe<Scalars['String']>;
  wineEntityName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  wineEntityImages?: Maybe<Array<Maybe<Img>>>;
  mainAppellation?: Maybe<Scalars['String']>;
  mainCommune?: Maybe<Scalars['String']>;
  checkNeeded?: Maybe<Scalars['Boolean']>;
  ownerOperatorChecked?: Maybe<Scalars['Boolean']>;
  sideBarImage?: Maybe<Img>;
  isProfessional?: Maybe<Scalars['Boolean']>;
  isSu?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  addonCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  cde_exp?: Maybe<Scalars['String']>;
};

export type SimpleVintage = {
  __typename?: 'SimpleVintage';
  _id?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  vintageId?: Maybe<Scalars['ID']>;
  cuvee?: Maybe<Scalars['String']>;
  scores?: Maybe<Array<Maybe<FrontScore>>>;
};

export type SimpleWine = {
  __typename?: 'SimpleWine';
  wineId: Scalars['ID'];
  brandName: Scalars['String'];
  appellationLabel?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  sweetness?: Maybe<Scalars['String']>;
  bottleImage?: Maybe<Img>;
  rank?: Maybe<Scalars['String']>;
  vintages?: Maybe<Array<Maybe<WineVintage>>>;
  fullAppellation?: Maybe<Scalars['String']>;
};

export type SimpleWineEntity = {
  __typename?: 'SimpleWineEntity';
  name?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['String']>;
  mainCommune?: Maybe<Scalars['String']>;
  mainAppellation?: Maybe<Scalars['String']>;
  wineEntityId?: Maybe<Scalars['ID']>;
  freeTrial?: Maybe<Scalars['Boolean']>;
};

export type SimpleWineEntityLabel = {
  __typename?: 'SimpleWineEntityLabel';
  id?: Maybe<Scalars['ID']>;
  appellationLabel?: Maybe<Scalars['String']>;
  wineEntities?: Maybe<Array<Maybe<SimpleWineEntity>>>;
};

export type SimpleWineType = {
  __typename?: 'SimpleWineType';
  brandName?: Maybe<Scalars['String']>;
  wineEntityId?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Image>>>;
  label?: Maybe<EnumType>;
  rank?: Maybe<EnumType>;
  color?: Maybe<EnumType>;
  sweetness?: Maybe<EnumType>;
  type?: Maybe<EnumType>;
  soils?: Maybe<Array<Maybe<EnumType>>>;
  subsoils?: Maybe<Array<Maybe<EnumType>>>;
};

export type SingleFrontScore = {
  __typename?: 'SingleFrontScore';
  id?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  sortIndex?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  scoreMax?: Maybe<Scalars['Float']>;
  isFeret?: Maybe<Scalars['Boolean']>;
  scoreUrl?: Maybe<Scalars['String']>;
  tastingDate?: Maybe<Scalars['Date']>;
  criticName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  permission?: Maybe<Scalars['Boolean']>;
  forceVisibility?: Maybe<Scalars['Boolean']>;
  tastingComment?: Maybe<LngString>;
  tastingRequestId?: Maybe<Scalars['String']>;
};

export type SingleReward = {
  __typename?: 'SingleReward';
  competitionName?: Maybe<Scalars['String']>;
  rewardName?: Maybe<Scalars['String']>;
  competitionYear?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  rewardId?: Maybe<Scalars['ID']>;
  logo?: Maybe<Img>;
  sortIndex?: Maybe<Scalars['Int']>;
};

export type SingleSurface = {
  __typename?: 'SingleSurface';
  appellation?: Maybe<Scalars['String']>;
  grapeColor?: Maybe<Scalars['String']>;
  surfaceArea?: Maybe<Scalars['Float']>;
};

export type SingleSurfaceInput = {
  appellation?: Maybe<Scalars['String']>;
  grapeColor?: Maybe<Scalars['String']>;
  surfaceArea?: Maybe<Scalars['Float']>;
};

export type SocialMedia = {
  __typename?: 'SocialMedia';
  platform?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SocialMediaFront = {
  __typename?: 'SocialMediaFront';
  _id?: Maybe<Scalars['ID']>;
  platform?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  icon?: Maybe<Img>;
};

export type SocialMediaInput = {
  platform?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SortType = {
  key?: Maybe<Scalars['String']>;
  direction?: Maybe<Scalars['String']>;
};

export type SubscriptionData = {
  __typename?: 'SubscriptionData';
  _id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['String']>;
  wineEntityName?: Maybe<Scalars['String']>;
  mainCommune?: Maybe<Scalars['String']>;
  mainCommuneInseeCode?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  billingAddress?: Maybe<Address>;
  billingInformation?: Maybe<BillingInformation>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  orphanWineEntityId?: Maybe<Scalars['ID']>;
  isOwner?: Maybe<Scalars['Boolean']>;
  isOperator?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  mainAppellation?: Maybe<Scalars['String']>;
  siret?: Maybe<Scalars['String']>;
  organizationStatus?: Maybe<Scalars['String']>;
  subscriptionType?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  addonCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  recurrence?: Maybe<SubscriptionRecurrence>;
};

export enum SubscriptionRecurrence {
  Month = 'month',
  Year = 'year'
}

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type SubscriptionSummary = {
  __typename?: 'SubscriptionSummary';
  bgImage?: Maybe<Img>;
  mainAppellation?: Maybe<Scalars['String']>;
  wineEntityName?: Maybe<Scalars['String']>;
};

export type SurfaceAppellation = {
  __typename?: 'SurfaceAppellation';
  color?: Maybe<Scalars['String']>;
  appellation?: Maybe<Scalars['String']>;
};

export type SurfaceDeProductionInput = {
  superficieVigne?: Maybe<Scalars['Float']>;
  densiteMoyenneDePlantation?: Maybe<Scalars['Float']>;
  ageMoyenDesVignes?: Maybe<Scalars['Float']>;
  surfaces?: Maybe<Array<Maybe<SurfaceSimpleInput>>>;
  surfaceImportCVI?: Maybe<Scalars['Boolean']>;
  hiddenSurfaces?: Maybe<Array<Maybe<HiddenSurfaceInput>>>;
};

export type SurfaceDeProductionScreen = {
  __typename?: 'SurfaceDeProductionScreen';
  superficieVigne?: Maybe<Scalars['Float']>;
  densiteMoyenneDePlantation?: Maybe<Scalars['Float']>;
  ageMoyenDesVignes?: Maybe<Scalars['Float']>;
  surfaces?: Maybe<Array<Maybe<SurfaceSimple>>>;
  hiddenSurfaces?: Maybe<Array<Maybe<HiddenSurface>>>;
  surfaceImportCVI?: Maybe<Scalars['Boolean']>;
  totalParcelCount?: Maybe<Scalars['Float']>;
  rootstocksTotal?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateImportCvi?: Maybe<Scalars['Date']>;
};

export type SurfaceImportCvi = {
  __typename?: 'SurfaceImportCvi';
  surfaceImportCVI?: Maybe<Scalars['Boolean']>;
  dateImportCvi?: Maybe<Scalars['Date']>;
};

export type SurfaceManualInput = {
  totalSurfaceArea?: Maybe<Scalars['Float']>;
  averageDensity?: Maybe<Scalars['Float']>;
  averageAge?: Maybe<Scalars['Float']>;
  surfaces?: Maybe<Array<Maybe<SingleSurfaceInput>>>;
};

export type SurfaceManualScreen = {
  __typename?: 'SurfaceManualScreen';
  surfaceImportCVI?: Maybe<Scalars['Boolean']>;
  estateSurfaceArea?: Maybe<Scalars['Float']>;
  totalSurfaceArea?: Maybe<Scalars['Float']>;
  averageDensity?: Maybe<Scalars['Float']>;
  averageAge?: Maybe<Scalars['Float']>;
  surfaces?: Maybe<Array<Maybe<SingleSurface>>>;
  allowedAppellations?: Maybe<Array<Maybe<SurfaceAppellation>>>;
};

export type SurfaceSimple = {
  __typename?: 'SurfaceSimple';
  couleur?: Maybe<Scalars['String']>;
  appellation?: Maybe<Scalars['String']>;
  superficie?: Maybe<Scalars['Float']>;
  ageMoyenDesVignes?: Maybe<Scalars['Float']>;
  densiteDePlantation?: Maybe<Scalars['Float']>;
  communes?: Maybe<Array<Maybe<CommuneDistribution>>>;
  encepagements?: Maybe<Array<Maybe<EncepagementDistribution>>>;
  vinesCount?: Maybe<Scalars['Float']>;
  rootstocks?: Maybe<Array<Maybe<Scalars['String']>>>;
  parcelCount?: Maybe<Scalars['Float']>;
  localities?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SurfaceSimpleInput = {
  couleur?: Maybe<Scalars['String']>;
  appellation?: Maybe<Scalars['String']>;
  superficie?: Maybe<Scalars['Float']>;
};

export type TastingRequestInput = {
  wineEntityId?: Maybe<Scalars['ID']>;
  wineId?: Maybe<Scalars['ID']>;
  vintageId?: Maybe<Scalars['ID']>;
  journalistName?: Maybe<Scalars['String']>;
  videoRequested?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
  wineTank?: Maybe<Scalars['Boolean']>;
  wineReleased?: Maybe<Scalars['Boolean']>;
  bottlingDate?: Maybe<Scalars['Date']>;
  extraInformations?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  scale?: Maybe<Scalars['Int']>;
  websiteUrl?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
  comment?: Maybe<LngStringInput>;
  tastingDate?: Maybe<Scalars['Date']>;
};

export type TastingRequestType = {
  __typename?: 'TastingRequestType';
  _id?: Maybe<Scalars['ID']>;
  sampleId?: Maybe<Scalars['String']>;
  wineEntityId?: Maybe<Scalars['ID']>;
  wineId?: Maybe<Scalars['ID']>;
  vintageId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  journalistName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  videoRequested?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  scale?: Maybe<Scalars['Int']>;
  websiteUrl?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
  tastingDate?: Maybe<Scalars['Date']>;
  comment?: Maybe<LngString>;
  wineTank?: Maybe<Scalars['Boolean']>;
  wineReleased?: Maybe<Scalars['Boolean']>;
  bottlingDate?: Maybe<Scalars['Date']>;
  extraInformations?: Maybe<LngString>;
  aoc?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  wineEntity?: Maybe<WineEntityType>;
  wine?: Maybe<ReferenceWineType>;
  vintage?: Maybe<VintageType>;
};

export type TeamGeneral = {
  __typename?: 'TeamGeneral';
  teamDescription?: Maybe<LngString>;
  members?: Maybe<Array<Maybe<GeneralTeamMember>>>;
  owners?: Maybe<Array<Maybe<GeneralTeamMember>>>;
  operators?: Maybe<Array<Maybe<GeneralTeamMember>>>;
  image?: Maybe<Array<Maybe<Img>>>;
};

export type TeamMemberBoc = {
  __typename?: 'TeamMemberBoc';
  personId?: Maybe<Scalars['String']>;
  kind?: Maybe<PersonKind>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  organizationStatus?: Maybe<Scalars['String']>;
  visibility?: Maybe<Scalars['Boolean']>;
  hidden?: Maybe<Scalars['Boolean']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  vip?: Maybe<Scalars['Boolean']>;
  wasVip?: Maybe<Scalars['Boolean']>;
  description?: Maybe<LngString>;
  roleComplement?: Maybe<Scalars['String']>;
  feminineProFunction?: Maybe<Scalars['Boolean']>;
  proFunctions?: Maybe<Array<Maybe<Scalars['String']>>>;
  images?: Maybe<Array<Maybe<Img>>>;
  displayName?: Maybe<Scalars['String']>;
};

export type TeamMemberFront = {
  __typename?: 'TeamMemberFront';
  displayName?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  roleComplement?: Maybe<Scalars['String']>;
  image?: Maybe<Img>;
  vip?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  defaultImage?: Maybe<Scalars['Boolean']>;
};

export type TimeInput = {
  amount?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
};

export type TimeType = {
  __typename?: 'TimeType';
  amount?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
};

export type TranslationComparison = {
  __typename?: 'TranslationComparison';
  accessor?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  oldValueTranslated?: Maybe<Scalars['String']>;
  newValue?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  wordCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  subId?: Maybe<Scalars['String']>;
  modelName?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<Scalars['String']>;
};

export type TranslationFieldInput = {
  value?: Maybe<Scalars['JSON']>;
  path?: Maybe<Scalars['String']>;
  modelName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  subId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  wordCount?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  accessor?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  oldValueTranslated?: Maybe<Scalars['String']>;
  newValue?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<Scalars['String']>;
};

export enum TranslationMode {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
  None = 'NONE'
}

export type TranslationOption = {
  __typename?: 'TranslationOption';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  status?: Maybe<TranslationStatus>;
  supportedTranslationModes?: Maybe<Array<Maybe<TranslationMode>>>;
  chosenTranslationMode?: Maybe<TranslationMode>;
  discountFree?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  outdated?: Maybe<Array<Maybe<TranslationComparison>>>;
  upToDate?: Maybe<Array<Maybe<TranslationComparison>>>;
  notTranslated?: Maybe<Array<Maybe<TranslationComparison>>>;
  lastTranslationRequestCreatedAt?: Maybe<Scalars['Date']>;
};

export type TranslationRequestInput = {
  fieldsToTranslate?: Maybe<Array<Maybe<TranslationFieldInput>>>;
  fieldsNotToTranslate?: Maybe<Array<Maybe<TranslationFieldInput>>>;
};

export enum TranslationStatus {
  UpToDate = 'UP_TO_DATE',
  InProgress = 'IN_PROGRESS',
  OutOfDate = 'OUT_OF_DATE'
}

export type UnpaidSubscriptionInformation = {
  __typename?: 'UnpaidSubscriptionInformation';
  zohoSubscriptionId?: Maybe<Scalars['ID']>;
  dateUnpaid?: Maybe<Scalars['Date']>;
  wineEntityName?: Maybe<Scalars['String']>;
  mainAppellation?: Maybe<Scalars['String']>;
  subscriptionName?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
};

export type UpdateCertificationBofInput = {
  internalStatus?: Maybe<Scalars['String']>;
  refusalReason?: Maybe<Scalars['String']>;
};

export type UpdateCertificationInput = {
  visibility?: Maybe<Scalars['Boolean']>;
  isInConversion?: Maybe<Scalars['Boolean']>;
  certificationBody?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['Date']>;
  proofDocuments?: Maybe<Array<Maybe<DocumentInput>>>;
};

export type UpdateFtpDiffusionInput = {
  language?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
  remotePath?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  autoSync?: Maybe<Scalars['Boolean']>;
  protocole?: Maybe<Scalars['String']>;
};

export type UpdateMatchingDatesResponse = {
  __typename?: 'UpdateMatchingDatesResponse';
  success?: Maybe<Array<Maybe<Scalars['ID']>>>;
  fail?: Maybe<Array<Maybe<Scalars['ID']>>>;
  total?: Maybe<Scalars['Int']>;
};

export type UpdateWineByIdInput = {
  harvestMethod?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UrlByLng = {
  __typename?: 'UrlByLng';
  de?: Maybe<FileUrl>;
  en?: Maybe<FileUrl>;
  es?: Maybe<FileUrl>;
  fr?: Maybe<FileUrl>;
  it?: Maybe<FileUrl>;
  ja?: Maybe<FileUrl>;
  nl?: Maybe<FileUrl>;
  zh?: Maybe<FileUrl>;
};

export type UrlType = {
  __typename?: 'UrlType';
  url?: Maybe<Scalars['String']>;
};

export type UserSettingsType = {
  __typename?: 'UserSettingsType';
  noDisplayModal?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Vme = {
  __typename?: 'VME';
  content?: Maybe<VmeContentType>;
};

export type VmeContentType = {
  __typename?: 'VMEContentType';
  barrelMaturation?: Maybe<Scalars['String']>;
  barrelOrigins?: Maybe<Array<Maybe<Scalars['String']>>>;
  maturationTechniques?: Maybe<Scalars['String']>;
  complementaryInformation?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  newPercentage?: Maybe<Scalars['String']>;
  heat?: Maybe<Scalars['String']>;
  frenchOak?: Maybe<Scalars['String']>;
};

export type Vmi = {
  __typename?: 'VMI';
  name?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  cuvee?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  sweetness?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  rewards?: Maybe<Array<Maybe<FtAwards>>>;
  scores?: Maybe<Array<Maybe<FtScore>>>;
  bottleImage?: Maybe<FtImg>;
  grapeVarieties?: Maybe<Array<Maybe<FtSimpleGrapeVarietyPart>>>;
};

export type Vmid = {
  __typename?: 'VMID';
  name?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  cuvee?: Maybe<Scalars['String']>;
};

export type Vms = {
  __typename?: 'VMS';
  serviceTemperature?: Maybe<Scalars['Int']>;
  apogee?: Maybe<Array<Maybe<Scalars['Int']>>>;
  treatmentBeforeService?: Maybe<Scalars['String']>;
  decantingDuration?: Maybe<Scalars['Int']>;
  foodPairings?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type VagueDate = {
  __typename?: 'VagueDate';
  day?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  vaguePeriod?: Maybe<Scalars['String']>;
};

export type VagueDateInput = {
  day?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  vaguePeriod?: Maybe<Scalars['String']>;
};

export type VatContainer = {
  __typename?: 'VatContainer';
  kind?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  form?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type VatContainerInput = {
  kind?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  form?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type Vineyard = {
  __typename?: 'Vineyard';
  totalSurfaceArea?: Maybe<Mesure>;
};

export type VineyardFrontPopin = {
  __typename?: 'VineyardFrontPopin';
  vineSurfaceArea?: Maybe<Scalars['Float']>;
  averageVineAge?: Maybe<Scalars['Float']>;
  averagePlantationDensity?: Maybe<Scalars['Float']>;
  totalParcelCount?: Maybe<Scalars['Int']>;
  soil?: Maybe<Array<Maybe<Scalars['String']>>>;
  subsoil?: Maybe<Array<Maybe<Scalars['String']>>>;
  soilDescription?: Maybe<Scalars['String']>;
  rootstocks?: Maybe<Array<Maybe<Scalars['String']>>>;
  grassGrowing?: Maybe<Array<Maybe<Scalars['String']>>>;
  fertilizers?: Maybe<Array<Maybe<Scalars['String']>>>;
  worksUnderTheRow?: Maybe<Array<Maybe<Scalars['String']>>>;
  soilWorkDescription?: Maybe<Scalars['String']>;
  pruningMethod?: Maybe<Array<Maybe<Scalars['String']>>>;
  greenProcedures?: Maybe<Array<Maybe<Scalars['String']>>>;
  vineWorkDescription?: Maybe<Scalars['String']>;
  sexualConfusion?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  grassGrowingIsTotal?: Maybe<Scalars['Boolean']>;
  grassGrowingIsTemporary?: Maybe<Scalars['Boolean']>;
  insectWorkDescription?: Maybe<Scalars['String']>;
  surfaces?: Maybe<Array<Maybe<SurfaceSimple>>>;
  surfaceImportCVI?: Maybe<Scalars['Boolean']>;
  hiddenSurfaces?: Maybe<Array<Maybe<HiddenSurface>>>;
  allHiddenSurfaces?: Maybe<Scalars['Boolean']>;
  otherCultures?: Maybe<Scalars['String']>;
};

export type VineyardIndicator = {
  __typename?: 'VineyardIndicator';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
};

export type VineyardInput = {
  totalSurfaceArea?: Maybe<MesureInput>;
};

export type VineyardMetrics = {
  __typename?: 'VineyardMetrics';
  vineyardIndicator?: Maybe<Array<Maybe<VineyardIndicator>>>;
  vinesIndicator?: Maybe<Array<Maybe<GraphIndicator>>>;
  repartitionsGeoIndicator?: Maybe<Array<Maybe<GraphIndicator>>>;
};

export type VintageAdviceScreen = {
  __typename?: 'VintageAdviceScreen';
  videoUrl?: Maybe<Scalars['String']>;
  serviceTemperature?: Maybe<Scalars['Int']>;
  apogee?: Maybe<Array<Maybe<Scalars['Int']>>>;
  treatmentBeforeService?: Maybe<Scalars['String']>;
  decantingDuration?: Maybe<Scalars['Int']>;
  foodPairings?: Maybe<Array<Maybe<FoodPairing>>>;
  appearanceColor?: Maybe<Scalars['String']>;
  appearanceIntensity?: Maybe<Scalars['Int']>;
  appearanceBrilliance?: Maybe<Scalars['String']>;
  appearanceLimpidity?: Maybe<Scalars['String']>;
  appearanceTears?: Maybe<Scalars['String']>;
  noseAromas?: Maybe<Array<Maybe<Scalars['String']>>>;
  noseIntensity?: Maybe<Scalars['Int']>;
  palateAttack?: Maybe<Scalars['String']>;
  palateIntensity?: Maybe<Scalars['Int']>;
  palateLength?: Maybe<Scalars['String']>;
  palateAcidity?: Maybe<Scalars['String']>;
  palateTannins?: Maybe<Scalars['String']>;
  aromaValues?: Maybe<AromaValues>;
  description?: Maybe<LngString>;
  aromaAnimal?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaBalsamic?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaConfectionery?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaEmpyreumatic?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaFermented?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaFloral?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaFruity?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaMineral?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaOaky?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaSpicy?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaVegetal?: Maybe<Array<Maybe<Scalars['String']>>>;
  colorOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type VintageAdviceScreenInput = {
  videoUrl?: Maybe<Scalars['String']>;
  serviceTemperature?: Maybe<Scalars['Int']>;
  apogee?: Maybe<Array<Maybe<Scalars['Int']>>>;
  treatmentBeforeService?: Maybe<Scalars['String']>;
  decantingDuration?: Maybe<Scalars['Int']>;
  foodPairings?: Maybe<Array<Maybe<FoodPairingInput>>>;
  appearanceColor?: Maybe<Scalars['String']>;
  appearanceIntensity?: Maybe<Scalars['Int']>;
  appearanceBrilliance?: Maybe<Scalars['String']>;
  appearanceLimpidity?: Maybe<Scalars['String']>;
  appearanceTears?: Maybe<Scalars['String']>;
  noseAromas?: Maybe<Array<Maybe<Scalars['String']>>>;
  noseIntensity?: Maybe<Scalars['Int']>;
  palateAttack?: Maybe<Scalars['String']>;
  palateIntensity?: Maybe<Scalars['Int']>;
  palateLength?: Maybe<Scalars['String']>;
  palateAcidity?: Maybe<Scalars['String']>;
  palateTannins?: Maybe<Scalars['String']>;
  aromaValues?: Maybe<AromaValuesInput>;
  aromaAnimal?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaBalsamic?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaConfectionery?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaEmpyreumatic?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaFermented?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaFloral?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaFruity?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaMineral?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaOaky?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaSpicy?: Maybe<Array<Maybe<Scalars['String']>>>;
  aromaVegetal?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<LngStringInput>;
};

export type VintageAgeingScreen = {
  __typename?: 'VintageAgeingScreen';
  barrelMaturation?: Maybe<Scalars['Boolean']>;
  barrelMaturationDurationValue?: Maybe<Scalars['Int']>;
  barrelMaturationDurationUnit?: Maybe<Scalars['String']>;
  newBarrelPercentage?: Maybe<Scalars['Int']>;
  toasting?: Maybe<Scalars['Int']>;
  frenchOak?: Maybe<Scalars['Boolean']>;
  barrelMakers?: Maybe<Array<Maybe<BarrelMakerPart>>>;
  otherMaturations?: Maybe<Array<Maybe<OtherMaturation>>>;
  lees?: Maybe<Scalars['Boolean']>;
  batonnage?: Maybe<Scalars['Boolean']>;
  description?: Maybe<LngString>;
};

export type VintageAgeingScreenInput = {
  barrelMaturation?: Maybe<Scalars['Boolean']>;
  barrelMaturationDurationValue?: Maybe<Scalars['Int']>;
  barrelMaturationDurationUnit?: Maybe<Scalars['String']>;
  newBarrelPercentage?: Maybe<Scalars['Int']>;
  toasting?: Maybe<Scalars['Int']>;
  frenchOak?: Maybe<Scalars['Boolean']>;
  barrelMakers?: Maybe<Array<Maybe<BarrelMakerPartInput>>>;
  otherMaturations?: Maybe<Array<Maybe<OtherMaturationInput>>>;
  lees?: Maybe<Scalars['Boolean']>;
  batonnage?: Maybe<Scalars['Boolean']>;
  description?: Maybe<LngStringInput>;
};

export type VintageAssemblyType = {
  __typename?: 'VintageAssemblyType';
  grapeVarietyName?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['String']>;
};

export type VintageCandidate = {
  __typename?: 'VintageCandidate';
  year?: Maybe<Scalars['String']>;
  cuvee?: Maybe<Scalars['String']>;
  image?: Maybe<Img>;
  fullAppellation?: Maybe<Scalars['String']>;
  wineColor?: Maybe<Scalars['String']>;
  wineName?: Maybe<Scalars['String']>;
  wineSweetness?: Maybe<Scalars['String']>;
  wineType?: Maybe<Scalars['String']>;
  wineId?: Maybe<Scalars['ID']>;
  vintageId?: Maybe<Scalars['ID']>;
};

export type VintageCardInformation = {
  __typename?: 'VintageCardInformation';
  year?: Maybe<Scalars['Int']>;
  cuvee?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['ID']>;
};

export type VintageClimatology = {
  __typename?: 'VintageClimatology';
  winterClimatology?: Maybe<Scalars['String']>;
  springClimatology?: Maybe<Scalars['String']>;
  fallClimatology?: Maybe<Scalars['String']>;
  summerClimatology?: Maybe<Scalars['String']>;
};

export type VintageIdentityInput = {
  shortId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  wineName?: Maybe<Scalars['String']>;
  wineAssembly?: Maybe<Array<Maybe<SimpleGrapeVarietyPartInput>>>;
  alcoholicStrength?: Maybe<Scalars['Float']>;
  residualSugar?: Maybe<Scalars['Float']>;
  totalSulfur?: Maybe<Scalars['Float']>;
  ph?: Maybe<Scalars['Float']>;
  totalAcidity?: Maybe<Scalars['Float']>;
  noSulphites?: Maybe<Scalars['Boolean']>;
  qrs?: Maybe<DocumentInput>;
  volatileAcidity?: Maybe<Scalars['Float']>;
  nonOGM?: Maybe<Scalars['Boolean']>;
  ipt?: Maybe<Scalars['Float']>;
  allergens?: Maybe<Array<Maybe<Scalars['String']>>>;
  globalClimatology?: Maybe<LngStringInput>;
  winterClimatology?: Maybe<LngStringInput>;
  springClimatology?: Maybe<LngStringInput>;
  fallClimatology?: Maybe<LngStringInput>;
  summerClimatology?: Maybe<LngStringInput>;
  vintageDetails?: Maybe<LngStringInput>;
  bySeasonClimatology?: Maybe<Scalars['Boolean']>;
  bottlePictures?: Maybe<Array<Maybe<ImgInput>>>;
  bottleLabels?: Maybe<Array<Maybe<ImgInput>>>;
  consultingEnologist?: Maybe<PersonOptionInput>;
};

export type VintageIdentityScreen = {
  __typename?: 'VintageIdentityScreen';
  shortId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  wineName?: Maybe<Scalars['String']>;
  alcoholicStrength?: Maybe<Scalars['Float']>;
  residualSugar?: Maybe<Scalars['Float']>;
  totalSulfur?: Maybe<Scalars['Float']>;
  totalAcidity?: Maybe<Scalars['Float']>;
  ph?: Maybe<Scalars['Float']>;
  qr?: Maybe<Document>;
  noSulphites?: Maybe<Scalars['Boolean']>;
  volatileAcidity?: Maybe<Scalars['Float']>;
  nonOGM?: Maybe<Scalars['Boolean']>;
  ipt?: Maybe<Scalars['Float']>;
  allergens?: Maybe<Array<Maybe<Scalars['String']>>>;
  consultingEnologist?: Maybe<PersonOption>;
  globalClimatology?: Maybe<LngString>;
  winterClimatology?: Maybe<LngString>;
  springClimatology?: Maybe<LngString>;
  fallClimatology?: Maybe<LngString>;
  summerClimatology?: Maybe<LngString>;
  bySeasonClimatology?: Maybe<Scalars['Boolean']>;
  vintageDetails?: Maybe<LngString>;
  bottlePictures?: Maybe<Array<Maybe<Img>>>;
  bottleLabels?: Maybe<Array<Maybe<Img>>>;
  allowedGrapeVarieties?: Maybe<Array<Maybe<Scalars['String']>>>;
  wineAssembly?: Maybe<Array<Maybe<SimpleGrapeVarietyPart>>>;
  oenologueOptions?: Maybe<Array<Maybe<PersonOption>>>;
  classifications?: Maybe<Array<Maybe<Classification>>>;
};

export type VintageMarketingScreen = {
  __typename?: 'VintageMarketingScreen';
  ownWebsiteSelling?: Maybe<Scalars['Boolean']>;
  productPageUrl?: Maybe<Scalars['String']>;
  exportPercentage?: Maybe<Scalars['Int']>;
  directPercentage?: Maybe<Scalars['Int']>;
  indirectPercentage?: Maybe<Scalars['Int']>;
  exportCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  packagings?: Maybe<Array<Maybe<Packaging>>>;
};

export type VintageMarketingScreenInput = {
  ownWebsiteSelling?: Maybe<Scalars['Boolean']>;
  productPageUrl?: Maybe<Scalars['String']>;
  exportPercentage?: Maybe<Scalars['Int']>;
  directPercentage?: Maybe<Scalars['Int']>;
  indirectPercentage?: Maybe<Scalars['Int']>;
  exportCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  packagings?: Maybe<Array<Maybe<PackagingInput>>>;
  gtin?: Maybe<Scalars['Float']>;
};

export type VintageNutritionScreen = {
  __typename?: 'VintageNutritionScreen';
  nutritionalAnalysis?: Maybe<Array<Maybe<Document>>>;
  fats?: Maybe<Scalars['Float']>;
  saturatedFattyAcids?: Maybe<Scalars['Float']>;
  glucides?: Maybe<Scalars['Float']>;
  sugars?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  salt?: Maybe<Scalars['Float']>;
  energy?: Maybe<Scalars['Float']>;
  energyType?: Maybe<Scalars['String']>;
  alcoholicStrength?: Maybe<Scalars['Float']>;
  residualSugar?: Maybe<Scalars['Float']>;
  totalAcidity?: Maybe<Scalars['Float']>;
  auto?: Maybe<Scalars['Boolean']>;
  qrnutri?: Maybe<Document>;
  ingredients?: Maybe<Array<Maybe<IngredientPart>>>;
};

export type VintageNutritionScreenInput = {
  nutritionalAnalysis?: Maybe<Array<Maybe<DocumentInput>>>;
  fats?: Maybe<Scalars['Float']>;
  saturatedFattyAcids?: Maybe<Scalars['Float']>;
  glucides?: Maybe<Scalars['Float']>;
  sugars?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  salt?: Maybe<Scalars['Float']>;
  energy?: Maybe<Scalars['Float']>;
  energyType?: Maybe<Scalars['String']>;
  alcoholicStrength?: Maybe<Scalars['Float']>;
  residualSugar?: Maybe<Scalars['Float']>;
  totalAcidity?: Maybe<Scalars['Float']>;
  auto?: Maybe<Scalars['Boolean']>;
  qrnutri?: Maybe<DocumentInput>;
  ingredients?: Maybe<Array<Maybe<IngredientPartInput>>>;
};

export type VintageProductionScreen = {
  __typename?: 'VintageProductionScreen';
  finingTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  filtrationTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  bottlingDate?: Maybe<Scalars['Date']>;
  estateBottling?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  bottlesVolume?: Maybe<Scalars['Int']>;
  yield?: Maybe<Scalars['Int']>;
  production?: Maybe<Scalars['Int']>;
  corkingMode?: Maybe<Scalars['String']>;
  wineEntityAddress?: Maybe<Address>;
  waxedBottle?: Maybe<Scalars['Boolean']>;
  bottlerOptions?: Maybe<Array<Maybe<BottlerOption>>>;
  bottler?: Maybe<BottlerOption>;
  bottlerCommuneINSEEcode?: Maybe<Scalars['String']>;
  bottlerCommune?: Maybe<Scalars['String']>;
  bottlerPostalCode?: Maybe<Scalars['String']>;
};

export type VintageProductionScreenInput = {
  finingTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  filtrationTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  bottlingDate?: Maybe<Scalars['Date']>;
  estateBottling?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressInput>;
  bottlesVolume?: Maybe<Scalars['Int']>;
  yield?: Maybe<Scalars['Int']>;
  production?: Maybe<Scalars['Int']>;
  corkingMode?: Maybe<Scalars['String']>;
  waxedBottle?: Maybe<Scalars['Boolean']>;
  bottler?: Maybe<BottlerOptionInput>;
  bottlerCommune?: Maybe<Scalars['String']>;
  bottlerCommuneINSEEcode?: Maybe<Scalars['String']>;
  bottlerPostalCode?: Maybe<Scalars['String']>;
};

export type VintageSearchInput = {
  searchText?: Maybe<Scalars['String']>;
  colors?: Maybe<Array<Maybe<Scalars['String']>>>;
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  appellations?: Maybe<Array<Maybe<Scalars['String']>>>;
  years?: Maybe<Array<Maybe<Scalars['String']>>>;
  sweetness?: Maybe<Array<Maybe<Scalars['String']>>>;
  alcoolPercentage?: Maybe<Array<Maybe<Scalars['String']>>>;
  priceRange?: Maybe<Array<Maybe<Scalars['Int']>>>;
  allergens?: Maybe<Array<Maybe<Scalars['String']>>>;
  FoodAndWinePairings?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeOfHarvest?: Maybe<Array<Maybe<Scalars['String']>>>;
  durationAgingBarrels?: Maybe<Array<Maybe<Scalars['String']>>>;
  levurages?: Maybe<Array<Maybe<Scalars['String']>>>;
  oenologists?: Maybe<Array<Maybe<Scalars['String']>>>;
  cepages?: Maybe<Array<Maybe<Scalars['String']>>>;
  attack?: Maybe<Array<Maybe<Scalars['String']>>>;
  acidity?: Maybe<Array<Maybe<Scalars['String']>>>;
  tanins?: Maybe<Array<Maybe<Scalars['String']>>>;
  length?: Maybe<Array<Maybe<Scalars['String']>>>;
  newBarrels?: Maybe<Array<Maybe<Scalars['String']>>>;
  typesMacerations?: Maybe<Array<Maybe<Scalars['String']>>>;
  heatingBarrels?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeOfClosures?: Maybe<Array<Maybe<Scalars['String']>>>;
  onlineSales?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Boolean']>;
  reward?: Maybe<Scalars['Boolean']>;
  barrelAging?: Maybe<Scalars['String']>;
  wineWithoutAddedSulphite?: Maybe<Scalars['Boolean']>;
  doesNotContainOGM?: Maybe<Scalars['Boolean']>;
  plotVinification?: Maybe<Scalars['Boolean']>;
  frenchOak?: Maybe<Scalars['Boolean']>;
  onLies?: Maybe<Scalars['Boolean']>;
  bonding?: Maybe<Scalars['String']>;
  filtration?: Maybe<Scalars['String']>;
  waxedBottle?: Maybe<Scalars['Boolean']>;
  bottlingAtTheProperty?: Maybe<Scalars['Boolean']>;
  apogee?: Maybe<Array<Maybe<Scalars['Int']>>>;
  classificationOrganisms?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type VintageTechnicalInformation = {
  __typename?: 'VintageTechnicalInformation';
  alcoholicStrength?: Maybe<Scalars['Float']>;
  ph?: Maybe<Scalars['Float']>;
  ipt?: Maybe<Scalars['Float']>;
  totalSulfur?: Maybe<Scalars['Float']>;
  totalAcidity?: Maybe<Scalars['Float']>;
  residualSugar?: Maybe<Scalars['Float']>;
  volatileAcidity?: Maybe<Scalars['Float']>;
};

export type VintageType = {
  __typename?: 'VintageType';
  _id?: Maybe<Scalars['ID']>;
  wineEntityId?: Maybe<Scalars['ID']>;
  wineId?: Maybe<Scalars['ID']>;
  year?: Maybe<Scalars['String']>;
  cuvee?: Maybe<Scalars['String']>;
};

export type VintageViticultureScreen = {
  __typename?: 'VintageViticultureScreen';
  greenOperations?: Maybe<Array<Maybe<GreenOperation>>>;
  simplePhenologicalStages?: Maybe<Array<Maybe<SimplePhenologicalStage>>>;
  detailedPhenologicalStages?: Maybe<Array<Maybe<DetailedPhenologicalStage>>>;
  showDetailedPhenologicalStages?: Maybe<Scalars['Boolean']>;
  showDetailedHarvest?: Maybe<Scalars['Boolean']>;
  wineHarvestMethods?: Maybe<Array<Maybe<Scalars['String']>>>;
  viticultureDetails?: Maybe<LngString>;
  numberOfSorts?: Maybe<Scalars['Int']>;
  allowedGrapeVarieties?: Maybe<Array<Maybe<Scalars['String']>>>;
  harvest?: Maybe<Array<Maybe<Harvest>>>;
  detailedHarvest?: Maybe<Array<Maybe<DetailedHarvest>>>;
};

export type VintageViticultureScreenInput = {
  greenOperations?: Maybe<Array<Maybe<GreenOperationInput>>>;
  simplePhenologicalStages?: Maybe<Array<Maybe<SimplePhenologicalStageInput>>>;
  detailedPhenologicalStages?: Maybe<Array<Maybe<DetailedPhenologicalStageInput>>>;
  showDetailedPhenologicalStages?: Maybe<Scalars['Boolean']>;
  showDetailedHarvest?: Maybe<Scalars['Boolean']>;
  viticultureDetails?: Maybe<LngStringInput>;
  numberOfSorts?: Maybe<Scalars['Int']>;
  harvest?: Maybe<Array<Maybe<HarvestInput>>>;
  detailedHarvest?: Maybe<Array<Maybe<DetailedHarvestInput>>>;
};

export type VintageWineMakingScreen = {
  __typename?: 'VintageWineMakingScreen';
  parcelBasedVinification?: Maybe<Scalars['Boolean']>;
  sortingMethod?: Maybe<Array<Maybe<Scalars['String']>>>;
  destemming?: Maybe<Scalars['String']>;
  treading?: Maybe<Scalars['String']>;
  vatting?: Maybe<Array<Maybe<Scalars['String']>>>;
  extractionMode?: Maybe<Array<Maybe<Scalars['String']>>>;
  macerations?: Maybe<Array<Maybe<Maceration>>>;
  pressType?: Maybe<Array<Maybe<Scalars['String']>>>;
  yeasting?: Maybe<Scalars['String']>;
  vinificationPlace?: Maybe<Scalars['String']>;
  vinificationCoopName?: Maybe<Scalars['String']>;
  alcoholicFermentationDuration?: Maybe<Scalars['Float']>;
  alcoholicFermentationDurationUnit?: Maybe<Scalars['String']>;
  alcoholicFermentationTemperature?: Maybe<Scalars['Int']>;
  malolacticFermentation?: Maybe<Scalars['Boolean']>;
  malolacticFermentationDuration?: Maybe<Scalars['Float']>;
  malolacticFermentationDurationUnit?: Maybe<Scalars['String']>;
  malolacticFermentationTemperature?: Maybe<Scalars['Int']>;
  malolacticBacteria?: Maybe<Scalars['String']>;
  vinificationDetails?: Maybe<LngString>;
};

export type VintageWineMakingScreenInput = {
  parcelBasedVinification?: Maybe<Scalars['Boolean']>;
  sortingMethod?: Maybe<Array<Maybe<Scalars['String']>>>;
  destemming?: Maybe<Scalars['String']>;
  treading?: Maybe<Scalars['String']>;
  vatting?: Maybe<Array<Maybe<Scalars['String']>>>;
  extractionMode?: Maybe<Array<Maybe<Scalars['String']>>>;
  macerations?: Maybe<Array<Maybe<MacerationInput>>>;
  vinificationPlace?: Maybe<Scalars['String']>;
  vinificationCoopName?: Maybe<Scalars['String']>;
  pressType?: Maybe<Array<Maybe<Scalars['String']>>>;
  yeasting?: Maybe<Scalars['String']>;
  malolacticBacteria?: Maybe<Scalars['String']>;
  alcoholicFermentationDuration?: Maybe<Scalars['Int']>;
  alcoholicFermentationDurationUnit?: Maybe<Scalars['String']>;
  alcoholicFermentationTemperature?: Maybe<Scalars['Int']>;
  malolacticFermentation?: Maybe<Scalars['Boolean']>;
  malolacticFermentationDuration?: Maybe<Scalars['Int']>;
  malolacticFermentationDurationUnit?: Maybe<Scalars['String']>;
  malolacticFermentationTemperature?: Maybe<Scalars['Int']>;
  vinificationDetails?: Maybe<LngStringInput>;
};

export type VintagesInfo = {
  __typename?: 'VintagesInfo';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type WineActivityMetrics = {
  __typename?: 'WineActivityMetrics';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ActivityType>;
  url?: Maybe<Scalars['String']>;
  image?: Maybe<Img>;
};

export type WineCardInformation = {
  __typename?: 'WineCardInformation';
  img?: Maybe<Img>;
  rewarded?: Maybe<Scalars['Boolean']>;
  scored?: Maybe<Scalars['Boolean']>;
  vintageCardInformation?: Maybe<Array<Maybe<VintageCardInformation>>>;
};

export type WineCommonalityType = {
  __typename?: 'WineCommonalityType';
  wineId?: Maybe<Scalars['ID']>;
  wineEntityName?: Maybe<Scalars['String']>;
  wineEntityShortId?: Maybe<Scalars['String']>;
  wineShortId?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  appellation?: Maybe<Scalars['String']>;
  bottleImage?: Maybe<Img>;
  color?: Maybe<Scalars['String']>;
  sweetness?: Maybe<Scalars['String']>;
};

export type WineCompositionInput = {
  color?: Maybe<Scalars['String']>;
  sweetness?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type WineCompositionType = {
  __typename?: 'WineCompositionType';
  color?: Maybe<Scalars['String']>;
  sweetness?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type WineDescription = {
  __typename?: 'WineDescription';
  brandName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type WineEntitiesCandidatesResponse = {
  __typename?: 'WineEntitiesCandidatesResponse';
  found?: Maybe<Array<Maybe<WineEntityCandidate>>>;
  notFound?: Maybe<Array<Maybe<WineEntityCandidate>>>;
};

export type WineEntitiesCommonalitiesType = {
  __typename?: 'WineEntitiesCommonalitiesType';
  byCommune?: Maybe<WineEntityFilteredType>;
  byAppellation?: Maybe<WineEntityFilteredType>;
  bySubregion?: Maybe<WineEntityFilteredType>;
};

export type WineEntitiesData = {
  __typename?: 'WineEntitiesData';
  wineEntity?: Maybe<WineEntityData>;
  vineyard?: Maybe<Vineyard>;
  cellar?: Maybe<CellarData>;
};

export type WineEntitiesInput = {
  wineEntity?: Maybe<WineEntityInput>;
  vineyard?: Maybe<VineyardInput>;
  cellar?: Maybe<CellarInput>;
};

export type WineEntityBrief = {
  __typename?: 'WineEntityBrief';
  name?: Maybe<Scalars['String']>;
  image?: Maybe<Img>;
  wineEntityId?: Maybe<Scalars['ID']>;
  wineEntityShortId?: Maybe<Scalars['String']>;
  appellation?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  hasRedWines?: Maybe<Scalars['Boolean']>;
  hasRoseWines?: Maybe<Scalars['Boolean']>;
  hasWhiteWines?: Maybe<Scalars['Boolean']>;
};

export type WineEntityCandidate = {
  __typename?: 'WineEntityCandidate';
  year?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  sweetness?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  appellation?: Maybe<Scalars['String']>;
  characteristics?: Maybe<Scalars['String']>;
  withAppellation?: Maybe<Scalars['String']>;
  wineEntities?: Maybe<Array<Maybe<WineEntitySample>>>;
};

export type WineEntityCardInformation = {
  __typename?: 'WineEntityCardInformation';
  classifications?: Maybe<Array<Maybe<Scalars['String']>>>;
  img?: Maybe<Img>;
  wineColors?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasEnotourismActivity?: Maybe<Scalars['Boolean']>;
  hasBadge?: Maybe<Scalars['Boolean']>;
  surfaceArea?: Maybe<Scalars['Float']>;
  isOpenNow?: Maybe<Scalars['String']>;
  hasProductPageUrl?: Maybe<Scalars['Boolean']>;
};

export type WineEntityCommonalityType = {
  __typename?: 'WineEntityCommonalityType';
  wineEntityId?: Maybe<Scalars['String']>;
  wineEntityShortId?: Maybe<Scalars['String']>;
  wineEntityName?: Maybe<Scalars['String']>;
  appellation?: Maybe<Scalars['String']>;
  INSEEcode?: Maybe<Scalars['String']>;
  subregion?: Maybe<Scalars['String']>;
};

export type WineEntityData = {
  __typename?: 'WineEntityData';
  name?: Maybe<Scalars['String']>;
  cde_exp?: Maybe<Scalars['String']>;
  wineEntityId?: Maybe<Scalars['String']>;
  index_1?: Maybe<Scalars['String']>;
  EVVNumber?: Maybe<Scalars['String']>;
  imported?: Maybe<Scalars['Boolean']>;
  isAdopted?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  mainCommuneInseeCode?: Maybe<Scalars['String']>;
  mainCommune?: Maybe<Scalars['String']>;
  classifications?: Maybe<Array<Maybe<Scalars['String']>>>;
  classificationOrganisms?: Maybe<Array<Maybe<Scalars['String']>>>;
  mainAppellation?: Maybe<Scalars['String']>;
  wineAppellations?: Maybe<Array<Maybe<Scalars['String']>>>;
  subregion?: Maybe<Scalars['String']>;
  totalSurfaceArea?: Maybe<Mesure>;
};

export type WineEntityFilteredType = {
  __typename?: 'WineEntityFilteredType';
  result?: Maybe<Array<Maybe<WineEntityCommonalityType>>>;
  total?: Maybe<Scalars['Int']>;
};

export type WineEntityFilters = {
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  commune?: Maybe<Scalars['String']>;
  appellation?: Maybe<Scalars['String']>;
  freeTrial?: Maybe<Scalars['Boolean']>;
};

export type WineEntityInput = {
  name?: Maybe<Scalars['String']>;
  cde_exp?: Maybe<Scalars['String']>;
  wineEntityId?: Maybe<Scalars['String']>;
  index_1?: Maybe<Scalars['String']>;
  EVVNumber?: Maybe<Scalars['String']>;
  imported?: Maybe<Scalars['Boolean']>;
  isAdopted?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressInput>;
  mainCommuneInseeCode?: Maybe<Scalars['String']>;
  mainCommune?: Maybe<Scalars['String']>;
  classifications?: Maybe<Array<Maybe<Scalars['String']>>>;
  classificationOrganisms?: Maybe<Array<Maybe<Scalars['String']>>>;
  mainAppellation?: Maybe<Scalars['String']>;
  wineAppellations?: Maybe<Array<Maybe<Scalars['String']>>>;
  subregion?: Maybe<Scalars['String']>;
  totalSurfaceArea?: Maybe<MesureInput>;
};

export type WineEntityMetrics = {
  __typename?: 'WineEntityMetrics';
  surfaceImportCvi?: Maybe<Scalars['Boolean']>;
  wineEntityName?: Maybe<Scalars['String']>;
};

export type WineEntityResult = {
  __typename?: 'WineEntityResult';
  data?: Maybe<Array<Maybe<WineEntityResultData>>>;
  totalCount?: Maybe<Array<Maybe<CountResult>>>;
  similar?: Maybe<Scalars['Boolean']>;
};

export type WineEntityResultData = {
  __typename?: 'WineEntityResultData';
  wineEntityId?: Maybe<Scalars['String']>;
  wineEntityShortId?: Maybe<Scalars['String']>;
  wineEntityName?: Maybe<Scalars['String']>;
  appellation?: Maybe<Scalars['String']>;
  badge?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  onlineSales?: Maybe<Scalars['Boolean']>;
  isOpen?: Maybe<Scalars['Boolean']>;
  classification?: Maybe<Scalars['String']>;
  surfaceArea?: Maybe<Scalars['Float']>;
  missingField?: Maybe<Scalars['String']>;
};

export type WineEntitySample = {
  __typename?: 'WineEntitySample';
  name?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['ID']>;
  wineEntityId?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<Maybe<Img>>>;
  mainAppellation?: Maybe<Scalars['String']>;
  vintages?: Maybe<Array<Maybe<VintageCandidate>>>;
};

export type WineEntitySearchInput = {
  searchText?: Maybe<Scalars['String']>;
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  appellations?: Maybe<Array<Maybe<Scalars['String']>>>;
  appellationsWineProducts?: Maybe<Array<Maybe<Scalars['String']>>>;
  classifications?: Maybe<Array<Maybe<Scalars['String']>>>;
  badge?: Maybe<Scalars['Boolean']>;
  tourism?: Maybe<Array<Maybe<Scalars['String']>>>;
  sweetness?: Maybe<Array<Maybe<Scalars['String']>>>;
  colors?: Maybe<Array<Maybe<Scalars['String']>>>;
  subRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  communes?: Maybe<Array<Maybe<Scalars['String']>>>;
  priceRange?: Maybe<Array<Maybe<Scalars['Int']>>>;
  surface?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type WineEntityType = {
  __typename?: 'WineEntityType';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['String']>;
  subregion?: Maybe<Scalars['String']>;
  mainCommune?: Maybe<Scalars['String']>;
  mainAppellation?: Maybe<Scalars['String']>;
  siret?: Maybe<Scalars['String']>;
  searchable?: Maybe<Scalars['Boolean']>;
};

export type WineFilteredType = {
  __typename?: 'WineFilteredType';
  result?: Maybe<Array<Maybe<WineCommonalityType>>>;
  total?: Maybe<Scalars['Int']>;
};

export type WineFront = {
  __typename?: 'WineFront';
  wineId?: Maybe<Scalars['ID']>;
  shortId?: Maybe<Scalars['String']>;
  shortWineId?: Maybe<Scalars['String']>;
  rewarded?: Maybe<Scalars['Boolean']>;
  scored?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  rankFr?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorFr?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  sweetness?: Maybe<Scalars['String']>;
  appellation?: Maybe<Scalars['String']>;
  lastVintageId?: Maybe<Scalars['String']>;
  bottleImage?: Maybe<Img>;
  classifications?: Maybe<Array<Maybe<Classification>>>;
  shouldMentionSweetness?: Maybe<Scalars['Boolean']>;
  shouldMentionColor?: Maybe<Scalars['Boolean']>;
};

export type WineInformationFrontType = {
  __typename?: 'WineInformationFrontType';
  wineName?: Maybe<Scalars['String']>;
  aromas?: Maybe<Array<Maybe<Scalars['String']>>>;
  noseIntensity?: Maybe<Scalars['Int']>;
  tasteIntensity?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['Float']>;
  attack?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['String']>;
  acidity?: Maybe<Scalars['String']>;
  storageYear?: Maybe<Scalars['Int']>;
  about?: Maybe<Scalars['String']>;
};

export type WineInformationInput = {
  wineName?: Maybe<Scalars['String']>;
  aromas?: Maybe<Array<Maybe<Scalars['String']>>>;
  noseIntensity?: Maybe<Scalars['Int']>;
  tasteIntensity?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['Float']>;
  attack?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['String']>;
  acidity?: Maybe<Scalars['String']>;
  storageYear?: Maybe<Scalars['Int']>;
  about?: Maybe<Scalars['String']>;
};

export type WineInformationType = {
  __typename?: 'WineInformationType';
  wineName?: Maybe<Scalars['String']>;
  aromas?: Maybe<Array<Maybe<Scalars['String']>>>;
  noseIntensity?: Maybe<Scalars['Int']>;
  tasteIntensity?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['Float']>;
  attack?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['String']>;
  acidity?: Maybe<Scalars['String']>;
  storageYear?: Maybe<Scalars['Int']>;
  about?: Maybe<LngString>;
};

export type WineMaking = {
  __typename?: 'WineMaking';
  parcelBasedVinification?: Maybe<Scalars['Boolean']>;
  sortingMethod?: Maybe<Array<Maybe<Scalars['String']>>>;
  extractionMode?: Maybe<Array<Maybe<Scalars['String']>>>;
  pressType?: Maybe<Array<Maybe<Scalars['String']>>>;
  yeasting?: Maybe<Scalars['String']>;
  alcoholicFermentationDuration?: Maybe<Scalars['Int']>;
  alcoholicFermentationDurationUnit?: Maybe<Scalars['String']>;
  alcoholicFermentationTemperature?: Maybe<Scalars['Int']>;
};

export type WineMakingInput = {
  parcelBasedVinification?: Maybe<Scalars['Boolean']>;
  sortingMethod?: Maybe<Array<Maybe<Scalars['String']>>>;
  extractionMode?: Maybe<Array<Maybe<Scalars['String']>>>;
  pressType?: Maybe<Array<Maybe<Scalars['String']>>>;
  yeasting?: Maybe<Scalars['String']>;
  alcoholicFermentationDuration?: Maybe<Scalars['Int']>;
  alcoholicFermentationDurationUnit?: Maybe<Scalars['String']>;
  alcoholicFermentationTemperature?: Maybe<Scalars['Int']>;
};

export type WineMetrics = {
  __typename?: 'WineMetrics';
  wineId?: Maybe<Scalars['ID']>;
  wineName?: Maybe<Scalars['String']>;
  bottleImage?: Maybe<Img>;
  rewardCount?: Maybe<Scalars['Int']>;
  scroreCount?: Maybe<Scalars['Int']>;
  wineColor?: Maybe<Scalars['String']>;
  appellation?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  vintages?: Maybe<Array<Maybe<VintagesInfo>>>;
};

export type WinePresentationInput = {
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  sweetness?: Maybe<Scalars['String']>;
  classifications?: Maybe<Array<Maybe<ClassificationInput>>>;
  type?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  wineAssembly?: Maybe<Array<Maybe<SimpleGrapeVarietyPartInput>>>;
  allowedGrapeVarieties?: Maybe<Array<Maybe<Scalars['String']>>>;
  consultingEnologist?: Maybe<PersonOptionInput>;
  oenologueOptions?: Maybe<Array<Maybe<PersonOptionInput>>>;
  description?: Maybe<LngStringInput>;
  vinification?: Maybe<LngStringInput>;
  soils?: Maybe<Array<Maybe<Scalars['String']>>>;
  subsoils?: Maybe<Array<Maybe<Scalars['String']>>>;
  noSulphites?: Maybe<Scalars['Boolean']>;
  nonOGM?: Maybe<Scalars['Boolean']>;
  advice?: Maybe<AdviceInput>;
  wineMaking?: Maybe<WineMakingInput>;
  harvestMethod?: Maybe<Array<Maybe<Scalars['String']>>>;
  maturation?: Maybe<MaturationWineInput>;
  otherMaturations?: Maybe<Array<Maybe<OtherMaturationInput>>>;
  vineyard?: Maybe<SurfaceManualInput>;
  production?: Maybe<WineProductionInput>;
  marketing?: Maybe<MarketingWineInput>;
  images?: Maybe<Array<Maybe<ImgInput>>>;
  shortId?: Maybe<Scalars['String']>;
};

export type WinePresentationScreen = {
  __typename?: 'WinePresentationScreen';
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  sweetness?: Maybe<Scalars['String']>;
  classifications?: Maybe<Array<Maybe<Classification>>>;
  type?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  wineAssembly?: Maybe<Array<Maybe<SimpleGrapeVarietyPart>>>;
  allowedGrapeVarieties?: Maybe<Array<Maybe<Scalars['String']>>>;
  oenologueOptions?: Maybe<Array<Maybe<PersonOption>>>;
  consultingEnologist?: Maybe<PersonOption>;
  description?: Maybe<LngString>;
  vinification?: Maybe<LngString>;
  soils?: Maybe<Array<Maybe<Scalars['String']>>>;
  subsoils?: Maybe<Array<Maybe<Scalars['String']>>>;
  noSulphites?: Maybe<Scalars['Boolean']>;
  nonOGM?: Maybe<Scalars['Boolean']>;
  advice?: Maybe<AdviceType>;
  wineMaking?: Maybe<WineMaking>;
  harvestMethod?: Maybe<Array<Maybe<Scalars['String']>>>;
  maturation?: Maybe<MaturationWine>;
  otherMaturations?: Maybe<Array<Maybe<OtherMaturation>>>;
  vineyard?: Maybe<SurfaceManualScreen>;
  production?: Maybe<WineProduction>;
  marketing?: Maybe<MarketingWine>;
  images?: Maybe<Array<Maybe<Img>>>;
  shortId?: Maybe<Scalars['String']>;
  limitVineSurfaceArea?: Maybe<Scalars['Float']>;
};

export type WineProduction = {
  __typename?: 'WineProduction';
  bottlesVolume?: Maybe<Scalars['Int']>;
  yield?: Maybe<Scalars['Int']>;
  production?: Maybe<Scalars['Int']>;
};

export type WineProductionInput = {
  bottlesVolume?: Maybe<Scalars['Int']>;
  yield?: Maybe<Scalars['Int']>;
  production?: Maybe<Scalars['Int']>;
};

export type WineResult = {
  __typename?: 'WineResult';
  similar?: Maybe<Scalars['Boolean']>;
  colors?: Maybe<Array<Maybe<ByGroupResult>>>;
  appellations?: Maybe<Array<Maybe<ByGroupResult>>>;
  years?: Maybe<Array<Maybe<ByGroupResult>>>;
  cuvees?: Maybe<Array<Maybe<ByGroupResult>>>;
  data?: Maybe<Array<Maybe<WineResultData>>>;
  totalCount?: Maybe<Array<Maybe<CountResult>>>;
  similarData?: Maybe<Array<Maybe<WineResultData>>>;
  similarTotalCount?: Maybe<Scalars['Int']>;
};

export type WineResultData = {
  __typename?: 'WineResultData';
  _id?: Maybe<Scalars['String']>;
  wineEntityShortId?: Maybe<Scalars['String']>;
  wineShortId?: Maybe<Scalars['String']>;
  vintageIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  years?: Maybe<Array<Maybe<Scalars['Int']>>>;
  wineEntityId?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  sweetness?: Maybe<Scalars['String']>;
  appellation?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  wineEntityName?: Maybe<Scalars['String']>;
  wineId?: Maybe<Scalars['String']>;
  couleurMissing?: Maybe<Scalars['Int']>;
  appellationMissing?: Maybe<Scalars['Int']>;
  yearsMissing?: Maybe<Scalars['Int']>;
  sizeMissingFields?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  missingField?: Maybe<Scalars['String']>;
  totalNumber?: Maybe<Scalars['Int']>;
};

export type WineType = {
  __typename?: 'WineType';
  name?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  sweetness?: Maybe<Scalars['String']>;
  appellation?: Maybe<Scalars['String']>;
};

export type WineVintage = {
  __typename?: 'WineVintage';
  year?: Maybe<Scalars['Int']>;
  _id?: Maybe<Scalars['ID']>;
  cuvee?: Maybe<Scalars['String']>;
};

export type WinesCommonalitiesType = {
  __typename?: 'WinesCommonalitiesType';
  byAppellation?: Maybe<WineFilteredType>;
  byProfil?: Maybe<WineFilteredType>;
};

export type AddonInput = {
  addon_code?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type BarrelMakerPart = {
  __typename?: 'barrelMakerPart';
  barrelMaker?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Int']>;
};

export type BarrelMakerPartInput = {
  barrelMaker?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Int']>;
};

export type CommuneDistribution = {
  __typename?: 'communeDistribution';
  commune?: Maybe<Scalars['String']>;
  superficie?: Maybe<Scalars['Float']>;
  lieuDits?: Maybe<Array<Maybe<LieuDitDistribution>>>;
};

export type ConfigurationType = {
  __typename?: 'configurationType';
  pages?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  components?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type EncepagementDistribution = {
  __typename?: 'encepagementDistribution';
  cepage?: Maybe<Scalars['String']>;
  superficie?: Maybe<Scalars['Float']>;
};

export type FtIdInput = {
  ftId?: Maybe<Scalars['ID']>;
  inputs?: Maybe<Array<Maybe<UpdateFtpDiffusionInput>>>;
};

export type IngredientPart = {
  __typename?: 'ingredientPart';
  ingredient?: Maybe<Scalars['String']>;
  isAllergen?: Maybe<Scalars['Boolean']>;
};

export type IngredientPartInput = {
  ingredient?: Maybe<Scalars['String']>;
  isAllergen?: Maybe<Scalars['Boolean']>;
};

export type OpeningHour = {
  __typename?: 'openingHour';
  name?: Maybe<Scalars['String']>;
  open?: Maybe<Scalars['Boolean']>;
  openMorning?: Maybe<Scalars['Date']>;
  openAfternoon?: Maybe<Scalars['Date']>;
  closeMorning?: Maybe<Scalars['Date']>;
  closeAfternoon?: Maybe<Scalars['Date']>;
  nonStop?: Maybe<Scalars['Boolean']>;
  val?: Maybe<Scalars['Int']>;
};

export type OpeningHourInput = {
  name?: Maybe<Scalars['String']>;
  open?: Maybe<Scalars['Boolean']>;
  openMorning?: Maybe<Scalars['Date']>;
  openAfternoon?: Maybe<Scalars['Date']>;
  closeMorning?: Maybe<Scalars['Date']>;
  closeAfternoon?: Maybe<Scalars['Date']>;
  nonStop?: Maybe<Scalars['Boolean']>;
  val?: Maybe<Scalars['Int']>;
};

export type OtherMaturation = {
  __typename?: 'otherMaturation';
  containerType?: Maybe<Scalars['String']>;
  durationValue?: Maybe<Scalars['Int']>;
  durationUnit?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
};

export type OtherMaturationInput = {
  containerType?: Maybe<Scalars['String']>;
  durationValue?: Maybe<Scalars['Int']>;
  durationUnit?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
};

export enum PersonKind {
  Moral = 'MORAL',
  Physical = 'PHYSICAL'
}

export type VintageAppellationType = {
  __typename?: 'vintageAppellationType';
  year?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['String']>;
  cuvee?: Maybe<Scalars['String']>;
  vintageId?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  wineName?: Maybe<Scalars['String']>;
  bottlePicture?: Maybe<Img>;
  wineShortId?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  wineEntityName?: Maybe<Scalars['String']>;
  wineEntityShortId?: Maybe<Scalars['String']>;
};

export type CreateUploadUrlMutationVariables = Exact<{
  bucketName?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
}>;


export type CreateUploadUrlMutation = (
  { __typename?: 'Mutation' }
  & { CreateUploadURL?: Maybe<(
    { __typename?: 'UrlType' }
    & Pick<UrlType, 'url'>
  )> }
);

export type CreateDownloadUrlMutationVariables = Exact<{
  bucketName?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
}>;


export type CreateDownloadUrlMutation = (
  { __typename?: 'Mutation' }
  & { CreateDownloadURL?: Maybe<(
    { __typename?: 'UrlType' }
    & Pick<UrlType, 'url'>
  )> }
);

export type CreateWineMutationVariables = Exact<{
  input: CreateWineInput;
}>;


export type CreateWineMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateWine'>
);

export type RemoveCellarMutationVariables = Exact<{
  cellarId: Scalars['ID'];
}>;


export type RemoveCellarMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RemoveCellar'>
);

export type RemoveCertificationMutationVariables = Exact<{
  certificationId: Scalars['ID'];
}>;


export type RemoveCertificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RemoveCertification'>
);

export type ParseCviMutationVariables = Exact<{
  input: Scalars['JSON'];
  wineEntityId: Scalars['ID'];
}>;


export type ParseCviMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ParseCVI'>
);

export type DeleteAllSurfacesMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAllSurfacesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAllSurfaces'>
);

export type AddTeamMemberMutationVariables = Exact<{
  input: AddTeamMemberInput;
  wineEntityId: Scalars['ID'];
}>;


export type AddTeamMemberMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AddTeamMember'>
);

export type EditTeamMemberMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  personId: Scalars['ID'];
  input: EditTeamMemberInput;
}>;


export type EditTeamMemberMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditTeamMember'>
);

export type DeleteTeamMemberMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  personId: Scalars['ID'];
}>;


export type DeleteTeamMemberMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteTeamMember'>
);

export type ProprieteVignobleScreenMutationMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  input: ProprieteVignobleScreenInput;
}>;


export type ProprieteVignobleScreenMutationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ProprieteVignobleScreen'>
);

export type EditTeamGeneralMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  input: EditTeamGeneralInput;
}>;


export type EditTeamGeneralMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditTeamGeneral'>
);

export type EditGeneralInformationScreenMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  input: GeneralInformationScreenInput;
}>;


export type EditGeneralInformationScreenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GeneralInformationScreen'>
);

export type EditVintageViticultureScreenMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
  input: VintageViticultureScreenInput;
}>;


export type EditVintageViticultureScreenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditVintageViticultureScreen'>
);

export type EditVintageWineMakingScreenMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
  input: VintageWineMakingScreenInput;
}>;


export type EditVintageWineMakingScreenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditVintageWineMakingScreen'>
);

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;


export type SignUpMutation = (
  { __typename?: 'Mutation' }
  & { SignUp?: Maybe<(
    { __typename?: 'SignUpResponse' }
    & { user?: Maybe<(
      { __typename?: 'SignUpUser' }
      & Pick<SignUpUser, '_id' | 'email' | 'title' | 'firstName' | 'lastName' | 'telephoneNumber' | 'telephoneNumberCountry'>
    )> }
  )> }
);

export type SignUpInviteMutationVariables = Exact<{
  input: SignUpInviteInput;
}>;


export type SignUpInviteMutation = (
  { __typename?: 'Mutation' }
  & { SignUpInvite?: Maybe<(
    { __typename?: 'SignUpResponse' }
    & { user?: Maybe<(
      { __typename?: 'SignUpUser' }
      & Pick<SignUpUser, '_id' | 'email' | 'title' | 'firstName' | 'lastName' | 'telephoneNumber' | 'telephoneNumberCountry'>
    )> }
  )> }
);

export type SignInInviteMutationVariables = Exact<{
  input: SignInInviteInput;
}>;


export type SignInInviteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SignInInvite'>
);

export type SignUpJournalistMutationVariables = Exact<{
  input: SignUpJournalistInput;
}>;


export type SignUpJournalistMutation = (
  { __typename?: 'Mutation' }
  & { SignUpJournalist?: Maybe<(
    { __typename?: 'SignUpResponse' }
    & { user?: Maybe<(
      { __typename?: 'SignUpUser' }
      & Pick<SignUpUser, '_id' | 'email' | 'title' | 'firstName' | 'lastName'>
    )> }
  )> }
);

export type EditWinePresentationScreenMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  wineId: Scalars['ID'];
  input?: Maybe<WinePresentationInput>;
}>;


export type EditWinePresentationScreenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditWinePresentationScreen'>
);

export type EditVintageAgeingScreenMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
  input: VintageAgeingScreenInput;
}>;


export type EditVintageAgeingScreenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditVintageAgeingScreen'>
);

export type EditVintageProductionScreenMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
  input: VintageProductionScreenInput;
}>;


export type EditVintageProductionScreenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditVintageProductionScreen'>
);

export type EditVintageNutritionScreenMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
  input: VintageNutritionScreenInput;
}>;


export type EditVintageNutritionScreenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditVintageNutritionScreen'>
);

export type EditVintageMarketingScreenMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
  input: VintageMarketingScreenInput;
}>;


export type EditVintageMarketingScreenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditVintageMarketingScreen'>
);

export type EditVintageAdviceScreenMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
  input: VintageAdviceScreenInput;
}>;


export type EditVintageAdviceScreenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditVintageAdviceScreen'>
);

export type EditVintageAdviceUrlMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
  videoUrl: Scalars['String'];
}>;


export type EditVintageAdviceUrlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditVintageAdviceUrl'>
);

export type EditSurfaceManualMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  input: SurfaceManualInput;
}>;


export type EditSurfaceManualMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditSurfaceManual'>
);

export type EditEstateMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  input: EditEstateInput;
}>;


export type EditEstateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditEstate'>
);

export type EditVintageIdentityMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  wineId: Scalars['ID'];
  vintageId: Scalars['ID'];
  input: VintageIdentityInput;
}>;


export type EditVintageIdentityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditVintageIdentity'>
);

export type EditEstateComplementaryInfoMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  input: EstateComplementaryInfoInput;
}>;


export type EditEstateComplementaryInfoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditEstateComplementaryInfo'>
);

export type AddCellarMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  input: AddCellarInput;
}>;


export type AddCellarMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AddCellar'>
);

export type EditCellarTabMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  cellarId: Scalars['ID'];
  input: EditCellarTabInput;
}>;


export type EditCellarTabMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditCellarTab'>
);

export type AddVintageMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  input: CreateVintageInput;
}>;


export type AddVintageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateVintage'>
);

export type EditCellarPencilMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  cellarId: Scalars['ID'];
  input: EditCellarPencilInput;
}>;


export type EditCellarPencilMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditCellarPencil'>
);

export type CreateLabelBocMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  wineId?: Maybe<Scalars['ID']>;
  vintageId?: Maybe<Scalars['ID']>;
  input: CreateLabelBocInput;
}>;


export type CreateLabelBocMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateLabelBoc'>
);

export type ModifyLabelBocMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  certificationId: Scalars['ID'];
  input: UpdateCertificationInput;
}>;


export type ModifyLabelBocMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ModifyLabelBoc'>
);

export type ChangePasswordMutationVariables = Exact<{
  email: Scalars['String'];
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ChangePassword'>
);

export type EditAccountScreenMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: EditAccountInput;
}>;


export type EditAccountScreenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditAccountScreen'>
);

export type EditActivityMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  activityId: Scalars['ID'];
  input: EditActivityInput;
}>;


export type EditActivityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditActivity'>
);

export type AddActivityMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  input: AddActivityInput;
}>;


export type AddActivityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AddActivity'>
);

export type RemoveActivityMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  activityId: Scalars['ID'];
}>;


export type RemoveActivityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RemoveActivity'>
);

export type RemoveScoreMutationVariables = Exact<{
  scoreId: Scalars['ID'];
}>;


export type RemoveScoreMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RemoveScore'>
);

export type AddQrcodeStatsMutationVariables = Exact<{
  input: AddQrcodeStatsInput;
}>;


export type AddQrcodeStatsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AddQrcodeStats'>
);

export type AddConnectionLogMutationVariables = Exact<{
  input: AddConnectionLogInput;
}>;


export type AddConnectionLogMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AddConnectionLog'>
);

export type EditContactMutationVariables = Exact<{
  wineEntityId?: Maybe<Scalars['ID']>;
  personId?: Maybe<Scalars['ID']>;
  type: Scalars['String'];
  input: EditContactInput;
}>;


export type EditContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditContact'>
);

export type EditHiddenSurfacesMutationVariables = Exact<{
  input: EditHiddenSurfacesInput;
  wineEntityId: Scalars['ID'];
}>;


export type EditHiddenSurfacesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditHiddenSurfaces'>
);

export type SetImportCviMutationVariables = Exact<{
  input?: Maybe<Scalars['Boolean']>;
  wineEntityId: Scalars['ID'];
}>;


export type SetImportCviMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SetImportCVI'>
);

export type CreateCertificationSuggestionMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  input: CreateCertificationSuggestionInput;
}>;


export type CreateCertificationSuggestionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateCertificationSuggestion'>
);

export type EditRewardsMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
  input: EditRewardsInput;
}>;


export type EditRewardsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditRewards'>
);

export type MarkNotificationMutationVariables = Exact<{
  notificationId: Scalars['ID'];
  isRead?: Maybe<Scalars['Boolean']>;
}>;


export type MarkNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'MarkNotification'>
);

export type DeleteManyNotificationsMutationVariables = Exact<{
  notificationIds: Array<Maybe<Scalars['ID']>>;
}>;


export type DeleteManyNotificationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteManyNotifications'>
);

export type MarkManyNotificationsMutationVariables = Exact<{
  notificationIds: Array<Maybe<Scalars['ID']>>;
  isRead?: Maybe<Scalars['Boolean']>;
}>;


export type MarkManyNotificationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'MarkManyNotifications'>
);

export type DeleteNotificationMutationVariables = Exact<{
  notificationId: Scalars['ID'];
}>;


export type DeleteNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteNotification'>
);

export type NewTranslationRequestMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  locale: Scalars['String'];
  translationMode: TranslationMode;
  translationRequest?: Maybe<TranslationRequestInput>;
  price: Scalars['Float'];
  paymentMethod: Scalars['String'];
  wordCount: Scalars['Int'];
}>;


export type NewTranslationRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NewTranslationRequest'>
);

export type ApplyEstateCheckingMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  input?: Maybe<ApplyEstateCheckingInput>;
}>;


export type ApplyEstateCheckingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ApplyEstateChecking'>
);

export type ApplyOwnerOperatorMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type ApplyOwnerOperatorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ApplyOwnerOperator'>
);

export type ConfirmRewardPermissionMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
  rewardId?: Maybe<Scalars['ID']>;
}>;


export type ConfirmRewardPermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditRewards'>
);

export type GenerateFtMutationVariables = Exact<{
  ftParametersId: Scalars['ID'];
  locales?: Maybe<Array<Maybe<Scalars['String']>>>;
  userId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
}>;


export type GenerateFtMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GenerateFt'>
);

export type RequestFtLanguageMutationVariables = Exact<{
  ftParametersId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Boolean']>;
}>;


export type RequestFtLanguageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RequestFtLanguage'>
);

export type RequestFtLanguagesMutationVariables = Exact<{
  ftParametersId: Scalars['ID'];
  locales?: Maybe<LanguagesBoolInput>;
}>;


export type RequestFtLanguagesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RequestFtLanguages'>
);

export type CreateFeretContactMutationVariables = Exact<{
  input: FeretContactInput;
  wineEntityId: Scalars['ID'];
  diffusionListIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
}>;


export type CreateFeretContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateFeretContact'>
);

export type CancelContractCrmTaskMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type CancelContractCrmTaskMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CancelContractCRMTask'>
);

export type CreateFeretContactsMutationVariables = Exact<{
  contactInputs: Array<Maybe<FeretContactInput>>;
  wineEntityId: Scalars['ID'];
}>;


export type CreateFeretContactsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateFeretContacts'>
);

export type EditFeretContactMutationVariables = Exact<{
  input: FeretContactInput;
  contactId: Scalars['ID'];
  wineEntityId: Scalars['ID'];
}>;


export type EditFeretContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditFeretContact'>
);

export type DeleteFeretContactMutationVariables = Exact<{
  contactId: Scalars['ID'];
  wineEntityId: Scalars['ID'];
}>;


export type DeleteFeretContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteFeretContact'>
);

export type CreateDiffusionListMutationVariables = Exact<{
  diffusionListInput: DiffusionListInput;
  wineEntityId: Scalars['ID'];
}>;


export type CreateDiffusionListMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateDiffusionList'>
);

export type EditDiffusionListMutationVariables = Exact<{
  input: DiffusionListInput;
  diffusionListId: Scalars['ID'];
  wineEntityId: Scalars['ID'];
}>;


export type EditDiffusionListMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditDiffusionList'>
);

export type DeleteDiffusionListMutationVariables = Exact<{
  diffusionListId: Scalars['ID'];
  wineEntityId: Scalars['ID'];
}>;


export type DeleteDiffusionListMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteDiffusionList'>
);

export type RemoveContactFromDiffusionListMutationVariables = Exact<{
  diffusionListId: Scalars['ID'];
  wineEntityId: Scalars['ID'];
  contactId: Scalars['ID'];
}>;


export type RemoveContactFromDiffusionListMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RemoveContactFromDiffusionList'>
);

export type AddContactToDiffusionListMutationVariables = Exact<{
  diffusionListId: Scalars['ID'];
  wineEntityId: Scalars['ID'];
  contactId: Scalars['ID'];
}>;


export type AddContactToDiffusionListMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AddContactToDiffusionList'>
);

export type OpenFtDiffusionMutationVariables = Exact<{
  ftDiffusionId: Scalars['ID'];
}>;


export type OpenFtDiffusionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OpenFtDiffusion'>
);

export type EditAccountScreenEmptyMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: EditAccountScreenEmptyInput;
}>;


export type EditAccountScreenEmptyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditAccountScreenEmpty'>
);

export type EditJournalistAccountMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: EditJournalistAccountInput;
}>;


export type EditJournalistAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditJournalistAccount'>
);

export type EditServicesOfferByJournalistMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: EditServicesOfferByJournalistInput;
}>;


export type EditServicesOfferByJournalistMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditServicesOfferByJournalist'>
);

export type CreateFtDiffusionMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  contactIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  diffusionListIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  ftId: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
}>;


export type CreateFtDiffusionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateFtDiffusion'>
);

export type EditDiffusionParametersMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  parameters: DiffusionParametersInput;
}>;


export type EditDiffusionParametersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditDiffusionParameters'>
);

export type DeleteFtMutationVariables = Exact<{
  ftId: Scalars['ID'];
}>;


export type DeleteFtMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteFt'>
);

export type SendEstateMailMutationVariables = Exact<{
  wineEntityId: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  emailFrom: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  estateName: Scalars['String'];
  newsletter?: Maybe<Scalars['Boolean']>;
}>;


export type SendEstateMailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SendEstateMail'>
);

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteUser'>
);

export type EditContactAndResentDiffusionMutationVariables = Exact<{
  contactId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
}>;


export type EditContactAndResentDiffusionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditContactAndResentDiffusion'>
);

export type EditFtDiffusionMutationVariables = Exact<{
  ftId: Scalars['ID'];
  diffusionListIds?: Maybe<Array<Scalars['ID']>>;
  message?: Maybe<Scalars['String']>;
}>;


export type EditFtDiffusionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditFtDiffusion'>
);

export type DeleteWineMutationVariables = Exact<{
  wineId: Scalars['ID'];
}>;


export type DeleteWineMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteWine'>
);

export type DeleteVintageMutationVariables = Exact<{
  vintageId: Scalars['ID'];
}>;


export type DeleteVintageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteVintage'>
);

export type ResendAllMutationVariables = Exact<{
  ftId: Scalars['ID'];
  wineEntityId: Scalars['ID'];
}>;


export type ResendAllMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ResendAll'>
);

export type ResendOneMutationVariables = Exact<{
  ftDiffusionId: Scalars['ID'];
}>;


export type ResendOneMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ResendOne'>
);

export type SwitchStatusMutationVariables = Exact<{
  ftParametersId: Scalars['ID'];
  status: Scalars['String'];
}>;


export type SwitchStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SwitchStatus'>
);

export type CreateFtMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  wineId?: Maybe<Scalars['ID']>;
  vintageId?: Maybe<Scalars['ID']>;
  ftId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
}>;


export type CreateFtMutation = (
  { __typename?: 'Mutation' }
  & { CreateFt?: Maybe<(
    { __typename?: 'FtCreatorParams' }
    & Pick<FtCreatorParams, 'token' | 'ftParametersId'>
  )> }
);

export type EditFtMutationVariables = Exact<{
  ftParametersId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  online?: Maybe<Scalars['Boolean']>;
}>;


export type EditFtMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditFt'>
);

export type UpdateFtComponentsMutationVariables = Exact<{
  ftParametersId: Scalars['ID'];
}>;


export type UpdateFtComponentsMutation = (
  { __typename?: 'Mutation' }
  & { UpdateFtComponents?: Maybe<(
    { __typename?: 'FtCreatorParams' }
    & Pick<FtCreatorParams, 'token' | 'ftParametersId'>
  )> }
);

export type EditUserEmailMutationVariables = Exact<{
  userId: Scalars['String'];
  email: Scalars['String'];
}>;


export type EditUserEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditUserEmail'>
);

export type CreateContactsWithQueueMutationVariables = Exact<{
  csvFile: DocumentInput;
  wineEntityId: Scalars['ID'];
}>;


export type CreateContactsWithQueueMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateContactsWithQueue'>
);

export type CreateWebFtDiffusionMutationVariables = Exact<{
  userId: Scalars['ID'];
  ftId: Scalars['ID'];
}>;


export type CreateWebFtDiffusionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateWebFtDiffusion'>
);

export type CreateFtFromModelMutationVariables = Exact<{
  ftModelId: Scalars['ID'];
  wineEntityId: Scalars['ID'];
  wineId?: Maybe<Scalars['ID']>;
  vintageId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
}>;


export type CreateFtFromModelMutation = (
  { __typename?: 'Mutation' }
  & { CreateFtFromModel?: Maybe<(
    { __typename?: 'FtCreatorParams' }
    & Pick<FtCreatorParams, 'token' | 'ftParametersId'>
  )> }
);

export type HandleReCaptchaMutationVariables = Exact<{
  recaptchaResponse: Scalars['String'];
}>;


export type HandleReCaptchaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'HandleReCaptcha'>
);

export type SendContactMailMutationVariables = Exact<{
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  emailFrom?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  newsletter?: Maybe<Scalars['Boolean']>;
}>;


export type SendContactMailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SendContactMail'>
);

export type CreateRoleSugestionMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  roleName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;


export type CreateRoleSugestionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateRoleSugestion'>
);

export type CreateNewSubscriptionDataMutationVariables = Exact<{
  input: NewSubscriptionDataInput;
  freeTrial?: Maybe<Scalars['Boolean']>;
}>;


export type CreateNewSubscriptionDataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateNewSubscriptionData'>
);

export type GenerateSubscriptionHostedPageMutationVariables = Exact<{
  userId: Scalars['ID'];
  planCode: Scalars['String'];
  subscriptionDataId: Scalars['ID'];
  addonCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;


export type GenerateSubscriptionHostedPageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GenerateSubscriptionHostedPage'>
);

export type ChangeEstateVisibilityMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  visibility: Scalars['Boolean'];
}>;


export type ChangeEstateVisibilityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ChangeEstateVisibility'>
);

export type EditNoDisplayModalMutationVariables = Exact<{
  userId: Scalars['ID'];
  noDisplayModal: Array<Maybe<Scalars['String']>>;
}>;


export type EditNoDisplayModalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditNoDisplayModal'>
);

export type CreateServiceRequestMutationVariables = Exact<{
  input: ServiceRequestInput;
}>;


export type CreateServiceRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateServiceRequest'>
);

export type GenerateHostedPageAddonMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  addonCodes: Array<Maybe<AddonInput>>;
}>;


export type GenerateHostedPageAddonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GenerateHostedPageAddon'>
);

export type CancelSubscriptionDataMutationVariables = Exact<{
  subscriptionDataId: Scalars['ID'];
}>;


export type CancelSubscriptionDataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CancelSubscriptionData'>
);

export type EditPaperSubcriptionMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  paperSubsciptionName: Scalars['String'];
}>;


export type EditPaperSubcriptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditPaperSubcription'>
);

export type CheckEmailAvailabilityMutationVariables = Exact<{
  email: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
}>;


export type CheckEmailAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CheckEmailAvailability'>
);

export type EditTastingRequestMutationVariables = Exact<{
  tastingRequestId: Scalars['ID'];
  input: TastingRequestInput;
  locale?: Maybe<Scalars['String']>;
}>;


export type EditTastingRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditTastingRequest'>
);

export type EditTastingRequestStatusMutationVariables = Exact<{
  tastingRequestId: Scalars['ID'];
  status: Scalars['String'];
}>;


export type EditTastingRequestStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditTastingRequestStatus'>
);

export type GenerateHostedPageUpgradeMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  planName: Scalars['String'];
  addonCodes?: Maybe<Array<Maybe<AddonInput>>>;
}>;


export type GenerateHostedPageUpgradeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GenerateHostedPageUpgrade'>
);

export type CreateTastingRequestsMutationVariables = Exact<{
  tastingRequests?: Maybe<Array<Maybe<TastingRequestInput>>>;
}>;


export type CreateTastingRequestsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateTastingRequests'>
);

export type UpdateFtpDiffusionsMutationVariables = Exact<{
  wineEntityId?: Maybe<Scalars['ID']>;
  ftIdInputs?: Maybe<Array<Maybe<FtIdInput>>>;
}>;


export type UpdateFtpDiffusionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateFtpDiffusions'>
);

export type DiffuseOneMutationVariables = Exact<{
  ftpDiffusionId?: Maybe<Scalars['ID']>;
}>;


export type DiffuseOneMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DiffuseOne'>
);

export type SavePaperSubscriptionDataMutationVariables = Exact<{
  wineEntityId: Scalars['ID'];
  accessibility?: Maybe<Array<Maybe<Scalars['String']>>>;
  isBookingRequired?: Maybe<Scalars['Boolean']>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  priceRange?: Maybe<Array<Maybe<Scalars['Int']>>>;
  touristLabels?: Maybe<Scalars['String']>;
  visitSchedule?: Maybe<Scalars['String']>;
  hasOtherCulture?: Maybe<Scalars['Boolean']>;
  otherCulture?: Maybe<Scalars['String']>;
  bugFightMethod?: Maybe<Scalars['String']>;
  description?: Maybe<LngStringInput>;
  byGrassing?: Maybe<Scalars['Boolean']>;
  grassingMethod?: Maybe<Scalars['String']>;
  done?: Maybe<Scalars['Boolean']>;
  redVineSurface?: Maybe<Scalars['Int']>;
  whiteVineSurface?: Maybe<Scalars['Int']>;
  redAvgProd?: Maybe<Scalars['Int']>;
  whiteAvgProd?: Maybe<Scalars['Int']>;
  redWineAssembly?: Maybe<Array<Maybe<SimpleGrapeVarietyPartInput>>>;
  whiteWineAssembly?: Maybe<Array<Maybe<SimpleGrapeVarietyPartInput>>>;
  certificationFiles?: Maybe<Array<Maybe<DocumentInput>>>;
}>;


export type SavePaperSubscriptionDataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SavePaperSubscriptionData'>
);

export type GetSimpleUserByIdQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetSimpleUserByIdQuery = (
  { __typename?: 'Query' }
  & { GetSimpleUserById?: Maybe<(
    { __typename?: 'SimpleUser' }
    & Pick<SimpleUser, 'isSu' | 'userId' | 'email' | 'title' | 'firstName' | 'lastName' | 'telephoneNumber' | 'freeTrial' | 'subscriptionName' | 'subscriptionPaperName' | 'subscriptionRecurrence' | 'zohoSubscriptionId' | 'wineEntityId' | 'wineEntityShortId' | 'shortWineEntityId' | 'wineEntityName' | 'status' | 'mainAppellation' | 'mainCommune' | 'checkNeeded' | 'ownerOperatorChecked' | 'isProfessional' | 'company' | 'category' | 'addonCodes' | 'paymentStatus' | 'paymentMethod' | 'language' | 'cde_exp'>
    & { wineEntityImages?: Maybe<Array<Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'fileName' | 'mainImage' | 'backgroundImage'>
    )>>>, sideBarImage?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'fileName'>
    )> }
  )> }
);

export type AboutHistoryQueryVariables = Exact<{
  wineEntityId?: Maybe<Scalars['ID']>;
}>;


export type AboutHistoryQuery = (
  { __typename?: 'Query' }
  & { AboutHistory?: Maybe<(
    { __typename?: 'AboutHistory' }
    & Pick<AboutHistory, 'description'>
  )> }
);

export type ListWinesQueryVariables = Exact<{
  wineEntityId?: Maybe<Scalars['ID']>;
}>;


export type ListWinesQuery = (
  { __typename?: 'Query' }
  & { ListWines?: Maybe<Array<Maybe<(
    { __typename?: 'SimpleWine' }
    & Pick<SimpleWine, 'wineId' | 'brandName' | 'appellationLabel' | 'type' | 'color' | 'sweetness' | 'fullAppellation' | 'rank'>
    & { bottleImage?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )>, vintages?: Maybe<Array<Maybe<(
      { __typename?: 'WineVintage' }
      & Pick<WineVintage, '_id' | 'year' | 'cuvee'>
    )>>> }
  )>>> }
);

export type GetWineByIdQueryVariables = Exact<{
  wineId: Scalars['ID'];
}>;


export type GetWineByIdQuery = (
  { __typename?: 'Query' }
  & { GetWineById?: Maybe<(
    { __typename?: 'SimpleWineType' }
    & Pick<SimpleWineType, 'brandName' | 'wineEntityId'>
    & { images?: Maybe<Array<Maybe<(
      { __typename?: 'Image' }
      & { file?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'fileName'>
      )> }
    )>>>, label?: Maybe<(
      { __typename?: 'EnumType' }
      & Pick<EnumType, 'value'>
      & { valueLng?: Maybe<(
        { __typename?: 'LngString' }
        & Pick<LngString, 'fr' | 'locale'>
      )> }
    )>, color?: Maybe<(
      { __typename?: 'EnumType' }
      & Pick<EnumType, 'value'>
    )>, type?: Maybe<(
      { __typename?: 'EnumType' }
      & Pick<EnumType, 'value'>
    )>, sweetness?: Maybe<(
      { __typename?: 'EnumType' }
      & Pick<EnumType, 'value'>
    )>, soils?: Maybe<Array<Maybe<(
      { __typename?: 'EnumType' }
      & Pick<EnumType, 'value'>
    )>>>, subsoils?: Maybe<Array<Maybe<(
      { __typename?: 'EnumType' }
      & Pick<EnumType, 'value'>
    )>>> }
  )> }
);

export type GetEnumByFieldQueryVariables = Exact<{
  field: Scalars['String'];
}>;


export type GetEnumByFieldQuery = (
  { __typename?: 'Query' }
  & { GetEnumByField?: Maybe<Array<Maybe<(
    { __typename?: 'EnumType' }
    & Pick<EnumType, 'title' | 'value' | 'conditionIndex'>
    & { valueLng?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'de' | 'en' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )> }
  )>>> }
);

export type GetRoleEnumQueryVariables = Exact<{
  type: Scalars['String'];
  wineEntityId: Scalars['ID'];
  personId?: Maybe<Scalars['ID']>;
}>;


export type GetRoleEnumQuery = (
  { __typename?: 'Query' }
  & { GetRoleEnum?: Maybe<Array<Maybe<(
    { __typename?: 'EnumType' }
    & Pick<EnumType, 'title' | 'value' | 'conditionIndex'>
    & { valueLng?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'de' | 'en' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )> }
  )>>> }
);

export type GetVintagesByWineIdQueryVariables = Exact<{
  wineId: Scalars['ID'];
}>;


export type GetVintagesByWineIdQuery = (
  { __typename?: 'Query' }
  & { GetVintagesByWineId?: Maybe<Array<Maybe<(
    { __typename?: 'VintageType' }
    & Pick<VintageType, '_id' | 'wineEntityId' | 'wineId' | 'year' | 'cuvee'>
  )>>> }
);

export type SurfaceDeProductionScreenQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type SurfaceDeProductionScreenQuery = (
  { __typename?: 'Query' }
  & { SurfaceDeProductionScreen?: Maybe<(
    { __typename?: 'SurfaceDeProductionScreen' }
    & Pick<SurfaceDeProductionScreen, 'surfaceImportCVI' | 'superficieVigne' | 'densiteMoyenneDePlantation' | 'ageMoyenDesVignes' | 'totalParcelCount' | 'rootstocksTotal' | 'dateImportCvi'>
    & { surfaces?: Maybe<Array<Maybe<(
      { __typename?: 'SurfaceSimple' }
      & Pick<SurfaceSimple, 'appellation' | 'superficie' | 'couleur' | 'ageMoyenDesVignes' | 'densiteDePlantation' | 'vinesCount' | 'rootstocks' | 'parcelCount' | 'localities'>
      & { communes?: Maybe<Array<Maybe<(
        { __typename?: 'communeDistribution' }
        & Pick<CommuneDistribution, 'commune' | 'superficie'>
        & { lieuDits?: Maybe<Array<Maybe<(
          { __typename?: 'LieuDitDistribution' }
          & Pick<LieuDitDistribution, 'lieuDit' | 'superficie'>
        )>>> }
      )>>>, encepagements?: Maybe<Array<Maybe<(
        { __typename?: 'encepagementDistribution' }
        & Pick<EncepagementDistribution, 'cepage' | 'superficie'>
      )>>> }
    )>>>, hiddenSurfaces?: Maybe<Array<Maybe<(
      { __typename?: 'HiddenSurface' }
      & Pick<HiddenSurface, 'ageMoyenDesVignes' | 'densiteDePlantation' | 'communes' | 'encepagements' | 'vinesCount' | 'rootstocks' | 'parcelCount'>
    )>>> }
  )> }
);

export type GetAppellationDependencyObjectQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAppellationDependencyObjectQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AppellationDependencyObject'>
);

export type TeamMemberBocQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  personId: Scalars['ID'];
}>;


export type TeamMemberBocQuery = (
  { __typename?: 'Query' }
  & { TeamMemberBoc?: Maybe<(
    { __typename?: 'TeamMemberBoc' }
    & Pick<TeamMemberBoc, 'personId' | 'kind' | 'title' | 'firstName' | 'lastName' | 'roleComplement' | 'organizationName' | 'organizationStatus' | 'hidden' | 'proFunctions' | 'feminineProFunction' | 'dateOfBirth' | 'email' | 'telephone' | 'vip' | 'wasVip'>
    & { description?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, images?: Maybe<Array<Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'fileName' | 'mainImage' | 'backgroundImage' | 'caption'>
    )>>> }
  )> }
);

export type ProprieteVignobleScreenQueryQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type ProprieteVignobleScreenQueryQuery = (
  { __typename?: 'Query' }
  & { ProprieteVignobleScreen?: Maybe<(
    { __typename?: 'ProprieteVignobleScreen' }
    & Pick<ProprieteVignobleScreen, 'soil' | 'subsoil' | 'grassGrowing' | 'grassGrowingIsTotal' | 'grassGrowingIsTemporary' | 'fertilizers' | 'worksUnderTheRow' | 'pruningMethod' | 'greenProcedures' | 'sexualConfusion'>
    & { soilDescription?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, soilWorkDescription?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, vineWorkDescription?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, insectWorkDescription?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, description?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, otherCultures?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )> }
  )> }
);

export type GetFeminineTitlesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFeminineTitlesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'FeminineTitles'>
);

export type TeamGeneralQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type TeamGeneralQuery = (
  { __typename?: 'Query' }
  & { TeamGeneral?: Maybe<(
    { __typename?: 'TeamGeneral' }
    & { teamDescription?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, members?: Maybe<Array<Maybe<(
      { __typename?: 'GeneralTeamMember' }
      & Pick<GeneralTeamMember, 'vip' | 'kind' | 'title' | 'hidden' | 'personId' | 'lastName' | 'firstName' | 'displayName' | 'proFunctions' | 'organizationName' | 'organizationStatus'>
    )>>>, owners?: Maybe<Array<Maybe<(
      { __typename?: 'GeneralTeamMember' }
      & Pick<GeneralTeamMember, 'vip' | 'kind' | 'hidden' | 'personId' | 'lastName' | 'firstName' | 'displayName' | 'proFunctions' | 'organizationName' | 'organizationStatus'>
    )>>>, operators?: Maybe<Array<Maybe<(
      { __typename?: 'GeneralTeamMember' }
      & Pick<GeneralTeamMember, 'vip' | 'kind' | 'hidden' | 'personId' | 'lastName' | 'firstName' | 'displayName' | 'proFunctions' | 'organizationName' | 'organizationStatus'>
    )>>>, image?: Maybe<Array<Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'fileName' | 'mainImage' | 'backgroundImage' | 'caption'>
    )>>> }
  )> }
);

export type VintageIdentityScreenQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  wineId: Scalars['ID'];
  vintageId: Scalars['ID'];
}>;


export type VintageIdentityScreenQuery = (
  { __typename?: 'Query' }
  & { VintageIdentityScreen?: Maybe<(
    { __typename?: 'VintageIdentityScreen' }
    & Pick<VintageIdentityScreen, 'shortId' | 'wineName' | 'year' | 'alcoholicStrength' | 'residualSugar' | 'totalSulfur' | 'totalAcidity' | 'ph' | 'noSulphites' | 'bySeasonClimatology' | 'allowedGrapeVarieties' | 'volatileAcidity' | 'nonOGM' | 'ipt' | 'allergens'>
    & { qr?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'data' | 'fileName' | 'bucketName'>
    )>, consultingEnologist?: Maybe<(
      { __typename?: 'PersonOption' }
      & Pick<PersonOption, 'title' | 'type' | 'functions'>
    )>, globalClimatology?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, winterClimatology?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, springClimatology?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, fallClimatology?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, summerClimatology?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, vintageDetails?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, bottlePictures?: Maybe<Array<Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'fileName' | 'mainImage' | 'backgroundImage' | 'caption'>
    )>>>, bottleLabels?: Maybe<Array<Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'fileName' | 'mainImage' | 'backgroundImage' | 'caption'>
    )>>>, wineAssembly?: Maybe<Array<Maybe<(
      { __typename?: 'SimpleGrapeVarietyPart' }
      & Pick<SimpleGrapeVarietyPart, 'grapeVarietyName' | 'percentage'>
    )>>>, oenologueOptions?: Maybe<Array<Maybe<(
      { __typename?: 'PersonOption' }
      & Pick<PersonOption, 'title' | 'type' | 'functions' | 'personId'>
    )>>> }
  )> }
);

export type VintageViticultureScreenQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
}>;


export type VintageViticultureScreenQuery = (
  { __typename?: 'Query' }
  & { VintageViticultureScreen?: Maybe<(
    { __typename?: 'VintageViticultureScreen' }
    & Pick<VintageViticultureScreen, 'showDetailedPhenologicalStages' | 'showDetailedHarvest' | 'wineHarvestMethods' | 'numberOfSorts' | 'allowedGrapeVarieties'>
    & { greenOperations?: Maybe<Array<Maybe<(
      { __typename?: 'GreenOperation' }
      & Pick<GreenOperation, 'operation'>
      & { startAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )>, endAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )> }
    )>>>, simplePhenologicalStages?: Maybe<Array<Maybe<(
      { __typename?: 'SimplePhenologicalStage' }
      & Pick<SimplePhenologicalStage, 'stage'>
      & { startAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )>, endAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )> }
    )>>>, detailedPhenologicalStages?: Maybe<Array<Maybe<(
      { __typename?: 'DetailedPhenologicalStage' }
      & Pick<DetailedPhenologicalStage, 'stage' | 'grapeVariety'>
      & { startAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )>, endAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )> }
    )>>>, detailedHarvest?: Maybe<Array<Maybe<(
      { __typename?: 'DetailedHarvest' }
      & Pick<DetailedHarvest, 'method' | 'grapeVariety'>
      & { startAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )>, endAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )> }
    )>>>, harvest?: Maybe<Array<Maybe<(
      { __typename?: 'Harvest' }
      & Pick<Harvest, 'method'>
      & { startAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )>, endAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )> }
    )>>>, viticultureDetails?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )> }
  )> }
);

export type VintageWineMakingScreenQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
}>;


export type VintageWineMakingScreenQuery = (
  { __typename?: 'Query' }
  & { VintageWineMakingScreen?: Maybe<(
    { __typename?: 'VintageWineMakingScreen' }
    & Pick<VintageWineMakingScreen, 'vinificationPlace' | 'vinificationCoopName' | 'parcelBasedVinification' | 'sortingMethod' | 'destemming' | 'treading' | 'vatting' | 'extractionMode' | 'yeasting' | 'alcoholicFermentationDuration' | 'alcoholicFermentationDurationUnit' | 'alcoholicFermentationTemperature' | 'malolacticFermentation' | 'malolacticBacteria' | 'malolacticFermentationDuration' | 'malolacticFermentationDurationUnit' | 'malolacticFermentationTemperature' | 'pressType'>
    & { vinificationDetails?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, macerations?: Maybe<Array<Maybe<(
      { __typename?: 'Maceration' }
      & Pick<Maceration, 'type' | 'duration' | 'durationUnit' | 'temperature'>
    )>>> }
  )> }
);

export type GeneralInformationScreenQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GeneralInformationScreenQuery = (
  { __typename?: 'Query' }
  & { GeneralInformationScreen?: Maybe<(
    { __typename?: 'GeneralInformationScreen' }
    & Pick<GeneralInformationScreen, 'name' | 'siret' | 'telephoneCountry' | 'telephone' | 'faxCountry' | 'fax' | 'email' | 'website' | 'mainCommune' | 'mainAppellation' | 'displayOpeningHours' | 'isUniformOpeningHours'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'complementaryAddress' | 'postalCode' | 'commune' | 'communeINSEEcode'>
    )>, socialMedia?: Maybe<Array<Maybe<(
      { __typename?: 'SocialMedia' }
      & Pick<SocialMedia, 'platform' | 'name' | 'url'>
    )>>>, image?: Maybe<Array<Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'fileName' | 'mainImage' | 'backgroundImage' | 'caption'>
    )>>>, openingHours?: Maybe<Array<Maybe<(
      { __typename?: 'openingHour' }
      & Pick<OpeningHour, 'name' | 'open' | 'openMorning' | 'closeMorning' | 'openAfternoon' | 'closeAfternoon' | 'nonStop' | 'val'>
    )>>>, uniformOpeningHours?: Maybe<Array<Maybe<(
      { __typename?: 'openingHour' }
      & Pick<OpeningHour, 'name' | 'open' | 'openMorning' | 'closeMorning' | 'openAfternoon' | 'closeAfternoon' | 'nonStop' | 'val'>
    )>>>, classifications?: Maybe<Array<Maybe<(
      { __typename?: 'Classification' }
      & Pick<Classification, 'name' | 'url'>
    )>>> }
  )> }
);

export type WinePresentationScreenQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  wineId: Scalars['ID'];
}>;


export type WinePresentationScreenQuery = (
  { __typename?: 'Query' }
  & { WinePresentationScreen?: Maybe<(
    { __typename?: 'WinePresentationScreen' }
    & Pick<WinePresentationScreen, 'name' | 'shortId' | 'label' | 'color' | 'sweetness' | 'type' | 'rank' | 'soils' | 'subsoils' | 'noSulphites' | 'nonOGM' | 'limitVineSurfaceArea' | 'allowedGrapeVarieties' | 'harvestMethod'>
    & { classifications?: Maybe<Array<Maybe<(
      { __typename?: 'Classification' }
      & Pick<Classification, 'name' | 'url'>
    )>>>, description?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, vinification?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, wineAssembly?: Maybe<Array<Maybe<(
      { __typename?: 'SimpleGrapeVarietyPart' }
      & Pick<SimpleGrapeVarietyPart, 'grapeVarietyName' | 'percentage'>
    )>>>, oenologueOptions?: Maybe<Array<Maybe<(
      { __typename?: 'PersonOption' }
      & Pick<PersonOption, 'title' | 'type' | 'functions' | 'personId'>
    )>>>, consultingEnologist?: Maybe<(
      { __typename?: 'PersonOption' }
      & Pick<PersonOption, 'title' | 'type' | 'functions' | 'phoneNumber' | 'email' | 'personId'>
      & { image?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )> }
    )>, advice?: Maybe<(
      { __typename?: 'AdviceType' }
      & Pick<AdviceType, 'serviceTemperature' | 'ageing' | 'treatmentBeforeService' | 'decantingDuration' | 'appearanceColor' | 'colorOptions' | 'appearanceIntensity' | 'appearanceBrilliance' | 'appearanceLimpidity' | 'appearanceTears' | 'noseAromas' | 'noseIntensity' | 'palateAttack' | 'palateIntensity' | 'palateLength' | 'palateAcidity' | 'palateTannins' | 'aromaAnimal' | 'aromaBalsamic' | 'aromaConfectionery' | 'aromaEmpyreumatic' | 'aromaFermented' | 'aromaFloral' | 'aromaFruity' | 'aromaMineral' | 'aromaOaky' | 'aromaSpicy' | 'aromaVegetal'>
      & { description?: Maybe<(
        { __typename?: 'LngString' }
        & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
      )>, foodPairings?: Maybe<Array<Maybe<(
        { __typename?: 'FoodPairing' }
        & Pick<FoodPairing, 'category' | 'food'>
      )>>>, aromaValues?: Maybe<(
        { __typename?: 'AromaValues' }
        & Pick<AromaValues, 'Animal' | 'Balsamique' | 'Boise' | 'Confiserie' | 'Empyreumatique' | 'Epice' | 'Fermentaire' | 'Floral' | 'Fruite' | 'Mineral' | 'Vegetal'>
      )> }
    )>, wineMaking?: Maybe<(
      { __typename?: 'WineMaking' }
      & Pick<WineMaking, 'parcelBasedVinification' | 'sortingMethod' | 'extractionMode' | 'pressType' | 'yeasting' | 'alcoholicFermentationDuration' | 'alcoholicFermentationDurationUnit' | 'alcoholicFermentationTemperature'>
    )>, maturation?: Maybe<(
      { __typename?: 'MaturationWine' }
      & Pick<MaturationWine, 'barrelMaturation' | 'barrelMaturationDurationValue' | 'barrelMaturationDurationUnit' | 'newBarrelPercentage' | 'toasting' | 'frenchOak'>
    )>, otherMaturations?: Maybe<Array<Maybe<(
      { __typename?: 'otherMaturation' }
      & Pick<OtherMaturation, 'containerType' | 'durationValue' | 'durationUnit' | 'material'>
    )>>>, vineyard?: Maybe<(
      { __typename?: 'SurfaceManualScreen' }
      & Pick<SurfaceManualScreen, 'totalSurfaceArea' | 'averageDensity' | 'averageAge'>
    )>, production?: Maybe<(
      { __typename?: 'WineProduction' }
      & Pick<WineProduction, 'bottlesVolume' | 'yield' | 'production'>
    )>, marketing?: Maybe<(
      { __typename?: 'MarketingWine' }
      & Pick<MarketingWine, 'exportPercentage' | 'directPercentage' | 'indirectPercentage' | 'exportCountries'>
    )>, images?: Maybe<Array<Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'fileName' | 'mainImage' | 'backgroundImage' | 'caption'>
    )>>> }
  )> }
);

export type VintageAgeingScreenQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
}>;


export type VintageAgeingScreenQuery = (
  { __typename?: 'Query' }
  & { VintageAgeingScreen?: Maybe<(
    { __typename?: 'VintageAgeingScreen' }
    & Pick<VintageAgeingScreen, 'barrelMaturation' | 'barrelMaturationDurationValue' | 'barrelMaturationDurationUnit' | 'newBarrelPercentage' | 'toasting' | 'frenchOak' | 'lees' | 'batonnage'>
    & { barrelMakers?: Maybe<Array<Maybe<(
      { __typename?: 'barrelMakerPart' }
      & Pick<BarrelMakerPart, 'barrelMaker' | 'percentage'>
    )>>>, otherMaturations?: Maybe<Array<Maybe<(
      { __typename?: 'otherMaturation' }
      & Pick<OtherMaturation, 'containerType' | 'durationValue' | 'durationUnit' | 'material'>
    )>>>, description?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )> }
  )> }
);

export type VintageProductionScreenQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
}>;


export type VintageProductionScreenQuery = (
  { __typename?: 'Query' }
  & { VintageProductionScreen?: Maybe<(
    { __typename?: 'VintageProductionScreen' }
    & Pick<VintageProductionScreen, 'corkingMode' | 'finingTypes' | 'filtrationTypes' | 'bottlingDate' | 'estateBottling' | 'bottlesVolume' | 'yield' | 'production' | 'waxedBottle' | 'bottlerCommune' | 'bottlerCommuneINSEEcode' | 'bottlerPostalCode'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'complementaryAddress' | 'postalCode' | 'commune' | 'communeINSEEcode'>
    )>, wineEntityAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'postalCode' | 'complementaryAddress' | 'commune' | 'communeINSEEcode'>
    )>, bottlerOptions?: Maybe<Array<Maybe<(
      { __typename?: 'BottlerOption' }
      & Pick<BottlerOption, 'title' | 'type' | 'postalCode' | 'commune' | 'communeINSEEcode'>
    )>>>, bottler?: Maybe<(
      { __typename?: 'BottlerOption' }
      & Pick<BottlerOption, 'title' | 'type'>
    )> }
  )> }
);

export type VintageNutritionScreenQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
}>;


export type VintageNutritionScreenQuery = (
  { __typename?: 'Query' }
  & { VintageNutritionScreen?: Maybe<(
    { __typename?: 'VintageNutritionScreen' }
    & Pick<VintageNutritionScreen, 'fats' | 'saturatedFattyAcids' | 'glucides' | 'sugars' | 'protein' | 'salt' | 'energy' | 'energyType' | 'alcoholicStrength' | 'residualSugar' | 'totalAcidity' | 'auto'>
    & { nutritionalAnalysis?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'data' | 'fileName' | 'bucketName'>
    )>>>, qrnutri?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'data' | 'fileName' | 'bucketName'>
    )>, ingredients?: Maybe<Array<Maybe<(
      { __typename?: 'ingredientPart' }
      & Pick<IngredientPart, 'ingredient'>
    )>>> }
  )> }
);

export type GetNotificationsQueryVariables = Exact<{
  userId?: Maybe<Scalars['ID']>;
}>;


export type GetNotificationsQuery = (
  { __typename?: 'Query' }
  & { GetNotifications?: Maybe<Array<Maybe<(
    { __typename?: 'FeretNotification' }
    & Pick<FeretNotification, 'notificationId' | 'wineEntityId' | 'category' | 'dateCreated' | 'dateRead' | 'text' | 'extraContent' | 'deleted' | 'action' | 'actionArgs'>
  )>>> }
);

export type VintageMarketingScreenQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
}>;


export type VintageMarketingScreenQuery = (
  { __typename?: 'Query' }
  & { VintageMarketingScreen?: Maybe<(
    { __typename?: 'VintageMarketingScreen' }
    & Pick<VintageMarketingScreen, 'ownWebsiteSelling' | 'productPageUrl' | 'exportPercentage' | 'directPercentage' | 'indirectPercentage' | 'exportCountries'>
    & { packagings?: Maybe<Array<Maybe<(
      { __typename?: 'Packaging' }
      & Pick<Packaging, 'centilisation' | 'units' | 'conditionningMode' | 'price' | 'gtin' | 'url'>
    )>>> }
  )> }
);

export type VintageAdviceScreenQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
}>;


export type VintageAdviceScreenQuery = (
  { __typename?: 'Query' }
  & { VintageAdviceScreen?: Maybe<(
    { __typename?: 'VintageAdviceScreen' }
    & Pick<VintageAdviceScreen, 'videoUrl' | 'serviceTemperature' | 'apogee' | 'treatmentBeforeService' | 'decantingDuration' | 'appearanceColor' | 'appearanceIntensity' | 'appearanceBrilliance' | 'appearanceLimpidity' | 'appearanceTears' | 'noseAromas' | 'noseIntensity' | 'palateAttack' | 'palateIntensity' | 'palateLength' | 'palateAcidity' | 'palateTannins' | 'aromaAnimal' | 'aromaBalsamic' | 'aromaConfectionery' | 'aromaEmpyreumatic' | 'aromaFermented' | 'aromaFloral' | 'aromaFruity' | 'aromaMineral' | 'aromaOaky' | 'aromaSpicy' | 'aromaVegetal' | 'colorOptions'>
    & { foodPairings?: Maybe<Array<Maybe<(
      { __typename?: 'FoodPairing' }
      & Pick<FoodPairing, 'category' | 'food'>
    )>>>, aromaValues?: Maybe<(
      { __typename?: 'AromaValues' }
      & Pick<AromaValues, 'Animal' | 'Balsamique' | 'Boise' | 'Confiserie' | 'Empyreumatique' | 'Epice' | 'Fermentaire' | 'Floral' | 'Fruite' | 'Mineral' | 'Vegetal'>
    )>, description?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )> }
  )> }
);

export type SurfaceImportCviQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type SurfaceImportCviQuery = (
  { __typename?: 'Query' }
  & { SurfaceImportCvi?: Maybe<(
    { __typename?: 'SurfaceImportCvi' }
    & Pick<SurfaceImportCvi, 'surfaceImportCVI' | 'dateImportCvi'>
  )> }
);

export type CellarsDashboardQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type CellarsDashboardQuery = (
  { __typename?: 'Query' }
  & { CellarsDashboard?: Maybe<(
    { __typename?: 'CellarsDashboard' }
    & { cellars?: Maybe<Array<Maybe<(
      { __typename?: 'SimpleCellar' }
      & Pick<SimpleCellar, 'cellarId' | 'name'>
    )>>>, wineEntityAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'postalCode' | 'complementaryAddress' | 'commune' | 'communeINSEEcode'>
    )> }
  )> }
);

export type SurfaceManualScreenQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type SurfaceManualScreenQuery = (
  { __typename?: 'Query' }
  & { SurfaceManualScreen?: Maybe<(
    { __typename?: 'SurfaceManualScreen' }
    & Pick<SurfaceManualScreen, 'surfaceImportCVI' | 'estateSurfaceArea' | 'totalSurfaceArea' | 'averageAge' | 'averageDensity'>
    & { surfaces?: Maybe<Array<Maybe<(
      { __typename?: 'SingleSurface' }
      & Pick<SingleSurface, 'appellation' | 'grapeColor' | 'surfaceArea'>
    )>>>, allowedAppellations?: Maybe<Array<Maybe<(
      { __typename?: 'SurfaceAppellation' }
      & Pick<SurfaceAppellation, 'color' | 'appellation'>
    )>>> }
  )> }
);

export type EstateScreenQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type EstateScreenQuery = (
  { __typename?: 'Query' }
  & { EstateScreen?: Maybe<(
    { __typename?: 'EstateScreen' }
    & Pick<EstateScreen, 'wineEntityName' | 'info_19e' | 'promotionBodies' | 'commercialisationTypes' | 'totalSurfaceArea' | 'vineSurfaceArea'>
    & { images?: Maybe<Array<Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'fileName' | 'mainImage' | 'backgroundImage' | 'caption' | 'hidden'>
    )>>>, description?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, citation?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, author?: Maybe<(
      { __typename?: 'PersonOption' }
      & Pick<PersonOption, 'title' | 'type' | 'functions'>
    )>, authorOptions?: Maybe<Array<Maybe<(
      { __typename?: 'PersonOption' }
      & Pick<PersonOption, 'title' | 'type' | 'functions' | 'personId'>
    )>>>, defaultBgImage?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )> }
  )> }
);

export type AccountScreenEmptyQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type AccountScreenEmptyQuery = (
  { __typename?: 'Query' }
  & { AccountScreenEmpty?: Maybe<(
    { __typename?: 'AccountScreenEmpty' }
    & Pick<AccountScreenEmpty, 'logInProviders' | 'hasPassword' | 'title' | 'firstName' | 'lastName' | 'telephoneNumber' | 'isEmailValid' | 'email' | 'isProfessional' | 'professionalCategory' | 'professionalCompany' | 'allowDigest'>
    & { professionalAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'complementaryAddress' | 'address' | 'commune' | 'postalCode' | 'communeINSEEcode' | 'country'>
    )> }
  )> }
);

export type JournalistAccountScreenQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type JournalistAccountScreenQuery = (
  { __typename?: 'Query' }
  & { JournalistAccountScreen?: Maybe<(
    { __typename?: 'JournalistAccountScreen' }
    & Pick<JournalistAccountScreen, 'logInProviders' | 'email' | 'isEmailValid' | 'hasPassword' | 'lastName' | 'firstName' | 'title' | 'displayName' | 'companyName' | 'isFeretAddress' | 'locale'>
    & { shippingAddress?: Maybe<(
      { __typename?: 'ShippingAddressType' }
      & Pick<ShippingAddressType, 'recipient' | 'isDefault'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'complementaryAddress' | 'address' | 'commune' | 'postalCode' | 'communeINSEEcode' | 'country'>
      )> }
    )>, paymentInformation?: Maybe<(
      { __typename?: 'PaymentInformationType' }
      & Pick<PaymentInformationType, 'iban' | 'bic'>
    )> }
  )> }
);

export type GetServicesOfferByJournalistQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetServicesOfferByJournalistQuery = (
  { __typename?: 'Query' }
  & { GetServicesOfferByJournalist?: Maybe<(
    { __typename?: 'GetServicesOfferByJournalist' }
    & Pick<GetServicesOfferByJournalist, 'areas' | 'maxTasting' | 'delay' | 'video' | 'demoVideoUrl'>
    & { prerequisites?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )> }
  )> }
);

export type AccountScreenQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type AccountScreenQuery = (
  { __typename?: 'Query' }
  & { AccountScreen?: Maybe<(
    { __typename?: 'AccountScreen' }
    & Pick<AccountScreen, 'subscriptionRecurrence' | 'logInProviders' | 'newsletter' | 'hasPassword' | 'title' | 'firstName' | 'lastName' | 'allowDigest' | 'isEmailValid' | 'email'>
    & { billingAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'complementaryAddress' | 'address' | 'commune' | 'postalCode' | 'communeINSEEcode' | 'country'>
    )>, billingInformation?: Maybe<(
      { __typename?: 'BillingInformation' }
      & Pick<BillingInformation, 'siret' | 'organizationStatus' | 'organizationName' | 'tvaNumber' | 'title' | 'firstName' | 'lastName' | 'email' | 'telephoneNumber'>
    )> }
  )> }
);

export type CellarTabScreenQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  cellarId: Scalars['ID'];
}>;


export type CellarTabScreenQuery = (
  { __typename?: 'Query' }
  & { CellarTabScreen?: Maybe<(
    { __typename?: 'CellarTabScreen' }
    & Pick<CellarTabScreen, 'name' | 'sameAddressAsWineEntity' | 'surfaceArea' | 'airConditioning' | 'temperature' | 'vatThermoregulation' | 'vatCirculation' | 'isAgeing' | 'isVathouse' | 'isConditioning' | 'conditioningActivities' | 'numberOfConditioningChains' | 'conditioningCapacity' | 'isStorage' | 'storageCapacity' | 'storageCapacityUnit'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'complementaryAddress' | 'address' | 'commune' | 'postalCode' | 'communeINSEEcode'>
    )>, wineEntityAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'complementaryAddress' | 'address' | 'commune' | 'postalCode' | 'communeINSEEcode'>
    )>, images?: Maybe<Array<Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'fileName' | 'mainImage' | 'backgroundImage' | 'caption'>
    )>>>, description?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, vatContainers?: Maybe<Array<Maybe<(
      { __typename?: 'VatContainer' }
      & Pick<VatContainer, 'kind' | 'material' | 'form' | 'capacity' | 'quantity'>
    )>>>, ageingContainers?: Maybe<Array<Maybe<(
      { __typename?: 'VatContainer' }
      & Pick<VatContainer, 'kind' | 'material' | 'form' | 'capacity' | 'quantity'>
    )>>> }
  )> }
);

export type SimpleVintagesQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  wineId: Scalars['ID'];
}>;


export type SimpleVintagesQuery = (
  { __typename?: 'Query' }
  & { SimpleVintages?: Maybe<Array<Maybe<(
    { __typename?: 'SimpleVintage' }
    & Pick<SimpleVintage, 'year' | 'vintageId' | 'cuvee'>
  )>>> }
);

export type GetLabelsForBocQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  wineId?: Maybe<Scalars['ID']>;
  vintageId?: Maybe<Scalars['ID']>;
  level?: Maybe<CertificationLevel>;
}>;


export type GetLabelsForBocQuery = (
  { __typename?: 'Query' }
  & { GetLabelsForBOC?: Maybe<Array<Maybe<(
    { __typename?: 'LabelsBOC' }
    & Pick<LabelsBoc, 'object' | 'createdAt' | 'updatedAt' | 'element' | 'expiryDate' | 'visibility' | 'refusalReason' | 'internalStatus' | 'isInConversion' | 'certificationId' | 'certificationBody'>
    & { proofDocuments?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'data' | 'fileName'>
    )>>>, logo?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )> }
  )>>> }
);

export type GetCertificationObjectsQueryVariables = Exact<{
  level?: Maybe<CertificationLevel>;
}>;


export type GetCertificationObjectsQuery = (
  { __typename?: 'Query' }
  & { GetCertificationObjects?: Maybe<Array<Maybe<(
    { __typename?: 'SimpleCertificationObject' }
    & Pick<SimpleCertificationObject, 'name' | 'level' | 'url' | 'inConversionPossible'>
    & { logo?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )> }
  )>>> }
);

export type SubscriptionSummaryQueryVariables = Exact<{
  wineEntityId?: Maybe<Scalars['ID']>;
}>;


export type SubscriptionSummaryQuery = (
  { __typename?: 'Query' }
  & { SubscriptionSummary?: Maybe<(
    { __typename?: 'SubscriptionSummary' }
    & Pick<SubscriptionSummary, 'wineEntityName' | 'mainAppellation'>
    & { bgImage?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )> }
  )> }
);

export type GetCommunesOptionsQueryVariables = Exact<{
  postalCode?: Maybe<Scalars['String']>;
  filterByRegion?: Maybe<Scalars['String']>;
}>;


export type GetCommunesOptionsQuery = (
  { __typename?: 'Query' }
  & { GetCommunesOptions?: Maybe<Array<Maybe<(
    { __typename?: 'CommuneOption' }
    & Pick<CommuneOption, 'communeName' | 'communeINSEEcode' | 'appellations' | 'simpleCommuneName' | 'postalCode'>
  )>>> }
);

export type ActivityListQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type ActivityListQuery = (
  { __typename?: 'Query' }
  & { ActivityList?: Maybe<(
    { __typename?: 'ActivityList' }
    & { activities?: Maybe<Array<Maybe<(
      { __typename?: 'SimpleActivity' }
      & Pick<SimpleActivity, 'activityId' | 'name'>
    )>>> }
  )> }
);

export type EstateFrontScreenQueryVariables = Exact<{
  wineEntityShortId: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
}>;


export type EstateFrontScreenQuery = (
  { __typename?: 'Query' }
  & { EstateFrontScreen?: Maybe<(
    { __typename?: 'EstateFrontScreen' }
    & Pick<EstateFrontScreen, 'wineEntityId' | 'name' | 'subscriptionName' | 'hasBadge' | 'isOrphan' | 'promotionBodies' | 'mainAppellation' | 'estateSurfaceArea' | 'displayOpeningHours' | 'commercialisationTypes' | 'description' | 'truncatedDescription' | 'region' | 'subregion' | 'vineSurfaceArea' | 'commune' | 'citation' | 'teamDescription' | 'telephoneNumber' | 'hasVineyardInformation'>
    & { logos?: Maybe<Array<Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'twicpicURL' | 'twicpicPath'>
    )>>>, classifications?: Maybe<Array<Maybe<(
      { __typename?: 'Classification' }
      & Pick<Classification, 'name' | 'url'>
    )>>>, certifications?: Maybe<Array<Maybe<(
      { __typename?: 'CertificationFront' }
      & Pick<CertificationFront, 'name' | 'isInConversion'>
    )>>>, bgImage?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'twicpicURL'>
    )>, socialMedias?: Maybe<Array<Maybe<(
      { __typename?: 'SocialMediaFront' }
      & Pick<SocialMediaFront, 'platform' | 'name' | 'url'>
      & { icon?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )> }
    )>>>, images?: Maybe<Array<Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'twicpicURL' | 'twicpicPath' | 'data' | 'caption' | 'backgroundImage' | 'fileName' | 'bucketName'>
    )>>>, cellars?: Maybe<Array<Maybe<(
      { __typename?: 'CellarFront' }
      & Pick<CellarFront, 'name' | 'surfaceArea' | 'isVathouse' | 'isConditioning' | 'isStorage' | 'isAgeing' | 'cellarId'>
      & { images?: Maybe<Array<Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data' | 'twicpicURL' | 'twicpicPath'>
      )>>> }
    )>>>, subregionImage?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )>, citationAuthor?: Maybe<(
      { __typename?: 'PersonOption' }
      & Pick<PersonOption, 'title' | 'functions'>
    )>, teamImage?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'twicpicURL'>
    )>, members?: Maybe<Array<Maybe<(
      { __typename?: 'TeamMemberFront' }
      & Pick<TeamMemberFront, 'displayName' | 'roles' | 'vip' | 'description' | 'roleComplement' | 'defaultImage'>
      & { image?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data' | 'twicpicURL' | 'twicpicPath'>
      )> }
    )>>>, owners?: Maybe<Array<Maybe<(
      { __typename?: 'TeamMemberFront' }
      & Pick<TeamMemberFront, 'displayName' | 'roles' | 'vip' | 'description' | 'defaultImage'>
      & { image?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )> }
    )>>>, operators?: Maybe<Array<Maybe<(
      { __typename?: 'TeamMemberFront' }
      & Pick<TeamMemberFront, 'displayName' | 'roles' | 'vip' | 'description' | 'defaultImage'>
      & { image?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )> }
    )>>>, firstWines?: Maybe<Array<Maybe<(
      { __typename?: 'WineFront' }
      & Pick<WineFront, 'scored' | 'rewarded' | 'name' | 'rank' | 'color' | 'type' | 'sweetness' | 'lastVintageId' | 'appellation'>
      & { classifications?: Maybe<Array<Maybe<(
        { __typename?: 'Classification' }
        & Pick<Classification, 'name' | 'url'>
      )>>>, bottleImage?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )> }
    )>>>, secondWines?: Maybe<Array<Maybe<(
      { __typename?: 'WineFront' }
      & Pick<WineFront, 'scored' | 'rewarded' | 'name' | 'rank' | 'color' | 'type' | 'sweetness' | 'lastVintageId' | 'appellation'>
      & { classifications?: Maybe<Array<Maybe<(
        { __typename?: 'Classification' }
        & Pick<Classification, 'name' | 'url'>
      )>>>, bottleImage?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )> }
    )>>>, otherWines?: Maybe<Array<Maybe<(
      { __typename?: 'WineFront' }
      & Pick<WineFront, 'scored' | 'rewarded' | 'name' | 'rank' | 'color' | 'type' | 'sweetness' | 'lastVintageId' | 'appellation'>
      & { classifications?: Maybe<Array<Maybe<(
        { __typename?: 'Classification' }
        & Pick<Classification, 'name' | 'url'>
      )>>>, bottleImage?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )> }
    )>>>, activities?: Maybe<Array<Maybe<(
      { __typename?: 'ActivityFront' }
      & Pick<ActivityFront, 'free' | 'activityId' | 'name' | 'description' | 'groupSize' | 'accessibleTo' | 'startingAge' | 'tags' | 'duration' | 'durationUnit' | 'reservationNeeded' | 'contactPhoneNumber' | 'contactEmail'>
      & { types?: Maybe<Array<Maybe<(
        { __typename?: 'ActivityType' }
        & Pick<ActivityType, 'bgColor' | 'textColor' | 'type'>
      )>>>, languages?: Maybe<Array<Maybe<(
        { __typename?: 'Language' }
        & Pick<Language, 'language'>
        & { icon?: Maybe<(
          { __typename?: 'Img' }
          & Pick<Img, 'data' | 'twicpicURL'>
        )> }
      )>>>, image?: Maybe<Array<Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )>>>, activePeriods?: Maybe<Array<Maybe<(
        { __typename?: 'ActivePeriod' }
        & { startAt?: Maybe<(
          { __typename?: 'VagueDate' }
          & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
        )>, endAt?: Maybe<(
          { __typename?: 'VagueDate' }
          & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
        )> }
      )>>>, priceDetails?: Maybe<Array<Maybe<(
        { __typename?: 'PriceDetail' }
        & Pick<PriceDetail, 'kind' | 'price' | 'currency'>
      )>>>, contactPerson?: Maybe<(
        { __typename?: 'PersonOption' }
        & Pick<PersonOption, 'title' | 'type' | 'functions' | 'phoneNumber' | 'email' | 'personId'>
        & { image?: Maybe<(
          { __typename?: 'Img' }
          & Pick<Img, 'data'>
        )> }
      )> }
    )>>>, address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'complementaryAddress' | 'commune' | 'postalCode' | 'communeINSEEcode'>
    )>, coordinates?: Maybe<(
      { __typename?: 'Coordinates' }
      & Pick<Coordinates, 'latitude' | 'longitude' | 'preciseLocation'>
    )>, openingHours?: Maybe<Array<Maybe<(
      { __typename?: 'openingHour' }
      & Pick<OpeningHour, 'name' | 'open' | 'openMorning' | 'closeMorning' | 'openAfternoon' | 'closeAfternoon'>
    )>>> }
  )> }
);

export type CellarFrontPopinQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  cellarId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
}>;


export type CellarFrontPopinQuery = (
  { __typename?: 'Query' }
  & { CellarFrontPopin?: Maybe<(
    { __typename?: 'CellarFrontPopin' }
    & Pick<CellarFrontPopin, 'name' | 'surfaceArea' | 'airConditioning' | 'temperature' | 'description' | 'vatThermoregulation' | 'vatCirculation' | 'isAgeing' | 'isVathouse' | 'isConditioning' | 'isStorage' | 'storageCapacity' | 'storageCapacityUnit' | 'conditioningActivities' | 'numberOfConditioningChains' | 'conditioningCapacity'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'complementaryAddress' | 'commune' | 'postalCode' | 'communeINSEEcode'>
    )>, images?: Maybe<Array<Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'twicpicPath' | 'twicpicURL'>
    )>>>, vatContainers?: Maybe<Array<Maybe<(
      { __typename?: 'VatContainer' }
      & Pick<VatContainer, 'kind' | 'material' | 'form' | 'capacity' | 'quantity'>
    )>>>, ageingContainers?: Maybe<Array<Maybe<(
      { __typename?: 'VatContainer' }
      & Pick<VatContainer, 'kind' | 'material' | 'form' | 'capacity' | 'quantity'>
    )>>> }
  )> }
);

export type VineyardFrontPopinQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
}>;


export type VineyardFrontPopinQuery = (
  { __typename?: 'Query' }
  & { VineyardFrontPopin?: Maybe<(
    { __typename?: 'VineyardFrontPopin' }
    & Pick<VineyardFrontPopin, 'vineSurfaceArea' | 'averageVineAge' | 'averagePlantationDensity' | 'totalParcelCount' | 'soil' | 'subsoil' | 'soilDescription' | 'rootstocks' | 'insectWorkDescription' | 'grassGrowing' | 'fertilizers' | 'worksUnderTheRow' | 'soilWorkDescription' | 'pruningMethod' | 'greenProcedures' | 'vineWorkDescription' | 'sexualConfusion' | 'description' | 'surfaceImportCVI' | 'allHiddenSurfaces' | 'grassGrowingIsTotal' | 'grassGrowingIsTemporary' | 'otherCultures'>
    & { surfaces?: Maybe<Array<Maybe<(
      { __typename?: 'SurfaceSimple' }
      & Pick<SurfaceSimple, 'appellation' | 'superficie' | 'couleur' | 'ageMoyenDesVignes' | 'densiteDePlantation' | 'vinesCount' | 'rootstocks' | 'parcelCount' | 'localities'>
      & { communes?: Maybe<Array<Maybe<(
        { __typename?: 'communeDistribution' }
        & Pick<CommuneDistribution, 'commune' | 'superficie'>
        & { lieuDits?: Maybe<Array<Maybe<(
          { __typename?: 'LieuDitDistribution' }
          & Pick<LieuDitDistribution, 'lieuDit' | 'superficie'>
        )>>> }
      )>>>, encepagements?: Maybe<Array<Maybe<(
        { __typename?: 'encepagementDistribution' }
        & Pick<EncepagementDistribution, 'cepage' | 'superficie'>
      )>>> }
    )>>>, hiddenSurfaces?: Maybe<Array<Maybe<(
      { __typename?: 'HiddenSurface' }
      & Pick<HiddenSurface, 'ageMoyenDesVignes' | 'densiteDePlantation' | 'communes' | 'encepagements' | 'vinesCount' | 'rootstocks' | 'parcelCount'>
    )>>> }
  )> }
);

export type FrontWinePresentationQueryVariables = Exact<{
  wineShortId: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
}>;


export type FrontWinePresentationQuery = (
  { __typename?: 'Query' }
  & { FrontWinePresentation?: Maybe<(
    { __typename?: 'FrontWinePresentation' }
    & Pick<FrontWinePresentation, 'tabs' | 'name' | 'rank' | 'type' | 'soil' | 'label' | 'color' | 'rankFr' | 'wineId' | 'nonOGM' | 'subsoil' | 'sweetness' | 'noSulphites' | 'description' | 'wineEntityId' | 'harvestMethod' | 'classification' | 'wineEntityName' | 'subscriptionName' | 'commercialisation' | 'truncateDescription' | 'backgroundBottleColor' | 'averageAge' | 'totalSurfaceArea' | 'averageDensity'>
    & { wineAssembly?: Maybe<Array<Maybe<(
      { __typename?: 'SimpleGrapeVarietyPart' }
      & Pick<SimpleGrapeVarietyPart, 'grapeVarietyName' | 'percentage'>
    )>>>, vintageList?: Maybe<Array<Maybe<(
      { __typename?: 'SimpleVintage' }
      & Pick<SimpleVintage, 'year' | 'vintageId' | 'cuvee'>
      & { scores?: Maybe<Array<Maybe<(
        { __typename?: 'FrontScore' }
        & Pick<FrontScore, '_id' | 'score' | 'scoreMax' | 'scoreUrl' | 'tastingDate' | 'criticName' | 'companyName' | 'tastingComment'>
      )>>> }
    )>>>, bottleImage?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'twicpicURL'>
    )>, backgroundBottleImage?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'twicpicURL'>
    )>, backgroundImage?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'twicpicURL'>
    )>, scores?: Maybe<Array<Maybe<(
      { __typename?: 'FrontScore' }
      & Pick<FrontScore, '_id' | 'score' | 'scoreMax' | 'scoreUrl' | 'tastingDate' | 'criticName' | 'companyName' | 'tastingComment' | 'vintageYear'>
    )>>> }
  )> }
);

export type FrontVintageQueryVariables = Exact<{
  wineShortId: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type FrontVintageQuery = (
  { __typename?: 'Query' }
  & { FrontVintage?: Maybe<(
    { __typename?: 'FrontVintage' }
    & Pick<FrontVintage, 'id' | 'wineId' | 'subscriptionName' | 'noSulphites' | 'name' | 'year' | 'cuvee' | 'rank' | 'rankFr' | 'wineEntityName' | 'wineEntityId' | 'label' | 'color' | 'sweetness' | 'type' | 'ftId' | 'tabs' | 'bySeasonClimatology' | 'backgroundBottleColor' | 'alcoholicStrength' | 'residualSugar' | 'totalAcidity' | 'vintageDescription' | 'truncatedVintageDescription' | 'allergens' | 'commercialisation' | 'nonOGM' | 'productPageUrl' | 'showDetailedHarvest' | 'classification'>
    & { backgroundImage?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'twicpicURL'>
    )>, wineAssembly?: Maybe<Array<Maybe<(
      { __typename?: 'SimpleGrapeVarietyPart' }
      & Pick<SimpleGrapeVarietyPart, 'grapeVarietyName' | 'percentage'>
    )>>>, climatology?: Maybe<Array<Maybe<(
      { __typename?: 'FrontClimatology' }
      & Pick<FrontClimatology, 'season' | 'text'>
      & { logo?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data' | 'twicpicURL'>
      )> }
    )>>>, bottleImage?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'twicpicURL'>
    )>, backgroundBottleImage?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'twicpicURL'>
    )>, bottleLabel?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'twicpicURL'>
    )>, vintageList?: Maybe<Array<Maybe<(
      { __typename?: 'SimpleVintage' }
      & Pick<SimpleVintage, 'year' | 'vintageId' | 'cuvee'>
    )>>>, certificationList?: Maybe<Array<Maybe<(
      { __typename?: 'SimpleLabel' }
      & Pick<SimpleLabel, 'certificationId' | 'object' | 'certificationBody'>
      & { logo?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data' | 'twicpicURL'>
      )> }
    )>>>, rewards?: Maybe<Array<Maybe<(
      { __typename?: 'FrontReward' }
      & Pick<FrontReward, 'rewardId' | 'year' | 'rewardName' | 'competitionName' | 'url'>
      & { image?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data' | 'twicpicURL'>
      )> }
    )>>>, scores?: Maybe<Array<Maybe<(
      { __typename?: 'FrontScore' }
      & Pick<FrontScore, 'score' | 'scoreMax' | 'scoreUrl' | 'tastingDate' | 'criticName' | 'companyName' | 'tastingComment'>
    )>>> }
  )> }
);

export type FrontVintageViticultureQueryVariables = Exact<{
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
}>;


export type FrontVintageViticultureQuery = (
  { __typename?: 'Query' }
  & { FrontVintageViticulture?: Maybe<(
    { __typename?: 'FrontVintageViticulture' }
    & Pick<FrontVintageViticulture, 'soil' | 'subsoil' | 'viticultureDetails' | 'showDefaultViticultureDetails'>
    & { greenOperations?: Maybe<Array<Maybe<(
      { __typename?: 'GreenOperation' }
      & Pick<GreenOperation, 'operation'>
      & { startAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )>, endAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )> }
    )>>>, simplePhenologicalStages?: Maybe<Array<Maybe<(
      { __typename?: 'SimplePhenologicalStage' }
      & Pick<SimplePhenologicalStage, 'stage'>
      & { image?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )>, startAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )>, endAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )> }
    )>>>, detailedPhenologicalStages?: Maybe<Array<Maybe<(
      { __typename?: 'DetailedPhenologicalStage' }
      & Pick<DetailedPhenologicalStage, 'stage' | 'grapeVariety'>
      & { image?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )>, startAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )>, endAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )> }
    )>>> }
  )> }
);

export type FrontVintageLabelsPopinQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
}>;


export type FrontVintageLabelsPopinQuery = (
  { __typename?: 'Query' }
  & { FrontVintageLabelsPopin?: Maybe<Array<Maybe<(
    { __typename?: 'FrontLabel' }
    & Pick<FrontLabel, 'certificationId' | 'object' | 'expiryDate' | 'certificationBody' | 'isInConversion'>
    & { logo?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )> }
  )>>> }
);

export type FrontVintageWineMakingQueryVariables = Exact<{
  vintageId?: Maybe<Scalars['ID']>;
  wineId?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type FrontVintageWineMakingQuery = (
  { __typename?: 'Query' }
  & { FrontVintageWineMaking?: Maybe<(
    { __typename?: 'FrontVintageWineMaking' }
    & Pick<FrontVintageWineMaking, 'vinificationPlace' | 'vinificationDetails' | 'vinificationCoopName' | 'parcelBasedVinification' | 'sortingMethod' | 'destemming' | 'destemmingFr' | 'treading' | 'treadingFr' | 'vatting' | 'extractionMode' | 'yeasting' | 'alcoholicFermentationDuration' | 'alcoholicFermentationDurationUnit' | 'alcoholicFermentationTemperature' | 'malolacticFermentation' | 'malolacticBacteria' | 'malolacticFermentationDuration' | 'malolacticFermentationDurationUnit' | 'malolacticFermentationTemperature' | 'pressType'>
    & { consultingEnologist?: Maybe<(
      { __typename?: 'PersonOption' }
      & Pick<PersonOption, 'type' | 'title' | 'functions'>
      & { image?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )> }
    )>, macerations?: Maybe<Array<Maybe<(
      { __typename?: 'Maceration' }
      & Pick<Maceration, 'type' | 'duration' | 'durationUnit' | 'temperature'>
    )>>> }
  )> }
);

export type FrontVintageAgeingQueryVariables = Exact<{
  vintageId?: Maybe<Scalars['ID']>;
  wineId?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type FrontVintageAgeingQuery = (
  { __typename?: 'Query' }
  & { FrontVintageAgeing?: Maybe<(
    { __typename?: 'FrontVintageAgeing' }
    & Pick<FrontVintageAgeing, 'barrelMaturation' | 'barrelMaturationDurationValue' | 'barrelMaturationDurationUnit' | 'newBarrelPercentage' | 'toasting' | 'frenchOak' | 'lees' | 'batonnage' | 'description'>
    & { barrelMakers?: Maybe<Array<Maybe<(
      { __typename?: 'barrelMakerPart' }
      & Pick<BarrelMakerPart, 'barrelMaker' | 'percentage'>
    )>>>, otherMaturations?: Maybe<Array<Maybe<(
      { __typename?: 'otherMaturation' }
      & Pick<OtherMaturation, 'containerType' | 'durationValue' | 'durationUnit' | 'material'>
    )>>> }
  )> }
);

export type FrontVintageProductionQueryVariables = Exact<{
  vintageId?: Maybe<Scalars['ID']>;
  wineId?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type FrontVintageProductionQuery = (
  { __typename?: 'Query' }
  & { FrontVintageProduction?: Maybe<(
    { __typename?: 'FrontVintageProduction' }
    & Pick<FrontVintageProduction, 'corkingMode' | 'finingTypes' | 'filtrationTypes' | 'bottlingDate' | 'estateBottling' | 'bottlesVolume' | 'yield' | 'production' | 'waxedBottle' | 'bottlerCommune' | 'bottlerCommuneINSEEcode' | 'bottlerPostalCode'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'complementaryAddress' | 'postalCode' | 'commune' | 'communeINSEEcode'>
    )>, bottler?: Maybe<(
      { __typename?: 'BottlerOption' }
      & Pick<BottlerOption, 'title' | 'type'>
    )> }
  )> }
);

export type FrontVintageNutritionQueryVariables = Exact<{
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
}>;


export type FrontVintageNutritionQuery = (
  { __typename?: 'Query' }
  & { FrontVintageNutrition?: Maybe<(
    { __typename?: 'FrontVintageNutrition' }
    & Pick<FrontVintageNutrition, 'fats' | 'saturatedFattyAcids' | 'glucides' | 'sugars' | 'protein' | 'salt' | 'energy' | 'energyType' | 'alcoholicStrength' | 'residualSugar' | 'totalAcidity' | 'auto'>
    & { nutritionalAnalysis?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'data' | 'fileName' | 'bucketName'>
    )>>>, qrnutri?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'data' | 'fileName' | 'bucketName'>
    )>, ingredients?: Maybe<Array<Maybe<(
      { __typename?: 'ingredientPart' }
      & Pick<IngredientPart, 'ingredient'>
    )>>> }
  )> }
);

export type FrontVintageQrcodeQueryVariables = Exact<{
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
}>;


export type FrontVintageQrcodeQuery = (
  { __typename?: 'Query' }
  & { FrontVintageQrcode?: Maybe<(
    { __typename?: 'FrontVintageQrcode' }
    & Pick<FrontVintageQrcode, 'wineId' | 'wineShortId' | 'wineEntityShortId' | 'name' | 'year' | 'cuvee' | 'wineEntityName' | 'wineEntityId' | 'label' | 'fats' | 'saturatedFattyAcids' | 'glucides' | 'sugars' | 'protein' | 'salt' | 'energy' | 'energyType' | 'alcoholicStrength' | 'residualSugar' | 'totalAcidity'>
    & { nutritionalAnalysis?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'data' | 'fileName' | 'bucketName'>
    )>>>, qrnutri?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'data' | 'fileName' | 'bucketName'>
    )>, ingredients?: Maybe<Array<Maybe<(
      { __typename?: 'ingredientPart' }
      & Pick<IngredientPart, 'ingredient' | 'isAllergen'>
    )>>> }
  )> }
);

export type FrontQrcodeRedirectQueryVariables = Exact<{
  vintageId: Scalars['String'];
}>;


export type FrontQrcodeRedirectQuery = (
  { __typename?: 'Query' }
  & { FrontQrcodeRedirect?: Maybe<(
    { __typename?: 'FrontQrcodeRedirect' }
    & Pick<FrontQrcodeRedirect, 'wineId' | 'vintageId' | 'url' | 'redirect'>
  )> }
);

export type FrontVintageMarketingQueryVariables = Exact<{
  vintageId?: Maybe<Scalars['ID']>;
  wineId?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type FrontVintageMarketingQuery = (
  { __typename?: 'Query' }
  & { FrontVintageMarketing?: Maybe<(
    { __typename?: 'FrontVintageMarketing' }
    & Pick<FrontVintageMarketing, 'exportPercentage' | 'directPercentage' | 'indirectPercentage' | 'exportCountries'>
    & { packagings?: Maybe<Array<Maybe<(
      { __typename?: 'FrontPackaging' }
      & Pick<FrontPackaging, 'conditionningMode' | 'centilisation' | 'units' | 'price' | 'gtin' | 'url'>
      & { icon?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )> }
    )>>> }
  )> }
);

export type FrontVintageAdviceQueryVariables = Exact<{
  vintageId?: Maybe<Scalars['ID']>;
  wineId?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type FrontVintageAdviceQuery = (
  { __typename?: 'Query' }
  & { FrontVintageAdvice?: Maybe<(
    { __typename?: 'FrontVintageAdvice' }
    & Pick<FrontVintageAdvice, 'wineColor' | 'wineSweetness' | 'wineLabel' | 'videoUrl' | 'serviceTemperature' | 'apogee' | 'ageing' | 'treatmentBeforeService' | 'decantingDuration' | 'appearanceColor' | 'appearanceIntensity' | 'appearanceBrilliance' | 'appearanceLimpidity' | 'appearanceTears' | 'noseAromas' | 'noseIntensity' | 'palateAttack' | 'palateIntensity' | 'palateLength' | 'palateAcidity' | 'palateTannins' | 'aromaValues' | 'description' | 'aromaAnimal' | 'aromaBalsamic' | 'aromaConfectionery' | 'aromaEmpyreumatic' | 'aromaFermented' | 'aromaFloral' | 'aromaFruity' | 'aromaMineral' | 'aromaOaky' | 'aromaSpicy' | 'aromaVegetal'>
    & { foodPairingGroups?: Maybe<Array<Maybe<(
      { __typename?: 'FoodPairingGroup' }
      & Pick<FoodPairingGroup, 'foods' | 'category'>
      & { icon?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )> }
    )>>> }
  )> }
);

export type FrontVintageDetailedHarvestQueryVariables = Exact<{
  vintageId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
}>;


export type FrontVintageDetailedHarvestQuery = (
  { __typename?: 'Query' }
  & { FrontVintageDetailedHarvest?: Maybe<Array<Maybe<(
    { __typename?: 'FrontDetailedHarvest' }
    & Pick<FrontDetailedHarvest, 'method' | 'methodFr' | 'grapeVariety'>
    & { startAt?: Maybe<(
      { __typename?: 'VagueDate' }
      & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
    )>, endAt?: Maybe<(
      { __typename?: 'VagueDate' }
      & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
    )>, icon?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )> }
  )>>> }
);

export type GetTranslationOptionsQueryVariables = Exact<{
  wineEntityId?: Maybe<Scalars['ID']>;
}>;


export type GetTranslationOptionsQuery = (
  { __typename?: 'Query' }
  & { GetTranslationOptions?: Maybe<Array<Maybe<(
    { __typename?: 'TranslationOption' }
    & Pick<TranslationOption, 'label' | 'value' | 'status' | 'supportedTranslationModes' | 'chosenTranslationMode' | 'discountFree' | 'price' | 'lastTranslationRequestCreatedAt'>
    & { upToDate?: Maybe<Array<Maybe<(
      { __typename?: 'TranslationComparison' }
      & Pick<TranslationComparison, 'id' | 'subId' | 'price' | 'label' | 'accessor' | 'oldValue' | 'newValue' | 'wordCount' | 'modelName' | 'additionalInfo' | 'oldValueTranslated'>
    )>>>, outdated?: Maybe<Array<Maybe<(
      { __typename?: 'TranslationComparison' }
      & Pick<TranslationComparison, 'id' | 'subId' | 'price' | 'label' | 'accessor' | 'oldValue' | 'newValue' | 'wordCount' | 'modelName' | 'additionalInfo' | 'oldValueTranslated'>
    )>>>, notTranslated?: Maybe<Array<Maybe<(
      { __typename?: 'TranslationComparison' }
      & Pick<TranslationComparison, 'id' | 'subId' | 'price' | 'label' | 'accessor' | 'oldValue' | 'newValue' | 'wordCount' | 'modelName' | 'additionalInfo' | 'oldValueTranslated'>
    )>>> }
  )>>> }
);

export type ActivityFrontQueryVariables = Exact<{
  activityShortId?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type ActivityFrontQuery = (
  { __typename?: 'Query' }
  & { ActivityFront?: Maybe<(
    { __typename?: 'FullActivityFront' }
    & Pick<FullActivityFront, 'id' | 'wineEntityShortId' | 'activityShortId' | 'estateName' | 'isOrphan' | 'atWineEntity' | 'private' | 'wineEntityId' | 'availableOptions' | 'equipmentProvided' | 'priceOnEstimation' | 'paymentOptionsAccepted' | 'sanitaryMeasures' | 'minRentTime' | 'minRentTimeUnit' | 'maxRentTime' | 'maxRentTimeUnit' | 'accomodationPossible' | 'bedCount' | 'free' | 'name' | 'description' | 'groupSize' | 'accessibleTo' | 'startingAge' | 'tags' | 'duration' | 'durationUnit' | 'reservationNeeded' | 'contactPhoneNumber' | 'contactEmail' | 'parkingBus' | 'numberWineTasted'>
    & { bgImage?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'twicpicURL'>
    )>, socialMedias?: Maybe<Array<Maybe<(
      { __typename?: 'SocialMediaFront' }
      & Pick<SocialMediaFront, 'platform' | 'name' | 'url'>
      & { icon?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )> }
    )>>>, receptionAreas?: Maybe<Array<Maybe<(
      { __typename?: 'ReceptionArea' }
      & Pick<ReceptionArea, 'name' | 'surfaceArea' | 'surfaceAreaUnit' | 'capacity' | 'type'>
    )>>>, address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'complementaryAddress' | 'postalCode' | 'commune' | 'communeINSEEcode'>
    )>, paymentDetails?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, accomodationDetails?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, types?: Maybe<Array<Maybe<(
      { __typename?: 'ActivityType' }
      & Pick<ActivityType, 'typeFr' | 'bgColor' | 'textColor' | 'type'>
    )>>>, languages?: Maybe<Array<Maybe<(
      { __typename?: 'Language' }
      & Pick<Language, 'language'>
      & { icon?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data' | 'twicpicURL'>
      )> }
    )>>>, images?: Maybe<Array<Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'fileName' | 'twicpicPath' | 'twicpicURL'>
    )>>>, activePeriods?: Maybe<Array<Maybe<(
      { __typename?: 'ActivePeriod' }
      & { startAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )>, endAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )> }
    )>>>, priceDetails?: Maybe<Array<Maybe<(
      { __typename?: 'PriceDetail' }
      & Pick<PriceDetail, 'kind' | 'price'>
    )>>>, contactPerson?: Maybe<(
      { __typename?: 'PersonOption' }
      & Pick<PersonOption, 'title' | 'type' | 'functions'>
    )> }
  )> }
);

export type ActivityTabScreenQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  activityId: Scalars['ID'];
}>;


export type ActivityTabScreenQuery = (
  { __typename?: 'Query' }
  & { ActivityTabScreen?: Maybe<(
    { __typename?: 'ActivityTabScreen' }
    & Pick<ActivityTabScreen, 'atWineEntity' | 'private' | 'availableOptions' | 'equipmentProvided' | 'priceOnEstimation' | 'paymentOptionsAccepted' | 'sanitaryMeasures' | 'minRentTime' | 'minRentTimeUnit' | 'maxRentTime' | 'maxRentTimeUnit' | 'accomodationPossible' | 'bedCount' | 'free' | 'name' | 'types' | 'languages' | 'groupSize' | 'accessibleTo' | 'startingAge' | 'tags' | 'isVagueActivePeriods' | 'duration' | 'durationUnit' | 'reservationNeeded' | 'contactPhoneNumber' | 'contactEmail' | 'parkingBus' | 'numberWineTasted'>
    & { receptionAreas?: Maybe<Array<Maybe<(
      { __typename?: 'ReceptionArea' }
      & Pick<ReceptionArea, 'name' | 'surfaceArea' | 'surfaceAreaUnit' | 'capacity' | 'type'>
    )>>>, address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'complementaryAddress' | 'postalCode' | 'commune' | 'communeINSEEcode'>
    )>, wineEntityAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'complementaryAddress' | 'postalCode' | 'commune' | 'communeINSEEcode'>
    )>, paymentDetails?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, accomodationDetails?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, description?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, images?: Maybe<Array<Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'fileName'>
    )>>>, activePeriods?: Maybe<Array<Maybe<(
      { __typename?: 'ActivePeriod' }
      & { startAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )>, endAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )> }
    )>>>, vagueActivePeriods?: Maybe<Array<Maybe<(
      { __typename?: 'ActivePeriod' }
      & { startAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )>, endAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )> }
    )>>>, priceDetails?: Maybe<Array<Maybe<(
      { __typename?: 'PriceDetail' }
      & Pick<PriceDetail, 'kind' | 'price'>
    )>>>, contactPerson?: Maybe<(
      { __typename?: 'PersonOption' }
      & Pick<PersonOption, 'title' | 'type' | 'functions'>
    )>, contactOptions?: Maybe<Array<Maybe<(
      { __typename?: 'PersonOption' }
      & Pick<PersonOption, 'title' | 'type' | 'functions' | 'personId' | 'email' | 'phoneNumber'>
    )>>> }
  )> }
);

export type ContactScreenQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  personId?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
}>;


export type ContactScreenQuery = (
  { __typename?: 'Query' }
  & { ContactScreen?: Maybe<(
    { __typename?: 'ContactScreen' }
    & Pick<ContactScreen, 'contactEmail' | 'contactPhoneNumber'>
  )> }
);

export type AllowedAppellationLabelsQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type AllowedAppellationLabelsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AllowedAppellationLabels'>
);

export type AllFoodPairingsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllFoodPairingsQuery = (
  { __typename?: 'Query' }
  & { AllFoodPairings?: Maybe<Array<Maybe<(
    { __typename?: 'FoodPairing' }
    & Pick<FoodPairing, 'food' | 'category'>
  )>>> }
);

export type GetExternalServiceRequestsQueryVariables = Exact<{
  wineEntityId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
}>;


export type GetExternalServiceRequestsQuery = (
  { __typename?: 'Query' }
  & { GetExternalServiceRequests?: Maybe<(
    { __typename?: 'ServiceInformations' }
    & Pick<ServiceInformations, 'hasTastingRequest'>
    & { services?: Maybe<Array<Maybe<(
      { __typename?: 'ExternalServiceType' }
      & Pick<ExternalServiceType, '_id' | 'createdAt' | 'updatedAt' | 'status' | 'userId' | 'wineEntityId' | 'type' | 'interview' | 'numberOfPackshots' | 'photoReport' | 'standaloneStatus' | 'webAddress'>
    )>>> }
  )> }
);

export type VintageRewardScreenQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  vintageId: Scalars['ID'];
}>;


export type VintageRewardScreenQuery = (
  { __typename?: 'Query' }
  & { VintageRewardScreen?: Maybe<(
    { __typename?: 'RewardScreen' }
    & { rewards?: Maybe<Array<Maybe<(
      { __typename?: 'SingleReward' }
      & Pick<SingleReward, 'competitionName' | 'rewardName' | 'competitionYear' | 'hidden' | 'rewardId' | 'sortIndex'>
      & { logo?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data' | 'twicpicURL'>
      )> }
    )>>>, scores?: Maybe<Array<Maybe<(
      { __typename?: 'SingleFrontScore' }
      & Pick<SingleFrontScore, 'id' | 'score' | 'hidden' | 'isFeret' | 'scoreMax' | 'scoreUrl' | 'sortIndex' | 'criticName' | 'permission' | 'companyName' | 'tastingDate' | 'forceVisibility' | 'tastingRequestId'>
      & { tastingComment?: Maybe<(
        { __typename?: 'LngString' }
        & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
      )> }
    )>>> }
  )> }
);

export type FrontWineListQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
  omitWineId?: Maybe<Scalars['ID']>;
}>;


export type FrontWineListQuery = (
  { __typename?: 'Query' }
  & { FrontWineList?: Maybe<(
    { __typename?: 'FrontWineList' }
    & { firstWines?: Maybe<Array<Maybe<(
      { __typename?: 'WineFront' }
      & Pick<WineFront, 'scored' | 'wineId' | 'shortWineId' | 'rewarded' | 'name' | 'rank' | 'rankFr' | 'color' | 'colorFr' | 'type' | 'sweetness' | 'lastVintageId' | 'appellation' | 'shouldMentionSweetness' | 'shouldMentionColor'>
      & { classifications?: Maybe<Array<Maybe<(
        { __typename?: 'Classification' }
        & Pick<Classification, 'name' | 'url'>
      )>>>, bottleImage?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'twicpicURL' | 'data'>
      )> }
    )>>>, secondWines?: Maybe<Array<Maybe<(
      { __typename?: 'WineFront' }
      & Pick<WineFront, 'scored' | 'wineId' | 'shortWineId' | 'rewarded' | 'name' | 'rank' | 'rankFr' | 'color' | 'colorFr' | 'type' | 'sweetness' | 'lastVintageId' | 'appellation' | 'shouldMentionSweetness' | 'shouldMentionColor'>
      & { classifications?: Maybe<Array<Maybe<(
        { __typename?: 'Classification' }
        & Pick<Classification, 'name' | 'url'>
      )>>>, bottleImage?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'twicpicURL' | 'data'>
      )> }
    )>>>, otherWines?: Maybe<Array<Maybe<(
      { __typename?: 'WineFront' }
      & Pick<WineFront, 'wineId' | 'shortWineId' | 'scored' | 'rewarded' | 'name' | 'rank' | 'rankFr' | 'color' | 'colorFr' | 'type' | 'sweetness' | 'lastVintageId' | 'appellation' | 'shouldMentionSweetness' | 'shouldMentionColor'>
      & { classifications?: Maybe<Array<Maybe<(
        { __typename?: 'Classification' }
        & Pick<Classification, 'name' | 'url'>
      )>>>, bottleImage?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'twicpicURL' | 'data'>
      )> }
    )>>> }
  )> }
);

export type ListActivitiesQueryVariables = Exact<{
  wineEntityId?: Maybe<Scalars['ID']>;
  omit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  locale?: Maybe<Scalars['String']>;
}>;


export type ListActivitiesQuery = (
  { __typename?: 'Query' }
  & { ListActivities?: Maybe<Array<Maybe<(
    { __typename?: 'ActivityFront' }
    & Pick<ActivityFront, 'activityShortId' | 'subscriptionName' | 'priceOnEstimation' | 'free' | 'activityId' | 'name' | 'description' | 'groupSize' | 'accessibleTo' | 'startingAge' | 'tags' | 'duration' | 'durationUnit' | 'reservationNeeded' | 'contactPhoneNumber' | 'contactEmail' | 'minRentTime' | 'minRentTimeUnit' | 'maxRentTime' | 'maxRentTimeUnit'>
    & { types?: Maybe<Array<Maybe<(
      { __typename?: 'ActivityType' }
      & Pick<ActivityType, 'bgColor' | 'textColor' | 'type' | 'typeFr'>
    )>>>, languages?: Maybe<Array<Maybe<(
      { __typename?: 'Language' }
      & Pick<Language, 'language'>
      & { icon?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data' | 'twicpicURL'>
      )> }
    )>>>, image?: Maybe<Array<Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'twicpicURL' | 'twicpicPath'>
    )>>>, activePeriods?: Maybe<Array<Maybe<(
      { __typename?: 'ActivePeriod' }
      & { startAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )>, endAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )> }
    )>>>, priceDetails?: Maybe<Array<Maybe<(
      { __typename?: 'PriceDetail' }
      & Pick<PriceDetail, 'kind' | 'price' | 'currency'>
    )>>>, contactPerson?: Maybe<(
      { __typename?: 'PersonOption' }
      & Pick<PersonOption, 'title' | 'type' | 'functions' | 'phoneNumber' | 'email' | 'personId'>
      & { image?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )> }
    )> }
  )>>> }
);

export type MainTitleQueryVariables = Exact<{
  wineEntityId?: Maybe<Scalars['ID']>;
}>;


export type MainTitleQuery = (
  { __typename?: 'Query' }
  & { MainTitle?: Maybe<(
    { __typename?: 'MainTitle' }
    & Pick<MainTitle, 'name' | 'mainAppellation' | 'estateSurfaceArea'>
    & { classifications?: Maybe<Array<Maybe<(
      { __typename?: 'Classification' }
      & Pick<Classification, 'name'>
    )>>> }
  )> }
);

export type GetCommuneFrontQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type GetCommuneFrontQuery = (
  { __typename?: 'Query' }
  & { GetCommuneFront?: Maybe<(
    { __typename?: 'CommuneFront' }
    & Pick<CommuneFront, 'communeId' | 'fullName' | 'rootName' | 'nameAttribute' | 'surfaceArea' | 'geoSituation' | 'soilDescription' | 'INSEEcode' | 'postalCode' | 'regionName' | 'departmentNumber' | 'population' | 'latitude' | 'longitude' | 'altitudeMax' | 'altitudeMin' | 'gentileM' | 'gentileF' | 'redWines' | 'roseWines' | 'whiteWines'>
    & { mairieAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'communeINSEEcode' | 'postalCode' | 'commune'>
    )> }
  )> }
);

export type ContactCardQueryVariables = Exact<{
  wineEntityId?: Maybe<Scalars['ID']>;
}>;


export type ContactCardQuery = (
  { __typename?: 'Query' }
  & { ContactCard?: Maybe<(
    { __typename?: 'ContactCard' }
    & Pick<ContactCard, 'estateName' | 'displayOpeningHours' | 'fax' | 'telephoneNumber'>
    & { openingHours?: Maybe<Array<Maybe<(
      { __typename?: 'openingHour' }
      & Pick<OpeningHour, 'name' | 'open' | 'openMorning' | 'openAfternoon' | 'closeMorning' | 'closeAfternoon' | 'nonStop' | 'val'>
    )>>>, logo?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'twicpicURL' | 'twicpicPath'>
    )>, coordinates?: Maybe<(
      { __typename?: 'Coordinates' }
      & Pick<Coordinates, 'latitude' | 'longitude' | 'preciseLocation'>
    )>, address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'complementaryAddress' | 'commune' | 'postalCode' | 'communeINSEEcode'>
    )> }
  )> }
);

export type GetRandomWineEntitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRandomWineEntitiesQuery = (
  { __typename?: 'Query' }
  & { GetRandomWineEntities?: Maybe<Array<Maybe<(
    { __typename?: 'WineEntityBrief' }
    & Pick<WineEntityBrief, 'wineEntityShortId' | 'name' | 'wineEntityId' | 'appellation' | 'hasRedWines' | 'hasRoseWines' | 'hasWhiteWines'>
    & { image?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'twicpicURL'>
    )> }
  )>>> }
);

export type GetRandomWineEntitiesFromCommuneQueryVariables = Exact<{
  communeINSEEcode: Scalars['String'];
}>;


export type GetRandomWineEntitiesFromCommuneQuery = (
  { __typename?: 'Query' }
  & { GetRandomWineEntitiesFromCommune?: Maybe<Array<Maybe<(
    { __typename?: 'WineEntityBrief' }
    & Pick<WineEntityBrief, 'wineEntityShortId' | 'name' | 'wineEntityId' | 'appellation'>
    & { image?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )> }
  )>>> }
);

export type AboutVineyardQueryVariables = Exact<{
  wineEntityId?: Maybe<Scalars['ID']>;
}>;


export type AboutVineyardQuery = (
  { __typename?: 'Query' }
  & { AboutVineyard?: Maybe<(
    { __typename?: 'AboutVineyard' }
    & Pick<AboutVineyard, 'description' | 'soilDescription' | 'otherCultures' | 'totalSurfaceArea' | 'vineSurfaceArea'>
  )> }
);

export type AboutContactDetailsQueryVariables = Exact<{
  wineEntityId?: Maybe<Scalars['ID']>;
}>;


export type AboutContactDetailsQuery = (
  { __typename?: 'Query' }
  & { AboutContactDetails?: Maybe<(
    { __typename?: 'AboutContactDetails' }
    & Pick<AboutContactDetails, 'phone' | 'fax' | 'website'>
  )> }
);

export type AboutCellarsQueryVariables = Exact<{
  wineEntityId?: Maybe<Scalars['ID']>;
}>;


export type AboutCellarsQuery = (
  { __typename?: 'Query' }
  & { AboutCellars?: Maybe<(
    { __typename?: 'AboutCellars' }
    & { cellars?: Maybe<Array<Maybe<(
      { __typename?: 'CellarChecking' }
      & Pick<CellarChecking, 'name' | 'isVathouse' | 'isConditioning' | 'isStorage' | 'isAgeing' | 'surfaceArea'>
      & { wineEntityAddress?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'complementaryAddress' | 'postalCode' | 'communeINSEEcode' | 'commune'>
      )> }
    )>>> }
  )> }
);

export type GetCommunesOfAppellationQueryVariables = Exact<{
  appellationLabel: Scalars['String'];
  filterByRegion: Scalars['String'];
}>;


export type GetCommunesOfAppellationQuery = (
  { __typename?: 'Query' }
  & { GetCommunesOfAppellation?: Maybe<Array<Maybe<(
    { __typename?: 'CommuneSimple' }
    & Pick<CommuneSimple, 'name' | 'INSEEcode' | 'postalCode'>
  )>>> }
);

export type GetOrphanWineEntitiesQueryVariables = Exact<{
  searchString?: Maybe<Scalars['String']>;
}>;


export type GetOrphanWineEntitiesQuery = (
  { __typename?: 'Query' }
  & { GetOrphanWineEntities?: Maybe<Array<Maybe<(
    { __typename?: 'OrphanWineEntity' }
    & Pick<OrphanWineEntity, 'wineEntityId' | 'estateName' | 'mainAppellation' | 'mainCommune' | 'communeINSEEcode'>
    & { businessAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'postalCode' | 'commune'>
    )> }
  )>>> }
);

export type GetBesvInfoQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetBesvInfoQuery = (
  { __typename?: 'Query' }
  & { GetBesvInfo?: Maybe<Array<Maybe<(
    { __typename?: 'BesvInfo' }
    & Pick<BesvInfo, 'edition' | 'page' | 'year'>
    & { scans?: Maybe<Array<Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )>>> }
  )>>> }
);

export type GetOwnerOperatorQueryVariables = Exact<{
  wineEntityId?: Maybe<Scalars['ID']>;
}>;


export type GetOwnerOperatorQuery = (
  { __typename?: 'Query' }
  & { GetOwnerOperator?: Maybe<(
    { __typename?: 'OwnerOperator' }
    & Pick<OwnerOperator, 'numMembers'>
    & { operators?: Maybe<Array<Maybe<(
      { __typename?: 'TeamMemberBoc' }
      & Pick<TeamMemberBoc, 'personId' | 'kind' | 'title' | 'firstName' | 'lastName' | 'organizationName' | 'organizationStatus' | 'visibility' | 'hidden' | 'dateOfBirth' | 'email' | 'telephone' | 'vip' | 'wasVip' | 'proFunctions' | 'displayName' | 'roleComplement'>
    )>>>, owners?: Maybe<Array<Maybe<(
      { __typename?: 'TeamMemberBoc' }
      & Pick<TeamMemberBoc, 'roleComplement' | 'personId' | 'kind' | 'title' | 'firstName' | 'lastName' | 'organizationName' | 'organizationStatus' | 'visibility' | 'hidden' | 'dateOfBirth' | 'email' | 'telephone' | 'vip' | 'wasVip' | 'proFunctions' | 'displayName'>
    )>>> }
  )> }
);

export type GetFtParametersQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetFtParametersQuery = (
  { __typename?: 'Query' }
  & { GetFtParameters?: Maybe<Array<Maybe<(
    { __typename?: 'BocFtParameters' }
    & Pick<BocFtParameters, 'ftId' | 'wineEntityId' | 'wineId' | 'vintageId' | 'name' | 'description' | 'wine' | 'vintage' | 'version' | 'status' | 'lastModification' | 'online' | 'hasDiffusion' | 'cuvee' | 'fullAppellation'>
    & { bottleImage?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )>, preview?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )>, languages?: Maybe<(
      { __typename?: 'LanguagesBool' }
      & Pick<LanguagesBool, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, configuration?: Maybe<(
      { __typename?: 'configurationType' }
      & Pick<ConfigurationType, 'pages' | 'components'>
    )> }
  )>>> }
);

export type GetFtParameterQueryVariables = Exact<{
  ftParametersId: Scalars['ID'];
}>;


export type GetFtParameterQuery = (
  { __typename?: 'Query' }
  & { GetFtParameter?: Maybe<(
    { __typename?: 'BocFtParameters' }
    & Pick<BocFtParameters, 'ftId' | 'wineEntityId' | 'wineId' | 'vintageId' | 'name' | 'description' | 'wine' | 'vintage' | 'version' | 'status' | 'lastModification' | 'online' | 'hasDiffusion' | 'cuvee' | 'fullAppellation' | 'wineEntityName'>
    & { bottleImage?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )>, preview?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )>, languages?: Maybe<(
      { __typename?: 'LanguagesBool' }
      & Pick<LanguagesBool, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )> }
  )> }
);

export type GetClassificationOrganismFrontQueryVariables = Exact<{
  name: Scalars['String'];
  locale: Scalars['String'];
}>;


export type GetClassificationOrganismFrontQuery = (
  { __typename?: 'Query' }
  & { GetClassificationOrganismFront?: Maybe<(
    { __typename?: 'ClassificationOrganismFront' }
    & Pick<ClassificationOrganismFront, '_id' | 'name' | 'lastClassification' | 'nextClassification' | 'organisation' | 'classificationLink' | 'history' | 'about'>
    & { classifiedWines?: Maybe<(
      { __typename?: 'WineType' }
      & Pick<WineType, 'name' | 'rank' | 'color' | 'type' | 'sweetness' | 'appellation'>
    )>, socialMedias?: Maybe<Array<Maybe<(
      { __typename?: 'SocialMediaFront' }
      & Pick<SocialMediaFront, 'platform' | 'url'>
    )>>>, organism?: Maybe<(
      { __typename?: 'PersonSimple' }
      & Pick<PersonSimple, 'telephone' | 'email'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'postalCode' | 'commune' | 'country'>
      )> }
    )>, president?: Maybe<(
      { __typename?: 'PersonSimple' }
      & Pick<PersonSimple, '_id' | 'title' | 'firstName' | 'lastName' | 'telephone' | 'email'>
    )>, director?: Maybe<(
      { __typename?: 'PersonSimple' }
      & Pick<PersonSimple, '_id' | 'title' | 'firstName' | 'lastName' | 'telephone' | 'email'>
    )>, logo?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'fileName' | 'data' | 'twicpicURL'>
    )> }
  )> }
);

export type GetClassificationOrganismListQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
}>;


export type GetClassificationOrganismListQuery = (
  { __typename?: 'Query' }
  & { GetClassificationOrganismList?: Maybe<Array<Maybe<(
    { __typename?: 'ClassificationOrganismItem' }
    & Pick<ClassificationOrganismItem, '_id' | 'name' | 'classifiedWinesCount'>
    & { logo?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'fileName' | 'data' | 'twicpicURL'>
    )> }
  )>>> }
);

export type GetOdgFrontQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type GetOdgFrontQuery = (
  { __typename?: 'Query' }
  & { GetOdgFront?: Maybe<Array<Maybe<(
    { __typename?: 'OdgFrontType' }
    & Pick<OdgFrontType, 'name' | 'description'>
    & { appellations?: Maybe<Array<Maybe<(
      { __typename?: 'AppellationType' }
      & Pick<AppellationType, 'label' | 'link'>
    )>>>, members?: Maybe<Array<Maybe<(
      { __typename?: 'MembersFrontType' }
      & Pick<MembersFrontType, 'title' | 'firstName' | 'lastName' | 'displayName' | 'email' | 'role' | 'telephone'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'postalCode' | 'commune' | 'country'>
      )> }
    )>>>, socialMedias?: Maybe<Array<Maybe<(
      { __typename?: 'SocialMediaFront' }
      & Pick<SocialMediaFront, 'platform' | 'url'>
      & { icon?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )> }
    )>>> }
  )>>> }
);

export type GetOdgShortQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOdgShortQuery = (
  { __typename?: 'Query' }
  & { GetOdg?: Maybe<Array<Maybe<(
    { __typename?: 'OdgType' }
    & Pick<OdgType, '_id' | 'name'>
  )>>> }
);

export type GetOdgSampleQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
}>;


export type GetOdgSampleQuery = (
  { __typename?: 'Query' }
  & { GetOdgSample?: Maybe<Array<Maybe<(
    { __typename?: 'OdgSampleType' }
    & Pick<OdgSampleType, 'name'>
  )>>> }
);

export type GetAuthorFrontQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type GetAuthorFrontQuery = (
  { __typename?: 'Query' }
  & { GetAuthorFront?: Maybe<(
    { __typename?: 'AuthorFrontType' }
    & Pick<AuthorFrontType, '_id' | 'degree' | 'professionalExperience' | 'expertise' | 'expertiseDetails' | 'title' | 'description'>
    & { personIdentity?: Maybe<(
      { __typename?: 'PersonSimple' }
      & Pick<PersonSimple, '_id' | 'title' | 'firstName' | 'lastName'>
    )>, photo?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'data' | 'twicpicURL'>
    )>, recentPublications?: Maybe<Array<Maybe<(
      { __typename?: 'PublicationFront' }
      & Pick<PublicationFront, '_id' | 'year' | 'publication'>
    )>>> }
  )> }
);

export type GetAuthorFrontListQueryVariables = Exact<{
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}>;


export type GetAuthorFrontListQuery = (
  { __typename?: 'Query' }
  & { GetAuthorFrontList?: Maybe<Array<Maybe<(
    { __typename?: 'AuthorFrontItemType' }
    & { personIdentity?: Maybe<(
      { __typename?: 'PersonSimple' }
      & Pick<PersonSimple, 'title' | 'firstName' | 'lastName'>
    )>, photo?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'data' | 'twicpicURL'>
    )> }
  )>>> }
);

export type GetFeretContactsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<SortType>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  wineEntityId: Scalars['ID'];
}>;


export type GetFeretContactsQuery = (
  { __typename?: 'Query' }
  & { GetFeretContacts?: Maybe<(
    { __typename?: 'ContactPagination' }
    & Pick<ContactPagination, 'count'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'FeretContactType' }
      & Pick<FeretContactType, '_id' | 'title' | 'email' | 'userId' | 'company' | 'category' | 'lastName' | 'firstName' | 'telephone' | 'isDeleted' | 'description' | 'isEmailValid' | 'hasDiffusion' | 'lastDiffusion'>
      & { images?: Maybe<Array<Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'data'>
      )>>>, diffusionLists?: Maybe<Array<Maybe<(
        { __typename?: 'DiffusionListInContact' }
        & Pick<DiffusionListInContact, '_id' | 'name' | 'description'>
      )>>> }
    )>>> }
  )> }
);

export type GetFeretContactEmailsQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetFeretContactEmailsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetFeretContactEmails'>
);

export type GetFeretContactStatsQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetFeretContactStatsQuery = (
  { __typename?: 'Query' }
  & { GetFeretContactStats?: Maybe<(
    { __typename?: 'ContactStats' }
    & Pick<ContactStats, 'contactCount' | 'contactListCount'>
  )> }
);

export type GetFeretContactQueryVariables = Exact<{
  contactId?: Maybe<Scalars['ID']>;
}>;


export type GetFeretContactQuery = (
  { __typename?: 'Query' }
  & { GetFeretContact?: Maybe<(
    { __typename?: 'FeretContactType' }
    & Pick<FeretContactType, '_id' | 'title' | 'firstName' | 'lastName' | 'email' | 'company' | 'category' | 'lastDiffusion' | 'telephone' | 'description' | 'isEmailValid' | 'isDeleted' | 'userId' | 'hasDiffusion'>
    & { images?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'data' | 'fileName'>
    )>>>, diffusionLists?: Maybe<Array<Maybe<(
      { __typename?: 'DiffusionListInContact' }
      & Pick<DiffusionListInContact, '_id' | 'name' | 'description'>
    )>>>, ftDiffusions?: Maybe<Array<Maybe<(
      { __typename?: 'FtDiffusionType' }
      & Pick<FtDiffusionType, '_id' | 'isOpen' | 'createdAt' | 'name' | 'wineName' | 'estateName' | 'vintage' | 'mainAppellation' | 'ftId' | 'lastUpdate' | 'isEmailValid'>
      & { bottleImage?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )>, languages?: Maybe<(
        { __typename?: 'LanguagesBool' }
        & Pick<LanguagesBool, 'fr' | 'en' | 'es' | 'de' | 'nl' | 'it'>
      )>, ftDownload?: Maybe<(
        { __typename?: 'FtDownloadType' }
        & { fr?: Maybe<(
          { __typename?: 'LngCount' }
          & Pick<LngCount, 'count' | 'lastDownload'>
        )>, en?: Maybe<(
          { __typename?: 'LngCount' }
          & Pick<LngCount, 'count' | 'lastDownload'>
        )>, es?: Maybe<(
          { __typename?: 'LngCount' }
          & Pick<LngCount, 'count' | 'lastDownload'>
        )>, de?: Maybe<(
          { __typename?: 'LngCount' }
          & Pick<LngCount, 'count' | 'lastDownload'>
        )>, nl?: Maybe<(
          { __typename?: 'LngCount' }
          & Pick<LngCount, 'count' | 'lastDownload'>
        )>, it?: Maybe<(
          { __typename?: 'LngCount' }
          & Pick<LngCount, 'count' | 'lastDownload'>
        )> }
      )> }
    )>>> }
  )> }
);

export type GetContactsFromCsvQueryVariables = Exact<{
  csvFile: DocumentInput;
  wineEntityId: Scalars['ID'];
}>;


export type GetContactsFromCsvQuery = (
  { __typename?: 'Query' }
  & { GetContactsFromCSV?: Maybe<(
    { __typename?: 'CSVContactType' }
    & Pick<CsvContactType, 'contactNumber'>
    & { contacts?: Maybe<Array<Maybe<(
      { __typename?: 'FeretContactType' }
      & Pick<FeretContactType, 'title' | 'firstName' | 'lastName' | 'email' | 'company' | 'category' | 'list' | 'invalidCategory' | 'isValidEmail' | 'isDuplicate'>
    )>>> }
  )> }
);

export type GetDiffusionListsSimpleQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetDiffusionListsSimpleQuery = (
  { __typename?: 'Query' }
  & { GetDiffusionLists?: Maybe<Array<Maybe<(
    { __typename?: 'DiffusionListType' }
    & Pick<DiffusionListType, '_id' | 'name' | 'description' | 'contactCount'>
  )>>> }
);

export type GetDiffusionListsQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetDiffusionListsQuery = (
  { __typename?: 'Query' }
  & { GetDiffusionLists?: Maybe<Array<Maybe<(
    { __typename?: 'DiffusionListType' }
    & Pick<DiffusionListType, '_id' | 'name' | 'description'>
    & { contacts?: Maybe<Array<Maybe<(
      { __typename?: 'FeretContactType' }
      & Pick<FeretContactType, '_id' | 'title' | 'firstName' | 'lastName' | 'email' | 'company' | 'category' | 'createdAt' | 'isDeleted'>
      & { ftDiffusions?: Maybe<Array<Maybe<(
        { __typename?: 'FtDiffusionType' }
        & Pick<FtDiffusionType, '_id' | 'isOpen' | 'createdAt' | 'name' | 'wineName' | 'estateName' | 'vintage' | 'mainAppellation' | 'ftId' | 'isEmailValid' | 'diffusionListId'>
      )>>> }
    )>>> }
  )>>> }
);

export type GetDiffusionListsWithContactsQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetDiffusionListsWithContactsQuery = (
  { __typename?: 'Query' }
  & { GetDiffusionLists?: Maybe<Array<Maybe<(
    { __typename?: 'DiffusionListType' }
    & Pick<DiffusionListType, '_id' | 'name' | 'description'>
    & { contacts?: Maybe<Array<Maybe<(
      { __typename?: 'FeretContactType' }
      & Pick<FeretContactType, '_id' | 'title' | 'firstName' | 'lastName' | 'email' | 'company' | 'category' | 'createdAt' | 'isDeleted'>
    )>>> }
  )>>> }
);

export type GetDiffusionListQueryVariables = Exact<{
  diffusionListId: Scalars['ID'];
}>;


export type GetDiffusionListQuery = (
  { __typename?: 'Query' }
  & { GetDiffusionList?: Maybe<(
    { __typename?: 'DiffusionListType' }
    & Pick<DiffusionListType, '_id' | 'name' | 'description'>
    & { contacts?: Maybe<Array<Maybe<(
      { __typename?: 'FeretContactType' }
      & Pick<FeretContactType, '_id' | 'userId' | 'title' | 'firstName' | 'lastName' | 'email' | 'company' | 'category' | 'createdAt' | 'isDeleted'>
    )>>> }
  )> }
);

export type GetStreamerFtDiffusionsQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetStreamerFtDiffusionsQuery = (
  { __typename?: 'Query' }
  & { GetStreamerFtDiffusions?: Maybe<Array<Maybe<(
    { __typename?: 'FtDiffusionType' }
    & Pick<FtDiffusionType, '_id' | 'isOpen' | 'createdAt' | 'name' | 'wineName' | 'estateName' | 'vintage' | 'mainAppellation' | 'ftId' | 'lastUpdate' | 'status' | 'isEmailValid' | 'contactCount' | 'webCount' | 'totalCount' | 'diffusionListNames'>
    & { languages?: Maybe<(
      { __typename?: 'LanguagesBool' }
      & Pick<LanguagesBool, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, bottleImage?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )> }
  )>>> }
);

export type GetWatcherFtDiffusionsQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetWatcherFtDiffusionsQuery = (
  { __typename?: 'Query' }
  & { GetWatcherFtDiffusions?: Maybe<Array<Maybe<(
    { __typename?: 'FtDiffusionType' }
    & Pick<FtDiffusionType, '_id' | 'isOpen' | 'isCreated' | 'isUpdated' | 'createdAt' | 'name' | 'wineName' | 'wineEntityId' | 'wineEntityShortId' | 'estateName' | 'vintage' | 'mainAppellation' | 'ftId' | 'lastUpdate' | 'message'>
    & { languages?: Maybe<(
      { __typename?: 'LanguagesBool' }
      & Pick<LanguagesBool, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'zh' | 'ja'>
    )>, preview?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )>, bottleImage?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )> }
  )>>> }
);

export type GetFtRequestByCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type GetFtRequestByCodeQuery = (
  { __typename?: 'Query' }
  & { GetFtRequestByCode?: Maybe<(
    { __typename?: 'FtRequestType' }
    & Pick<FtRequestType, '_id' | 'estateName' | 'email'>
  )> }
);

export type GetDiffusionParametersQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetDiffusionParametersQuery = (
  { __typename?: 'Query' }
  & { GetDiffusionParameters?: Maybe<(
    { __typename?: 'DiffusionParametersType' }
    & Pick<DiffusionParametersType, 'scoreNotify' | 'medalNotify' | 'certificationNotify'>
  )> }
);

export type GetFtDiffusionByContactQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  ftId: Scalars['ID'];
}>;


export type GetFtDiffusionByContactQuery = (
  { __typename?: 'Query' }
  & { GetFtDiffusionByContact?: Maybe<(
    { __typename?: 'FeretContactDiffMetricsType' }
    & Pick<FeretContactDiffMetricsType, 'name' | 'vintage' | 'wineName' | 'estateName' | 'mainAppellation' | 'lastModification'>
    & { preview?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )>, contacts?: Maybe<Array<Maybe<(
      { __typename?: 'FeretContactDiffType' }
      & { contact?: Maybe<(
        { __typename?: 'FeretContactType' }
        & Pick<FeretContactType, '_id' | 'title' | 'firstName' | 'lastName' | 'email' | 'lastDiffusion' | 'category' | 'company' | 'userId' | 'hasDiffusion' | 'isEmailValid' | 'isDeleted'>
        & { diffusionLists?: Maybe<Array<Maybe<(
          { __typename?: 'DiffusionListInContact' }
          & Pick<DiffusionListInContact, 'name'>
        )>>> }
      )>, ftDiffusion?: Maybe<(
        { __typename?: 'FtDiffusionType' }
        & Pick<FtDiffusionType, '_id' | 'userId' | 'isOpen' | 'lastUpdate' | 'isEmailValid'>
      )>, ftRequest?: Maybe<(
        { __typename?: 'FtRequestType' }
        & Pick<FtRequestType, '_id' | 'lastUpdate' | 'isEmailValid'>
      )>, ftOpened?: Maybe<Array<Maybe<(
        { __typename?: 'FtOpenedType' }
        & Pick<FtOpenedType, 'userId' | 'ftId' | 'version'>
      )>>>, ftDownload?: Maybe<(
        { __typename?: 'FtDownloadType' }
        & { fr?: Maybe<(
          { __typename?: 'LngCount' }
          & Pick<LngCount, 'count' | 'lastDownload'>
        )>, en?: Maybe<(
          { __typename?: 'LngCount' }
          & Pick<LngCount, 'count' | 'lastDownload'>
        )>, es?: Maybe<(
          { __typename?: 'LngCount' }
          & Pick<LngCount, 'count' | 'lastDownload'>
        )>, de?: Maybe<(
          { __typename?: 'LngCount' }
          & Pick<LngCount, 'count' | 'lastDownload'>
        )>, nl?: Maybe<(
          { __typename?: 'LngCount' }
          & Pick<LngCount, 'count' | 'lastDownload'>
        )>, it?: Maybe<(
          { __typename?: 'LngCount' }
          & Pick<LngCount, 'count' | 'lastDownload'>
        )>, ja?: Maybe<(
          { __typename?: 'LngCount' }
          & Pick<LngCount, 'count' | 'lastDownload'>
        )>, zh?: Maybe<(
          { __typename?: 'LngCount' }
          & Pick<LngCount, 'count' | 'lastDownload'>
        )> }
      )> }
    )>>>, contactMetrics?: Maybe<(
      { __typename?: 'FtContactMetricsType' }
      & Pick<FtContactMetricsType, 'lists' | 'contactNumber' | 'opened' | 'error' | 'downloaded'>
      & { locales?: Maybe<(
        { __typename?: 'LocalesType' }
        & Pick<LocalesType, 'fr' | 'en' | 'es' | 'it' | 'de' | 'nl' | 'ja' | 'zh'>
      )> }
    )>, allMetrics?: Maybe<(
      { __typename?: 'FtContactMetricsType' }
      & Pick<FtContactMetricsType, 'lists' | 'contactNumber' | 'opened' | 'error' | 'downloaded'>
      & { locales?: Maybe<(
        { __typename?: 'LocalesType' }
        & Pick<LocalesType, 'fr' | 'en' | 'es' | 'it' | 'de' | 'nl' | 'ja' | 'zh'>
      )> }
    )>, webMetrics?: Maybe<(
      { __typename?: 'FtWebMetricsType' }
      & Pick<FtWebMetricsType, 'webNumber' | 'opened' | 'downloaded'>
      & { locales?: Maybe<(
        { __typename?: 'LocalesType' }
        & Pick<LocalesType, 'fr' | 'en' | 'es' | 'it' | 'de' | 'nl' | 'ja' | 'zh'>
      )> }
    )> }
  )> }
);

export type GetFtDiffusionByDiffusionListQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
  ftId: Scalars['ID'];
}>;


export type GetFtDiffusionByDiffusionListQuery = (
  { __typename?: 'Query' }
  & { GetFtDiffusionByDiffusionList?: Maybe<(
    { __typename?: 'DiffusionListDiffType' }
    & Pick<DiffusionListDiffType, 'message' | 'name' | 'mainAppellation' | 'wineName' | 'estateName' | 'vintage' | 'lastUpdate'>
    & { preview?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )>, diffusionLists?: Maybe<Array<Maybe<(
      { __typename?: 'DiffusionListType' }
      & Pick<DiffusionListType, '_id' | 'name' | 'description'>
      & { contacts?: Maybe<Array<Maybe<(
        { __typename?: 'FeretContactType' }
        & Pick<FeretContactType, '_id' | 'firstName' | 'lastName' | 'email' | 'isDeleted'>
      )>>> }
    )>>> }
  )> }
);

export type GetCanCreateFtQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetCanCreateFtQuery = (
  { __typename?: 'Query' }
  & { GetCanCreateFt?: Maybe<(
    { __typename?: 'CanCreateFtType' }
    & Pick<CanCreateFtType, 'hasList' | 'hasFt'>
  )> }
);

export type SearchVintagesQueryVariables = Exact<{
  searchInput?: Maybe<VintageSearchInput>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type SearchVintagesQuery = (
  { __typename?: 'Query' }
  & { SearchVintages?: Maybe<(
    { __typename?: 'WineResult' }
    & Pick<WineResult, 'similar'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'WineResultData' }
      & Pick<WineResultData, 'sweetness' | '_id' | 'wineEntityId' | 'brandName' | 'color' | 'appellation' | 'wineEntityName' | 'missingField'>
    )>>>, colors?: Maybe<Array<Maybe<(
      { __typename?: 'ByGroupResult' }
      & Pick<ByGroupResult, '_id' | 'count'>
    )>>>, appellations?: Maybe<Array<Maybe<(
      { __typename?: 'ByGroupResult' }
      & Pick<ByGroupResult, '_id' | 'count'>
    )>>>, years?: Maybe<Array<Maybe<(
      { __typename?: 'ByGroupResult' }
      & Pick<ByGroupResult, '_id' | 'count'>
    )>>>, totalCount?: Maybe<Array<Maybe<(
      { __typename?: 'CountResult' }
      & Pick<CountResult, 'totalRecords'>
    )>>> }
  )> }
);

export type SearchWinesQueryVariables = Exact<{
  searchInput?: Maybe<VintageSearchInput>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type SearchWinesQuery = (
  { __typename?: 'Query' }
  & { searchWines?: Maybe<(
    { __typename?: 'WineResult' }
    & Pick<WineResult, 'similar'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'WineResultData' }
      & Pick<WineResultData, '_id' | 'wineEntityShortId' | 'wineShortId' | 'color' | 'sweetness' | 'brandName' | 'appellation' | 'wineEntityId' | 'wineEntityName' | 'missingField'>
    )>>>, colors?: Maybe<Array<Maybe<(
      { __typename?: 'ByGroupResult' }
      & Pick<ByGroupResult, '_id' | 'count'>
    )>>>, appellations?: Maybe<Array<Maybe<(
      { __typename?: 'ByGroupResult' }
      & Pick<ByGroupResult, '_id' | 'count'>
    )>>>, totalCount?: Maybe<Array<Maybe<(
      { __typename?: 'CountResult' }
      & Pick<CountResult, 'totalRecords'>
    )>>> }
  )> }
);

export type SearchActivitiesQueryVariables = Exact<{
  searchInput?: Maybe<ActivitySearchInput>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type SearchActivitiesQuery = (
  { __typename?: 'Query' }
  & { SearchActivities?: Maybe<(
    { __typename?: 'ActivityResult' }
    & Pick<ActivityResult, 'similar'>
    & { totalCount?: Maybe<Array<Maybe<(
      { __typename?: 'CountResult' }
      & Pick<CountResult, 'totalRecords'>
    )>>>, data?: Maybe<Array<Maybe<(
      { __typename?: 'ActivityResultData' }
      & Pick<ActivityResultData, '_id' | 'activityShortId' | 'wineEntityShortId' | 'name' | 'wineEntityName' | 'commune' | 'prices' | 'groupSize' | 'languages' | 'labelLanguages' | 'categories' | 'private' | 'parkingBus' | 'accessibleTo' | 'duration' | 'durationUnit' | 'missingField'>
    )>>> }
  )> }
);

export type SearchWineEntitiesQueryVariables = Exact<{
  searchInput?: Maybe<WineEntitySearchInput>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type SearchWineEntitiesQuery = (
  { __typename?: 'Query' }
  & { SearchWineEntities?: Maybe<(
    { __typename?: 'WineEntityResult' }
    & Pick<WineEntityResult, 'similar'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'WineEntityResultData' }
      & Pick<WineEntityResultData, 'wineEntityId' | 'wineEntityShortId' | 'wineEntityName' | 'appellation' | 'badge' | 'label' | 'onlineSales' | 'isOpen' | 'classification' | 'surfaceArea' | 'missingField'>
    )>>>, totalCount?: Maybe<Array<Maybe<(
      { __typename?: 'CountResult' }
      & Pick<CountResult, 'totalRecords'>
    )>>> }
  )> }
);

export type GetWineCardInformationQueryVariables = Exact<{
  wineId: Scalars['ID'];
}>;


export type GetWineCardInformationQuery = (
  { __typename?: 'Query' }
  & { GetWineCardInformation?: Maybe<(
    { __typename?: 'WineCardInformation' }
    & Pick<WineCardInformation, 'rewarded' | 'scored'>
    & { img?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'twicpicURL'>
    )>, vintageCardInformation?: Maybe<Array<Maybe<(
      { __typename?: 'VintageCardInformation' }
      & Pick<VintageCardInformation, 'year' | '_id' | 'cuvee'>
    )>>> }
  )> }
);

export type SearchAllQueryVariables = Exact<{
  searchText?: Maybe<Scalars['String']>;
}>;


export type SearchAllQuery = (
  { __typename?: 'Query' }
  & { SearchAll?: Maybe<(
    { __typename?: 'AllResult' }
    & Pick<AllResult, 'totalCount'>
    & { wineEntityData?: Maybe<(
      { __typename?: 'WineEntityResult' }
      & { data?: Maybe<Array<Maybe<(
        { __typename?: 'WineEntityResultData' }
        & Pick<WineEntityResultData, 'wineEntityId' | 'wineEntityShortId' | 'wineEntityName' | 'appellation' | 'badge' | 'label' | 'onlineSales' | 'isOpen' | 'classification' | 'surfaceArea'>
      )>>>, totalCount?: Maybe<Array<Maybe<(
        { __typename?: 'CountResult' }
        & Pick<CountResult, 'totalRecords'>
      )>>> }
    )>, vintageData?: Maybe<(
      { __typename?: 'WineResult' }
      & { data?: Maybe<Array<Maybe<(
        { __typename?: 'WineResultData' }
        & Pick<WineResultData, '_id' | 'wineEntityShortId' | 'wineShortId' | 'vintageIds' | 'years' | 'wineEntityId' | 'brandName' | 'appellation' | 'sweetness' | 'color' | 'wineEntityName'>
      )>>>, totalCount?: Maybe<Array<Maybe<(
        { __typename?: 'CountResult' }
        & Pick<CountResult, 'totalRecords'>
      )>>> }
    )>, activityData?: Maybe<(
      { __typename?: 'ActivityResult' }
      & { data?: Maybe<Array<Maybe<(
        { __typename?: 'ActivityResultData' }
        & Pick<ActivityResultData, 'activityShortId' | 'wineEntityShortId' | 'name' | 'wineEntityName' | 'commune' | 'prices' | 'groupSize' | 'languages' | 'categories' | 'private' | 'parkingBus' | 'accessibleTo' | 'duration' | 'durationUnit' | '_id'>
      )>>>, totalCount?: Maybe<Array<Maybe<(
        { __typename?: 'CountResult' }
        & Pick<CountResult, 'totalRecords'>
      )>>> }
    )> }
  )> }
);

export type HomeResultQueryVariables = Exact<{ [key: string]: never; }>;


export type HomeResultQuery = (
  { __typename?: 'Query' }
  & { HomeResult?: Maybe<(
    { __typename?: 'HomeResult' }
    & { wineEntityData?: Maybe<(
      { __typename?: 'WineEntityResult' }
      & { data?: Maybe<Array<Maybe<(
        { __typename?: 'WineEntityResultData' }
        & Pick<WineEntityResultData, 'wineEntityId' | 'wineEntityShortId' | 'wineEntityName' | 'appellation' | 'badge' | 'label' | 'onlineSales' | 'isOpen' | 'classification' | 'surfaceArea'>
      )>>>, totalCount?: Maybe<Array<Maybe<(
        { __typename?: 'CountResult' }
        & Pick<CountResult, 'totalRecords'>
      )>>> }
    )>, vintageData?: Maybe<(
      { __typename?: 'WineResult' }
      & { data?: Maybe<Array<Maybe<(
        { __typename?: 'WineResultData' }
        & Pick<WineResultData, '_id' | 'wineEntityShortId' | 'wineShortId' | 'vintageIds' | 'years' | 'wineEntityId' | 'brandName' | 'appellation' | 'sweetness' | 'color' | 'wineEntityName'>
      )>>>, totalCount?: Maybe<Array<Maybe<(
        { __typename?: 'CountResult' }
        & Pick<CountResult, 'totalRecords'>
      )>>> }
    )> }
  )> }
);

export type GetWineEntityCardInformationQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetWineEntityCardInformationQuery = (
  { __typename?: 'Query' }
  & { GetWineEntityCardInformation?: Maybe<(
    { __typename?: 'WineEntityCardInformation' }
    & Pick<WineEntityCardInformation, 'wineColors' | 'classifications' | 'hasEnotourismActivity' | 'hasBadge' | 'surfaceArea' | 'isOpenNow' | 'hasProductPageUrl'>
    & { img?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'twicpicURL'>
    )> }
  )> }
);

export type GetActivityCardInformationQueryVariables = Exact<{
  activityId: Scalars['ID'];
}>;


export type GetActivityCardInformationQuery = (
  { __typename?: 'Query' }
  & { GetActivityCardInformation?: Maybe<(
    { __typename?: 'ActivityCardInformation' }
    & { img?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'twicpicURL'>
    )> }
  )> }
);

export type AutocompleteSearchQueryVariables = Exact<{
  searchText: Scalars['String'];
}>;


export type AutocompleteSearchQuery = (
  { __typename?: 'Query' }
  & { AutocompleteSearch?: Maybe<Array<Maybe<(
    { __typename?: 'AutocompleteSearchResult' }
    & Pick<AutocompleteSearchResult, '_id' | 'kind' | 'title' | 'description' | 'wineShortId' | 'wineEntityId' | 'wineEntityName' | 'activityShortId' | 'wineEntityShortId'>
    & { vintages?: Maybe<Array<Maybe<(
      { __typename?: 'SimpleVintage' }
      & Pick<SimpleVintage, '_id' | 'year'>
    )>>> }
  )>>> }
);

export type GetFormationOrganismsFrontQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
}>;


export type GetFormationOrganismsFrontQuery = (
  { __typename?: 'Query' }
  & { GetFormationOrganismsFront?: Maybe<Array<Maybe<(
    { __typename?: 'FormationOrganismFront' }
    & Pick<FormationOrganismFront, '_id' | 'name' | 'about' | 'formations' | 'rhythm' | 'link' | 'phone' | 'email'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'commune' | 'postalCode' | 'country'>
    )>, socialMedias?: Maybe<Array<Maybe<(
      { __typename?: 'SocialMediaFront' }
      & Pick<SocialMediaFront, 'platform' | 'url'>
    )>>>, logo?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'data' | 'twicpicURL'>
    )> }
  )>>> }
);

export type GetFormationOrganismsSampleQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
}>;


export type GetFormationOrganismsSampleQuery = (
  { __typename?: 'Query' }
  & { GetFormationOrganismsSample?: Maybe<Array<Maybe<(
    { __typename?: 'FormationOrganismSample' }
    & Pick<FormationOrganismSample, '_id' | 'name'>
    & { logo?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'data' | 'twicpicURL'>
    )> }
  )>>> }
);

export type GetArticleFrontQueryVariables = Exact<{
  name: Scalars['String'];
  locale: Scalars['String'];
}>;


export type GetArticleFrontQuery = (
  { __typename?: 'Query' }
  & { GetArticleFront?: Maybe<(
    { __typename?: 'ArticleFrontType' }
    & Pick<ArticleFrontType, '_id' | 'name' | 'author' | 'readTime' | 'authorId' | 'pageCount' | 'authorInfo' | 'authorSlug'>
    & { references?: Maybe<Array<Maybe<(
      { __typename?: 'ReferenceType' }
      & Pick<ReferenceType, 'place' | 'content'>
    )>>> }
  )> }
);

export type GetArticleContentQueryVariables = Exact<{
  articleId: Scalars['ID'];
  locale: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
}>;


export type GetArticleContentQuery = (
  { __typename?: 'Query' }
  & { GetArticleContent?: Maybe<(
    { __typename?: 'ArticleContentType' }
    & Pick<ArticleContentType, 'page' | 'content'>
  )> }
);

export type GetArticleSummaryQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type GetArticleSummaryQuery = (
  { __typename?: 'Query' }
  & { GetArticleSummary?: Maybe<Array<Maybe<(
    { __typename?: 'ArticleSummaryType' }
    & Pick<ArticleSummaryType, 'name' | 'author' | 'slugFr' | 'authorId' | 'authorInfo'>
    & { readTime?: Maybe<(
      { __typename?: 'TimeType' }
      & Pick<TimeType, 'amount' | 'unit'>
    )> }
  )>>> }
);

export type GetVintagesOfCompetitionQueryVariables = Exact<{
  competitionId?: Maybe<Scalars['String']>;
}>;


export type GetVintagesOfCompetitionQuery = (
  { __typename?: 'Query' }
  & { GetVintagesOfCompetition?: Maybe<Array<Maybe<(
    { __typename?: 'SimpleRewardVintage' }
    & Pick<SimpleRewardVintage, 'wineEntityShortId' | 'wineShortId' | 'brandName' | 'year' | '_id' | 'wineId' | 'appellation' | 'type' | 'color' | 'sweetness' | 'wineEntityName' | 'rewardName' | 'rewardYear'>
    & { image?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'twicpicURL'>
    )> }
  )>>> }
);

export type GetRepresentationOrganismsFrontSimplifiedQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  excludeId?: Maybe<Scalars['ID']>;
}>;


export type GetRepresentationOrganismsFrontSimplifiedQuery = (
  { __typename?: 'Query' }
  & { GetRepresentationOrganismsFront?: Maybe<Array<Maybe<(
    { __typename?: 'RepresentationOrganismFront' }
    & Pick<RepresentationOrganismFront, '_id' | 'name'>
  )>>> }
);

export type GetArticlesByAuthorIdQueryVariables = Exact<{
  authorId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
}>;


export type GetArticlesByAuthorIdQuery = (
  { __typename?: 'Query' }
  & { GetArticlesByAuthorId?: Maybe<Array<Maybe<(
    { __typename?: 'AuthorArticleType' }
    & Pick<AuthorArticleType, 'name' | 'slugFr'>
  )>>> }
);

export type GetRepresentationOrganismsFrontQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
}>;


export type GetRepresentationOrganismsFrontQuery = (
  { __typename?: 'Query' }
  & { GetRepresentationOrganismsFront?: Maybe<Array<Maybe<(
    { __typename?: 'RepresentationOrganismFront' }
    & Pick<RepresentationOrganismFront, '_id' | 'name' | 'about' | 'phone' | 'email'>
    & { members?: Maybe<Array<Maybe<(
      { __typename?: 'OrganismMemberFront' }
      & Pick<OrganismMemberFront, 'title' | 'firstName' | 'lastName' | 'role' | 'icon'>
    )>>>, socialMedias?: Maybe<Array<Maybe<(
      { __typename?: 'SocialMediaFront' }
      & Pick<SocialMediaFront, 'platform' | 'url'>
    )>>>, logo?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'data' | 'twicpicURL'>
    )>, address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'postalCode' | 'address' | 'commune' | 'country'>
    )> }
  )>>> }
);

export type GetRandomCompetitionSampleQueryVariables = Exact<{
  excludeId?: Maybe<Scalars['ID']>;
}>;


export type GetRandomCompetitionSampleQuery = (
  { __typename?: 'Query' }
  & { GetRandomCompetitionSample?: Maybe<Array<Maybe<(
    { __typename?: 'SimpleCompetition' }
    & Pick<SimpleCompetition, '_id' | 'name' | 'totalMedals' | 'maxYear'>
    & { logo?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'data' | 'twicpicURL'>
    )> }
  )>>> }
);

export type GetCompetitionListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompetitionListQuery = (
  { __typename?: 'Query' }
  & { GetCompetitionList?: Maybe<Array<Maybe<(
    { __typename?: 'SimpleCompetition' }
    & Pick<SimpleCompetition, '_id' | 'name' | 'totalMedals' | 'maxYear'>
    & { logo?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'data' | 'twicpicURL'>
    )> }
  )>>> }
);

export type GetCompetitionsFrontQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type GetCompetitionsFrontQuery = (
  { __typename?: 'Query' }
  & { GetCompetitionsFront?: Maybe<Array<Maybe<(
    { __typename?: 'CompetitionFront' }
    & Pick<CompetitionFront, '_id' | 'url' | 'name' | 'email' | 'phone' | 'maxYear' | 'lastDate' | 'nextDate' | 'totalMedals' | 'nextDateYear' | 'presentation' | 'competitionId' | 'nextDateUnknow' | 'isNextDateKnow' | 'medalPercentage' | 'moreInformations' | 'selectionCriteria'>
    & { medals?: Maybe<Array<Maybe<(
      { __typename?: 'MedalType' }
      & Pick<MedalType, 'number' | 'year' | 'fontColor' | 'bgColor' | 'name'>
    )>>>, members?: Maybe<Array<Maybe<(
      { __typename?: 'CompetitionMembersType' }
      & Pick<CompetitionMembersType, 'title' | 'firstName' | 'lastName' | 'email' | 'telephone'>
      & { role?: Maybe<(
        { __typename?: 'LngString' }
        & Pick<LngString, 'fr' | 'en' | 'es' | 'it' | 'de' | 'nl' | 'ja' | 'zh'>
      )> }
    )>>>, address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'commune' | 'postalCode' | 'country'>
    )>, socialMedias?: Maybe<Array<Maybe<(
      { __typename?: 'SocialMediaFront' }
      & Pick<SocialMediaFront, '_id' | 'platform' | 'name' | 'url'>
      & { icon?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )> }
    )>>>, logo?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'data'>
    )> }
  )>>> }
);

export type GetAppellationDescriptionsFrontQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
}>;


export type GetAppellationDescriptionsFrontQuery = (
  { __typename?: 'Query' }
  & { GetAppellationDescriptionsFront?: Maybe<Array<Maybe<(
    { __typename?: 'AppellationDescriptionFrontType' }
    & Pick<AppellationDescriptionFrontType, 'name' | 'description' | 'subregion' | 'surface' | 'surfacePercentage' | 'specificationLink' | 'geoArea' | 'proximityArea' | 'winesProduced'>
    & { odg?: Maybe<(
      { __typename?: 'OdgType' }
      & Pick<OdgType, 'name'>
    )>, logo?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'data' | 'twicpicURL'>
    )>, wines?: Maybe<Array<Maybe<(
      { __typename?: 'WineCompositionType' }
      & Pick<WineCompositionType, 'color' | 'sweetness' | 'type'>
    )>>>, grapeVarietiesRed?: Maybe<Array<Maybe<(
      { __typename?: 'GrapeVarietyType' }
      & Pick<GrapeVarietyType, 'varietyDisplayName' | 'primary'>
    )>>>, grapeVarietiesWhite?: Maybe<Array<Maybe<(
      { __typename?: 'GrapeVarietyType' }
      & Pick<GrapeVarietyType, 'varietyDisplayName' | 'primary'>
    )>>>, wineInformations?: Maybe<Array<Maybe<(
      { __typename?: 'WineInformationFrontType' }
      & Pick<WineInformationFrontType, 'wineName' | 'aromas' | 'noseIntensity' | 'tasteIntensity' | 'temperature' | 'attack' | 'length' | 'acidity' | 'storageYear' | 'about'>
    )>>> }
  )>>> }
);

export type GetAppellationDescriptionsSampleQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAppellationDescriptionsSampleQuery = (
  { __typename?: 'Query' }
  & { GetAppellationDescriptionsSample?: Maybe<Array<Maybe<(
    { __typename?: 'AppellationDescriptionsSample' }
    & Pick<AppellationDescriptionsSample, 'name' | 'subregion'>
    & { logo?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'data'>
    )>, wines?: Maybe<Array<Maybe<(
      { __typename?: 'WineCompositionType' }
      & Pick<WineCompositionType, 'color' | 'sweetness' | 'type'>
    )>>> }
  )>>> }
);

export type GetAppellationVintageIdsQueryVariables = Exact<{
  label: Scalars['String'];
}>;


export type GetAppellationVintageIdsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetAppellationVintageIds'>
);

export type GetAppellationVintageQueryVariables = Exact<{
  vintageId: Scalars['ID'];
}>;


export type GetAppellationVintageQuery = (
  { __typename?: 'Query' }
  & { GetAppellationVintage?: Maybe<(
    { __typename?: 'vintageAppellationType' }
    & Pick<VintageAppellationType, 'year' | 'cuvee' | 'label' | 'wineName' | 'vintageId' | 'classification' | 'wineEntityName'>
    & { bottlePicture?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data' | 'twicpicURL'>
    )> }
  )> }
);

export type GetFirstWineIdQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetFirstWineIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetFirstWineId'>
);

export type GetFirstActivityIdQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetFirstActivityIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetFirstActivityId'>
);

export type GetExplorerKeywordsQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetExplorerKeywordsQuery = (
  { __typename?: 'Query' }
  & { GetExplorerKeywords?: Maybe<Array<Maybe<(
    { __typename?: 'ExplorerKeywords' }
    & Pick<ExplorerKeywords, 'label' | 'link' | 'category' | 'keywords'>
  )>>> }
);

export type GetCommunePostalCodeQueryVariables = Exact<{
  communePostalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetCommunePostalCodeQuery = (
  { __typename?: 'Query' }
  & { GetCommunePostalCode?: Maybe<Array<Maybe<(
    { __typename?: 'CommuneSimple' }
    & Pick<CommuneSimple, 'name' | 'displayName' | 'postalCode' | 'INSEEcode'>
  )>>> }
);

export type GetIndicatorsMetricsQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetIndicatorsMetricsQuery = (
  { __typename?: 'Query' }
  & { GetIndicatorsMetrics?: Maybe<(
    { __typename?: 'WineEntityMetrics' }
    & Pick<WineEntityMetrics, 'surfaceImportCvi' | 'wineEntityName'>
  )> }
);

export type GetMemberMetricsQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetMemberMetricsQuery = (
  { __typename?: 'Query' }
  & { GetMemberMetrics?: Maybe<(
    { __typename?: 'MemberMetrics' }
    & Pick<MemberMetrics, 'ownerCount' | 'operatorCount' | 'ownerOperatorCount' | 'total' | 'otherCount'>
    & { members?: Maybe<Array<Maybe<(
      { __typename?: 'MemberSimple' }
      & Pick<MemberSimple, 'displayName' | 'personId' | 'proFunctions' | 'vip'>
      & { image?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )> }
    )>>> }
  )> }
);

export type GetContactMetricsQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetContactMetricsQuery = (
  { __typename?: 'Query' }
  & { GetContactMetrics?: Maybe<(
    { __typename?: 'ContactMetrics' }
    & Pick<ContactMetrics, 'listCount' | 'total' | 'subscriberCount'>
    & { contactsByCategories?: Maybe<Array<Maybe<(
      { __typename?: 'ContactsCategory' }
      & Pick<ContactsCategory, 'count' | 'category' | 'backgroundColor' | 'color'>
    )>>> }
  )> }
);

export type GetFtParametersMetricsQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetFtParametersMetricsQuery = (
  { __typename?: 'Query' }
  & { GetFtParametersMetrics?: Maybe<(
    { __typename?: 'FtParameterMetrics' }
    & Pick<FtParameterMetrics, 'total'>
    & { ftByStatus?: Maybe<(
      { __typename?: 'FtByStatus' }
      & Pick<FtByStatus, 'draft' | 'active' | 'online' | 'inactive'>
    )> }
  )> }
);

export type GetWineMetricsQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetWineMetricsQuery = (
  { __typename?: 'Query' }
  & { GetWineMetrics?: Maybe<Array<Maybe<(
    { __typename?: 'WineMetrics' }
    & Pick<WineMetrics, 'wineId' | 'wineName' | 'wineColor' | 'appellation' | 'rewardCount' | 'scroreCount' | 'classification'>
    & { bottleImage?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )>, vintages?: Maybe<Array<Maybe<(
      { __typename?: 'VintagesInfo' }
      & Pick<VintagesInfo, 'name' | 'url'>
    )>>> }
  )>>> }
);

export type GetWineActivityMetricsQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetWineActivityMetricsQuery = (
  { __typename?: 'Query' }
  & { GetWineActivityMetrics?: Maybe<Array<Maybe<(
    { __typename?: 'WineActivityMetrics' }
    & Pick<WineActivityMetrics, 'name' | 'url'>
    & { type?: Maybe<(
      { __typename?: 'ActivityType' }
      & Pick<ActivityType, 'type' | 'bgColor' | 'textColor'>
    )>, image?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )> }
  )>>> }
);

export type GetVineyardMetricsQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetVineyardMetricsQuery = (
  { __typename?: 'Query' }
  & { GetVineyardMetrics?: Maybe<(
    { __typename?: 'VineyardMetrics' }
    & { vineyardIndicator?: Maybe<Array<Maybe<(
      { __typename?: 'VineyardIndicator' }
      & Pick<VineyardIndicator, 'id' | 'name' | 'quantity' | 'unit'>
    )>>>, vinesIndicator?: Maybe<Array<Maybe<(
      { __typename?: 'GraphIndicator' }
      & Pick<GraphIndicator, 'name' | 'rate' | 'area' | 'backgroundColor' | 'color'>
    )>>>, repartitionsGeoIndicator?: Maybe<Array<Maybe<(
      { __typename?: 'GraphIndicator' }
      & Pick<GraphIndicator, 'name' | 'rate' | 'area' | 'backgroundColor' | 'color'>
    )>>> }
  )> }
);

export type GetFtModelsQueryVariables = Exact<{
  vintageId: Scalars['ID'];
  wineEntityId: Scalars['ID'];
}>;


export type GetFtModelsQuery = (
  { __typename?: 'Query' }
  & { GetFtModels?: Maybe<Array<Maybe<(
    { __typename?: 'FtModel' }
    & Pick<FtModel, '_id' | 'category' | 'essentialRestriction'>
    & { missingComponents?: Maybe<Array<Maybe<(
      { __typename?: 'MissingComponentType' }
      & Pick<MissingComponentType, 'label' | 'url'>
    )>>>, pagePreviews?: Maybe<Array<Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )>>> }
  )>>> }
);

export type GetActivityFrontQueryVariables = Exact<{
  communeINSEEcode: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
}>;


export type GetActivityFrontQuery = (
  { __typename?: 'Query' }
  & { GetActivityFront?: Maybe<Array<Maybe<(
    { __typename?: 'ActivityFront' }
    & Pick<ActivityFront, 'priceOnEstimation' | 'free' | 'activityId' | 'name' | 'description' | 'groupSize' | 'accessibleTo' | 'startingAge' | 'tags' | 'duration' | 'durationUnit' | 'reservationNeeded' | 'contactPhoneNumber' | 'contactEmail' | 'minRentTime' | 'minRentTimeUnit' | 'maxRentTime' | 'maxRentTimeUnit'>
    & { types?: Maybe<Array<Maybe<(
      { __typename?: 'ActivityType' }
      & Pick<ActivityType, 'bgColor' | 'textColor' | 'type' | 'typeFr'>
    )>>>, languages?: Maybe<Array<Maybe<(
      { __typename?: 'Language' }
      & Pick<Language, 'language'>
      & { icon?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data' | 'twicpicURL'>
      )> }
    )>>>, image?: Maybe<Array<Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'data'>
    )>>>, activePeriods?: Maybe<Array<Maybe<(
      { __typename?: 'ActivePeriod' }
      & { startAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )>, endAt?: Maybe<(
        { __typename?: 'VagueDate' }
        & Pick<VagueDate, 'day' | 'month' | 'year' | 'vaguePeriod'>
      )> }
    )>>>, priceDetails?: Maybe<Array<Maybe<(
      { __typename?: 'PriceDetail' }
      & Pick<PriceDetail, 'kind' | 'price' | 'currency'>
    )>>>, contactPerson?: Maybe<(
      { __typename?: 'PersonOption' }
      & Pick<PersonOption, 'title' | 'type' | 'functions' | 'phoneNumber' | 'email' | 'personId'>
      & { image?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )> }
    )> }
  )>>> }
);

export type GetAppellationDescriptionsNameSubregionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAppellationDescriptionsNameSubregionQuery = (
  { __typename?: 'Query' }
  & { GetAppellationDescriptionsNameSubregion?: Maybe<Array<Maybe<(
    { __typename?: 'NameSubregionType' }
    & Pick<NameSubregionType, 'name' | 'subregion'>
  )>>> }
);

export type GetClassificationFrontQueryVariables = Exact<{
  classificationOrganismSlug: Scalars['String'];
}>;


export type GetClassificationFrontQuery = (
  { __typename?: 'Query' }
  & { GetClassificationFront?: Maybe<Array<Maybe<(
    { __typename?: 'ClassificationFront' }
    & Pick<ClassificationFront, 'id' | 'number' | 'classificationGrade'>
    & { classified?: Maybe<Array<Maybe<(
      { __typename?: 'SimpleWineEntityLabel' }
      & Pick<SimpleWineEntityLabel, 'id' | 'appellationLabel'>
      & { wineEntities?: Maybe<Array<Maybe<(
        { __typename?: 'SimpleWineEntity' }
        & Pick<SimpleWineEntity, 'wineEntityId' | 'name' | 'shortId'>
      )>>> }
    )>>> }
  )>>> }
);

export type GetSubscriptionDataQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetSubscriptionDataQuery = (
  { __typename?: 'Query' }
  & { GetSubscriptionData?: Maybe<(
    { __typename?: 'SubscriptionData' }
    & Pick<SubscriptionData, '_id' | 'email' | 'siret' | 'userId' | 'status' | 'isOwner' | 'telephone' | 'addonCodes' | 'recurrence' | 'isOperator' | 'mainCommune' | 'paymentMethod' | 'wineEntityName' | 'mainAppellation' | 'organizationName' | 'subscriptionType' | 'organizationStatus' | 'mainCommuneInseeCode' | 'orphanWineEntityId'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'commune' | 'country' | 'postalCode' | 'communeINSEEcode' | 'complementaryAddress'>
    )>, billingAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'commune' | 'country' | 'postalCode' | 'communeINSEEcode' | 'complementaryAddress'>
    )>, billingInformation?: Maybe<(
      { __typename?: 'BillingInformation' }
      & Pick<BillingInformation, 'siret' | 'title' | 'email' | 'lastName' | 'tvaNumber' | 'firstName' | 'telephoneNumber' | 'organizationName' | 'organizationStatus'>
    )> }
  )> }
);

export type GetEstateVisibilityQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetEstateVisibilityQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetEstateVisibility'>
);

export type GetUserSettingsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserSettingsQuery = (
  { __typename?: 'Query' }
  & { GetUserSettings?: Maybe<(
    { __typename?: 'UserSettingsType' }
    & Pick<UserSettingsType, 'noDisplayModal'>
  )> }
);

export type GetUnpaidSubscriptionInformationQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetUnpaidSubscriptionInformationQuery = (
  { __typename?: 'Query' }
  & { GetUnpaidSubscriptionInformation?: Maybe<(
    { __typename?: 'UnpaidSubscriptionInformation' }
    & Pick<UnpaidSubscriptionInformation, 'zohoSubscriptionId' | 'dateUnpaid' | 'wineEntityName' | 'mainAppellation' | 'subscriptionName' | 'price'>
  )> }
);

export type GetTastingRequestsQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
  wineEntityId?: Maybe<Scalars['String']>;
}>;


export type GetTastingRequestsQuery = (
  { __typename?: 'Query' }
  & { GetTastingRequests?: Maybe<Array<Maybe<(
    { __typename?: 'TastingRequestType' }
    & Pick<TastingRequestType, '_id' | 'sampleId' | 'wineEntityId' | 'wineId' | 'vintageId' | 'status' | 'journalistName' | 'createdAt' | 'videoRequested' | 'userId' | 'score' | 'scale' | 'websiteUrl' | 'videoUrl' | 'tastingDate'>
    & { wineEntity?: Maybe<(
      { __typename?: 'WineEntityType' }
      & Pick<WineEntityType, 'name' | 'shortId' | 'mainAppellation'>
    )>, wine?: Maybe<(
      { __typename?: 'ReferenceWineType' }
      & Pick<ReferenceWineType, 'wineName' | 'wineLabel' | 'wineColor' | 'wineType' | 'wineSweetness' | 'wineId' | 'appellation'>
      & { bottleImage?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )>, bottleIcon?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )> }
    )>, vintage?: Maybe<(
      { __typename?: 'VintageType' }
      & Pick<VintageType, 'year' | 'cuvee'>
    )> }
  )>>> }
);

export type GetTastingRequestQueryVariables = Exact<{
  tastingRequestId?: Maybe<Scalars['ID']>;
}>;


export type GetTastingRequestQuery = (
  { __typename?: 'Query' }
  & { GetTastingRequest?: Maybe<(
    { __typename?: 'TastingRequestType' }
    & Pick<TastingRequestType, '_id' | 'sampleId' | 'wineEntityId' | 'wineId' | 'vintageId' | 'status' | 'userId' | 'score' | 'scale' | 'websiteUrl' | 'videoUrl' | 'tastingDate' | 'wineTank' | 'wineReleased' | 'bottlingDate' | 'videoRequested'>
    & { wineEntity?: Maybe<(
      { __typename?: 'WineEntityType' }
      & Pick<WineEntityType, 'name' | 'shortId' | 'mainAppellation' | 'searchable'>
    )>, wine?: Maybe<(
      { __typename?: 'ReferenceWineType' }
      & Pick<ReferenceWineType, 'shortId' | 'wineName' | 'wineLabel' | 'wineColor' | 'wineType' | 'wineSweetness' | 'wineId' | 'appellation'>
      & { bottleImage?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )> }
    )>, vintage?: Maybe<(
      { __typename?: 'VintageType' }
      & Pick<VintageType, 'year' | 'cuvee'>
    )>, comment?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, extraInformations?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'de' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )> }
  )> }
);

export type GetJournalistListQueryVariables = Exact<{
  filters?: Maybe<JournalistFilter>;
}>;


export type GetJournalistListQuery = (
  { __typename?: 'Query' }
  & { GetJournalistList?: Maybe<Array<Maybe<(
    { __typename?: 'JournalistType' }
    & Pick<JournalistType, 'id' | 'name' | 'video' | 'demoVideoUrl' | 'delay' | 'prerequisites' | 'areas'>
  )>>> }
);

export type GetCommonalitiesWineEntitiesQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetCommonalitiesWineEntitiesQuery = (
  { __typename?: 'Query' }
  & { GetCommonalitiesWineEntities?: Maybe<(
    { __typename?: 'WineEntitiesCommonalitiesType' }
    & { byCommune?: Maybe<(
      { __typename?: 'WineEntityFilteredType' }
      & Pick<WineEntityFilteredType, 'total'>
      & { result?: Maybe<Array<Maybe<(
        { __typename?: 'WineEntityCommonalityType' }
        & Pick<WineEntityCommonalityType, 'wineEntityId' | 'wineEntityShortId' | 'wineEntityName' | 'appellation' | 'INSEEcode'>
      )>>> }
    )>, byAppellation?: Maybe<(
      { __typename?: 'WineEntityFilteredType' }
      & Pick<WineEntityFilteredType, 'total'>
      & { result?: Maybe<Array<Maybe<(
        { __typename?: 'WineEntityCommonalityType' }
        & Pick<WineEntityCommonalityType, 'wineEntityId' | 'wineEntityShortId' | 'wineEntityName' | 'appellation'>
      )>>> }
    )>, bySubregion?: Maybe<(
      { __typename?: 'WineEntityFilteredType' }
      & Pick<WineEntityFilteredType, 'total'>
      & { result?: Maybe<Array<Maybe<(
        { __typename?: 'WineEntityCommonalityType' }
        & Pick<WineEntityCommonalityType, 'wineEntityId' | 'wineEntityShortId' | 'wineEntityName' | 'appellation' | 'subregion'>
      )>>> }
    )> }
  )> }
);

export type GetJournalistQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type GetJournalistQuery = (
  { __typename?: 'Query' }
  & { GetJournalist?: Maybe<(
    { __typename?: 'JournalistItemFront' }
    & Pick<JournalistItemFront, 'title' | 'company' | 'areas'>
    & { about?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'en' | 'it' | 'nl' | 'de' | 'es' | 'ja' | 'zh'>
    )>, photo?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'twicpicURL'>
    )>, logo?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'twicpicURL'>
    )>, background?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'twicpicURL'>
    )>, socialMedias?: Maybe<Array<Maybe<(
      { __typename?: 'SocialMediaFront' }
      & Pick<SocialMediaFront, 'platform' | 'url'>
    )>>>, vintages?: Maybe<Array<Maybe<(
      { __typename?: 'JournalistVintages' }
      & Pick<JournalistVintages, 'classification'>
      & { image?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'twicpicURL'>
      )>, vintage?: Maybe<(
        { __typename?: 'FrontVintage' }
        & Pick<FrontVintage, 'year' | 'cuvee'>
      )>, wineEntity?: Maybe<(
        { __typename?: 'SimpleWineEntity' }
        & Pick<SimpleWineEntity, 'name' | 'shortId'>
      )>, wine?: Maybe<(
        { __typename?: 'WineFront' }
        & Pick<WineFront, 'shortId' | 'brandName' | 'appellation' | 'color' | 'type'>
      )> }
    )>>> }
  )> }
);

export type GetJournalistSamplesQueryVariables = Exact<{
  exclude?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetJournalistSamplesQuery = (
  { __typename?: 'Query' }
  & { GetJournalistSamples?: Maybe<Array<Maybe<(
    { __typename?: 'JournalistItem' }
    & Pick<JournalistItem, 'slug' | 'title' | 'company'>
    & { logo?: Maybe<(
      { __typename?: 'Img' }
      & Pick<Img, 'twicpicURL'>
    )> }
  )>>> }
);

export type GetCommonalitiesWineQueryVariables = Exact<{
  wineId: Scalars['ID'];
}>;


export type GetCommonalitiesWineQuery = (
  { __typename?: 'Query' }
  & { GetCommonalitiesWine?: Maybe<(
    { __typename?: 'WinesCommonalitiesType' }
    & { byAppellation?: Maybe<(
      { __typename?: 'WineFilteredType' }
      & Pick<WineFilteredType, 'total'>
      & { result?: Maybe<Array<Maybe<(
        { __typename?: 'WineCommonalityType' }
        & Pick<WineCommonalityType, 'wineId' | 'wineEntityName' | 'wineEntityShortId' | 'wineShortId' | 'brandName' | 'appellation' | 'color' | 'sweetness'>
        & { bottleImage?: Maybe<(
          { __typename?: 'Img' }
          & Pick<Img, 'data'>
        )> }
      )>>> }
    )>, byProfil?: Maybe<(
      { __typename?: 'WineFilteredType' }
      & Pick<WineFilteredType, 'total'>
      & { result?: Maybe<Array<Maybe<(
        { __typename?: 'WineCommonalityType' }
        & Pick<WineCommonalityType, 'wineId' | 'wineEntityName' | 'wineEntityShortId' | 'wineShortId' | 'brandName' | 'appellation' | 'color' | 'sweetness'>
        & { bottleImage?: Maybe<(
          { __typename?: 'Img' }
          & Pick<Img, 'data'>
        )> }
      )>>> }
    )> }
  )> }
);

export type GetCommonalitiesActivitiesQueryVariables = Exact<{
  activityId: Scalars['ID'];
}>;


export type GetCommonalitiesActivitiesQuery = (
  { __typename?: 'Query' }
  & { GetCommonalitiesActivities?: Maybe<(
    { __typename?: 'ActivitiesCommonalitiesType' }
    & { byCommune?: Maybe<(
      { __typename?: 'ActivityFilteredType' }
      & Pick<ActivityFilteredType, 'total'>
      & { result?: Maybe<Array<Maybe<(
        { __typename?: 'ActivityCommonalityType' }
        & Pick<ActivityCommonalityType, 'wineEntityShortId' | 'wineEntityName' | 'shortId' | 'name' | 'INSEEcode' | 'typeFr'>
        & { image?: Maybe<Array<Maybe<(
          { __typename?: 'Img' }
          & Pick<Img, 'data'>
        )>>> }
      )>>> }
    )>, byType?: Maybe<(
      { __typename?: 'ActivityFilteredType' }
      & Pick<ActivityFilteredType, 'total'>
      & { result?: Maybe<Array<Maybe<(
        { __typename?: 'ActivityCommonalityType' }
        & Pick<ActivityCommonalityType, 'wineEntityShortId' | 'wineEntityName' | 'shortId' | 'name' | 'INSEEcode' | 'typeFr'>
        & { image?: Maybe<Array<Maybe<(
          { __typename?: 'Img' }
          & Pick<Img, 'data'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type GetFtPdfUrlQueryVariables = Exact<{
  ftParametersId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
}>;


export type GetFtPdfUrlQuery = (
  { __typename?: 'Query' }
  & { GetFtPdfUrl?: Maybe<(
    { __typename?: 'FileUrl' }
    & Pick<FileUrl, 'url' | 'fileName'>
  )> }
);

export type GetFtPdfUrlsQueryVariables = Exact<{
  ftParametersId: Scalars['ID'];
  locales?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;


export type GetFtPdfUrlsQuery = (
  { __typename?: 'Query' }
  & { GetFtPdfUrls?: Maybe<Array<Maybe<(
    { __typename?: 'FileUrl' }
    & Pick<FileUrl, 'url' | 'fileName'>
  )>>> }
);

export type GetFtZipUrlQueryVariables = Exact<{
  ftParametersId: Scalars['ID'];
}>;


export type GetFtZipUrlQuery = (
  { __typename?: 'Query' }
  & { GetFtZipUrl?: Maybe<(
    { __typename?: 'FileUrl' }
    & Pick<FileUrl, 'url' | 'fileName'>
  )> }
);

export type GetFtpDiffusionsQueryVariables = Exact<{
  wineEntityId?: Maybe<Scalars['ID']>;
}>;


export type GetFtpDiffusionsQuery = (
  { __typename?: 'Query' }
  & { GetFtpDiffusions?: Maybe<Array<Maybe<(
    { __typename?: 'GetFtpDiffusions' }
    & { ft?: Maybe<(
      { __typename?: 'BocFtParameters' }
      & Pick<BocFtParameters, 'ftId' | 'wineEntityId' | 'wineId' | 'vintageId' | 'name' | 'description' | 'wine' | 'vintage' | 'cuvee' | 'fullAppellation' | 'version' | 'status' | 'lastModification' | 'online' | 'createdAt' | 'wineEntityName'>
      & { languages?: Maybe<(
        { __typename?: 'LanguagesBool' }
        & Pick<LanguagesBool, 'fr' | 'en' | 'es' | 'de' | 'it' | 'nl' | 'ja' | 'zh'>
      )>, preview?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )>, bottleImage?: Maybe<(
        { __typename?: 'Img' }
        & Pick<Img, 'data'>
      )> }
    )>, ftpDiffusions?: Maybe<Array<Maybe<(
      { __typename?: 'FtpDiffusion' }
      & Pick<FtpDiffusion, '_id' | 'host' | 'port' | 'ftId' | 'wineId' | 'version' | 'username' | 'password' | 'fileName' | 'language' | 'lastSync' | 'autoSync' | 'protocole' | 'vintageId' | 'remotePath' | 'wineEntityId' | 'succeed'>
    )>>> }
  )>>> }
);

export type GetFtpDiffusionLogQueryVariables = Exact<{
  ftpDiffusionId?: Maybe<Scalars['ID']>;
}>;


export type GetFtpDiffusionLogQuery = (
  { __typename?: 'Query' }
  & { GetFtpDiffusionLog?: Maybe<(
    { __typename?: 'FtpDiffusionLog' }
    & Pick<FtpDiffusionLog, 'text' | 'logName'>
  )> }
);

export type GetPaperSubscriptionDataQueryVariables = Exact<{
  wineEntityId: Scalars['ID'];
}>;


export type GetPaperSubscriptionDataQuery = (
  { __typename?: 'Query' }
  & { GetPaperSubscriptionData?: Maybe<(
    { __typename?: 'PaperSubcriptionData' }
    & Pick<PaperSubcriptionData, 'wineEntityId' | 'accessibility' | 'isBookingRequired' | 'languages' | 'priceRange' | 'touristLabels' | 'visitSchedule' | 'hasOtherCulture' | 'otherCulture' | 'bugFightMethod' | 'byGrassing' | 'grassingMethod' | 'done' | 'redVineSurface' | 'whiteVineSurface' | 'redAvgProd' | 'whiteAvgProd'>
    & { description?: Maybe<(
      { __typename?: 'LngString' }
      & Pick<LngString, 'fr' | 'de' | 'en' | 'es' | 'it' | 'nl' | 'ja' | 'zh'>
    )>, redWineAssembly?: Maybe<Array<Maybe<(
      { __typename?: 'SimpleGrapeVarietyPart' }
      & Pick<SimpleGrapeVarietyPart, 'grapeVarietyName' | 'percentage'>
    )>>>, whiteWineAssembly?: Maybe<Array<Maybe<(
      { __typename?: 'SimpleGrapeVarietyPart' }
      & Pick<SimpleGrapeVarietyPart, 'grapeVarietyName' | 'percentage'>
    )>>>, certificationFiles?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'bucketName' | 'fileName'>
    )>>> }
  )> }
);


export const CreateUploadUrlDocument = gql`
    mutation CreateUploadURL($bucketName: String, $fileName: String) {
  CreateUploadURL(input: {bucketName: $bucketName, fileName: $fileName}) {
    url
  }
}
    `;
export type CreateUploadUrlMutationFn = Apollo.MutationFunction<CreateUploadUrlMutation, CreateUploadUrlMutationVariables>;

/**
 * __useCreateUploadUrlMutation__
 *
 * To run a mutation, you first call `useCreateUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadUrlMutation, { data, loading, error }] = useCreateUploadUrlMutation({
 *   variables: {
 *      bucketName: // value for 'bucketName'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useCreateUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateUploadUrlMutation, CreateUploadUrlMutationVariables>) {
        return Apollo.useMutation<CreateUploadUrlMutation, CreateUploadUrlMutationVariables>(CreateUploadUrlDocument, baseOptions);
      }
export type CreateUploadUrlMutationHookResult = ReturnType<typeof useCreateUploadUrlMutation>;
export type CreateUploadUrlMutationResult = Apollo.MutationResult<CreateUploadUrlMutation>;
export type CreateUploadUrlMutationOptions = Apollo.BaseMutationOptions<CreateUploadUrlMutation, CreateUploadUrlMutationVariables>;
export const CreateDownloadUrlDocument = gql`
    mutation CreateDownloadURL($bucketName: String, $fileName: String) {
  CreateDownloadURL(input: {bucketName: $bucketName, fileName: $fileName}) {
    url
  }
}
    `;
export type CreateDownloadUrlMutationFn = Apollo.MutationFunction<CreateDownloadUrlMutation, CreateDownloadUrlMutationVariables>;

/**
 * __useCreateDownloadUrlMutation__
 *
 * To run a mutation, you first call `useCreateDownloadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDownloadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDownloadUrlMutation, { data, loading, error }] = useCreateDownloadUrlMutation({
 *   variables: {
 *      bucketName: // value for 'bucketName'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useCreateDownloadUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateDownloadUrlMutation, CreateDownloadUrlMutationVariables>) {
        return Apollo.useMutation<CreateDownloadUrlMutation, CreateDownloadUrlMutationVariables>(CreateDownloadUrlDocument, baseOptions);
      }
export type CreateDownloadUrlMutationHookResult = ReturnType<typeof useCreateDownloadUrlMutation>;
export type CreateDownloadUrlMutationResult = Apollo.MutationResult<CreateDownloadUrlMutation>;
export type CreateDownloadUrlMutationOptions = Apollo.BaseMutationOptions<CreateDownloadUrlMutation, CreateDownloadUrlMutationVariables>;
export const CreateWineDocument = gql`
    mutation CreateWine($input: CreateWineInput!) {
  CreateWine(input: $input)
}
    `;
export type CreateWineMutationFn = Apollo.MutationFunction<CreateWineMutation, CreateWineMutationVariables>;

/**
 * __useCreateWineMutation__
 *
 * To run a mutation, you first call `useCreateWineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWineMutation, { data, loading, error }] = useCreateWineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWineMutation(baseOptions?: Apollo.MutationHookOptions<CreateWineMutation, CreateWineMutationVariables>) {
        return Apollo.useMutation<CreateWineMutation, CreateWineMutationVariables>(CreateWineDocument, baseOptions);
      }
export type CreateWineMutationHookResult = ReturnType<typeof useCreateWineMutation>;
export type CreateWineMutationResult = Apollo.MutationResult<CreateWineMutation>;
export type CreateWineMutationOptions = Apollo.BaseMutationOptions<CreateWineMutation, CreateWineMutationVariables>;
export const RemoveCellarDocument = gql`
    mutation RemoveCellar($cellarId: ID!) {
  RemoveCellar(cellarId: $cellarId)
}
    `;
export type RemoveCellarMutationFn = Apollo.MutationFunction<RemoveCellarMutation, RemoveCellarMutationVariables>;

/**
 * __useRemoveCellarMutation__
 *
 * To run a mutation, you first call `useRemoveCellarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCellarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCellarMutation, { data, loading, error }] = useRemoveCellarMutation({
 *   variables: {
 *      cellarId: // value for 'cellarId'
 *   },
 * });
 */
export function useRemoveCellarMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCellarMutation, RemoveCellarMutationVariables>) {
        return Apollo.useMutation<RemoveCellarMutation, RemoveCellarMutationVariables>(RemoveCellarDocument, baseOptions);
      }
export type RemoveCellarMutationHookResult = ReturnType<typeof useRemoveCellarMutation>;
export type RemoveCellarMutationResult = Apollo.MutationResult<RemoveCellarMutation>;
export type RemoveCellarMutationOptions = Apollo.BaseMutationOptions<RemoveCellarMutation, RemoveCellarMutationVariables>;
export const RemoveCertificationDocument = gql`
    mutation RemoveCertification($certificationId: ID!) {
  RemoveCertification(certificationId: $certificationId)
}
    `;
export type RemoveCertificationMutationFn = Apollo.MutationFunction<RemoveCertificationMutation, RemoveCertificationMutationVariables>;

/**
 * __useRemoveCertificationMutation__
 *
 * To run a mutation, you first call `useRemoveCertificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCertificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCertificationMutation, { data, loading, error }] = useRemoveCertificationMutation({
 *   variables: {
 *      certificationId: // value for 'certificationId'
 *   },
 * });
 */
export function useRemoveCertificationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCertificationMutation, RemoveCertificationMutationVariables>) {
        return Apollo.useMutation<RemoveCertificationMutation, RemoveCertificationMutationVariables>(RemoveCertificationDocument, baseOptions);
      }
export type RemoveCertificationMutationHookResult = ReturnType<typeof useRemoveCertificationMutation>;
export type RemoveCertificationMutationResult = Apollo.MutationResult<RemoveCertificationMutation>;
export type RemoveCertificationMutationOptions = Apollo.BaseMutationOptions<RemoveCertificationMutation, RemoveCertificationMutationVariables>;
export const ParseCviDocument = gql`
    mutation ParseCVI($input: JSON!, $wineEntityId: ID!) {
  ParseCVI(input: $input, wineEntityId: $wineEntityId)
}
    `;
export type ParseCviMutationFn = Apollo.MutationFunction<ParseCviMutation, ParseCviMutationVariables>;

/**
 * __useParseCviMutation__
 *
 * To run a mutation, you first call `useParseCviMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParseCviMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parseCviMutation, { data, loading, error }] = useParseCviMutation({
 *   variables: {
 *      input: // value for 'input'
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useParseCviMutation(baseOptions?: Apollo.MutationHookOptions<ParseCviMutation, ParseCviMutationVariables>) {
        return Apollo.useMutation<ParseCviMutation, ParseCviMutationVariables>(ParseCviDocument, baseOptions);
      }
export type ParseCviMutationHookResult = ReturnType<typeof useParseCviMutation>;
export type ParseCviMutationResult = Apollo.MutationResult<ParseCviMutation>;
export type ParseCviMutationOptions = Apollo.BaseMutationOptions<ParseCviMutation, ParseCviMutationVariables>;
export const DeleteAllSurfacesDocument = gql`
    mutation deleteAllSurfaces($id: ID!) {
  deleteAllSurfaces(wineEntityId: $id)
}
    `;
export type DeleteAllSurfacesMutationFn = Apollo.MutationFunction<DeleteAllSurfacesMutation, DeleteAllSurfacesMutationVariables>;

/**
 * __useDeleteAllSurfacesMutation__
 *
 * To run a mutation, you first call `useDeleteAllSurfacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllSurfacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllSurfacesMutation, { data, loading, error }] = useDeleteAllSurfacesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAllSurfacesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAllSurfacesMutation, DeleteAllSurfacesMutationVariables>) {
        return Apollo.useMutation<DeleteAllSurfacesMutation, DeleteAllSurfacesMutationVariables>(DeleteAllSurfacesDocument, baseOptions);
      }
export type DeleteAllSurfacesMutationHookResult = ReturnType<typeof useDeleteAllSurfacesMutation>;
export type DeleteAllSurfacesMutationResult = Apollo.MutationResult<DeleteAllSurfacesMutation>;
export type DeleteAllSurfacesMutationOptions = Apollo.BaseMutationOptions<DeleteAllSurfacesMutation, DeleteAllSurfacesMutationVariables>;
export const AddTeamMemberDocument = gql`
    mutation AddTeamMember($input: AddTeamMemberInput!, $wineEntityId: ID!) {
  AddTeamMember(input: $input, wineEntityId: $wineEntityId)
}
    `;
export type AddTeamMemberMutationFn = Apollo.MutationFunction<AddTeamMemberMutation, AddTeamMemberMutationVariables>;

/**
 * __useAddTeamMemberMutation__
 *
 * To run a mutation, you first call `useAddTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTeamMemberMutation, { data, loading, error }] = useAddTeamMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useAddTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<AddTeamMemberMutation, AddTeamMemberMutationVariables>) {
        return Apollo.useMutation<AddTeamMemberMutation, AddTeamMemberMutationVariables>(AddTeamMemberDocument, baseOptions);
      }
export type AddTeamMemberMutationHookResult = ReturnType<typeof useAddTeamMemberMutation>;
export type AddTeamMemberMutationResult = Apollo.MutationResult<AddTeamMemberMutation>;
export type AddTeamMemberMutationOptions = Apollo.BaseMutationOptions<AddTeamMemberMutation, AddTeamMemberMutationVariables>;
export const EditTeamMemberDocument = gql`
    mutation EditTeamMember($wineEntityId: ID!, $personId: ID!, $input: EditTeamMemberInput!) {
  EditTeamMember(wineEntityId: $wineEntityId, personId: $personId, input: $input)
}
    `;
export type EditTeamMemberMutationFn = Apollo.MutationFunction<EditTeamMemberMutation, EditTeamMemberMutationVariables>;

/**
 * __useEditTeamMemberMutation__
 *
 * To run a mutation, you first call `useEditTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTeamMemberMutation, { data, loading, error }] = useEditTeamMemberMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      personId: // value for 'personId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<EditTeamMemberMutation, EditTeamMemberMutationVariables>) {
        return Apollo.useMutation<EditTeamMemberMutation, EditTeamMemberMutationVariables>(EditTeamMemberDocument, baseOptions);
      }
export type EditTeamMemberMutationHookResult = ReturnType<typeof useEditTeamMemberMutation>;
export type EditTeamMemberMutationResult = Apollo.MutationResult<EditTeamMemberMutation>;
export type EditTeamMemberMutationOptions = Apollo.BaseMutationOptions<EditTeamMemberMutation, EditTeamMemberMutationVariables>;
export const DeleteTeamMemberDocument = gql`
    mutation DeleteTeamMember($wineEntityId: ID!, $personId: ID!) {
  DeleteTeamMember(wineEntityId: $wineEntityId, personId: $personId)
}
    `;
export type DeleteTeamMemberMutationFn = Apollo.MutationFunction<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>;

/**
 * __useDeleteTeamMemberMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMemberMutation, { data, loading, error }] = useDeleteTeamMemberMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useDeleteTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>) {
        return Apollo.useMutation<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>(DeleteTeamMemberDocument, baseOptions);
      }
export type DeleteTeamMemberMutationHookResult = ReturnType<typeof useDeleteTeamMemberMutation>;
export type DeleteTeamMemberMutationResult = Apollo.MutationResult<DeleteTeamMemberMutation>;
export type DeleteTeamMemberMutationOptions = Apollo.BaseMutationOptions<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>;
export const ProprieteVignobleScreenMutationDocument = gql`
    mutation ProprieteVignobleScreenMutation($wineEntityId: ID!, $input: ProprieteVignobleScreenInput!) {
  ProprieteVignobleScreen(wineEntityId: $wineEntityId, input: $input)
}
    `;
export type ProprieteVignobleScreenMutationMutationFn = Apollo.MutationFunction<ProprieteVignobleScreenMutationMutation, ProprieteVignobleScreenMutationMutationVariables>;

/**
 * __useProprieteVignobleScreenMutationMutation__
 *
 * To run a mutation, you first call `useProprieteVignobleScreenMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProprieteVignobleScreenMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [proprieteVignobleScreenMutationMutation, { data, loading, error }] = useProprieteVignobleScreenMutationMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProprieteVignobleScreenMutationMutation(baseOptions?: Apollo.MutationHookOptions<ProprieteVignobleScreenMutationMutation, ProprieteVignobleScreenMutationMutationVariables>) {
        return Apollo.useMutation<ProprieteVignobleScreenMutationMutation, ProprieteVignobleScreenMutationMutationVariables>(ProprieteVignobleScreenMutationDocument, baseOptions);
      }
export type ProprieteVignobleScreenMutationMutationHookResult = ReturnType<typeof useProprieteVignobleScreenMutationMutation>;
export type ProprieteVignobleScreenMutationMutationResult = Apollo.MutationResult<ProprieteVignobleScreenMutationMutation>;
export type ProprieteVignobleScreenMutationMutationOptions = Apollo.BaseMutationOptions<ProprieteVignobleScreenMutationMutation, ProprieteVignobleScreenMutationMutationVariables>;
export const EditTeamGeneralDocument = gql`
    mutation EditTeamGeneral($wineEntityId: ID!, $input: EditTeamGeneralInput!) {
  EditTeamGeneral(wineEntityId: $wineEntityId, input: $input)
}
    `;
export type EditTeamGeneralMutationFn = Apollo.MutationFunction<EditTeamGeneralMutation, EditTeamGeneralMutationVariables>;

/**
 * __useEditTeamGeneralMutation__
 *
 * To run a mutation, you first call `useEditTeamGeneralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTeamGeneralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTeamGeneralMutation, { data, loading, error }] = useEditTeamGeneralMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTeamGeneralMutation(baseOptions?: Apollo.MutationHookOptions<EditTeamGeneralMutation, EditTeamGeneralMutationVariables>) {
        return Apollo.useMutation<EditTeamGeneralMutation, EditTeamGeneralMutationVariables>(EditTeamGeneralDocument, baseOptions);
      }
export type EditTeamGeneralMutationHookResult = ReturnType<typeof useEditTeamGeneralMutation>;
export type EditTeamGeneralMutationResult = Apollo.MutationResult<EditTeamGeneralMutation>;
export type EditTeamGeneralMutationOptions = Apollo.BaseMutationOptions<EditTeamGeneralMutation, EditTeamGeneralMutationVariables>;
export const EditGeneralInformationScreenDocument = gql`
    mutation EditGeneralInformationScreen($wineEntityId: ID!, $input: GeneralInformationScreenInput!) {
  GeneralInformationScreen(wineEntityId: $wineEntityId, input: $input)
}
    `;
export type EditGeneralInformationScreenMutationFn = Apollo.MutationFunction<EditGeneralInformationScreenMutation, EditGeneralInformationScreenMutationVariables>;

/**
 * __useEditGeneralInformationScreenMutation__
 *
 * To run a mutation, you first call `useEditGeneralInformationScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGeneralInformationScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGeneralInformationScreenMutation, { data, loading, error }] = useEditGeneralInformationScreenMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditGeneralInformationScreenMutation(baseOptions?: Apollo.MutationHookOptions<EditGeneralInformationScreenMutation, EditGeneralInformationScreenMutationVariables>) {
        return Apollo.useMutation<EditGeneralInformationScreenMutation, EditGeneralInformationScreenMutationVariables>(EditGeneralInformationScreenDocument, baseOptions);
      }
export type EditGeneralInformationScreenMutationHookResult = ReturnType<typeof useEditGeneralInformationScreenMutation>;
export type EditGeneralInformationScreenMutationResult = Apollo.MutationResult<EditGeneralInformationScreenMutation>;
export type EditGeneralInformationScreenMutationOptions = Apollo.BaseMutationOptions<EditGeneralInformationScreenMutation, EditGeneralInformationScreenMutationVariables>;
export const EditVintageViticultureScreenDocument = gql`
    mutation EditVintageViticultureScreen($wineEntityId: ID!, $vintageId: ID!, $input: VintageViticultureScreenInput!) {
  EditVintageViticultureScreen(
    vintageId: $vintageId
    wineEntityId: $wineEntityId
    input: $input
  )
}
    `;
export type EditVintageViticultureScreenMutationFn = Apollo.MutationFunction<EditVintageViticultureScreenMutation, EditVintageViticultureScreenMutationVariables>;

/**
 * __useEditVintageViticultureScreenMutation__
 *
 * To run a mutation, you first call `useEditVintageViticultureScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVintageViticultureScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVintageViticultureScreenMutation, { data, loading, error }] = useEditVintageViticultureScreenMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      vintageId: // value for 'vintageId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditVintageViticultureScreenMutation(baseOptions?: Apollo.MutationHookOptions<EditVintageViticultureScreenMutation, EditVintageViticultureScreenMutationVariables>) {
        return Apollo.useMutation<EditVintageViticultureScreenMutation, EditVintageViticultureScreenMutationVariables>(EditVintageViticultureScreenDocument, baseOptions);
      }
export type EditVintageViticultureScreenMutationHookResult = ReturnType<typeof useEditVintageViticultureScreenMutation>;
export type EditVintageViticultureScreenMutationResult = Apollo.MutationResult<EditVintageViticultureScreenMutation>;
export type EditVintageViticultureScreenMutationOptions = Apollo.BaseMutationOptions<EditVintageViticultureScreenMutation, EditVintageViticultureScreenMutationVariables>;
export const EditVintageWineMakingScreenDocument = gql`
    mutation EditVintageWineMakingScreen($wineEntityId: ID!, $vintageId: ID!, $input: VintageWineMakingScreenInput!) {
  EditVintageWineMakingScreen(
    vintageId: $vintageId
    wineEntityId: $wineEntityId
    input: $input
  )
}
    `;
export type EditVintageWineMakingScreenMutationFn = Apollo.MutationFunction<EditVintageWineMakingScreenMutation, EditVintageWineMakingScreenMutationVariables>;

/**
 * __useEditVintageWineMakingScreenMutation__
 *
 * To run a mutation, you first call `useEditVintageWineMakingScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVintageWineMakingScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVintageWineMakingScreenMutation, { data, loading, error }] = useEditVintageWineMakingScreenMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      vintageId: // value for 'vintageId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditVintageWineMakingScreenMutation(baseOptions?: Apollo.MutationHookOptions<EditVintageWineMakingScreenMutation, EditVintageWineMakingScreenMutationVariables>) {
        return Apollo.useMutation<EditVintageWineMakingScreenMutation, EditVintageWineMakingScreenMutationVariables>(EditVintageWineMakingScreenDocument, baseOptions);
      }
export type EditVintageWineMakingScreenMutationHookResult = ReturnType<typeof useEditVintageWineMakingScreenMutation>;
export type EditVintageWineMakingScreenMutationResult = Apollo.MutationResult<EditVintageWineMakingScreenMutation>;
export type EditVintageWineMakingScreenMutationOptions = Apollo.BaseMutationOptions<EditVintageWineMakingScreenMutation, EditVintageWineMakingScreenMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($input: SignUpInput!) {
  SignUp(input: $input) {
    user {
      _id
      email
      title
      firstName
      lastName
      telephoneNumber
      telephoneNumberCountry
    }
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, baseOptions);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const SignUpInviteDocument = gql`
    mutation SignUpInvite($input: SignUpInviteInput!) {
  SignUpInvite(input: $input) {
    user {
      _id
      email
      title
      firstName
      lastName
      telephoneNumber
      telephoneNumberCountry
    }
  }
}
    `;
export type SignUpInviteMutationFn = Apollo.MutationFunction<SignUpInviteMutation, SignUpInviteMutationVariables>;

/**
 * __useSignUpInviteMutation__
 *
 * To run a mutation, you first call `useSignUpInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpInviteMutation, { data, loading, error }] = useSignUpInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpInviteMutation(baseOptions?: Apollo.MutationHookOptions<SignUpInviteMutation, SignUpInviteMutationVariables>) {
        return Apollo.useMutation<SignUpInviteMutation, SignUpInviteMutationVariables>(SignUpInviteDocument, baseOptions);
      }
export type SignUpInviteMutationHookResult = ReturnType<typeof useSignUpInviteMutation>;
export type SignUpInviteMutationResult = Apollo.MutationResult<SignUpInviteMutation>;
export type SignUpInviteMutationOptions = Apollo.BaseMutationOptions<SignUpInviteMutation, SignUpInviteMutationVariables>;
export const SignInInviteDocument = gql`
    mutation SignInInvite($input: SignInInviteInput!) {
  SignInInvite(input: $input)
}
    `;
export type SignInInviteMutationFn = Apollo.MutationFunction<SignInInviteMutation, SignInInviteMutationVariables>;

/**
 * __useSignInInviteMutation__
 *
 * To run a mutation, you first call `useSignInInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInInviteMutation, { data, loading, error }] = useSignInInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInInviteMutation(baseOptions?: Apollo.MutationHookOptions<SignInInviteMutation, SignInInviteMutationVariables>) {
        return Apollo.useMutation<SignInInviteMutation, SignInInviteMutationVariables>(SignInInviteDocument, baseOptions);
      }
export type SignInInviteMutationHookResult = ReturnType<typeof useSignInInviteMutation>;
export type SignInInviteMutationResult = Apollo.MutationResult<SignInInviteMutation>;
export type SignInInviteMutationOptions = Apollo.BaseMutationOptions<SignInInviteMutation, SignInInviteMutationVariables>;
export const SignUpJournalistDocument = gql`
    mutation SignUpJournalist($input: SignUpJournalistInput!) {
  SignUpJournalist(input: $input) {
    user {
      _id
      email
      title
      firstName
      lastName
    }
  }
}
    `;
export type SignUpJournalistMutationFn = Apollo.MutationFunction<SignUpJournalistMutation, SignUpJournalistMutationVariables>;

/**
 * __useSignUpJournalistMutation__
 *
 * To run a mutation, you first call `useSignUpJournalistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpJournalistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpJournalistMutation, { data, loading, error }] = useSignUpJournalistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpJournalistMutation(baseOptions?: Apollo.MutationHookOptions<SignUpJournalistMutation, SignUpJournalistMutationVariables>) {
        return Apollo.useMutation<SignUpJournalistMutation, SignUpJournalistMutationVariables>(SignUpJournalistDocument, baseOptions);
      }
export type SignUpJournalistMutationHookResult = ReturnType<typeof useSignUpJournalistMutation>;
export type SignUpJournalistMutationResult = Apollo.MutationResult<SignUpJournalistMutation>;
export type SignUpJournalistMutationOptions = Apollo.BaseMutationOptions<SignUpJournalistMutation, SignUpJournalistMutationVariables>;
export const EditWinePresentationScreenDocument = gql`
    mutation EditWinePresentationScreen($wineEntityId: ID!, $wineId: ID!, $input: WinePresentationInput) {
  EditWinePresentationScreen(
    wineEntityId: $wineEntityId
    wineId: $wineId
    input: $input
  )
}
    `;
export type EditWinePresentationScreenMutationFn = Apollo.MutationFunction<EditWinePresentationScreenMutation, EditWinePresentationScreenMutationVariables>;

/**
 * __useEditWinePresentationScreenMutation__
 *
 * To run a mutation, you first call `useEditWinePresentationScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditWinePresentationScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editWinePresentationScreenMutation, { data, loading, error }] = useEditWinePresentationScreenMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      wineId: // value for 'wineId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditWinePresentationScreenMutation(baseOptions?: Apollo.MutationHookOptions<EditWinePresentationScreenMutation, EditWinePresentationScreenMutationVariables>) {
        return Apollo.useMutation<EditWinePresentationScreenMutation, EditWinePresentationScreenMutationVariables>(EditWinePresentationScreenDocument, baseOptions);
      }
export type EditWinePresentationScreenMutationHookResult = ReturnType<typeof useEditWinePresentationScreenMutation>;
export type EditWinePresentationScreenMutationResult = Apollo.MutationResult<EditWinePresentationScreenMutation>;
export type EditWinePresentationScreenMutationOptions = Apollo.BaseMutationOptions<EditWinePresentationScreenMutation, EditWinePresentationScreenMutationVariables>;
export const EditVintageAgeingScreenDocument = gql`
    mutation EditVintageAgeingScreen($wineEntityId: ID!, $vintageId: ID!, $input: VintageAgeingScreenInput!) {
  EditVintageAgeingScreen(
    vintageId: $vintageId
    wineEntityId: $wineEntityId
    input: $input
  )
}
    `;
export type EditVintageAgeingScreenMutationFn = Apollo.MutationFunction<EditVintageAgeingScreenMutation, EditVintageAgeingScreenMutationVariables>;

/**
 * __useEditVintageAgeingScreenMutation__
 *
 * To run a mutation, you first call `useEditVintageAgeingScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVintageAgeingScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVintageAgeingScreenMutation, { data, loading, error }] = useEditVintageAgeingScreenMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      vintageId: // value for 'vintageId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditVintageAgeingScreenMutation(baseOptions?: Apollo.MutationHookOptions<EditVintageAgeingScreenMutation, EditVintageAgeingScreenMutationVariables>) {
        return Apollo.useMutation<EditVintageAgeingScreenMutation, EditVintageAgeingScreenMutationVariables>(EditVintageAgeingScreenDocument, baseOptions);
      }
export type EditVintageAgeingScreenMutationHookResult = ReturnType<typeof useEditVintageAgeingScreenMutation>;
export type EditVintageAgeingScreenMutationResult = Apollo.MutationResult<EditVintageAgeingScreenMutation>;
export type EditVintageAgeingScreenMutationOptions = Apollo.BaseMutationOptions<EditVintageAgeingScreenMutation, EditVintageAgeingScreenMutationVariables>;
export const EditVintageProductionScreenDocument = gql`
    mutation EditVintageProductionScreen($wineEntityId: ID!, $vintageId: ID!, $input: VintageProductionScreenInput!) {
  EditVintageProductionScreen(
    vintageId: $vintageId
    wineEntityId: $wineEntityId
    input: $input
  )
}
    `;
export type EditVintageProductionScreenMutationFn = Apollo.MutationFunction<EditVintageProductionScreenMutation, EditVintageProductionScreenMutationVariables>;

/**
 * __useEditVintageProductionScreenMutation__
 *
 * To run a mutation, you first call `useEditVintageProductionScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVintageProductionScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVintageProductionScreenMutation, { data, loading, error }] = useEditVintageProductionScreenMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      vintageId: // value for 'vintageId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditVintageProductionScreenMutation(baseOptions?: Apollo.MutationHookOptions<EditVintageProductionScreenMutation, EditVintageProductionScreenMutationVariables>) {
        return Apollo.useMutation<EditVintageProductionScreenMutation, EditVintageProductionScreenMutationVariables>(EditVintageProductionScreenDocument, baseOptions);
      }
export type EditVintageProductionScreenMutationHookResult = ReturnType<typeof useEditVintageProductionScreenMutation>;
export type EditVintageProductionScreenMutationResult = Apollo.MutationResult<EditVintageProductionScreenMutation>;
export type EditVintageProductionScreenMutationOptions = Apollo.BaseMutationOptions<EditVintageProductionScreenMutation, EditVintageProductionScreenMutationVariables>;
export const EditVintageNutritionScreenDocument = gql`
    mutation EditVintageNutritionScreen($wineEntityId: ID!, $vintageId: ID!, $input: VintageNutritionScreenInput!) {
  EditVintageNutritionScreen(
    vintageId: $vintageId
    wineEntityId: $wineEntityId
    input: $input
  )
}
    `;
export type EditVintageNutritionScreenMutationFn = Apollo.MutationFunction<EditVintageNutritionScreenMutation, EditVintageNutritionScreenMutationVariables>;

/**
 * __useEditVintageNutritionScreenMutation__
 *
 * To run a mutation, you first call `useEditVintageNutritionScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVintageNutritionScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVintageNutritionScreenMutation, { data, loading, error }] = useEditVintageNutritionScreenMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      vintageId: // value for 'vintageId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditVintageNutritionScreenMutation(baseOptions?: Apollo.MutationHookOptions<EditVintageNutritionScreenMutation, EditVintageNutritionScreenMutationVariables>) {
        return Apollo.useMutation<EditVintageNutritionScreenMutation, EditVintageNutritionScreenMutationVariables>(EditVintageNutritionScreenDocument, baseOptions);
      }
export type EditVintageNutritionScreenMutationHookResult = ReturnType<typeof useEditVintageNutritionScreenMutation>;
export type EditVintageNutritionScreenMutationResult = Apollo.MutationResult<EditVintageNutritionScreenMutation>;
export type EditVintageNutritionScreenMutationOptions = Apollo.BaseMutationOptions<EditVintageNutritionScreenMutation, EditVintageNutritionScreenMutationVariables>;
export const EditVintageMarketingScreenDocument = gql`
    mutation EditVintageMarketingScreen($wineEntityId: ID!, $vintageId: ID!, $input: VintageMarketingScreenInput!) {
  EditVintageMarketingScreen(
    vintageId: $vintageId
    wineEntityId: $wineEntityId
    input: $input
  )
}
    `;
export type EditVintageMarketingScreenMutationFn = Apollo.MutationFunction<EditVintageMarketingScreenMutation, EditVintageMarketingScreenMutationVariables>;

/**
 * __useEditVintageMarketingScreenMutation__
 *
 * To run a mutation, you first call `useEditVintageMarketingScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVintageMarketingScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVintageMarketingScreenMutation, { data, loading, error }] = useEditVintageMarketingScreenMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      vintageId: // value for 'vintageId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditVintageMarketingScreenMutation(baseOptions?: Apollo.MutationHookOptions<EditVintageMarketingScreenMutation, EditVintageMarketingScreenMutationVariables>) {
        return Apollo.useMutation<EditVintageMarketingScreenMutation, EditVintageMarketingScreenMutationVariables>(EditVintageMarketingScreenDocument, baseOptions);
      }
export type EditVintageMarketingScreenMutationHookResult = ReturnType<typeof useEditVintageMarketingScreenMutation>;
export type EditVintageMarketingScreenMutationResult = Apollo.MutationResult<EditVintageMarketingScreenMutation>;
export type EditVintageMarketingScreenMutationOptions = Apollo.BaseMutationOptions<EditVintageMarketingScreenMutation, EditVintageMarketingScreenMutationVariables>;
export const EditVintageAdviceScreenDocument = gql`
    mutation EditVintageAdviceScreen($wineEntityId: ID!, $vintageId: ID!, $input: VintageAdviceScreenInput!) {
  EditVintageAdviceScreen(
    vintageId: $vintageId
    wineEntityId: $wineEntityId
    input: $input
  )
}
    `;
export type EditVintageAdviceScreenMutationFn = Apollo.MutationFunction<EditVintageAdviceScreenMutation, EditVintageAdviceScreenMutationVariables>;

/**
 * __useEditVintageAdviceScreenMutation__
 *
 * To run a mutation, you first call `useEditVintageAdviceScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVintageAdviceScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVintageAdviceScreenMutation, { data, loading, error }] = useEditVintageAdviceScreenMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      vintageId: // value for 'vintageId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditVintageAdviceScreenMutation(baseOptions?: Apollo.MutationHookOptions<EditVintageAdviceScreenMutation, EditVintageAdviceScreenMutationVariables>) {
        return Apollo.useMutation<EditVintageAdviceScreenMutation, EditVintageAdviceScreenMutationVariables>(EditVintageAdviceScreenDocument, baseOptions);
      }
export type EditVintageAdviceScreenMutationHookResult = ReturnType<typeof useEditVintageAdviceScreenMutation>;
export type EditVintageAdviceScreenMutationResult = Apollo.MutationResult<EditVintageAdviceScreenMutation>;
export type EditVintageAdviceScreenMutationOptions = Apollo.BaseMutationOptions<EditVintageAdviceScreenMutation, EditVintageAdviceScreenMutationVariables>;
export const EditVintageAdviceUrlDocument = gql`
    mutation EditVintageAdviceUrl($wineEntityId: ID!, $vintageId: ID!, $videoUrl: String!) {
  EditVintageAdviceUrl(
    vintageId: $vintageId
    wineEntityId: $wineEntityId
    videoUrl: $videoUrl
  )
}
    `;
export type EditVintageAdviceUrlMutationFn = Apollo.MutationFunction<EditVintageAdviceUrlMutation, EditVintageAdviceUrlMutationVariables>;

/**
 * __useEditVintageAdviceUrlMutation__
 *
 * To run a mutation, you first call `useEditVintageAdviceUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVintageAdviceUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVintageAdviceUrlMutation, { data, loading, error }] = useEditVintageAdviceUrlMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      vintageId: // value for 'vintageId'
 *      videoUrl: // value for 'videoUrl'
 *   },
 * });
 */
export function useEditVintageAdviceUrlMutation(baseOptions?: Apollo.MutationHookOptions<EditVintageAdviceUrlMutation, EditVintageAdviceUrlMutationVariables>) {
        return Apollo.useMutation<EditVintageAdviceUrlMutation, EditVintageAdviceUrlMutationVariables>(EditVintageAdviceUrlDocument, baseOptions);
      }
export type EditVintageAdviceUrlMutationHookResult = ReturnType<typeof useEditVintageAdviceUrlMutation>;
export type EditVintageAdviceUrlMutationResult = Apollo.MutationResult<EditVintageAdviceUrlMutation>;
export type EditVintageAdviceUrlMutationOptions = Apollo.BaseMutationOptions<EditVintageAdviceUrlMutation, EditVintageAdviceUrlMutationVariables>;
export const EditSurfaceManualDocument = gql`
    mutation EditSurfaceManual($wineEntityId: ID!, $input: SurfaceManualInput!) {
  EditSurfaceManual(wineEntityId: $wineEntityId, input: $input)
}
    `;
export type EditSurfaceManualMutationFn = Apollo.MutationFunction<EditSurfaceManualMutation, EditSurfaceManualMutationVariables>;

/**
 * __useEditSurfaceManualMutation__
 *
 * To run a mutation, you first call `useEditSurfaceManualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSurfaceManualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSurfaceManualMutation, { data, loading, error }] = useEditSurfaceManualMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSurfaceManualMutation(baseOptions?: Apollo.MutationHookOptions<EditSurfaceManualMutation, EditSurfaceManualMutationVariables>) {
        return Apollo.useMutation<EditSurfaceManualMutation, EditSurfaceManualMutationVariables>(EditSurfaceManualDocument, baseOptions);
      }
export type EditSurfaceManualMutationHookResult = ReturnType<typeof useEditSurfaceManualMutation>;
export type EditSurfaceManualMutationResult = Apollo.MutationResult<EditSurfaceManualMutation>;
export type EditSurfaceManualMutationOptions = Apollo.BaseMutationOptions<EditSurfaceManualMutation, EditSurfaceManualMutationVariables>;
export const EditEstateDocument = gql`
    mutation EditEstate($wineEntityId: ID!, $input: EditEstateInput!) {
  EditEstate(wineEntityId: $wineEntityId, input: $input)
}
    `;
export type EditEstateMutationFn = Apollo.MutationFunction<EditEstateMutation, EditEstateMutationVariables>;

/**
 * __useEditEstateMutation__
 *
 * To run a mutation, you first call `useEditEstateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEstateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEstateMutation, { data, loading, error }] = useEditEstateMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditEstateMutation(baseOptions?: Apollo.MutationHookOptions<EditEstateMutation, EditEstateMutationVariables>) {
        return Apollo.useMutation<EditEstateMutation, EditEstateMutationVariables>(EditEstateDocument, baseOptions);
      }
export type EditEstateMutationHookResult = ReturnType<typeof useEditEstateMutation>;
export type EditEstateMutationResult = Apollo.MutationResult<EditEstateMutation>;
export type EditEstateMutationOptions = Apollo.BaseMutationOptions<EditEstateMutation, EditEstateMutationVariables>;
export const EditVintageIdentityDocument = gql`
    mutation EditVintageIdentity($wineEntityId: ID!, $wineId: ID!, $vintageId: ID!, $input: VintageIdentityInput!) {
  EditVintageIdentity(
    wineEntityId: $wineEntityId
    wineId: $wineId
    vintageId: $vintageId
    input: $input
  )
}
    `;
export type EditVintageIdentityMutationFn = Apollo.MutationFunction<EditVintageIdentityMutation, EditVintageIdentityMutationVariables>;

/**
 * __useEditVintageIdentityMutation__
 *
 * To run a mutation, you first call `useEditVintageIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVintageIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVintageIdentityMutation, { data, loading, error }] = useEditVintageIdentityMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      wineId: // value for 'wineId'
 *      vintageId: // value for 'vintageId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditVintageIdentityMutation(baseOptions?: Apollo.MutationHookOptions<EditVintageIdentityMutation, EditVintageIdentityMutationVariables>) {
        return Apollo.useMutation<EditVintageIdentityMutation, EditVintageIdentityMutationVariables>(EditVintageIdentityDocument, baseOptions);
      }
export type EditVintageIdentityMutationHookResult = ReturnType<typeof useEditVintageIdentityMutation>;
export type EditVintageIdentityMutationResult = Apollo.MutationResult<EditVintageIdentityMutation>;
export type EditVintageIdentityMutationOptions = Apollo.BaseMutationOptions<EditVintageIdentityMutation, EditVintageIdentityMutationVariables>;
export const EditEstateComplementaryInfoDocument = gql`
    mutation EditEstateComplementaryInfo($wineEntityId: ID!, $input: EstateComplementaryInfoInput!) {
  EditEstateComplementaryInfo(wineEntityId: $wineEntityId, input: $input)
}
    `;
export type EditEstateComplementaryInfoMutationFn = Apollo.MutationFunction<EditEstateComplementaryInfoMutation, EditEstateComplementaryInfoMutationVariables>;

/**
 * __useEditEstateComplementaryInfoMutation__
 *
 * To run a mutation, you first call `useEditEstateComplementaryInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEstateComplementaryInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEstateComplementaryInfoMutation, { data, loading, error }] = useEditEstateComplementaryInfoMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditEstateComplementaryInfoMutation(baseOptions?: Apollo.MutationHookOptions<EditEstateComplementaryInfoMutation, EditEstateComplementaryInfoMutationVariables>) {
        return Apollo.useMutation<EditEstateComplementaryInfoMutation, EditEstateComplementaryInfoMutationVariables>(EditEstateComplementaryInfoDocument, baseOptions);
      }
export type EditEstateComplementaryInfoMutationHookResult = ReturnType<typeof useEditEstateComplementaryInfoMutation>;
export type EditEstateComplementaryInfoMutationResult = Apollo.MutationResult<EditEstateComplementaryInfoMutation>;
export type EditEstateComplementaryInfoMutationOptions = Apollo.BaseMutationOptions<EditEstateComplementaryInfoMutation, EditEstateComplementaryInfoMutationVariables>;
export const AddCellarDocument = gql`
    mutation AddCellar($wineEntityId: ID!, $input: AddCellarInput!) {
  AddCellar(wineEntityId: $wineEntityId, input: $input)
}
    `;
export type AddCellarMutationFn = Apollo.MutationFunction<AddCellarMutation, AddCellarMutationVariables>;

/**
 * __useAddCellarMutation__
 *
 * To run a mutation, you first call `useAddCellarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCellarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCellarMutation, { data, loading, error }] = useAddCellarMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCellarMutation(baseOptions?: Apollo.MutationHookOptions<AddCellarMutation, AddCellarMutationVariables>) {
        return Apollo.useMutation<AddCellarMutation, AddCellarMutationVariables>(AddCellarDocument, baseOptions);
      }
export type AddCellarMutationHookResult = ReturnType<typeof useAddCellarMutation>;
export type AddCellarMutationResult = Apollo.MutationResult<AddCellarMutation>;
export type AddCellarMutationOptions = Apollo.BaseMutationOptions<AddCellarMutation, AddCellarMutationVariables>;
export const EditCellarTabDocument = gql`
    mutation EditCellarTab($wineEntityId: ID!, $cellarId: ID!, $input: EditCellarTabInput!) {
  EditCellarTab(wineEntityId: $wineEntityId, cellarId: $cellarId, input: $input)
}
    `;
export type EditCellarTabMutationFn = Apollo.MutationFunction<EditCellarTabMutation, EditCellarTabMutationVariables>;

/**
 * __useEditCellarTabMutation__
 *
 * To run a mutation, you first call `useEditCellarTabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCellarTabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCellarTabMutation, { data, loading, error }] = useEditCellarTabMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      cellarId: // value for 'cellarId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCellarTabMutation(baseOptions?: Apollo.MutationHookOptions<EditCellarTabMutation, EditCellarTabMutationVariables>) {
        return Apollo.useMutation<EditCellarTabMutation, EditCellarTabMutationVariables>(EditCellarTabDocument, baseOptions);
      }
export type EditCellarTabMutationHookResult = ReturnType<typeof useEditCellarTabMutation>;
export type EditCellarTabMutationResult = Apollo.MutationResult<EditCellarTabMutation>;
export type EditCellarTabMutationOptions = Apollo.BaseMutationOptions<EditCellarTabMutation, EditCellarTabMutationVariables>;
export const AddVintageDocument = gql`
    mutation AddVintage($wineEntityId: ID!, $input: CreateVintageInput!) {
  CreateVintage(wineEntityId: $wineEntityId, input: $input)
}
    `;
export type AddVintageMutationFn = Apollo.MutationFunction<AddVintageMutation, AddVintageMutationVariables>;

/**
 * __useAddVintageMutation__
 *
 * To run a mutation, you first call `useAddVintageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVintageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVintageMutation, { data, loading, error }] = useAddVintageMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVintageMutation(baseOptions?: Apollo.MutationHookOptions<AddVintageMutation, AddVintageMutationVariables>) {
        return Apollo.useMutation<AddVintageMutation, AddVintageMutationVariables>(AddVintageDocument, baseOptions);
      }
export type AddVintageMutationHookResult = ReturnType<typeof useAddVintageMutation>;
export type AddVintageMutationResult = Apollo.MutationResult<AddVintageMutation>;
export type AddVintageMutationOptions = Apollo.BaseMutationOptions<AddVintageMutation, AddVintageMutationVariables>;
export const EditCellarPencilDocument = gql`
    mutation EditCellarPencil($wineEntityId: ID!, $cellarId: ID!, $input: EditCellarPencilInput!) {
  EditCellarPencil(
    wineEntityId: $wineEntityId
    cellarId: $cellarId
    input: $input
  )
}
    `;
export type EditCellarPencilMutationFn = Apollo.MutationFunction<EditCellarPencilMutation, EditCellarPencilMutationVariables>;

/**
 * __useEditCellarPencilMutation__
 *
 * To run a mutation, you first call `useEditCellarPencilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCellarPencilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCellarPencilMutation, { data, loading, error }] = useEditCellarPencilMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      cellarId: // value for 'cellarId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCellarPencilMutation(baseOptions?: Apollo.MutationHookOptions<EditCellarPencilMutation, EditCellarPencilMutationVariables>) {
        return Apollo.useMutation<EditCellarPencilMutation, EditCellarPencilMutationVariables>(EditCellarPencilDocument, baseOptions);
      }
export type EditCellarPencilMutationHookResult = ReturnType<typeof useEditCellarPencilMutation>;
export type EditCellarPencilMutationResult = Apollo.MutationResult<EditCellarPencilMutation>;
export type EditCellarPencilMutationOptions = Apollo.BaseMutationOptions<EditCellarPencilMutation, EditCellarPencilMutationVariables>;
export const CreateLabelBocDocument = gql`
    mutation CreateLabelBoc($wineEntityId: ID!, $wineId: ID, $vintageId: ID, $input: CreateLabelBocInput!) {
  CreateLabelBoc(
    wineEntityId: $wineEntityId
    wineId: $wineId
    vintageId: $vintageId
    input: $input
  )
}
    `;
export type CreateLabelBocMutationFn = Apollo.MutationFunction<CreateLabelBocMutation, CreateLabelBocMutationVariables>;

/**
 * __useCreateLabelBocMutation__
 *
 * To run a mutation, you first call `useCreateLabelBocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLabelBocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLabelBocMutation, { data, loading, error }] = useCreateLabelBocMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      wineId: // value for 'wineId'
 *      vintageId: // value for 'vintageId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLabelBocMutation(baseOptions?: Apollo.MutationHookOptions<CreateLabelBocMutation, CreateLabelBocMutationVariables>) {
        return Apollo.useMutation<CreateLabelBocMutation, CreateLabelBocMutationVariables>(CreateLabelBocDocument, baseOptions);
      }
export type CreateLabelBocMutationHookResult = ReturnType<typeof useCreateLabelBocMutation>;
export type CreateLabelBocMutationResult = Apollo.MutationResult<CreateLabelBocMutation>;
export type CreateLabelBocMutationOptions = Apollo.BaseMutationOptions<CreateLabelBocMutation, CreateLabelBocMutationVariables>;
export const ModifyLabelBocDocument = gql`
    mutation ModifyLabelBoc($wineEntityId: ID!, $certificationId: ID!, $input: UpdateCertificationInput!) {
  ModifyLabelBoc(
    wineEntityId: $wineEntityId
    certificationId: $certificationId
    input: $input
  )
}
    `;
export type ModifyLabelBocMutationFn = Apollo.MutationFunction<ModifyLabelBocMutation, ModifyLabelBocMutationVariables>;

/**
 * __useModifyLabelBocMutation__
 *
 * To run a mutation, you first call `useModifyLabelBocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyLabelBocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyLabelBocMutation, { data, loading, error }] = useModifyLabelBocMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      certificationId: // value for 'certificationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyLabelBocMutation(baseOptions?: Apollo.MutationHookOptions<ModifyLabelBocMutation, ModifyLabelBocMutationVariables>) {
        return Apollo.useMutation<ModifyLabelBocMutation, ModifyLabelBocMutationVariables>(ModifyLabelBocDocument, baseOptions);
      }
export type ModifyLabelBocMutationHookResult = ReturnType<typeof useModifyLabelBocMutation>;
export type ModifyLabelBocMutationResult = Apollo.MutationResult<ModifyLabelBocMutation>;
export type ModifyLabelBocMutationOptions = Apollo.BaseMutationOptions<ModifyLabelBocMutation, ModifyLabelBocMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($email: String!, $oldPassword: String!, $newPassword: String!) {
  ChangePassword(
    email: $email
    oldPassword: $oldPassword
    newPassword: $newPassword
  )
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const EditAccountScreenDocument = gql`
    mutation EditAccountScreen($userId: ID!, $input: EditAccountInput!) {
  EditAccountScreen(userId: $userId, input: $input)
}
    `;
export type EditAccountScreenMutationFn = Apollo.MutationFunction<EditAccountScreenMutation, EditAccountScreenMutationVariables>;

/**
 * __useEditAccountScreenMutation__
 *
 * To run a mutation, you first call `useEditAccountScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAccountScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAccountScreenMutation, { data, loading, error }] = useEditAccountScreenMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAccountScreenMutation(baseOptions?: Apollo.MutationHookOptions<EditAccountScreenMutation, EditAccountScreenMutationVariables>) {
        return Apollo.useMutation<EditAccountScreenMutation, EditAccountScreenMutationVariables>(EditAccountScreenDocument, baseOptions);
      }
export type EditAccountScreenMutationHookResult = ReturnType<typeof useEditAccountScreenMutation>;
export type EditAccountScreenMutationResult = Apollo.MutationResult<EditAccountScreenMutation>;
export type EditAccountScreenMutationOptions = Apollo.BaseMutationOptions<EditAccountScreenMutation, EditAccountScreenMutationVariables>;
export const EditActivityDocument = gql`
    mutation EditActivity($wineEntityId: ID!, $activityId: ID!, $input: EditActivityInput!) {
  EditActivity(
    wineEntityId: $wineEntityId
    activityId: $activityId
    input: $input
  )
}
    `;
export type EditActivityMutationFn = Apollo.MutationFunction<EditActivityMutation, EditActivityMutationVariables>;

/**
 * __useEditActivityMutation__
 *
 * To run a mutation, you first call `useEditActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editActivityMutation, { data, loading, error }] = useEditActivityMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      activityId: // value for 'activityId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditActivityMutation(baseOptions?: Apollo.MutationHookOptions<EditActivityMutation, EditActivityMutationVariables>) {
        return Apollo.useMutation<EditActivityMutation, EditActivityMutationVariables>(EditActivityDocument, baseOptions);
      }
export type EditActivityMutationHookResult = ReturnType<typeof useEditActivityMutation>;
export type EditActivityMutationResult = Apollo.MutationResult<EditActivityMutation>;
export type EditActivityMutationOptions = Apollo.BaseMutationOptions<EditActivityMutation, EditActivityMutationVariables>;
export const AddActivityDocument = gql`
    mutation AddActivity($wineEntityId: ID!, $input: AddActivityInput!) {
  AddActivity(wineEntityId: $wineEntityId, input: $input)
}
    `;
export type AddActivityMutationFn = Apollo.MutationFunction<AddActivityMutation, AddActivityMutationVariables>;

/**
 * __useAddActivityMutation__
 *
 * To run a mutation, you first call `useAddActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addActivityMutation, { data, loading, error }] = useAddActivityMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddActivityMutation(baseOptions?: Apollo.MutationHookOptions<AddActivityMutation, AddActivityMutationVariables>) {
        return Apollo.useMutation<AddActivityMutation, AddActivityMutationVariables>(AddActivityDocument, baseOptions);
      }
export type AddActivityMutationHookResult = ReturnType<typeof useAddActivityMutation>;
export type AddActivityMutationResult = Apollo.MutationResult<AddActivityMutation>;
export type AddActivityMutationOptions = Apollo.BaseMutationOptions<AddActivityMutation, AddActivityMutationVariables>;
export const RemoveActivityDocument = gql`
    mutation RemoveActivity($wineEntityId: ID!, $activityId: ID!) {
  RemoveActivity(wineEntityId: $wineEntityId, activityId: $activityId)
}
    `;
export type RemoveActivityMutationFn = Apollo.MutationFunction<RemoveActivityMutation, RemoveActivityMutationVariables>;

/**
 * __useRemoveActivityMutation__
 *
 * To run a mutation, you first call `useRemoveActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeActivityMutation, { data, loading, error }] = useRemoveActivityMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useRemoveActivityMutation(baseOptions?: Apollo.MutationHookOptions<RemoveActivityMutation, RemoveActivityMutationVariables>) {
        return Apollo.useMutation<RemoveActivityMutation, RemoveActivityMutationVariables>(RemoveActivityDocument, baseOptions);
      }
export type RemoveActivityMutationHookResult = ReturnType<typeof useRemoveActivityMutation>;
export type RemoveActivityMutationResult = Apollo.MutationResult<RemoveActivityMutation>;
export type RemoveActivityMutationOptions = Apollo.BaseMutationOptions<RemoveActivityMutation, RemoveActivityMutationVariables>;
export const RemoveScoreDocument = gql`
    mutation RemoveScore($scoreId: ID!) {
  RemoveScore(scoreId: $scoreId)
}
    `;
export type RemoveScoreMutationFn = Apollo.MutationFunction<RemoveScoreMutation, RemoveScoreMutationVariables>;

/**
 * __useRemoveScoreMutation__
 *
 * To run a mutation, you first call `useRemoveScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeScoreMutation, { data, loading, error }] = useRemoveScoreMutation({
 *   variables: {
 *      scoreId: // value for 'scoreId'
 *   },
 * });
 */
export function useRemoveScoreMutation(baseOptions?: Apollo.MutationHookOptions<RemoveScoreMutation, RemoveScoreMutationVariables>) {
        return Apollo.useMutation<RemoveScoreMutation, RemoveScoreMutationVariables>(RemoveScoreDocument, baseOptions);
      }
export type RemoveScoreMutationHookResult = ReturnType<typeof useRemoveScoreMutation>;
export type RemoveScoreMutationResult = Apollo.MutationResult<RemoveScoreMutation>;
export type RemoveScoreMutationOptions = Apollo.BaseMutationOptions<RemoveScoreMutation, RemoveScoreMutationVariables>;
export const AddQrcodeStatsDocument = gql`
    mutation AddQrcodeStats($input: AddQrcodeStatsInput!) {
  AddQrcodeStats(input: $input)
}
    `;
export type AddQrcodeStatsMutationFn = Apollo.MutationFunction<AddQrcodeStatsMutation, AddQrcodeStatsMutationVariables>;

/**
 * __useAddQrcodeStatsMutation__
 *
 * To run a mutation, you first call `useAddQrcodeStatsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQrcodeStatsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQrcodeStatsMutation, { data, loading, error }] = useAddQrcodeStatsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddQrcodeStatsMutation(baseOptions?: Apollo.MutationHookOptions<AddQrcodeStatsMutation, AddQrcodeStatsMutationVariables>) {
        return Apollo.useMutation<AddQrcodeStatsMutation, AddQrcodeStatsMutationVariables>(AddQrcodeStatsDocument, baseOptions);
      }
export type AddQrcodeStatsMutationHookResult = ReturnType<typeof useAddQrcodeStatsMutation>;
export type AddQrcodeStatsMutationResult = Apollo.MutationResult<AddQrcodeStatsMutation>;
export type AddQrcodeStatsMutationOptions = Apollo.BaseMutationOptions<AddQrcodeStatsMutation, AddQrcodeStatsMutationVariables>;
export const AddConnectionLogDocument = gql`
    mutation AddConnectionLog($input: AddConnectionLogInput!) {
  AddConnectionLog(input: $input)
}
    `;
export type AddConnectionLogMutationFn = Apollo.MutationFunction<AddConnectionLogMutation, AddConnectionLogMutationVariables>;

/**
 * __useAddConnectionLogMutation__
 *
 * To run a mutation, you first call `useAddConnectionLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddConnectionLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addConnectionLogMutation, { data, loading, error }] = useAddConnectionLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddConnectionLogMutation(baseOptions?: Apollo.MutationHookOptions<AddConnectionLogMutation, AddConnectionLogMutationVariables>) {
        return Apollo.useMutation<AddConnectionLogMutation, AddConnectionLogMutationVariables>(AddConnectionLogDocument, baseOptions);
      }
export type AddConnectionLogMutationHookResult = ReturnType<typeof useAddConnectionLogMutation>;
export type AddConnectionLogMutationResult = Apollo.MutationResult<AddConnectionLogMutation>;
export type AddConnectionLogMutationOptions = Apollo.BaseMutationOptions<AddConnectionLogMutation, AddConnectionLogMutationVariables>;
export const EditContactDocument = gql`
    mutation EditContact($wineEntityId: ID, $personId: ID, $type: String!, $input: EditContactInput!) {
  EditContact(
    wineEntityId: $wineEntityId
    personId: $personId
    type: $type
    input: $input
  )
}
    `;
export type EditContactMutationFn = Apollo.MutationFunction<EditContactMutation, EditContactMutationVariables>;

/**
 * __useEditContactMutation__
 *
 * To run a mutation, you first call `useEditContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editContactMutation, { data, loading, error }] = useEditContactMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      personId: // value for 'personId'
 *      type: // value for 'type'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditContactMutation(baseOptions?: Apollo.MutationHookOptions<EditContactMutation, EditContactMutationVariables>) {
        return Apollo.useMutation<EditContactMutation, EditContactMutationVariables>(EditContactDocument, baseOptions);
      }
export type EditContactMutationHookResult = ReturnType<typeof useEditContactMutation>;
export type EditContactMutationResult = Apollo.MutationResult<EditContactMutation>;
export type EditContactMutationOptions = Apollo.BaseMutationOptions<EditContactMutation, EditContactMutationVariables>;
export const EditHiddenSurfacesDocument = gql`
    mutation EditHiddenSurfaces($input: EditHiddenSurfacesInput!, $wineEntityId: ID!) {
  EditHiddenSurfaces(input: $input, wineEntityId: $wineEntityId)
}
    `;
export type EditHiddenSurfacesMutationFn = Apollo.MutationFunction<EditHiddenSurfacesMutation, EditHiddenSurfacesMutationVariables>;

/**
 * __useEditHiddenSurfacesMutation__
 *
 * To run a mutation, you first call `useEditHiddenSurfacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditHiddenSurfacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editHiddenSurfacesMutation, { data, loading, error }] = useEditHiddenSurfacesMutation({
 *   variables: {
 *      input: // value for 'input'
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useEditHiddenSurfacesMutation(baseOptions?: Apollo.MutationHookOptions<EditHiddenSurfacesMutation, EditHiddenSurfacesMutationVariables>) {
        return Apollo.useMutation<EditHiddenSurfacesMutation, EditHiddenSurfacesMutationVariables>(EditHiddenSurfacesDocument, baseOptions);
      }
export type EditHiddenSurfacesMutationHookResult = ReturnType<typeof useEditHiddenSurfacesMutation>;
export type EditHiddenSurfacesMutationResult = Apollo.MutationResult<EditHiddenSurfacesMutation>;
export type EditHiddenSurfacesMutationOptions = Apollo.BaseMutationOptions<EditHiddenSurfacesMutation, EditHiddenSurfacesMutationVariables>;
export const SetImportCviDocument = gql`
    mutation SetImportCVI($input: Boolean, $wineEntityId: ID!) {
  SetImportCVI(input: $input, wineEntityId: $wineEntityId)
}
    `;
export type SetImportCviMutationFn = Apollo.MutationFunction<SetImportCviMutation, SetImportCviMutationVariables>;

/**
 * __useSetImportCviMutation__
 *
 * To run a mutation, you first call `useSetImportCviMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetImportCviMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setImportCviMutation, { data, loading, error }] = useSetImportCviMutation({
 *   variables: {
 *      input: // value for 'input'
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useSetImportCviMutation(baseOptions?: Apollo.MutationHookOptions<SetImportCviMutation, SetImportCviMutationVariables>) {
        return Apollo.useMutation<SetImportCviMutation, SetImportCviMutationVariables>(SetImportCviDocument, baseOptions);
      }
export type SetImportCviMutationHookResult = ReturnType<typeof useSetImportCviMutation>;
export type SetImportCviMutationResult = Apollo.MutationResult<SetImportCviMutation>;
export type SetImportCviMutationOptions = Apollo.BaseMutationOptions<SetImportCviMutation, SetImportCviMutationVariables>;
export const CreateCertificationSuggestionDocument = gql`
    mutation CreateCertificationSuggestion($wineEntityId: ID!, $input: CreateCertificationSuggestionInput!) {
  CreateCertificationSuggestion(wineEntityId: $wineEntityId, input: $input)
}
    `;
export type CreateCertificationSuggestionMutationFn = Apollo.MutationFunction<CreateCertificationSuggestionMutation, CreateCertificationSuggestionMutationVariables>;

/**
 * __useCreateCertificationSuggestionMutation__
 *
 * To run a mutation, you first call `useCreateCertificationSuggestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCertificationSuggestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCertificationSuggestionMutation, { data, loading, error }] = useCreateCertificationSuggestionMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCertificationSuggestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCertificationSuggestionMutation, CreateCertificationSuggestionMutationVariables>) {
        return Apollo.useMutation<CreateCertificationSuggestionMutation, CreateCertificationSuggestionMutationVariables>(CreateCertificationSuggestionDocument, baseOptions);
      }
export type CreateCertificationSuggestionMutationHookResult = ReturnType<typeof useCreateCertificationSuggestionMutation>;
export type CreateCertificationSuggestionMutationResult = Apollo.MutationResult<CreateCertificationSuggestionMutation>;
export type CreateCertificationSuggestionMutationOptions = Apollo.BaseMutationOptions<CreateCertificationSuggestionMutation, CreateCertificationSuggestionMutationVariables>;
export const EditRewardsDocument = gql`
    mutation EditRewards($wineEntityId: ID!, $vintageId: ID!, $input: EditRewardsInput!) {
  EditRewards(wineEntityId: $wineEntityId, input: $input, vintageId: $vintageId)
}
    `;
export type EditRewardsMutationFn = Apollo.MutationFunction<EditRewardsMutation, EditRewardsMutationVariables>;

/**
 * __useEditRewardsMutation__
 *
 * To run a mutation, you first call `useEditRewardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRewardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRewardsMutation, { data, loading, error }] = useEditRewardsMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      vintageId: // value for 'vintageId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRewardsMutation(baseOptions?: Apollo.MutationHookOptions<EditRewardsMutation, EditRewardsMutationVariables>) {
        return Apollo.useMutation<EditRewardsMutation, EditRewardsMutationVariables>(EditRewardsDocument, baseOptions);
      }
export type EditRewardsMutationHookResult = ReturnType<typeof useEditRewardsMutation>;
export type EditRewardsMutationResult = Apollo.MutationResult<EditRewardsMutation>;
export type EditRewardsMutationOptions = Apollo.BaseMutationOptions<EditRewardsMutation, EditRewardsMutationVariables>;
export const MarkNotificationDocument = gql`
    mutation MarkNotification($notificationId: ID!, $isRead: Boolean) {
  MarkNotification(notificationId: $notificationId, isRead: $isRead)
}
    `;
export type MarkNotificationMutationFn = Apollo.MutationFunction<MarkNotificationMutation, MarkNotificationMutationVariables>;

/**
 * __useMarkNotificationMutation__
 *
 * To run a mutation, you first call `useMarkNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationMutation, { data, loading, error }] = useMarkNotificationMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *      isRead: // value for 'isRead'
 *   },
 * });
 */
export function useMarkNotificationMutation(baseOptions?: Apollo.MutationHookOptions<MarkNotificationMutation, MarkNotificationMutationVariables>) {
        return Apollo.useMutation<MarkNotificationMutation, MarkNotificationMutationVariables>(MarkNotificationDocument, baseOptions);
      }
export type MarkNotificationMutationHookResult = ReturnType<typeof useMarkNotificationMutation>;
export type MarkNotificationMutationResult = Apollo.MutationResult<MarkNotificationMutation>;
export type MarkNotificationMutationOptions = Apollo.BaseMutationOptions<MarkNotificationMutation, MarkNotificationMutationVariables>;
export const DeleteManyNotificationsDocument = gql`
    mutation DeleteManyNotifications($notificationIds: [ID]!) {
  DeleteManyNotifications(notificationIds: $notificationIds)
}
    `;
export type DeleteManyNotificationsMutationFn = Apollo.MutationFunction<DeleteManyNotificationsMutation, DeleteManyNotificationsMutationVariables>;

/**
 * __useDeleteManyNotificationsMutation__
 *
 * To run a mutation, you first call `useDeleteManyNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyNotificationsMutation, { data, loading, error }] = useDeleteManyNotificationsMutation({
 *   variables: {
 *      notificationIds: // value for 'notificationIds'
 *   },
 * });
 */
export function useDeleteManyNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyNotificationsMutation, DeleteManyNotificationsMutationVariables>) {
        return Apollo.useMutation<DeleteManyNotificationsMutation, DeleteManyNotificationsMutationVariables>(DeleteManyNotificationsDocument, baseOptions);
      }
export type DeleteManyNotificationsMutationHookResult = ReturnType<typeof useDeleteManyNotificationsMutation>;
export type DeleteManyNotificationsMutationResult = Apollo.MutationResult<DeleteManyNotificationsMutation>;
export type DeleteManyNotificationsMutationOptions = Apollo.BaseMutationOptions<DeleteManyNotificationsMutation, DeleteManyNotificationsMutationVariables>;
export const MarkManyNotificationsDocument = gql`
    mutation MarkManyNotifications($notificationIds: [ID]!, $isRead: Boolean) {
  MarkManyNotifications(notificationIds: $notificationIds, isRead: $isRead)
}
    `;
export type MarkManyNotificationsMutationFn = Apollo.MutationFunction<MarkManyNotificationsMutation, MarkManyNotificationsMutationVariables>;

/**
 * __useMarkManyNotificationsMutation__
 *
 * To run a mutation, you first call `useMarkManyNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkManyNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markManyNotificationsMutation, { data, loading, error }] = useMarkManyNotificationsMutation({
 *   variables: {
 *      notificationIds: // value for 'notificationIds'
 *      isRead: // value for 'isRead'
 *   },
 * });
 */
export function useMarkManyNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<MarkManyNotificationsMutation, MarkManyNotificationsMutationVariables>) {
        return Apollo.useMutation<MarkManyNotificationsMutation, MarkManyNotificationsMutationVariables>(MarkManyNotificationsDocument, baseOptions);
      }
export type MarkManyNotificationsMutationHookResult = ReturnType<typeof useMarkManyNotificationsMutation>;
export type MarkManyNotificationsMutationResult = Apollo.MutationResult<MarkManyNotificationsMutation>;
export type MarkManyNotificationsMutationOptions = Apollo.BaseMutationOptions<MarkManyNotificationsMutation, MarkManyNotificationsMutationVariables>;
export const DeleteNotificationDocument = gql`
    mutation DeleteNotification($notificationId: ID!) {
  DeleteNotification(notificationId: $notificationId)
}
    `;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<DeleteNotificationMutation, DeleteNotificationMutationVariables>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useDeleteNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>) {
        return Apollo.useMutation<DeleteNotificationMutation, DeleteNotificationMutationVariables>(DeleteNotificationDocument, baseOptions);
      }
export type DeleteNotificationMutationHookResult = ReturnType<typeof useDeleteNotificationMutation>;
export type DeleteNotificationMutationResult = Apollo.MutationResult<DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>;
export const NewTranslationRequestDocument = gql`
    mutation NewTranslationRequest($wineEntityId: ID!, $locale: String!, $translationMode: TranslationMode!, $translationRequest: TranslationRequestInput, $price: Float!, $paymentMethod: String!, $wordCount: Int!) {
  NewTranslationRequest(
    wineEntityId: $wineEntityId
    locale: $locale
    translationMode: $translationMode
    translationRequest: $translationRequest
    price: $price
    paymentMethod: $paymentMethod
    wordCount: $wordCount
  )
}
    `;
export type NewTranslationRequestMutationFn = Apollo.MutationFunction<NewTranslationRequestMutation, NewTranslationRequestMutationVariables>;

/**
 * __useNewTranslationRequestMutation__
 *
 * To run a mutation, you first call `useNewTranslationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewTranslationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newTranslationRequestMutation, { data, loading, error }] = useNewTranslationRequestMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      locale: // value for 'locale'
 *      translationMode: // value for 'translationMode'
 *      translationRequest: // value for 'translationRequest'
 *      price: // value for 'price'
 *      paymentMethod: // value for 'paymentMethod'
 *      wordCount: // value for 'wordCount'
 *   },
 * });
 */
export function useNewTranslationRequestMutation(baseOptions?: Apollo.MutationHookOptions<NewTranslationRequestMutation, NewTranslationRequestMutationVariables>) {
        return Apollo.useMutation<NewTranslationRequestMutation, NewTranslationRequestMutationVariables>(NewTranslationRequestDocument, baseOptions);
      }
export type NewTranslationRequestMutationHookResult = ReturnType<typeof useNewTranslationRequestMutation>;
export type NewTranslationRequestMutationResult = Apollo.MutationResult<NewTranslationRequestMutation>;
export type NewTranslationRequestMutationOptions = Apollo.BaseMutationOptions<NewTranslationRequestMutation, NewTranslationRequestMutationVariables>;
export const ApplyEstateCheckingDocument = gql`
    mutation ApplyEstateChecking($wineEntityId: ID!, $input: ApplyEstateCheckingInput) {
  ApplyEstateChecking(wineEntityId: $wineEntityId, input: $input)
}
    `;
export type ApplyEstateCheckingMutationFn = Apollo.MutationFunction<ApplyEstateCheckingMutation, ApplyEstateCheckingMutationVariables>;

/**
 * __useApplyEstateCheckingMutation__
 *
 * To run a mutation, you first call `useApplyEstateCheckingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyEstateCheckingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyEstateCheckingMutation, { data, loading, error }] = useApplyEstateCheckingMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyEstateCheckingMutation(baseOptions?: Apollo.MutationHookOptions<ApplyEstateCheckingMutation, ApplyEstateCheckingMutationVariables>) {
        return Apollo.useMutation<ApplyEstateCheckingMutation, ApplyEstateCheckingMutationVariables>(ApplyEstateCheckingDocument, baseOptions);
      }
export type ApplyEstateCheckingMutationHookResult = ReturnType<typeof useApplyEstateCheckingMutation>;
export type ApplyEstateCheckingMutationResult = Apollo.MutationResult<ApplyEstateCheckingMutation>;
export type ApplyEstateCheckingMutationOptions = Apollo.BaseMutationOptions<ApplyEstateCheckingMutation, ApplyEstateCheckingMutationVariables>;
export const ApplyOwnerOperatorDocument = gql`
    mutation ApplyOwnerOperator($wineEntityId: ID!) {
  ApplyOwnerOperator(wineEntityId: $wineEntityId)
}
    `;
export type ApplyOwnerOperatorMutationFn = Apollo.MutationFunction<ApplyOwnerOperatorMutation, ApplyOwnerOperatorMutationVariables>;

/**
 * __useApplyOwnerOperatorMutation__
 *
 * To run a mutation, you first call `useApplyOwnerOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyOwnerOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyOwnerOperatorMutation, { data, loading, error }] = useApplyOwnerOperatorMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useApplyOwnerOperatorMutation(baseOptions?: Apollo.MutationHookOptions<ApplyOwnerOperatorMutation, ApplyOwnerOperatorMutationVariables>) {
        return Apollo.useMutation<ApplyOwnerOperatorMutation, ApplyOwnerOperatorMutationVariables>(ApplyOwnerOperatorDocument, baseOptions);
      }
export type ApplyOwnerOperatorMutationHookResult = ReturnType<typeof useApplyOwnerOperatorMutation>;
export type ApplyOwnerOperatorMutationResult = Apollo.MutationResult<ApplyOwnerOperatorMutation>;
export type ApplyOwnerOperatorMutationOptions = Apollo.BaseMutationOptions<ApplyOwnerOperatorMutation, ApplyOwnerOperatorMutationVariables>;
export const ConfirmRewardPermissionDocument = gql`
    mutation confirmRewardPermission($wineEntityId: ID!, $vintageId: ID!, $rewardId: ID) {
  EditRewards(
    wineEntityId: $wineEntityId
    vintageId: $vintageId
    input: {rewards: [{rewardId: $rewardId, permission: true, hidden: false}]}
  )
}
    `;
export type ConfirmRewardPermissionMutationFn = Apollo.MutationFunction<ConfirmRewardPermissionMutation, ConfirmRewardPermissionMutationVariables>;

/**
 * __useConfirmRewardPermissionMutation__
 *
 * To run a mutation, you first call `useConfirmRewardPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmRewardPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmRewardPermissionMutation, { data, loading, error }] = useConfirmRewardPermissionMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      vintageId: // value for 'vintageId'
 *      rewardId: // value for 'rewardId'
 *   },
 * });
 */
export function useConfirmRewardPermissionMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmRewardPermissionMutation, ConfirmRewardPermissionMutationVariables>) {
        return Apollo.useMutation<ConfirmRewardPermissionMutation, ConfirmRewardPermissionMutationVariables>(ConfirmRewardPermissionDocument, baseOptions);
      }
export type ConfirmRewardPermissionMutationHookResult = ReturnType<typeof useConfirmRewardPermissionMutation>;
export type ConfirmRewardPermissionMutationResult = Apollo.MutationResult<ConfirmRewardPermissionMutation>;
export type ConfirmRewardPermissionMutationOptions = Apollo.BaseMutationOptions<ConfirmRewardPermissionMutation, ConfirmRewardPermissionMutationVariables>;
export const GenerateFtDocument = gql`
    mutation GenerateFt($ftParametersId: ID!, $locales: [String], $userId: String, $uuid: String) {
  GenerateFt(
    ftParametersId: $ftParametersId
    locales: $locales
    userId: $userId
    uuid: $uuid
  )
}
    `;
export type GenerateFtMutationFn = Apollo.MutationFunction<GenerateFtMutation, GenerateFtMutationVariables>;

/**
 * __useGenerateFtMutation__
 *
 * To run a mutation, you first call `useGenerateFtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateFtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateFtMutation, { data, loading, error }] = useGenerateFtMutation({
 *   variables: {
 *      ftParametersId: // value for 'ftParametersId'
 *      locales: // value for 'locales'
 *      userId: // value for 'userId'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGenerateFtMutation(baseOptions?: Apollo.MutationHookOptions<GenerateFtMutation, GenerateFtMutationVariables>) {
        return Apollo.useMutation<GenerateFtMutation, GenerateFtMutationVariables>(GenerateFtDocument, baseOptions);
      }
export type GenerateFtMutationHookResult = ReturnType<typeof useGenerateFtMutation>;
export type GenerateFtMutationResult = Apollo.MutationResult<GenerateFtMutation>;
export type GenerateFtMutationOptions = Apollo.BaseMutationOptions<GenerateFtMutation, GenerateFtMutationVariables>;
export const RequestFtLanguageDocument = gql`
    mutation RequestFtLanguage($ftParametersId: ID!, $locale: String, $value: Boolean) {
  RequestFtLanguage(
    ftParametersId: $ftParametersId
    locale: $locale
    value: $value
  )
}
    `;
export type RequestFtLanguageMutationFn = Apollo.MutationFunction<RequestFtLanguageMutation, RequestFtLanguageMutationVariables>;

/**
 * __useRequestFtLanguageMutation__
 *
 * To run a mutation, you first call `useRequestFtLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestFtLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestFtLanguageMutation, { data, loading, error }] = useRequestFtLanguageMutation({
 *   variables: {
 *      ftParametersId: // value for 'ftParametersId'
 *      locale: // value for 'locale'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useRequestFtLanguageMutation(baseOptions?: Apollo.MutationHookOptions<RequestFtLanguageMutation, RequestFtLanguageMutationVariables>) {
        return Apollo.useMutation<RequestFtLanguageMutation, RequestFtLanguageMutationVariables>(RequestFtLanguageDocument, baseOptions);
      }
export type RequestFtLanguageMutationHookResult = ReturnType<typeof useRequestFtLanguageMutation>;
export type RequestFtLanguageMutationResult = Apollo.MutationResult<RequestFtLanguageMutation>;
export type RequestFtLanguageMutationOptions = Apollo.BaseMutationOptions<RequestFtLanguageMutation, RequestFtLanguageMutationVariables>;
export const RequestFtLanguagesDocument = gql`
    mutation RequestFtLanguages($ftParametersId: ID!, $locales: LanguagesBoolInput) {
  RequestFtLanguages(ftParametersId: $ftParametersId, locales: $locales)
}
    `;
export type RequestFtLanguagesMutationFn = Apollo.MutationFunction<RequestFtLanguagesMutation, RequestFtLanguagesMutationVariables>;

/**
 * __useRequestFtLanguagesMutation__
 *
 * To run a mutation, you first call `useRequestFtLanguagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestFtLanguagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestFtLanguagesMutation, { data, loading, error }] = useRequestFtLanguagesMutation({
 *   variables: {
 *      ftParametersId: // value for 'ftParametersId'
 *      locales: // value for 'locales'
 *   },
 * });
 */
export function useRequestFtLanguagesMutation(baseOptions?: Apollo.MutationHookOptions<RequestFtLanguagesMutation, RequestFtLanguagesMutationVariables>) {
        return Apollo.useMutation<RequestFtLanguagesMutation, RequestFtLanguagesMutationVariables>(RequestFtLanguagesDocument, baseOptions);
      }
export type RequestFtLanguagesMutationHookResult = ReturnType<typeof useRequestFtLanguagesMutation>;
export type RequestFtLanguagesMutationResult = Apollo.MutationResult<RequestFtLanguagesMutation>;
export type RequestFtLanguagesMutationOptions = Apollo.BaseMutationOptions<RequestFtLanguagesMutation, RequestFtLanguagesMutationVariables>;
export const CreateFeretContactDocument = gql`
    mutation CreateFeretContact($input: FeretContactInput!, $wineEntityId: ID!, $diffusionListIds: [ID]) {
  CreateFeretContact(
    input: $input
    wineEntityId: $wineEntityId
    diffusionListIds: $diffusionListIds
  )
}
    `;
export type CreateFeretContactMutationFn = Apollo.MutationFunction<CreateFeretContactMutation, CreateFeretContactMutationVariables>;

/**
 * __useCreateFeretContactMutation__
 *
 * To run a mutation, you first call `useCreateFeretContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeretContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeretContactMutation, { data, loading, error }] = useCreateFeretContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *      wineEntityId: // value for 'wineEntityId'
 *      diffusionListIds: // value for 'diffusionListIds'
 *   },
 * });
 */
export function useCreateFeretContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeretContactMutation, CreateFeretContactMutationVariables>) {
        return Apollo.useMutation<CreateFeretContactMutation, CreateFeretContactMutationVariables>(CreateFeretContactDocument, baseOptions);
      }
export type CreateFeretContactMutationHookResult = ReturnType<typeof useCreateFeretContactMutation>;
export type CreateFeretContactMutationResult = Apollo.MutationResult<CreateFeretContactMutation>;
export type CreateFeretContactMutationOptions = Apollo.BaseMutationOptions<CreateFeretContactMutation, CreateFeretContactMutationVariables>;
export const CancelContractCrmTaskDocument = gql`
    mutation CancelContractCRMTask($userId: ID!) {
  CancelContractCRMTask(userId: $userId)
}
    `;
export type CancelContractCrmTaskMutationFn = Apollo.MutationFunction<CancelContractCrmTaskMutation, CancelContractCrmTaskMutationVariables>;

/**
 * __useCancelContractCrmTaskMutation__
 *
 * To run a mutation, you first call `useCancelContractCrmTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContractCrmTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContractCrmTaskMutation, { data, loading, error }] = useCancelContractCrmTaskMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCancelContractCrmTaskMutation(baseOptions?: Apollo.MutationHookOptions<CancelContractCrmTaskMutation, CancelContractCrmTaskMutationVariables>) {
        return Apollo.useMutation<CancelContractCrmTaskMutation, CancelContractCrmTaskMutationVariables>(CancelContractCrmTaskDocument, baseOptions);
      }
export type CancelContractCrmTaskMutationHookResult = ReturnType<typeof useCancelContractCrmTaskMutation>;
export type CancelContractCrmTaskMutationResult = Apollo.MutationResult<CancelContractCrmTaskMutation>;
export type CancelContractCrmTaskMutationOptions = Apollo.BaseMutationOptions<CancelContractCrmTaskMutation, CancelContractCrmTaskMutationVariables>;
export const CreateFeretContactsDocument = gql`
    mutation CreateFeretContacts($contactInputs: [FeretContactInput]!, $wineEntityId: ID!) {
  CreateFeretContacts(contactInputs: $contactInputs, wineEntityId: $wineEntityId)
}
    `;
export type CreateFeretContactsMutationFn = Apollo.MutationFunction<CreateFeretContactsMutation, CreateFeretContactsMutationVariables>;

/**
 * __useCreateFeretContactsMutation__
 *
 * To run a mutation, you first call `useCreateFeretContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeretContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeretContactsMutation, { data, loading, error }] = useCreateFeretContactsMutation({
 *   variables: {
 *      contactInputs: // value for 'contactInputs'
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useCreateFeretContactsMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeretContactsMutation, CreateFeretContactsMutationVariables>) {
        return Apollo.useMutation<CreateFeretContactsMutation, CreateFeretContactsMutationVariables>(CreateFeretContactsDocument, baseOptions);
      }
export type CreateFeretContactsMutationHookResult = ReturnType<typeof useCreateFeretContactsMutation>;
export type CreateFeretContactsMutationResult = Apollo.MutationResult<CreateFeretContactsMutation>;
export type CreateFeretContactsMutationOptions = Apollo.BaseMutationOptions<CreateFeretContactsMutation, CreateFeretContactsMutationVariables>;
export const EditFeretContactDocument = gql`
    mutation EditFeretContact($input: FeretContactInput!, $contactId: ID!, $wineEntityId: ID!) {
  EditFeretContact(
    input: $input
    contactId: $contactId
    wineEntityId: $wineEntityId
  )
}
    `;
export type EditFeretContactMutationFn = Apollo.MutationFunction<EditFeretContactMutation, EditFeretContactMutationVariables>;

/**
 * __useEditFeretContactMutation__
 *
 * To run a mutation, you first call `useEditFeretContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFeretContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFeretContactMutation, { data, loading, error }] = useEditFeretContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *      contactId: // value for 'contactId'
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useEditFeretContactMutation(baseOptions?: Apollo.MutationHookOptions<EditFeretContactMutation, EditFeretContactMutationVariables>) {
        return Apollo.useMutation<EditFeretContactMutation, EditFeretContactMutationVariables>(EditFeretContactDocument, baseOptions);
      }
export type EditFeretContactMutationHookResult = ReturnType<typeof useEditFeretContactMutation>;
export type EditFeretContactMutationResult = Apollo.MutationResult<EditFeretContactMutation>;
export type EditFeretContactMutationOptions = Apollo.BaseMutationOptions<EditFeretContactMutation, EditFeretContactMutationVariables>;
export const DeleteFeretContactDocument = gql`
    mutation DeleteFeretContact($contactId: ID!, $wineEntityId: ID!) {
  DeleteFeretContact(contactId: $contactId, wineEntityId: $wineEntityId)
}
    `;
export type DeleteFeretContactMutationFn = Apollo.MutationFunction<DeleteFeretContactMutation, DeleteFeretContactMutationVariables>;

/**
 * __useDeleteFeretContactMutation__
 *
 * To run a mutation, you first call `useDeleteFeretContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeretContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeretContactMutation, { data, loading, error }] = useDeleteFeretContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useDeleteFeretContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeretContactMutation, DeleteFeretContactMutationVariables>) {
        return Apollo.useMutation<DeleteFeretContactMutation, DeleteFeretContactMutationVariables>(DeleteFeretContactDocument, baseOptions);
      }
export type DeleteFeretContactMutationHookResult = ReturnType<typeof useDeleteFeretContactMutation>;
export type DeleteFeretContactMutationResult = Apollo.MutationResult<DeleteFeretContactMutation>;
export type DeleteFeretContactMutationOptions = Apollo.BaseMutationOptions<DeleteFeretContactMutation, DeleteFeretContactMutationVariables>;
export const CreateDiffusionListDocument = gql`
    mutation CreateDiffusionList($diffusionListInput: DiffusionListInput!, $wineEntityId: ID!) {
  CreateDiffusionList(
    diffusionListInput: $diffusionListInput
    wineEntityId: $wineEntityId
  )
}
    `;
export type CreateDiffusionListMutationFn = Apollo.MutationFunction<CreateDiffusionListMutation, CreateDiffusionListMutationVariables>;

/**
 * __useCreateDiffusionListMutation__
 *
 * To run a mutation, you first call `useCreateDiffusionListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDiffusionListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDiffusionListMutation, { data, loading, error }] = useCreateDiffusionListMutation({
 *   variables: {
 *      diffusionListInput: // value for 'diffusionListInput'
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useCreateDiffusionListMutation(baseOptions?: Apollo.MutationHookOptions<CreateDiffusionListMutation, CreateDiffusionListMutationVariables>) {
        return Apollo.useMutation<CreateDiffusionListMutation, CreateDiffusionListMutationVariables>(CreateDiffusionListDocument, baseOptions);
      }
export type CreateDiffusionListMutationHookResult = ReturnType<typeof useCreateDiffusionListMutation>;
export type CreateDiffusionListMutationResult = Apollo.MutationResult<CreateDiffusionListMutation>;
export type CreateDiffusionListMutationOptions = Apollo.BaseMutationOptions<CreateDiffusionListMutation, CreateDiffusionListMutationVariables>;
export const EditDiffusionListDocument = gql`
    mutation EditDiffusionList($input: DiffusionListInput!, $diffusionListId: ID!, $wineEntityId: ID!) {
  EditDiffusionList(
    input: $input
    diffusionListId: $diffusionListId
    wineEntityId: $wineEntityId
  )
}
    `;
export type EditDiffusionListMutationFn = Apollo.MutationFunction<EditDiffusionListMutation, EditDiffusionListMutationVariables>;

/**
 * __useEditDiffusionListMutation__
 *
 * To run a mutation, you first call `useEditDiffusionListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDiffusionListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDiffusionListMutation, { data, loading, error }] = useEditDiffusionListMutation({
 *   variables: {
 *      input: // value for 'input'
 *      diffusionListId: // value for 'diffusionListId'
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useEditDiffusionListMutation(baseOptions?: Apollo.MutationHookOptions<EditDiffusionListMutation, EditDiffusionListMutationVariables>) {
        return Apollo.useMutation<EditDiffusionListMutation, EditDiffusionListMutationVariables>(EditDiffusionListDocument, baseOptions);
      }
export type EditDiffusionListMutationHookResult = ReturnType<typeof useEditDiffusionListMutation>;
export type EditDiffusionListMutationResult = Apollo.MutationResult<EditDiffusionListMutation>;
export type EditDiffusionListMutationOptions = Apollo.BaseMutationOptions<EditDiffusionListMutation, EditDiffusionListMutationVariables>;
export const DeleteDiffusionListDocument = gql`
    mutation DeleteDiffusionList($diffusionListId: ID!, $wineEntityId: ID!) {
  DeleteDiffusionList(
    diffusionListId: $diffusionListId
    wineEntityId: $wineEntityId
  )
}
    `;
export type DeleteDiffusionListMutationFn = Apollo.MutationFunction<DeleteDiffusionListMutation, DeleteDiffusionListMutationVariables>;

/**
 * __useDeleteDiffusionListMutation__
 *
 * To run a mutation, you first call `useDeleteDiffusionListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDiffusionListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDiffusionListMutation, { data, loading, error }] = useDeleteDiffusionListMutation({
 *   variables: {
 *      diffusionListId: // value for 'diffusionListId'
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useDeleteDiffusionListMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDiffusionListMutation, DeleteDiffusionListMutationVariables>) {
        return Apollo.useMutation<DeleteDiffusionListMutation, DeleteDiffusionListMutationVariables>(DeleteDiffusionListDocument, baseOptions);
      }
export type DeleteDiffusionListMutationHookResult = ReturnType<typeof useDeleteDiffusionListMutation>;
export type DeleteDiffusionListMutationResult = Apollo.MutationResult<DeleteDiffusionListMutation>;
export type DeleteDiffusionListMutationOptions = Apollo.BaseMutationOptions<DeleteDiffusionListMutation, DeleteDiffusionListMutationVariables>;
export const RemoveContactFromDiffusionListDocument = gql`
    mutation RemoveContactFromDiffusionList($diffusionListId: ID!, $wineEntityId: ID!, $contactId: ID!) {
  RemoveContactFromDiffusionList(
    diffusionListId: $diffusionListId
    wineEntityId: $wineEntityId
    contactId: $contactId
  )
}
    `;
export type RemoveContactFromDiffusionListMutationFn = Apollo.MutationFunction<RemoveContactFromDiffusionListMutation, RemoveContactFromDiffusionListMutationVariables>;

/**
 * __useRemoveContactFromDiffusionListMutation__
 *
 * To run a mutation, you first call `useRemoveContactFromDiffusionListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactFromDiffusionListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContactFromDiffusionListMutation, { data, loading, error }] = useRemoveContactFromDiffusionListMutation({
 *   variables: {
 *      diffusionListId: // value for 'diffusionListId'
 *      wineEntityId: // value for 'wineEntityId'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useRemoveContactFromDiffusionListMutation(baseOptions?: Apollo.MutationHookOptions<RemoveContactFromDiffusionListMutation, RemoveContactFromDiffusionListMutationVariables>) {
        return Apollo.useMutation<RemoveContactFromDiffusionListMutation, RemoveContactFromDiffusionListMutationVariables>(RemoveContactFromDiffusionListDocument, baseOptions);
      }
export type RemoveContactFromDiffusionListMutationHookResult = ReturnType<typeof useRemoveContactFromDiffusionListMutation>;
export type RemoveContactFromDiffusionListMutationResult = Apollo.MutationResult<RemoveContactFromDiffusionListMutation>;
export type RemoveContactFromDiffusionListMutationOptions = Apollo.BaseMutationOptions<RemoveContactFromDiffusionListMutation, RemoveContactFromDiffusionListMutationVariables>;
export const AddContactToDiffusionListDocument = gql`
    mutation AddContactToDiffusionList($diffusionListId: ID!, $wineEntityId: ID!, $contactId: ID!) {
  AddContactToDiffusionList(
    diffusionListId: $diffusionListId
    wineEntityId: $wineEntityId
    contactId: $contactId
  )
}
    `;
export type AddContactToDiffusionListMutationFn = Apollo.MutationFunction<AddContactToDiffusionListMutation, AddContactToDiffusionListMutationVariables>;

/**
 * __useAddContactToDiffusionListMutation__
 *
 * To run a mutation, you first call `useAddContactToDiffusionListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContactToDiffusionListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContactToDiffusionListMutation, { data, loading, error }] = useAddContactToDiffusionListMutation({
 *   variables: {
 *      diffusionListId: // value for 'diffusionListId'
 *      wineEntityId: // value for 'wineEntityId'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useAddContactToDiffusionListMutation(baseOptions?: Apollo.MutationHookOptions<AddContactToDiffusionListMutation, AddContactToDiffusionListMutationVariables>) {
        return Apollo.useMutation<AddContactToDiffusionListMutation, AddContactToDiffusionListMutationVariables>(AddContactToDiffusionListDocument, baseOptions);
      }
export type AddContactToDiffusionListMutationHookResult = ReturnType<typeof useAddContactToDiffusionListMutation>;
export type AddContactToDiffusionListMutationResult = Apollo.MutationResult<AddContactToDiffusionListMutation>;
export type AddContactToDiffusionListMutationOptions = Apollo.BaseMutationOptions<AddContactToDiffusionListMutation, AddContactToDiffusionListMutationVariables>;
export const OpenFtDiffusionDocument = gql`
    mutation OpenFtDiffusion($ftDiffusionId: ID!) {
  OpenFtDiffusion(ftDiffusionId: $ftDiffusionId)
}
    `;
export type OpenFtDiffusionMutationFn = Apollo.MutationFunction<OpenFtDiffusionMutation, OpenFtDiffusionMutationVariables>;

/**
 * __useOpenFtDiffusionMutation__
 *
 * To run a mutation, you first call `useOpenFtDiffusionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenFtDiffusionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openFtDiffusionMutation, { data, loading, error }] = useOpenFtDiffusionMutation({
 *   variables: {
 *      ftDiffusionId: // value for 'ftDiffusionId'
 *   },
 * });
 */
export function useOpenFtDiffusionMutation(baseOptions?: Apollo.MutationHookOptions<OpenFtDiffusionMutation, OpenFtDiffusionMutationVariables>) {
        return Apollo.useMutation<OpenFtDiffusionMutation, OpenFtDiffusionMutationVariables>(OpenFtDiffusionDocument, baseOptions);
      }
export type OpenFtDiffusionMutationHookResult = ReturnType<typeof useOpenFtDiffusionMutation>;
export type OpenFtDiffusionMutationResult = Apollo.MutationResult<OpenFtDiffusionMutation>;
export type OpenFtDiffusionMutationOptions = Apollo.BaseMutationOptions<OpenFtDiffusionMutation, OpenFtDiffusionMutationVariables>;
export const EditAccountScreenEmptyDocument = gql`
    mutation EditAccountScreenEmpty($userId: ID!, $input: EditAccountScreenEmptyInput!) {
  EditAccountScreenEmpty(userId: $userId, input: $input)
}
    `;
export type EditAccountScreenEmptyMutationFn = Apollo.MutationFunction<EditAccountScreenEmptyMutation, EditAccountScreenEmptyMutationVariables>;

/**
 * __useEditAccountScreenEmptyMutation__
 *
 * To run a mutation, you first call `useEditAccountScreenEmptyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAccountScreenEmptyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAccountScreenEmptyMutation, { data, loading, error }] = useEditAccountScreenEmptyMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAccountScreenEmptyMutation(baseOptions?: Apollo.MutationHookOptions<EditAccountScreenEmptyMutation, EditAccountScreenEmptyMutationVariables>) {
        return Apollo.useMutation<EditAccountScreenEmptyMutation, EditAccountScreenEmptyMutationVariables>(EditAccountScreenEmptyDocument, baseOptions);
      }
export type EditAccountScreenEmptyMutationHookResult = ReturnType<typeof useEditAccountScreenEmptyMutation>;
export type EditAccountScreenEmptyMutationResult = Apollo.MutationResult<EditAccountScreenEmptyMutation>;
export type EditAccountScreenEmptyMutationOptions = Apollo.BaseMutationOptions<EditAccountScreenEmptyMutation, EditAccountScreenEmptyMutationVariables>;
export const EditJournalistAccountDocument = gql`
    mutation EditJournalistAccount($userId: ID!, $input: EditJournalistAccountInput!) {
  EditJournalistAccount(userId: $userId, input: $input)
}
    `;
export type EditJournalistAccountMutationFn = Apollo.MutationFunction<EditJournalistAccountMutation, EditJournalistAccountMutationVariables>;

/**
 * __useEditJournalistAccountMutation__
 *
 * To run a mutation, you first call `useEditJournalistAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditJournalistAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editJournalistAccountMutation, { data, loading, error }] = useEditJournalistAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditJournalistAccountMutation(baseOptions?: Apollo.MutationHookOptions<EditJournalistAccountMutation, EditJournalistAccountMutationVariables>) {
        return Apollo.useMutation<EditJournalistAccountMutation, EditJournalistAccountMutationVariables>(EditJournalistAccountDocument, baseOptions);
      }
export type EditJournalistAccountMutationHookResult = ReturnType<typeof useEditJournalistAccountMutation>;
export type EditJournalistAccountMutationResult = Apollo.MutationResult<EditJournalistAccountMutation>;
export type EditJournalistAccountMutationOptions = Apollo.BaseMutationOptions<EditJournalistAccountMutation, EditJournalistAccountMutationVariables>;
export const EditServicesOfferByJournalistDocument = gql`
    mutation EditServicesOfferByJournalist($userId: ID!, $input: EditServicesOfferByJournalistInput!) {
  EditServicesOfferByJournalist(userId: $userId, input: $input)
}
    `;
export type EditServicesOfferByJournalistMutationFn = Apollo.MutationFunction<EditServicesOfferByJournalistMutation, EditServicesOfferByJournalistMutationVariables>;

/**
 * __useEditServicesOfferByJournalistMutation__
 *
 * To run a mutation, you first call `useEditServicesOfferByJournalistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditServicesOfferByJournalistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editServicesOfferByJournalistMutation, { data, loading, error }] = useEditServicesOfferByJournalistMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditServicesOfferByJournalistMutation(baseOptions?: Apollo.MutationHookOptions<EditServicesOfferByJournalistMutation, EditServicesOfferByJournalistMutationVariables>) {
        return Apollo.useMutation<EditServicesOfferByJournalistMutation, EditServicesOfferByJournalistMutationVariables>(EditServicesOfferByJournalistDocument, baseOptions);
      }
export type EditServicesOfferByJournalistMutationHookResult = ReturnType<typeof useEditServicesOfferByJournalistMutation>;
export type EditServicesOfferByJournalistMutationResult = Apollo.MutationResult<EditServicesOfferByJournalistMutation>;
export type EditServicesOfferByJournalistMutationOptions = Apollo.BaseMutationOptions<EditServicesOfferByJournalistMutation, EditServicesOfferByJournalistMutationVariables>;
export const CreateFtDiffusionDocument = gql`
    mutation CreateFtDiffusion($wineEntityId: ID!, $contactIds: [ID], $diffusionListIds: [ID], $ftId: ID!, $message: String) {
  CreateFtDiffusion(
    wineEntityId: $wineEntityId
    contactIds: $contactIds
    diffusionListIds: $diffusionListIds
    ftId: $ftId
    message: $message
  )
}
    `;
export type CreateFtDiffusionMutationFn = Apollo.MutationFunction<CreateFtDiffusionMutation, CreateFtDiffusionMutationVariables>;

/**
 * __useCreateFtDiffusionMutation__
 *
 * To run a mutation, you first call `useCreateFtDiffusionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFtDiffusionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFtDiffusionMutation, { data, loading, error }] = useCreateFtDiffusionMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      contactIds: // value for 'contactIds'
 *      diffusionListIds: // value for 'diffusionListIds'
 *      ftId: // value for 'ftId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useCreateFtDiffusionMutation(baseOptions?: Apollo.MutationHookOptions<CreateFtDiffusionMutation, CreateFtDiffusionMutationVariables>) {
        return Apollo.useMutation<CreateFtDiffusionMutation, CreateFtDiffusionMutationVariables>(CreateFtDiffusionDocument, baseOptions);
      }
export type CreateFtDiffusionMutationHookResult = ReturnType<typeof useCreateFtDiffusionMutation>;
export type CreateFtDiffusionMutationResult = Apollo.MutationResult<CreateFtDiffusionMutation>;
export type CreateFtDiffusionMutationOptions = Apollo.BaseMutationOptions<CreateFtDiffusionMutation, CreateFtDiffusionMutationVariables>;
export const EditDiffusionParametersDocument = gql`
    mutation EditDiffusionParameters($wineEntityId: ID!, $parameters: DiffusionParametersInput!) {
  EditDiffusionParameters(wineEntityId: $wineEntityId, parameters: $parameters)
}
    `;
export type EditDiffusionParametersMutationFn = Apollo.MutationFunction<EditDiffusionParametersMutation, EditDiffusionParametersMutationVariables>;

/**
 * __useEditDiffusionParametersMutation__
 *
 * To run a mutation, you first call `useEditDiffusionParametersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDiffusionParametersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDiffusionParametersMutation, { data, loading, error }] = useEditDiffusionParametersMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      parameters: // value for 'parameters'
 *   },
 * });
 */
export function useEditDiffusionParametersMutation(baseOptions?: Apollo.MutationHookOptions<EditDiffusionParametersMutation, EditDiffusionParametersMutationVariables>) {
        return Apollo.useMutation<EditDiffusionParametersMutation, EditDiffusionParametersMutationVariables>(EditDiffusionParametersDocument, baseOptions);
      }
export type EditDiffusionParametersMutationHookResult = ReturnType<typeof useEditDiffusionParametersMutation>;
export type EditDiffusionParametersMutationResult = Apollo.MutationResult<EditDiffusionParametersMutation>;
export type EditDiffusionParametersMutationOptions = Apollo.BaseMutationOptions<EditDiffusionParametersMutation, EditDiffusionParametersMutationVariables>;
export const DeleteFtDocument = gql`
    mutation DeleteFt($ftId: ID!) {
  DeleteFt(ftId: $ftId)
}
    `;
export type DeleteFtMutationFn = Apollo.MutationFunction<DeleteFtMutation, DeleteFtMutationVariables>;

/**
 * __useDeleteFtMutation__
 *
 * To run a mutation, you first call `useDeleteFtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFtMutation, { data, loading, error }] = useDeleteFtMutation({
 *   variables: {
 *      ftId: // value for 'ftId'
 *   },
 * });
 */
export function useDeleteFtMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFtMutation, DeleteFtMutationVariables>) {
        return Apollo.useMutation<DeleteFtMutation, DeleteFtMutationVariables>(DeleteFtDocument, baseOptions);
      }
export type DeleteFtMutationHookResult = ReturnType<typeof useDeleteFtMutation>;
export type DeleteFtMutationResult = Apollo.MutationResult<DeleteFtMutation>;
export type DeleteFtMutationOptions = Apollo.BaseMutationOptions<DeleteFtMutation, DeleteFtMutationVariables>;
export const SendEstateMailDocument = gql`
    mutation SendEstateMail($wineEntityId: String!, $title: String, $firstName: String, $lastName: String, $company: String, $telephone: String, $emailFrom: String!, $object: String, $message: String!, $estateName: String!, $newsletter: Boolean) {
  SendEstateMail(
    wineEntityId: $wineEntityId
    title: $title
    firstName: $firstName
    lastName: $lastName
    company: $company
    telephone: $telephone
    emailFrom: $emailFrom
    object: $object
    message: $message
    estateName: $estateName
    newsletter: $newsletter
  )
}
    `;
export type SendEstateMailMutationFn = Apollo.MutationFunction<SendEstateMailMutation, SendEstateMailMutationVariables>;

/**
 * __useSendEstateMailMutation__
 *
 * To run a mutation, you first call `useSendEstateMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEstateMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEstateMailMutation, { data, loading, error }] = useSendEstateMailMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      title: // value for 'title'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      company: // value for 'company'
 *      telephone: // value for 'telephone'
 *      emailFrom: // value for 'emailFrom'
 *      object: // value for 'object'
 *      message: // value for 'message'
 *      estateName: // value for 'estateName'
 *      newsletter: // value for 'newsletter'
 *   },
 * });
 */
export function useSendEstateMailMutation(baseOptions?: Apollo.MutationHookOptions<SendEstateMailMutation, SendEstateMailMutationVariables>) {
        return Apollo.useMutation<SendEstateMailMutation, SendEstateMailMutationVariables>(SendEstateMailDocument, baseOptions);
      }
export type SendEstateMailMutationHookResult = ReturnType<typeof useSendEstateMailMutation>;
export type SendEstateMailMutationResult = Apollo.MutationResult<SendEstateMailMutation>;
export type SendEstateMailMutationOptions = Apollo.BaseMutationOptions<SendEstateMailMutation, SendEstateMailMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($userId: String!) {
  DeleteUser(userId: $userId)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const EditContactAndResentDiffusionDocument = gql`
    mutation EditContactAndResentDiffusion($contactId: ID!, $email: String) {
  EditContactAndResentDiffusion(contactId: $contactId, email: $email)
}
    `;
export type EditContactAndResentDiffusionMutationFn = Apollo.MutationFunction<EditContactAndResentDiffusionMutation, EditContactAndResentDiffusionMutationVariables>;

/**
 * __useEditContactAndResentDiffusionMutation__
 *
 * To run a mutation, you first call `useEditContactAndResentDiffusionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditContactAndResentDiffusionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editContactAndResentDiffusionMutation, { data, loading, error }] = useEditContactAndResentDiffusionMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useEditContactAndResentDiffusionMutation(baseOptions?: Apollo.MutationHookOptions<EditContactAndResentDiffusionMutation, EditContactAndResentDiffusionMutationVariables>) {
        return Apollo.useMutation<EditContactAndResentDiffusionMutation, EditContactAndResentDiffusionMutationVariables>(EditContactAndResentDiffusionDocument, baseOptions);
      }
export type EditContactAndResentDiffusionMutationHookResult = ReturnType<typeof useEditContactAndResentDiffusionMutation>;
export type EditContactAndResentDiffusionMutationResult = Apollo.MutationResult<EditContactAndResentDiffusionMutation>;
export type EditContactAndResentDiffusionMutationOptions = Apollo.BaseMutationOptions<EditContactAndResentDiffusionMutation, EditContactAndResentDiffusionMutationVariables>;
export const EditFtDiffusionDocument = gql`
    mutation EditFtDiffusion($ftId: ID!, $diffusionListIds: [ID!], $message: String) {
  EditFtDiffusion(
    ftId: $ftId
    diffusionListIds: $diffusionListIds
    message: $message
  )
}
    `;
export type EditFtDiffusionMutationFn = Apollo.MutationFunction<EditFtDiffusionMutation, EditFtDiffusionMutationVariables>;

/**
 * __useEditFtDiffusionMutation__
 *
 * To run a mutation, you first call `useEditFtDiffusionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFtDiffusionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFtDiffusionMutation, { data, loading, error }] = useEditFtDiffusionMutation({
 *   variables: {
 *      ftId: // value for 'ftId'
 *      diffusionListIds: // value for 'diffusionListIds'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useEditFtDiffusionMutation(baseOptions?: Apollo.MutationHookOptions<EditFtDiffusionMutation, EditFtDiffusionMutationVariables>) {
        return Apollo.useMutation<EditFtDiffusionMutation, EditFtDiffusionMutationVariables>(EditFtDiffusionDocument, baseOptions);
      }
export type EditFtDiffusionMutationHookResult = ReturnType<typeof useEditFtDiffusionMutation>;
export type EditFtDiffusionMutationResult = Apollo.MutationResult<EditFtDiffusionMutation>;
export type EditFtDiffusionMutationOptions = Apollo.BaseMutationOptions<EditFtDiffusionMutation, EditFtDiffusionMutationVariables>;
export const DeleteWineDocument = gql`
    mutation DeleteWine($wineId: ID!) {
  DeleteWine(wineId: $wineId)
}
    `;
export type DeleteWineMutationFn = Apollo.MutationFunction<DeleteWineMutation, DeleteWineMutationVariables>;

/**
 * __useDeleteWineMutation__
 *
 * To run a mutation, you first call `useDeleteWineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWineMutation, { data, loading, error }] = useDeleteWineMutation({
 *   variables: {
 *      wineId: // value for 'wineId'
 *   },
 * });
 */
export function useDeleteWineMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWineMutation, DeleteWineMutationVariables>) {
        return Apollo.useMutation<DeleteWineMutation, DeleteWineMutationVariables>(DeleteWineDocument, baseOptions);
      }
export type DeleteWineMutationHookResult = ReturnType<typeof useDeleteWineMutation>;
export type DeleteWineMutationResult = Apollo.MutationResult<DeleteWineMutation>;
export type DeleteWineMutationOptions = Apollo.BaseMutationOptions<DeleteWineMutation, DeleteWineMutationVariables>;
export const DeleteVintageDocument = gql`
    mutation DeleteVintage($vintageId: ID!) {
  DeleteVintage(vintageId: $vintageId)
}
    `;
export type DeleteVintageMutationFn = Apollo.MutationFunction<DeleteVintageMutation, DeleteVintageMutationVariables>;

/**
 * __useDeleteVintageMutation__
 *
 * To run a mutation, you first call `useDeleteVintageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVintageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVintageMutation, { data, loading, error }] = useDeleteVintageMutation({
 *   variables: {
 *      vintageId: // value for 'vintageId'
 *   },
 * });
 */
export function useDeleteVintageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVintageMutation, DeleteVintageMutationVariables>) {
        return Apollo.useMutation<DeleteVintageMutation, DeleteVintageMutationVariables>(DeleteVintageDocument, baseOptions);
      }
export type DeleteVintageMutationHookResult = ReturnType<typeof useDeleteVintageMutation>;
export type DeleteVintageMutationResult = Apollo.MutationResult<DeleteVintageMutation>;
export type DeleteVintageMutationOptions = Apollo.BaseMutationOptions<DeleteVintageMutation, DeleteVintageMutationVariables>;
export const ResendAllDocument = gql`
    mutation ResendAll($ftId: ID!, $wineEntityId: ID!) {
  ResendAll(ftId: $ftId, wineEntityId: $wineEntityId)
}
    `;
export type ResendAllMutationFn = Apollo.MutationFunction<ResendAllMutation, ResendAllMutationVariables>;

/**
 * __useResendAllMutation__
 *
 * To run a mutation, you first call `useResendAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendAllMutation, { data, loading, error }] = useResendAllMutation({
 *   variables: {
 *      ftId: // value for 'ftId'
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useResendAllMutation(baseOptions?: Apollo.MutationHookOptions<ResendAllMutation, ResendAllMutationVariables>) {
        return Apollo.useMutation<ResendAllMutation, ResendAllMutationVariables>(ResendAllDocument, baseOptions);
      }
export type ResendAllMutationHookResult = ReturnType<typeof useResendAllMutation>;
export type ResendAllMutationResult = Apollo.MutationResult<ResendAllMutation>;
export type ResendAllMutationOptions = Apollo.BaseMutationOptions<ResendAllMutation, ResendAllMutationVariables>;
export const ResendOneDocument = gql`
    mutation ResendOne($ftDiffusionId: ID!) {
  ResendOne(ftDiffusionId: $ftDiffusionId)
}
    `;
export type ResendOneMutationFn = Apollo.MutationFunction<ResendOneMutation, ResendOneMutationVariables>;

/**
 * __useResendOneMutation__
 *
 * To run a mutation, you first call `useResendOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendOneMutation, { data, loading, error }] = useResendOneMutation({
 *   variables: {
 *      ftDiffusionId: // value for 'ftDiffusionId'
 *   },
 * });
 */
export function useResendOneMutation(baseOptions?: Apollo.MutationHookOptions<ResendOneMutation, ResendOneMutationVariables>) {
        return Apollo.useMutation<ResendOneMutation, ResendOneMutationVariables>(ResendOneDocument, baseOptions);
      }
export type ResendOneMutationHookResult = ReturnType<typeof useResendOneMutation>;
export type ResendOneMutationResult = Apollo.MutationResult<ResendOneMutation>;
export type ResendOneMutationOptions = Apollo.BaseMutationOptions<ResendOneMutation, ResendOneMutationVariables>;
export const SwitchStatusDocument = gql`
    mutation SwitchStatus($ftParametersId: ID!, $status: String!) {
  SwitchStatus(ftParametersId: $ftParametersId, status: $status)
}
    `;
export type SwitchStatusMutationFn = Apollo.MutationFunction<SwitchStatusMutation, SwitchStatusMutationVariables>;

/**
 * __useSwitchStatusMutation__
 *
 * To run a mutation, you first call `useSwitchStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchStatusMutation, { data, loading, error }] = useSwitchStatusMutation({
 *   variables: {
 *      ftParametersId: // value for 'ftParametersId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSwitchStatusMutation(baseOptions?: Apollo.MutationHookOptions<SwitchStatusMutation, SwitchStatusMutationVariables>) {
        return Apollo.useMutation<SwitchStatusMutation, SwitchStatusMutationVariables>(SwitchStatusDocument, baseOptions);
      }
export type SwitchStatusMutationHookResult = ReturnType<typeof useSwitchStatusMutation>;
export type SwitchStatusMutationResult = Apollo.MutationResult<SwitchStatusMutation>;
export type SwitchStatusMutationOptions = Apollo.BaseMutationOptions<SwitchStatusMutation, SwitchStatusMutationVariables>;
export const CreateFtDocument = gql`
    mutation CreateFt($wineEntityId: ID!, $wineId: ID, $vintageId: ID, $ftId: ID, $description: String, $name: String!) {
  CreateFt(
    wineEntityId: $wineEntityId
    wineId: $wineId
    vintageId: $vintageId
    ftId: $ftId
    description: $description
    name: $name
  ) {
    token
    ftParametersId
  }
}
    `;
export type CreateFtMutationFn = Apollo.MutationFunction<CreateFtMutation, CreateFtMutationVariables>;

/**
 * __useCreateFtMutation__
 *
 * To run a mutation, you first call `useCreateFtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFtMutation, { data, loading, error }] = useCreateFtMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      wineId: // value for 'wineId'
 *      vintageId: // value for 'vintageId'
 *      ftId: // value for 'ftId'
 *      description: // value for 'description'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateFtMutation(baseOptions?: Apollo.MutationHookOptions<CreateFtMutation, CreateFtMutationVariables>) {
        return Apollo.useMutation<CreateFtMutation, CreateFtMutationVariables>(CreateFtDocument, baseOptions);
      }
export type CreateFtMutationHookResult = ReturnType<typeof useCreateFtMutation>;
export type CreateFtMutationResult = Apollo.MutationResult<CreateFtMutation>;
export type CreateFtMutationOptions = Apollo.BaseMutationOptions<CreateFtMutation, CreateFtMutationVariables>;
export const EditFtDocument = gql`
    mutation EditFt($ftParametersId: ID!, $name: String, $description: String, $online: Boolean) {
  EditFt(
    ftParametersId: $ftParametersId
    name: $name
    description: $description
    online: $online
  )
}
    `;
export type EditFtMutationFn = Apollo.MutationFunction<EditFtMutation, EditFtMutationVariables>;

/**
 * __useEditFtMutation__
 *
 * To run a mutation, you first call `useEditFtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFtMutation, { data, loading, error }] = useEditFtMutation({
 *   variables: {
 *      ftParametersId: // value for 'ftParametersId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      online: // value for 'online'
 *   },
 * });
 */
export function useEditFtMutation(baseOptions?: Apollo.MutationHookOptions<EditFtMutation, EditFtMutationVariables>) {
        return Apollo.useMutation<EditFtMutation, EditFtMutationVariables>(EditFtDocument, baseOptions);
      }
export type EditFtMutationHookResult = ReturnType<typeof useEditFtMutation>;
export type EditFtMutationResult = Apollo.MutationResult<EditFtMutation>;
export type EditFtMutationOptions = Apollo.BaseMutationOptions<EditFtMutation, EditFtMutationVariables>;
export const UpdateFtComponentsDocument = gql`
    mutation UpdateFtComponents($ftParametersId: ID!) {
  UpdateFtComponents(ftParametersId: $ftParametersId) {
    token
    ftParametersId
  }
}
    `;
export type UpdateFtComponentsMutationFn = Apollo.MutationFunction<UpdateFtComponentsMutation, UpdateFtComponentsMutationVariables>;

/**
 * __useUpdateFtComponentsMutation__
 *
 * To run a mutation, you first call `useUpdateFtComponentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFtComponentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFtComponentsMutation, { data, loading, error }] = useUpdateFtComponentsMutation({
 *   variables: {
 *      ftParametersId: // value for 'ftParametersId'
 *   },
 * });
 */
export function useUpdateFtComponentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFtComponentsMutation, UpdateFtComponentsMutationVariables>) {
        return Apollo.useMutation<UpdateFtComponentsMutation, UpdateFtComponentsMutationVariables>(UpdateFtComponentsDocument, baseOptions);
      }
export type UpdateFtComponentsMutationHookResult = ReturnType<typeof useUpdateFtComponentsMutation>;
export type UpdateFtComponentsMutationResult = Apollo.MutationResult<UpdateFtComponentsMutation>;
export type UpdateFtComponentsMutationOptions = Apollo.BaseMutationOptions<UpdateFtComponentsMutation, UpdateFtComponentsMutationVariables>;
export const EditUserEmailDocument = gql`
    mutation EditUserEmail($userId: String!, $email: String!) {
  EditUserEmail(userId: $userId, email: $email)
}
    `;
export type EditUserEmailMutationFn = Apollo.MutationFunction<EditUserEmailMutation, EditUserEmailMutationVariables>;

/**
 * __useEditUserEmailMutation__
 *
 * To run a mutation, you first call `useEditUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserEmailMutation, { data, loading, error }] = useEditUserEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useEditUserEmailMutation(baseOptions?: Apollo.MutationHookOptions<EditUserEmailMutation, EditUserEmailMutationVariables>) {
        return Apollo.useMutation<EditUserEmailMutation, EditUserEmailMutationVariables>(EditUserEmailDocument, baseOptions);
      }
export type EditUserEmailMutationHookResult = ReturnType<typeof useEditUserEmailMutation>;
export type EditUserEmailMutationResult = Apollo.MutationResult<EditUserEmailMutation>;
export type EditUserEmailMutationOptions = Apollo.BaseMutationOptions<EditUserEmailMutation, EditUserEmailMutationVariables>;
export const CreateContactsWithQueueDocument = gql`
    mutation CreateContactsWithQueue($csvFile: DocumentInput!, $wineEntityId: ID!) {
  CreateContactsWithQueue(csvFile: $csvFile, wineEntityId: $wineEntityId)
}
    `;
export type CreateContactsWithQueueMutationFn = Apollo.MutationFunction<CreateContactsWithQueueMutation, CreateContactsWithQueueMutationVariables>;

/**
 * __useCreateContactsWithQueueMutation__
 *
 * To run a mutation, you first call `useCreateContactsWithQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactsWithQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactsWithQueueMutation, { data, loading, error }] = useCreateContactsWithQueueMutation({
 *   variables: {
 *      csvFile: // value for 'csvFile'
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useCreateContactsWithQueueMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactsWithQueueMutation, CreateContactsWithQueueMutationVariables>) {
        return Apollo.useMutation<CreateContactsWithQueueMutation, CreateContactsWithQueueMutationVariables>(CreateContactsWithQueueDocument, baseOptions);
      }
export type CreateContactsWithQueueMutationHookResult = ReturnType<typeof useCreateContactsWithQueueMutation>;
export type CreateContactsWithQueueMutationResult = Apollo.MutationResult<CreateContactsWithQueueMutation>;
export type CreateContactsWithQueueMutationOptions = Apollo.BaseMutationOptions<CreateContactsWithQueueMutation, CreateContactsWithQueueMutationVariables>;
export const CreateWebFtDiffusionDocument = gql`
    mutation CreateWebFtDiffusion($userId: ID!, $ftId: ID!) {
  CreateWebFtDiffusion(userId: $userId, ftId: $ftId)
}
    `;
export type CreateWebFtDiffusionMutationFn = Apollo.MutationFunction<CreateWebFtDiffusionMutation, CreateWebFtDiffusionMutationVariables>;

/**
 * __useCreateWebFtDiffusionMutation__
 *
 * To run a mutation, you first call `useCreateWebFtDiffusionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebFtDiffusionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebFtDiffusionMutation, { data, loading, error }] = useCreateWebFtDiffusionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      ftId: // value for 'ftId'
 *   },
 * });
 */
export function useCreateWebFtDiffusionMutation(baseOptions?: Apollo.MutationHookOptions<CreateWebFtDiffusionMutation, CreateWebFtDiffusionMutationVariables>) {
        return Apollo.useMutation<CreateWebFtDiffusionMutation, CreateWebFtDiffusionMutationVariables>(CreateWebFtDiffusionDocument, baseOptions);
      }
export type CreateWebFtDiffusionMutationHookResult = ReturnType<typeof useCreateWebFtDiffusionMutation>;
export type CreateWebFtDiffusionMutationResult = Apollo.MutationResult<CreateWebFtDiffusionMutation>;
export type CreateWebFtDiffusionMutationOptions = Apollo.BaseMutationOptions<CreateWebFtDiffusionMutation, CreateWebFtDiffusionMutationVariables>;
export const CreateFtFromModelDocument = gql`
    mutation CreateFtFromModel($ftModelId: ID!, $wineEntityId: ID!, $wineId: ID, $vintageId: ID!, $description: String, $name: String!) {
  CreateFtFromModel(
    ftModelId: $ftModelId
    wineEntityId: $wineEntityId
    wineId: $wineId
    vintageId: $vintageId
    description: $description
    name: $name
  ) {
    token
    ftParametersId
  }
}
    `;
export type CreateFtFromModelMutationFn = Apollo.MutationFunction<CreateFtFromModelMutation, CreateFtFromModelMutationVariables>;

/**
 * __useCreateFtFromModelMutation__
 *
 * To run a mutation, you first call `useCreateFtFromModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFtFromModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFtFromModelMutation, { data, loading, error }] = useCreateFtFromModelMutation({
 *   variables: {
 *      ftModelId: // value for 'ftModelId'
 *      wineEntityId: // value for 'wineEntityId'
 *      wineId: // value for 'wineId'
 *      vintageId: // value for 'vintageId'
 *      description: // value for 'description'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateFtFromModelMutation(baseOptions?: Apollo.MutationHookOptions<CreateFtFromModelMutation, CreateFtFromModelMutationVariables>) {
        return Apollo.useMutation<CreateFtFromModelMutation, CreateFtFromModelMutationVariables>(CreateFtFromModelDocument, baseOptions);
      }
export type CreateFtFromModelMutationHookResult = ReturnType<typeof useCreateFtFromModelMutation>;
export type CreateFtFromModelMutationResult = Apollo.MutationResult<CreateFtFromModelMutation>;
export type CreateFtFromModelMutationOptions = Apollo.BaseMutationOptions<CreateFtFromModelMutation, CreateFtFromModelMutationVariables>;
export const HandleReCaptchaDocument = gql`
    mutation HandleReCaptcha($recaptchaResponse: String!) {
  HandleReCaptcha(recaptchaResponse: $recaptchaResponse)
}
    `;
export type HandleReCaptchaMutationFn = Apollo.MutationFunction<HandleReCaptchaMutation, HandleReCaptchaMutationVariables>;

/**
 * __useHandleReCaptchaMutation__
 *
 * To run a mutation, you first call `useHandleReCaptchaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandleReCaptchaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handleReCaptchaMutation, { data, loading, error }] = useHandleReCaptchaMutation({
 *   variables: {
 *      recaptchaResponse: // value for 'recaptchaResponse'
 *   },
 * });
 */
export function useHandleReCaptchaMutation(baseOptions?: Apollo.MutationHookOptions<HandleReCaptchaMutation, HandleReCaptchaMutationVariables>) {
        return Apollo.useMutation<HandleReCaptchaMutation, HandleReCaptchaMutationVariables>(HandleReCaptchaDocument, baseOptions);
      }
export type HandleReCaptchaMutationHookResult = ReturnType<typeof useHandleReCaptchaMutation>;
export type HandleReCaptchaMutationResult = Apollo.MutationResult<HandleReCaptchaMutation>;
export type HandleReCaptchaMutationOptions = Apollo.BaseMutationOptions<HandleReCaptchaMutation, HandleReCaptchaMutationVariables>;
export const SendContactMailDocument = gql`
    mutation SendContactMail($title: String, $firstName: String, $lastName: String, $company: String, $telephone: String, $emailFrom: String, $object: String, $message: String!, $newsletter: Boolean) {
  SendContactMail(
    title: $title
    firstName: $firstName
    lastName: $lastName
    company: $company
    telephone: $telephone
    emailFrom: $emailFrom
    object: $object
    message: $message
    newsletter: $newsletter
  )
}
    `;
export type SendContactMailMutationFn = Apollo.MutationFunction<SendContactMailMutation, SendContactMailMutationVariables>;

/**
 * __useSendContactMailMutation__
 *
 * To run a mutation, you first call `useSendContactMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendContactMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendContactMailMutation, { data, loading, error }] = useSendContactMailMutation({
 *   variables: {
 *      title: // value for 'title'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      company: // value for 'company'
 *      telephone: // value for 'telephone'
 *      emailFrom: // value for 'emailFrom'
 *      object: // value for 'object'
 *      message: // value for 'message'
 *      newsletter: // value for 'newsletter'
 *   },
 * });
 */
export function useSendContactMailMutation(baseOptions?: Apollo.MutationHookOptions<SendContactMailMutation, SendContactMailMutationVariables>) {
        return Apollo.useMutation<SendContactMailMutation, SendContactMailMutationVariables>(SendContactMailDocument, baseOptions);
      }
export type SendContactMailMutationHookResult = ReturnType<typeof useSendContactMailMutation>;
export type SendContactMailMutationResult = Apollo.MutationResult<SendContactMailMutation>;
export type SendContactMailMutationOptions = Apollo.BaseMutationOptions<SendContactMailMutation, SendContactMailMutationVariables>;
export const CreateRoleSugestionDocument = gql`
    mutation CreateRoleSugestion($wineEntityId: ID!, $roleName: String!, $description: String) {
  CreateRoleSugestion(
    wineEntityId: $wineEntityId
    roleName: $roleName
    description: $description
  )
}
    `;
export type CreateRoleSugestionMutationFn = Apollo.MutationFunction<CreateRoleSugestionMutation, CreateRoleSugestionMutationVariables>;

/**
 * __useCreateRoleSugestionMutation__
 *
 * To run a mutation, you first call `useCreateRoleSugestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleSugestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleSugestionMutation, { data, loading, error }] = useCreateRoleSugestionMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      roleName: // value for 'roleName'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateRoleSugestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleSugestionMutation, CreateRoleSugestionMutationVariables>) {
        return Apollo.useMutation<CreateRoleSugestionMutation, CreateRoleSugestionMutationVariables>(CreateRoleSugestionDocument, baseOptions);
      }
export type CreateRoleSugestionMutationHookResult = ReturnType<typeof useCreateRoleSugestionMutation>;
export type CreateRoleSugestionMutationResult = Apollo.MutationResult<CreateRoleSugestionMutation>;
export type CreateRoleSugestionMutationOptions = Apollo.BaseMutationOptions<CreateRoleSugestionMutation, CreateRoleSugestionMutationVariables>;
export const CreateNewSubscriptionDataDocument = gql`
    mutation CreateNewSubscriptionData($input: NewSubscriptionDataInput!, $freeTrial: Boolean) {
  CreateNewSubscriptionData(input: $input, freeTrial: $freeTrial)
}
    `;
export type CreateNewSubscriptionDataMutationFn = Apollo.MutationFunction<CreateNewSubscriptionDataMutation, CreateNewSubscriptionDataMutationVariables>;

/**
 * __useCreateNewSubscriptionDataMutation__
 *
 * To run a mutation, you first call `useCreateNewSubscriptionDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewSubscriptionDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewSubscriptionDataMutation, { data, loading, error }] = useCreateNewSubscriptionDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      freeTrial: // value for 'freeTrial'
 *   },
 * });
 */
export function useCreateNewSubscriptionDataMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewSubscriptionDataMutation, CreateNewSubscriptionDataMutationVariables>) {
        return Apollo.useMutation<CreateNewSubscriptionDataMutation, CreateNewSubscriptionDataMutationVariables>(CreateNewSubscriptionDataDocument, baseOptions);
      }
export type CreateNewSubscriptionDataMutationHookResult = ReturnType<typeof useCreateNewSubscriptionDataMutation>;
export type CreateNewSubscriptionDataMutationResult = Apollo.MutationResult<CreateNewSubscriptionDataMutation>;
export type CreateNewSubscriptionDataMutationOptions = Apollo.BaseMutationOptions<CreateNewSubscriptionDataMutation, CreateNewSubscriptionDataMutationVariables>;
export const GenerateSubscriptionHostedPageDocument = gql`
    mutation GenerateSubscriptionHostedPage($userId: ID!, $planCode: String!, $subscriptionDataId: ID!, $addonCodes: [String]) {
  GenerateSubscriptionHostedPage(
    userId: $userId
    planCode: $planCode
    subscriptionDataId: $subscriptionDataId
    addonCodes: $addonCodes
  )
}
    `;
export type GenerateSubscriptionHostedPageMutationFn = Apollo.MutationFunction<GenerateSubscriptionHostedPageMutation, GenerateSubscriptionHostedPageMutationVariables>;

/**
 * __useGenerateSubscriptionHostedPageMutation__
 *
 * To run a mutation, you first call `useGenerateSubscriptionHostedPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSubscriptionHostedPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSubscriptionHostedPageMutation, { data, loading, error }] = useGenerateSubscriptionHostedPageMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      planCode: // value for 'planCode'
 *      subscriptionDataId: // value for 'subscriptionDataId'
 *      addonCodes: // value for 'addonCodes'
 *   },
 * });
 */
export function useGenerateSubscriptionHostedPageMutation(baseOptions?: Apollo.MutationHookOptions<GenerateSubscriptionHostedPageMutation, GenerateSubscriptionHostedPageMutationVariables>) {
        return Apollo.useMutation<GenerateSubscriptionHostedPageMutation, GenerateSubscriptionHostedPageMutationVariables>(GenerateSubscriptionHostedPageDocument, baseOptions);
      }
export type GenerateSubscriptionHostedPageMutationHookResult = ReturnType<typeof useGenerateSubscriptionHostedPageMutation>;
export type GenerateSubscriptionHostedPageMutationResult = Apollo.MutationResult<GenerateSubscriptionHostedPageMutation>;
export type GenerateSubscriptionHostedPageMutationOptions = Apollo.BaseMutationOptions<GenerateSubscriptionHostedPageMutation, GenerateSubscriptionHostedPageMutationVariables>;
export const ChangeEstateVisibilityDocument = gql`
    mutation ChangeEstateVisibility($wineEntityId: ID!, $visibility: Boolean!) {
  ChangeEstateVisibility(wineEntityId: $wineEntityId, visibility: $visibility)
}
    `;
export type ChangeEstateVisibilityMutationFn = Apollo.MutationFunction<ChangeEstateVisibilityMutation, ChangeEstateVisibilityMutationVariables>;

/**
 * __useChangeEstateVisibilityMutation__
 *
 * To run a mutation, you first call `useChangeEstateVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEstateVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEstateVisibilityMutation, { data, loading, error }] = useChangeEstateVisibilityMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      visibility: // value for 'visibility'
 *   },
 * });
 */
export function useChangeEstateVisibilityMutation(baseOptions?: Apollo.MutationHookOptions<ChangeEstateVisibilityMutation, ChangeEstateVisibilityMutationVariables>) {
        return Apollo.useMutation<ChangeEstateVisibilityMutation, ChangeEstateVisibilityMutationVariables>(ChangeEstateVisibilityDocument, baseOptions);
      }
export type ChangeEstateVisibilityMutationHookResult = ReturnType<typeof useChangeEstateVisibilityMutation>;
export type ChangeEstateVisibilityMutationResult = Apollo.MutationResult<ChangeEstateVisibilityMutation>;
export type ChangeEstateVisibilityMutationOptions = Apollo.BaseMutationOptions<ChangeEstateVisibilityMutation, ChangeEstateVisibilityMutationVariables>;
export const EditNoDisplayModalDocument = gql`
    mutation EditNoDisplayModal($userId: ID!, $noDisplayModal: [String]!) {
  EditNoDisplayModal(userId: $userId, noDisplayModal: $noDisplayModal)
}
    `;
export type EditNoDisplayModalMutationFn = Apollo.MutationFunction<EditNoDisplayModalMutation, EditNoDisplayModalMutationVariables>;

/**
 * __useEditNoDisplayModalMutation__
 *
 * To run a mutation, you first call `useEditNoDisplayModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNoDisplayModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNoDisplayModalMutation, { data, loading, error }] = useEditNoDisplayModalMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      noDisplayModal: // value for 'noDisplayModal'
 *   },
 * });
 */
export function useEditNoDisplayModalMutation(baseOptions?: Apollo.MutationHookOptions<EditNoDisplayModalMutation, EditNoDisplayModalMutationVariables>) {
        return Apollo.useMutation<EditNoDisplayModalMutation, EditNoDisplayModalMutationVariables>(EditNoDisplayModalDocument, baseOptions);
      }
export type EditNoDisplayModalMutationHookResult = ReturnType<typeof useEditNoDisplayModalMutation>;
export type EditNoDisplayModalMutationResult = Apollo.MutationResult<EditNoDisplayModalMutation>;
export type EditNoDisplayModalMutationOptions = Apollo.BaseMutationOptions<EditNoDisplayModalMutation, EditNoDisplayModalMutationVariables>;
export const CreateServiceRequestDocument = gql`
    mutation CreateServiceRequest($input: ServiceRequestInput!) {
  CreateServiceRequest(input: $input)
}
    `;
export type CreateServiceRequestMutationFn = Apollo.MutationFunction<CreateServiceRequestMutation, CreateServiceRequestMutationVariables>;

/**
 * __useCreateServiceRequestMutation__
 *
 * To run a mutation, you first call `useCreateServiceRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceRequestMutation, { data, loading, error }] = useCreateServiceRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceRequestMutation, CreateServiceRequestMutationVariables>) {
        return Apollo.useMutation<CreateServiceRequestMutation, CreateServiceRequestMutationVariables>(CreateServiceRequestDocument, baseOptions);
      }
export type CreateServiceRequestMutationHookResult = ReturnType<typeof useCreateServiceRequestMutation>;
export type CreateServiceRequestMutationResult = Apollo.MutationResult<CreateServiceRequestMutation>;
export type CreateServiceRequestMutationOptions = Apollo.BaseMutationOptions<CreateServiceRequestMutation, CreateServiceRequestMutationVariables>;
export const GenerateHostedPageAddonDocument = gql`
    mutation GenerateHostedPageAddon($wineEntityId: ID!, $addonCodes: [addonInput]!) {
  GenerateHostedPageAddon(wineEntityId: $wineEntityId, addonCodes: $addonCodes)
}
    `;
export type GenerateHostedPageAddonMutationFn = Apollo.MutationFunction<GenerateHostedPageAddonMutation, GenerateHostedPageAddonMutationVariables>;

/**
 * __useGenerateHostedPageAddonMutation__
 *
 * To run a mutation, you first call `useGenerateHostedPageAddonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateHostedPageAddonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateHostedPageAddonMutation, { data, loading, error }] = useGenerateHostedPageAddonMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      addonCodes: // value for 'addonCodes'
 *   },
 * });
 */
export function useGenerateHostedPageAddonMutation(baseOptions?: Apollo.MutationHookOptions<GenerateHostedPageAddonMutation, GenerateHostedPageAddonMutationVariables>) {
        return Apollo.useMutation<GenerateHostedPageAddonMutation, GenerateHostedPageAddonMutationVariables>(GenerateHostedPageAddonDocument, baseOptions);
      }
export type GenerateHostedPageAddonMutationHookResult = ReturnType<typeof useGenerateHostedPageAddonMutation>;
export type GenerateHostedPageAddonMutationResult = Apollo.MutationResult<GenerateHostedPageAddonMutation>;
export type GenerateHostedPageAddonMutationOptions = Apollo.BaseMutationOptions<GenerateHostedPageAddonMutation, GenerateHostedPageAddonMutationVariables>;
export const CancelSubscriptionDataDocument = gql`
    mutation CancelSubscriptionData($subscriptionDataId: ID!) {
  CancelSubscriptionData(subscriptionDataId: $subscriptionDataId)
}
    `;
export type CancelSubscriptionDataMutationFn = Apollo.MutationFunction<CancelSubscriptionDataMutation, CancelSubscriptionDataMutationVariables>;

/**
 * __useCancelSubscriptionDataMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionDataMutation, { data, loading, error }] = useCancelSubscriptionDataMutation({
 *   variables: {
 *      subscriptionDataId: // value for 'subscriptionDataId'
 *   },
 * });
 */
export function useCancelSubscriptionDataMutation(baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionDataMutation, CancelSubscriptionDataMutationVariables>) {
        return Apollo.useMutation<CancelSubscriptionDataMutation, CancelSubscriptionDataMutationVariables>(CancelSubscriptionDataDocument, baseOptions);
      }
export type CancelSubscriptionDataMutationHookResult = ReturnType<typeof useCancelSubscriptionDataMutation>;
export type CancelSubscriptionDataMutationResult = Apollo.MutationResult<CancelSubscriptionDataMutation>;
export type CancelSubscriptionDataMutationOptions = Apollo.BaseMutationOptions<CancelSubscriptionDataMutation, CancelSubscriptionDataMutationVariables>;
export const EditPaperSubcriptionDocument = gql`
    mutation EditPaperSubcription($wineEntityId: ID!, $paperSubsciptionName: String!) {
  EditPaperSubcription(
    wineEntityId: $wineEntityId
    paperSubsciptionName: $paperSubsciptionName
  )
}
    `;
export type EditPaperSubcriptionMutationFn = Apollo.MutationFunction<EditPaperSubcriptionMutation, EditPaperSubcriptionMutationVariables>;

/**
 * __useEditPaperSubcriptionMutation__
 *
 * To run a mutation, you first call `useEditPaperSubcriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPaperSubcriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPaperSubcriptionMutation, { data, loading, error }] = useEditPaperSubcriptionMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      paperSubsciptionName: // value for 'paperSubsciptionName'
 *   },
 * });
 */
export function useEditPaperSubcriptionMutation(baseOptions?: Apollo.MutationHookOptions<EditPaperSubcriptionMutation, EditPaperSubcriptionMutationVariables>) {
        return Apollo.useMutation<EditPaperSubcriptionMutation, EditPaperSubcriptionMutationVariables>(EditPaperSubcriptionDocument, baseOptions);
      }
export type EditPaperSubcriptionMutationHookResult = ReturnType<typeof useEditPaperSubcriptionMutation>;
export type EditPaperSubcriptionMutationResult = Apollo.MutationResult<EditPaperSubcriptionMutation>;
export type EditPaperSubcriptionMutationOptions = Apollo.BaseMutationOptions<EditPaperSubcriptionMutation, EditPaperSubcriptionMutationVariables>;
export const CheckEmailAvailabilityDocument = gql`
    mutation CheckEmailAvailability($email: String!, $userId: ID) {
  CheckEmailAvailability(email: $email, userId: $userId)
}
    `;
export type CheckEmailAvailabilityMutationFn = Apollo.MutationFunction<CheckEmailAvailabilityMutation, CheckEmailAvailabilityMutationVariables>;

/**
 * __useCheckEmailAvailabilityMutation__
 *
 * To run a mutation, you first call `useCheckEmailAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckEmailAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkEmailAvailabilityMutation, { data, loading, error }] = useCheckEmailAvailabilityMutation({
 *   variables: {
 *      email: // value for 'email'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCheckEmailAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<CheckEmailAvailabilityMutation, CheckEmailAvailabilityMutationVariables>) {
        return Apollo.useMutation<CheckEmailAvailabilityMutation, CheckEmailAvailabilityMutationVariables>(CheckEmailAvailabilityDocument, baseOptions);
      }
export type CheckEmailAvailabilityMutationHookResult = ReturnType<typeof useCheckEmailAvailabilityMutation>;
export type CheckEmailAvailabilityMutationResult = Apollo.MutationResult<CheckEmailAvailabilityMutation>;
export type CheckEmailAvailabilityMutationOptions = Apollo.BaseMutationOptions<CheckEmailAvailabilityMutation, CheckEmailAvailabilityMutationVariables>;
export const EditTastingRequestDocument = gql`
    mutation EditTastingRequest($tastingRequestId: ID!, $input: TastingRequestInput!, $locale: String) {
  EditTastingRequest(
    tastingRequestId: $tastingRequestId
    input: $input
    locale: $locale
  )
}
    `;
export type EditTastingRequestMutationFn = Apollo.MutationFunction<EditTastingRequestMutation, EditTastingRequestMutationVariables>;

/**
 * __useEditTastingRequestMutation__
 *
 * To run a mutation, you first call `useEditTastingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTastingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTastingRequestMutation, { data, loading, error }] = useEditTastingRequestMutation({
 *   variables: {
 *      tastingRequestId: // value for 'tastingRequestId'
 *      input: // value for 'input'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useEditTastingRequestMutation(baseOptions?: Apollo.MutationHookOptions<EditTastingRequestMutation, EditTastingRequestMutationVariables>) {
        return Apollo.useMutation<EditTastingRequestMutation, EditTastingRequestMutationVariables>(EditTastingRequestDocument, baseOptions);
      }
export type EditTastingRequestMutationHookResult = ReturnType<typeof useEditTastingRequestMutation>;
export type EditTastingRequestMutationResult = Apollo.MutationResult<EditTastingRequestMutation>;
export type EditTastingRequestMutationOptions = Apollo.BaseMutationOptions<EditTastingRequestMutation, EditTastingRequestMutationVariables>;
export const EditTastingRequestStatusDocument = gql`
    mutation EditTastingRequestStatus($tastingRequestId: ID!, $status: String!) {
  EditTastingRequestStatus(tastingRequestId: $tastingRequestId, status: $status)
}
    `;
export type EditTastingRequestStatusMutationFn = Apollo.MutationFunction<EditTastingRequestStatusMutation, EditTastingRequestStatusMutationVariables>;

/**
 * __useEditTastingRequestStatusMutation__
 *
 * To run a mutation, you first call `useEditTastingRequestStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTastingRequestStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTastingRequestStatusMutation, { data, loading, error }] = useEditTastingRequestStatusMutation({
 *   variables: {
 *      tastingRequestId: // value for 'tastingRequestId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useEditTastingRequestStatusMutation(baseOptions?: Apollo.MutationHookOptions<EditTastingRequestStatusMutation, EditTastingRequestStatusMutationVariables>) {
        return Apollo.useMutation<EditTastingRequestStatusMutation, EditTastingRequestStatusMutationVariables>(EditTastingRequestStatusDocument, baseOptions);
      }
export type EditTastingRequestStatusMutationHookResult = ReturnType<typeof useEditTastingRequestStatusMutation>;
export type EditTastingRequestStatusMutationResult = Apollo.MutationResult<EditTastingRequestStatusMutation>;
export type EditTastingRequestStatusMutationOptions = Apollo.BaseMutationOptions<EditTastingRequestStatusMutation, EditTastingRequestStatusMutationVariables>;
export const GenerateHostedPageUpgradeDocument = gql`
    mutation GenerateHostedPageUpgrade($wineEntityId: ID!, $planName: String!, $addonCodes: [addonInput]) {
  GenerateHostedPageUpgrade(
    wineEntityId: $wineEntityId
    planName: $planName
    addonCodes: $addonCodes
  )
}
    `;
export type GenerateHostedPageUpgradeMutationFn = Apollo.MutationFunction<GenerateHostedPageUpgradeMutation, GenerateHostedPageUpgradeMutationVariables>;

/**
 * __useGenerateHostedPageUpgradeMutation__
 *
 * To run a mutation, you first call `useGenerateHostedPageUpgradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateHostedPageUpgradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateHostedPageUpgradeMutation, { data, loading, error }] = useGenerateHostedPageUpgradeMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      planName: // value for 'planName'
 *      addonCodes: // value for 'addonCodes'
 *   },
 * });
 */
export function useGenerateHostedPageUpgradeMutation(baseOptions?: Apollo.MutationHookOptions<GenerateHostedPageUpgradeMutation, GenerateHostedPageUpgradeMutationVariables>) {
        return Apollo.useMutation<GenerateHostedPageUpgradeMutation, GenerateHostedPageUpgradeMutationVariables>(GenerateHostedPageUpgradeDocument, baseOptions);
      }
export type GenerateHostedPageUpgradeMutationHookResult = ReturnType<typeof useGenerateHostedPageUpgradeMutation>;
export type GenerateHostedPageUpgradeMutationResult = Apollo.MutationResult<GenerateHostedPageUpgradeMutation>;
export type GenerateHostedPageUpgradeMutationOptions = Apollo.BaseMutationOptions<GenerateHostedPageUpgradeMutation, GenerateHostedPageUpgradeMutationVariables>;
export const CreateTastingRequestsDocument = gql`
    mutation CreateTastingRequests($tastingRequests: [TastingRequestInput]) {
  CreateTastingRequests(tastingRequests: $tastingRequests)
}
    `;
export type CreateTastingRequestsMutationFn = Apollo.MutationFunction<CreateTastingRequestsMutation, CreateTastingRequestsMutationVariables>;

/**
 * __useCreateTastingRequestsMutation__
 *
 * To run a mutation, you first call `useCreateTastingRequestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTastingRequestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTastingRequestsMutation, { data, loading, error }] = useCreateTastingRequestsMutation({
 *   variables: {
 *      tastingRequests: // value for 'tastingRequests'
 *   },
 * });
 */
export function useCreateTastingRequestsMutation(baseOptions?: Apollo.MutationHookOptions<CreateTastingRequestsMutation, CreateTastingRequestsMutationVariables>) {
        return Apollo.useMutation<CreateTastingRequestsMutation, CreateTastingRequestsMutationVariables>(CreateTastingRequestsDocument, baseOptions);
      }
export type CreateTastingRequestsMutationHookResult = ReturnType<typeof useCreateTastingRequestsMutation>;
export type CreateTastingRequestsMutationResult = Apollo.MutationResult<CreateTastingRequestsMutation>;
export type CreateTastingRequestsMutationOptions = Apollo.BaseMutationOptions<CreateTastingRequestsMutation, CreateTastingRequestsMutationVariables>;
export const UpdateFtpDiffusionsDocument = gql`
    mutation UpdateFtpDiffusions($wineEntityId: ID, $ftIdInputs: [ftIdInput]) {
  UpdateFtpDiffusions(wineEntityId: $wineEntityId, ftIdInputs: $ftIdInputs)
}
    `;
export type UpdateFtpDiffusionsMutationFn = Apollo.MutationFunction<UpdateFtpDiffusionsMutation, UpdateFtpDiffusionsMutationVariables>;

/**
 * __useUpdateFtpDiffusionsMutation__
 *
 * To run a mutation, you first call `useUpdateFtpDiffusionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFtpDiffusionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFtpDiffusionsMutation, { data, loading, error }] = useUpdateFtpDiffusionsMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      ftIdInputs: // value for 'ftIdInputs'
 *   },
 * });
 */
export function useUpdateFtpDiffusionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFtpDiffusionsMutation, UpdateFtpDiffusionsMutationVariables>) {
        return Apollo.useMutation<UpdateFtpDiffusionsMutation, UpdateFtpDiffusionsMutationVariables>(UpdateFtpDiffusionsDocument, baseOptions);
      }
export type UpdateFtpDiffusionsMutationHookResult = ReturnType<typeof useUpdateFtpDiffusionsMutation>;
export type UpdateFtpDiffusionsMutationResult = Apollo.MutationResult<UpdateFtpDiffusionsMutation>;
export type UpdateFtpDiffusionsMutationOptions = Apollo.BaseMutationOptions<UpdateFtpDiffusionsMutation, UpdateFtpDiffusionsMutationVariables>;
export const DiffuseOneDocument = gql`
    mutation DiffuseOne($ftpDiffusionId: ID) {
  DiffuseOne(ftpDiffusionId: $ftpDiffusionId)
}
    `;
export type DiffuseOneMutationFn = Apollo.MutationFunction<DiffuseOneMutation, DiffuseOneMutationVariables>;

/**
 * __useDiffuseOneMutation__
 *
 * To run a mutation, you first call `useDiffuseOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiffuseOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [diffuseOneMutation, { data, loading, error }] = useDiffuseOneMutation({
 *   variables: {
 *      ftpDiffusionId: // value for 'ftpDiffusionId'
 *   },
 * });
 */
export function useDiffuseOneMutation(baseOptions?: Apollo.MutationHookOptions<DiffuseOneMutation, DiffuseOneMutationVariables>) {
        return Apollo.useMutation<DiffuseOneMutation, DiffuseOneMutationVariables>(DiffuseOneDocument, baseOptions);
      }
export type DiffuseOneMutationHookResult = ReturnType<typeof useDiffuseOneMutation>;
export type DiffuseOneMutationResult = Apollo.MutationResult<DiffuseOneMutation>;
export type DiffuseOneMutationOptions = Apollo.BaseMutationOptions<DiffuseOneMutation, DiffuseOneMutationVariables>;
export const SavePaperSubscriptionDataDocument = gql`
    mutation SavePaperSubscriptionData($wineEntityId: ID!, $accessibility: [String], $isBookingRequired: Boolean, $languages: [String], $priceRange: [Int], $touristLabels: String, $visitSchedule: String, $hasOtherCulture: Boolean, $otherCulture: String, $bugFightMethod: String, $description: LngStringInput, $byGrassing: Boolean, $grassingMethod: String, $done: Boolean, $redVineSurface: Int, $whiteVineSurface: Int, $redAvgProd: Int, $whiteAvgProd: Int, $redWineAssembly: [SimpleGrapeVarietyPartInput], $whiteWineAssembly: [SimpleGrapeVarietyPartInput], $certificationFiles: [DocumentInput]) {
  SavePaperSubscriptionData(
    wineEntityId: $wineEntityId
    accessibility: $accessibility
    isBookingRequired: $isBookingRequired
    languages: $languages
    priceRange: $priceRange
    touristLabels: $touristLabels
    visitSchedule: $visitSchedule
    hasOtherCulture: $hasOtherCulture
    otherCulture: $otherCulture
    bugFightMethod: $bugFightMethod
    description: $description
    byGrassing: $byGrassing
    grassingMethod: $grassingMethod
    done: $done
    redVineSurface: $redVineSurface
    whiteVineSurface: $whiteVineSurface
    redAvgProd: $redAvgProd
    whiteAvgProd: $whiteAvgProd
    redWineAssembly: $redWineAssembly
    whiteWineAssembly: $whiteWineAssembly
    certificationFiles: $certificationFiles
  )
}
    `;
export type SavePaperSubscriptionDataMutationFn = Apollo.MutationFunction<SavePaperSubscriptionDataMutation, SavePaperSubscriptionDataMutationVariables>;

/**
 * __useSavePaperSubscriptionDataMutation__
 *
 * To run a mutation, you first call `useSavePaperSubscriptionDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePaperSubscriptionDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePaperSubscriptionDataMutation, { data, loading, error }] = useSavePaperSubscriptionDataMutation({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      accessibility: // value for 'accessibility'
 *      isBookingRequired: // value for 'isBookingRequired'
 *      languages: // value for 'languages'
 *      priceRange: // value for 'priceRange'
 *      touristLabels: // value for 'touristLabels'
 *      visitSchedule: // value for 'visitSchedule'
 *      hasOtherCulture: // value for 'hasOtherCulture'
 *      otherCulture: // value for 'otherCulture'
 *      bugFightMethod: // value for 'bugFightMethod'
 *      description: // value for 'description'
 *      byGrassing: // value for 'byGrassing'
 *      grassingMethod: // value for 'grassingMethod'
 *      done: // value for 'done'
 *      redVineSurface: // value for 'redVineSurface'
 *      whiteVineSurface: // value for 'whiteVineSurface'
 *      redAvgProd: // value for 'redAvgProd'
 *      whiteAvgProd: // value for 'whiteAvgProd'
 *      redWineAssembly: // value for 'redWineAssembly'
 *      whiteWineAssembly: // value for 'whiteWineAssembly'
 *      certificationFiles: // value for 'certificationFiles'
 *   },
 * });
 */
export function useSavePaperSubscriptionDataMutation(baseOptions?: Apollo.MutationHookOptions<SavePaperSubscriptionDataMutation, SavePaperSubscriptionDataMutationVariables>) {
        return Apollo.useMutation<SavePaperSubscriptionDataMutation, SavePaperSubscriptionDataMutationVariables>(SavePaperSubscriptionDataDocument, baseOptions);
      }
export type SavePaperSubscriptionDataMutationHookResult = ReturnType<typeof useSavePaperSubscriptionDataMutation>;
export type SavePaperSubscriptionDataMutationResult = Apollo.MutationResult<SavePaperSubscriptionDataMutation>;
export type SavePaperSubscriptionDataMutationOptions = Apollo.BaseMutationOptions<SavePaperSubscriptionDataMutation, SavePaperSubscriptionDataMutationVariables>;
export const GetSimpleUserByIdDocument = gql`
    query getSimpleUserById($userId: ID!) {
  GetSimpleUserById(userId: $userId) {
    isSu
    userId
    email
    title
    firstName
    lastName
    telephoneNumber
    freeTrial
    subscriptionName
    subscriptionPaperName
    subscriptionRecurrence
    zohoSubscriptionId
    wineEntityId
    wineEntityShortId
    shortWineEntityId
    wineEntityName
    wineEntityImages {
      data
      fileName
      mainImage
      backgroundImage
    }
    status
    mainAppellation
    mainCommune
    checkNeeded
    ownerOperatorChecked
    sideBarImage {
      data
      fileName
    }
    isProfessional
    company
    category
    addonCodes
    paymentStatus
    paymentMethod
    language
    cde_exp
  }
}
    `;

/**
 * __useGetSimpleUserByIdQuery__
 *
 * To run a query within a React component, call `useGetSimpleUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimpleUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimpleUserByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetSimpleUserByIdQuery(baseOptions: Apollo.QueryHookOptions<GetSimpleUserByIdQuery, GetSimpleUserByIdQueryVariables>) {
        return Apollo.useQuery<GetSimpleUserByIdQuery, GetSimpleUserByIdQueryVariables>(GetSimpleUserByIdDocument, baseOptions);
      }
export function useGetSimpleUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSimpleUserByIdQuery, GetSimpleUserByIdQueryVariables>) {
          return Apollo.useLazyQuery<GetSimpleUserByIdQuery, GetSimpleUserByIdQueryVariables>(GetSimpleUserByIdDocument, baseOptions);
        }
export type GetSimpleUserByIdQueryHookResult = ReturnType<typeof useGetSimpleUserByIdQuery>;
export type GetSimpleUserByIdLazyQueryHookResult = ReturnType<typeof useGetSimpleUserByIdLazyQuery>;
export type GetSimpleUserByIdQueryResult = Apollo.QueryResult<GetSimpleUserByIdQuery, GetSimpleUserByIdQueryVariables>;
export const AboutHistoryDocument = gql`
    query AboutHistory($wineEntityId: ID) {
  AboutHistory(wineEntityId: $wineEntityId) {
    description
  }
}
    `;

/**
 * __useAboutHistoryQuery__
 *
 * To run a query within a React component, call `useAboutHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutHistoryQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useAboutHistoryQuery(baseOptions?: Apollo.QueryHookOptions<AboutHistoryQuery, AboutHistoryQueryVariables>) {
        return Apollo.useQuery<AboutHistoryQuery, AboutHistoryQueryVariables>(AboutHistoryDocument, baseOptions);
      }
export function useAboutHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AboutHistoryQuery, AboutHistoryQueryVariables>) {
          return Apollo.useLazyQuery<AboutHistoryQuery, AboutHistoryQueryVariables>(AboutHistoryDocument, baseOptions);
        }
export type AboutHistoryQueryHookResult = ReturnType<typeof useAboutHistoryQuery>;
export type AboutHistoryLazyQueryHookResult = ReturnType<typeof useAboutHistoryLazyQuery>;
export type AboutHistoryQueryResult = Apollo.QueryResult<AboutHistoryQuery, AboutHistoryQueryVariables>;
export const ListWinesDocument = gql`
    query ListWines($wineEntityId: ID) {
  ListWines(wineEntityId: $wineEntityId) {
    wineId
    brandName
    appellationLabel
    type
    color
    sweetness
    fullAppellation
    rank
    bottleImage {
      data
    }
    vintages {
      _id
      year
      cuvee
    }
  }
}
    `;

/**
 * __useListWinesQuery__
 *
 * To run a query within a React component, call `useListWinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWinesQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useListWinesQuery(baseOptions?: Apollo.QueryHookOptions<ListWinesQuery, ListWinesQueryVariables>) {
        return Apollo.useQuery<ListWinesQuery, ListWinesQueryVariables>(ListWinesDocument, baseOptions);
      }
export function useListWinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListWinesQuery, ListWinesQueryVariables>) {
          return Apollo.useLazyQuery<ListWinesQuery, ListWinesQueryVariables>(ListWinesDocument, baseOptions);
        }
export type ListWinesQueryHookResult = ReturnType<typeof useListWinesQuery>;
export type ListWinesLazyQueryHookResult = ReturnType<typeof useListWinesLazyQuery>;
export type ListWinesQueryResult = Apollo.QueryResult<ListWinesQuery, ListWinesQueryVariables>;
export const GetWineByIdDocument = gql`
    query GetWineById($wineId: ID!) {
  GetWineById(wineId: $wineId) {
    brandName
    wineEntityId
    images {
      file {
        fileName
      }
    }
    label {
      value
      valueLng {
        fr
        locale
      }
    }
    color {
      value
    }
    type {
      value
    }
    sweetness {
      value
    }
    soils {
      value
    }
    subsoils {
      value
    }
  }
}
    `;

/**
 * __useGetWineByIdQuery__
 *
 * To run a query within a React component, call `useGetWineByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWineByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWineByIdQuery({
 *   variables: {
 *      wineId: // value for 'wineId'
 *   },
 * });
 */
export function useGetWineByIdQuery(baseOptions: Apollo.QueryHookOptions<GetWineByIdQuery, GetWineByIdQueryVariables>) {
        return Apollo.useQuery<GetWineByIdQuery, GetWineByIdQueryVariables>(GetWineByIdDocument, baseOptions);
      }
export function useGetWineByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWineByIdQuery, GetWineByIdQueryVariables>) {
          return Apollo.useLazyQuery<GetWineByIdQuery, GetWineByIdQueryVariables>(GetWineByIdDocument, baseOptions);
        }
export type GetWineByIdQueryHookResult = ReturnType<typeof useGetWineByIdQuery>;
export type GetWineByIdLazyQueryHookResult = ReturnType<typeof useGetWineByIdLazyQuery>;
export type GetWineByIdQueryResult = Apollo.QueryResult<GetWineByIdQuery, GetWineByIdQueryVariables>;
export const GetEnumByFieldDocument = gql`
    query GetEnumByField($field: String!) {
  GetEnumByField(field: $field) {
    title
    value
    conditionIndex
    valueLng {
      fr
      de
      en
      es
      it
      nl
      ja
      zh
    }
  }
}
    `;

/**
 * __useGetEnumByFieldQuery__
 *
 * To run a query within a React component, call `useGetEnumByFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnumByFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnumByFieldQuery({
 *   variables: {
 *      field: // value for 'field'
 *   },
 * });
 */
export function useGetEnumByFieldQuery(baseOptions: Apollo.QueryHookOptions<GetEnumByFieldQuery, GetEnumByFieldQueryVariables>) {
        return Apollo.useQuery<GetEnumByFieldQuery, GetEnumByFieldQueryVariables>(GetEnumByFieldDocument, baseOptions);
      }
export function useGetEnumByFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnumByFieldQuery, GetEnumByFieldQueryVariables>) {
          return Apollo.useLazyQuery<GetEnumByFieldQuery, GetEnumByFieldQueryVariables>(GetEnumByFieldDocument, baseOptions);
        }
export type GetEnumByFieldQueryHookResult = ReturnType<typeof useGetEnumByFieldQuery>;
export type GetEnumByFieldLazyQueryHookResult = ReturnType<typeof useGetEnumByFieldLazyQuery>;
export type GetEnumByFieldQueryResult = Apollo.QueryResult<GetEnumByFieldQuery, GetEnumByFieldQueryVariables>;
export const GetRoleEnumDocument = gql`
    query GetRoleEnum($type: String!, $wineEntityId: ID!, $personId: ID) {
  GetRoleEnum(type: $type, wineEntityId: $wineEntityId, personId: $personId) {
    title
    value
    conditionIndex
    valueLng {
      fr
      de
      en
      es
      it
      nl
      ja
      zh
    }
  }
}
    `;

/**
 * __useGetRoleEnumQuery__
 *
 * To run a query within a React component, call `useGetRoleEnumQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleEnumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleEnumQuery({
 *   variables: {
 *      type: // value for 'type'
 *      wineEntityId: // value for 'wineEntityId'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetRoleEnumQuery(baseOptions: Apollo.QueryHookOptions<GetRoleEnumQuery, GetRoleEnumQueryVariables>) {
        return Apollo.useQuery<GetRoleEnumQuery, GetRoleEnumQueryVariables>(GetRoleEnumDocument, baseOptions);
      }
export function useGetRoleEnumLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleEnumQuery, GetRoleEnumQueryVariables>) {
          return Apollo.useLazyQuery<GetRoleEnumQuery, GetRoleEnumQueryVariables>(GetRoleEnumDocument, baseOptions);
        }
export type GetRoleEnumQueryHookResult = ReturnType<typeof useGetRoleEnumQuery>;
export type GetRoleEnumLazyQueryHookResult = ReturnType<typeof useGetRoleEnumLazyQuery>;
export type GetRoleEnumQueryResult = Apollo.QueryResult<GetRoleEnumQuery, GetRoleEnumQueryVariables>;
export const GetVintagesByWineIdDocument = gql`
    query GetVintagesByWineId($wineId: ID!) {
  GetVintagesByWineId(wineId: $wineId) {
    _id
    wineEntityId
    wineId
    year
    cuvee
  }
}
    `;

/**
 * __useGetVintagesByWineIdQuery__
 *
 * To run a query within a React component, call `useGetVintagesByWineIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVintagesByWineIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVintagesByWineIdQuery({
 *   variables: {
 *      wineId: // value for 'wineId'
 *   },
 * });
 */
export function useGetVintagesByWineIdQuery(baseOptions: Apollo.QueryHookOptions<GetVintagesByWineIdQuery, GetVintagesByWineIdQueryVariables>) {
        return Apollo.useQuery<GetVintagesByWineIdQuery, GetVintagesByWineIdQueryVariables>(GetVintagesByWineIdDocument, baseOptions);
      }
export function useGetVintagesByWineIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVintagesByWineIdQuery, GetVintagesByWineIdQueryVariables>) {
          return Apollo.useLazyQuery<GetVintagesByWineIdQuery, GetVintagesByWineIdQueryVariables>(GetVintagesByWineIdDocument, baseOptions);
        }
export type GetVintagesByWineIdQueryHookResult = ReturnType<typeof useGetVintagesByWineIdQuery>;
export type GetVintagesByWineIdLazyQueryHookResult = ReturnType<typeof useGetVintagesByWineIdLazyQuery>;
export type GetVintagesByWineIdQueryResult = Apollo.QueryResult<GetVintagesByWineIdQuery, GetVintagesByWineIdQueryVariables>;
export const SurfaceDeProductionScreenDocument = gql`
    query SurfaceDeProductionScreen($wineEntityId: ID!) {
  SurfaceDeProductionScreen(wineEntityId: $wineEntityId) {
    surfaceImportCVI
    superficieVigne
    densiteMoyenneDePlantation
    ageMoyenDesVignes
    surfaces {
      appellation
      superficie
      couleur
      ageMoyenDesVignes
      densiteDePlantation
      communes {
        commune
        superficie
        lieuDits {
          lieuDit
          superficie
        }
      }
      encepagements {
        cepage
        superficie
      }
      vinesCount
      rootstocks
      parcelCount
      localities
    }
    hiddenSurfaces {
      ageMoyenDesVignes
      densiteDePlantation
      communes
      encepagements
      vinesCount
      rootstocks
      parcelCount
    }
    totalParcelCount
    rootstocksTotal
    dateImportCvi
  }
}
    `;

/**
 * __useSurfaceDeProductionScreenQuery__
 *
 * To run a query within a React component, call `useSurfaceDeProductionScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurfaceDeProductionScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurfaceDeProductionScreenQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useSurfaceDeProductionScreenQuery(baseOptions: Apollo.QueryHookOptions<SurfaceDeProductionScreenQuery, SurfaceDeProductionScreenQueryVariables>) {
        return Apollo.useQuery<SurfaceDeProductionScreenQuery, SurfaceDeProductionScreenQueryVariables>(SurfaceDeProductionScreenDocument, baseOptions);
      }
export function useSurfaceDeProductionScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurfaceDeProductionScreenQuery, SurfaceDeProductionScreenQueryVariables>) {
          return Apollo.useLazyQuery<SurfaceDeProductionScreenQuery, SurfaceDeProductionScreenQueryVariables>(SurfaceDeProductionScreenDocument, baseOptions);
        }
export type SurfaceDeProductionScreenQueryHookResult = ReturnType<typeof useSurfaceDeProductionScreenQuery>;
export type SurfaceDeProductionScreenLazyQueryHookResult = ReturnType<typeof useSurfaceDeProductionScreenLazyQuery>;
export type SurfaceDeProductionScreenQueryResult = Apollo.QueryResult<SurfaceDeProductionScreenQuery, SurfaceDeProductionScreenQueryVariables>;
export const GetAppellationDependencyObjectDocument = gql`
    query getAppellationDependencyObject {
  AppellationDependencyObject(dependentFields: [color, type, sweetness])
}
    `;

/**
 * __useGetAppellationDependencyObjectQuery__
 *
 * To run a query within a React component, call `useGetAppellationDependencyObjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppellationDependencyObjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppellationDependencyObjectQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppellationDependencyObjectQuery(baseOptions?: Apollo.QueryHookOptions<GetAppellationDependencyObjectQuery, GetAppellationDependencyObjectQueryVariables>) {
        return Apollo.useQuery<GetAppellationDependencyObjectQuery, GetAppellationDependencyObjectQueryVariables>(GetAppellationDependencyObjectDocument, baseOptions);
      }
export function useGetAppellationDependencyObjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppellationDependencyObjectQuery, GetAppellationDependencyObjectQueryVariables>) {
          return Apollo.useLazyQuery<GetAppellationDependencyObjectQuery, GetAppellationDependencyObjectQueryVariables>(GetAppellationDependencyObjectDocument, baseOptions);
        }
export type GetAppellationDependencyObjectQueryHookResult = ReturnType<typeof useGetAppellationDependencyObjectQuery>;
export type GetAppellationDependencyObjectLazyQueryHookResult = ReturnType<typeof useGetAppellationDependencyObjectLazyQuery>;
export type GetAppellationDependencyObjectQueryResult = Apollo.QueryResult<GetAppellationDependencyObjectQuery, GetAppellationDependencyObjectQueryVariables>;
export const TeamMemberBocDocument = gql`
    query TeamMemberBoc($wineEntityId: ID!, $personId: ID!) {
  TeamMemberBoc(wineEntityId: $wineEntityId, personId: $personId) {
    personId
    kind
    title
    firstName
    lastName
    roleComplement
    organizationName
    organizationStatus
    hidden
    proFunctions
    feminineProFunction
    dateOfBirth
    email
    telephone
    vip
    wasVip
    description {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    images {
      data
      fileName
      mainImage
      backgroundImage
      caption
    }
  }
}
    `;

/**
 * __useTeamMemberBocQuery__
 *
 * To run a query within a React component, call `useTeamMemberBocQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMemberBocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMemberBocQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useTeamMemberBocQuery(baseOptions: Apollo.QueryHookOptions<TeamMemberBocQuery, TeamMemberBocQueryVariables>) {
        return Apollo.useQuery<TeamMemberBocQuery, TeamMemberBocQueryVariables>(TeamMemberBocDocument, baseOptions);
      }
export function useTeamMemberBocLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamMemberBocQuery, TeamMemberBocQueryVariables>) {
          return Apollo.useLazyQuery<TeamMemberBocQuery, TeamMemberBocQueryVariables>(TeamMemberBocDocument, baseOptions);
        }
export type TeamMemberBocQueryHookResult = ReturnType<typeof useTeamMemberBocQuery>;
export type TeamMemberBocLazyQueryHookResult = ReturnType<typeof useTeamMemberBocLazyQuery>;
export type TeamMemberBocQueryResult = Apollo.QueryResult<TeamMemberBocQuery, TeamMemberBocQueryVariables>;
export const ProprieteVignobleScreenQueryDocument = gql`
    query ProprieteVignobleScreenQuery($wineEntityId: ID!) {
  ProprieteVignobleScreen(wineEntityId: $wineEntityId) {
    soil
    subsoil
    soilDescription {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    grassGrowing
    grassGrowingIsTotal
    grassGrowingIsTemporary
    fertilizers
    worksUnderTheRow
    soilWorkDescription {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    pruningMethod
    greenProcedures
    vineWorkDescription {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    sexualConfusion
    insectWorkDescription {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    description {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    otherCultures {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
  }
}
    `;

/**
 * __useProprieteVignobleScreenQueryQuery__
 *
 * To run a query within a React component, call `useProprieteVignobleScreenQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProprieteVignobleScreenQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProprieteVignobleScreenQueryQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useProprieteVignobleScreenQueryQuery(baseOptions: Apollo.QueryHookOptions<ProprieteVignobleScreenQueryQuery, ProprieteVignobleScreenQueryQueryVariables>) {
        return Apollo.useQuery<ProprieteVignobleScreenQueryQuery, ProprieteVignobleScreenQueryQueryVariables>(ProprieteVignobleScreenQueryDocument, baseOptions);
      }
export function useProprieteVignobleScreenQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProprieteVignobleScreenQueryQuery, ProprieteVignobleScreenQueryQueryVariables>) {
          return Apollo.useLazyQuery<ProprieteVignobleScreenQueryQuery, ProprieteVignobleScreenQueryQueryVariables>(ProprieteVignobleScreenQueryDocument, baseOptions);
        }
export type ProprieteVignobleScreenQueryQueryHookResult = ReturnType<typeof useProprieteVignobleScreenQueryQuery>;
export type ProprieteVignobleScreenQueryLazyQueryHookResult = ReturnType<typeof useProprieteVignobleScreenQueryLazyQuery>;
export type ProprieteVignobleScreenQueryQueryResult = Apollo.QueryResult<ProprieteVignobleScreenQueryQuery, ProprieteVignobleScreenQueryQueryVariables>;
export const GetFeminineTitlesDocument = gql`
    query getFeminineTitles {
  FeminineTitles
}
    `;

/**
 * __useGetFeminineTitlesQuery__
 *
 * To run a query within a React component, call `useGetFeminineTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeminineTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeminineTitlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeminineTitlesQuery(baseOptions?: Apollo.QueryHookOptions<GetFeminineTitlesQuery, GetFeminineTitlesQueryVariables>) {
        return Apollo.useQuery<GetFeminineTitlesQuery, GetFeminineTitlesQueryVariables>(GetFeminineTitlesDocument, baseOptions);
      }
export function useGetFeminineTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeminineTitlesQuery, GetFeminineTitlesQueryVariables>) {
          return Apollo.useLazyQuery<GetFeminineTitlesQuery, GetFeminineTitlesQueryVariables>(GetFeminineTitlesDocument, baseOptions);
        }
export type GetFeminineTitlesQueryHookResult = ReturnType<typeof useGetFeminineTitlesQuery>;
export type GetFeminineTitlesLazyQueryHookResult = ReturnType<typeof useGetFeminineTitlesLazyQuery>;
export type GetFeminineTitlesQueryResult = Apollo.QueryResult<GetFeminineTitlesQuery, GetFeminineTitlesQueryVariables>;
export const TeamGeneralDocument = gql`
    query TeamGeneral($wineEntityId: ID!) {
  TeamGeneral(wineEntityId: $wineEntityId) {
    teamDescription {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    members {
      vip
      kind
      title
      hidden
      personId
      lastName
      firstName
      displayName
      proFunctions
      organizationName
      organizationStatus
    }
    owners {
      vip
      kind
      hidden
      personId
      lastName
      firstName
      displayName
      proFunctions
      organizationName
      organizationStatus
    }
    operators {
      vip
      kind
      hidden
      personId
      lastName
      firstName
      displayName
      proFunctions
      organizationName
      organizationStatus
    }
    image {
      data
      fileName
      mainImage
      backgroundImage
      caption
    }
  }
}
    `;

/**
 * __useTeamGeneralQuery__
 *
 * To run a query within a React component, call `useTeamGeneralQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamGeneralQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamGeneralQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useTeamGeneralQuery(baseOptions: Apollo.QueryHookOptions<TeamGeneralQuery, TeamGeneralQueryVariables>) {
        return Apollo.useQuery<TeamGeneralQuery, TeamGeneralQueryVariables>(TeamGeneralDocument, baseOptions);
      }
export function useTeamGeneralLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamGeneralQuery, TeamGeneralQueryVariables>) {
          return Apollo.useLazyQuery<TeamGeneralQuery, TeamGeneralQueryVariables>(TeamGeneralDocument, baseOptions);
        }
export type TeamGeneralQueryHookResult = ReturnType<typeof useTeamGeneralQuery>;
export type TeamGeneralLazyQueryHookResult = ReturnType<typeof useTeamGeneralLazyQuery>;
export type TeamGeneralQueryResult = Apollo.QueryResult<TeamGeneralQuery, TeamGeneralQueryVariables>;
export const VintageIdentityScreenDocument = gql`
    query VintageIdentityScreen($wineEntityId: ID!, $wineId: ID!, $vintageId: ID!) {
  VintageIdentityScreen(
    wineEntityId: $wineEntityId
    wineId: $wineId
    vintageId: $vintageId
  ) {
    shortId
    wineName
    year
    alcoholicStrength
    residualSugar
    totalSulfur
    totalAcidity
    ph
    qr {
      data
      fileName
      bucketName
    }
    noSulphites
    consultingEnologist {
      title
      type
      functions
    }
    globalClimatology {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    winterClimatology {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    springClimatology {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    fallClimatology {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    summerClimatology {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    bySeasonClimatology
    vintageDetails {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    bottlePictures {
      data
      fileName
      mainImage
      backgroundImage
      caption
    }
    bottleLabels {
      data
      fileName
      mainImage
      backgroundImage
      caption
    }
    wineAssembly {
      grapeVarietyName
      percentage
    }
    allowedGrapeVarieties
    volatileAcidity
    nonOGM
    ipt
    allergens
    oenologueOptions {
      title
      type
      functions
      personId
    }
  }
}
    `;

/**
 * __useVintageIdentityScreenQuery__
 *
 * To run a query within a React component, call `useVintageIdentityScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVintageIdentityScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVintageIdentityScreenQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      wineId: // value for 'wineId'
 *      vintageId: // value for 'vintageId'
 *   },
 * });
 */
export function useVintageIdentityScreenQuery(baseOptions: Apollo.QueryHookOptions<VintageIdentityScreenQuery, VintageIdentityScreenQueryVariables>) {
        return Apollo.useQuery<VintageIdentityScreenQuery, VintageIdentityScreenQueryVariables>(VintageIdentityScreenDocument, baseOptions);
      }
export function useVintageIdentityScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VintageIdentityScreenQuery, VintageIdentityScreenQueryVariables>) {
          return Apollo.useLazyQuery<VintageIdentityScreenQuery, VintageIdentityScreenQueryVariables>(VintageIdentityScreenDocument, baseOptions);
        }
export type VintageIdentityScreenQueryHookResult = ReturnType<typeof useVintageIdentityScreenQuery>;
export type VintageIdentityScreenLazyQueryHookResult = ReturnType<typeof useVintageIdentityScreenLazyQuery>;
export type VintageIdentityScreenQueryResult = Apollo.QueryResult<VintageIdentityScreenQuery, VintageIdentityScreenQueryVariables>;
export const VintageViticultureScreenDocument = gql`
    query VintageViticultureScreen($wineEntityId: ID!, $vintageId: ID!) {
  VintageViticultureScreen(wineEntityId: $wineEntityId, vintageId: $vintageId) {
    greenOperations {
      operation
      startAt {
        day
        month
        year
        vaguePeriod
      }
      endAt {
        day
        month
        year
        vaguePeriod
      }
    }
    simplePhenologicalStages {
      stage
      startAt {
        day
        month
        year
        vaguePeriod
      }
      endAt {
        day
        month
        year
        vaguePeriod
      }
    }
    detailedPhenologicalStages {
      stage
      grapeVariety
      startAt {
        day
        month
        year
        vaguePeriod
      }
      endAt {
        day
        month
        year
        vaguePeriod
      }
    }
    detailedHarvest {
      method
      startAt {
        day
        month
        year
        vaguePeriod
      }
      endAt {
        day
        month
        year
        vaguePeriod
      }
      grapeVariety
    }
    harvest {
      method
      startAt {
        day
        month
        year
        vaguePeriod
      }
      endAt {
        day
        month
        year
        vaguePeriod
      }
    }
    showDetailedPhenologicalStages
    showDetailedHarvest
    wineHarvestMethods
    viticultureDetails {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    numberOfSorts
    allowedGrapeVarieties
  }
}
    `;

/**
 * __useVintageViticultureScreenQuery__
 *
 * To run a query within a React component, call `useVintageViticultureScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVintageViticultureScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVintageViticultureScreenQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      vintageId: // value for 'vintageId'
 *   },
 * });
 */
export function useVintageViticultureScreenQuery(baseOptions: Apollo.QueryHookOptions<VintageViticultureScreenQuery, VintageViticultureScreenQueryVariables>) {
        return Apollo.useQuery<VintageViticultureScreenQuery, VintageViticultureScreenQueryVariables>(VintageViticultureScreenDocument, baseOptions);
      }
export function useVintageViticultureScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VintageViticultureScreenQuery, VintageViticultureScreenQueryVariables>) {
          return Apollo.useLazyQuery<VintageViticultureScreenQuery, VintageViticultureScreenQueryVariables>(VintageViticultureScreenDocument, baseOptions);
        }
export type VintageViticultureScreenQueryHookResult = ReturnType<typeof useVintageViticultureScreenQuery>;
export type VintageViticultureScreenLazyQueryHookResult = ReturnType<typeof useVintageViticultureScreenLazyQuery>;
export type VintageViticultureScreenQueryResult = Apollo.QueryResult<VintageViticultureScreenQuery, VintageViticultureScreenQueryVariables>;
export const VintageWineMakingScreenDocument = gql`
    query VintageWineMakingScreen($wineEntityId: ID!, $vintageId: ID!) {
  VintageWineMakingScreen(wineEntityId: $wineEntityId, vintageId: $vintageId) {
    vinificationPlace
    vinificationCoopName
    parcelBasedVinification
    sortingMethod
    destemming
    treading
    vatting
    extractionMode
    yeasting
    alcoholicFermentationDuration
    alcoholicFermentationDurationUnit
    alcoholicFermentationTemperature
    malolacticFermentation
    malolacticBacteria
    malolacticFermentationDuration
    malolacticFermentationDurationUnit
    malolacticFermentationTemperature
    pressType
    vinificationDetails {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    macerations {
      type
      duration
      durationUnit
      temperature
    }
  }
}
    `;

/**
 * __useVintageWineMakingScreenQuery__
 *
 * To run a query within a React component, call `useVintageWineMakingScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVintageWineMakingScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVintageWineMakingScreenQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      vintageId: // value for 'vintageId'
 *   },
 * });
 */
export function useVintageWineMakingScreenQuery(baseOptions: Apollo.QueryHookOptions<VintageWineMakingScreenQuery, VintageWineMakingScreenQueryVariables>) {
        return Apollo.useQuery<VintageWineMakingScreenQuery, VintageWineMakingScreenQueryVariables>(VintageWineMakingScreenDocument, baseOptions);
      }
export function useVintageWineMakingScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VintageWineMakingScreenQuery, VintageWineMakingScreenQueryVariables>) {
          return Apollo.useLazyQuery<VintageWineMakingScreenQuery, VintageWineMakingScreenQueryVariables>(VintageWineMakingScreenDocument, baseOptions);
        }
export type VintageWineMakingScreenQueryHookResult = ReturnType<typeof useVintageWineMakingScreenQuery>;
export type VintageWineMakingScreenLazyQueryHookResult = ReturnType<typeof useVintageWineMakingScreenLazyQuery>;
export type VintageWineMakingScreenQueryResult = Apollo.QueryResult<VintageWineMakingScreenQuery, VintageWineMakingScreenQueryVariables>;
export const GeneralInformationScreenDocument = gql`
    query GeneralInformationScreen($wineEntityId: ID!) {
  GeneralInformationScreen(wineEntityId: $wineEntityId) {
    name
    siret
    address {
      address
      complementaryAddress
      postalCode
      commune
      communeINSEEcode
    }
    telephoneCountry
    telephone
    faxCountry
    fax
    email
    website
    socialMedia {
      platform
      name
      url
    }
    image {
      data
      fileName
      mainImage
      backgroundImage
      caption
    }
    mainCommune
    mainAppellation
    displayOpeningHours
    openingHours {
      name
      open
      openMorning
      closeMorning
      openAfternoon
      closeAfternoon
      nonStop
      val
    }
    isUniformOpeningHours
    uniformOpeningHours {
      name
      open
      openMorning
      closeMorning
      openAfternoon
      closeAfternoon
      nonStop
      val
    }
    classifications {
      name
      url
    }
  }
}
    `;

/**
 * __useGeneralInformationScreenQuery__
 *
 * To run a query within a React component, call `useGeneralInformationScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneralInformationScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneralInformationScreenQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGeneralInformationScreenQuery(baseOptions: Apollo.QueryHookOptions<GeneralInformationScreenQuery, GeneralInformationScreenQueryVariables>) {
        return Apollo.useQuery<GeneralInformationScreenQuery, GeneralInformationScreenQueryVariables>(GeneralInformationScreenDocument, baseOptions);
      }
export function useGeneralInformationScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GeneralInformationScreenQuery, GeneralInformationScreenQueryVariables>) {
          return Apollo.useLazyQuery<GeneralInformationScreenQuery, GeneralInformationScreenQueryVariables>(GeneralInformationScreenDocument, baseOptions);
        }
export type GeneralInformationScreenQueryHookResult = ReturnType<typeof useGeneralInformationScreenQuery>;
export type GeneralInformationScreenLazyQueryHookResult = ReturnType<typeof useGeneralInformationScreenLazyQuery>;
export type GeneralInformationScreenQueryResult = Apollo.QueryResult<GeneralInformationScreenQuery, GeneralInformationScreenQueryVariables>;
export const WinePresentationScreenDocument = gql`
    query WinePresentationScreen($wineEntityId: ID!, $wineId: ID!) {
  WinePresentationScreen(wineId: $wineId, wineEntityId: $wineEntityId) {
    classifications {
      name
      url
    }
    name
    shortId
    label
    color
    sweetness
    type
    rank
    description {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    vinification {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    soils
    subsoils
    noSulphites
    nonOGM
    limitVineSurfaceArea
    wineAssembly {
      grapeVarietyName
      percentage
    }
    allowedGrapeVarieties
    oenologueOptions {
      title
      type
      functions
      personId
    }
    consultingEnologist {
      title
      type
      functions
      phoneNumber
      email
      personId
      image {
        data
      }
    }
    advice {
      description {
        fr
        en
        de
        es
        it
        nl
        ja
        zh
      }
      serviceTemperature
      ageing
      treatmentBeforeService
      decantingDuration
      foodPairings {
        category
        food
      }
      appearanceColor
      colorOptions
      appearanceIntensity
      appearanceBrilliance
      appearanceLimpidity
      appearanceTears
      noseAromas
      noseIntensity
      palateAttack
      palateIntensity
      palateLength
      palateAcidity
      palateTannins
      aromaValues {
        Animal
        Balsamique
        Boise
        Confiserie
        Empyreumatique
        Epice
        Fermentaire
        Floral
        Fruite
        Mineral
        Vegetal
      }
      aromaAnimal
      aromaBalsamic
      aromaConfectionery
      aromaEmpyreumatic
      aromaFermented
      aromaFloral
      aromaFruity
      aromaMineral
      aromaOaky
      aromaSpicy
      aromaVegetal
    }
    wineMaking {
      parcelBasedVinification
      sortingMethod
      extractionMode
      pressType
      yeasting
      alcoholicFermentationDuration
      alcoholicFermentationDurationUnit
      alcoholicFermentationTemperature
    }
    harvestMethod
    maturation {
      barrelMaturation
      barrelMaturationDurationValue
      barrelMaturationDurationUnit
      newBarrelPercentage
      toasting
      frenchOak
    }
    otherMaturations {
      containerType
      durationValue
      durationUnit
      material
    }
    vineyard {
      totalSurfaceArea
      averageDensity
      averageAge
    }
    production {
      bottlesVolume
      yield
      production
    }
    marketing {
      exportPercentage
      directPercentage
      indirectPercentage
      exportCountries
    }
    images {
      data
      fileName
      mainImage
      backgroundImage
      caption
    }
  }
}
    `;

/**
 * __useWinePresentationScreenQuery__
 *
 * To run a query within a React component, call `useWinePresentationScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinePresentationScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinePresentationScreenQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      wineId: // value for 'wineId'
 *   },
 * });
 */
export function useWinePresentationScreenQuery(baseOptions: Apollo.QueryHookOptions<WinePresentationScreenQuery, WinePresentationScreenQueryVariables>) {
        return Apollo.useQuery<WinePresentationScreenQuery, WinePresentationScreenQueryVariables>(WinePresentationScreenDocument, baseOptions);
      }
export function useWinePresentationScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WinePresentationScreenQuery, WinePresentationScreenQueryVariables>) {
          return Apollo.useLazyQuery<WinePresentationScreenQuery, WinePresentationScreenQueryVariables>(WinePresentationScreenDocument, baseOptions);
        }
export type WinePresentationScreenQueryHookResult = ReturnType<typeof useWinePresentationScreenQuery>;
export type WinePresentationScreenLazyQueryHookResult = ReturnType<typeof useWinePresentationScreenLazyQuery>;
export type WinePresentationScreenQueryResult = Apollo.QueryResult<WinePresentationScreenQuery, WinePresentationScreenQueryVariables>;
export const VintageAgeingScreenDocument = gql`
    query VintageAgeingScreen($wineEntityId: ID!, $vintageId: ID!) {
  VintageAgeingScreen(wineEntityId: $wineEntityId, vintageId: $vintageId) {
    barrelMaturation
    barrelMaturationDurationValue
    barrelMaturationDurationUnit
    newBarrelPercentage
    toasting
    frenchOak
    barrelMakers {
      barrelMaker
      percentage
    }
    otherMaturations {
      containerType
      durationValue
      durationUnit
      material
    }
    lees
    batonnage
    description {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
  }
}
    `;

/**
 * __useVintageAgeingScreenQuery__
 *
 * To run a query within a React component, call `useVintageAgeingScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVintageAgeingScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVintageAgeingScreenQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      vintageId: // value for 'vintageId'
 *   },
 * });
 */
export function useVintageAgeingScreenQuery(baseOptions: Apollo.QueryHookOptions<VintageAgeingScreenQuery, VintageAgeingScreenQueryVariables>) {
        return Apollo.useQuery<VintageAgeingScreenQuery, VintageAgeingScreenQueryVariables>(VintageAgeingScreenDocument, baseOptions);
      }
export function useVintageAgeingScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VintageAgeingScreenQuery, VintageAgeingScreenQueryVariables>) {
          return Apollo.useLazyQuery<VintageAgeingScreenQuery, VintageAgeingScreenQueryVariables>(VintageAgeingScreenDocument, baseOptions);
        }
export type VintageAgeingScreenQueryHookResult = ReturnType<typeof useVintageAgeingScreenQuery>;
export type VintageAgeingScreenLazyQueryHookResult = ReturnType<typeof useVintageAgeingScreenLazyQuery>;
export type VintageAgeingScreenQueryResult = Apollo.QueryResult<VintageAgeingScreenQuery, VintageAgeingScreenQueryVariables>;
export const VintageProductionScreenDocument = gql`
    query VintageProductionScreen($wineEntityId: ID!, $vintageId: ID!) {
  VintageProductionScreen(wineEntityId: $wineEntityId, vintageId: $vintageId) {
    corkingMode
    finingTypes
    filtrationTypes
    bottlingDate
    estateBottling
    address {
      address
      complementaryAddress
      postalCode
      commune
      communeINSEEcode
    }
    wineEntityAddress {
      address
      postalCode
      complementaryAddress
      commune
      communeINSEEcode
    }
    bottlesVolume
    yield
    production
    waxedBottle
    bottlerOptions {
      title
      type
      postalCode
      commune
      communeINSEEcode
    }
    bottler {
      title
      type
    }
    bottlerCommune
    bottlerCommuneINSEEcode
    bottlerPostalCode
  }
}
    `;

/**
 * __useVintageProductionScreenQuery__
 *
 * To run a query within a React component, call `useVintageProductionScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVintageProductionScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVintageProductionScreenQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      vintageId: // value for 'vintageId'
 *   },
 * });
 */
export function useVintageProductionScreenQuery(baseOptions: Apollo.QueryHookOptions<VintageProductionScreenQuery, VintageProductionScreenQueryVariables>) {
        return Apollo.useQuery<VintageProductionScreenQuery, VintageProductionScreenQueryVariables>(VintageProductionScreenDocument, baseOptions);
      }
export function useVintageProductionScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VintageProductionScreenQuery, VintageProductionScreenQueryVariables>) {
          return Apollo.useLazyQuery<VintageProductionScreenQuery, VintageProductionScreenQueryVariables>(VintageProductionScreenDocument, baseOptions);
        }
export type VintageProductionScreenQueryHookResult = ReturnType<typeof useVintageProductionScreenQuery>;
export type VintageProductionScreenLazyQueryHookResult = ReturnType<typeof useVintageProductionScreenLazyQuery>;
export type VintageProductionScreenQueryResult = Apollo.QueryResult<VintageProductionScreenQuery, VintageProductionScreenQueryVariables>;
export const VintageNutritionScreenDocument = gql`
    query VintageNutritionScreen($wineEntityId: ID!, $vintageId: ID!) {
  VintageNutritionScreen(wineEntityId: $wineEntityId, vintageId: $vintageId) {
    nutritionalAnalysis {
      data
      fileName
      bucketName
    }
    fats
    saturatedFattyAcids
    glucides
    sugars
    protein
    salt
    energy
    energyType
    alcoholicStrength
    residualSugar
    totalAcidity
    auto
    qrnutri {
      data
      fileName
      bucketName
    }
    ingredients {
      ingredient
    }
  }
}
    `;

/**
 * __useVintageNutritionScreenQuery__
 *
 * To run a query within a React component, call `useVintageNutritionScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVintageNutritionScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVintageNutritionScreenQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      vintageId: // value for 'vintageId'
 *   },
 * });
 */
export function useVintageNutritionScreenQuery(baseOptions: Apollo.QueryHookOptions<VintageNutritionScreenQuery, VintageNutritionScreenQueryVariables>) {
        return Apollo.useQuery<VintageNutritionScreenQuery, VintageNutritionScreenQueryVariables>(VintageNutritionScreenDocument, baseOptions);
      }
export function useVintageNutritionScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VintageNutritionScreenQuery, VintageNutritionScreenQueryVariables>) {
          return Apollo.useLazyQuery<VintageNutritionScreenQuery, VintageNutritionScreenQueryVariables>(VintageNutritionScreenDocument, baseOptions);
        }
export type VintageNutritionScreenQueryHookResult = ReturnType<typeof useVintageNutritionScreenQuery>;
export type VintageNutritionScreenLazyQueryHookResult = ReturnType<typeof useVintageNutritionScreenLazyQuery>;
export type VintageNutritionScreenQueryResult = Apollo.QueryResult<VintageNutritionScreenQuery, VintageNutritionScreenQueryVariables>;
export const GetNotificationsDocument = gql`
    query GetNotifications($userId: ID) {
  GetNotifications(userId: $userId) {
    notificationId
    wineEntityId
    category
    dateCreated
    dateRead
    text
    extraContent
    deleted
    action
    actionArgs
  }
}
    `;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, baseOptions);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, baseOptions);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const VintageMarketingScreenDocument = gql`
    query VintageMarketingScreen($wineEntityId: ID!, $vintageId: ID!) {
  VintageMarketingScreen(wineEntityId: $wineEntityId, vintageId: $vintageId) {
    ownWebsiteSelling
    productPageUrl
    exportPercentage
    directPercentage
    indirectPercentage
    exportCountries
    packagings {
      centilisation
      units
      conditionningMode
      price
      gtin
      url
    }
  }
}
    `;

/**
 * __useVintageMarketingScreenQuery__
 *
 * To run a query within a React component, call `useVintageMarketingScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVintageMarketingScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVintageMarketingScreenQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      vintageId: // value for 'vintageId'
 *   },
 * });
 */
export function useVintageMarketingScreenQuery(baseOptions: Apollo.QueryHookOptions<VintageMarketingScreenQuery, VintageMarketingScreenQueryVariables>) {
        return Apollo.useQuery<VintageMarketingScreenQuery, VintageMarketingScreenQueryVariables>(VintageMarketingScreenDocument, baseOptions);
      }
export function useVintageMarketingScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VintageMarketingScreenQuery, VintageMarketingScreenQueryVariables>) {
          return Apollo.useLazyQuery<VintageMarketingScreenQuery, VintageMarketingScreenQueryVariables>(VintageMarketingScreenDocument, baseOptions);
        }
export type VintageMarketingScreenQueryHookResult = ReturnType<typeof useVintageMarketingScreenQuery>;
export type VintageMarketingScreenLazyQueryHookResult = ReturnType<typeof useVintageMarketingScreenLazyQuery>;
export type VintageMarketingScreenQueryResult = Apollo.QueryResult<VintageMarketingScreenQuery, VintageMarketingScreenQueryVariables>;
export const VintageAdviceScreenDocument = gql`
    query VintageAdviceScreen($wineEntityId: ID!, $vintageId: ID!) {
  VintageAdviceScreen(wineEntityId: $wineEntityId, vintageId: $vintageId) {
    videoUrl
    serviceTemperature
    apogee
    treatmentBeforeService
    decantingDuration
    foodPairings {
      category
      food
    }
    appearanceColor
    appearanceIntensity
    appearanceBrilliance
    appearanceLimpidity
    appearanceTears
    noseAromas
    noseIntensity
    palateAttack
    palateIntensity
    palateLength
    palateAcidity
    palateTannins
    aromaValues {
      Animal
      Balsamique
      Boise
      Confiserie
      Empyreumatique
      Epice
      Fermentaire
      Floral
      Fruite
      Mineral
      Vegetal
    }
    description {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    aromaAnimal
    aromaBalsamic
    aromaConfectionery
    aromaEmpyreumatic
    aromaFermented
    aromaFloral
    aromaFruity
    aromaMineral
    aromaOaky
    aromaSpicy
    aromaVegetal
    colorOptions
  }
}
    `;

/**
 * __useVintageAdviceScreenQuery__
 *
 * To run a query within a React component, call `useVintageAdviceScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVintageAdviceScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVintageAdviceScreenQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      vintageId: // value for 'vintageId'
 *   },
 * });
 */
export function useVintageAdviceScreenQuery(baseOptions: Apollo.QueryHookOptions<VintageAdviceScreenQuery, VintageAdviceScreenQueryVariables>) {
        return Apollo.useQuery<VintageAdviceScreenQuery, VintageAdviceScreenQueryVariables>(VintageAdviceScreenDocument, baseOptions);
      }
export function useVintageAdviceScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VintageAdviceScreenQuery, VintageAdviceScreenQueryVariables>) {
          return Apollo.useLazyQuery<VintageAdviceScreenQuery, VintageAdviceScreenQueryVariables>(VintageAdviceScreenDocument, baseOptions);
        }
export type VintageAdviceScreenQueryHookResult = ReturnType<typeof useVintageAdviceScreenQuery>;
export type VintageAdviceScreenLazyQueryHookResult = ReturnType<typeof useVintageAdviceScreenLazyQuery>;
export type VintageAdviceScreenQueryResult = Apollo.QueryResult<VintageAdviceScreenQuery, VintageAdviceScreenQueryVariables>;
export const SurfaceImportCviDocument = gql`
    query SurfaceImportCvi($wineEntityId: ID!) {
  SurfaceImportCvi(wineEntityId: $wineEntityId) {
    surfaceImportCVI
    dateImportCvi
  }
}
    `;

/**
 * __useSurfaceImportCviQuery__
 *
 * To run a query within a React component, call `useSurfaceImportCviQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurfaceImportCviQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurfaceImportCviQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useSurfaceImportCviQuery(baseOptions: Apollo.QueryHookOptions<SurfaceImportCviQuery, SurfaceImportCviQueryVariables>) {
        return Apollo.useQuery<SurfaceImportCviQuery, SurfaceImportCviQueryVariables>(SurfaceImportCviDocument, baseOptions);
      }
export function useSurfaceImportCviLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurfaceImportCviQuery, SurfaceImportCviQueryVariables>) {
          return Apollo.useLazyQuery<SurfaceImportCviQuery, SurfaceImportCviQueryVariables>(SurfaceImportCviDocument, baseOptions);
        }
export type SurfaceImportCviQueryHookResult = ReturnType<typeof useSurfaceImportCviQuery>;
export type SurfaceImportCviLazyQueryHookResult = ReturnType<typeof useSurfaceImportCviLazyQuery>;
export type SurfaceImportCviQueryResult = Apollo.QueryResult<SurfaceImportCviQuery, SurfaceImportCviQueryVariables>;
export const CellarsDashboardDocument = gql`
    query CellarsDashboard($wineEntityId: ID!) {
  CellarsDashboard(wineEntityId: $wineEntityId) {
    cellars {
      cellarId
      name
    }
    wineEntityAddress {
      address
      postalCode
      complementaryAddress
      commune
      communeINSEEcode
    }
  }
}
    `;

/**
 * __useCellarsDashboardQuery__
 *
 * To run a query within a React component, call `useCellarsDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCellarsDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCellarsDashboardQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useCellarsDashboardQuery(baseOptions: Apollo.QueryHookOptions<CellarsDashboardQuery, CellarsDashboardQueryVariables>) {
        return Apollo.useQuery<CellarsDashboardQuery, CellarsDashboardQueryVariables>(CellarsDashboardDocument, baseOptions);
      }
export function useCellarsDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CellarsDashboardQuery, CellarsDashboardQueryVariables>) {
          return Apollo.useLazyQuery<CellarsDashboardQuery, CellarsDashboardQueryVariables>(CellarsDashboardDocument, baseOptions);
        }
export type CellarsDashboardQueryHookResult = ReturnType<typeof useCellarsDashboardQuery>;
export type CellarsDashboardLazyQueryHookResult = ReturnType<typeof useCellarsDashboardLazyQuery>;
export type CellarsDashboardQueryResult = Apollo.QueryResult<CellarsDashboardQuery, CellarsDashboardQueryVariables>;
export const SurfaceManualScreenDocument = gql`
    query SurfaceManualScreen($wineEntityId: ID!) {
  SurfaceManualScreen(wineEntityId: $wineEntityId) {
    surfaceImportCVI
    estateSurfaceArea
    totalSurfaceArea
    averageAge
    averageDensity
    surfaces {
      appellation
      grapeColor
      surfaceArea
    }
    allowedAppellations {
      color
      appellation
    }
  }
}
    `;

/**
 * __useSurfaceManualScreenQuery__
 *
 * To run a query within a React component, call `useSurfaceManualScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurfaceManualScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurfaceManualScreenQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useSurfaceManualScreenQuery(baseOptions: Apollo.QueryHookOptions<SurfaceManualScreenQuery, SurfaceManualScreenQueryVariables>) {
        return Apollo.useQuery<SurfaceManualScreenQuery, SurfaceManualScreenQueryVariables>(SurfaceManualScreenDocument, baseOptions);
      }
export function useSurfaceManualScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurfaceManualScreenQuery, SurfaceManualScreenQueryVariables>) {
          return Apollo.useLazyQuery<SurfaceManualScreenQuery, SurfaceManualScreenQueryVariables>(SurfaceManualScreenDocument, baseOptions);
        }
export type SurfaceManualScreenQueryHookResult = ReturnType<typeof useSurfaceManualScreenQuery>;
export type SurfaceManualScreenLazyQueryHookResult = ReturnType<typeof useSurfaceManualScreenLazyQuery>;
export type SurfaceManualScreenQueryResult = Apollo.QueryResult<SurfaceManualScreenQuery, SurfaceManualScreenQueryVariables>;
export const EstateScreenDocument = gql`
    query EstateScreen($wineEntityId: ID!) {
  EstateScreen(wineEntityId: $wineEntityId) {
    wineEntityName
    info_19e
    promotionBodies
    commercialisationTypes
    images {
      data
      fileName
      mainImage
      backgroundImage
      caption
      hidden
    }
    totalSurfaceArea
    vineSurfaceArea
    description {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    citation {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    author {
      title
      type
      functions
    }
    authorOptions {
      title
      type
      functions
      personId
    }
    defaultBgImage {
      data
    }
  }
}
    `;

/**
 * __useEstateScreenQuery__
 *
 * To run a query within a React component, call `useEstateScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstateScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstateScreenQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useEstateScreenQuery(baseOptions: Apollo.QueryHookOptions<EstateScreenQuery, EstateScreenQueryVariables>) {
        return Apollo.useQuery<EstateScreenQuery, EstateScreenQueryVariables>(EstateScreenDocument, baseOptions);
      }
export function useEstateScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EstateScreenQuery, EstateScreenQueryVariables>) {
          return Apollo.useLazyQuery<EstateScreenQuery, EstateScreenQueryVariables>(EstateScreenDocument, baseOptions);
        }
export type EstateScreenQueryHookResult = ReturnType<typeof useEstateScreenQuery>;
export type EstateScreenLazyQueryHookResult = ReturnType<typeof useEstateScreenLazyQuery>;
export type EstateScreenQueryResult = Apollo.QueryResult<EstateScreenQuery, EstateScreenQueryVariables>;
export const AccountScreenEmptyDocument = gql`
    query AccountScreenEmpty($userId: ID!) {
  AccountScreenEmpty(userId: $userId) {
    logInProviders
    hasPassword
    title
    firstName
    lastName
    telephoneNumber
    isEmailValid
    email
    isProfessional
    professionalCategory
    professionalCompany
    allowDigest
    professionalAddress {
      complementaryAddress
      address
      commune
      postalCode
      communeINSEEcode
      country
    }
  }
}
    `;

/**
 * __useAccountScreenEmptyQuery__
 *
 * To run a query within a React component, call `useAccountScreenEmptyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountScreenEmptyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountScreenEmptyQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAccountScreenEmptyQuery(baseOptions: Apollo.QueryHookOptions<AccountScreenEmptyQuery, AccountScreenEmptyQueryVariables>) {
        return Apollo.useQuery<AccountScreenEmptyQuery, AccountScreenEmptyQueryVariables>(AccountScreenEmptyDocument, baseOptions);
      }
export function useAccountScreenEmptyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountScreenEmptyQuery, AccountScreenEmptyQueryVariables>) {
          return Apollo.useLazyQuery<AccountScreenEmptyQuery, AccountScreenEmptyQueryVariables>(AccountScreenEmptyDocument, baseOptions);
        }
export type AccountScreenEmptyQueryHookResult = ReturnType<typeof useAccountScreenEmptyQuery>;
export type AccountScreenEmptyLazyQueryHookResult = ReturnType<typeof useAccountScreenEmptyLazyQuery>;
export type AccountScreenEmptyQueryResult = Apollo.QueryResult<AccountScreenEmptyQuery, AccountScreenEmptyQueryVariables>;
export const JournalistAccountScreenDocument = gql`
    query JournalistAccountScreen($userId: ID!) {
  JournalistAccountScreen(userId: $userId) {
    logInProviders
    email
    isEmailValid
    hasPassword
    lastName
    firstName
    title
    displayName
    companyName
    isFeretAddress
    shippingAddress {
      recipient
      isDefault
      address {
        complementaryAddress
        address
        commune
        postalCode
        communeINSEEcode
        country
      }
    }
    paymentInformation {
      iban
      bic
    }
    locale
  }
}
    `;

/**
 * __useJournalistAccountScreenQuery__
 *
 * To run a query within a React component, call `useJournalistAccountScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useJournalistAccountScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJournalistAccountScreenQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useJournalistAccountScreenQuery(baseOptions: Apollo.QueryHookOptions<JournalistAccountScreenQuery, JournalistAccountScreenQueryVariables>) {
        return Apollo.useQuery<JournalistAccountScreenQuery, JournalistAccountScreenQueryVariables>(JournalistAccountScreenDocument, baseOptions);
      }
export function useJournalistAccountScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JournalistAccountScreenQuery, JournalistAccountScreenQueryVariables>) {
          return Apollo.useLazyQuery<JournalistAccountScreenQuery, JournalistAccountScreenQueryVariables>(JournalistAccountScreenDocument, baseOptions);
        }
export type JournalistAccountScreenQueryHookResult = ReturnType<typeof useJournalistAccountScreenQuery>;
export type JournalistAccountScreenLazyQueryHookResult = ReturnType<typeof useJournalistAccountScreenLazyQuery>;
export type JournalistAccountScreenQueryResult = Apollo.QueryResult<JournalistAccountScreenQuery, JournalistAccountScreenQueryVariables>;
export const GetServicesOfferByJournalistDocument = gql`
    query GetServicesOfferByJournalist($userId: ID!) {
  GetServicesOfferByJournalist(userId: $userId) {
    areas
    prerequisites {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    maxTasting
    delay
    video
    demoVideoUrl
  }
}
    `;

/**
 * __useGetServicesOfferByJournalistQuery__
 *
 * To run a query within a React component, call `useGetServicesOfferByJournalistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesOfferByJournalistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesOfferByJournalistQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetServicesOfferByJournalistQuery(baseOptions: Apollo.QueryHookOptions<GetServicesOfferByJournalistQuery, GetServicesOfferByJournalistQueryVariables>) {
        return Apollo.useQuery<GetServicesOfferByJournalistQuery, GetServicesOfferByJournalistQueryVariables>(GetServicesOfferByJournalistDocument, baseOptions);
      }
export function useGetServicesOfferByJournalistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServicesOfferByJournalistQuery, GetServicesOfferByJournalistQueryVariables>) {
          return Apollo.useLazyQuery<GetServicesOfferByJournalistQuery, GetServicesOfferByJournalistQueryVariables>(GetServicesOfferByJournalistDocument, baseOptions);
        }
export type GetServicesOfferByJournalistQueryHookResult = ReturnType<typeof useGetServicesOfferByJournalistQuery>;
export type GetServicesOfferByJournalistLazyQueryHookResult = ReturnType<typeof useGetServicesOfferByJournalistLazyQuery>;
export type GetServicesOfferByJournalistQueryResult = Apollo.QueryResult<GetServicesOfferByJournalistQuery, GetServicesOfferByJournalistQueryVariables>;
export const AccountScreenDocument = gql`
    query AccountScreen($userId: ID!) {
  AccountScreen(userId: $userId) {
    subscriptionRecurrence
    logInProviders
    newsletter
    hasPassword
    title
    firstName
    lastName
    allowDigest
    isEmailValid
    email
    billingAddress {
      complementaryAddress
      address
      commune
      postalCode
      communeINSEEcode
      country
    }
    billingInformation {
      siret
      organizationStatus
      organizationName
      tvaNumber
      title
      firstName
      lastName
      email
      telephoneNumber
    }
  }
}
    `;

/**
 * __useAccountScreenQuery__
 *
 * To run a query within a React component, call `useAccountScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountScreenQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAccountScreenQuery(baseOptions: Apollo.QueryHookOptions<AccountScreenQuery, AccountScreenQueryVariables>) {
        return Apollo.useQuery<AccountScreenQuery, AccountScreenQueryVariables>(AccountScreenDocument, baseOptions);
      }
export function useAccountScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountScreenQuery, AccountScreenQueryVariables>) {
          return Apollo.useLazyQuery<AccountScreenQuery, AccountScreenQueryVariables>(AccountScreenDocument, baseOptions);
        }
export type AccountScreenQueryHookResult = ReturnType<typeof useAccountScreenQuery>;
export type AccountScreenLazyQueryHookResult = ReturnType<typeof useAccountScreenLazyQuery>;
export type AccountScreenQueryResult = Apollo.QueryResult<AccountScreenQuery, AccountScreenQueryVariables>;
export const CellarTabScreenDocument = gql`
    query CellarTabScreen($wineEntityId: ID!, $cellarId: ID!) {
  CellarTabScreen(wineEntityId: $wineEntityId, cellarId: $cellarId) {
    name
    sameAddressAsWineEntity
    address {
      complementaryAddress
      address
      commune
      postalCode
      communeINSEEcode
    }
    wineEntityAddress {
      complementaryAddress
      address
      commune
      postalCode
      communeINSEEcode
    }
    images {
      data
      fileName
      mainImage
      backgroundImage
      caption
    }
    surfaceArea
    airConditioning
    temperature
    description {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    vatThermoregulation
    vatCirculation
    vatContainers {
      kind
      material
      form
      capacity
      quantity
    }
    ageingContainers {
      kind
      material
      form
      capacity
      quantity
    }
    isAgeing
    isVathouse
    isConditioning
    conditioningActivities
    numberOfConditioningChains
    conditioningCapacity
    isStorage
    storageCapacity
    storageCapacityUnit
  }
}
    `;

/**
 * __useCellarTabScreenQuery__
 *
 * To run a query within a React component, call `useCellarTabScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCellarTabScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCellarTabScreenQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      cellarId: // value for 'cellarId'
 *   },
 * });
 */
export function useCellarTabScreenQuery(baseOptions: Apollo.QueryHookOptions<CellarTabScreenQuery, CellarTabScreenQueryVariables>) {
        return Apollo.useQuery<CellarTabScreenQuery, CellarTabScreenQueryVariables>(CellarTabScreenDocument, baseOptions);
      }
export function useCellarTabScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CellarTabScreenQuery, CellarTabScreenQueryVariables>) {
          return Apollo.useLazyQuery<CellarTabScreenQuery, CellarTabScreenQueryVariables>(CellarTabScreenDocument, baseOptions);
        }
export type CellarTabScreenQueryHookResult = ReturnType<typeof useCellarTabScreenQuery>;
export type CellarTabScreenLazyQueryHookResult = ReturnType<typeof useCellarTabScreenLazyQuery>;
export type CellarTabScreenQueryResult = Apollo.QueryResult<CellarTabScreenQuery, CellarTabScreenQueryVariables>;
export const SimpleVintagesDocument = gql`
    query SimpleVintages($wineEntityId: ID!, $wineId: ID!) {
  SimpleVintages(wineEntityId: $wineEntityId, wineId: $wineId) {
    year
    vintageId
    cuvee
  }
}
    `;

/**
 * __useSimpleVintagesQuery__
 *
 * To run a query within a React component, call `useSimpleVintagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleVintagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleVintagesQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      wineId: // value for 'wineId'
 *   },
 * });
 */
export function useSimpleVintagesQuery(baseOptions: Apollo.QueryHookOptions<SimpleVintagesQuery, SimpleVintagesQueryVariables>) {
        return Apollo.useQuery<SimpleVintagesQuery, SimpleVintagesQueryVariables>(SimpleVintagesDocument, baseOptions);
      }
export function useSimpleVintagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimpleVintagesQuery, SimpleVintagesQueryVariables>) {
          return Apollo.useLazyQuery<SimpleVintagesQuery, SimpleVintagesQueryVariables>(SimpleVintagesDocument, baseOptions);
        }
export type SimpleVintagesQueryHookResult = ReturnType<typeof useSimpleVintagesQuery>;
export type SimpleVintagesLazyQueryHookResult = ReturnType<typeof useSimpleVintagesLazyQuery>;
export type SimpleVintagesQueryResult = Apollo.QueryResult<SimpleVintagesQuery, SimpleVintagesQueryVariables>;
export const GetLabelsForBocDocument = gql`
    query GetLabelsForBoc($wineEntityId: ID!, $wineId: ID, $vintageId: ID, $level: CertificationLevel) {
  GetLabelsForBOC(
    wineEntityId: $wineEntityId
    wineId: $wineId
    vintageId: $vintageId
    level: $level
  ) {
    object
    createdAt
    updatedAt
    element
    expiryDate
    visibility
    refusalReason
    internalStatus
    isInConversion
    certificationId
    certificationBody
    proofDocuments {
      data
      fileName
    }
    logo {
      data
    }
  }
}
    `;

/**
 * __useGetLabelsForBocQuery__
 *
 * To run a query within a React component, call `useGetLabelsForBocQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelsForBocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelsForBocQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      wineId: // value for 'wineId'
 *      vintageId: // value for 'vintageId'
 *      level: // value for 'level'
 *   },
 * });
 */
export function useGetLabelsForBocQuery(baseOptions: Apollo.QueryHookOptions<GetLabelsForBocQuery, GetLabelsForBocQueryVariables>) {
        return Apollo.useQuery<GetLabelsForBocQuery, GetLabelsForBocQueryVariables>(GetLabelsForBocDocument, baseOptions);
      }
export function useGetLabelsForBocLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLabelsForBocQuery, GetLabelsForBocQueryVariables>) {
          return Apollo.useLazyQuery<GetLabelsForBocQuery, GetLabelsForBocQueryVariables>(GetLabelsForBocDocument, baseOptions);
        }
export type GetLabelsForBocQueryHookResult = ReturnType<typeof useGetLabelsForBocQuery>;
export type GetLabelsForBocLazyQueryHookResult = ReturnType<typeof useGetLabelsForBocLazyQuery>;
export type GetLabelsForBocQueryResult = Apollo.QueryResult<GetLabelsForBocQuery, GetLabelsForBocQueryVariables>;
export const GetCertificationObjectsDocument = gql`
    query GetCertificationObjects($level: CertificationLevel) {
  GetCertificationObjects(level: $level) {
    name
    logo {
      data
    }
    level
    url
    inConversionPossible
  }
}
    `;

/**
 * __useGetCertificationObjectsQuery__
 *
 * To run a query within a React component, call `useGetCertificationObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificationObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificationObjectsQuery({
 *   variables: {
 *      level: // value for 'level'
 *   },
 * });
 */
export function useGetCertificationObjectsQuery(baseOptions?: Apollo.QueryHookOptions<GetCertificationObjectsQuery, GetCertificationObjectsQueryVariables>) {
        return Apollo.useQuery<GetCertificationObjectsQuery, GetCertificationObjectsQueryVariables>(GetCertificationObjectsDocument, baseOptions);
      }
export function useGetCertificationObjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCertificationObjectsQuery, GetCertificationObjectsQueryVariables>) {
          return Apollo.useLazyQuery<GetCertificationObjectsQuery, GetCertificationObjectsQueryVariables>(GetCertificationObjectsDocument, baseOptions);
        }
export type GetCertificationObjectsQueryHookResult = ReturnType<typeof useGetCertificationObjectsQuery>;
export type GetCertificationObjectsLazyQueryHookResult = ReturnType<typeof useGetCertificationObjectsLazyQuery>;
export type GetCertificationObjectsQueryResult = Apollo.QueryResult<GetCertificationObjectsQuery, GetCertificationObjectsQueryVariables>;
export const SubscriptionSummaryDocument = gql`
    query SubscriptionSummary($wineEntityId: ID) {
  SubscriptionSummary(wineEntityId: $wineEntityId) {
    wineEntityName
    mainAppellation
    bgImage {
      data
    }
  }
}
    `;

/**
 * __useSubscriptionSummaryQuery__
 *
 * To run a query within a React component, call `useSubscriptionSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionSummaryQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useSubscriptionSummaryQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionSummaryQuery, SubscriptionSummaryQueryVariables>) {
        return Apollo.useQuery<SubscriptionSummaryQuery, SubscriptionSummaryQueryVariables>(SubscriptionSummaryDocument, baseOptions);
      }
export function useSubscriptionSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionSummaryQuery, SubscriptionSummaryQueryVariables>) {
          return Apollo.useLazyQuery<SubscriptionSummaryQuery, SubscriptionSummaryQueryVariables>(SubscriptionSummaryDocument, baseOptions);
        }
export type SubscriptionSummaryQueryHookResult = ReturnType<typeof useSubscriptionSummaryQuery>;
export type SubscriptionSummaryLazyQueryHookResult = ReturnType<typeof useSubscriptionSummaryLazyQuery>;
export type SubscriptionSummaryQueryResult = Apollo.QueryResult<SubscriptionSummaryQuery, SubscriptionSummaryQueryVariables>;
export const GetCommunesOptionsDocument = gql`
    query GetCommunesOptions($postalCode: String, $filterByRegion: String) {
  GetCommunesOptions(postalCode: $postalCode, filterByRegion: $filterByRegion) {
    communeName
    communeINSEEcode
    appellations
    simpleCommuneName
    postalCode
  }
}
    `;

/**
 * __useGetCommunesOptionsQuery__
 *
 * To run a query within a React component, call `useGetCommunesOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunesOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunesOptionsQuery({
 *   variables: {
 *      postalCode: // value for 'postalCode'
 *      filterByRegion: // value for 'filterByRegion'
 *   },
 * });
 */
export function useGetCommunesOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetCommunesOptionsQuery, GetCommunesOptionsQueryVariables>) {
        return Apollo.useQuery<GetCommunesOptionsQuery, GetCommunesOptionsQueryVariables>(GetCommunesOptionsDocument, baseOptions);
      }
export function useGetCommunesOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunesOptionsQuery, GetCommunesOptionsQueryVariables>) {
          return Apollo.useLazyQuery<GetCommunesOptionsQuery, GetCommunesOptionsQueryVariables>(GetCommunesOptionsDocument, baseOptions);
        }
export type GetCommunesOptionsQueryHookResult = ReturnType<typeof useGetCommunesOptionsQuery>;
export type GetCommunesOptionsLazyQueryHookResult = ReturnType<typeof useGetCommunesOptionsLazyQuery>;
export type GetCommunesOptionsQueryResult = Apollo.QueryResult<GetCommunesOptionsQuery, GetCommunesOptionsQueryVariables>;
export const ActivityListDocument = gql`
    query ActivityList($wineEntityId: ID!) {
  ActivityList(wineEntityId: $wineEntityId) {
    activities {
      activityId
      name
    }
  }
}
    `;

/**
 * __useActivityListQuery__
 *
 * To run a query within a React component, call `useActivityListQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityListQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useActivityListQuery(baseOptions: Apollo.QueryHookOptions<ActivityListQuery, ActivityListQueryVariables>) {
        return Apollo.useQuery<ActivityListQuery, ActivityListQueryVariables>(ActivityListDocument, baseOptions);
      }
export function useActivityListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivityListQuery, ActivityListQueryVariables>) {
          return Apollo.useLazyQuery<ActivityListQuery, ActivityListQueryVariables>(ActivityListDocument, baseOptions);
        }
export type ActivityListQueryHookResult = ReturnType<typeof useActivityListQuery>;
export type ActivityListLazyQueryHookResult = ReturnType<typeof useActivityListLazyQuery>;
export type ActivityListQueryResult = Apollo.QueryResult<ActivityListQuery, ActivityListQueryVariables>;
export const EstateFrontScreenDocument = gql`
    query EstateFrontScreen($wineEntityShortId: String!, $locale: String) {
  EstateFrontScreen(wineEntityShortId: $wineEntityShortId, locale: $locale) {
    wineEntityId
    name
    subscriptionName
    hasBadge
    isOrphan
    promotionBodies
    mainAppellation
    estateSurfaceArea
    displayOpeningHours
    commercialisationTypes
    logos {
      data
      twicpicURL
      twicpicPath
    }
    classifications {
      name
      url
    }
    certifications {
      name
      isInConversion
    }
    bgImage {
      data
      twicpicURL
    }
    socialMedias {
      platform
      name
      url
      icon {
        data
      }
    }
    images {
      twicpicURL
      twicpicPath
      data
      caption
      backgroundImage
      fileName
      bucketName
    }
    description
    truncatedDescription
    cellars {
      name
      surfaceArea
      isVathouse
      isConditioning
      isStorage
      isAgeing
      cellarId
      images {
        data
        twicpicURL
        twicpicPath
      }
    }
    region
    subregion
    subregionImage {
      data
    }
    vineSurfaceArea
    commune
    citation
    citationAuthor {
      title
      functions
    }
    teamImage {
      data
      twicpicURL
    }
    teamDescription
    members {
      displayName
      roles
      image {
        data
        twicpicURL
        twicpicPath
      }
      vip
      description
      roleComplement
      defaultImage
    }
    owners {
      displayName
      roles
      image {
        data
      }
      vip
      description
      defaultImage
    }
    operators {
      displayName
      roles
      image {
        data
      }
      vip
      description
      defaultImage
    }
    firstWines {
      scored
      rewarded
      classifications {
        name
        url
      }
      name
      rank
      color
      type
      sweetness
      lastVintageId
      appellation
      bottleImage {
        data
      }
    }
    secondWines {
      scored
      rewarded
      classifications {
        name
        url
      }
      name
      rank
      color
      type
      sweetness
      lastVintageId
      appellation
      bottleImage {
        data
      }
    }
    otherWines {
      scored
      rewarded
      classifications {
        name
        url
      }
      name
      rank
      color
      type
      sweetness
      lastVintageId
      appellation
      bottleImage {
        data
      }
    }
    activities {
      free
      activityId
      name
      types {
        bgColor
        textColor
        type
      }
      languages {
        language
        icon {
          data
          twicpicURL
        }
      }
      description
      groupSize
      image {
        data
      }
      accessibleTo
      startingAge
      tags
      activePeriods {
        startAt {
          day
          month
          year
          vaguePeriod
        }
        endAt {
          day
          month
          year
          vaguePeriod
        }
      }
      duration
      durationUnit
      reservationNeeded
      priceDetails {
        kind
        price
        currency
      }
      contactPerson {
        title
        type
        functions
        phoneNumber
        email
        personId
        image {
          data
        }
      }
      contactPhoneNumber
      contactEmail
    }
    address {
      address
      complementaryAddress
      commune
      postalCode
      communeINSEEcode
    }
    telephoneNumber
    hasVineyardInformation
    coordinates {
      latitude
      longitude
      preciseLocation
    }
    openingHours {
      name
      open
      openMorning
      closeMorning
      openAfternoon
      closeAfternoon
    }
  }
}
    `;

/**
 * __useEstateFrontScreenQuery__
 *
 * To run a query within a React component, call `useEstateFrontScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstateFrontScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstateFrontScreenQuery({
 *   variables: {
 *      wineEntityShortId: // value for 'wineEntityShortId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useEstateFrontScreenQuery(baseOptions: Apollo.QueryHookOptions<EstateFrontScreenQuery, EstateFrontScreenQueryVariables>) {
        return Apollo.useQuery<EstateFrontScreenQuery, EstateFrontScreenQueryVariables>(EstateFrontScreenDocument, baseOptions);
      }
export function useEstateFrontScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EstateFrontScreenQuery, EstateFrontScreenQueryVariables>) {
          return Apollo.useLazyQuery<EstateFrontScreenQuery, EstateFrontScreenQueryVariables>(EstateFrontScreenDocument, baseOptions);
        }
export type EstateFrontScreenQueryHookResult = ReturnType<typeof useEstateFrontScreenQuery>;
export type EstateFrontScreenLazyQueryHookResult = ReturnType<typeof useEstateFrontScreenLazyQuery>;
export type EstateFrontScreenQueryResult = Apollo.QueryResult<EstateFrontScreenQuery, EstateFrontScreenQueryVariables>;
export const CellarFrontPopinDocument = gql`
    query CellarFrontPopin($wineEntityId: ID!, $cellarId: ID!, $locale: String) {
  CellarFrontPopin(
    wineEntityId: $wineEntityId
    cellarId: $cellarId
    locale: $locale
  ) {
    name
    address {
      address
      complementaryAddress
      commune
      postalCode
      communeINSEEcode
    }
    images {
      data
      twicpicPath
      twicpicURL
    }
    surfaceArea
    airConditioning
    temperature
    description
    vatThermoregulation
    vatCirculation
    isAgeing
    isVathouse
    isConditioning
    isStorage
    storageCapacity
    storageCapacityUnit
    vatContainers {
      kind
      material
      form
      capacity
      quantity
    }
    ageingContainers {
      kind
      material
      form
      capacity
      quantity
    }
    conditioningActivities
    numberOfConditioningChains
    conditioningCapacity
  }
}
    `;

/**
 * __useCellarFrontPopinQuery__
 *
 * To run a query within a React component, call `useCellarFrontPopinQuery` and pass it any options that fit your needs.
 * When your component renders, `useCellarFrontPopinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCellarFrontPopinQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      cellarId: // value for 'cellarId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCellarFrontPopinQuery(baseOptions: Apollo.QueryHookOptions<CellarFrontPopinQuery, CellarFrontPopinQueryVariables>) {
        return Apollo.useQuery<CellarFrontPopinQuery, CellarFrontPopinQueryVariables>(CellarFrontPopinDocument, baseOptions);
      }
export function useCellarFrontPopinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CellarFrontPopinQuery, CellarFrontPopinQueryVariables>) {
          return Apollo.useLazyQuery<CellarFrontPopinQuery, CellarFrontPopinQueryVariables>(CellarFrontPopinDocument, baseOptions);
        }
export type CellarFrontPopinQueryHookResult = ReturnType<typeof useCellarFrontPopinQuery>;
export type CellarFrontPopinLazyQueryHookResult = ReturnType<typeof useCellarFrontPopinLazyQuery>;
export type CellarFrontPopinQueryResult = Apollo.QueryResult<CellarFrontPopinQuery, CellarFrontPopinQueryVariables>;
export const VineyardFrontPopinDocument = gql`
    query VineyardFrontPopin($wineEntityId: ID!, $locale: String) {
  VineyardFrontPopin(wineEntityId: $wineEntityId, locale: $locale) {
    vineSurfaceArea
    averageVineAge
    averagePlantationDensity
    totalParcelCount
    soil
    subsoil
    soilDescription
    rootstocks
    insectWorkDescription
    grassGrowing
    fertilizers
    worksUnderTheRow
    soilWorkDescription
    pruningMethod
    greenProcedures
    vineWorkDescription
    sexualConfusion
    description
    surfaceImportCVI
    surfaces {
      appellation
      superficie
      couleur
      ageMoyenDesVignes
      densiteDePlantation
      communes {
        commune
        superficie
        lieuDits {
          lieuDit
          superficie
        }
      }
      encepagements {
        cepage
        superficie
      }
      vinesCount
      rootstocks
      parcelCount
      localities
    }
    hiddenSurfaces {
      ageMoyenDesVignes
      densiteDePlantation
      communes
      encepagements
      vinesCount
      rootstocks
      parcelCount
    }
    allHiddenSurfaces
    grassGrowingIsTotal
    grassGrowingIsTemporary
    otherCultures
  }
}
    `;

/**
 * __useVineyardFrontPopinQuery__
 *
 * To run a query within a React component, call `useVineyardFrontPopinQuery` and pass it any options that fit your needs.
 * When your component renders, `useVineyardFrontPopinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVineyardFrontPopinQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useVineyardFrontPopinQuery(baseOptions: Apollo.QueryHookOptions<VineyardFrontPopinQuery, VineyardFrontPopinQueryVariables>) {
        return Apollo.useQuery<VineyardFrontPopinQuery, VineyardFrontPopinQueryVariables>(VineyardFrontPopinDocument, baseOptions);
      }
export function useVineyardFrontPopinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VineyardFrontPopinQuery, VineyardFrontPopinQueryVariables>) {
          return Apollo.useLazyQuery<VineyardFrontPopinQuery, VineyardFrontPopinQueryVariables>(VineyardFrontPopinDocument, baseOptions);
        }
export type VineyardFrontPopinQueryHookResult = ReturnType<typeof useVineyardFrontPopinQuery>;
export type VineyardFrontPopinLazyQueryHookResult = ReturnType<typeof useVineyardFrontPopinLazyQuery>;
export type VineyardFrontPopinQueryResult = Apollo.QueryResult<VineyardFrontPopinQuery, VineyardFrontPopinQueryVariables>;
export const FrontWinePresentationDocument = gql`
    query FrontWinePresentation($wineShortId: String!, $locale: String) {
  FrontWinePresentation(wineShortId: $wineShortId, locale: $locale) {
    tabs
    name
    rank
    type
    soil
    label
    color
    rankFr
    wineId
    nonOGM
    subsoil
    sweetness
    noSulphites
    description
    wineEntityId
    harvestMethod
    classification
    wineEntityName
    subscriptionName
    commercialisation
    truncateDescription
    backgroundBottleColor
    averageAge
    totalSurfaceArea
    averageDensity
    wineAssembly {
      grapeVarietyName
      percentage
    }
    vintageList {
      year
      vintageId
      cuvee
      scores {
        _id
        score
        scoreMax
        scoreUrl
        tastingDate
        criticName
        companyName
        tastingComment
      }
    }
    bottleImage {
      data
      twicpicURL
    }
    backgroundBottleImage {
      data
      twicpicURL
    }
    backgroundImage {
      data
      twicpicURL
    }
    scores {
      _id
      score
      scoreMax
      scoreUrl
      tastingDate
      criticName
      companyName
      tastingComment
      vintageYear
    }
  }
}
    `;

/**
 * __useFrontWinePresentationQuery__
 *
 * To run a query within a React component, call `useFrontWinePresentationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontWinePresentationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontWinePresentationQuery({
 *   variables: {
 *      wineShortId: // value for 'wineShortId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFrontWinePresentationQuery(baseOptions: Apollo.QueryHookOptions<FrontWinePresentationQuery, FrontWinePresentationQueryVariables>) {
        return Apollo.useQuery<FrontWinePresentationQuery, FrontWinePresentationQueryVariables>(FrontWinePresentationDocument, baseOptions);
      }
export function useFrontWinePresentationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FrontWinePresentationQuery, FrontWinePresentationQueryVariables>) {
          return Apollo.useLazyQuery<FrontWinePresentationQuery, FrontWinePresentationQueryVariables>(FrontWinePresentationDocument, baseOptions);
        }
export type FrontWinePresentationQueryHookResult = ReturnType<typeof useFrontWinePresentationQuery>;
export type FrontWinePresentationLazyQueryHookResult = ReturnType<typeof useFrontWinePresentationLazyQuery>;
export type FrontWinePresentationQueryResult = Apollo.QueryResult<FrontWinePresentationQuery, FrontWinePresentationQueryVariables>;
export const FrontVintageDocument = gql`
    query FrontVintage($wineShortId: String!, $slug: String, $locale: String) {
  FrontVintage(wineShortId: $wineShortId, slug: $slug, locale: $locale) {
    id
    wineId
    subscriptionName
    noSulphites
    name
    year
    cuvee
    rank
    rankFr
    wineEntityName
    wineEntityId
    label
    color
    sweetness
    type
    ftId
    tabs
    backgroundImage {
      data
      twicpicURL
    }
    wineAssembly {
      grapeVarietyName
      percentage
    }
    climatology {
      season
      logo {
        data
        twicpicURL
      }
      text
    }
    bySeasonClimatology
    bottleImage {
      data
      twicpicURL
    }
    backgroundBottleColor
    backgroundBottleImage {
      data
      twicpicURL
    }
    bottleLabel {
      data
      twicpicURL
    }
    alcoholicStrength
    residualSugar
    totalAcidity
    vintageList {
      year
      vintageId
      cuvee
    }
    vintageDescription
    truncatedVintageDescription
    certificationList {
      certificationId
      object
      logo {
        data
        twicpicURL
      }
      certificationBody
    }
    allergens
    commercialisation
    nonOGM
    productPageUrl
    showDetailedHarvest
    rewards {
      rewardId
      image {
        data
        twicpicURL
      }
      year
      rewardName
      competitionName
      url
    }
    scores {
      score
      scoreMax
      scoreUrl
      tastingDate
      criticName
      companyName
      tastingComment
    }
    classification
  }
}
    `;

/**
 * __useFrontVintageQuery__
 *
 * To run a query within a React component, call `useFrontVintageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontVintageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontVintageQuery({
 *   variables: {
 *      wineShortId: // value for 'wineShortId'
 *      slug: // value for 'slug'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFrontVintageQuery(baseOptions: Apollo.QueryHookOptions<FrontVintageQuery, FrontVintageQueryVariables>) {
        return Apollo.useQuery<FrontVintageQuery, FrontVintageQueryVariables>(FrontVintageDocument, baseOptions);
      }
export function useFrontVintageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FrontVintageQuery, FrontVintageQueryVariables>) {
          return Apollo.useLazyQuery<FrontVintageQuery, FrontVintageQueryVariables>(FrontVintageDocument, baseOptions);
        }
export type FrontVintageQueryHookResult = ReturnType<typeof useFrontVintageQuery>;
export type FrontVintageLazyQueryHookResult = ReturnType<typeof useFrontVintageLazyQuery>;
export type FrontVintageQueryResult = Apollo.QueryResult<FrontVintageQuery, FrontVintageQueryVariables>;
export const FrontVintageViticultureDocument = gql`
    query FrontVintageViticulture($vintageId: ID!, $locale: String) {
  FrontVintageViticulture(vintageId: $vintageId, locale: $locale) {
    soil
    subsoil
    greenOperations {
      operation
      startAt {
        day
        month
        year
        vaguePeriod
      }
      endAt {
        day
        month
        year
        vaguePeriod
      }
    }
    simplePhenologicalStages {
      image {
        data
      }
      stage
      startAt {
        day
        month
        year
        vaguePeriod
      }
      endAt {
        day
        month
        year
        vaguePeriod
      }
    }
    detailedPhenologicalStages {
      image {
        data
      }
      stage
      startAt {
        day
        month
        year
        vaguePeriod
      }
      endAt {
        day
        month
        year
        vaguePeriod
      }
      grapeVariety
    }
    viticultureDetails
    showDefaultViticultureDetails
  }
}
    `;

/**
 * __useFrontVintageViticultureQuery__
 *
 * To run a query within a React component, call `useFrontVintageViticultureQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontVintageViticultureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontVintageViticultureQuery({
 *   variables: {
 *      vintageId: // value for 'vintageId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFrontVintageViticultureQuery(baseOptions: Apollo.QueryHookOptions<FrontVintageViticultureQuery, FrontVintageViticultureQueryVariables>) {
        return Apollo.useQuery<FrontVintageViticultureQuery, FrontVintageViticultureQueryVariables>(FrontVintageViticultureDocument, baseOptions);
      }
export function useFrontVintageViticultureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FrontVintageViticultureQuery, FrontVintageViticultureQueryVariables>) {
          return Apollo.useLazyQuery<FrontVintageViticultureQuery, FrontVintageViticultureQueryVariables>(FrontVintageViticultureDocument, baseOptions);
        }
export type FrontVintageViticultureQueryHookResult = ReturnType<typeof useFrontVintageViticultureQuery>;
export type FrontVintageViticultureLazyQueryHookResult = ReturnType<typeof useFrontVintageViticultureLazyQuery>;
export type FrontVintageViticultureQueryResult = Apollo.QueryResult<FrontVintageViticultureQuery, FrontVintageViticultureQueryVariables>;
export const FrontVintageLabelsPopinDocument = gql`
    query FrontVintageLabelsPopin($wineEntityId: ID!, $locale: String) {
  FrontVintageLabelsPopin(wineEntityId: $wineEntityId, locale: $locale) {
    certificationId
    object
    logo {
      data
    }
    expiryDate
    certificationBody
    isInConversion
  }
}
    `;

/**
 * __useFrontVintageLabelsPopinQuery__
 *
 * To run a query within a React component, call `useFrontVintageLabelsPopinQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontVintageLabelsPopinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontVintageLabelsPopinQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFrontVintageLabelsPopinQuery(baseOptions: Apollo.QueryHookOptions<FrontVintageLabelsPopinQuery, FrontVintageLabelsPopinQueryVariables>) {
        return Apollo.useQuery<FrontVintageLabelsPopinQuery, FrontVintageLabelsPopinQueryVariables>(FrontVintageLabelsPopinDocument, baseOptions);
      }
export function useFrontVintageLabelsPopinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FrontVintageLabelsPopinQuery, FrontVintageLabelsPopinQueryVariables>) {
          return Apollo.useLazyQuery<FrontVintageLabelsPopinQuery, FrontVintageLabelsPopinQueryVariables>(FrontVintageLabelsPopinDocument, baseOptions);
        }
export type FrontVintageLabelsPopinQueryHookResult = ReturnType<typeof useFrontVintageLabelsPopinQuery>;
export type FrontVintageLabelsPopinLazyQueryHookResult = ReturnType<typeof useFrontVintageLabelsPopinLazyQuery>;
export type FrontVintageLabelsPopinQueryResult = Apollo.QueryResult<FrontVintageLabelsPopinQuery, FrontVintageLabelsPopinQueryVariables>;
export const FrontVintageWineMakingDocument = gql`
    query FrontVintageWineMaking($vintageId: ID, $wineId: ID, $locale: String) {
  FrontVintageWineMaking(vintageId: $vintageId, wineId: $wineId, locale: $locale) {
    consultingEnologist {
      type
      title
      functions
      image {
        data
      }
    }
    vinificationPlace
    vinificationDetails
    vinificationCoopName
    parcelBasedVinification
    sortingMethod
    destemming
    destemmingFr
    treading
    treadingFr
    vatting
    extractionMode
    yeasting
    alcoholicFermentationDuration
    alcoholicFermentationDurationUnit
    alcoholicFermentationTemperature
    malolacticFermentation
    malolacticBacteria
    malolacticFermentationDuration
    malolacticFermentationDurationUnit
    malolacticFermentationTemperature
    pressType
    vinificationDetails
    macerations {
      type
      duration
      durationUnit
      temperature
    }
  }
}
    `;

/**
 * __useFrontVintageWineMakingQuery__
 *
 * To run a query within a React component, call `useFrontVintageWineMakingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontVintageWineMakingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontVintageWineMakingQuery({
 *   variables: {
 *      vintageId: // value for 'vintageId'
 *      wineId: // value for 'wineId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFrontVintageWineMakingQuery(baseOptions?: Apollo.QueryHookOptions<FrontVintageWineMakingQuery, FrontVintageWineMakingQueryVariables>) {
        return Apollo.useQuery<FrontVintageWineMakingQuery, FrontVintageWineMakingQueryVariables>(FrontVintageWineMakingDocument, baseOptions);
      }
export function useFrontVintageWineMakingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FrontVintageWineMakingQuery, FrontVintageWineMakingQueryVariables>) {
          return Apollo.useLazyQuery<FrontVintageWineMakingQuery, FrontVintageWineMakingQueryVariables>(FrontVintageWineMakingDocument, baseOptions);
        }
export type FrontVintageWineMakingQueryHookResult = ReturnType<typeof useFrontVintageWineMakingQuery>;
export type FrontVintageWineMakingLazyQueryHookResult = ReturnType<typeof useFrontVintageWineMakingLazyQuery>;
export type FrontVintageWineMakingQueryResult = Apollo.QueryResult<FrontVintageWineMakingQuery, FrontVintageWineMakingQueryVariables>;
export const FrontVintageAgeingDocument = gql`
    query FrontVintageAgeing($vintageId: ID, $wineId: ID, $locale: String) {
  FrontVintageAgeing(vintageId: $vintageId, wineId: $wineId, locale: $locale) {
    barrelMaturation
    barrelMaturationDurationValue
    barrelMaturationDurationUnit
    newBarrelPercentage
    toasting
    frenchOak
    barrelMakers {
      barrelMaker
      percentage
    }
    otherMaturations {
      containerType
      durationValue
      durationUnit
      material
    }
    lees
    batonnage
    description
  }
}
    `;

/**
 * __useFrontVintageAgeingQuery__
 *
 * To run a query within a React component, call `useFrontVintageAgeingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontVintageAgeingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontVintageAgeingQuery({
 *   variables: {
 *      vintageId: // value for 'vintageId'
 *      wineId: // value for 'wineId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFrontVintageAgeingQuery(baseOptions?: Apollo.QueryHookOptions<FrontVintageAgeingQuery, FrontVintageAgeingQueryVariables>) {
        return Apollo.useQuery<FrontVintageAgeingQuery, FrontVintageAgeingQueryVariables>(FrontVintageAgeingDocument, baseOptions);
      }
export function useFrontVintageAgeingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FrontVintageAgeingQuery, FrontVintageAgeingQueryVariables>) {
          return Apollo.useLazyQuery<FrontVintageAgeingQuery, FrontVintageAgeingQueryVariables>(FrontVintageAgeingDocument, baseOptions);
        }
export type FrontVintageAgeingQueryHookResult = ReturnType<typeof useFrontVintageAgeingQuery>;
export type FrontVintageAgeingLazyQueryHookResult = ReturnType<typeof useFrontVintageAgeingLazyQuery>;
export type FrontVintageAgeingQueryResult = Apollo.QueryResult<FrontVintageAgeingQuery, FrontVintageAgeingQueryVariables>;
export const FrontVintageProductionDocument = gql`
    query FrontVintageProduction($vintageId: ID, $wineId: ID, $locale: String) {
  FrontVintageProduction(vintageId: $vintageId, wineId: $wineId, locale: $locale) {
    corkingMode
    finingTypes
    filtrationTypes
    bottlingDate
    estateBottling
    address {
      address
      complementaryAddress
      postalCode
      commune
      communeINSEEcode
    }
    bottlesVolume
    yield
    production
    waxedBottle
    bottler {
      title
      type
    }
    bottlerCommune
    bottlerCommuneINSEEcode
    bottlerPostalCode
  }
}
    `;

/**
 * __useFrontVintageProductionQuery__
 *
 * To run a query within a React component, call `useFrontVintageProductionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontVintageProductionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontVintageProductionQuery({
 *   variables: {
 *      vintageId: // value for 'vintageId'
 *      wineId: // value for 'wineId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFrontVintageProductionQuery(baseOptions?: Apollo.QueryHookOptions<FrontVintageProductionQuery, FrontVintageProductionQueryVariables>) {
        return Apollo.useQuery<FrontVintageProductionQuery, FrontVintageProductionQueryVariables>(FrontVintageProductionDocument, baseOptions);
      }
export function useFrontVintageProductionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FrontVintageProductionQuery, FrontVintageProductionQueryVariables>) {
          return Apollo.useLazyQuery<FrontVintageProductionQuery, FrontVintageProductionQueryVariables>(FrontVintageProductionDocument, baseOptions);
        }
export type FrontVintageProductionQueryHookResult = ReturnType<typeof useFrontVintageProductionQuery>;
export type FrontVintageProductionLazyQueryHookResult = ReturnType<typeof useFrontVintageProductionLazyQuery>;
export type FrontVintageProductionQueryResult = Apollo.QueryResult<FrontVintageProductionQuery, FrontVintageProductionQueryVariables>;
export const FrontVintageNutritionDocument = gql`
    query FrontVintageNutrition($vintageId: ID!, $locale: String) {
  FrontVintageNutrition(vintageId: $vintageId, locale: $locale) {
    nutritionalAnalysis {
      data
      fileName
      bucketName
    }
    fats
    saturatedFattyAcids
    glucides
    sugars
    protein
    salt
    energy
    energyType
    alcoholicStrength
    residualSugar
    totalAcidity
    auto
    qrnutri {
      data
      fileName
      bucketName
    }
    ingredients {
      ingredient
    }
  }
}
    `;

/**
 * __useFrontVintageNutritionQuery__
 *
 * To run a query within a React component, call `useFrontVintageNutritionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontVintageNutritionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontVintageNutritionQuery({
 *   variables: {
 *      vintageId: // value for 'vintageId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFrontVintageNutritionQuery(baseOptions: Apollo.QueryHookOptions<FrontVintageNutritionQuery, FrontVintageNutritionQueryVariables>) {
        return Apollo.useQuery<FrontVintageNutritionQuery, FrontVintageNutritionQueryVariables>(FrontVintageNutritionDocument, baseOptions);
      }
export function useFrontVintageNutritionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FrontVintageNutritionQuery, FrontVintageNutritionQueryVariables>) {
          return Apollo.useLazyQuery<FrontVintageNutritionQuery, FrontVintageNutritionQueryVariables>(FrontVintageNutritionDocument, baseOptions);
        }
export type FrontVintageNutritionQueryHookResult = ReturnType<typeof useFrontVintageNutritionQuery>;
export type FrontVintageNutritionLazyQueryHookResult = ReturnType<typeof useFrontVintageNutritionLazyQuery>;
export type FrontVintageNutritionQueryResult = Apollo.QueryResult<FrontVintageNutritionQuery, FrontVintageNutritionQueryVariables>;
export const FrontVintageQrcodeDocument = gql`
    query FrontVintageQrcode($vintageId: ID!, $locale: String) {
  FrontVintageQrcode(vintageId: $vintageId, locale: $locale) {
    wineId
    wineShortId
    wineEntityShortId
    name
    year
    cuvee
    wineEntityName
    wineEntityId
    label
    nutritionalAnalysis {
      data
      fileName
      bucketName
    }
    fats
    saturatedFattyAcids
    glucides
    sugars
    protein
    salt
    energy
    energyType
    alcoholicStrength
    residualSugar
    totalAcidity
    qrnutri {
      data
      fileName
      bucketName
    }
    ingredients {
      ingredient
      isAllergen
    }
  }
}
    `;

/**
 * __useFrontVintageQrcodeQuery__
 *
 * To run a query within a React component, call `useFrontVintageQrcodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontVintageQrcodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontVintageQrcodeQuery({
 *   variables: {
 *      vintageId: // value for 'vintageId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFrontVintageQrcodeQuery(baseOptions: Apollo.QueryHookOptions<FrontVintageQrcodeQuery, FrontVintageQrcodeQueryVariables>) {
        return Apollo.useQuery<FrontVintageQrcodeQuery, FrontVintageQrcodeQueryVariables>(FrontVintageQrcodeDocument, baseOptions);
      }
export function useFrontVintageQrcodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FrontVintageQrcodeQuery, FrontVintageQrcodeQueryVariables>) {
          return Apollo.useLazyQuery<FrontVintageQrcodeQuery, FrontVintageQrcodeQueryVariables>(FrontVintageQrcodeDocument, baseOptions);
        }
export type FrontVintageQrcodeQueryHookResult = ReturnType<typeof useFrontVintageQrcodeQuery>;
export type FrontVintageQrcodeLazyQueryHookResult = ReturnType<typeof useFrontVintageQrcodeLazyQuery>;
export type FrontVintageQrcodeQueryResult = Apollo.QueryResult<FrontVintageQrcodeQuery, FrontVintageQrcodeQueryVariables>;
export const FrontQrcodeRedirectDocument = gql`
    query FrontQrcodeRedirect($vintageId: String!) {
  FrontQrcodeRedirect(vintageId: $vintageId) {
    wineId
    vintageId
    url
    redirect
  }
}
    `;

/**
 * __useFrontQrcodeRedirectQuery__
 *
 * To run a query within a React component, call `useFrontQrcodeRedirectQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontQrcodeRedirectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontQrcodeRedirectQuery({
 *   variables: {
 *      vintageId: // value for 'vintageId'
 *   },
 * });
 */
export function useFrontQrcodeRedirectQuery(baseOptions: Apollo.QueryHookOptions<FrontQrcodeRedirectQuery, FrontQrcodeRedirectQueryVariables>) {
        return Apollo.useQuery<FrontQrcodeRedirectQuery, FrontQrcodeRedirectQueryVariables>(FrontQrcodeRedirectDocument, baseOptions);
      }
export function useFrontQrcodeRedirectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FrontQrcodeRedirectQuery, FrontQrcodeRedirectQueryVariables>) {
          return Apollo.useLazyQuery<FrontQrcodeRedirectQuery, FrontQrcodeRedirectQueryVariables>(FrontQrcodeRedirectDocument, baseOptions);
        }
export type FrontQrcodeRedirectQueryHookResult = ReturnType<typeof useFrontQrcodeRedirectQuery>;
export type FrontQrcodeRedirectLazyQueryHookResult = ReturnType<typeof useFrontQrcodeRedirectLazyQuery>;
export type FrontQrcodeRedirectQueryResult = Apollo.QueryResult<FrontQrcodeRedirectQuery, FrontQrcodeRedirectQueryVariables>;
export const FrontVintageMarketingDocument = gql`
    query FrontVintageMarketing($vintageId: ID, $wineId: ID, $locale: String) {
  FrontVintageMarketing(vintageId: $vintageId, wineId: $wineId, locale: $locale) {
    exportPercentage
    directPercentage
    indirectPercentage
    exportCountries
    packagings {
      conditionningMode
      centilisation
      units
      price
      gtin
      url
      icon {
        data
      }
    }
  }
}
    `;

/**
 * __useFrontVintageMarketingQuery__
 *
 * To run a query within a React component, call `useFrontVintageMarketingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontVintageMarketingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontVintageMarketingQuery({
 *   variables: {
 *      vintageId: // value for 'vintageId'
 *      wineId: // value for 'wineId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFrontVintageMarketingQuery(baseOptions?: Apollo.QueryHookOptions<FrontVintageMarketingQuery, FrontVintageMarketingQueryVariables>) {
        return Apollo.useQuery<FrontVintageMarketingQuery, FrontVintageMarketingQueryVariables>(FrontVintageMarketingDocument, baseOptions);
      }
export function useFrontVintageMarketingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FrontVintageMarketingQuery, FrontVintageMarketingQueryVariables>) {
          return Apollo.useLazyQuery<FrontVintageMarketingQuery, FrontVintageMarketingQueryVariables>(FrontVintageMarketingDocument, baseOptions);
        }
export type FrontVintageMarketingQueryHookResult = ReturnType<typeof useFrontVintageMarketingQuery>;
export type FrontVintageMarketingLazyQueryHookResult = ReturnType<typeof useFrontVintageMarketingLazyQuery>;
export type FrontVintageMarketingQueryResult = Apollo.QueryResult<FrontVintageMarketingQuery, FrontVintageMarketingQueryVariables>;
export const FrontVintageAdviceDocument = gql`
    query FrontVintageAdvice($vintageId: ID, $wineId: ID, $locale: String) {
  FrontVintageAdvice(vintageId: $vintageId, wineId: $wineId, locale: $locale) {
    wineColor
    wineSweetness
    wineLabel
    videoUrl
    serviceTemperature
    apogee
    ageing
    treatmentBeforeService
    decantingDuration
    foodPairingGroups {
      foods
      category
      icon {
        data
      }
    }
    appearanceColor
    appearanceIntensity
    appearanceBrilliance
    appearanceLimpidity
    appearanceTears
    noseAromas
    noseIntensity
    palateAttack
    palateIntensity
    palateLength
    palateAcidity
    palateTannins
    aromaValues
    description
    aromaAnimal
    aromaBalsamic
    aromaConfectionery
    aromaEmpyreumatic
    aromaFermented
    aromaFloral
    aromaFruity
    aromaMineral
    aromaOaky
    aromaSpicy
    aromaVegetal
  }
}
    `;

/**
 * __useFrontVintageAdviceQuery__
 *
 * To run a query within a React component, call `useFrontVintageAdviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontVintageAdviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontVintageAdviceQuery({
 *   variables: {
 *      vintageId: // value for 'vintageId'
 *      wineId: // value for 'wineId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFrontVintageAdviceQuery(baseOptions?: Apollo.QueryHookOptions<FrontVintageAdviceQuery, FrontVintageAdviceQueryVariables>) {
        return Apollo.useQuery<FrontVintageAdviceQuery, FrontVintageAdviceQueryVariables>(FrontVintageAdviceDocument, baseOptions);
      }
export function useFrontVintageAdviceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FrontVintageAdviceQuery, FrontVintageAdviceQueryVariables>) {
          return Apollo.useLazyQuery<FrontVintageAdviceQuery, FrontVintageAdviceQueryVariables>(FrontVintageAdviceDocument, baseOptions);
        }
export type FrontVintageAdviceQueryHookResult = ReturnType<typeof useFrontVintageAdviceQuery>;
export type FrontVintageAdviceLazyQueryHookResult = ReturnType<typeof useFrontVintageAdviceLazyQuery>;
export type FrontVintageAdviceQueryResult = Apollo.QueryResult<FrontVintageAdviceQuery, FrontVintageAdviceQueryVariables>;
export const FrontVintageDetailedHarvestDocument = gql`
    query FrontVintageDetailedHarvest($vintageId: ID!, $locale: String) {
  FrontVintageDetailedHarvest(vintageId: $vintageId, locale: $locale) {
    method
    methodFr
    startAt {
      day
      month
      year
      vaguePeriod
    }
    endAt {
      day
      month
      year
      vaguePeriod
    }
    icon {
      data
    }
    grapeVariety
  }
}
    `;

/**
 * __useFrontVintageDetailedHarvestQuery__
 *
 * To run a query within a React component, call `useFrontVintageDetailedHarvestQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontVintageDetailedHarvestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontVintageDetailedHarvestQuery({
 *   variables: {
 *      vintageId: // value for 'vintageId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFrontVintageDetailedHarvestQuery(baseOptions: Apollo.QueryHookOptions<FrontVintageDetailedHarvestQuery, FrontVintageDetailedHarvestQueryVariables>) {
        return Apollo.useQuery<FrontVintageDetailedHarvestQuery, FrontVintageDetailedHarvestQueryVariables>(FrontVintageDetailedHarvestDocument, baseOptions);
      }
export function useFrontVintageDetailedHarvestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FrontVintageDetailedHarvestQuery, FrontVintageDetailedHarvestQueryVariables>) {
          return Apollo.useLazyQuery<FrontVintageDetailedHarvestQuery, FrontVintageDetailedHarvestQueryVariables>(FrontVintageDetailedHarvestDocument, baseOptions);
        }
export type FrontVintageDetailedHarvestQueryHookResult = ReturnType<typeof useFrontVintageDetailedHarvestQuery>;
export type FrontVintageDetailedHarvestLazyQueryHookResult = ReturnType<typeof useFrontVintageDetailedHarvestLazyQuery>;
export type FrontVintageDetailedHarvestQueryResult = Apollo.QueryResult<FrontVintageDetailedHarvestQuery, FrontVintageDetailedHarvestQueryVariables>;
export const GetTranslationOptionsDocument = gql`
    query GetTranslationOptions($wineEntityId: ID) {
  GetTranslationOptions(wineEntityId: $wineEntityId) {
    label
    value
    status
    supportedTranslationModes
    chosenTranslationMode
    discountFree
    price
    upToDate {
      id
      subId
      price
      label
      accessor
      oldValue
      newValue
      wordCount
      modelName
      additionalInfo
      oldValueTranslated
    }
    outdated {
      id
      subId
      price
      label
      accessor
      oldValue
      newValue
      wordCount
      modelName
      additionalInfo
      oldValueTranslated
    }
    notTranslated {
      id
      subId
      price
      label
      accessor
      oldValue
      newValue
      wordCount
      modelName
      additionalInfo
      oldValueTranslated
    }
    lastTranslationRequestCreatedAt
  }
}
    `;

/**
 * __useGetTranslationOptionsQuery__
 *
 * To run a query within a React component, call `useGetTranslationOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTranslationOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTranslationOptionsQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetTranslationOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetTranslationOptionsQuery, GetTranslationOptionsQueryVariables>) {
        return Apollo.useQuery<GetTranslationOptionsQuery, GetTranslationOptionsQueryVariables>(GetTranslationOptionsDocument, baseOptions);
      }
export function useGetTranslationOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTranslationOptionsQuery, GetTranslationOptionsQueryVariables>) {
          return Apollo.useLazyQuery<GetTranslationOptionsQuery, GetTranslationOptionsQueryVariables>(GetTranslationOptionsDocument, baseOptions);
        }
export type GetTranslationOptionsQueryHookResult = ReturnType<typeof useGetTranslationOptionsQuery>;
export type GetTranslationOptionsLazyQueryHookResult = ReturnType<typeof useGetTranslationOptionsLazyQuery>;
export type GetTranslationOptionsQueryResult = Apollo.QueryResult<GetTranslationOptionsQuery, GetTranslationOptionsQueryVariables>;
export const ActivityFrontDocument = gql`
    query ActivityFront($activityShortId: String, $locale: String) {
  ActivityFront(activityShortId: $activityShortId, locale: $locale) {
    id
    wineEntityShortId
    activityShortId
    estateName
    isOrphan
    bgImage {
      data
      twicpicURL
    }
    socialMedias {
      platform
      name
      url
      icon {
        data
      }
    }
    receptionAreas {
      name
      surfaceArea
      surfaceAreaUnit
      capacity
      type
    }
    atWineEntity
    private
    address {
      address
      complementaryAddress
      postalCode
      commune
      communeINSEEcode
    }
    wineEntityId
    availableOptions
    equipmentProvided
    paymentDetails {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    priceOnEstimation
    paymentOptionsAccepted
    sanitaryMeasures
    minRentTime
    minRentTimeUnit
    maxRentTime
    maxRentTimeUnit
    accomodationPossible
    bedCount
    accomodationDetails {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    free
    name
    types {
      typeFr
      bgColor
      textColor
      type
    }
    languages {
      language
      icon {
        data
        twicpicURL
      }
    }
    description
    groupSize
    images {
      data
      fileName
      twicpicPath
      twicpicURL
    }
    accessibleTo
    startingAge
    tags
    activePeriods {
      startAt {
        day
        month
        year
        vaguePeriod
      }
      endAt {
        day
        month
        year
        vaguePeriod
      }
    }
    duration
    durationUnit
    reservationNeeded
    priceDetails {
      kind
      price
    }
    contactPerson {
      title
      type
      functions
    }
    contactPhoneNumber
    contactEmail
    parkingBus
    numberWineTasted
  }
}
    `;

/**
 * __useActivityFrontQuery__
 *
 * To run a query within a React component, call `useActivityFrontQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityFrontQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityFrontQuery({
 *   variables: {
 *      activityShortId: // value for 'activityShortId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useActivityFrontQuery(baseOptions?: Apollo.QueryHookOptions<ActivityFrontQuery, ActivityFrontQueryVariables>) {
        return Apollo.useQuery<ActivityFrontQuery, ActivityFrontQueryVariables>(ActivityFrontDocument, baseOptions);
      }
export function useActivityFrontLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivityFrontQuery, ActivityFrontQueryVariables>) {
          return Apollo.useLazyQuery<ActivityFrontQuery, ActivityFrontQueryVariables>(ActivityFrontDocument, baseOptions);
        }
export type ActivityFrontQueryHookResult = ReturnType<typeof useActivityFrontQuery>;
export type ActivityFrontLazyQueryHookResult = ReturnType<typeof useActivityFrontLazyQuery>;
export type ActivityFrontQueryResult = Apollo.QueryResult<ActivityFrontQuery, ActivityFrontQueryVariables>;
export const ActivityTabScreenDocument = gql`
    query ActivityTabScreen($wineEntityId: ID!, $activityId: ID!) {
  ActivityTabScreen(wineEntityId: $wineEntityId, activityId: $activityId) {
    receptionAreas {
      name
      surfaceArea
      surfaceAreaUnit
      capacity
      type
    }
    atWineEntity
    private
    address {
      address
      complementaryAddress
      postalCode
      commune
      communeINSEEcode
    }
    wineEntityAddress {
      address
      complementaryAddress
      postalCode
      commune
      communeINSEEcode
    }
    availableOptions
    equipmentProvided
    paymentDetails {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    priceOnEstimation
    paymentOptionsAccepted
    sanitaryMeasures
    minRentTime
    minRentTimeUnit
    maxRentTime
    maxRentTimeUnit
    accomodationPossible
    bedCount
    accomodationDetails {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    free
    name
    types
    languages
    description {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    groupSize
    images {
      data
      fileName
    }
    accessibleTo
    startingAge
    tags
    activePeriods {
      startAt {
        day
        month
        year
        vaguePeriod
      }
      endAt {
        day
        month
        year
        vaguePeriod
      }
    }
    isVagueActivePeriods
    vagueActivePeriods {
      startAt {
        day
        month
        year
        vaguePeriod
      }
      endAt {
        day
        month
        year
        vaguePeriod
      }
    }
    duration
    durationUnit
    reservationNeeded
    priceDetails {
      kind
      price
    }
    contactPerson {
      title
      type
      functions
    }
    contactPhoneNumber
    contactEmail
    contactOptions {
      title
      type
      functions
      personId
      email
      phoneNumber
    }
    parkingBus
    numberWineTasted
  }
}
    `;

/**
 * __useActivityTabScreenQuery__
 *
 * To run a query within a React component, call `useActivityTabScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityTabScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityTabScreenQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useActivityTabScreenQuery(baseOptions: Apollo.QueryHookOptions<ActivityTabScreenQuery, ActivityTabScreenQueryVariables>) {
        return Apollo.useQuery<ActivityTabScreenQuery, ActivityTabScreenQueryVariables>(ActivityTabScreenDocument, baseOptions);
      }
export function useActivityTabScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivityTabScreenQuery, ActivityTabScreenQueryVariables>) {
          return Apollo.useLazyQuery<ActivityTabScreenQuery, ActivityTabScreenQueryVariables>(ActivityTabScreenDocument, baseOptions);
        }
export type ActivityTabScreenQueryHookResult = ReturnType<typeof useActivityTabScreenQuery>;
export type ActivityTabScreenLazyQueryHookResult = ReturnType<typeof useActivityTabScreenLazyQuery>;
export type ActivityTabScreenQueryResult = Apollo.QueryResult<ActivityTabScreenQuery, ActivityTabScreenQueryVariables>;
export const ContactScreenDocument = gql`
    query ContactScreen($wineEntityId: ID!, $personId: ID, $type: String) {
  ContactScreen(wineEntityId: $wineEntityId, personId: $personId, type: $type) {
    contactEmail
    contactPhoneNumber
  }
}
    `;

/**
 * __useContactScreenQuery__
 *
 * To run a query within a React component, call `useContactScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactScreenQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      personId: // value for 'personId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useContactScreenQuery(baseOptions: Apollo.QueryHookOptions<ContactScreenQuery, ContactScreenQueryVariables>) {
        return Apollo.useQuery<ContactScreenQuery, ContactScreenQueryVariables>(ContactScreenDocument, baseOptions);
      }
export function useContactScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactScreenQuery, ContactScreenQueryVariables>) {
          return Apollo.useLazyQuery<ContactScreenQuery, ContactScreenQueryVariables>(ContactScreenDocument, baseOptions);
        }
export type ContactScreenQueryHookResult = ReturnType<typeof useContactScreenQuery>;
export type ContactScreenLazyQueryHookResult = ReturnType<typeof useContactScreenLazyQuery>;
export type ContactScreenQueryResult = Apollo.QueryResult<ContactScreenQuery, ContactScreenQueryVariables>;
export const AllowedAppellationLabelsDocument = gql`
    query AllowedAppellationLabels($wineEntityId: ID!) {
  AllowedAppellationLabels(wineEntityId: $wineEntityId)
}
    `;

/**
 * __useAllowedAppellationLabelsQuery__
 *
 * To run a query within a React component, call `useAllowedAppellationLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowedAppellationLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowedAppellationLabelsQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useAllowedAppellationLabelsQuery(baseOptions: Apollo.QueryHookOptions<AllowedAppellationLabelsQuery, AllowedAppellationLabelsQueryVariables>) {
        return Apollo.useQuery<AllowedAppellationLabelsQuery, AllowedAppellationLabelsQueryVariables>(AllowedAppellationLabelsDocument, baseOptions);
      }
export function useAllowedAppellationLabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllowedAppellationLabelsQuery, AllowedAppellationLabelsQueryVariables>) {
          return Apollo.useLazyQuery<AllowedAppellationLabelsQuery, AllowedAppellationLabelsQueryVariables>(AllowedAppellationLabelsDocument, baseOptions);
        }
export type AllowedAppellationLabelsQueryHookResult = ReturnType<typeof useAllowedAppellationLabelsQuery>;
export type AllowedAppellationLabelsLazyQueryHookResult = ReturnType<typeof useAllowedAppellationLabelsLazyQuery>;
export type AllowedAppellationLabelsQueryResult = Apollo.QueryResult<AllowedAppellationLabelsQuery, AllowedAppellationLabelsQueryVariables>;
export const AllFoodPairingsDocument = gql`
    query AllFoodPairings {
  AllFoodPairings {
    food
    category
  }
}
    `;

/**
 * __useAllFoodPairingsQuery__
 *
 * To run a query within a React component, call `useAllFoodPairingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFoodPairingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFoodPairingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllFoodPairingsQuery(baseOptions?: Apollo.QueryHookOptions<AllFoodPairingsQuery, AllFoodPairingsQueryVariables>) {
        return Apollo.useQuery<AllFoodPairingsQuery, AllFoodPairingsQueryVariables>(AllFoodPairingsDocument, baseOptions);
      }
export function useAllFoodPairingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllFoodPairingsQuery, AllFoodPairingsQueryVariables>) {
          return Apollo.useLazyQuery<AllFoodPairingsQuery, AllFoodPairingsQueryVariables>(AllFoodPairingsDocument, baseOptions);
        }
export type AllFoodPairingsQueryHookResult = ReturnType<typeof useAllFoodPairingsQuery>;
export type AllFoodPairingsLazyQueryHookResult = ReturnType<typeof useAllFoodPairingsLazyQuery>;
export type AllFoodPairingsQueryResult = Apollo.QueryResult<AllFoodPairingsQuery, AllFoodPairingsQueryVariables>;
export const GetExternalServiceRequestsDocument = gql`
    query GetExternalServiceRequests($wineEntityId: ID, $userId: ID) {
  GetExternalServiceRequests(wineEntityId: $wineEntityId, userId: $userId) {
    hasTastingRequest
    services {
      _id
      createdAt
      updatedAt
      status
      userId
      wineEntityId
      type
      interview
      numberOfPackshots
      photoReport
      standaloneStatus
      webAddress
    }
  }
}
    `;

/**
 * __useGetExternalServiceRequestsQuery__
 *
 * To run a query within a React component, call `useGetExternalServiceRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalServiceRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalServiceRequestsQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetExternalServiceRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetExternalServiceRequestsQuery, GetExternalServiceRequestsQueryVariables>) {
        return Apollo.useQuery<GetExternalServiceRequestsQuery, GetExternalServiceRequestsQueryVariables>(GetExternalServiceRequestsDocument, baseOptions);
      }
export function useGetExternalServiceRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExternalServiceRequestsQuery, GetExternalServiceRequestsQueryVariables>) {
          return Apollo.useLazyQuery<GetExternalServiceRequestsQuery, GetExternalServiceRequestsQueryVariables>(GetExternalServiceRequestsDocument, baseOptions);
        }
export type GetExternalServiceRequestsQueryHookResult = ReturnType<typeof useGetExternalServiceRequestsQuery>;
export type GetExternalServiceRequestsLazyQueryHookResult = ReturnType<typeof useGetExternalServiceRequestsLazyQuery>;
export type GetExternalServiceRequestsQueryResult = Apollo.QueryResult<GetExternalServiceRequestsQuery, GetExternalServiceRequestsQueryVariables>;
export const VintageRewardScreenDocument = gql`
    query VintageRewardScreen($wineEntityId: ID!, $vintageId: ID!) {
  VintageRewardScreen(wineEntityId: $wineEntityId, vintageId: $vintageId) {
    rewards {
      competitionName
      rewardName
      competitionYear
      hidden
      rewardId
      sortIndex
      logo {
        data
        twicpicURL
      }
    }
    scores {
      id
      score
      hidden
      isFeret
      scoreMax
      scoreUrl
      sortIndex
      criticName
      permission
      companyName
      tastingDate
      forceVisibility
      tastingRequestId
      tastingComment {
        fr
        en
        de
        es
        it
        nl
        ja
        zh
      }
    }
  }
}
    `;

/**
 * __useVintageRewardScreenQuery__
 *
 * To run a query within a React component, call `useVintageRewardScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVintageRewardScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVintageRewardScreenQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      vintageId: // value for 'vintageId'
 *   },
 * });
 */
export function useVintageRewardScreenQuery(baseOptions: Apollo.QueryHookOptions<VintageRewardScreenQuery, VintageRewardScreenQueryVariables>) {
        return Apollo.useQuery<VintageRewardScreenQuery, VintageRewardScreenQueryVariables>(VintageRewardScreenDocument, baseOptions);
      }
export function useVintageRewardScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VintageRewardScreenQuery, VintageRewardScreenQueryVariables>) {
          return Apollo.useLazyQuery<VintageRewardScreenQuery, VintageRewardScreenQueryVariables>(VintageRewardScreenDocument, baseOptions);
        }
export type VintageRewardScreenQueryHookResult = ReturnType<typeof useVintageRewardScreenQuery>;
export type VintageRewardScreenLazyQueryHookResult = ReturnType<typeof useVintageRewardScreenLazyQuery>;
export type VintageRewardScreenQueryResult = Apollo.QueryResult<VintageRewardScreenQuery, VintageRewardScreenQueryVariables>;
export const FrontWineListDocument = gql`
    query FrontWineList($wineEntityId: ID!, $locale: String, $omitWineId: ID) {
  FrontWineList(
    wineEntityId: $wineEntityId
    locale: $locale
    omitWineId: $omitWineId
  ) {
    firstWines {
      scored
      wineId
      shortWineId
      rewarded
      classifications {
        name
        url
      }
      name
      rank
      rankFr
      color
      colorFr
      type
      sweetness
      lastVintageId
      appellation
      bottleImage {
        twicpicURL
        data
      }
      shouldMentionSweetness
      shouldMentionColor
    }
    secondWines {
      scored
      wineId
      shortWineId
      rewarded
      classifications {
        name
        url
      }
      name
      rank
      rankFr
      color
      colorFr
      type
      sweetness
      lastVintageId
      appellation
      bottleImage {
        twicpicURL
        data
      }
      shouldMentionSweetness
      shouldMentionColor
    }
    otherWines {
      wineId
      shortWineId
      scored
      rewarded
      classifications {
        name
        url
      }
      name
      rank
      rankFr
      color
      colorFr
      type
      sweetness
      lastVintageId
      appellation
      bottleImage {
        twicpicURL
        data
      }
      shouldMentionSweetness
      shouldMentionColor
    }
  }
}
    `;

/**
 * __useFrontWineListQuery__
 *
 * To run a query within a React component, call `useFrontWineListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontWineListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontWineListQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      locale: // value for 'locale'
 *      omitWineId: // value for 'omitWineId'
 *   },
 * });
 */
export function useFrontWineListQuery(baseOptions: Apollo.QueryHookOptions<FrontWineListQuery, FrontWineListQueryVariables>) {
        return Apollo.useQuery<FrontWineListQuery, FrontWineListQueryVariables>(FrontWineListDocument, baseOptions);
      }
export function useFrontWineListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FrontWineListQuery, FrontWineListQueryVariables>) {
          return Apollo.useLazyQuery<FrontWineListQuery, FrontWineListQueryVariables>(FrontWineListDocument, baseOptions);
        }
export type FrontWineListQueryHookResult = ReturnType<typeof useFrontWineListQuery>;
export type FrontWineListLazyQueryHookResult = ReturnType<typeof useFrontWineListLazyQuery>;
export type FrontWineListQueryResult = Apollo.QueryResult<FrontWineListQuery, FrontWineListQueryVariables>;
export const ListActivitiesDocument = gql`
    query ListActivities($wineEntityId: ID, $omit: [ID], $locale: String) {
  ListActivities(wineEntityId: $wineEntityId, omit: $omit, locale: $locale) {
    activityShortId
    subscriptionName
    priceOnEstimation
    free
    activityId
    name
    types {
      bgColor
      textColor
      type
      typeFr
    }
    languages {
      language
      icon {
        data
        twicpicURL
      }
    }
    description
    groupSize
    image {
      data
      twicpicURL
      twicpicPath
    }
    accessibleTo
    startingAge
    tags
    activePeriods {
      startAt {
        day
        month
        year
        vaguePeriod
      }
      endAt {
        day
        month
        year
        vaguePeriod
      }
    }
    duration
    durationUnit
    reservationNeeded
    priceDetails {
      kind
      price
      currency
    }
    contactPerson {
      title
      type
      functions
      phoneNumber
      email
      personId
      image {
        data
      }
    }
    contactPhoneNumber
    contactEmail
    minRentTime
    minRentTimeUnit
    maxRentTime
    maxRentTimeUnit
  }
}
    `;

/**
 * __useListActivitiesQuery__
 *
 * To run a query within a React component, call `useListActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListActivitiesQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      omit: // value for 'omit'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useListActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<ListActivitiesQuery, ListActivitiesQueryVariables>) {
        return Apollo.useQuery<ListActivitiesQuery, ListActivitiesQueryVariables>(ListActivitiesDocument, baseOptions);
      }
export function useListActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListActivitiesQuery, ListActivitiesQueryVariables>) {
          return Apollo.useLazyQuery<ListActivitiesQuery, ListActivitiesQueryVariables>(ListActivitiesDocument, baseOptions);
        }
export type ListActivitiesQueryHookResult = ReturnType<typeof useListActivitiesQuery>;
export type ListActivitiesLazyQueryHookResult = ReturnType<typeof useListActivitiesLazyQuery>;
export type ListActivitiesQueryResult = Apollo.QueryResult<ListActivitiesQuery, ListActivitiesQueryVariables>;
export const MainTitleDocument = gql`
    query MainTitle($wineEntityId: ID) {
  MainTitle(wineEntityId: $wineEntityId) {
    name
    mainAppellation
    classifications {
      name
    }
    estateSurfaceArea
  }
}
    `;

/**
 * __useMainTitleQuery__
 *
 * To run a query within a React component, call `useMainTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainTitleQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useMainTitleQuery(baseOptions?: Apollo.QueryHookOptions<MainTitleQuery, MainTitleQueryVariables>) {
        return Apollo.useQuery<MainTitleQuery, MainTitleQueryVariables>(MainTitleDocument, baseOptions);
      }
export function useMainTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MainTitleQuery, MainTitleQueryVariables>) {
          return Apollo.useLazyQuery<MainTitleQuery, MainTitleQueryVariables>(MainTitleDocument, baseOptions);
        }
export type MainTitleQueryHookResult = ReturnType<typeof useMainTitleQuery>;
export type MainTitleLazyQueryHookResult = ReturnType<typeof useMainTitleLazyQuery>;
export type MainTitleQueryResult = Apollo.QueryResult<MainTitleQuery, MainTitleQueryVariables>;
export const GetCommuneFrontDocument = gql`
    query GetCommuneFront($name: String!) {
  GetCommuneFront(name: $name) {
    communeId
    fullName
    rootName
    nameAttribute
    surfaceArea
    geoSituation
    soilDescription
    INSEEcode
    postalCode
    regionName
    departmentNumber
    population
    latitude
    longitude
    altitudeMax
    altitudeMin
    gentileM
    gentileF
    redWines
    roseWines
    whiteWines
    mairieAddress {
      address
      communeINSEEcode
      postalCode
      commune
    }
  }
}
    `;

/**
 * __useGetCommuneFrontQuery__
 *
 * To run a query within a React component, call `useGetCommuneFrontQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommuneFrontQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommuneFrontQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetCommuneFrontQuery(baseOptions: Apollo.QueryHookOptions<GetCommuneFrontQuery, GetCommuneFrontQueryVariables>) {
        return Apollo.useQuery<GetCommuneFrontQuery, GetCommuneFrontQueryVariables>(GetCommuneFrontDocument, baseOptions);
      }
export function useGetCommuneFrontLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommuneFrontQuery, GetCommuneFrontQueryVariables>) {
          return Apollo.useLazyQuery<GetCommuneFrontQuery, GetCommuneFrontQueryVariables>(GetCommuneFrontDocument, baseOptions);
        }
export type GetCommuneFrontQueryHookResult = ReturnType<typeof useGetCommuneFrontQuery>;
export type GetCommuneFrontLazyQueryHookResult = ReturnType<typeof useGetCommuneFrontLazyQuery>;
export type GetCommuneFrontQueryResult = Apollo.QueryResult<GetCommuneFrontQuery, GetCommuneFrontQueryVariables>;
export const ContactCardDocument = gql`
    query ContactCard($wineEntityId: ID) {
  ContactCard(wineEntityId: $wineEntityId) {
    estateName
    openingHours {
      name
      open
      openMorning
      openAfternoon
      closeMorning
      closeAfternoon
      nonStop
      val
    }
    displayOpeningHours
    fax
    logo {
      data
      twicpicURL
      twicpicPath
    }
    coordinates {
      latitude
      longitude
      preciseLocation
    }
    telephoneNumber
    address {
      address
      complementaryAddress
      commune
      postalCode
      communeINSEEcode
    }
  }
}
    `;

/**
 * __useContactCardQuery__
 *
 * To run a query within a React component, call `useContactCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactCardQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useContactCardQuery(baseOptions?: Apollo.QueryHookOptions<ContactCardQuery, ContactCardQueryVariables>) {
        return Apollo.useQuery<ContactCardQuery, ContactCardQueryVariables>(ContactCardDocument, baseOptions);
      }
export function useContactCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactCardQuery, ContactCardQueryVariables>) {
          return Apollo.useLazyQuery<ContactCardQuery, ContactCardQueryVariables>(ContactCardDocument, baseOptions);
        }
export type ContactCardQueryHookResult = ReturnType<typeof useContactCardQuery>;
export type ContactCardLazyQueryHookResult = ReturnType<typeof useContactCardLazyQuery>;
export type ContactCardQueryResult = Apollo.QueryResult<ContactCardQuery, ContactCardQueryVariables>;
export const GetRandomWineEntitiesDocument = gql`
    query GetRandomWineEntities {
  GetRandomWineEntities {
    wineEntityShortId
    name
    wineEntityId
    appellation
    hasRedWines
    hasRoseWines
    hasWhiteWines
    image {
      data
      twicpicURL
    }
  }
}
    `;

/**
 * __useGetRandomWineEntitiesQuery__
 *
 * To run a query within a React component, call `useGetRandomWineEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRandomWineEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRandomWineEntitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRandomWineEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetRandomWineEntitiesQuery, GetRandomWineEntitiesQueryVariables>) {
        return Apollo.useQuery<GetRandomWineEntitiesQuery, GetRandomWineEntitiesQueryVariables>(GetRandomWineEntitiesDocument, baseOptions);
      }
export function useGetRandomWineEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRandomWineEntitiesQuery, GetRandomWineEntitiesQueryVariables>) {
          return Apollo.useLazyQuery<GetRandomWineEntitiesQuery, GetRandomWineEntitiesQueryVariables>(GetRandomWineEntitiesDocument, baseOptions);
        }
export type GetRandomWineEntitiesQueryHookResult = ReturnType<typeof useGetRandomWineEntitiesQuery>;
export type GetRandomWineEntitiesLazyQueryHookResult = ReturnType<typeof useGetRandomWineEntitiesLazyQuery>;
export type GetRandomWineEntitiesQueryResult = Apollo.QueryResult<GetRandomWineEntitiesQuery, GetRandomWineEntitiesQueryVariables>;
export const GetRandomWineEntitiesFromCommuneDocument = gql`
    query GetRandomWineEntitiesFromCommune($communeINSEEcode: String!) {
  GetRandomWineEntitiesFromCommune(communeINSEEcode: $communeINSEEcode) {
    wineEntityShortId
    name
    wineEntityId
    appellation
    image {
      data
    }
  }
}
    `;

/**
 * __useGetRandomWineEntitiesFromCommuneQuery__
 *
 * To run a query within a React component, call `useGetRandomWineEntitiesFromCommuneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRandomWineEntitiesFromCommuneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRandomWineEntitiesFromCommuneQuery({
 *   variables: {
 *      communeINSEEcode: // value for 'communeINSEEcode'
 *   },
 * });
 */
export function useGetRandomWineEntitiesFromCommuneQuery(baseOptions: Apollo.QueryHookOptions<GetRandomWineEntitiesFromCommuneQuery, GetRandomWineEntitiesFromCommuneQueryVariables>) {
        return Apollo.useQuery<GetRandomWineEntitiesFromCommuneQuery, GetRandomWineEntitiesFromCommuneQueryVariables>(GetRandomWineEntitiesFromCommuneDocument, baseOptions);
      }
export function useGetRandomWineEntitiesFromCommuneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRandomWineEntitiesFromCommuneQuery, GetRandomWineEntitiesFromCommuneQueryVariables>) {
          return Apollo.useLazyQuery<GetRandomWineEntitiesFromCommuneQuery, GetRandomWineEntitiesFromCommuneQueryVariables>(GetRandomWineEntitiesFromCommuneDocument, baseOptions);
        }
export type GetRandomWineEntitiesFromCommuneQueryHookResult = ReturnType<typeof useGetRandomWineEntitiesFromCommuneQuery>;
export type GetRandomWineEntitiesFromCommuneLazyQueryHookResult = ReturnType<typeof useGetRandomWineEntitiesFromCommuneLazyQuery>;
export type GetRandomWineEntitiesFromCommuneQueryResult = Apollo.QueryResult<GetRandomWineEntitiesFromCommuneQuery, GetRandomWineEntitiesFromCommuneQueryVariables>;
export const AboutVineyardDocument = gql`
    query AboutVineyard($wineEntityId: ID) {
  AboutVineyard(wineEntityId: $wineEntityId) {
    description
    soilDescription
    otherCultures
    totalSurfaceArea
    vineSurfaceArea
  }
}
    `;

/**
 * __useAboutVineyardQuery__
 *
 * To run a query within a React component, call `useAboutVineyardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutVineyardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutVineyardQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useAboutVineyardQuery(baseOptions?: Apollo.QueryHookOptions<AboutVineyardQuery, AboutVineyardQueryVariables>) {
        return Apollo.useQuery<AboutVineyardQuery, AboutVineyardQueryVariables>(AboutVineyardDocument, baseOptions);
      }
export function useAboutVineyardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AboutVineyardQuery, AboutVineyardQueryVariables>) {
          return Apollo.useLazyQuery<AboutVineyardQuery, AboutVineyardQueryVariables>(AboutVineyardDocument, baseOptions);
        }
export type AboutVineyardQueryHookResult = ReturnType<typeof useAboutVineyardQuery>;
export type AboutVineyardLazyQueryHookResult = ReturnType<typeof useAboutVineyardLazyQuery>;
export type AboutVineyardQueryResult = Apollo.QueryResult<AboutVineyardQuery, AboutVineyardQueryVariables>;
export const AboutContactDetailsDocument = gql`
    query AboutContactDetails($wineEntityId: ID) {
  AboutContactDetails(wineEntityId: $wineEntityId) {
    phone
    fax
    website
  }
}
    `;

/**
 * __useAboutContactDetailsQuery__
 *
 * To run a query within a React component, call `useAboutContactDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutContactDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutContactDetailsQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useAboutContactDetailsQuery(baseOptions?: Apollo.QueryHookOptions<AboutContactDetailsQuery, AboutContactDetailsQueryVariables>) {
        return Apollo.useQuery<AboutContactDetailsQuery, AboutContactDetailsQueryVariables>(AboutContactDetailsDocument, baseOptions);
      }
export function useAboutContactDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AboutContactDetailsQuery, AboutContactDetailsQueryVariables>) {
          return Apollo.useLazyQuery<AboutContactDetailsQuery, AboutContactDetailsQueryVariables>(AboutContactDetailsDocument, baseOptions);
        }
export type AboutContactDetailsQueryHookResult = ReturnType<typeof useAboutContactDetailsQuery>;
export type AboutContactDetailsLazyQueryHookResult = ReturnType<typeof useAboutContactDetailsLazyQuery>;
export type AboutContactDetailsQueryResult = Apollo.QueryResult<AboutContactDetailsQuery, AboutContactDetailsQueryVariables>;
export const AboutCellarsDocument = gql`
    query AboutCellars($wineEntityId: ID) {
  AboutCellars(wineEntityId: $wineEntityId) {
    cellars {
      name
      isVathouse
      isConditioning
      isStorage
      isAgeing
      surfaceArea
      wineEntityAddress {
        address
        complementaryAddress
        postalCode
        communeINSEEcode
        commune
      }
    }
  }
}
    `;

/**
 * __useAboutCellarsQuery__
 *
 * To run a query within a React component, call `useAboutCellarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutCellarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutCellarsQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useAboutCellarsQuery(baseOptions?: Apollo.QueryHookOptions<AboutCellarsQuery, AboutCellarsQueryVariables>) {
        return Apollo.useQuery<AboutCellarsQuery, AboutCellarsQueryVariables>(AboutCellarsDocument, baseOptions);
      }
export function useAboutCellarsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AboutCellarsQuery, AboutCellarsQueryVariables>) {
          return Apollo.useLazyQuery<AboutCellarsQuery, AboutCellarsQueryVariables>(AboutCellarsDocument, baseOptions);
        }
export type AboutCellarsQueryHookResult = ReturnType<typeof useAboutCellarsQuery>;
export type AboutCellarsLazyQueryHookResult = ReturnType<typeof useAboutCellarsLazyQuery>;
export type AboutCellarsQueryResult = Apollo.QueryResult<AboutCellarsQuery, AboutCellarsQueryVariables>;
export const GetCommunesOfAppellationDocument = gql`
    query GetCommunesOfAppellation($appellationLabel: String!, $filterByRegion: String!) {
  GetCommunesOfAppellation(
    appellationLabel: $appellationLabel
    filterByRegion: $filterByRegion
  ) {
    name
    INSEEcode
    postalCode
  }
}
    `;

/**
 * __useGetCommunesOfAppellationQuery__
 *
 * To run a query within a React component, call `useGetCommunesOfAppellationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunesOfAppellationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunesOfAppellationQuery({
 *   variables: {
 *      appellationLabel: // value for 'appellationLabel'
 *      filterByRegion: // value for 'filterByRegion'
 *   },
 * });
 */
export function useGetCommunesOfAppellationQuery(baseOptions: Apollo.QueryHookOptions<GetCommunesOfAppellationQuery, GetCommunesOfAppellationQueryVariables>) {
        return Apollo.useQuery<GetCommunesOfAppellationQuery, GetCommunesOfAppellationQueryVariables>(GetCommunesOfAppellationDocument, baseOptions);
      }
export function useGetCommunesOfAppellationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunesOfAppellationQuery, GetCommunesOfAppellationQueryVariables>) {
          return Apollo.useLazyQuery<GetCommunesOfAppellationQuery, GetCommunesOfAppellationQueryVariables>(GetCommunesOfAppellationDocument, baseOptions);
        }
export type GetCommunesOfAppellationQueryHookResult = ReturnType<typeof useGetCommunesOfAppellationQuery>;
export type GetCommunesOfAppellationLazyQueryHookResult = ReturnType<typeof useGetCommunesOfAppellationLazyQuery>;
export type GetCommunesOfAppellationQueryResult = Apollo.QueryResult<GetCommunesOfAppellationQuery, GetCommunesOfAppellationQueryVariables>;
export const GetOrphanWineEntitiesDocument = gql`
    query GetOrphanWineEntities($searchString: String) {
  GetOrphanWineEntities(searchString: $searchString) {
    wineEntityId
    estateName
    mainAppellation
    mainCommune
    communeINSEEcode
    businessAddress {
      address
      postalCode
      commune
    }
  }
}
    `;

/**
 * __useGetOrphanWineEntitiesQuery__
 *
 * To run a query within a React component, call `useGetOrphanWineEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrphanWineEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrphanWineEntitiesQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useGetOrphanWineEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetOrphanWineEntitiesQuery, GetOrphanWineEntitiesQueryVariables>) {
        return Apollo.useQuery<GetOrphanWineEntitiesQuery, GetOrphanWineEntitiesQueryVariables>(GetOrphanWineEntitiesDocument, baseOptions);
      }
export function useGetOrphanWineEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrphanWineEntitiesQuery, GetOrphanWineEntitiesQueryVariables>) {
          return Apollo.useLazyQuery<GetOrphanWineEntitiesQuery, GetOrphanWineEntitiesQueryVariables>(GetOrphanWineEntitiesDocument, baseOptions);
        }
export type GetOrphanWineEntitiesQueryHookResult = ReturnType<typeof useGetOrphanWineEntitiesQuery>;
export type GetOrphanWineEntitiesLazyQueryHookResult = ReturnType<typeof useGetOrphanWineEntitiesLazyQuery>;
export type GetOrphanWineEntitiesQueryResult = Apollo.QueryResult<GetOrphanWineEntitiesQuery, GetOrphanWineEntitiesQueryVariables>;
export const GetBesvInfoDocument = gql`
    query GetBesvInfo($wineEntityId: ID!) {
  GetBesvInfo(wineEntityId: $wineEntityId) {
    scans {
      data
    }
    edition
    page
    year
  }
}
    `;

/**
 * __useGetBesvInfoQuery__
 *
 * To run a query within a React component, call `useGetBesvInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBesvInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBesvInfoQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetBesvInfoQuery(baseOptions: Apollo.QueryHookOptions<GetBesvInfoQuery, GetBesvInfoQueryVariables>) {
        return Apollo.useQuery<GetBesvInfoQuery, GetBesvInfoQueryVariables>(GetBesvInfoDocument, baseOptions);
      }
export function useGetBesvInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBesvInfoQuery, GetBesvInfoQueryVariables>) {
          return Apollo.useLazyQuery<GetBesvInfoQuery, GetBesvInfoQueryVariables>(GetBesvInfoDocument, baseOptions);
        }
export type GetBesvInfoQueryHookResult = ReturnType<typeof useGetBesvInfoQuery>;
export type GetBesvInfoLazyQueryHookResult = ReturnType<typeof useGetBesvInfoLazyQuery>;
export type GetBesvInfoQueryResult = Apollo.QueryResult<GetBesvInfoQuery, GetBesvInfoQueryVariables>;
export const GetOwnerOperatorDocument = gql`
    query GetOwnerOperator($wineEntityId: ID) {
  GetOwnerOperator(wineEntityId: $wineEntityId) {
    operators {
      personId
      kind
      title
      firstName
      lastName
      organizationName
      organizationStatus
      visibility
      hidden
      dateOfBirth
      email
      telephone
      vip
      wasVip
      proFunctions
      displayName
      roleComplement
    }
    owners {
      roleComplement
      personId
      kind
      title
      firstName
      lastName
      organizationName
      organizationStatus
      visibility
      hidden
      dateOfBirth
      email
      telephone
      vip
      wasVip
      proFunctions
      displayName
    }
    numMembers
  }
}
    `;

/**
 * __useGetOwnerOperatorQuery__
 *
 * To run a query within a React component, call `useGetOwnerOperatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerOperatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerOperatorQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetOwnerOperatorQuery(baseOptions?: Apollo.QueryHookOptions<GetOwnerOperatorQuery, GetOwnerOperatorQueryVariables>) {
        return Apollo.useQuery<GetOwnerOperatorQuery, GetOwnerOperatorQueryVariables>(GetOwnerOperatorDocument, baseOptions);
      }
export function useGetOwnerOperatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOwnerOperatorQuery, GetOwnerOperatorQueryVariables>) {
          return Apollo.useLazyQuery<GetOwnerOperatorQuery, GetOwnerOperatorQueryVariables>(GetOwnerOperatorDocument, baseOptions);
        }
export type GetOwnerOperatorQueryHookResult = ReturnType<typeof useGetOwnerOperatorQuery>;
export type GetOwnerOperatorLazyQueryHookResult = ReturnType<typeof useGetOwnerOperatorLazyQuery>;
export type GetOwnerOperatorQueryResult = Apollo.QueryResult<GetOwnerOperatorQuery, GetOwnerOperatorQueryVariables>;
export const GetFtParametersDocument = gql`
    query GetFtParameters($wineEntityId: ID!) {
  GetFtParameters(wineEntityId: $wineEntityId) {
    bottleImage {
      data
    }
    preview {
      data
    }
    ftId
    wineEntityId
    wineId
    vintageId
    name
    description
    wine
    vintage
    version
    status
    lastModification
    online
    hasDiffusion
    languages {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    configuration {
      pages
      components
    }
    cuvee
    fullAppellation
  }
}
    `;

/**
 * __useGetFtParametersQuery__
 *
 * To run a query within a React component, call `useGetFtParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFtParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFtParametersQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetFtParametersQuery(baseOptions: Apollo.QueryHookOptions<GetFtParametersQuery, GetFtParametersQueryVariables>) {
        return Apollo.useQuery<GetFtParametersQuery, GetFtParametersQueryVariables>(GetFtParametersDocument, baseOptions);
      }
export function useGetFtParametersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFtParametersQuery, GetFtParametersQueryVariables>) {
          return Apollo.useLazyQuery<GetFtParametersQuery, GetFtParametersQueryVariables>(GetFtParametersDocument, baseOptions);
        }
export type GetFtParametersQueryHookResult = ReturnType<typeof useGetFtParametersQuery>;
export type GetFtParametersLazyQueryHookResult = ReturnType<typeof useGetFtParametersLazyQuery>;
export type GetFtParametersQueryResult = Apollo.QueryResult<GetFtParametersQuery, GetFtParametersQueryVariables>;
export const GetFtParameterDocument = gql`
    query GetFtParameter($ftParametersId: ID!) {
  GetFtParameter(ftParametersId: $ftParametersId) {
    bottleImage {
      data
    }
    preview {
      data
    }
    ftId
    wineEntityId
    wineId
    vintageId
    name
    description
    wine
    vintage
    version
    status
    lastModification
    online
    hasDiffusion
    cuvee
    fullAppellation
    wineEntityName
    languages {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
  }
}
    `;

/**
 * __useGetFtParameterQuery__
 *
 * To run a query within a React component, call `useGetFtParameterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFtParameterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFtParameterQuery({
 *   variables: {
 *      ftParametersId: // value for 'ftParametersId'
 *   },
 * });
 */
export function useGetFtParameterQuery(baseOptions: Apollo.QueryHookOptions<GetFtParameterQuery, GetFtParameterQueryVariables>) {
        return Apollo.useQuery<GetFtParameterQuery, GetFtParameterQueryVariables>(GetFtParameterDocument, baseOptions);
      }
export function useGetFtParameterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFtParameterQuery, GetFtParameterQueryVariables>) {
          return Apollo.useLazyQuery<GetFtParameterQuery, GetFtParameterQueryVariables>(GetFtParameterDocument, baseOptions);
        }
export type GetFtParameterQueryHookResult = ReturnType<typeof useGetFtParameterQuery>;
export type GetFtParameterLazyQueryHookResult = ReturnType<typeof useGetFtParameterLazyQuery>;
export type GetFtParameterQueryResult = Apollo.QueryResult<GetFtParameterQuery, GetFtParameterQueryVariables>;
export const GetClassificationOrganismFrontDocument = gql`
    query GetClassificationOrganismFront($name: String!, $locale: String!) {
  GetClassificationOrganismFront(name: $name, locale: $locale) {
    _id
    name
    lastClassification
    nextClassification
    organisation
    classificationLink
    history
    about
    classifiedWines {
      name
      rank
      color
      type
      sweetness
      appellation
    }
    socialMedias {
      platform
      url
    }
    organism {
      address {
        address
        postalCode
        commune
        country
      }
      telephone
      email
    }
    president {
      _id
      title
      firstName
      lastName
      telephone
      email
    }
    director {
      _id
      title
      firstName
      lastName
      telephone
      email
    }
    logo {
      fileName
      data
      twicpicURL
    }
  }
}
    `;

/**
 * __useGetClassificationOrganismFrontQuery__
 *
 * To run a query within a React component, call `useGetClassificationOrganismFrontQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassificationOrganismFrontQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassificationOrganismFrontQuery({
 *   variables: {
 *      name: // value for 'name'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetClassificationOrganismFrontQuery(baseOptions: Apollo.QueryHookOptions<GetClassificationOrganismFrontQuery, GetClassificationOrganismFrontQueryVariables>) {
        return Apollo.useQuery<GetClassificationOrganismFrontQuery, GetClassificationOrganismFrontQueryVariables>(GetClassificationOrganismFrontDocument, baseOptions);
      }
export function useGetClassificationOrganismFrontLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassificationOrganismFrontQuery, GetClassificationOrganismFrontQueryVariables>) {
          return Apollo.useLazyQuery<GetClassificationOrganismFrontQuery, GetClassificationOrganismFrontQueryVariables>(GetClassificationOrganismFrontDocument, baseOptions);
        }
export type GetClassificationOrganismFrontQueryHookResult = ReturnType<typeof useGetClassificationOrganismFrontQuery>;
export type GetClassificationOrganismFrontLazyQueryHookResult = ReturnType<typeof useGetClassificationOrganismFrontLazyQuery>;
export type GetClassificationOrganismFrontQueryResult = Apollo.QueryResult<GetClassificationOrganismFrontQuery, GetClassificationOrganismFrontQueryVariables>;
export const GetClassificationOrganismListDocument = gql`
    query GetClassificationOrganismList($name: String) {
  GetClassificationOrganismList(name: $name) {
    _id
    name
    classifiedWinesCount
    logo {
      fileName
      data
      twicpicURL
    }
  }
}
    `;

/**
 * __useGetClassificationOrganismListQuery__
 *
 * To run a query within a React component, call `useGetClassificationOrganismListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassificationOrganismListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassificationOrganismListQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetClassificationOrganismListQuery(baseOptions?: Apollo.QueryHookOptions<GetClassificationOrganismListQuery, GetClassificationOrganismListQueryVariables>) {
        return Apollo.useQuery<GetClassificationOrganismListQuery, GetClassificationOrganismListQueryVariables>(GetClassificationOrganismListDocument, baseOptions);
      }
export function useGetClassificationOrganismListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassificationOrganismListQuery, GetClassificationOrganismListQueryVariables>) {
          return Apollo.useLazyQuery<GetClassificationOrganismListQuery, GetClassificationOrganismListQueryVariables>(GetClassificationOrganismListDocument, baseOptions);
        }
export type GetClassificationOrganismListQueryHookResult = ReturnType<typeof useGetClassificationOrganismListQuery>;
export type GetClassificationOrganismListLazyQueryHookResult = ReturnType<typeof useGetClassificationOrganismListLazyQuery>;
export type GetClassificationOrganismListQueryResult = Apollo.QueryResult<GetClassificationOrganismListQuery, GetClassificationOrganismListQueryVariables>;
export const GetOdgFrontDocument = gql`
    query GetOdgFront($name: String, $locale: String) {
  GetOdgFront(name: $name, locale: $locale) {
    name
    description
    appellations {
      label
      link
    }
    members {
      title
      firstName
      lastName
      displayName
      email
      role
      telephone
      address {
        address
        postalCode
        commune
        country
      }
    }
    socialMedias {
      platform
      url
      icon {
        data
      }
    }
  }
}
    `;

/**
 * __useGetOdgFrontQuery__
 *
 * To run a query within a React component, call `useGetOdgFrontQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOdgFrontQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOdgFrontQuery({
 *   variables: {
 *      name: // value for 'name'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetOdgFrontQuery(baseOptions?: Apollo.QueryHookOptions<GetOdgFrontQuery, GetOdgFrontQueryVariables>) {
        return Apollo.useQuery<GetOdgFrontQuery, GetOdgFrontQueryVariables>(GetOdgFrontDocument, baseOptions);
      }
export function useGetOdgFrontLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOdgFrontQuery, GetOdgFrontQueryVariables>) {
          return Apollo.useLazyQuery<GetOdgFrontQuery, GetOdgFrontQueryVariables>(GetOdgFrontDocument, baseOptions);
        }
export type GetOdgFrontQueryHookResult = ReturnType<typeof useGetOdgFrontQuery>;
export type GetOdgFrontLazyQueryHookResult = ReturnType<typeof useGetOdgFrontLazyQuery>;
export type GetOdgFrontQueryResult = Apollo.QueryResult<GetOdgFrontQuery, GetOdgFrontQueryVariables>;
export const GetOdgShortDocument = gql`
    query GetOdgShort {
  GetOdg {
    _id
    name
  }
}
    `;

/**
 * __useGetOdgShortQuery__
 *
 * To run a query within a React component, call `useGetOdgShortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOdgShortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOdgShortQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOdgShortQuery(baseOptions?: Apollo.QueryHookOptions<GetOdgShortQuery, GetOdgShortQueryVariables>) {
        return Apollo.useQuery<GetOdgShortQuery, GetOdgShortQueryVariables>(GetOdgShortDocument, baseOptions);
      }
export function useGetOdgShortLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOdgShortQuery, GetOdgShortQueryVariables>) {
          return Apollo.useLazyQuery<GetOdgShortQuery, GetOdgShortQueryVariables>(GetOdgShortDocument, baseOptions);
        }
export type GetOdgShortQueryHookResult = ReturnType<typeof useGetOdgShortQuery>;
export type GetOdgShortLazyQueryHookResult = ReturnType<typeof useGetOdgShortLazyQuery>;
export type GetOdgShortQueryResult = Apollo.QueryResult<GetOdgShortQuery, GetOdgShortQueryVariables>;
export const GetOdgSampleDocument = gql`
    query GetOdgSample($name: String) {
  GetOdgSample(name: $name) {
    name
  }
}
    `;

/**
 * __useGetOdgSampleQuery__
 *
 * To run a query within a React component, call `useGetOdgSampleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOdgSampleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOdgSampleQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetOdgSampleQuery(baseOptions?: Apollo.QueryHookOptions<GetOdgSampleQuery, GetOdgSampleQueryVariables>) {
        return Apollo.useQuery<GetOdgSampleQuery, GetOdgSampleQueryVariables>(GetOdgSampleDocument, baseOptions);
      }
export function useGetOdgSampleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOdgSampleQuery, GetOdgSampleQueryVariables>) {
          return Apollo.useLazyQuery<GetOdgSampleQuery, GetOdgSampleQueryVariables>(GetOdgSampleDocument, baseOptions);
        }
export type GetOdgSampleQueryHookResult = ReturnType<typeof useGetOdgSampleQuery>;
export type GetOdgSampleLazyQueryHookResult = ReturnType<typeof useGetOdgSampleLazyQuery>;
export type GetOdgSampleQueryResult = Apollo.QueryResult<GetOdgSampleQuery, GetOdgSampleQueryVariables>;
export const GetAuthorFrontDocument = gql`
    query GetAuthorFront($name: String, $locale: String) {
  GetAuthorFront(name: $name, locale: $locale) {
    _id
    degree
    professionalExperience
    expertise
    expertiseDetails
    personIdentity {
      _id
      title
      firstName
      lastName
    }
    title
    description
    photo {
      data
      twicpicURL
    }
    recentPublications {
      _id
      year
      publication
    }
  }
}
    `;

/**
 * __useGetAuthorFrontQuery__
 *
 * To run a query within a React component, call `useGetAuthorFrontQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthorFrontQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthorFrontQuery({
 *   variables: {
 *      name: // value for 'name'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetAuthorFrontQuery(baseOptions?: Apollo.QueryHookOptions<GetAuthorFrontQuery, GetAuthorFrontQueryVariables>) {
        return Apollo.useQuery<GetAuthorFrontQuery, GetAuthorFrontQueryVariables>(GetAuthorFrontDocument, baseOptions);
      }
export function useGetAuthorFrontLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuthorFrontQuery, GetAuthorFrontQueryVariables>) {
          return Apollo.useLazyQuery<GetAuthorFrontQuery, GetAuthorFrontQueryVariables>(GetAuthorFrontDocument, baseOptions);
        }
export type GetAuthorFrontQueryHookResult = ReturnType<typeof useGetAuthorFrontQuery>;
export type GetAuthorFrontLazyQueryHookResult = ReturnType<typeof useGetAuthorFrontLazyQuery>;
export type GetAuthorFrontQueryResult = Apollo.QueryResult<GetAuthorFrontQuery, GetAuthorFrontQueryVariables>;
export const GetAuthorFrontListDocument = gql`
    query GetAuthorFrontList($locale: String, $name: String) {
  GetAuthorFrontList(locale: $locale, name: $name) {
    personIdentity {
      title
      firstName
      lastName
    }
    photo {
      data
      twicpicURL
    }
  }
}
    `;

/**
 * __useGetAuthorFrontListQuery__
 *
 * To run a query within a React component, call `useGetAuthorFrontListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthorFrontListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthorFrontListQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetAuthorFrontListQuery(baseOptions?: Apollo.QueryHookOptions<GetAuthorFrontListQuery, GetAuthorFrontListQueryVariables>) {
        return Apollo.useQuery<GetAuthorFrontListQuery, GetAuthorFrontListQueryVariables>(GetAuthorFrontListDocument, baseOptions);
      }
export function useGetAuthorFrontListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuthorFrontListQuery, GetAuthorFrontListQueryVariables>) {
          return Apollo.useLazyQuery<GetAuthorFrontListQuery, GetAuthorFrontListQueryVariables>(GetAuthorFrontListDocument, baseOptions);
        }
export type GetAuthorFrontListQueryHookResult = ReturnType<typeof useGetAuthorFrontListQuery>;
export type GetAuthorFrontListLazyQueryHookResult = ReturnType<typeof useGetAuthorFrontListLazyQuery>;
export type GetAuthorFrontListQueryResult = Apollo.QueryResult<GetAuthorFrontListQuery, GetAuthorFrontListQueryVariables>;
export const GetFeretContactsDocument = gql`
    query GetFeretContacts($page: Int, $pageSize: Int, $search: String, $sortBy: SortType, $categories: [String], $wineEntityId: ID!) {
  GetFeretContacts(
    page: $page
    search: $search
    sortBy: $sortBy
    pageSize: $pageSize
    categories: $categories
    wineEntityId: $wineEntityId
  ) {
    items {
      _id
      title
      email
      userId
      company
      category
      lastName
      firstName
      telephone
      isDeleted
      description
      isEmailValid
      hasDiffusion
      lastDiffusion
      images {
        data
      }
      diffusionLists {
        _id
        name
        description
      }
    }
    count
  }
}
    `;

/**
 * __useGetFeretContactsQuery__
 *
 * To run a query within a React component, call `useGetFeretContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeretContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeretContactsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *      sortBy: // value for 'sortBy'
 *      categories: // value for 'categories'
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetFeretContactsQuery(baseOptions: Apollo.QueryHookOptions<GetFeretContactsQuery, GetFeretContactsQueryVariables>) {
        return Apollo.useQuery<GetFeretContactsQuery, GetFeretContactsQueryVariables>(GetFeretContactsDocument, baseOptions);
      }
export function useGetFeretContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeretContactsQuery, GetFeretContactsQueryVariables>) {
          return Apollo.useLazyQuery<GetFeretContactsQuery, GetFeretContactsQueryVariables>(GetFeretContactsDocument, baseOptions);
        }
export type GetFeretContactsQueryHookResult = ReturnType<typeof useGetFeretContactsQuery>;
export type GetFeretContactsLazyQueryHookResult = ReturnType<typeof useGetFeretContactsLazyQuery>;
export type GetFeretContactsQueryResult = Apollo.QueryResult<GetFeretContactsQuery, GetFeretContactsQueryVariables>;
export const GetFeretContactEmailsDocument = gql`
    query GetFeretContactEmails($wineEntityId: ID!) {
  GetFeretContactEmails(wineEntityId: $wineEntityId)
}
    `;

/**
 * __useGetFeretContactEmailsQuery__
 *
 * To run a query within a React component, call `useGetFeretContactEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeretContactEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeretContactEmailsQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetFeretContactEmailsQuery(baseOptions: Apollo.QueryHookOptions<GetFeretContactEmailsQuery, GetFeretContactEmailsQueryVariables>) {
        return Apollo.useQuery<GetFeretContactEmailsQuery, GetFeretContactEmailsQueryVariables>(GetFeretContactEmailsDocument, baseOptions);
      }
export function useGetFeretContactEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeretContactEmailsQuery, GetFeretContactEmailsQueryVariables>) {
          return Apollo.useLazyQuery<GetFeretContactEmailsQuery, GetFeretContactEmailsQueryVariables>(GetFeretContactEmailsDocument, baseOptions);
        }
export type GetFeretContactEmailsQueryHookResult = ReturnType<typeof useGetFeretContactEmailsQuery>;
export type GetFeretContactEmailsLazyQueryHookResult = ReturnType<typeof useGetFeretContactEmailsLazyQuery>;
export type GetFeretContactEmailsQueryResult = Apollo.QueryResult<GetFeretContactEmailsQuery, GetFeretContactEmailsQueryVariables>;
export const GetFeretContactStatsDocument = gql`
    query GetFeretContactStats($wineEntityId: ID!) {
  GetFeretContactStats(wineEntityId: $wineEntityId) {
    contactCount
    contactListCount
  }
}
    `;

/**
 * __useGetFeretContactStatsQuery__
 *
 * To run a query within a React component, call `useGetFeretContactStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeretContactStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeretContactStatsQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetFeretContactStatsQuery(baseOptions: Apollo.QueryHookOptions<GetFeretContactStatsQuery, GetFeretContactStatsQueryVariables>) {
        return Apollo.useQuery<GetFeretContactStatsQuery, GetFeretContactStatsQueryVariables>(GetFeretContactStatsDocument, baseOptions);
      }
export function useGetFeretContactStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeretContactStatsQuery, GetFeretContactStatsQueryVariables>) {
          return Apollo.useLazyQuery<GetFeretContactStatsQuery, GetFeretContactStatsQueryVariables>(GetFeretContactStatsDocument, baseOptions);
        }
export type GetFeretContactStatsQueryHookResult = ReturnType<typeof useGetFeretContactStatsQuery>;
export type GetFeretContactStatsLazyQueryHookResult = ReturnType<typeof useGetFeretContactStatsLazyQuery>;
export type GetFeretContactStatsQueryResult = Apollo.QueryResult<GetFeretContactStatsQuery, GetFeretContactStatsQueryVariables>;
export const GetFeretContactDocument = gql`
    query GetFeretContact($contactId: ID) {
  GetFeretContact(contactId: $contactId) {
    _id
    title
    firstName
    lastName
    email
    company
    category
    lastDiffusion
    telephone
    description
    isEmailValid
    isDeleted
    userId
    hasDiffusion
    images {
      data
      fileName
    }
    diffusionLists {
      _id
      name
      description
    }
    ftDiffusions {
      _id
      isOpen
      createdAt
      name
      wineName
      estateName
      vintage
      mainAppellation
      ftId
      lastUpdate
      isEmailValid
      bottleImage {
        data
      }
      languages {
        fr
        en
        es
        de
        nl
        it
      }
      ftDownload {
        fr {
          count
          lastDownload
        }
        en {
          count
          lastDownload
        }
        es {
          count
          lastDownload
        }
        de {
          count
          lastDownload
        }
        nl {
          count
          lastDownload
        }
        it {
          count
          lastDownload
        }
      }
    }
  }
}
    `;

/**
 * __useGetFeretContactQuery__
 *
 * To run a query within a React component, call `useGetFeretContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeretContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeretContactQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useGetFeretContactQuery(baseOptions?: Apollo.QueryHookOptions<GetFeretContactQuery, GetFeretContactQueryVariables>) {
        return Apollo.useQuery<GetFeretContactQuery, GetFeretContactQueryVariables>(GetFeretContactDocument, baseOptions);
      }
export function useGetFeretContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeretContactQuery, GetFeretContactQueryVariables>) {
          return Apollo.useLazyQuery<GetFeretContactQuery, GetFeretContactQueryVariables>(GetFeretContactDocument, baseOptions);
        }
export type GetFeretContactQueryHookResult = ReturnType<typeof useGetFeretContactQuery>;
export type GetFeretContactLazyQueryHookResult = ReturnType<typeof useGetFeretContactLazyQuery>;
export type GetFeretContactQueryResult = Apollo.QueryResult<GetFeretContactQuery, GetFeretContactQueryVariables>;
export const GetContactsFromCsvDocument = gql`
    query GetContactsFromCSV($csvFile: DocumentInput!, $wineEntityId: ID!) {
  GetContactsFromCSV(csvFile: $csvFile, wineEntityId: $wineEntityId) {
    contactNumber
    contacts {
      title
      firstName
      lastName
      email
      company
      category
      list
      invalidCategory
      isValidEmail
      isDuplicate
    }
  }
}
    `;

/**
 * __useGetContactsFromCsvQuery__
 *
 * To run a query within a React component, call `useGetContactsFromCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsFromCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsFromCsvQuery({
 *   variables: {
 *      csvFile: // value for 'csvFile'
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetContactsFromCsvQuery(baseOptions: Apollo.QueryHookOptions<GetContactsFromCsvQuery, GetContactsFromCsvQueryVariables>) {
        return Apollo.useQuery<GetContactsFromCsvQuery, GetContactsFromCsvQueryVariables>(GetContactsFromCsvDocument, baseOptions);
      }
export function useGetContactsFromCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactsFromCsvQuery, GetContactsFromCsvQueryVariables>) {
          return Apollo.useLazyQuery<GetContactsFromCsvQuery, GetContactsFromCsvQueryVariables>(GetContactsFromCsvDocument, baseOptions);
        }
export type GetContactsFromCsvQueryHookResult = ReturnType<typeof useGetContactsFromCsvQuery>;
export type GetContactsFromCsvLazyQueryHookResult = ReturnType<typeof useGetContactsFromCsvLazyQuery>;
export type GetContactsFromCsvQueryResult = Apollo.QueryResult<GetContactsFromCsvQuery, GetContactsFromCsvQueryVariables>;
export const GetDiffusionListsSimpleDocument = gql`
    query GetDiffusionListsSimple($wineEntityId: ID!) {
  GetDiffusionLists(wineEntityId: $wineEntityId) {
    _id
    name
    description
    contactCount
  }
}
    `;

/**
 * __useGetDiffusionListsSimpleQuery__
 *
 * To run a query within a React component, call `useGetDiffusionListsSimpleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiffusionListsSimpleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiffusionListsSimpleQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetDiffusionListsSimpleQuery(baseOptions: Apollo.QueryHookOptions<GetDiffusionListsSimpleQuery, GetDiffusionListsSimpleQueryVariables>) {
        return Apollo.useQuery<GetDiffusionListsSimpleQuery, GetDiffusionListsSimpleQueryVariables>(GetDiffusionListsSimpleDocument, baseOptions);
      }
export function useGetDiffusionListsSimpleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiffusionListsSimpleQuery, GetDiffusionListsSimpleQueryVariables>) {
          return Apollo.useLazyQuery<GetDiffusionListsSimpleQuery, GetDiffusionListsSimpleQueryVariables>(GetDiffusionListsSimpleDocument, baseOptions);
        }
export type GetDiffusionListsSimpleQueryHookResult = ReturnType<typeof useGetDiffusionListsSimpleQuery>;
export type GetDiffusionListsSimpleLazyQueryHookResult = ReturnType<typeof useGetDiffusionListsSimpleLazyQuery>;
export type GetDiffusionListsSimpleQueryResult = Apollo.QueryResult<GetDiffusionListsSimpleQuery, GetDiffusionListsSimpleQueryVariables>;
export const GetDiffusionListsDocument = gql`
    query GetDiffusionLists($wineEntityId: ID!) {
  GetDiffusionLists(wineEntityId: $wineEntityId) {
    _id
    name
    description
    contacts {
      _id
      title
      firstName
      lastName
      email
      company
      category
      createdAt
      isDeleted
      ftDiffusions {
        _id
        isOpen
        createdAt
        name
        wineName
        estateName
        vintage
        mainAppellation
        ftId
        isEmailValid
        diffusionListId
      }
    }
  }
}
    `;

/**
 * __useGetDiffusionListsQuery__
 *
 * To run a query within a React component, call `useGetDiffusionListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiffusionListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiffusionListsQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetDiffusionListsQuery(baseOptions: Apollo.QueryHookOptions<GetDiffusionListsQuery, GetDiffusionListsQueryVariables>) {
        return Apollo.useQuery<GetDiffusionListsQuery, GetDiffusionListsQueryVariables>(GetDiffusionListsDocument, baseOptions);
      }
export function useGetDiffusionListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiffusionListsQuery, GetDiffusionListsQueryVariables>) {
          return Apollo.useLazyQuery<GetDiffusionListsQuery, GetDiffusionListsQueryVariables>(GetDiffusionListsDocument, baseOptions);
        }
export type GetDiffusionListsQueryHookResult = ReturnType<typeof useGetDiffusionListsQuery>;
export type GetDiffusionListsLazyQueryHookResult = ReturnType<typeof useGetDiffusionListsLazyQuery>;
export type GetDiffusionListsQueryResult = Apollo.QueryResult<GetDiffusionListsQuery, GetDiffusionListsQueryVariables>;
export const GetDiffusionListsWithContactsDocument = gql`
    query GetDiffusionListsWithContacts($wineEntityId: ID!) {
  GetDiffusionLists(wineEntityId: $wineEntityId) {
    _id
    name
    description
    contacts {
      _id
      title
      firstName
      lastName
      email
      company
      category
      createdAt
      isDeleted
    }
  }
}
    `;

/**
 * __useGetDiffusionListsWithContactsQuery__
 *
 * To run a query within a React component, call `useGetDiffusionListsWithContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiffusionListsWithContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiffusionListsWithContactsQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetDiffusionListsWithContactsQuery(baseOptions: Apollo.QueryHookOptions<GetDiffusionListsWithContactsQuery, GetDiffusionListsWithContactsQueryVariables>) {
        return Apollo.useQuery<GetDiffusionListsWithContactsQuery, GetDiffusionListsWithContactsQueryVariables>(GetDiffusionListsWithContactsDocument, baseOptions);
      }
export function useGetDiffusionListsWithContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiffusionListsWithContactsQuery, GetDiffusionListsWithContactsQueryVariables>) {
          return Apollo.useLazyQuery<GetDiffusionListsWithContactsQuery, GetDiffusionListsWithContactsQueryVariables>(GetDiffusionListsWithContactsDocument, baseOptions);
        }
export type GetDiffusionListsWithContactsQueryHookResult = ReturnType<typeof useGetDiffusionListsWithContactsQuery>;
export type GetDiffusionListsWithContactsLazyQueryHookResult = ReturnType<typeof useGetDiffusionListsWithContactsLazyQuery>;
export type GetDiffusionListsWithContactsQueryResult = Apollo.QueryResult<GetDiffusionListsWithContactsQuery, GetDiffusionListsWithContactsQueryVariables>;
export const GetDiffusionListDocument = gql`
    query GetDiffusionList($diffusionListId: ID!) {
  GetDiffusionList(diffusionListId: $diffusionListId) {
    _id
    name
    description
    contacts {
      _id
      userId
      title
      firstName
      lastName
      email
      company
      category
      createdAt
      isDeleted
    }
  }
}
    `;

/**
 * __useGetDiffusionListQuery__
 *
 * To run a query within a React component, call `useGetDiffusionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiffusionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiffusionListQuery({
 *   variables: {
 *      diffusionListId: // value for 'diffusionListId'
 *   },
 * });
 */
export function useGetDiffusionListQuery(baseOptions: Apollo.QueryHookOptions<GetDiffusionListQuery, GetDiffusionListQueryVariables>) {
        return Apollo.useQuery<GetDiffusionListQuery, GetDiffusionListQueryVariables>(GetDiffusionListDocument, baseOptions);
      }
export function useGetDiffusionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiffusionListQuery, GetDiffusionListQueryVariables>) {
          return Apollo.useLazyQuery<GetDiffusionListQuery, GetDiffusionListQueryVariables>(GetDiffusionListDocument, baseOptions);
        }
export type GetDiffusionListQueryHookResult = ReturnType<typeof useGetDiffusionListQuery>;
export type GetDiffusionListLazyQueryHookResult = ReturnType<typeof useGetDiffusionListLazyQuery>;
export type GetDiffusionListQueryResult = Apollo.QueryResult<GetDiffusionListQuery, GetDiffusionListQueryVariables>;
export const GetStreamerFtDiffusionsDocument = gql`
    query GetStreamerFtDiffusions($wineEntityId: ID!) {
  GetStreamerFtDiffusions(wineEntityId: $wineEntityId) {
    _id
    isOpen
    createdAt
    name
    wineName
    estateName
    vintage
    mainAppellation
    ftId
    lastUpdate
    status
    isEmailValid
    contactCount
    webCount
    totalCount
    diffusionListNames
    languages {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    bottleImage {
      data
    }
  }
}
    `;

/**
 * __useGetStreamerFtDiffusionsQuery__
 *
 * To run a query within a React component, call `useGetStreamerFtDiffusionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamerFtDiffusionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamerFtDiffusionsQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetStreamerFtDiffusionsQuery(baseOptions: Apollo.QueryHookOptions<GetStreamerFtDiffusionsQuery, GetStreamerFtDiffusionsQueryVariables>) {
        return Apollo.useQuery<GetStreamerFtDiffusionsQuery, GetStreamerFtDiffusionsQueryVariables>(GetStreamerFtDiffusionsDocument, baseOptions);
      }
export function useGetStreamerFtDiffusionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStreamerFtDiffusionsQuery, GetStreamerFtDiffusionsQueryVariables>) {
          return Apollo.useLazyQuery<GetStreamerFtDiffusionsQuery, GetStreamerFtDiffusionsQueryVariables>(GetStreamerFtDiffusionsDocument, baseOptions);
        }
export type GetStreamerFtDiffusionsQueryHookResult = ReturnType<typeof useGetStreamerFtDiffusionsQuery>;
export type GetStreamerFtDiffusionsLazyQueryHookResult = ReturnType<typeof useGetStreamerFtDiffusionsLazyQuery>;
export type GetStreamerFtDiffusionsQueryResult = Apollo.QueryResult<GetStreamerFtDiffusionsQuery, GetStreamerFtDiffusionsQueryVariables>;
export const GetWatcherFtDiffusionsDocument = gql`
    query GetWatcherFtDiffusions($userId: ID!) {
  GetWatcherFtDiffusions(userId: $userId) {
    _id
    isOpen
    isCreated
    isUpdated
    createdAt
    name
    wineName
    wineEntityId
    wineEntityShortId
    estateName
    vintage
    mainAppellation
    ftId
    lastUpdate
    message
    languages {
      fr
      en
      de
      es
      it
      nl
      zh
      ja
    }
    preview {
      data
    }
    bottleImage {
      data
    }
  }
}
    `;

/**
 * __useGetWatcherFtDiffusionsQuery__
 *
 * To run a query within a React component, call `useGetWatcherFtDiffusionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWatcherFtDiffusionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWatcherFtDiffusionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetWatcherFtDiffusionsQuery(baseOptions: Apollo.QueryHookOptions<GetWatcherFtDiffusionsQuery, GetWatcherFtDiffusionsQueryVariables>) {
        return Apollo.useQuery<GetWatcherFtDiffusionsQuery, GetWatcherFtDiffusionsQueryVariables>(GetWatcherFtDiffusionsDocument, baseOptions);
      }
export function useGetWatcherFtDiffusionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWatcherFtDiffusionsQuery, GetWatcherFtDiffusionsQueryVariables>) {
          return Apollo.useLazyQuery<GetWatcherFtDiffusionsQuery, GetWatcherFtDiffusionsQueryVariables>(GetWatcherFtDiffusionsDocument, baseOptions);
        }
export type GetWatcherFtDiffusionsQueryHookResult = ReturnType<typeof useGetWatcherFtDiffusionsQuery>;
export type GetWatcherFtDiffusionsLazyQueryHookResult = ReturnType<typeof useGetWatcherFtDiffusionsLazyQuery>;
export type GetWatcherFtDiffusionsQueryResult = Apollo.QueryResult<GetWatcherFtDiffusionsQuery, GetWatcherFtDiffusionsQueryVariables>;
export const GetFtRequestByCodeDocument = gql`
    query GetFtRequestByCode($code: String!) {
  GetFtRequestByCode(code: $code) {
    _id
    estateName
    email
  }
}
    `;

/**
 * __useGetFtRequestByCodeQuery__
 *
 * To run a query within a React component, call `useGetFtRequestByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFtRequestByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFtRequestByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetFtRequestByCodeQuery(baseOptions: Apollo.QueryHookOptions<GetFtRequestByCodeQuery, GetFtRequestByCodeQueryVariables>) {
        return Apollo.useQuery<GetFtRequestByCodeQuery, GetFtRequestByCodeQueryVariables>(GetFtRequestByCodeDocument, baseOptions);
      }
export function useGetFtRequestByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFtRequestByCodeQuery, GetFtRequestByCodeQueryVariables>) {
          return Apollo.useLazyQuery<GetFtRequestByCodeQuery, GetFtRequestByCodeQueryVariables>(GetFtRequestByCodeDocument, baseOptions);
        }
export type GetFtRequestByCodeQueryHookResult = ReturnType<typeof useGetFtRequestByCodeQuery>;
export type GetFtRequestByCodeLazyQueryHookResult = ReturnType<typeof useGetFtRequestByCodeLazyQuery>;
export type GetFtRequestByCodeQueryResult = Apollo.QueryResult<GetFtRequestByCodeQuery, GetFtRequestByCodeQueryVariables>;
export const GetDiffusionParametersDocument = gql`
    query GetDiffusionParameters($wineEntityId: ID!) {
  GetDiffusionParameters(wineEntityId: $wineEntityId) {
    scoreNotify
    medalNotify
    certificationNotify
  }
}
    `;

/**
 * __useGetDiffusionParametersQuery__
 *
 * To run a query within a React component, call `useGetDiffusionParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiffusionParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiffusionParametersQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetDiffusionParametersQuery(baseOptions: Apollo.QueryHookOptions<GetDiffusionParametersQuery, GetDiffusionParametersQueryVariables>) {
        return Apollo.useQuery<GetDiffusionParametersQuery, GetDiffusionParametersQueryVariables>(GetDiffusionParametersDocument, baseOptions);
      }
export function useGetDiffusionParametersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiffusionParametersQuery, GetDiffusionParametersQueryVariables>) {
          return Apollo.useLazyQuery<GetDiffusionParametersQuery, GetDiffusionParametersQueryVariables>(GetDiffusionParametersDocument, baseOptions);
        }
export type GetDiffusionParametersQueryHookResult = ReturnType<typeof useGetDiffusionParametersQuery>;
export type GetDiffusionParametersLazyQueryHookResult = ReturnType<typeof useGetDiffusionParametersLazyQuery>;
export type GetDiffusionParametersQueryResult = Apollo.QueryResult<GetDiffusionParametersQuery, GetDiffusionParametersQueryVariables>;
export const GetFtDiffusionByContactDocument = gql`
    query GetFtDiffusionByContact($wineEntityId: ID!, $ftId: ID!) {
  GetFtDiffusionByContact(wineEntityId: $wineEntityId, ftId: $ftId) {
    name
    vintage
    wineName
    estateName
    mainAppellation
    lastModification
    preview {
      data
    }
    contacts {
      contact {
        _id
        title
        firstName
        lastName
        email
        lastDiffusion
        category
        company
        userId
        hasDiffusion
        diffusionLists {
          name
        }
        isEmailValid
        isDeleted
      }
      ftDiffusion {
        _id
        userId
        isOpen
        lastUpdate
        isEmailValid
      }
      ftRequest {
        _id
        lastUpdate
        isEmailValid
      }
      ftOpened {
        userId
        ftId
        version
      }
      ftDownload {
        fr {
          count
          lastDownload
        }
        en {
          count
          lastDownload
        }
        es {
          count
          lastDownload
        }
        de {
          count
          lastDownload
        }
        nl {
          count
          lastDownload
        }
        it {
          count
          lastDownload
        }
        ja {
          count
          lastDownload
        }
        zh {
          count
          lastDownload
        }
      }
    }
    contactMetrics {
      lists
      contactNumber
      opened
      error
      downloaded
      locales {
        fr
        en
        es
        it
        de
        nl
        ja
        zh
      }
    }
    allMetrics {
      lists
      contactNumber
      opened
      error
      downloaded
      locales {
        fr
        en
        es
        it
        de
        nl
        ja
        zh
      }
    }
    webMetrics {
      webNumber
      opened
      downloaded
      locales {
        fr
        en
        es
        it
        de
        nl
        ja
        zh
      }
    }
  }
}
    `;

/**
 * __useGetFtDiffusionByContactQuery__
 *
 * To run a query within a React component, call `useGetFtDiffusionByContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFtDiffusionByContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFtDiffusionByContactQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      ftId: // value for 'ftId'
 *   },
 * });
 */
export function useGetFtDiffusionByContactQuery(baseOptions: Apollo.QueryHookOptions<GetFtDiffusionByContactQuery, GetFtDiffusionByContactQueryVariables>) {
        return Apollo.useQuery<GetFtDiffusionByContactQuery, GetFtDiffusionByContactQueryVariables>(GetFtDiffusionByContactDocument, baseOptions);
      }
export function useGetFtDiffusionByContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFtDiffusionByContactQuery, GetFtDiffusionByContactQueryVariables>) {
          return Apollo.useLazyQuery<GetFtDiffusionByContactQuery, GetFtDiffusionByContactQueryVariables>(GetFtDiffusionByContactDocument, baseOptions);
        }
export type GetFtDiffusionByContactQueryHookResult = ReturnType<typeof useGetFtDiffusionByContactQuery>;
export type GetFtDiffusionByContactLazyQueryHookResult = ReturnType<typeof useGetFtDiffusionByContactLazyQuery>;
export type GetFtDiffusionByContactQueryResult = Apollo.QueryResult<GetFtDiffusionByContactQuery, GetFtDiffusionByContactQueryVariables>;
export const GetFtDiffusionByDiffusionListDocument = gql`
    query GetFtDiffusionByDiffusionList($wineEntityId: ID!, $ftId: ID!) {
  GetFtDiffusionByDiffusionList(wineEntityId: $wineEntityId, ftId: $ftId) {
    message
    name
    mainAppellation
    wineName
    estateName
    vintage
    preview {
      data
    }
    lastUpdate
    diffusionLists {
      _id
      name
      description
      contacts {
        _id
        firstName
        lastName
        email
        isDeleted
      }
    }
  }
}
    `;

/**
 * __useGetFtDiffusionByDiffusionListQuery__
 *
 * To run a query within a React component, call `useGetFtDiffusionByDiffusionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFtDiffusionByDiffusionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFtDiffusionByDiffusionListQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *      ftId: // value for 'ftId'
 *   },
 * });
 */
export function useGetFtDiffusionByDiffusionListQuery(baseOptions: Apollo.QueryHookOptions<GetFtDiffusionByDiffusionListQuery, GetFtDiffusionByDiffusionListQueryVariables>) {
        return Apollo.useQuery<GetFtDiffusionByDiffusionListQuery, GetFtDiffusionByDiffusionListQueryVariables>(GetFtDiffusionByDiffusionListDocument, baseOptions);
      }
export function useGetFtDiffusionByDiffusionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFtDiffusionByDiffusionListQuery, GetFtDiffusionByDiffusionListQueryVariables>) {
          return Apollo.useLazyQuery<GetFtDiffusionByDiffusionListQuery, GetFtDiffusionByDiffusionListQueryVariables>(GetFtDiffusionByDiffusionListDocument, baseOptions);
        }
export type GetFtDiffusionByDiffusionListQueryHookResult = ReturnType<typeof useGetFtDiffusionByDiffusionListQuery>;
export type GetFtDiffusionByDiffusionListLazyQueryHookResult = ReturnType<typeof useGetFtDiffusionByDiffusionListLazyQuery>;
export type GetFtDiffusionByDiffusionListQueryResult = Apollo.QueryResult<GetFtDiffusionByDiffusionListQuery, GetFtDiffusionByDiffusionListQueryVariables>;
export const GetCanCreateFtDocument = gql`
    query GetCanCreateFt($wineEntityId: ID!) {
  GetCanCreateFt(wineEntityId: $wineEntityId) {
    hasList
    hasFt
  }
}
    `;

/**
 * __useGetCanCreateFtQuery__
 *
 * To run a query within a React component, call `useGetCanCreateFtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCanCreateFtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCanCreateFtQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetCanCreateFtQuery(baseOptions: Apollo.QueryHookOptions<GetCanCreateFtQuery, GetCanCreateFtQueryVariables>) {
        return Apollo.useQuery<GetCanCreateFtQuery, GetCanCreateFtQueryVariables>(GetCanCreateFtDocument, baseOptions);
      }
export function useGetCanCreateFtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCanCreateFtQuery, GetCanCreateFtQueryVariables>) {
          return Apollo.useLazyQuery<GetCanCreateFtQuery, GetCanCreateFtQueryVariables>(GetCanCreateFtDocument, baseOptions);
        }
export type GetCanCreateFtQueryHookResult = ReturnType<typeof useGetCanCreateFtQuery>;
export type GetCanCreateFtLazyQueryHookResult = ReturnType<typeof useGetCanCreateFtLazyQuery>;
export type GetCanCreateFtQueryResult = Apollo.QueryResult<GetCanCreateFtQuery, GetCanCreateFtQueryVariables>;
export const SearchVintagesDocument = gql`
    query SearchVintages($searchInput: VintageSearchInput, $pageNumber: Int, $pageSize: Int, $locale: String) {
  SearchVintages(
    searchInput: $searchInput
    pageNumber: $pageNumber
    pageSize: $pageSize
    locale: $locale
  ) {
    similar
    data {
      sweetness
      _id
      wineEntityId
      brandName
      color
      appellation
      wineEntityName
      missingField
    }
    colors {
      _id
      count
    }
    appellations {
      _id
      count
    }
    years {
      _id
      count
    }
    totalCount {
      totalRecords
    }
  }
}
    `;

/**
 * __useSearchVintagesQuery__
 *
 * To run a query within a React component, call `useSearchVintagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchVintagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchVintagesQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSearchVintagesQuery(baseOptions?: Apollo.QueryHookOptions<SearchVintagesQuery, SearchVintagesQueryVariables>) {
        return Apollo.useQuery<SearchVintagesQuery, SearchVintagesQueryVariables>(SearchVintagesDocument, baseOptions);
      }
export function useSearchVintagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchVintagesQuery, SearchVintagesQueryVariables>) {
          return Apollo.useLazyQuery<SearchVintagesQuery, SearchVintagesQueryVariables>(SearchVintagesDocument, baseOptions);
        }
export type SearchVintagesQueryHookResult = ReturnType<typeof useSearchVintagesQuery>;
export type SearchVintagesLazyQueryHookResult = ReturnType<typeof useSearchVintagesLazyQuery>;
export type SearchVintagesQueryResult = Apollo.QueryResult<SearchVintagesQuery, SearchVintagesQueryVariables>;
export const SearchWinesDocument = gql`
    query searchWines($searchInput: VintageSearchInput, $pageNumber: Int, $pageSize: Int, $locale: String) {
  searchWines(
    searchInput: $searchInput
    pageNumber: $pageNumber
    pageSize: $pageSize
    locale: $locale
  ) {
    similar
    data {
      _id
      wineEntityShortId
      wineShortId
      color
      sweetness
      brandName
      appellation
      wineEntityId
      wineEntityName
      missingField
    }
    colors {
      _id
      count
    }
    appellations {
      _id
      count
    }
    totalCount {
      totalRecords
    }
  }
}
    `;

/**
 * __useSearchWinesQuery__
 *
 * To run a query within a React component, call `useSearchWinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchWinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchWinesQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSearchWinesQuery(baseOptions?: Apollo.QueryHookOptions<SearchWinesQuery, SearchWinesQueryVariables>) {
        return Apollo.useQuery<SearchWinesQuery, SearchWinesQueryVariables>(SearchWinesDocument, baseOptions);
      }
export function useSearchWinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchWinesQuery, SearchWinesQueryVariables>) {
          return Apollo.useLazyQuery<SearchWinesQuery, SearchWinesQueryVariables>(SearchWinesDocument, baseOptions);
        }
export type SearchWinesQueryHookResult = ReturnType<typeof useSearchWinesQuery>;
export type SearchWinesLazyQueryHookResult = ReturnType<typeof useSearchWinesLazyQuery>;
export type SearchWinesQueryResult = Apollo.QueryResult<SearchWinesQuery, SearchWinesQueryVariables>;
export const SearchActivitiesDocument = gql`
    query SearchActivities($searchInput: ActivitySearchInput, $pageNumber: Int, $pageSize: Int, $locale: String) {
  SearchActivities(
    searchInput: $searchInput
    locale: $locale
    pageNumber: $pageNumber
    pageSize: $pageSize
  ) {
    totalCount {
      totalRecords
    }
    data {
      _id
      activityShortId
      wineEntityShortId
      name
      wineEntityName
      commune
      prices
      groupSize
      languages
      labelLanguages
      categories
      private
      parkingBus
      accessibleTo
      duration
      durationUnit
      missingField
    }
    similar
  }
}
    `;

/**
 * __useSearchActivitiesQuery__
 *
 * To run a query within a React component, call `useSearchActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchActivitiesQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSearchActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<SearchActivitiesQuery, SearchActivitiesQueryVariables>) {
        return Apollo.useQuery<SearchActivitiesQuery, SearchActivitiesQueryVariables>(SearchActivitiesDocument, baseOptions);
      }
export function useSearchActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchActivitiesQuery, SearchActivitiesQueryVariables>) {
          return Apollo.useLazyQuery<SearchActivitiesQuery, SearchActivitiesQueryVariables>(SearchActivitiesDocument, baseOptions);
        }
export type SearchActivitiesQueryHookResult = ReturnType<typeof useSearchActivitiesQuery>;
export type SearchActivitiesLazyQueryHookResult = ReturnType<typeof useSearchActivitiesLazyQuery>;
export type SearchActivitiesQueryResult = Apollo.QueryResult<SearchActivitiesQuery, SearchActivitiesQueryVariables>;
export const SearchWineEntitiesDocument = gql`
    query SearchWineEntities($searchInput: WineEntitySearchInput, $pageNumber: Int, $pageSize: Int, $locale: String) {
  SearchWineEntities(
    searchInput: $searchInput
    locale: $locale
    pageNumber: $pageNumber
    pageSize: $pageSize
  ) {
    data {
      wineEntityId
      wineEntityShortId
      wineEntityName
      appellation
      badge
      label
      onlineSales
      isOpen
      classification
      surfaceArea
      missingField
    }
    totalCount {
      totalRecords
    }
    similar
  }
}
    `;

/**
 * __useSearchWineEntitiesQuery__
 *
 * To run a query within a React component, call `useSearchWineEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchWineEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchWineEntitiesQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSearchWineEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<SearchWineEntitiesQuery, SearchWineEntitiesQueryVariables>) {
        return Apollo.useQuery<SearchWineEntitiesQuery, SearchWineEntitiesQueryVariables>(SearchWineEntitiesDocument, baseOptions);
      }
export function useSearchWineEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchWineEntitiesQuery, SearchWineEntitiesQueryVariables>) {
          return Apollo.useLazyQuery<SearchWineEntitiesQuery, SearchWineEntitiesQueryVariables>(SearchWineEntitiesDocument, baseOptions);
        }
export type SearchWineEntitiesQueryHookResult = ReturnType<typeof useSearchWineEntitiesQuery>;
export type SearchWineEntitiesLazyQueryHookResult = ReturnType<typeof useSearchWineEntitiesLazyQuery>;
export type SearchWineEntitiesQueryResult = Apollo.QueryResult<SearchWineEntitiesQuery, SearchWineEntitiesQueryVariables>;
export const GetWineCardInformationDocument = gql`
    query GetWineCardInformation($wineId: ID!) {
  GetWineCardInformation(wineId: $wineId) {
    img {
      data
      twicpicURL
    }
    rewarded
    scored
    vintageCardInformation {
      year
      _id
      cuvee
    }
  }
}
    `;

/**
 * __useGetWineCardInformationQuery__
 *
 * To run a query within a React component, call `useGetWineCardInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWineCardInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWineCardInformationQuery({
 *   variables: {
 *      wineId: // value for 'wineId'
 *   },
 * });
 */
export function useGetWineCardInformationQuery(baseOptions: Apollo.QueryHookOptions<GetWineCardInformationQuery, GetWineCardInformationQueryVariables>) {
        return Apollo.useQuery<GetWineCardInformationQuery, GetWineCardInformationQueryVariables>(GetWineCardInformationDocument, baseOptions);
      }
export function useGetWineCardInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWineCardInformationQuery, GetWineCardInformationQueryVariables>) {
          return Apollo.useLazyQuery<GetWineCardInformationQuery, GetWineCardInformationQueryVariables>(GetWineCardInformationDocument, baseOptions);
        }
export type GetWineCardInformationQueryHookResult = ReturnType<typeof useGetWineCardInformationQuery>;
export type GetWineCardInformationLazyQueryHookResult = ReturnType<typeof useGetWineCardInformationLazyQuery>;
export type GetWineCardInformationQueryResult = Apollo.QueryResult<GetWineCardInformationQuery, GetWineCardInformationQueryVariables>;
export const SearchAllDocument = gql`
    query SearchAll($searchText: String) {
  SearchAll(searchInput: {searchText: $searchText}) {
    wineEntityData {
      data {
        wineEntityId
        wineEntityShortId
        wineEntityName
        appellation
        badge
        label
        onlineSales
        isOpen
        classification
        surfaceArea
      }
      totalCount {
        totalRecords
      }
    }
    vintageData {
      data {
        _id
        wineEntityShortId
        wineShortId
        vintageIds
        years
        wineEntityId
        brandName
        appellation
        sweetness
        color
        wineEntityName
      }
      totalCount {
        totalRecords
      }
    }
    activityData {
      data {
        activityShortId
        wineEntityShortId
        name
        wineEntityName
        commune
        prices
        groupSize
        languages
        categories
        private
        parkingBus
        accessibleTo
        duration
        durationUnit
        _id
      }
      totalCount {
        totalRecords
      }
    }
    totalCount
  }
}
    `;

/**
 * __useSearchAllQuery__
 *
 * To run a query within a React component, call `useSearchAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAllQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useSearchAllQuery(baseOptions?: Apollo.QueryHookOptions<SearchAllQuery, SearchAllQueryVariables>) {
        return Apollo.useQuery<SearchAllQuery, SearchAllQueryVariables>(SearchAllDocument, baseOptions);
      }
export function useSearchAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAllQuery, SearchAllQueryVariables>) {
          return Apollo.useLazyQuery<SearchAllQuery, SearchAllQueryVariables>(SearchAllDocument, baseOptions);
        }
export type SearchAllQueryHookResult = ReturnType<typeof useSearchAllQuery>;
export type SearchAllLazyQueryHookResult = ReturnType<typeof useSearchAllLazyQuery>;
export type SearchAllQueryResult = Apollo.QueryResult<SearchAllQuery, SearchAllQueryVariables>;
export const HomeResultDocument = gql`
    query HomeResult {
  HomeResult {
    wineEntityData {
      data {
        wineEntityId
        wineEntityShortId
        wineEntityName
        appellation
        badge
        label
        onlineSales
        isOpen
        classification
        surfaceArea
      }
      totalCount {
        totalRecords
      }
    }
    vintageData {
      data {
        _id
        wineEntityShortId
        wineShortId
        vintageIds
        years
        wineEntityId
        brandName
        appellation
        sweetness
        color
        wineEntityName
      }
      totalCount {
        totalRecords
      }
    }
  }
}
    `;

/**
 * __useHomeResultQuery__
 *
 * To run a query within a React component, call `useHomeResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeResultQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeResultQuery(baseOptions?: Apollo.QueryHookOptions<HomeResultQuery, HomeResultQueryVariables>) {
        return Apollo.useQuery<HomeResultQuery, HomeResultQueryVariables>(HomeResultDocument, baseOptions);
      }
export function useHomeResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeResultQuery, HomeResultQueryVariables>) {
          return Apollo.useLazyQuery<HomeResultQuery, HomeResultQueryVariables>(HomeResultDocument, baseOptions);
        }
export type HomeResultQueryHookResult = ReturnType<typeof useHomeResultQuery>;
export type HomeResultLazyQueryHookResult = ReturnType<typeof useHomeResultLazyQuery>;
export type HomeResultQueryResult = Apollo.QueryResult<HomeResultQuery, HomeResultQueryVariables>;
export const GetWineEntityCardInformationDocument = gql`
    query GetWineEntityCardInformation($wineEntityId: ID!) {
  GetWineEntityCardInformation(wineEntityId: $wineEntityId) {
    img {
      data
      twicpicURL
    }
    wineColors
    classifications
    hasEnotourismActivity
    hasBadge
    surfaceArea
    isOpenNow
    hasProductPageUrl
  }
}
    `;

/**
 * __useGetWineEntityCardInformationQuery__
 *
 * To run a query within a React component, call `useGetWineEntityCardInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWineEntityCardInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWineEntityCardInformationQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetWineEntityCardInformationQuery(baseOptions: Apollo.QueryHookOptions<GetWineEntityCardInformationQuery, GetWineEntityCardInformationQueryVariables>) {
        return Apollo.useQuery<GetWineEntityCardInformationQuery, GetWineEntityCardInformationQueryVariables>(GetWineEntityCardInformationDocument, baseOptions);
      }
export function useGetWineEntityCardInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWineEntityCardInformationQuery, GetWineEntityCardInformationQueryVariables>) {
          return Apollo.useLazyQuery<GetWineEntityCardInformationQuery, GetWineEntityCardInformationQueryVariables>(GetWineEntityCardInformationDocument, baseOptions);
        }
export type GetWineEntityCardInformationQueryHookResult = ReturnType<typeof useGetWineEntityCardInformationQuery>;
export type GetWineEntityCardInformationLazyQueryHookResult = ReturnType<typeof useGetWineEntityCardInformationLazyQuery>;
export type GetWineEntityCardInformationQueryResult = Apollo.QueryResult<GetWineEntityCardInformationQuery, GetWineEntityCardInformationQueryVariables>;
export const GetActivityCardInformationDocument = gql`
    query GetActivityCardInformation($activityId: ID!) {
  GetActivityCardInformation(activityId: $activityId) {
    img {
      data
      twicpicURL
    }
  }
}
    `;

/**
 * __useGetActivityCardInformationQuery__
 *
 * To run a query within a React component, call `useGetActivityCardInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityCardInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityCardInformationQuery({
 *   variables: {
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useGetActivityCardInformationQuery(baseOptions: Apollo.QueryHookOptions<GetActivityCardInformationQuery, GetActivityCardInformationQueryVariables>) {
        return Apollo.useQuery<GetActivityCardInformationQuery, GetActivityCardInformationQueryVariables>(GetActivityCardInformationDocument, baseOptions);
      }
export function useGetActivityCardInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityCardInformationQuery, GetActivityCardInformationQueryVariables>) {
          return Apollo.useLazyQuery<GetActivityCardInformationQuery, GetActivityCardInformationQueryVariables>(GetActivityCardInformationDocument, baseOptions);
        }
export type GetActivityCardInformationQueryHookResult = ReturnType<typeof useGetActivityCardInformationQuery>;
export type GetActivityCardInformationLazyQueryHookResult = ReturnType<typeof useGetActivityCardInformationLazyQuery>;
export type GetActivityCardInformationQueryResult = Apollo.QueryResult<GetActivityCardInformationQuery, GetActivityCardInformationQueryVariables>;
export const AutocompleteSearchDocument = gql`
    query AutocompleteSearch($searchText: String!) {
  AutocompleteSearch(searchText: $searchText) {
    _id
    kind
    title
    description
    wineShortId
    wineEntityId
    wineEntityName
    activityShortId
    wineEntityShortId
    vintages {
      _id
      year
    }
  }
}
    `;

/**
 * __useAutocompleteSearchQuery__
 *
 * To run a query within a React component, call `useAutocompleteSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteSearchQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useAutocompleteSearchQuery(baseOptions: Apollo.QueryHookOptions<AutocompleteSearchQuery, AutocompleteSearchQueryVariables>) {
        return Apollo.useQuery<AutocompleteSearchQuery, AutocompleteSearchQueryVariables>(AutocompleteSearchDocument, baseOptions);
      }
export function useAutocompleteSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutocompleteSearchQuery, AutocompleteSearchQueryVariables>) {
          return Apollo.useLazyQuery<AutocompleteSearchQuery, AutocompleteSearchQueryVariables>(AutocompleteSearchDocument, baseOptions);
        }
export type AutocompleteSearchQueryHookResult = ReturnType<typeof useAutocompleteSearchQuery>;
export type AutocompleteSearchLazyQueryHookResult = ReturnType<typeof useAutocompleteSearchLazyQuery>;
export type AutocompleteSearchQueryResult = Apollo.QueryResult<AutocompleteSearchQuery, AutocompleteSearchQueryVariables>;
export const GetFormationOrganismsFrontDocument = gql`
    query GetFormationOrganismsFront($name: String, $locale: String!) {
  GetFormationOrganismsFront(name: $name, locale: $locale) {
    _id
    name
    about
    formations
    rhythm
    link
    phone
    email
    address {
      address
      commune
      postalCode
      country
    }
    socialMedias {
      platform
      url
    }
    logo {
      data
      twicpicURL
    }
  }
}
    `;

/**
 * __useGetFormationOrganismsFrontQuery__
 *
 * To run a query within a React component, call `useGetFormationOrganismsFrontQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormationOrganismsFrontQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormationOrganismsFrontQuery({
 *   variables: {
 *      name: // value for 'name'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetFormationOrganismsFrontQuery(baseOptions: Apollo.QueryHookOptions<GetFormationOrganismsFrontQuery, GetFormationOrganismsFrontQueryVariables>) {
        return Apollo.useQuery<GetFormationOrganismsFrontQuery, GetFormationOrganismsFrontQueryVariables>(GetFormationOrganismsFrontDocument, baseOptions);
      }
export function useGetFormationOrganismsFrontLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormationOrganismsFrontQuery, GetFormationOrganismsFrontQueryVariables>) {
          return Apollo.useLazyQuery<GetFormationOrganismsFrontQuery, GetFormationOrganismsFrontQueryVariables>(GetFormationOrganismsFrontDocument, baseOptions);
        }
export type GetFormationOrganismsFrontQueryHookResult = ReturnType<typeof useGetFormationOrganismsFrontQuery>;
export type GetFormationOrganismsFrontLazyQueryHookResult = ReturnType<typeof useGetFormationOrganismsFrontLazyQuery>;
export type GetFormationOrganismsFrontQueryResult = Apollo.QueryResult<GetFormationOrganismsFrontQuery, GetFormationOrganismsFrontQueryVariables>;
export const GetFormationOrganismsSampleDocument = gql`
    query GetFormationOrganismsSample($name: String, $locale: String!) {
  GetFormationOrganismsSample(name: $name, locale: $locale) {
    _id
    name
    logo {
      data
      twicpicURL
    }
  }
}
    `;

/**
 * __useGetFormationOrganismsSampleQuery__
 *
 * To run a query within a React component, call `useGetFormationOrganismsSampleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormationOrganismsSampleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormationOrganismsSampleQuery({
 *   variables: {
 *      name: // value for 'name'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetFormationOrganismsSampleQuery(baseOptions: Apollo.QueryHookOptions<GetFormationOrganismsSampleQuery, GetFormationOrganismsSampleQueryVariables>) {
        return Apollo.useQuery<GetFormationOrganismsSampleQuery, GetFormationOrganismsSampleQueryVariables>(GetFormationOrganismsSampleDocument, baseOptions);
      }
export function useGetFormationOrganismsSampleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormationOrganismsSampleQuery, GetFormationOrganismsSampleQueryVariables>) {
          return Apollo.useLazyQuery<GetFormationOrganismsSampleQuery, GetFormationOrganismsSampleQueryVariables>(GetFormationOrganismsSampleDocument, baseOptions);
        }
export type GetFormationOrganismsSampleQueryHookResult = ReturnType<typeof useGetFormationOrganismsSampleQuery>;
export type GetFormationOrganismsSampleLazyQueryHookResult = ReturnType<typeof useGetFormationOrganismsSampleLazyQuery>;
export type GetFormationOrganismsSampleQueryResult = Apollo.QueryResult<GetFormationOrganismsSampleQuery, GetFormationOrganismsSampleQueryVariables>;
export const GetArticleFrontDocument = gql`
    query GetArticleFront($name: String!, $locale: String!) {
  GetArticleFront(name: $name, locale: $locale) {
    _id
    name
    author
    readTime
    authorId
    pageCount
    authorInfo
    authorSlug
    references {
      place
      content
    }
  }
}
    `;

/**
 * __useGetArticleFrontQuery__
 *
 * To run a query within a React component, call `useGetArticleFrontQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleFrontQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleFrontQuery({
 *   variables: {
 *      name: // value for 'name'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetArticleFrontQuery(baseOptions: Apollo.QueryHookOptions<GetArticleFrontQuery, GetArticleFrontQueryVariables>) {
        return Apollo.useQuery<GetArticleFrontQuery, GetArticleFrontQueryVariables>(GetArticleFrontDocument, baseOptions);
      }
export function useGetArticleFrontLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticleFrontQuery, GetArticleFrontQueryVariables>) {
          return Apollo.useLazyQuery<GetArticleFrontQuery, GetArticleFrontQueryVariables>(GetArticleFrontDocument, baseOptions);
        }
export type GetArticleFrontQueryHookResult = ReturnType<typeof useGetArticleFrontQuery>;
export type GetArticleFrontLazyQueryHookResult = ReturnType<typeof useGetArticleFrontLazyQuery>;
export type GetArticleFrontQueryResult = Apollo.QueryResult<GetArticleFrontQuery, GetArticleFrontQueryVariables>;
export const GetArticleContentDocument = gql`
    query GetArticleContent($articleId: ID!, $locale: String!, $page: Int) {
  GetArticleContent(articleId: $articleId, locale: $locale, page: $page) {
    page
    content
  }
}
    `;

/**
 * __useGetArticleContentQuery__
 *
 * To run a query within a React component, call `useGetArticleContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleContentQuery({
 *   variables: {
 *      articleId: // value for 'articleId'
 *      locale: // value for 'locale'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetArticleContentQuery(baseOptions: Apollo.QueryHookOptions<GetArticleContentQuery, GetArticleContentQueryVariables>) {
        return Apollo.useQuery<GetArticleContentQuery, GetArticleContentQueryVariables>(GetArticleContentDocument, baseOptions);
      }
export function useGetArticleContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticleContentQuery, GetArticleContentQueryVariables>) {
          return Apollo.useLazyQuery<GetArticleContentQuery, GetArticleContentQueryVariables>(GetArticleContentDocument, baseOptions);
        }
export type GetArticleContentQueryHookResult = ReturnType<typeof useGetArticleContentQuery>;
export type GetArticleContentLazyQueryHookResult = ReturnType<typeof useGetArticleContentLazyQuery>;
export type GetArticleContentQueryResult = Apollo.QueryResult<GetArticleContentQuery, GetArticleContentQueryVariables>;
export const GetArticleSummaryDocument = gql`
    query GetArticleSummary($name: String, $locale: String) {
  GetArticleSummary(name: $name, locale: $locale) {
    name
    author
    slugFr
    authorId
    authorInfo
    readTime {
      amount
      unit
    }
  }
}
    `;

/**
 * __useGetArticleSummaryQuery__
 *
 * To run a query within a React component, call `useGetArticleSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleSummaryQuery({
 *   variables: {
 *      name: // value for 'name'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetArticleSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetArticleSummaryQuery, GetArticleSummaryQueryVariables>) {
        return Apollo.useQuery<GetArticleSummaryQuery, GetArticleSummaryQueryVariables>(GetArticleSummaryDocument, baseOptions);
      }
export function useGetArticleSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticleSummaryQuery, GetArticleSummaryQueryVariables>) {
          return Apollo.useLazyQuery<GetArticleSummaryQuery, GetArticleSummaryQueryVariables>(GetArticleSummaryDocument, baseOptions);
        }
export type GetArticleSummaryQueryHookResult = ReturnType<typeof useGetArticleSummaryQuery>;
export type GetArticleSummaryLazyQueryHookResult = ReturnType<typeof useGetArticleSummaryLazyQuery>;
export type GetArticleSummaryQueryResult = Apollo.QueryResult<GetArticleSummaryQuery, GetArticleSummaryQueryVariables>;
export const GetVintagesOfCompetitionDocument = gql`
    query GetVintagesOfCompetition($competitionId: String) {
  GetVintagesOfCompetition(competitionId: $competitionId) {
    wineEntityShortId
    wineShortId
    brandName
    year
    _id
    wineId
    appellation
    type
    color
    sweetness
    wineEntityName
    rewardName
    rewardYear
    image {
      data
      twicpicURL
    }
  }
}
    `;

/**
 * __useGetVintagesOfCompetitionQuery__
 *
 * To run a query within a React component, call `useGetVintagesOfCompetitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVintagesOfCompetitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVintagesOfCompetitionQuery({
 *   variables: {
 *      competitionId: // value for 'competitionId'
 *   },
 * });
 */
export function useGetVintagesOfCompetitionQuery(baseOptions?: Apollo.QueryHookOptions<GetVintagesOfCompetitionQuery, GetVintagesOfCompetitionQueryVariables>) {
        return Apollo.useQuery<GetVintagesOfCompetitionQuery, GetVintagesOfCompetitionQueryVariables>(GetVintagesOfCompetitionDocument, baseOptions);
      }
export function useGetVintagesOfCompetitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVintagesOfCompetitionQuery, GetVintagesOfCompetitionQueryVariables>) {
          return Apollo.useLazyQuery<GetVintagesOfCompetitionQuery, GetVintagesOfCompetitionQueryVariables>(GetVintagesOfCompetitionDocument, baseOptions);
        }
export type GetVintagesOfCompetitionQueryHookResult = ReturnType<typeof useGetVintagesOfCompetitionQuery>;
export type GetVintagesOfCompetitionLazyQueryHookResult = ReturnType<typeof useGetVintagesOfCompetitionLazyQuery>;
export type GetVintagesOfCompetitionQueryResult = Apollo.QueryResult<GetVintagesOfCompetitionQuery, GetVintagesOfCompetitionQueryVariables>;
export const GetRepresentationOrganismsFrontSimplifiedDocument = gql`
    query GetRepresentationOrganismsFrontSimplified($name: String, $locale: String!, $excludeId: ID) {
  GetRepresentationOrganismsFront(
    name: $name
    locale: $locale
    excludeId: $excludeId
  ) {
    _id
    name
  }
}
    `;

/**
 * __useGetRepresentationOrganismsFrontSimplifiedQuery__
 *
 * To run a query within a React component, call `useGetRepresentationOrganismsFrontSimplifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepresentationOrganismsFrontSimplifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepresentationOrganismsFrontSimplifiedQuery({
 *   variables: {
 *      name: // value for 'name'
 *      locale: // value for 'locale'
 *      excludeId: // value for 'excludeId'
 *   },
 * });
 */
export function useGetRepresentationOrganismsFrontSimplifiedQuery(baseOptions: Apollo.QueryHookOptions<GetRepresentationOrganismsFrontSimplifiedQuery, GetRepresentationOrganismsFrontSimplifiedQueryVariables>) {
        return Apollo.useQuery<GetRepresentationOrganismsFrontSimplifiedQuery, GetRepresentationOrganismsFrontSimplifiedQueryVariables>(GetRepresentationOrganismsFrontSimplifiedDocument, baseOptions);
      }
export function useGetRepresentationOrganismsFrontSimplifiedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRepresentationOrganismsFrontSimplifiedQuery, GetRepresentationOrganismsFrontSimplifiedQueryVariables>) {
          return Apollo.useLazyQuery<GetRepresentationOrganismsFrontSimplifiedQuery, GetRepresentationOrganismsFrontSimplifiedQueryVariables>(GetRepresentationOrganismsFrontSimplifiedDocument, baseOptions);
        }
export type GetRepresentationOrganismsFrontSimplifiedQueryHookResult = ReturnType<typeof useGetRepresentationOrganismsFrontSimplifiedQuery>;
export type GetRepresentationOrganismsFrontSimplifiedLazyQueryHookResult = ReturnType<typeof useGetRepresentationOrganismsFrontSimplifiedLazyQuery>;
export type GetRepresentationOrganismsFrontSimplifiedQueryResult = Apollo.QueryResult<GetRepresentationOrganismsFrontSimplifiedQuery, GetRepresentationOrganismsFrontSimplifiedQueryVariables>;
export const GetArticlesByAuthorIdDocument = gql`
    query GetArticlesByAuthorId($authorId: ID!, $locale: String) {
  GetArticlesByAuthorId(authorId: $authorId, locale: $locale) {
    name
    slugFr
  }
}
    `;

/**
 * __useGetArticlesByAuthorIdQuery__
 *
 * To run a query within a React component, call `useGetArticlesByAuthorIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesByAuthorIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesByAuthorIdQuery({
 *   variables: {
 *      authorId: // value for 'authorId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetArticlesByAuthorIdQuery(baseOptions: Apollo.QueryHookOptions<GetArticlesByAuthorIdQuery, GetArticlesByAuthorIdQueryVariables>) {
        return Apollo.useQuery<GetArticlesByAuthorIdQuery, GetArticlesByAuthorIdQueryVariables>(GetArticlesByAuthorIdDocument, baseOptions);
      }
export function useGetArticlesByAuthorIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticlesByAuthorIdQuery, GetArticlesByAuthorIdQueryVariables>) {
          return Apollo.useLazyQuery<GetArticlesByAuthorIdQuery, GetArticlesByAuthorIdQueryVariables>(GetArticlesByAuthorIdDocument, baseOptions);
        }
export type GetArticlesByAuthorIdQueryHookResult = ReturnType<typeof useGetArticlesByAuthorIdQuery>;
export type GetArticlesByAuthorIdLazyQueryHookResult = ReturnType<typeof useGetArticlesByAuthorIdLazyQuery>;
export type GetArticlesByAuthorIdQueryResult = Apollo.QueryResult<GetArticlesByAuthorIdQuery, GetArticlesByAuthorIdQueryVariables>;
export const GetRepresentationOrganismsFrontDocument = gql`
    query GetRepresentationOrganismsFront($name: String, $locale: String!) {
  GetRepresentationOrganismsFront(name: $name, locale: $locale) {
    _id
    name
    about
    phone
    email
    members {
      title
      firstName
      lastName
      role
      icon
    }
    socialMedias: socialMedias {
      platform
      url
    }
    logo {
      data
      twicpicURL
    }
    address {
      postalCode
      address
      commune
      country
    }
  }
}
    `;

/**
 * __useGetRepresentationOrganismsFrontQuery__
 *
 * To run a query within a React component, call `useGetRepresentationOrganismsFrontQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepresentationOrganismsFrontQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepresentationOrganismsFrontQuery({
 *   variables: {
 *      name: // value for 'name'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetRepresentationOrganismsFrontQuery(baseOptions: Apollo.QueryHookOptions<GetRepresentationOrganismsFrontQuery, GetRepresentationOrganismsFrontQueryVariables>) {
        return Apollo.useQuery<GetRepresentationOrganismsFrontQuery, GetRepresentationOrganismsFrontQueryVariables>(GetRepresentationOrganismsFrontDocument, baseOptions);
      }
export function useGetRepresentationOrganismsFrontLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRepresentationOrganismsFrontQuery, GetRepresentationOrganismsFrontQueryVariables>) {
          return Apollo.useLazyQuery<GetRepresentationOrganismsFrontQuery, GetRepresentationOrganismsFrontQueryVariables>(GetRepresentationOrganismsFrontDocument, baseOptions);
        }
export type GetRepresentationOrganismsFrontQueryHookResult = ReturnType<typeof useGetRepresentationOrganismsFrontQuery>;
export type GetRepresentationOrganismsFrontLazyQueryHookResult = ReturnType<typeof useGetRepresentationOrganismsFrontLazyQuery>;
export type GetRepresentationOrganismsFrontQueryResult = Apollo.QueryResult<GetRepresentationOrganismsFrontQuery, GetRepresentationOrganismsFrontQueryVariables>;
export const GetRandomCompetitionSampleDocument = gql`
    query GetRandomCompetitionSample($excludeId: ID) {
  GetRandomCompetitionSample(excludeId: $excludeId) {
    _id
    name
    totalMedals
    maxYear
    logo {
      data
      twicpicURL
    }
  }
}
    `;

/**
 * __useGetRandomCompetitionSampleQuery__
 *
 * To run a query within a React component, call `useGetRandomCompetitionSampleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRandomCompetitionSampleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRandomCompetitionSampleQuery({
 *   variables: {
 *      excludeId: // value for 'excludeId'
 *   },
 * });
 */
export function useGetRandomCompetitionSampleQuery(baseOptions?: Apollo.QueryHookOptions<GetRandomCompetitionSampleQuery, GetRandomCompetitionSampleQueryVariables>) {
        return Apollo.useQuery<GetRandomCompetitionSampleQuery, GetRandomCompetitionSampleQueryVariables>(GetRandomCompetitionSampleDocument, baseOptions);
      }
export function useGetRandomCompetitionSampleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRandomCompetitionSampleQuery, GetRandomCompetitionSampleQueryVariables>) {
          return Apollo.useLazyQuery<GetRandomCompetitionSampleQuery, GetRandomCompetitionSampleQueryVariables>(GetRandomCompetitionSampleDocument, baseOptions);
        }
export type GetRandomCompetitionSampleQueryHookResult = ReturnType<typeof useGetRandomCompetitionSampleQuery>;
export type GetRandomCompetitionSampleLazyQueryHookResult = ReturnType<typeof useGetRandomCompetitionSampleLazyQuery>;
export type GetRandomCompetitionSampleQueryResult = Apollo.QueryResult<GetRandomCompetitionSampleQuery, GetRandomCompetitionSampleQueryVariables>;
export const GetCompetitionListDocument = gql`
    query GetCompetitionList {
  GetCompetitionList {
    _id
    name
    totalMedals
    maxYear
    logo {
      data
      twicpicURL
    }
  }
}
    `;

/**
 * __useGetCompetitionListQuery__
 *
 * To run a query within a React component, call `useGetCompetitionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompetitionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompetitionListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompetitionListQuery(baseOptions?: Apollo.QueryHookOptions<GetCompetitionListQuery, GetCompetitionListQueryVariables>) {
        return Apollo.useQuery<GetCompetitionListQuery, GetCompetitionListQueryVariables>(GetCompetitionListDocument, baseOptions);
      }
export function useGetCompetitionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompetitionListQuery, GetCompetitionListQueryVariables>) {
          return Apollo.useLazyQuery<GetCompetitionListQuery, GetCompetitionListQueryVariables>(GetCompetitionListDocument, baseOptions);
        }
export type GetCompetitionListQueryHookResult = ReturnType<typeof useGetCompetitionListQuery>;
export type GetCompetitionListLazyQueryHookResult = ReturnType<typeof useGetCompetitionListLazyQuery>;
export type GetCompetitionListQueryResult = Apollo.QueryResult<GetCompetitionListQuery, GetCompetitionListQueryVariables>;
export const GetCompetitionsFrontDocument = gql`
    query GetCompetitionsFront($name: String, $locale: String) {
  GetCompetitionsFront(name: $name, locale: $locale) {
    _id
    url
    name
    email
    phone
    maxYear
    lastDate
    nextDate
    totalMedals
    nextDateYear
    presentation
    competitionId
    nextDateUnknow
    isNextDateKnow
    medalPercentage
    moreInformations
    selectionCriteria
    medals {
      number
      year
      fontColor
      bgColor
      name
    }
    members {
      title
      firstName
      lastName
      email
      telephone
      role {
        fr
        en
        es
        it
        de
        nl
        ja
        zh
      }
    }
    address {
      address
      commune
      postalCode
      country
    }
    socialMedias {
      _id
      platform
      name
      url
      icon {
        data
      }
    }
    logo {
      data
    }
  }
}
    `;

/**
 * __useGetCompetitionsFrontQuery__
 *
 * To run a query within a React component, call `useGetCompetitionsFrontQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompetitionsFrontQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompetitionsFrontQuery({
 *   variables: {
 *      name: // value for 'name'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetCompetitionsFrontQuery(baseOptions?: Apollo.QueryHookOptions<GetCompetitionsFrontQuery, GetCompetitionsFrontQueryVariables>) {
        return Apollo.useQuery<GetCompetitionsFrontQuery, GetCompetitionsFrontQueryVariables>(GetCompetitionsFrontDocument, baseOptions);
      }
export function useGetCompetitionsFrontLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompetitionsFrontQuery, GetCompetitionsFrontQueryVariables>) {
          return Apollo.useLazyQuery<GetCompetitionsFrontQuery, GetCompetitionsFrontQueryVariables>(GetCompetitionsFrontDocument, baseOptions);
        }
export type GetCompetitionsFrontQueryHookResult = ReturnType<typeof useGetCompetitionsFrontQuery>;
export type GetCompetitionsFrontLazyQueryHookResult = ReturnType<typeof useGetCompetitionsFrontLazyQuery>;
export type GetCompetitionsFrontQueryResult = Apollo.QueryResult<GetCompetitionsFrontQuery, GetCompetitionsFrontQueryVariables>;
export const GetAppellationDescriptionsFrontDocument = gql`
    query GetAppellationDescriptionsFront($name: String, $locale: String!) {
  GetAppellationDescriptionsFront(name: $name, locale: $locale) {
    name
    description
    subregion
    surface
    surfacePercentage
    specificationLink
    geoArea
    proximityArea
    odg {
      name
    }
    logo {
      data
      twicpicURL
    }
    wines {
      color
      sweetness
      type
    }
    winesProduced
    grapeVarietiesRed {
      varietyDisplayName
      primary
    }
    grapeVarietiesWhite {
      varietyDisplayName
      primary
    }
    wineInformations {
      wineName
      aromas
      noseIntensity
      tasteIntensity
      temperature
      attack
      length
      acidity
      storageYear
      about
    }
  }
}
    `;

/**
 * __useGetAppellationDescriptionsFrontQuery__
 *
 * To run a query within a React component, call `useGetAppellationDescriptionsFrontQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppellationDescriptionsFrontQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppellationDescriptionsFrontQuery({
 *   variables: {
 *      name: // value for 'name'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetAppellationDescriptionsFrontQuery(baseOptions: Apollo.QueryHookOptions<GetAppellationDescriptionsFrontQuery, GetAppellationDescriptionsFrontQueryVariables>) {
        return Apollo.useQuery<GetAppellationDescriptionsFrontQuery, GetAppellationDescriptionsFrontQueryVariables>(GetAppellationDescriptionsFrontDocument, baseOptions);
      }
export function useGetAppellationDescriptionsFrontLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppellationDescriptionsFrontQuery, GetAppellationDescriptionsFrontQueryVariables>) {
          return Apollo.useLazyQuery<GetAppellationDescriptionsFrontQuery, GetAppellationDescriptionsFrontQueryVariables>(GetAppellationDescriptionsFrontDocument, baseOptions);
        }
export type GetAppellationDescriptionsFrontQueryHookResult = ReturnType<typeof useGetAppellationDescriptionsFrontQuery>;
export type GetAppellationDescriptionsFrontLazyQueryHookResult = ReturnType<typeof useGetAppellationDescriptionsFrontLazyQuery>;
export type GetAppellationDescriptionsFrontQueryResult = Apollo.QueryResult<GetAppellationDescriptionsFrontQuery, GetAppellationDescriptionsFrontQueryVariables>;
export const GetAppellationDescriptionsSampleDocument = gql`
    query GetAppellationDescriptionsSample {
  GetAppellationDescriptionsSample {
    name
    logo {
      data
    }
    subregion
    wines {
      color
      sweetness
      type
    }
  }
}
    `;

/**
 * __useGetAppellationDescriptionsSampleQuery__
 *
 * To run a query within a React component, call `useGetAppellationDescriptionsSampleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppellationDescriptionsSampleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppellationDescriptionsSampleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppellationDescriptionsSampleQuery(baseOptions?: Apollo.QueryHookOptions<GetAppellationDescriptionsSampleQuery, GetAppellationDescriptionsSampleQueryVariables>) {
        return Apollo.useQuery<GetAppellationDescriptionsSampleQuery, GetAppellationDescriptionsSampleQueryVariables>(GetAppellationDescriptionsSampleDocument, baseOptions);
      }
export function useGetAppellationDescriptionsSampleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppellationDescriptionsSampleQuery, GetAppellationDescriptionsSampleQueryVariables>) {
          return Apollo.useLazyQuery<GetAppellationDescriptionsSampleQuery, GetAppellationDescriptionsSampleQueryVariables>(GetAppellationDescriptionsSampleDocument, baseOptions);
        }
export type GetAppellationDescriptionsSampleQueryHookResult = ReturnType<typeof useGetAppellationDescriptionsSampleQuery>;
export type GetAppellationDescriptionsSampleLazyQueryHookResult = ReturnType<typeof useGetAppellationDescriptionsSampleLazyQuery>;
export type GetAppellationDescriptionsSampleQueryResult = Apollo.QueryResult<GetAppellationDescriptionsSampleQuery, GetAppellationDescriptionsSampleQueryVariables>;
export const GetAppellationVintageIdsDocument = gql`
    query GetAppellationVintageIds($label: String!) {
  GetAppellationVintageIds(label: $label)
}
    `;

/**
 * __useGetAppellationVintageIdsQuery__
 *
 * To run a query within a React component, call `useGetAppellationVintageIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppellationVintageIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppellationVintageIdsQuery({
 *   variables: {
 *      label: // value for 'label'
 *   },
 * });
 */
export function useGetAppellationVintageIdsQuery(baseOptions: Apollo.QueryHookOptions<GetAppellationVintageIdsQuery, GetAppellationVintageIdsQueryVariables>) {
        return Apollo.useQuery<GetAppellationVintageIdsQuery, GetAppellationVintageIdsQueryVariables>(GetAppellationVintageIdsDocument, baseOptions);
      }
export function useGetAppellationVintageIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppellationVintageIdsQuery, GetAppellationVintageIdsQueryVariables>) {
          return Apollo.useLazyQuery<GetAppellationVintageIdsQuery, GetAppellationVintageIdsQueryVariables>(GetAppellationVintageIdsDocument, baseOptions);
        }
export type GetAppellationVintageIdsQueryHookResult = ReturnType<typeof useGetAppellationVintageIdsQuery>;
export type GetAppellationVintageIdsLazyQueryHookResult = ReturnType<typeof useGetAppellationVintageIdsLazyQuery>;
export type GetAppellationVintageIdsQueryResult = Apollo.QueryResult<GetAppellationVintageIdsQuery, GetAppellationVintageIdsQueryVariables>;
export const GetAppellationVintageDocument = gql`
    query GetAppellationVintage($vintageId: ID!) {
  GetAppellationVintage(vintageId: $vintageId) {
    year
    cuvee
    label
    wineName
    vintageId
    classification
    wineEntityName
    bottlePicture {
      data
      twicpicURL
    }
  }
}
    `;

/**
 * __useGetAppellationVintageQuery__
 *
 * To run a query within a React component, call `useGetAppellationVintageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppellationVintageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppellationVintageQuery({
 *   variables: {
 *      vintageId: // value for 'vintageId'
 *   },
 * });
 */
export function useGetAppellationVintageQuery(baseOptions: Apollo.QueryHookOptions<GetAppellationVintageQuery, GetAppellationVintageQueryVariables>) {
        return Apollo.useQuery<GetAppellationVintageQuery, GetAppellationVintageQueryVariables>(GetAppellationVintageDocument, baseOptions);
      }
export function useGetAppellationVintageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppellationVintageQuery, GetAppellationVintageQueryVariables>) {
          return Apollo.useLazyQuery<GetAppellationVintageQuery, GetAppellationVintageQueryVariables>(GetAppellationVintageDocument, baseOptions);
        }
export type GetAppellationVintageQueryHookResult = ReturnType<typeof useGetAppellationVintageQuery>;
export type GetAppellationVintageLazyQueryHookResult = ReturnType<typeof useGetAppellationVintageLazyQuery>;
export type GetAppellationVintageQueryResult = Apollo.QueryResult<GetAppellationVintageQuery, GetAppellationVintageQueryVariables>;
export const GetFirstWineIdDocument = gql`
    query GetFirstWineId($wineEntityId: ID!) {
  GetFirstWineId(wineEntityId: $wineEntityId)
}
    `;

/**
 * __useGetFirstWineIdQuery__
 *
 * To run a query within a React component, call `useGetFirstWineIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirstWineIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirstWineIdQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetFirstWineIdQuery(baseOptions: Apollo.QueryHookOptions<GetFirstWineIdQuery, GetFirstWineIdQueryVariables>) {
        return Apollo.useQuery<GetFirstWineIdQuery, GetFirstWineIdQueryVariables>(GetFirstWineIdDocument, baseOptions);
      }
export function useGetFirstWineIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFirstWineIdQuery, GetFirstWineIdQueryVariables>) {
          return Apollo.useLazyQuery<GetFirstWineIdQuery, GetFirstWineIdQueryVariables>(GetFirstWineIdDocument, baseOptions);
        }
export type GetFirstWineIdQueryHookResult = ReturnType<typeof useGetFirstWineIdQuery>;
export type GetFirstWineIdLazyQueryHookResult = ReturnType<typeof useGetFirstWineIdLazyQuery>;
export type GetFirstWineIdQueryResult = Apollo.QueryResult<GetFirstWineIdQuery, GetFirstWineIdQueryVariables>;
export const GetFirstActivityIdDocument = gql`
    query GetFirstActivityId($wineEntityId: ID!) {
  GetFirstActivityId(wineEntityId: $wineEntityId)
}
    `;

/**
 * __useGetFirstActivityIdQuery__
 *
 * To run a query within a React component, call `useGetFirstActivityIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirstActivityIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirstActivityIdQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetFirstActivityIdQuery(baseOptions: Apollo.QueryHookOptions<GetFirstActivityIdQuery, GetFirstActivityIdQueryVariables>) {
        return Apollo.useQuery<GetFirstActivityIdQuery, GetFirstActivityIdQueryVariables>(GetFirstActivityIdDocument, baseOptions);
      }
export function useGetFirstActivityIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFirstActivityIdQuery, GetFirstActivityIdQueryVariables>) {
          return Apollo.useLazyQuery<GetFirstActivityIdQuery, GetFirstActivityIdQueryVariables>(GetFirstActivityIdDocument, baseOptions);
        }
export type GetFirstActivityIdQueryHookResult = ReturnType<typeof useGetFirstActivityIdQuery>;
export type GetFirstActivityIdLazyQueryHookResult = ReturnType<typeof useGetFirstActivityIdLazyQuery>;
export type GetFirstActivityIdQueryResult = Apollo.QueryResult<GetFirstActivityIdQuery, GetFirstActivityIdQueryVariables>;
export const GetExplorerKeywordsDocument = gql`
    query GetExplorerKeywords($wineEntityId: ID!) {
  GetExplorerKeywords(wineEntityId: $wineEntityId) {
    label
    link
    category
    keywords
  }
}
    `;

/**
 * __useGetExplorerKeywordsQuery__
 *
 * To run a query within a React component, call `useGetExplorerKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExplorerKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExplorerKeywordsQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetExplorerKeywordsQuery(baseOptions: Apollo.QueryHookOptions<GetExplorerKeywordsQuery, GetExplorerKeywordsQueryVariables>) {
        return Apollo.useQuery<GetExplorerKeywordsQuery, GetExplorerKeywordsQueryVariables>(GetExplorerKeywordsDocument, baseOptions);
      }
export function useGetExplorerKeywordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExplorerKeywordsQuery, GetExplorerKeywordsQueryVariables>) {
          return Apollo.useLazyQuery<GetExplorerKeywordsQuery, GetExplorerKeywordsQueryVariables>(GetExplorerKeywordsDocument, baseOptions);
        }
export type GetExplorerKeywordsQueryHookResult = ReturnType<typeof useGetExplorerKeywordsQuery>;
export type GetExplorerKeywordsLazyQueryHookResult = ReturnType<typeof useGetExplorerKeywordsLazyQuery>;
export type GetExplorerKeywordsQueryResult = Apollo.QueryResult<GetExplorerKeywordsQuery, GetExplorerKeywordsQueryVariables>;
export const GetCommunePostalCodeDocument = gql`
    query GetCommunePostalCode($communePostalCode: String, $region: String, $limit: Int) {
  GetCommunePostalCode(
    communePostalCode: $communePostalCode
    region: $region
    limit: $limit
  ) {
    name
    displayName
    postalCode
    INSEEcode
  }
}
    `;

/**
 * __useGetCommunePostalCodeQuery__
 *
 * To run a query within a React component, call `useGetCommunePostalCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunePostalCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunePostalCodeQuery({
 *   variables: {
 *      communePostalCode: // value for 'communePostalCode'
 *      region: // value for 'region'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCommunePostalCodeQuery(baseOptions?: Apollo.QueryHookOptions<GetCommunePostalCodeQuery, GetCommunePostalCodeQueryVariables>) {
        return Apollo.useQuery<GetCommunePostalCodeQuery, GetCommunePostalCodeQueryVariables>(GetCommunePostalCodeDocument, baseOptions);
      }
export function useGetCommunePostalCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunePostalCodeQuery, GetCommunePostalCodeQueryVariables>) {
          return Apollo.useLazyQuery<GetCommunePostalCodeQuery, GetCommunePostalCodeQueryVariables>(GetCommunePostalCodeDocument, baseOptions);
        }
export type GetCommunePostalCodeQueryHookResult = ReturnType<typeof useGetCommunePostalCodeQuery>;
export type GetCommunePostalCodeLazyQueryHookResult = ReturnType<typeof useGetCommunePostalCodeLazyQuery>;
export type GetCommunePostalCodeQueryResult = Apollo.QueryResult<GetCommunePostalCodeQuery, GetCommunePostalCodeQueryVariables>;
export const GetIndicatorsMetricsDocument = gql`
    query GetIndicatorsMetrics($wineEntityId: ID!) {
  GetIndicatorsMetrics(wineEntityId: $wineEntityId) {
    surfaceImportCvi
    wineEntityName
  }
}
    `;

/**
 * __useGetIndicatorsMetricsQuery__
 *
 * To run a query within a React component, call `useGetIndicatorsMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIndicatorsMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIndicatorsMetricsQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetIndicatorsMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetIndicatorsMetricsQuery, GetIndicatorsMetricsQueryVariables>) {
        return Apollo.useQuery<GetIndicatorsMetricsQuery, GetIndicatorsMetricsQueryVariables>(GetIndicatorsMetricsDocument, baseOptions);
      }
export function useGetIndicatorsMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIndicatorsMetricsQuery, GetIndicatorsMetricsQueryVariables>) {
          return Apollo.useLazyQuery<GetIndicatorsMetricsQuery, GetIndicatorsMetricsQueryVariables>(GetIndicatorsMetricsDocument, baseOptions);
        }
export type GetIndicatorsMetricsQueryHookResult = ReturnType<typeof useGetIndicatorsMetricsQuery>;
export type GetIndicatorsMetricsLazyQueryHookResult = ReturnType<typeof useGetIndicatorsMetricsLazyQuery>;
export type GetIndicatorsMetricsQueryResult = Apollo.QueryResult<GetIndicatorsMetricsQuery, GetIndicatorsMetricsQueryVariables>;
export const GetMemberMetricsDocument = gql`
    query GetMemberMetrics($wineEntityId: ID!) {
  GetMemberMetrics(wineEntityId: $wineEntityId) {
    ownerCount
    operatorCount
    ownerOperatorCount
    total
    otherCount
    members {
      displayName
      personId
      proFunctions
      vip
      image {
        data
      }
    }
  }
}
    `;

/**
 * __useGetMemberMetricsQuery__
 *
 * To run a query within a React component, call `useGetMemberMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberMetricsQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetMemberMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetMemberMetricsQuery, GetMemberMetricsQueryVariables>) {
        return Apollo.useQuery<GetMemberMetricsQuery, GetMemberMetricsQueryVariables>(GetMemberMetricsDocument, baseOptions);
      }
export function useGetMemberMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberMetricsQuery, GetMemberMetricsQueryVariables>) {
          return Apollo.useLazyQuery<GetMemberMetricsQuery, GetMemberMetricsQueryVariables>(GetMemberMetricsDocument, baseOptions);
        }
export type GetMemberMetricsQueryHookResult = ReturnType<typeof useGetMemberMetricsQuery>;
export type GetMemberMetricsLazyQueryHookResult = ReturnType<typeof useGetMemberMetricsLazyQuery>;
export type GetMemberMetricsQueryResult = Apollo.QueryResult<GetMemberMetricsQuery, GetMemberMetricsQueryVariables>;
export const GetContactMetricsDocument = gql`
    query GetContactMetrics($wineEntityId: ID!) {
  GetContactMetrics(wineEntityId: $wineEntityId) {
    listCount
    total
    subscriberCount
    contactsByCategories {
      count
      category
      backgroundColor
      color
    }
  }
}
    `;

/**
 * __useGetContactMetricsQuery__
 *
 * To run a query within a React component, call `useGetContactMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactMetricsQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetContactMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetContactMetricsQuery, GetContactMetricsQueryVariables>) {
        return Apollo.useQuery<GetContactMetricsQuery, GetContactMetricsQueryVariables>(GetContactMetricsDocument, baseOptions);
      }
export function useGetContactMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactMetricsQuery, GetContactMetricsQueryVariables>) {
          return Apollo.useLazyQuery<GetContactMetricsQuery, GetContactMetricsQueryVariables>(GetContactMetricsDocument, baseOptions);
        }
export type GetContactMetricsQueryHookResult = ReturnType<typeof useGetContactMetricsQuery>;
export type GetContactMetricsLazyQueryHookResult = ReturnType<typeof useGetContactMetricsLazyQuery>;
export type GetContactMetricsQueryResult = Apollo.QueryResult<GetContactMetricsQuery, GetContactMetricsQueryVariables>;
export const GetFtParametersMetricsDocument = gql`
    query GetFtParametersMetrics($wineEntityId: ID!) {
  GetFtParametersMetrics(wineEntityId: $wineEntityId) {
    total
    ftByStatus {
      draft
      active
      online
      inactive
    }
  }
}
    `;

/**
 * __useGetFtParametersMetricsQuery__
 *
 * To run a query within a React component, call `useGetFtParametersMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFtParametersMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFtParametersMetricsQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetFtParametersMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetFtParametersMetricsQuery, GetFtParametersMetricsQueryVariables>) {
        return Apollo.useQuery<GetFtParametersMetricsQuery, GetFtParametersMetricsQueryVariables>(GetFtParametersMetricsDocument, baseOptions);
      }
export function useGetFtParametersMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFtParametersMetricsQuery, GetFtParametersMetricsQueryVariables>) {
          return Apollo.useLazyQuery<GetFtParametersMetricsQuery, GetFtParametersMetricsQueryVariables>(GetFtParametersMetricsDocument, baseOptions);
        }
export type GetFtParametersMetricsQueryHookResult = ReturnType<typeof useGetFtParametersMetricsQuery>;
export type GetFtParametersMetricsLazyQueryHookResult = ReturnType<typeof useGetFtParametersMetricsLazyQuery>;
export type GetFtParametersMetricsQueryResult = Apollo.QueryResult<GetFtParametersMetricsQuery, GetFtParametersMetricsQueryVariables>;
export const GetWineMetricsDocument = gql`
    query GetWineMetrics($wineEntityId: ID!) {
  GetWineMetrics(wineEntityId: $wineEntityId) {
    wineId
    wineName
    wineColor
    appellation
    rewardCount
    scroreCount
    classification
    bottleImage {
      data
    }
    vintages {
      name
      url
    }
  }
}
    `;

/**
 * __useGetWineMetricsQuery__
 *
 * To run a query within a React component, call `useGetWineMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWineMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWineMetricsQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetWineMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetWineMetricsQuery, GetWineMetricsQueryVariables>) {
        return Apollo.useQuery<GetWineMetricsQuery, GetWineMetricsQueryVariables>(GetWineMetricsDocument, baseOptions);
      }
export function useGetWineMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWineMetricsQuery, GetWineMetricsQueryVariables>) {
          return Apollo.useLazyQuery<GetWineMetricsQuery, GetWineMetricsQueryVariables>(GetWineMetricsDocument, baseOptions);
        }
export type GetWineMetricsQueryHookResult = ReturnType<typeof useGetWineMetricsQuery>;
export type GetWineMetricsLazyQueryHookResult = ReturnType<typeof useGetWineMetricsLazyQuery>;
export type GetWineMetricsQueryResult = Apollo.QueryResult<GetWineMetricsQuery, GetWineMetricsQueryVariables>;
export const GetWineActivityMetricsDocument = gql`
    query GetWineActivityMetrics($wineEntityId: ID!) {
  GetWineActivityMetrics(wineEntityId: $wineEntityId) {
    name
    url
    type {
      type
      bgColor
      textColor
    }
    image {
      data
    }
  }
}
    `;

/**
 * __useGetWineActivityMetricsQuery__
 *
 * To run a query within a React component, call `useGetWineActivityMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWineActivityMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWineActivityMetricsQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetWineActivityMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetWineActivityMetricsQuery, GetWineActivityMetricsQueryVariables>) {
        return Apollo.useQuery<GetWineActivityMetricsQuery, GetWineActivityMetricsQueryVariables>(GetWineActivityMetricsDocument, baseOptions);
      }
export function useGetWineActivityMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWineActivityMetricsQuery, GetWineActivityMetricsQueryVariables>) {
          return Apollo.useLazyQuery<GetWineActivityMetricsQuery, GetWineActivityMetricsQueryVariables>(GetWineActivityMetricsDocument, baseOptions);
        }
export type GetWineActivityMetricsQueryHookResult = ReturnType<typeof useGetWineActivityMetricsQuery>;
export type GetWineActivityMetricsLazyQueryHookResult = ReturnType<typeof useGetWineActivityMetricsLazyQuery>;
export type GetWineActivityMetricsQueryResult = Apollo.QueryResult<GetWineActivityMetricsQuery, GetWineActivityMetricsQueryVariables>;
export const GetVineyardMetricsDocument = gql`
    query GetVineyardMetrics($wineEntityId: ID!) {
  GetVineyardMetrics(wineEntityId: $wineEntityId) {
    vineyardIndicator {
      id
      name
      quantity
      unit
    }
    vinesIndicator {
      name
      rate
      area
      backgroundColor
      color
    }
    repartitionsGeoIndicator {
      name
      rate
      area
      backgroundColor
      color
    }
  }
}
    `;

/**
 * __useGetVineyardMetricsQuery__
 *
 * To run a query within a React component, call `useGetVineyardMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVineyardMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVineyardMetricsQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetVineyardMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetVineyardMetricsQuery, GetVineyardMetricsQueryVariables>) {
        return Apollo.useQuery<GetVineyardMetricsQuery, GetVineyardMetricsQueryVariables>(GetVineyardMetricsDocument, baseOptions);
      }
export function useGetVineyardMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVineyardMetricsQuery, GetVineyardMetricsQueryVariables>) {
          return Apollo.useLazyQuery<GetVineyardMetricsQuery, GetVineyardMetricsQueryVariables>(GetVineyardMetricsDocument, baseOptions);
        }
export type GetVineyardMetricsQueryHookResult = ReturnType<typeof useGetVineyardMetricsQuery>;
export type GetVineyardMetricsLazyQueryHookResult = ReturnType<typeof useGetVineyardMetricsLazyQuery>;
export type GetVineyardMetricsQueryResult = Apollo.QueryResult<GetVineyardMetricsQuery, GetVineyardMetricsQueryVariables>;
export const GetFtModelsDocument = gql`
    query GetFtModels($vintageId: ID!, $wineEntityId: ID!) {
  GetFtModels(vintageId: $vintageId, wineEntityId: $wineEntityId) {
    _id
    category
    missingComponents {
      label
      url
    }
    pagePreviews {
      data
    }
    essentialRestriction
  }
}
    `;

/**
 * __useGetFtModelsQuery__
 *
 * To run a query within a React component, call `useGetFtModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFtModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFtModelsQuery({
 *   variables: {
 *      vintageId: // value for 'vintageId'
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetFtModelsQuery(baseOptions: Apollo.QueryHookOptions<GetFtModelsQuery, GetFtModelsQueryVariables>) {
        return Apollo.useQuery<GetFtModelsQuery, GetFtModelsQueryVariables>(GetFtModelsDocument, baseOptions);
      }
export function useGetFtModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFtModelsQuery, GetFtModelsQueryVariables>) {
          return Apollo.useLazyQuery<GetFtModelsQuery, GetFtModelsQueryVariables>(GetFtModelsDocument, baseOptions);
        }
export type GetFtModelsQueryHookResult = ReturnType<typeof useGetFtModelsQuery>;
export type GetFtModelsLazyQueryHookResult = ReturnType<typeof useGetFtModelsLazyQuery>;
export type GetFtModelsQueryResult = Apollo.QueryResult<GetFtModelsQuery, GetFtModelsQueryVariables>;
export const GetActivityFrontDocument = gql`
    query GetActivityFront($communeINSEEcode: String!, $locale: String) {
  GetActivityFront(communeINSEEcode: $communeINSEEcode, locale: $locale) {
    priceOnEstimation
    free
    activityId
    name
    types {
      bgColor
      textColor
      type
      typeFr
    }
    languages {
      language
      icon {
        data
        twicpicURL
      }
    }
    description
    groupSize
    image {
      data
    }
    accessibleTo
    startingAge
    tags
    activePeriods {
      startAt {
        day
        month
        year
        vaguePeriod
      }
      endAt {
        day
        month
        year
        vaguePeriod
      }
    }
    duration
    durationUnit
    reservationNeeded
    priceDetails {
      kind
      price
      currency
    }
    contactPerson {
      title
      type
      functions
      phoneNumber
      email
      personId
      image {
        data
      }
    }
    contactPhoneNumber
    contactEmail
    minRentTime
    minRentTimeUnit
    maxRentTime
    maxRentTimeUnit
  }
}
    `;

/**
 * __useGetActivityFrontQuery__
 *
 * To run a query within a React component, call `useGetActivityFrontQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityFrontQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityFrontQuery({
 *   variables: {
 *      communeINSEEcode: // value for 'communeINSEEcode'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetActivityFrontQuery(baseOptions: Apollo.QueryHookOptions<GetActivityFrontQuery, GetActivityFrontQueryVariables>) {
        return Apollo.useQuery<GetActivityFrontQuery, GetActivityFrontQueryVariables>(GetActivityFrontDocument, baseOptions);
      }
export function useGetActivityFrontLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityFrontQuery, GetActivityFrontQueryVariables>) {
          return Apollo.useLazyQuery<GetActivityFrontQuery, GetActivityFrontQueryVariables>(GetActivityFrontDocument, baseOptions);
        }
export type GetActivityFrontQueryHookResult = ReturnType<typeof useGetActivityFrontQuery>;
export type GetActivityFrontLazyQueryHookResult = ReturnType<typeof useGetActivityFrontLazyQuery>;
export type GetActivityFrontQueryResult = Apollo.QueryResult<GetActivityFrontQuery, GetActivityFrontQueryVariables>;
export const GetAppellationDescriptionsNameSubregionDocument = gql`
    query GetAppellationDescriptionsNameSubregion {
  GetAppellationDescriptionsNameSubregion {
    name
    subregion
  }
}
    `;

/**
 * __useGetAppellationDescriptionsNameSubregionQuery__
 *
 * To run a query within a React component, call `useGetAppellationDescriptionsNameSubregionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppellationDescriptionsNameSubregionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppellationDescriptionsNameSubregionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppellationDescriptionsNameSubregionQuery(baseOptions?: Apollo.QueryHookOptions<GetAppellationDescriptionsNameSubregionQuery, GetAppellationDescriptionsNameSubregionQueryVariables>) {
        return Apollo.useQuery<GetAppellationDescriptionsNameSubregionQuery, GetAppellationDescriptionsNameSubregionQueryVariables>(GetAppellationDescriptionsNameSubregionDocument, baseOptions);
      }
export function useGetAppellationDescriptionsNameSubregionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppellationDescriptionsNameSubregionQuery, GetAppellationDescriptionsNameSubregionQueryVariables>) {
          return Apollo.useLazyQuery<GetAppellationDescriptionsNameSubregionQuery, GetAppellationDescriptionsNameSubregionQueryVariables>(GetAppellationDescriptionsNameSubregionDocument, baseOptions);
        }
export type GetAppellationDescriptionsNameSubregionQueryHookResult = ReturnType<typeof useGetAppellationDescriptionsNameSubregionQuery>;
export type GetAppellationDescriptionsNameSubregionLazyQueryHookResult = ReturnType<typeof useGetAppellationDescriptionsNameSubregionLazyQuery>;
export type GetAppellationDescriptionsNameSubregionQueryResult = Apollo.QueryResult<GetAppellationDescriptionsNameSubregionQuery, GetAppellationDescriptionsNameSubregionQueryVariables>;
export const GetClassificationFrontDocument = gql`
    query GetClassificationFront($classificationOrganismSlug: String!) {
  GetClassificationFront(classificationOrganismSlug: $classificationOrganismSlug) {
    id
    number
    classified {
      id
      appellationLabel
      wineEntities {
        wineEntityId
        name
        shortId
      }
    }
    classificationGrade
  }
}
    `;

/**
 * __useGetClassificationFrontQuery__
 *
 * To run a query within a React component, call `useGetClassificationFrontQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassificationFrontQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassificationFrontQuery({
 *   variables: {
 *      classificationOrganismSlug: // value for 'classificationOrganismSlug'
 *   },
 * });
 */
export function useGetClassificationFrontQuery(baseOptions: Apollo.QueryHookOptions<GetClassificationFrontQuery, GetClassificationFrontQueryVariables>) {
        return Apollo.useQuery<GetClassificationFrontQuery, GetClassificationFrontQueryVariables>(GetClassificationFrontDocument, baseOptions);
      }
export function useGetClassificationFrontLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassificationFrontQuery, GetClassificationFrontQueryVariables>) {
          return Apollo.useLazyQuery<GetClassificationFrontQuery, GetClassificationFrontQueryVariables>(GetClassificationFrontDocument, baseOptions);
        }
export type GetClassificationFrontQueryHookResult = ReturnType<typeof useGetClassificationFrontQuery>;
export type GetClassificationFrontLazyQueryHookResult = ReturnType<typeof useGetClassificationFrontLazyQuery>;
export type GetClassificationFrontQueryResult = Apollo.QueryResult<GetClassificationFrontQuery, GetClassificationFrontQueryVariables>;
export const GetSubscriptionDataDocument = gql`
    query GetSubscriptionData($userId: ID!) {
  GetSubscriptionData(userId: $userId) {
    _id
    email
    siret
    userId
    status
    isOwner
    telephone
    addonCodes
    recurrence
    isOperator
    mainCommune
    paymentMethod
    wineEntityName
    mainAppellation
    organizationName
    subscriptionType
    organizationStatus
    mainCommuneInseeCode
    orphanWineEntityId
    address {
      address
      commune
      country
      postalCode
      communeINSEEcode
      complementaryAddress
    }
    billingAddress {
      address
      commune
      country
      postalCode
      communeINSEEcode
      complementaryAddress
    }
    billingInformation {
      siret
      title
      email
      lastName
      tvaNumber
      firstName
      telephoneNumber
      organizationName
      organizationStatus
    }
  }
}
    `;

/**
 * __useGetSubscriptionDataQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionDataQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetSubscriptionDataQuery(baseOptions: Apollo.QueryHookOptions<GetSubscriptionDataQuery, GetSubscriptionDataQueryVariables>) {
        return Apollo.useQuery<GetSubscriptionDataQuery, GetSubscriptionDataQueryVariables>(GetSubscriptionDataDocument, baseOptions);
      }
export function useGetSubscriptionDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionDataQuery, GetSubscriptionDataQueryVariables>) {
          return Apollo.useLazyQuery<GetSubscriptionDataQuery, GetSubscriptionDataQueryVariables>(GetSubscriptionDataDocument, baseOptions);
        }
export type GetSubscriptionDataQueryHookResult = ReturnType<typeof useGetSubscriptionDataQuery>;
export type GetSubscriptionDataLazyQueryHookResult = ReturnType<typeof useGetSubscriptionDataLazyQuery>;
export type GetSubscriptionDataQueryResult = Apollo.QueryResult<GetSubscriptionDataQuery, GetSubscriptionDataQueryVariables>;
export const GetEstateVisibilityDocument = gql`
    query GetEstateVisibility($wineEntityId: ID!) {
  GetEstateVisibility(wineEntityId: $wineEntityId)
}
    `;

/**
 * __useGetEstateVisibilityQuery__
 *
 * To run a query within a React component, call `useGetEstateVisibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstateVisibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstateVisibilityQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetEstateVisibilityQuery(baseOptions: Apollo.QueryHookOptions<GetEstateVisibilityQuery, GetEstateVisibilityQueryVariables>) {
        return Apollo.useQuery<GetEstateVisibilityQuery, GetEstateVisibilityQueryVariables>(GetEstateVisibilityDocument, baseOptions);
      }
export function useGetEstateVisibilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEstateVisibilityQuery, GetEstateVisibilityQueryVariables>) {
          return Apollo.useLazyQuery<GetEstateVisibilityQuery, GetEstateVisibilityQueryVariables>(GetEstateVisibilityDocument, baseOptions);
        }
export type GetEstateVisibilityQueryHookResult = ReturnType<typeof useGetEstateVisibilityQuery>;
export type GetEstateVisibilityLazyQueryHookResult = ReturnType<typeof useGetEstateVisibilityLazyQuery>;
export type GetEstateVisibilityQueryResult = Apollo.QueryResult<GetEstateVisibilityQuery, GetEstateVisibilityQueryVariables>;
export const GetUserSettingsDocument = gql`
    query GetUserSettings($userId: String!) {
  GetUserSettings(userId: $userId) {
    noDisplayModal
  }
}
    `;

/**
 * __useGetUserSettingsQuery__
 *
 * To run a query within a React component, call `useGetUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSettingsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetUserSettingsQuery, GetUserSettingsQueryVariables>) {
        return Apollo.useQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>(GetUserSettingsDocument, baseOptions);
      }
export function useGetUserSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserSettingsQuery, GetUserSettingsQueryVariables>) {
          return Apollo.useLazyQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>(GetUserSettingsDocument, baseOptions);
        }
export type GetUserSettingsQueryHookResult = ReturnType<typeof useGetUserSettingsQuery>;
export type GetUserSettingsLazyQueryHookResult = ReturnType<typeof useGetUserSettingsLazyQuery>;
export type GetUserSettingsQueryResult = Apollo.QueryResult<GetUserSettingsQuery, GetUserSettingsQueryVariables>;
export const GetUnpaidSubscriptionInformationDocument = gql`
    query GetUnpaidSubscriptionInformation($wineEntityId: ID!) {
  GetUnpaidSubscriptionInformation(wineEntityId: $wineEntityId) {
    zohoSubscriptionId
    dateUnpaid
    wineEntityName
    mainAppellation
    subscriptionName
    price
  }
}
    `;

/**
 * __useGetUnpaidSubscriptionInformationQuery__
 *
 * To run a query within a React component, call `useGetUnpaidSubscriptionInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnpaidSubscriptionInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnpaidSubscriptionInformationQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetUnpaidSubscriptionInformationQuery(baseOptions: Apollo.QueryHookOptions<GetUnpaidSubscriptionInformationQuery, GetUnpaidSubscriptionInformationQueryVariables>) {
        return Apollo.useQuery<GetUnpaidSubscriptionInformationQuery, GetUnpaidSubscriptionInformationQueryVariables>(GetUnpaidSubscriptionInformationDocument, baseOptions);
      }
export function useGetUnpaidSubscriptionInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnpaidSubscriptionInformationQuery, GetUnpaidSubscriptionInformationQueryVariables>) {
          return Apollo.useLazyQuery<GetUnpaidSubscriptionInformationQuery, GetUnpaidSubscriptionInformationQueryVariables>(GetUnpaidSubscriptionInformationDocument, baseOptions);
        }
export type GetUnpaidSubscriptionInformationQueryHookResult = ReturnType<typeof useGetUnpaidSubscriptionInformationQuery>;
export type GetUnpaidSubscriptionInformationLazyQueryHookResult = ReturnType<typeof useGetUnpaidSubscriptionInformationLazyQuery>;
export type GetUnpaidSubscriptionInformationQueryResult = Apollo.QueryResult<GetUnpaidSubscriptionInformationQuery, GetUnpaidSubscriptionInformationQueryVariables>;
export const GetTastingRequestsDocument = gql`
    query GetTastingRequests($userId: String, $wineEntityId: String) {
  GetTastingRequests(userId: $userId, wineEntityId: $wineEntityId) {
    _id
    sampleId
    wineEntityId
    wineId
    vintageId
    status
    journalistName
    createdAt
    videoRequested
    userId
    score
    scale
    websiteUrl
    videoUrl
    tastingDate
    wineEntity {
      name
      shortId
      mainAppellation
    }
    wine {
      wineName
      wineLabel
      wineColor
      wineType
      wineSweetness
      wineId
      appellation
      bottleImage {
        data
      }
      bottleIcon {
        data
      }
    }
    vintage {
      year
      cuvee
    }
  }
}
    `;

/**
 * __useGetTastingRequestsQuery__
 *
 * To run a query within a React component, call `useGetTastingRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTastingRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTastingRequestsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetTastingRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetTastingRequestsQuery, GetTastingRequestsQueryVariables>) {
        return Apollo.useQuery<GetTastingRequestsQuery, GetTastingRequestsQueryVariables>(GetTastingRequestsDocument, baseOptions);
      }
export function useGetTastingRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTastingRequestsQuery, GetTastingRequestsQueryVariables>) {
          return Apollo.useLazyQuery<GetTastingRequestsQuery, GetTastingRequestsQueryVariables>(GetTastingRequestsDocument, baseOptions);
        }
export type GetTastingRequestsQueryHookResult = ReturnType<typeof useGetTastingRequestsQuery>;
export type GetTastingRequestsLazyQueryHookResult = ReturnType<typeof useGetTastingRequestsLazyQuery>;
export type GetTastingRequestsQueryResult = Apollo.QueryResult<GetTastingRequestsQuery, GetTastingRequestsQueryVariables>;
export const GetTastingRequestDocument = gql`
    query GetTastingRequest($tastingRequestId: ID) {
  GetTastingRequest(tastingRequestId: $tastingRequestId) {
    _id
    sampleId
    wineEntity {
      name
      shortId
      mainAppellation
      searchable
    }
    wine {
      shortId
      wineName
      wineLabel
      wineColor
      wineType
      wineSweetness
      wineId
      appellation
      bottleImage {
        data
      }
    }
    vintage {
      year
      cuvee
    }
    wineEntityId
    wineId
    vintageId
    status
    userId
    score
    scale
    websiteUrl
    videoUrl
    tastingDate
    comment {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    wineTank
    wineReleased
    bottlingDate
    extraInformations {
      fr
      en
      de
      es
      it
      nl
      ja
      zh
    }
    videoRequested
  }
}
    `;

/**
 * __useGetTastingRequestQuery__
 *
 * To run a query within a React component, call `useGetTastingRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTastingRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTastingRequestQuery({
 *   variables: {
 *      tastingRequestId: // value for 'tastingRequestId'
 *   },
 * });
 */
export function useGetTastingRequestQuery(baseOptions?: Apollo.QueryHookOptions<GetTastingRequestQuery, GetTastingRequestQueryVariables>) {
        return Apollo.useQuery<GetTastingRequestQuery, GetTastingRequestQueryVariables>(GetTastingRequestDocument, baseOptions);
      }
export function useGetTastingRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTastingRequestQuery, GetTastingRequestQueryVariables>) {
          return Apollo.useLazyQuery<GetTastingRequestQuery, GetTastingRequestQueryVariables>(GetTastingRequestDocument, baseOptions);
        }
export type GetTastingRequestQueryHookResult = ReturnType<typeof useGetTastingRequestQuery>;
export type GetTastingRequestLazyQueryHookResult = ReturnType<typeof useGetTastingRequestLazyQuery>;
export type GetTastingRequestQueryResult = Apollo.QueryResult<GetTastingRequestQuery, GetTastingRequestQueryVariables>;
export const GetJournalistListDocument = gql`
    query GetJournalistList($filters: JournalistFilter) {
  GetJournalistList(filters: $filters) {
    id
    name
    video
    demoVideoUrl
    delay
    prerequisites
    areas
  }
}
    `;

/**
 * __useGetJournalistListQuery__
 *
 * To run a query within a React component, call `useGetJournalistListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJournalistListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJournalistListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetJournalistListQuery(baseOptions?: Apollo.QueryHookOptions<GetJournalistListQuery, GetJournalistListQueryVariables>) {
        return Apollo.useQuery<GetJournalistListQuery, GetJournalistListQueryVariables>(GetJournalistListDocument, baseOptions);
      }
export function useGetJournalistListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJournalistListQuery, GetJournalistListQueryVariables>) {
          return Apollo.useLazyQuery<GetJournalistListQuery, GetJournalistListQueryVariables>(GetJournalistListDocument, baseOptions);
        }
export type GetJournalistListQueryHookResult = ReturnType<typeof useGetJournalistListQuery>;
export type GetJournalistListLazyQueryHookResult = ReturnType<typeof useGetJournalistListLazyQuery>;
export type GetJournalistListQueryResult = Apollo.QueryResult<GetJournalistListQuery, GetJournalistListQueryVariables>;
export const GetCommonalitiesWineEntitiesDocument = gql`
    query GetCommonalitiesWineEntities($wineEntityId: ID!) {
  GetCommonalitiesWineEntities(wineEntityId: $wineEntityId) {
    byCommune {
      result {
        wineEntityId
        wineEntityShortId
        wineEntityName
        appellation
        INSEEcode
      }
      total
    }
    byAppellation {
      result {
        wineEntityId
        wineEntityShortId
        wineEntityName
        appellation
      }
      total
    }
    bySubregion {
      result {
        wineEntityId
        wineEntityShortId
        wineEntityName
        appellation
        subregion
      }
      total
    }
  }
}
    `;

/**
 * __useGetCommonalitiesWineEntitiesQuery__
 *
 * To run a query within a React component, call `useGetCommonalitiesWineEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommonalitiesWineEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommonalitiesWineEntitiesQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetCommonalitiesWineEntitiesQuery(baseOptions: Apollo.QueryHookOptions<GetCommonalitiesWineEntitiesQuery, GetCommonalitiesWineEntitiesQueryVariables>) {
        return Apollo.useQuery<GetCommonalitiesWineEntitiesQuery, GetCommonalitiesWineEntitiesQueryVariables>(GetCommonalitiesWineEntitiesDocument, baseOptions);
      }
export function useGetCommonalitiesWineEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommonalitiesWineEntitiesQuery, GetCommonalitiesWineEntitiesQueryVariables>) {
          return Apollo.useLazyQuery<GetCommonalitiesWineEntitiesQuery, GetCommonalitiesWineEntitiesQueryVariables>(GetCommonalitiesWineEntitiesDocument, baseOptions);
        }
export type GetCommonalitiesWineEntitiesQueryHookResult = ReturnType<typeof useGetCommonalitiesWineEntitiesQuery>;
export type GetCommonalitiesWineEntitiesLazyQueryHookResult = ReturnType<typeof useGetCommonalitiesWineEntitiesLazyQuery>;
export type GetCommonalitiesWineEntitiesQueryResult = Apollo.QueryResult<GetCommonalitiesWineEntitiesQuery, GetCommonalitiesWineEntitiesQueryVariables>;
export const GetJournalistDocument = gql`
    query GetJournalist($slug: String, $locale: String) {
  GetJournalist(slug: $slug, locale: $locale) {
    title
    company
    areas
    about {
      fr
      en
      it
      nl
      de
      es
      ja
      zh
    }
    photo {
      twicpicURL
    }
    logo {
      twicpicURL
    }
    background {
      twicpicURL
    }
    socialMedias {
      platform
      url
    }
    vintages {
      classification
      image {
        twicpicURL
      }
      vintage {
        year
        cuvee
      }
      wineEntity {
        name
        shortId
      }
      wine {
        shortId
        brandName
        appellation
        color
        type
      }
    }
  }
}
    `;

/**
 * __useGetJournalistQuery__
 *
 * To run a query within a React component, call `useGetJournalistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJournalistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJournalistQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetJournalistQuery(baseOptions?: Apollo.QueryHookOptions<GetJournalistQuery, GetJournalistQueryVariables>) {
        return Apollo.useQuery<GetJournalistQuery, GetJournalistQueryVariables>(GetJournalistDocument, baseOptions);
      }
export function useGetJournalistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJournalistQuery, GetJournalistQueryVariables>) {
          return Apollo.useLazyQuery<GetJournalistQuery, GetJournalistQueryVariables>(GetJournalistDocument, baseOptions);
        }
export type GetJournalistQueryHookResult = ReturnType<typeof useGetJournalistQuery>;
export type GetJournalistLazyQueryHookResult = ReturnType<typeof useGetJournalistLazyQuery>;
export type GetJournalistQueryResult = Apollo.QueryResult<GetJournalistQuery, GetJournalistQueryVariables>;
export const GetJournalistSamplesDocument = gql`
    query GetJournalistSamples($exclude: String, $limit: Int) {
  GetJournalistSamples(exclude: $exclude, limit: $limit) {
    slug
    title
    company
    logo {
      twicpicURL
    }
  }
}
    `;

/**
 * __useGetJournalistSamplesQuery__
 *
 * To run a query within a React component, call `useGetJournalistSamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJournalistSamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJournalistSamplesQuery({
 *   variables: {
 *      exclude: // value for 'exclude'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetJournalistSamplesQuery(baseOptions?: Apollo.QueryHookOptions<GetJournalistSamplesQuery, GetJournalistSamplesQueryVariables>) {
        return Apollo.useQuery<GetJournalistSamplesQuery, GetJournalistSamplesQueryVariables>(GetJournalistSamplesDocument, baseOptions);
      }
export function useGetJournalistSamplesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJournalistSamplesQuery, GetJournalistSamplesQueryVariables>) {
          return Apollo.useLazyQuery<GetJournalistSamplesQuery, GetJournalistSamplesQueryVariables>(GetJournalistSamplesDocument, baseOptions);
        }
export type GetJournalistSamplesQueryHookResult = ReturnType<typeof useGetJournalistSamplesQuery>;
export type GetJournalistSamplesLazyQueryHookResult = ReturnType<typeof useGetJournalistSamplesLazyQuery>;
export type GetJournalistSamplesQueryResult = Apollo.QueryResult<GetJournalistSamplesQuery, GetJournalistSamplesQueryVariables>;
export const GetCommonalitiesWineDocument = gql`
    query GetCommonalitiesWine($wineId: ID!) {
  GetCommonalitiesWine(wineId: $wineId) {
    byAppellation {
      result {
        wineId
        wineEntityName
        wineEntityShortId
        wineShortId
        brandName
        appellation
        bottleImage {
          data
        }
        color
        sweetness
      }
      total
    }
    byProfil {
      result {
        wineId
        wineEntityName
        wineEntityShortId
        wineShortId
        brandName
        appellation
        bottleImage {
          data
        }
        color
        sweetness
      }
      total
    }
  }
}
    `;

/**
 * __useGetCommonalitiesWineQuery__
 *
 * To run a query within a React component, call `useGetCommonalitiesWineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommonalitiesWineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommonalitiesWineQuery({
 *   variables: {
 *      wineId: // value for 'wineId'
 *   },
 * });
 */
export function useGetCommonalitiesWineQuery(baseOptions: Apollo.QueryHookOptions<GetCommonalitiesWineQuery, GetCommonalitiesWineQueryVariables>) {
        return Apollo.useQuery<GetCommonalitiesWineQuery, GetCommonalitiesWineQueryVariables>(GetCommonalitiesWineDocument, baseOptions);
      }
export function useGetCommonalitiesWineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommonalitiesWineQuery, GetCommonalitiesWineQueryVariables>) {
          return Apollo.useLazyQuery<GetCommonalitiesWineQuery, GetCommonalitiesWineQueryVariables>(GetCommonalitiesWineDocument, baseOptions);
        }
export type GetCommonalitiesWineQueryHookResult = ReturnType<typeof useGetCommonalitiesWineQuery>;
export type GetCommonalitiesWineLazyQueryHookResult = ReturnType<typeof useGetCommonalitiesWineLazyQuery>;
export type GetCommonalitiesWineQueryResult = Apollo.QueryResult<GetCommonalitiesWineQuery, GetCommonalitiesWineQueryVariables>;
export const GetCommonalitiesActivitiesDocument = gql`
    query GetCommonalitiesActivities($activityId: ID!) {
  GetCommonalitiesActivities(activityId: $activityId) {
    byCommune {
      result {
        wineEntityShortId
        wineEntityName
        shortId
        name
        INSEEcode
        typeFr
        image {
          data
        }
      }
      total
    }
    byType {
      result {
        wineEntityShortId
        wineEntityName
        shortId
        name
        INSEEcode
        typeFr
        image {
          data
        }
      }
      total
    }
  }
}
    `;

/**
 * __useGetCommonalitiesActivitiesQuery__
 *
 * To run a query within a React component, call `useGetCommonalitiesActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommonalitiesActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommonalitiesActivitiesQuery({
 *   variables: {
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useGetCommonalitiesActivitiesQuery(baseOptions: Apollo.QueryHookOptions<GetCommonalitiesActivitiesQuery, GetCommonalitiesActivitiesQueryVariables>) {
        return Apollo.useQuery<GetCommonalitiesActivitiesQuery, GetCommonalitiesActivitiesQueryVariables>(GetCommonalitiesActivitiesDocument, baseOptions);
      }
export function useGetCommonalitiesActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommonalitiesActivitiesQuery, GetCommonalitiesActivitiesQueryVariables>) {
          return Apollo.useLazyQuery<GetCommonalitiesActivitiesQuery, GetCommonalitiesActivitiesQueryVariables>(GetCommonalitiesActivitiesDocument, baseOptions);
        }
export type GetCommonalitiesActivitiesQueryHookResult = ReturnType<typeof useGetCommonalitiesActivitiesQuery>;
export type GetCommonalitiesActivitiesLazyQueryHookResult = ReturnType<typeof useGetCommonalitiesActivitiesLazyQuery>;
export type GetCommonalitiesActivitiesQueryResult = Apollo.QueryResult<GetCommonalitiesActivitiesQuery, GetCommonalitiesActivitiesQueryVariables>;
export const GetFtPdfUrlDocument = gql`
    query GetFtPdfUrl($ftParametersId: ID!, $locale: String) {
  GetFtPdfUrl(ftParametersId: $ftParametersId, locale: $locale) {
    url
    fileName
  }
}
    `;

/**
 * __useGetFtPdfUrlQuery__
 *
 * To run a query within a React component, call `useGetFtPdfUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFtPdfUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFtPdfUrlQuery({
 *   variables: {
 *      ftParametersId: // value for 'ftParametersId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetFtPdfUrlQuery(baseOptions: Apollo.QueryHookOptions<GetFtPdfUrlQuery, GetFtPdfUrlQueryVariables>) {
        return Apollo.useQuery<GetFtPdfUrlQuery, GetFtPdfUrlQueryVariables>(GetFtPdfUrlDocument, baseOptions);
      }
export function useGetFtPdfUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFtPdfUrlQuery, GetFtPdfUrlQueryVariables>) {
          return Apollo.useLazyQuery<GetFtPdfUrlQuery, GetFtPdfUrlQueryVariables>(GetFtPdfUrlDocument, baseOptions);
        }
export type GetFtPdfUrlQueryHookResult = ReturnType<typeof useGetFtPdfUrlQuery>;
export type GetFtPdfUrlLazyQueryHookResult = ReturnType<typeof useGetFtPdfUrlLazyQuery>;
export type GetFtPdfUrlQueryResult = Apollo.QueryResult<GetFtPdfUrlQuery, GetFtPdfUrlQueryVariables>;
export const GetFtPdfUrlsDocument = gql`
    query GetFtPdfUrls($ftParametersId: ID!, $locales: [String]) {
  GetFtPdfUrls(ftParametersId: $ftParametersId, locales: $locales) {
    url
    fileName
  }
}
    `;

/**
 * __useGetFtPdfUrlsQuery__
 *
 * To run a query within a React component, call `useGetFtPdfUrlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFtPdfUrlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFtPdfUrlsQuery({
 *   variables: {
 *      ftParametersId: // value for 'ftParametersId'
 *      locales: // value for 'locales'
 *   },
 * });
 */
export function useGetFtPdfUrlsQuery(baseOptions: Apollo.QueryHookOptions<GetFtPdfUrlsQuery, GetFtPdfUrlsQueryVariables>) {
        return Apollo.useQuery<GetFtPdfUrlsQuery, GetFtPdfUrlsQueryVariables>(GetFtPdfUrlsDocument, baseOptions);
      }
export function useGetFtPdfUrlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFtPdfUrlsQuery, GetFtPdfUrlsQueryVariables>) {
          return Apollo.useLazyQuery<GetFtPdfUrlsQuery, GetFtPdfUrlsQueryVariables>(GetFtPdfUrlsDocument, baseOptions);
        }
export type GetFtPdfUrlsQueryHookResult = ReturnType<typeof useGetFtPdfUrlsQuery>;
export type GetFtPdfUrlsLazyQueryHookResult = ReturnType<typeof useGetFtPdfUrlsLazyQuery>;
export type GetFtPdfUrlsQueryResult = Apollo.QueryResult<GetFtPdfUrlsQuery, GetFtPdfUrlsQueryVariables>;
export const GetFtZipUrlDocument = gql`
    query GetFtZipUrl($ftParametersId: ID!) {
  GetFtZipUrl(ftParametersId: $ftParametersId) {
    url
    fileName
  }
}
    `;

/**
 * __useGetFtZipUrlQuery__
 *
 * To run a query within a React component, call `useGetFtZipUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFtZipUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFtZipUrlQuery({
 *   variables: {
 *      ftParametersId: // value for 'ftParametersId'
 *   },
 * });
 */
export function useGetFtZipUrlQuery(baseOptions: Apollo.QueryHookOptions<GetFtZipUrlQuery, GetFtZipUrlQueryVariables>) {
        return Apollo.useQuery<GetFtZipUrlQuery, GetFtZipUrlQueryVariables>(GetFtZipUrlDocument, baseOptions);
      }
export function useGetFtZipUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFtZipUrlQuery, GetFtZipUrlQueryVariables>) {
          return Apollo.useLazyQuery<GetFtZipUrlQuery, GetFtZipUrlQueryVariables>(GetFtZipUrlDocument, baseOptions);
        }
export type GetFtZipUrlQueryHookResult = ReturnType<typeof useGetFtZipUrlQuery>;
export type GetFtZipUrlLazyQueryHookResult = ReturnType<typeof useGetFtZipUrlLazyQuery>;
export type GetFtZipUrlQueryResult = Apollo.QueryResult<GetFtZipUrlQuery, GetFtZipUrlQueryVariables>;
export const GetFtpDiffusionsDocument = gql`
    query GetFtpDiffusions($wineEntityId: ID) {
  GetFtpDiffusions(wineEntityId: $wineEntityId) {
    ft {
      ftId
      wineEntityId
      wineId
      vintageId
      name
      description
      wine
      vintage
      cuvee
      fullAppellation
      version
      status
      lastModification
      online
      createdAt
      wineEntityName
      languages {
        fr
        en
        es
        de
        it
        nl
        ja
        zh
      }
      preview {
        data
      }
      bottleImage {
        data
      }
    }
    ftpDiffusions {
      _id
      host
      port
      ftId
      wineId
      version
      username
      password
      fileName
      language
      lastSync
      autoSync
      protocole
      vintageId
      remotePath
      wineEntityId
      succeed
    }
  }
}
    `;

/**
 * __useGetFtpDiffusionsQuery__
 *
 * To run a query within a React component, call `useGetFtpDiffusionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFtpDiffusionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFtpDiffusionsQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetFtpDiffusionsQuery(baseOptions?: Apollo.QueryHookOptions<GetFtpDiffusionsQuery, GetFtpDiffusionsQueryVariables>) {
        return Apollo.useQuery<GetFtpDiffusionsQuery, GetFtpDiffusionsQueryVariables>(GetFtpDiffusionsDocument, baseOptions);
      }
export function useGetFtpDiffusionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFtpDiffusionsQuery, GetFtpDiffusionsQueryVariables>) {
          return Apollo.useLazyQuery<GetFtpDiffusionsQuery, GetFtpDiffusionsQueryVariables>(GetFtpDiffusionsDocument, baseOptions);
        }
export type GetFtpDiffusionsQueryHookResult = ReturnType<typeof useGetFtpDiffusionsQuery>;
export type GetFtpDiffusionsLazyQueryHookResult = ReturnType<typeof useGetFtpDiffusionsLazyQuery>;
export type GetFtpDiffusionsQueryResult = Apollo.QueryResult<GetFtpDiffusionsQuery, GetFtpDiffusionsQueryVariables>;
export const GetFtpDiffusionLogDocument = gql`
    query GetFtpDiffusionLog($ftpDiffusionId: ID) {
  GetFtpDiffusionLog(ftpDiffusionId: $ftpDiffusionId) {
    text
    logName
  }
}
    `;

/**
 * __useGetFtpDiffusionLogQuery__
 *
 * To run a query within a React component, call `useGetFtpDiffusionLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFtpDiffusionLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFtpDiffusionLogQuery({
 *   variables: {
 *      ftpDiffusionId: // value for 'ftpDiffusionId'
 *   },
 * });
 */
export function useGetFtpDiffusionLogQuery(baseOptions?: Apollo.QueryHookOptions<GetFtpDiffusionLogQuery, GetFtpDiffusionLogQueryVariables>) {
        return Apollo.useQuery<GetFtpDiffusionLogQuery, GetFtpDiffusionLogQueryVariables>(GetFtpDiffusionLogDocument, baseOptions);
      }
export function useGetFtpDiffusionLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFtpDiffusionLogQuery, GetFtpDiffusionLogQueryVariables>) {
          return Apollo.useLazyQuery<GetFtpDiffusionLogQuery, GetFtpDiffusionLogQueryVariables>(GetFtpDiffusionLogDocument, baseOptions);
        }
export type GetFtpDiffusionLogQueryHookResult = ReturnType<typeof useGetFtpDiffusionLogQuery>;
export type GetFtpDiffusionLogLazyQueryHookResult = ReturnType<typeof useGetFtpDiffusionLogLazyQuery>;
export type GetFtpDiffusionLogQueryResult = Apollo.QueryResult<GetFtpDiffusionLogQuery, GetFtpDiffusionLogQueryVariables>;
export const GetPaperSubscriptionDataDocument = gql`
    query GetPaperSubscriptionData($wineEntityId: ID!) {
  GetPaperSubscriptionData(wineEntityId: $wineEntityId) {
    wineEntityId
    accessibility
    isBookingRequired
    description {
      fr
      de
      en
      es
      it
      nl
      ja
      zh
    }
    languages
    priceRange
    touristLabels
    visitSchedule
    hasOtherCulture
    otherCulture
    bugFightMethod
    byGrassing
    grassingMethod
    done
    redVineSurface
    whiteVineSurface
    redAvgProd
    whiteAvgProd
    redWineAssembly {
      grapeVarietyName
      percentage
    }
    whiteWineAssembly {
      grapeVarietyName
      percentage
    }
    certificationFiles {
      bucketName
      fileName
    }
  }
}
    `;

/**
 * __useGetPaperSubscriptionDataQuery__
 *
 * To run a query within a React component, call `useGetPaperSubscriptionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaperSubscriptionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaperSubscriptionDataQuery({
 *   variables: {
 *      wineEntityId: // value for 'wineEntityId'
 *   },
 * });
 */
export function useGetPaperSubscriptionDataQuery(baseOptions: Apollo.QueryHookOptions<GetPaperSubscriptionDataQuery, GetPaperSubscriptionDataQueryVariables>) {
        return Apollo.useQuery<GetPaperSubscriptionDataQuery, GetPaperSubscriptionDataQueryVariables>(GetPaperSubscriptionDataDocument, baseOptions);
      }
export function useGetPaperSubscriptionDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaperSubscriptionDataQuery, GetPaperSubscriptionDataQueryVariables>) {
          return Apollo.useLazyQuery<GetPaperSubscriptionDataQuery, GetPaperSubscriptionDataQueryVariables>(GetPaperSubscriptionDataDocument, baseOptions);
        }
export type GetPaperSubscriptionDataQueryHookResult = ReturnType<typeof useGetPaperSubscriptionDataQuery>;
export type GetPaperSubscriptionDataLazyQueryHookResult = ReturnType<typeof useGetPaperSubscriptionDataLazyQuery>;
export type GetPaperSubscriptionDataQueryResult = Apollo.QueryResult<GetPaperSubscriptionDataQuery, GetPaperSubscriptionDataQueryVariables>;
export const namedOperations = {
  Query: {
    getSimpleUserById: 'getSimpleUserById',
    AboutHistory: 'AboutHistory',
    ListWines: 'ListWines',
    GetWineById: 'GetWineById',
    GetEnumByField: 'GetEnumByField',
    GetRoleEnum: 'GetRoleEnum',
    GetVintagesByWineId: 'GetVintagesByWineId',
    SurfaceDeProductionScreen: 'SurfaceDeProductionScreen',
    getAppellationDependencyObject: 'getAppellationDependencyObject',
    TeamMemberBoc: 'TeamMemberBoc',
    ProprieteVignobleScreenQuery: 'ProprieteVignobleScreenQuery',
    getFeminineTitles: 'getFeminineTitles',
    TeamGeneral: 'TeamGeneral',
    VintageIdentityScreen: 'VintageIdentityScreen',
    VintageViticultureScreen: 'VintageViticultureScreen',
    VintageWineMakingScreen: 'VintageWineMakingScreen',
    GeneralInformationScreen: 'GeneralInformationScreen',
    WinePresentationScreen: 'WinePresentationScreen',
    VintageAgeingScreen: 'VintageAgeingScreen',
    VintageProductionScreen: 'VintageProductionScreen',
    VintageNutritionScreen: 'VintageNutritionScreen',
    GetNotifications: 'GetNotifications',
    VintageMarketingScreen: 'VintageMarketingScreen',
    VintageAdviceScreen: 'VintageAdviceScreen',
    SurfaceImportCvi: 'SurfaceImportCvi',
    CellarsDashboard: 'CellarsDashboard',
    SurfaceManualScreen: 'SurfaceManualScreen',
    EstateScreen: 'EstateScreen',
    AccountScreenEmpty: 'AccountScreenEmpty',
    JournalistAccountScreen: 'JournalistAccountScreen',
    GetServicesOfferByJournalist: 'GetServicesOfferByJournalist',
    AccountScreen: 'AccountScreen',
    CellarTabScreen: 'CellarTabScreen',
    SimpleVintages: 'SimpleVintages',
    GetLabelsForBoc: 'GetLabelsForBoc',
    GetCertificationObjects: 'GetCertificationObjects',
    SubscriptionSummary: 'SubscriptionSummary',
    GetCommunesOptions: 'GetCommunesOptions',
    ActivityList: 'ActivityList',
    EstateFrontScreen: 'EstateFrontScreen',
    CellarFrontPopin: 'CellarFrontPopin',
    VineyardFrontPopin: 'VineyardFrontPopin',
    FrontWinePresentation: 'FrontWinePresentation',
    FrontVintage: 'FrontVintage',
    FrontVintageViticulture: 'FrontVintageViticulture',
    FrontVintageLabelsPopin: 'FrontVintageLabelsPopin',
    FrontVintageWineMaking: 'FrontVintageWineMaking',
    FrontVintageAgeing: 'FrontVintageAgeing',
    FrontVintageProduction: 'FrontVintageProduction',
    FrontVintageNutrition: 'FrontVintageNutrition',
    FrontVintageQrcode: 'FrontVintageQrcode',
    FrontQrcodeRedirect: 'FrontQrcodeRedirect',
    FrontVintageMarketing: 'FrontVintageMarketing',
    FrontVintageAdvice: 'FrontVintageAdvice',
    FrontVintageDetailedHarvest: 'FrontVintageDetailedHarvest',
    GetTranslationOptions: 'GetTranslationOptions',
    ActivityFront: 'ActivityFront',
    ActivityTabScreen: 'ActivityTabScreen',
    ContactScreen: 'ContactScreen',
    AllowedAppellationLabels: 'AllowedAppellationLabels',
    AllFoodPairings: 'AllFoodPairings',
    GetExternalServiceRequests: 'GetExternalServiceRequests',
    VintageRewardScreen: 'VintageRewardScreen',
    FrontWineList: 'FrontWineList',
    ListActivities: 'ListActivities',
    MainTitle: 'MainTitle',
    GetCommuneFront: 'GetCommuneFront',
    ContactCard: 'ContactCard',
    GetRandomWineEntities: 'GetRandomWineEntities',
    GetRandomWineEntitiesFromCommune: 'GetRandomWineEntitiesFromCommune',
    AboutVineyard: 'AboutVineyard',
    AboutContactDetails: 'AboutContactDetails',
    AboutCellars: 'AboutCellars',
    GetCommunesOfAppellation: 'GetCommunesOfAppellation',
    GetOrphanWineEntities: 'GetOrphanWineEntities',
    GetBesvInfo: 'GetBesvInfo',
    GetOwnerOperator: 'GetOwnerOperator',
    GetFtParameters: 'GetFtParameters',
    GetFtParameter: 'GetFtParameter',
    GetClassificationOrganismFront: 'GetClassificationOrganismFront',
    GetClassificationOrganismList: 'GetClassificationOrganismList',
    GetOdgFront: 'GetOdgFront',
    GetOdgShort: 'GetOdgShort',
    GetOdgSample: 'GetOdgSample',
    GetAuthorFront: 'GetAuthorFront',
    GetAuthorFrontList: 'GetAuthorFrontList',
    GetFeretContacts: 'GetFeretContacts',
    GetFeretContactEmails: 'GetFeretContactEmails',
    GetFeretContactStats: 'GetFeretContactStats',
    GetFeretContact: 'GetFeretContact',
    GetContactsFromCSV: 'GetContactsFromCSV',
    GetDiffusionListsSimple: 'GetDiffusionListsSimple',
    GetDiffusionLists: 'GetDiffusionLists',
    GetDiffusionListsWithContacts: 'GetDiffusionListsWithContacts',
    GetDiffusionList: 'GetDiffusionList',
    GetStreamerFtDiffusions: 'GetStreamerFtDiffusions',
    GetWatcherFtDiffusions: 'GetWatcherFtDiffusions',
    GetFtRequestByCode: 'GetFtRequestByCode',
    GetDiffusionParameters: 'GetDiffusionParameters',
    GetFtDiffusionByContact: 'GetFtDiffusionByContact',
    GetFtDiffusionByDiffusionList: 'GetFtDiffusionByDiffusionList',
    GetCanCreateFt: 'GetCanCreateFt',
    SearchVintages: 'SearchVintages',
    searchWines: 'searchWines',
    SearchActivities: 'SearchActivities',
    SearchWineEntities: 'SearchWineEntities',
    GetWineCardInformation: 'GetWineCardInformation',
    SearchAll: 'SearchAll',
    HomeResult: 'HomeResult',
    GetWineEntityCardInformation: 'GetWineEntityCardInformation',
    GetActivityCardInformation: 'GetActivityCardInformation',
    AutocompleteSearch: 'AutocompleteSearch',
    GetFormationOrganismsFront: 'GetFormationOrganismsFront',
    GetFormationOrganismsSample: 'GetFormationOrganismsSample',
    GetArticleFront: 'GetArticleFront',
    GetArticleContent: 'GetArticleContent',
    GetArticleSummary: 'GetArticleSummary',
    GetVintagesOfCompetition: 'GetVintagesOfCompetition',
    GetRepresentationOrganismsFrontSimplified: 'GetRepresentationOrganismsFrontSimplified',
    GetArticlesByAuthorId: 'GetArticlesByAuthorId',
    GetRepresentationOrganismsFront: 'GetRepresentationOrganismsFront',
    GetRandomCompetitionSample: 'GetRandomCompetitionSample',
    GetCompetitionList: 'GetCompetitionList',
    GetCompetitionsFront: 'GetCompetitionsFront',
    GetAppellationDescriptionsFront: 'GetAppellationDescriptionsFront',
    GetAppellationDescriptionsSample: 'GetAppellationDescriptionsSample',
    GetAppellationVintageIds: 'GetAppellationVintageIds',
    GetAppellationVintage: 'GetAppellationVintage',
    GetFirstWineId: 'GetFirstWineId',
    GetFirstActivityId: 'GetFirstActivityId',
    GetExplorerKeywords: 'GetExplorerKeywords',
    GetCommunePostalCode: 'GetCommunePostalCode',
    GetIndicatorsMetrics: 'GetIndicatorsMetrics',
    GetMemberMetrics: 'GetMemberMetrics',
    GetContactMetrics: 'GetContactMetrics',
    GetFtParametersMetrics: 'GetFtParametersMetrics',
    GetWineMetrics: 'GetWineMetrics',
    GetWineActivityMetrics: 'GetWineActivityMetrics',
    GetVineyardMetrics: 'GetVineyardMetrics',
    GetFtModels: 'GetFtModels',
    GetActivityFront: 'GetActivityFront',
    GetAppellationDescriptionsNameSubregion: 'GetAppellationDescriptionsNameSubregion',
    GetClassificationFront: 'GetClassificationFront',
    GetSubscriptionData: 'GetSubscriptionData',
    GetEstateVisibility: 'GetEstateVisibility',
    GetUserSettings: 'GetUserSettings',
    GetUnpaidSubscriptionInformation: 'GetUnpaidSubscriptionInformation',
    GetTastingRequests: 'GetTastingRequests',
    GetTastingRequest: 'GetTastingRequest',
    GetJournalistList: 'GetJournalistList',
    GetCommonalitiesWineEntities: 'GetCommonalitiesWineEntities',
    GetJournalist: 'GetJournalist',
    GetJournalistSamples: 'GetJournalistSamples',
    GetCommonalitiesWine: 'GetCommonalitiesWine',
    GetCommonalitiesActivities: 'GetCommonalitiesActivities',
    GetFtPdfUrl: 'GetFtPdfUrl',
    GetFtPdfUrls: 'GetFtPdfUrls',
    GetFtZipUrl: 'GetFtZipUrl',
    GetFtpDiffusions: 'GetFtpDiffusions',
    GetFtpDiffusionLog: 'GetFtpDiffusionLog',
    GetPaperSubscriptionData: 'GetPaperSubscriptionData'
  },
  Mutation: {
    CreateUploadURL: 'CreateUploadURL',
    CreateDownloadURL: 'CreateDownloadURL',
    CreateWine: 'CreateWine',
    RemoveCellar: 'RemoveCellar',
    RemoveCertification: 'RemoveCertification',
    ParseCVI: 'ParseCVI',
    deleteAllSurfaces: 'deleteAllSurfaces',
    AddTeamMember: 'AddTeamMember',
    EditTeamMember: 'EditTeamMember',
    DeleteTeamMember: 'DeleteTeamMember',
    ProprieteVignobleScreenMutation: 'ProprieteVignobleScreenMutation',
    EditTeamGeneral: 'EditTeamGeneral',
    EditGeneralInformationScreen: 'EditGeneralInformationScreen',
    EditVintageViticultureScreen: 'EditVintageViticultureScreen',
    EditVintageWineMakingScreen: 'EditVintageWineMakingScreen',
    SignUp: 'SignUp',
    SignUpInvite: 'SignUpInvite',
    SignInInvite: 'SignInInvite',
    SignUpJournalist: 'SignUpJournalist',
    EditWinePresentationScreen: 'EditWinePresentationScreen',
    EditVintageAgeingScreen: 'EditVintageAgeingScreen',
    EditVintageProductionScreen: 'EditVintageProductionScreen',
    EditVintageNutritionScreen: 'EditVintageNutritionScreen',
    EditVintageMarketingScreen: 'EditVintageMarketingScreen',
    EditVintageAdviceScreen: 'EditVintageAdviceScreen',
    EditVintageAdviceUrl: 'EditVintageAdviceUrl',
    EditSurfaceManual: 'EditSurfaceManual',
    EditEstate: 'EditEstate',
    EditVintageIdentity: 'EditVintageIdentity',
    EditEstateComplementaryInfo: 'EditEstateComplementaryInfo',
    AddCellar: 'AddCellar',
    EditCellarTab: 'EditCellarTab',
    AddVintage: 'AddVintage',
    EditCellarPencil: 'EditCellarPencil',
    CreateLabelBoc: 'CreateLabelBoc',
    ModifyLabelBoc: 'ModifyLabelBoc',
    ChangePassword: 'ChangePassword',
    EditAccountScreen: 'EditAccountScreen',
    EditActivity: 'EditActivity',
    AddActivity: 'AddActivity',
    RemoveActivity: 'RemoveActivity',
    RemoveScore: 'RemoveScore',
    AddQrcodeStats: 'AddQrcodeStats',
    AddConnectionLog: 'AddConnectionLog',
    EditContact: 'EditContact',
    EditHiddenSurfaces: 'EditHiddenSurfaces',
    SetImportCVI: 'SetImportCVI',
    CreateCertificationSuggestion: 'CreateCertificationSuggestion',
    EditRewards: 'EditRewards',
    MarkNotification: 'MarkNotification',
    DeleteManyNotifications: 'DeleteManyNotifications',
    MarkManyNotifications: 'MarkManyNotifications',
    DeleteNotification: 'DeleteNotification',
    NewTranslationRequest: 'NewTranslationRequest',
    ApplyEstateChecking: 'ApplyEstateChecking',
    ApplyOwnerOperator: 'ApplyOwnerOperator',
    confirmRewardPermission: 'confirmRewardPermission',
    GenerateFt: 'GenerateFt',
    RequestFtLanguage: 'RequestFtLanguage',
    RequestFtLanguages: 'RequestFtLanguages',
    CreateFeretContact: 'CreateFeretContact',
    CancelContractCRMTask: 'CancelContractCRMTask',
    CreateFeretContacts: 'CreateFeretContacts',
    EditFeretContact: 'EditFeretContact',
    DeleteFeretContact: 'DeleteFeretContact',
    CreateDiffusionList: 'CreateDiffusionList',
    EditDiffusionList: 'EditDiffusionList',
    DeleteDiffusionList: 'DeleteDiffusionList',
    RemoveContactFromDiffusionList: 'RemoveContactFromDiffusionList',
    AddContactToDiffusionList: 'AddContactToDiffusionList',
    OpenFtDiffusion: 'OpenFtDiffusion',
    EditAccountScreenEmpty: 'EditAccountScreenEmpty',
    EditJournalistAccount: 'EditJournalistAccount',
    EditServicesOfferByJournalist: 'EditServicesOfferByJournalist',
    CreateFtDiffusion: 'CreateFtDiffusion',
    EditDiffusionParameters: 'EditDiffusionParameters',
    DeleteFt: 'DeleteFt',
    SendEstateMail: 'SendEstateMail',
    DeleteUser: 'DeleteUser',
    EditContactAndResentDiffusion: 'EditContactAndResentDiffusion',
    EditFtDiffusion: 'EditFtDiffusion',
    DeleteWine: 'DeleteWine',
    DeleteVintage: 'DeleteVintage',
    ResendAll: 'ResendAll',
    ResendOne: 'ResendOne',
    SwitchStatus: 'SwitchStatus',
    CreateFt: 'CreateFt',
    EditFt: 'EditFt',
    UpdateFtComponents: 'UpdateFtComponents',
    EditUserEmail: 'EditUserEmail',
    CreateContactsWithQueue: 'CreateContactsWithQueue',
    CreateWebFtDiffusion: 'CreateWebFtDiffusion',
    CreateFtFromModel: 'CreateFtFromModel',
    HandleReCaptcha: 'HandleReCaptcha',
    SendContactMail: 'SendContactMail',
    CreateRoleSugestion: 'CreateRoleSugestion',
    CreateNewSubscriptionData: 'CreateNewSubscriptionData',
    GenerateSubscriptionHostedPage: 'GenerateSubscriptionHostedPage',
    ChangeEstateVisibility: 'ChangeEstateVisibility',
    EditNoDisplayModal: 'EditNoDisplayModal',
    CreateServiceRequest: 'CreateServiceRequest',
    GenerateHostedPageAddon: 'GenerateHostedPageAddon',
    CancelSubscriptionData: 'CancelSubscriptionData',
    EditPaperSubcription: 'EditPaperSubcription',
    CheckEmailAvailability: 'CheckEmailAvailability',
    EditTastingRequest: 'EditTastingRequest',
    EditTastingRequestStatus: 'EditTastingRequestStatus',
    GenerateHostedPageUpgrade: 'GenerateHostedPageUpgrade',
    CreateTastingRequests: 'CreateTastingRequests',
    UpdateFtpDiffusions: 'UpdateFtpDiffusions',
    DiffuseOne: 'DiffuseOne',
    SavePaperSubscriptionData: 'SavePaperSubscriptionData'
  }
}