import { Box, makeStyles } from "@material-ui/core";
import OptionCard from "./OptionCard";
import { useWindowDimensions } from "helpers/helpers";
import theme from "globalTheme";
import { SwiperWrapper } from "Front/SwiperCards";
import { Trans, t } from "@lingui/macro";
import basic from "assets/appearsInBESV/basic.svg";
import essential from "assets/appearsInBESV/essential.svg";
import initial from "assets/appearsInBESV/initial.svg";

const useStyles = makeStyles(() => ({
    options_container: {
        gridTemplateColumns: "repeat(12, 1fr)",
        display: "grid",
        gap: "1rem",
        maxWidth: "1100px",
        margin: "2rem auto 0 auto",
        justifyContent: "space-around",
        alignContent: "space-around",
    },
    option_card: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 4",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 4",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 4",
        },
    },
}));

const SubscriptionOptions = () => {
    const styles = useStyles();
    const { width } = useWindowDimensions();

    return (
        <Box
            className={styles.options_container}
            component={width < 600 && SwiperWrapper}
            spaceBetween={0}
            slidesPerView={1}
            noPadding
            boxPaddingTop="1rem"
        >
            <OptionCard
                icon={basic}
                className={styles.option_card}
                subscriptionName={t`basique`}
                description={
                    <Trans>
                        Offrez-vous gratuitement une simple présence sur <i>Bordeaux et ses Vins</i> en ligne.{" "}
                    </Trans>
                }
                publication={<Trans>Mention ligne dans le papier comprise pour la XXème édition</Trans>}
            />
            <OptionCard
                icon={essential}
                className={styles.option_card}
                subscriptionName={t`initial`}
                description={
                    <Trans>Présentez de façon intermédiaire votre propriété et ses vins au monde entier.</Trans>
                }
                publication={<Trans>Souscription complète papier comprise pour la XXème édition</Trans>}
            />
            <OptionCard
                icon={initial}
                className={styles.option_card}
                subscriptionName={t`essentiel`}
                description={
                    <Trans>En plus de la version « Initial », automatisez et diffusez vos fiches techniques</Trans>
                }
                publication={<Trans>Souscription complète papier comprise pour la XXème édition</Trans>}
            />
            {/* <OptionCard
                icon={premium}
                className={styles.option_card}
                subscriptionName={t`premium`}
                description={<Trans>Toutes les fonctionnalités de la version « Essentiel » sans aucune limite !</Trans>}
            /> */}
        </Box>
    );
};

SubscriptionOptions.propTypes = {};

export default SubscriptionOptions;
